import { BubbleView, theme } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { TopicDetailQuery } from '../../generated/hooks';
import { scrollToElement } from './helpers';
import { SectionLinkView, DefaultSectionLinkView } from './LayoutComponents';
import { isNextSection, QuestionMappingByOrder } from './topicStructure';

type SectionLinkProps = {
  rule: TopicDetailQuery['topicDetail']['allQuestions'][0]['rules'][0];
  mapping: QuestionMappingByOrder;
  questionId: string;
  isDefault?: boolean;
  isForAll?: boolean;
};

const SectionLink = ({ rule, mapping, questionId, isDefault, isForAll }: SectionLinkProps) => {
  const nextQuestionId = rule.nextQuestion.id;
  const sectionIndex = mapping[nextQuestionId];

  const [t] = useTranslation('topicOverview');

  const calculateText = () => {
    const isNext = isNextSection(questionId, rule.nextQuestion.id, mapping);

    if (isDefault && isForAll) {
      return isNext ? t('routeToNext') : `${t('routeWithoutAnonymity')} ${sectionIndex + 1}`;
    } else if (isDefault && !isForAll) {
      return isNext ? t('selectRouteToNext') : `${t('selectRouteToNumber')} ${sectionIndex + 1}`;
    } else {
      return isNext ? t('routeToNext') : `${t('routeWithoutAnonymity')} ${sectionIndex + 1}`;
    }
  };

  const sectionLink = (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <BubbleView
        backgroundColor={theme.colors.backgroundCover.default}
        left={true}
        color={theme.colors.text.secondary}
        font={theme.typography.body.small ? theme.typography.body.small.regular : undefined}
      >
        <a
          href={`#section_${sectionIndex}`}
          onClick={(event) => {
            event.preventDefault();
            scrollToElement(`section_${sectionIndex}`);
          }}
        >
          {calculateText()}
        </a>
      </BubbleView>
    </div>
  );

  if (isDefault) {
    return <DefaultSectionLinkView>{sectionLink}</DefaultSectionLinkView>;
  }
  return <SectionLinkView>{sectionLink}</SectionLinkView>;
};

export default SectionLink;
