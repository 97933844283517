import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { theme, Checkbox, ItemWithCheckboxContainer } from '@arnold/common';
import { ReactComponent as Check } from '@arnold/common/lib/assets/icons/Check.svg';
import { ReactComponent as Line } from '@arnold/common/lib/assets/icons/Line.svg';
import { intersection } from 'ramda';
import { ActiveRespondentsQuery, SurveyQuery } from '../../generated/hooks';
import { Team } from './TeamSelect';

type Members = NonNullable<SurveyQuery['survey']>['whitelistedRespondents'];

interface ICustomMenuProps {
  teams: Team[];
  value: Members;
  onToggle: (respondent: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents']) => void;
  search?: string;
  loading?: boolean;
  remainingCount?: number;
}

interface ITeamProps {
  value: Members;
  level: number;
  team: Team;
  onToggle: (respondent: Members) => void;
  search?: string;
  loading?: boolean;
  remainingCount?: number;
}

interface IItemContainer {
  level: number;
  highlight: boolean;
}

interface ITeamMemberProps {
  value: Members;
  level: number;
  member: Members[0];
  onToggle: (respondent: Members) => void;
  search?: string;
  disabled?: boolean;
}

type IconProps = {
  disabled?: boolean;
};

const OptionName = styled.span`
  white-space: nowrap;
`;

const Empty = styled.div`
  padding: ${theme.spacing.e} ${theme.spacing.f};
  color: ${theme.colors.text.secondary};
`;

const ItemContainer = styled(ItemWithCheckboxContainer)`
  padding-left: ${(props: IItemContainer) => 18 + props.level * 16}px;
  font-weight: ${(props: IItemContainer) => (props.highlight ? '700' : '400')};
`;

const StyledCheckIcon = styled(Check)<IconProps>`
  & path {
    fill: ${({ disabled }) => (disabled ? theme.colors.borderMain.disabled : theme.colors.actionPrimary)};
  }
`;

const StyledLineIcon = styled(Line)<IconProps>`
  & path {
    stroke: ${({ disabled }) => (disabled ? theme.colors.borderMain.disabled : theme.colors.actionPrimary)};
  }
`;

const getTeamMembers = (team: Team, all: Members = []): Members => {
  all = [...all, ...team.members];
  if (team.teams) {
    team.teams.forEach((t) => {
      all = getTeamMembers(t, all);
    });
  }
  return all;
};

const TeamMember = ({ value, onToggle, search, member, level, disabled }: ITeamMemberProps) => {
  const selected = !!value.find((respondent) => respondent.id === member.id);
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      onToggle(selected ? value.filter((resp) => member.id !== resp.id) : [...value, member]);
      event.stopPropagation();
    }
  };
  const highlight =
    search?.length && `${member.firstname} ${member.surname}`.toLowerCase().includes(search.toLowerCase());
  return (
    <div>
      <ItemContainer
        key={`member-${member.id}`}
        level={level}
        onClick={onClick}
        highlight={!!highlight}
        data-cy="team-select-respondent"
        disabled={disabled}
      >
        <Checkbox
          onToggle={onClick}
          selected={selected}
          partlySelected={false}
          text={<OptionName>{`${member.firstname} ${member.surname}`}</OptionName>}
          checkIcon={<StyledCheckIcon disabled={disabled} />}
          lineIcon={<StyledLineIcon disabled={disabled} />}
          disabled={disabled}
        />
      </ItemContainer>
    </div>
  );
};

const countMembers = (team: Team): number => {
  const memberCount = team.members.filter((m) => !m.disabled).length;
  if (team.teams) {
    return team.teams.reduce((acc, t) => acc + countMembers(t), 0) + memberCount;
  }
  return memberCount;
};

const TeamItem = React.memo(({ team, value, onToggle, level, search, loading, remainingCount }: ITeamProps) => {
  const { t } = useTranslation('respondentSelect');
  if (team.id.toString() === '-1') {
    return <TeamMember member={team.members[0]} value={value} onToggle={onToggle} level={0} search={search} />;
  }

  const teamMembers = getTeamMembers(team).map((member) => member.id);
  const selectedValues = value.map((member) => member.id);

  const partlySelected = intersection(teamMembers, selectedValues).length > 0;

  const selected = intersection(teamMembers, selectedValues).length === teamMembers.length;

  const memberCount = countMembers(team);
  const disabled = loading || (!selected && typeof remainingCount === 'number' ? memberCount > remainingCount : false);

  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled) {
      onToggle(selected ? value.filter((resp) => !teamMembers.includes(resp.id)) : [...value, ...getTeamMembers(team)]);
    }
    event.stopPropagation();
  };
  const highlight = search?.length && team.name?.toLowerCase().includes(search.toLowerCase());

  return (
    <div key={`team-${team.id}`}>
      <ItemContainer
        key={team.id}
        level={level}
        onClick={onClick}
        highlight={!!highlight}
        data-cy="team-select-item"
        disabled={disabled}
      >
        <Checkbox
          onToggle={onClick}
          selected={selected}
          partlySelected={partlySelected}
          text={<OptionName>{team.name ? t('teamName', { team: team.name }) : t('teams:withoutLeader')}</OptionName>}
          checkIcon={<StyledCheckIcon disabled={loading} />}
          lineIcon={<StyledLineIcon disabled={loading} />}
          disabled={disabled}
        />
      </ItemContainer>
      {team.teams &&
        team.teams.map((newTeam) => {
          return (
            <TeamItem
              level={level + 1}
              key={newTeam.id}
              team={newTeam}
              value={value}
              onToggle={onToggle}
              search={search}
              loading={loading}
              remainingCount={remainingCount}
            />
          );
        })}
      {team.members &&
        team.members.map((member) => {
          const isMemberSelected = value.find((respondent) => respondent.id === member.id);
          return (
            <TeamMember
              member={member}
              value={value}
              onToggle={onToggle}
              level={level + 1}
              search={search}
              disabled={
                loading || (!isMemberSelected && typeof remainingCount === 'number' ? remainingCount < 1 : false)
              }
            />
          );
        })}
    </div>
  );
});

const CustomMenu = React.forwardRef((props: ICustomMenuProps, ref) => {
  const { t } = useTranslation('respondentSelect');
  const menuRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    menuRef.current?.scrollTo({ top: (ref as React.MutableRefObject<number>).current });
    const handle = () => {
      if (menuRef.current) {
        (ref as React.MutableRefObject<number>).current = menuRef.current.scrollTop;
      }
    };
    if (menuRef.current) {
      menuRef.current.addEventListener('scroll', handle);
    }
    return () => {
      if (menuRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        menuRef.current.removeEventListener('scroll', handle);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <components.Menu {...props}>
      <div data-cy="admin-respondent-list" style={{ overflow: 'auto', maxHeight: '200px' }} ref={menuRef}>
        {props.teams.map((team) => {
          return (
            <>
              <TeamItem
                level={0}
                key={`t_${team?.leader?.id}`}
                team={team}
                value={props.value}
                onToggle={props.onToggle}
                search={props.search}
                loading={props.loading}
                remainingCount={props.remainingCount}
              />
            </>
          );
        })}
        {props.teams.length === 0 && <Empty>{t('processDetail:noOptions')}</Empty>}
      </div>
    </components.Menu>
  );
});

export default CustomMenu;
