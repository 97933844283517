import { getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { BodyMedium, Checkbox, theme } from '@arnold/common';
import i18n from '../../translations/i18n';
import { SurveyGroupQuery } from '../../generated/hooks';
import BaseModal from './BaseModal';

const Content = styled.div`
  padding-bottom: ${theme.spacing.f};
`;

interface IProps {
  close: () => void;
  addedOn: Date;
  ongoingSurveys: SurveyGroupSurveysData[];
  onSubmit: (runOngoingSteps: string[], addedOn: Date) => void;
  selectedRespondents?: string[];
}

export type SurveyGroupSurveysData = Pick<
  NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['surveys'][0],
  'id'
> & {
  topic: string;
  startDate: string;
  daysFromStart: string;
  status: string;
};

export const AddSGRespondentToOngoingSurveys: FC<IProps> = ({
  close,
  addedOn,
  ongoingSurveys,
  onSubmit,
  selectedRespondents,
}) => {
  const { t } = useTranslation('addSurveyGroupRespondentModal');
  const [runOngoingSteps, setRunOngoingSteps] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const onOngoingPhaseChange = (actualStepId: string) => () => {
    const updatedRunOngoingSteps = runOngoingSteps.filter((stepId) => stepId !== actualStepId);
    if (updatedRunOngoingSteps.length === runOngoingSteps.length) {
      updatedRunOngoingSteps.push(actualStepId);
    }

    return setRunOngoingSteps(updatedRunOngoingSteps);
  };

  return (
    <BaseModal
      onClose={close}
      onPrimaryAction={async () => {
        setLoading(true);
        await onSubmit(runOngoingSteps, addedOn);
        setLoading(false);
        close();
      }}
      title={t('addSurveyGroupRespondentModal:title')}
      primaryActionText={t('addSurveyGroupRespondentModal:submit')}
      secondaryActionText={t('surveyOverviewRow:cancel')}
      onSecondaryAction={close}
      primaryActionDisabled={loading}
      primaryBtnDataCy="btn-modal-submit"
    >
      <Content>
        <BodyMedium margin={`0 0 ${theme.spacing.f} 0`}>{t('addSurveyGroupRespondentModal:text')}</BodyMedium>
        <BodyMedium margin={`0 0 ${theme.spacing.f} 0`}>
          {selectedRespondents && (
            <>
              {t('addSurveyGroupRespondentModal:numberOfAdded')} <strong>{selectedRespondents?.length}</strong> <br />
            </>
          )}
          {t('addSurveyGroupRespondentModal:addedOn')}{' '}
          <strong>{getLocalizedDateWithoutLeadingZeroes(addedOn!, i18n.language)}</strong>
        </BodyMedium>
        {ongoingSurveys.map((row) => (
          <Checkbox
            selected={runOngoingSteps.includes(row.id)}
            onToggle={onOngoingPhaseChange(row.id)}
            text={
              <BodyMedium>
                {t('addSurveyGroupRespondentModal:ongoingPhase')} <strong>{row.topic}</strong>
              </BodyMedium>
            }
          />
        ))}
      </Content>
    </BaseModal>
  );
};
