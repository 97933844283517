import { useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, calculateValidPeriod, HeadingLarge, ProductHistory } from '@arnold/common';
import { ProductCode } from '@arnold/core';
import { PageWrapper } from '../../components/Common';
import { EDITION } from '../../lib/common';
import {
  IsAccessTokenValidQuery,
  OrganizationQuery,
  useGetPricelistQuery,
  useGetRespondentsQuery,
} from '../../generated/hooks';
import i18n from '../../translations/i18n';
import { GraphQLErrorCard, Loading } from '../../components';
import OrderForm from './OrderForm';

interface ILocationState {
  productVersion?: ProductHistory;
  edition?: keyof typeof EDITION;
}

interface IProps {
  user: NonNullable<IsAccessTokenValidQuery['isAccessTokenValid']['user']>;
  organization: NonNullable<OrganizationQuery['organization']>;
}

export const OrderEditionScreen = (props: RouteComponentProps<any> & IProps) => {
  const location = useLocation<ILocationState>();
  const [t] = useTranslation('editionSettings');

  const {
    match: {
      params: { edition },
    },
    user,
    organization,
  } = props;

  const {
    loading,
    data: pricelistData,
    error,
  } = useGetPricelistQuery({
    variables: {
      language: i18n.language,
    },
  });

  const {
    loading: respondentsLoading,
    data: respondentsData,
    error: respondentsError,
  } = useGetRespondentsQuery({
    variables: { languageCode: i18n.language },
  });

  const { productVersion } = location?.state || {};

  const activeProductVersion = calculateValidPeriod(organization.productVersionHistory || []);

  useEffect(() => {
    if (
      (edition && ![EDITION.ESSENTIAL.name, EDITION.BUSINESS.name, EDITION.ENTERPRISE.name].includes(edition)) ||
      (productVersion && productVersion.product === EDITION.FREE.name)
    ) {
      props.history.push('/settings/pricing');
    }
  });

  if (error || respondentsError) {
    return <GraphQLErrorCard />;
  }

  if (loading || respondentsLoading) {
    return <Loading />;
  }

  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb
        classList={'mt-n6'}
        items={[
          { title: t('AuthorizedPage:settings'), link: '/settings' },
          {
            title: t('pricelist'),
            link: '/settings/pricing',
            locationState: edition ? undefined : { productVersion: activeProductVersion },
          },
          { title: t('order') },
        ]}
      />
      <HeadingLarge>{t('order')}</HeadingLarge>
      {organization.productVersionHistory && (
        <OrderForm
          productVersion={edition ? null : productVersion || activeProductVersion}
          organizationId={organization!.id}
          productHistory={organization!.productVersionHistory}
          edition={edition}
          pricelist={pricelistData?.getPricelist || []}
          user={user}
          withTeamioArnoldOnboarding={organization.productVersionHistory?.some(
            (pvh) => pvh.product === ProductCode.Teamio_Arnold,
          )}
          respondents={respondentsData?.user.respondents || []}
        />
      )}
    </PageWrapper>
  );
};

export default OrderEditionScreen;
