import { theme } from '@arnold/common';
import styled from '@emotion/styled';

export const TableContainer = styled.div`
  margin-top: ${theme.spacing.d};
`;

export const TableHead = styled.div`
  min-width: 75px;
`;

export const Line = styled.div`
  height: 1px;
  background-color: ${theme.colors.borderSeparator.default};
  margin: ${theme.spacing.f} 0;
`;
