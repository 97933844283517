import { FC, PropsWithChildren } from 'react';
import { Tooltip as TippyTooltip, TooltipProps as TippyTooltipProps } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

type TooltipComponentProps = Pick<
  TippyTooltipProps,
  'title' | 'position' | 'animation' | 'multiple' | 'disabled' | 'className' | 'html'
>;

export const Tooltip: FC<PropsWithChildren<TooltipComponentProps>> = ({
  title,
  children,
  position = 'bottom',
  animation = 'none',
  multiple,
  disabled = false,
  className,
  html,
}) => (
  // @ts-ignore - children-not-in-props type error
  <TippyTooltip
    className={className}
    title={title}
    html={html}
    position={position}
    arrow={false}
    trigger="mouseenter"
    animation={animation}
    animateFill={false}
    followCursor={true}
    delay={200}
    duration={0}
    // @ts-ignore - zIndex is missing in types
    zIndex={1997483633}
    multiple={multiple}
    disabled={disabled}
  >
    {children}
  </TippyTooltip>
);
