import { REPORT_LINK } from '@arnold/core';
import { getFrontendConfigValue } from '@arnold/common';
import { MetricAnswer } from '../../generated/hooks';
import { isLoggedUserSysAdmin } from '../../lib/helpers';

export const getFeelingsAggregated = (metricQuestions: MetricAnswer[]) => {
  const aggregatedMetrics = new Map<number, number>();
  metricQuestions.forEach((metrics) => {
    if (!metrics.value) {
      return;
    }

    // TODO: current layout limited to 7 point scale of how do you feel question - make it scalable
    const tempValue = metrics.value > 7 ? 7 : metrics.value;

    aggregatedMetrics.set(tempValue, (aggregatedMetrics.get(tempValue) ?? 0) + 1);
  });

  return aggregatedMetrics;
};

export const getAverageMetricsFeelings = (aggregatedMetrics: Map<number, number>) => {
  const { sum, count } = Array.from(aggregatedMetrics.entries()).reduce(
    (average, metrics) => {
      average.sum += metrics[0] * metrics[1];
      average.count += metrics[1];
      return average;
    },
    { sum: 0, count: 0 },
  );

  return count ? sum / count : 0;
};

export const goToResults = (topicGroupId: string, reportAccessKey: string) =>
  window.open(
    `${getFrontendConfigValue('ARNOLD_URL')}/${REPORT_LINK.REPORT}/${reportAccessKey}?topic_group_id=${topicGroupId}${
      isLoggedUserSysAdmin() ? '&sysAdmin' : ''
    }`,
    '_blank',
  );

export const goToSGResults = (reportAccessKey: string) =>
  window.open(
    `${getFrontendConfigValue('ARNOLD_URL')}/${REPORT_LINK.REPORT_SEQUENCE}/${reportAccessKey}${
      isLoggedUserSysAdmin() ? '?sysAdmin' : ''
    }`,
    '_blank',
  );

export const goToRespondentResults = (reportAccessKey: string, respondentId: string, topicGroupId: string) =>
  window.open(
    `${getFrontendConfigValue('ARNOLD_URL')}/${
      REPORT_LINK.REPORT_SEQUENCE
    }/${reportAccessKey}/respondent/${respondentId}?selected_tg=${topicGroupId}${
      isLoggedUserSysAdmin() ? '&sysAdmin' : ''
    }`,
    '_blank',
  );
export const goToTopicDetail = (topicId: number) => window.open(`/topic/${topicId}`, '_self');
