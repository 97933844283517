import { Query } from '@apollo/client/react/components';
import { HeadingLarge } from '@arnold/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageAndLocalizationCard, Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import OrganizationCard from '../../components/OrganizationCard';
import { getOrganizationSettings } from '../../graphql/queries';
import {
  Features,
  OrganizationQuery,
  OrganizationSettingQuery,
  OrganizationSettingQueryVariables,
  UserQuery,
} from '../../generated/hooks';
import EditionOverviewCard from './EditionOverviewCard';
import ManageFeaturesCard from './ManageFeaturesCard';
import OrganizationAdminsCard from './OrganizationAdminsCard';
interface SettingsScreenProps {
  user: UserQuery['user'];
  organization: NonNullable<OrganizationQuery['organization']>;
}

const SettingsScreen: FC<SettingsScreenProps> = (props) => {
  const { t } = useTranslation('settings');

  if (props.organization && props.organization) {
    return (
      <Query<OrganizationSettingQuery, OrganizationSettingQueryVariables>
        query={getOrganizationSettings}
        variables={{ organizationId: props.organization.id }}
      >
        {({ loading, error, data }) => {
          if (loading) {
            return <Loading />;
          }
          if (error) {
            return null;
          }
          if (data && data.organization) {
            const organization = data.organization;
            return (
              <PageWrapper data-cy="admin-page-content">
                <HeadingLarge data-icom="heading-organization-settings">{t('header')}</HeadingLarge>
                {data.organization.productVersionHistory && (
                  <EditionOverviewCard productHistory={data.organization.productVersionHistory} />
                )}
                {organization.featureNames && (
                  <ManageFeaturesCard
                    features={organization.featureNames as (Features | null)[]}
                    organizationId={organization.id}
                  />
                )}
                {organization.config && (
                  <LanguageAndLocalizationCard
                    product={organization.productVersion?.product}
                    language={organization.primaryLanguageCode}
                    languages={organization.languages}
                    timezone={organization.config.timezone}
                    countryCallingCode={organization.config.countryCallingCode || '420'}
                    organizationId={organization.id}
                  />
                )}
                <OrganizationCard customName={organization.name} systemName={organization.systemName} />
                <OrganizationAdminsCard
                  organizationAdmins={data.organization.organizationAdmins}
                  allRespondents={data.organization.allRespondents}
                  organizationId={data.organization.id}
                  userId={props.user.id}
                />
              </PageWrapper>
            );
          }
          return null;
        }}
      </Query>
    );
  }

  return null;
};

export default SettingsScreen;
