import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { NewProcessSurvey } from '../../components/NewProcessSurvey';
import { TopicGroupTypeCode } from '../../generated/hooks';

type ParamsProps = {
  subjectId: string;
};

export const CreateOnboardingScreen: FC<RouteComponentProps<ParamsProps>> = ({ match }) => {
  const { params } = match;
  const { t } = useTranslation('onboarding');
  return (
    <NewProcessSurvey
      title={t('newOnboarding')}
      topicGroupId={params.subjectId}
      typeCode={TopicGroupTypeCode.Onboarding}
    />
  );
};
