import { notEmpty, Tooltip } from '@arnold/common';
import styled from '@emotion/styled';
import { TFunction } from 'i18next';
import { ComponentType, FC } from 'react';
import { NodeEditor } from 'rete';
import { AreaPlugin, BaseAreaPlugin } from 'rete-area-plugin';
import { ContextMenuPlugin } from 'rete-context-menu-plugin';
import { Customize } from 'rete-react-plugin/_types/presets/context-menu/types';
import { useTranslation } from 'react-i18next';
import { IsAccessTokenValidQuery, QuestionType } from '../../generated/hooks';
import { baseItemStyles, baseMenuStyles } from '../StyledComponents';
import { DataNode, DataNodeWithDimensions, NodeData, Schemes } from './dataNode';
import { generateIndex } from './utils';
import { COMMENT_TYPE, ITEMS_WITH_TOOLTIP, MENU_TERMS } from './constants';
import { AreaExtra, CustomNodeEditor, nodeOnChange, nodeRemoveConnectionFromOutput } from './rete';
import { copySelectedNodes, deleteSelectedNodes, hasCoppiedNodes, pasteSelectedNodes } from './copyPasteDelete';

const MenuWrapper = (props: any) => <div {...{ ...props, className: props.className + ' contextMenu' }} />;

const Menu = styled(MenuWrapper)`
  ${baseMenuStyles}
`;

const Item = styled.div`
  ${baseItemStyles}
`;

type ItemWithTooltipProps = {
  children: [string, boolean];
};

type Position = {
  x: number;
  y: number;
};

export function createContextMenuCustomizedComponents(): Customize {
  return {
    main: () => Menu as unknown as ComponentType,
    item: () => ItemWithTooltip as ComponentType,
  };
}

const ItemWithTooltip: FC<ItemWithTooltipProps> = ({ children, ...args }) => {
  const { t } = useTranslation('topicEditor');

  const term = children[0];
  if (ITEMS_WITH_TOOLTIP.includes(term)) {
    return (
      <Tooltip title={t(`${term}Tooltip`)} key={term}>
        <Item children={t(term)} {...args} />
      </Tooltip>
    );
  }
  return <Item children={t(term)} {...args} />;
};

const addNodeToEditor = async (node: DataNode, editor: CustomNodeEditor | NodeEditor<Schemes>) => {
  await editor.addNode(node as DataNodeWithDimensions);
  if (editor instanceof CustomNodeEditor) {
    editor.nodeSelectorInterface.select(node.id, false);
  }
};

const createNode = async (
  type: QuestionType | typeof COMMENT_TYPE,
  editor: CustomNodeEditor,
  area: BaseAreaPlugin<Schemes, any> | AreaPlugin<Schemes, AreaExtra>,
  position: Position,
  t: TFunction,
  topicId: string,
  search?: string,
) => {
  let nodeData: NodeData = {
    questionIndex: generateIndex(),
    isRequired: false,
    questionText: '',
    choices: {},
    type,
    input: true,
    outputs: { 'opt-0': null },
    position: [Math.floor(position.x), Math.floor(position.y)],
    isMetric: false,
    isQuestionTrigger: false,
    reportOrder: getBiggestOrderFromNodes(editor.getNodes()) + 1,
    onChange: () => nodeOnChange(editor),
    onDataChange: (data) => {
      node.data = data;
      nodeOnChange(editor);
    },
    removeConnectionFromOutput: (node, outputName) => nodeRemoveConnectionFromOutput(node, outputName, editor),
    onDelete: () => {
      const removedNode = editor.getNode(node.id);
      editor.removeNode(node.id);
      return removedNode;
    },
    topicId,
    search,
    commentGroupId: undefined,
    addHistoryAction: (action) => {
      editor.historyPlugin.add(action);
    },
  };

  if (type === QuestionType.Freetext) {
    nodeData = { ...nodeData, isRequired: true };
  }

  if (type === QuestionType.Select) {
    nodeData = {
      ...nodeData,
      choices: { 'opt-0': '' },
      range: {
        min: 1,
        max: 1,
      },
    };
  }

  if (type === QuestionType.Multiselect) {
    nodeData = {
      ...nodeData,
      outputs: { 'opt-0': null, 'opt-1': null },
      choices: { 'opt-0': '', 'opt-1': '' },
      range: {
        min: 1,
        max: 2,
      },
    };
  }

  if (type === QuestionType.PersonMultiselect) {
    nodeData = {
      ...nodeData,
      range: {
        min: 0,
        max: 1,
      },
    };
  }

  if (type === COMMENT_TYPE) {
    nodeData = {
      ...nodeData,
      outputs: {},
      input: false,
      reportOrder: null,
    };
  }

  const node = new DataNode(nodeData, t, undefined, undefined);
  await addNodeToEditor(node, editor);
  area.translate(node.id, position);
};

export const getMenuItems = (
  t: TFunction,
  editor: CustomNodeEditor,
  area: BaseAreaPlugin<Schemes, any> | AreaPlugin<Schemes, AreaExtra>,
  topicId: string,
  position?: Position,
  user?: IsAccessTokenValidQuery['isAccessTokenValid']['user'],
  search?: string,
) => {
  const menuPosition = area.area.pointer;
  const nodePosition = position ? position : menuPosition;

  return [
    {
      label: MENU_TERMS.TELL,
      key: MENU_TERMS.TELL,
      handler: () => createNode(QuestionType.Tell, editor, area, nodePosition, t, topicId),
    },
    {
      label: MENU_TERMS.FREETEXT,
      key: MENU_TERMS.FREETEXT,
      handler: () => createNode(QuestionType.Freetext, editor, area, nodePosition, t, topicId),
    },
    {
      label: MENU_TERMS.SELECT,
      key: MENU_TERMS.SELECT,
      handler: () => createNode(QuestionType.Select, editor, area, nodePosition, t, topicId),
    },
    {
      label: MENU_TERMS.MULTISELECT,
      key: MENU_TERMS.MULTISELECT,
      handler: () => createNode(QuestionType.Multiselect, editor, area, nodePosition, t, topicId),
    },
    {
      label: MENU_TERMS.PERSONAL_MULTISELECT,
      key: MENU_TERMS.PERSONAL_MULTISELECT,
      handler: () => createNode(QuestionType.PersonMultiselect, editor, area, nodePosition, t, topicId),
    },
    {
      label: MENU_TERMS.COMMENT,
      key: MENU_TERMS.COMMENT,
      handler: () => createNode(COMMENT_TYPE, editor, area, nodePosition, t, topicId, search),
    },
    hasCoppiedNodes() && {
      label: t('paste'),
      key: 'PASTE',
      handler: async () => {
        pasteSelectedNodes(editor, t, area.area.pointer);
      },
    },
  ].filter(notEmpty);
};

export function createContextMenuPlugin(
  showDeleteNodeModal: (onSubmit: () => void) => void,
  t: TFunction,
  topicId: string,
  onCommentDelete: (id: string) => void,
  user?: IsAccessTokenValidQuery['isAccessTokenValid']['user'],
  search?: string,
): ContextMenuPlugin<Schemes> {
  const contextMenu = new ContextMenuPlugin<Schemes>({
    items(context, plugin) {
      const area = plugin.parentScope<BaseAreaPlugin<Schemes, any>>(BaseAreaPlugin);
      const editor = area.parentScope<CustomNodeEditor>(CustomNodeEditor);
      if (editor.customNodeSelector.entities.size > 1) {
        // if there is many nodes selected, it does not matter where we click, it will always show delete and copy
        return {
          searchBar: false,
          list: [
            {
              label: t('delete'),
              key: 'DELETE',
              dataCy: 'btn-node-delete',
              handler: async () => {
                deleteSelectedNodes(editor, onCommentDelete);
              },
            },
            {
              label: t('clone'),
              key: 'CLONE',
              dataCy: 'btn-node-clone',
              handler: async () => {
                const nodes = copySelectedNodes(editor, false);
                pasteSelectedNodes(editor, t, undefined, nodes);
              },
            },
            {
              label: t('copy'),
              key: 'COPY',
              dataCy: 'btn-node-copy',
              handler: async () => {
                copySelectedNodes(editor);
              },
            },
            context === 'root' &&
              hasCoppiedNodes() && {
                label: t('paste'),
                key: 'PASTE',
                dataCy: 'btn-node-paste',
                handler: async () => {
                  pasteSelectedNodes(editor, t, area.area.pointer);
                },
              },
          ].filter(notEmpty),
        };
      }
      if (context === 'root') {
        return {
          searchBar: false,
          list: getMenuItems(t, editor, area, topicId, undefined, user, search),
        };
      }
      const node = editor.getNode(context.id);
      if (node.data.type === COMMENT_TYPE) {
        return {
          searchBar: false,
          list: [],
        };
      }
      const canClone = node.data.type !== QuestionType.Ending;
      const canDelete = canClone && node.data.input !== false;

      const deleteNode = async () => {
        const nodeId = context.id;
        const connections = editor.getConnections().filter((c) => {
          return c.source === nodeId || c.target === nodeId;
        });

        for (const connection of connections) {
          await editor.removeConnection(connection.id);
        }
        await editor.removeNode(nodeId);
      };
      if (!canClone && !canDelete) return null as any;
      return {
        searchBar: false,
        list: [
          canDelete && {
            label: t('delete'),
            key: 'DELETE',
            dataCy: 'btn-node-delete',
            handler: async () => {
              if (node.lockEdit) {
                showDeleteNodeModal(() => deleteNode());
              } else {
                return deleteNode();
              }
            },
          },
          canClone && {
            label: t('clone'),
            key: 'CLONE',
            dataCy: 'btn-node-clone',
            handler: async () => {
              const originalNode = editor.getNode(context.id);
              const node = new DataNode(
                {
                  ...originalNode.data,
                  choices: { ...originalNode.data.choices },
                  answersToHighlight: originalNode.data.answersToHighlight
                    ? { ...originalNode.data.answersToHighlight }
                    : undefined,
                  outputs: Object.keys(originalNode.data.outputs).reduce(
                    (acc, outputKey) => ({ ...acc, [outputKey]: null }),
                    {},
                  ),
                  questionIndex: generateIndex(),
                  reportOrder: getBiggestOrderFromNodes(editor.getNodes()) + 1,
                  input: true,
                  isMetric: false,
                  isQuestionTrigger: false,
                  originalQuestionDefinition: undefined,
                },
                t,
              );

              await addNodeToEditor(node, editor);
              area.translate(node.id, area.area.pointer);
            },
          },
          {
            label: t('copy'),
            key: 'COPY',
            dataCy: 'btn-node-copy',
            handler: async () => {
              editor.nodeSelectorInterface.select(node.id, false);
              copySelectedNodes(editor);
            },
          },
        ].filter(notEmpty),
      };
    },
  });
  return contextMenu;
}

export const getBiggestOrderFromNodes = (nodes: DataNode[]): number => {
  return nodes.reduce(
    (previousLargestOrder, currentNode) =>
      (currentNode.data.reportOrder ?? 1) > previousLargestOrder
        ? currentNode.data.reportOrder ?? 1
        : previousLargestOrder,
    0,
  );
};
