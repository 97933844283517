import { createEventData, HeadingLarge, pushToDataLayer, useToast } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { Link, Redirect, RouteComponentProps, useLocation } from 'react-router-dom';
import { GraphQLErrorCard, Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import { getOrganizationTeams, getSurveyGroups } from '../../graphql/queries';
import { getContact, getRawContact } from '../../lib/common';

import RespondentForm from '../../components/Forms/RespondentForm/RespondentForm';
import auth from '../../lib/auth';
import {
  ContactSource,
  ContactType,
  OrganizationQuery,
  OrganizationTeamsQuery,
  UpdateRespondentMutation,
  useOrganizationSettingQuery,
  useUpdateRespondentMutation,
} from '../../generated/hooks';
import { useGetOrganizationTeams } from '../Surveys/utils';

interface ILocationState {
  respondent: NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'][0];
}

interface IProps extends RouteComponentProps {
  organization: NonNullable<OrganizationQuery['organization']>;
}

const UpdateRespondentScreen = ({ organization }: IProps) => {
  const [t] = useTranslation(['updateRespondent']);
  const { addToast } = useToast();
  const location = useLocation<ILocationState>();
  const { respondent } = location.state;

  const {
    data: organizationSettings,
    loading: organizationSettingsLoading,
    error: organizationSettingsError,
  } = useOrganizationSettingQuery({
    variables: { organizationId: organization.id },
  });

  const { organizationTeams, loading: teamsLoading, error: teamsError } = useGetOrganizationTeams(organization.id);

  const workEmail = getContact(respondent, ContactType.Email, ContactSource.Primary);
  const personalEmail = getContact(respondent, ContactType.Email, ContactSource.Respondent);
  const phone = getRawContact(respondent, ContactType.Sms, ContactSource.Respondent);

  const team = organizationTeams?.find((t) => {
    return t.members.some((member) => member.id === respondent.id);
  });

  const handleMutationCompleted = (data: UpdateRespondentMutation) => {
    addToast(
      t('updateSuccess', {
        respondentName: `${data.updateRespondent.firstname} ${data.updateRespondent.surname}`,
      }),
    );

    const user = auth.getUser();
    pushToDataLayer({
      userId: user!.id,
      orgId: organization.id,
      event: 'ux.respondent-update-success',
      ...createEventData('organization', 'updateRespondentSuccess', 'organization update respondent success'),
    });
  };

  const handleMutationError = () => {
    const user = auth.getUser();
    pushToDataLayer({
      userId: user!.id,
      orgId: organization.id,
      event: 'ux.respondent-update-error',
      ...createEventData('organization', 'updateRespondentError', 'organization update respondent error'),
    });
  };

  const [updateRespondentMutation, { loading }] = useUpdateRespondentMutation({
    onCompleted: handleMutationCompleted,
    onError: handleMutationError,
    refetchQueries: [getSurveyGroups, getOrganizationTeams],
  });

  if (!location.state || !location.state.respondent) {
    return <Redirect to="/teams" />;
  }

  if (organizationSettingsError || teamsError) {
    return <GraphQLErrorCard />;
  }
  if (organizationSettingsLoading || !organizationSettings || teamsLoading || !organizationTeams) {
    return <Loading />;
  }

  return (
    <PageWrapper data-cy="admin-page-content">
      <p className="mt-n6 font-size-xs">
        <Link to={'/teams'} data-cy={'back-to-employees-link'}>
          &lt; {t('backToEmployees')}
        </Link>
      </p>
      <HeadingLarge>{t('editEmployee')}</HeadingLarge>
      {loading && <Loading />}
      <RespondentForm
        respondent={respondent}
        organization={organization}
        organizationTeams={organizationTeams || []}
        saveRespondent={updateRespondentMutation}
        workEmail={workEmail}
        personalEmail={personalEmail}
        phone={phone?.phoneNumber?.nationalNumber || ''}
        countryCallingCode={
          phone?.phoneNumber?.countryCallingCode || organizationSettings.organization?.config?.countryCallingCode
        }
        team={team}
      />
    </PageWrapper>
  );
};

export default UpdateRespondentScreen;
