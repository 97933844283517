import { ActionMenuIcon, Tooltip, theme } from '@arnold/common';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { DndProvider, useDragDropManager } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ClassicScheme, Drag, RenderEmit } from 'rete-react-plugin';
import { Control, Output, Socket } from 'rete/_types/presets/classic';
import { ReactComponent as ErrorWarningIcon } from '../../../assets/images/ErrorWarning.svg';
import { ReactComponent as LockedIcon } from '../../../assets/images/Locked.svg';
import { QuestionType } from '../../../generated/hooks';
import { OptionControlData } from '../controls/OptionControl';
import { Schemes } from '../dataNode';
import { ValidationError } from '../validation';
import { COMMENT_TYPE } from '../constants';
import { Draggable } from './Draggable';
import { RefControl } from './RefControl';
import { RefSocket } from './RefSocket';

type NodeStylesData = {
  width?: number;
  height?: number;
  readOnly?: boolean;
  errors: ValidationError[];
  lockEdit: boolean;
};

const nodeWidth = 250;

export const NodeStyles = styled.div<
  NodeStylesData & { selected: boolean; styles?: (props: any) => any; isComment: boolean }
>`
  background: ${theme.background.primary};
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;
  width: ${(props) => (Number.isFinite(props.width) ? `${props.width}px` : `${nodeWidth}px`)};
  height: auto !important;
  padding-bottom: 6px;
  position: relative;
  user-select: none;
  line-height: initial;
  font-size: ${theme.typography.body.small.regular.fontSize};
  box-shadow: ${theme.shadows.elevationContentBlock};
  transition: all 0.2s ease-in-out;
  border: ${(props) => (props.isComment ? `${theme.spacing.a} solid ${theme.colors.emotionWarning.default}` : 'none')};
  &:hover {
    box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.05);
  }
  .title {
    color: ${(props) => (props.isComment ? theme.colors.text.primary : theme.colors.textInverted.primary)};
    background-color: ${(props) =>
      props.isComment ? theme.colors.emotionWarning.default : theme.colors.backgroundDark.default};
    border-top-left-radius: 9px;
    transition: background-color 0.2s ease-in-out;
    border-top-right-radius: 9px;
    font-family: sans-serif;
    font-size: ${theme.typography.body.small.regular.fontSize};
    padding: ${theme.spacing.d} ${theme.spacing.f};
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .iconContainer {
      align-self: stretch;
      display: flex;
      align-items: center;
      svg {
        height: 12px;
        path,
        circle {
          fill: white;
        }
      }
    }
    .threeDots {
      svg {
        transform: rotate(90deg);
        height: ${theme.spacing.f};
        scale: 1.5;
        & circle {
          fill: ${(props) => (props.isComment ? theme.colors.text.primary : theme.colors.textInverted.primary)};
        }
      }
    }
  }
  .titleText {
    margin-left: ${(props) => (props.isComment ? '0' : '-25px')};
    font-weight: normal;
    display: flex;
    & svg {
      margin-bottom: ${theme.spacing.b};
      margin-right: ${theme.spacing.c};
    }
  }
  &:focus-within {
    box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.05) !important;
    .title {
      background-color: ${(props) =>
        props.isComment ? theme.colors.emotionWarning.default : theme.colors.actionPrimary.default};
    }
  }
  ${(props) =>
    props.selected &&
    css`
      box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.05) !important;
      .title {
        background-color: ${props.isComment ? theme.colors.emotionWarning.active : theme.colors.actionPrimary.default};
      }
    `}
  ${(props) =>
    props.errors.length > 0 &&
    css`
      .title {
        background-color: ${theme.colors.emotionDanger.default} !important;
      }
    `}
  ${(props) => css`
    &:focus-within {
      box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.05) !important;
      .title {
        background-color: ${props.isComment
          ? theme.colors.emotionWarning.active
          : theme.colors.actionPrimary.default} !important;
      }
    }
  `}
  ${(props) =>
    props.selected &&
    !props.isComment &&
    css`
      box-shadow: 0px 10px 14px 0px rgba(0, 0, 0, 0.05) !important;
      .title {
        background-color: ${theme.colors.actionPrimary.default} !important;
      }
    `}
  ${(props) =>
    props.errors.map(
      (error) => css`
        [data-error-part='${error.part}'] {
          .textAreaControl,
          .optionControl,
          input[name='min'],
          input[name='max'] {
            outline: 1px solid ${theme.colors.emotionDanger.default} !important;
            border: none !important;
            box-shadow: none !important;
            &:focus,
            &:focus-within {
              box-shadow: 0 0 0rem 0.2rem #cc454d3f !important;
            }
          }
          .reportOrderInput {
            background-color: white !important;
            color: ${theme.colors.backgroundDark.default};
          }
          div[title='socket'] {
            background-color: ${theme.colors.emotionDanger.default} !important;
          }
        }
      `,
    )}
    ${(props) =>
    props.lockEdit &&
    css`
      pointer-events: none;
      .title {
        background-color: ${theme.colors.text.disabled} !important;
      }
      .output-socket,
      .input-socket,
      .errorTooltip {
        pointer-events: auto;
      }
    `}
  .reportOrderInput {
    padding: 2px !important;
    margin: 0px !important;
    width: ${theme.spacing.h};
    outline: none;
    color: white;
    background-color: transparent !important;
  }
  .reportOrderInput:hover,
  .reportOrderInput:focus {
    color: ${theme.colors.backgroundDark.default};
    background-color: white !important;
  }
  .textAreaSpan,
  input[type='text'] {
    transition: all 0.2s ease-in-out;
    padding: 8px;
    border: none;
    border-radius: 5px !important;
    background-color: ${theme.colors.backgroundBasic.default};
  }
  .textAreaSpan {
    display: block;
    resize: none;
    overflow: hidden;
    margin: ${theme.spacing.d} ${theme.spacing.f};
    padding: ${theme.spacing.d} ${theme.spacing.f};
    cursor: text;
    border: 1px solid ${theme.colors.backgroundBasic.default};
    outline: none;

    &:focus {
      border-color: ${theme.colors.borderMain.active};
      box-shadow: 0 0 0 0.2rem rgba(4, 183, 239, 0.25);
    }
  }
  input[type='text'] {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .checkboxAndLabel {
    margin: ${theme.spacing.f};
    margin-top: 16px;
    display: flex;
  }
  .horizontal {
    display: flex;
    justify-content: space-between;
    & > span {
      width: 100%;
    }
  }
  .inputs {
    margin-top: ${theme.spacing.d};
    margin-bottom: ${theme.spacing.d};
  }
  .controlsAndOutputs {
    flex-grow: 1;
  }
  .controlAndOutput {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    margin-bottom: ${theme.spacing.d};
  }
  .controlAndOutput .control {
    flex-grow: 1;
  }
  .output {
    text-align: right;
  }
  .input {
    text-align: left;
  }
  div[title='socket'] {
    width: 16px;
    height: 16px;
    background-color: #04b7ef;
    border: none;
    transition: all 0.3s;
  }
  .output-socket {
    text-align: right;
    margin-right: -10px;
    margin-left: 7px;
    display: inline-block;
  }
  .input-socket {
    text-align: left;
    margin-left: -10px;
    margin-right: 7px;
    display: inline-block;
  }
  .output-socket > div,
  .input-socket > div {
    padding: 0px !important;
  }
  .input-control {
    z-index: 1;
    vertical-align: middle;
    display: inline-block;
  }
  .control {
    display: block;
  }

  hr {
    margin: ${theme.spacing.f};
  }

  ${(props) => props.styles && props.styles(props)}
`;

function sortByIndex<T extends [string, undefined | { index?: number }][]>(entries: T) {
  entries.sort((a, b) => {
    const ai = a[1]?.index || 0;
    const bi = b[1]?.index || 0;

    return ai - bi;
  });
}

type OptionsProps<S extends ClassicScheme> = {
  controlsWithOutputs: {
    control: [string, Control | undefined];
    output: [string, Output<Socket> | undefined];
  }[];
  readOnly: boolean;
  id: string;
  emit: RenderEmit<S>;
};

function Options<Scheme extends ClassicScheme>({ controlsWithOutputs, readOnly, id, emit }: OptionsProps<Scheme>) {
  const dragDropManager = useDragDropManager();
  const monitor = dragDropManager.getMonitor();
  const [isSomethingElseDragged, setIsSomethingElseDragged] = useState(false);

  useEffect(
    () =>
      monitor.subscribeToStateChange(() => {
        setIsSomethingElseDragged(monitor.isDragging());
      }),
    [monitor],
  );

  return (
    <div className={classNames('controlsAndOutputs', isSomethingElseDragged && 'isSomethingDragged')}>
      {controlsWithOutputs.map(({ control: [key, control], output: [_key, output] }, index) => {
        if (control == null) throw new Error("Control with output can't have undefined control");
        if (output == null) throw new Error("Control with output can't have undefined output");
        const typedControl = control as OptionControlData;
        return (
          <div className="controlAndOutput justify-content-end" key={key}>
            <Draggable
              index={index}
              id={typedControl.id}
              move={typedControl.onMove}
              readOnly={readOnly}
              isSomethingDragged={isSomethingElseDragged}
            >
              <RefControl
                name="control"
                emit={emit}
                payload={control}
                data-cy={`control-${key}`}
                data-error-part={`choice-${key}`}
              />
            </Draggable>
            <div className="output" data-cy={`output-${key}`} data-error-part={`output-${key}`}>
              {output.label && (
                <div className="output-title" data-cy="output-title">
                  {output.label}
                </div>
              )}
              <RefSocket
                name="output-socket"
                side="output"
                socketKey={key}
                nodeId={id}
                emit={emit}
                payload={output.socket}
                data-cy="output-socket"
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

type Props<S extends ClassicScheme> = {
  data: S['Node'];
  styles?: () => any;
  emit: RenderEmit<S>;
};

// eslint-disable-next-line max-statements
export function CustomNode(props: Props<Schemes>) {
  const inputs = Object.entries(props.data.inputs);
  const outputs = Object.entries(props.data.outputs);
  const controls = Object.entries(props.data.controls).filter(
    (entries) => !['reportOrder', 'addOption', 'questionText'].includes(entries[0]),
  );
  const errors = props.data.errors;
  const reportOrderControl = props.data.controls.reportOrder!;
  const addOptionControl = props.data.controls.addOption;
  const questionTextControl = props.data.controls.questionText!;
  const selected = props.data.selected || false;
  const { id, label, width, height, data } = props.data;

  sortByIndex(inputs);
  sortByIndex(outputs);
  sortByIndex(controls);

  const controlsWithOutputs: { control: (typeof controls)[number]; output: (typeof outputs)[number] }[] = [];
  [...controls].forEach((controlEntry) => {
    const outputEntry = outputs.find((outputEntry) => outputEntry[0] === controlEntry[0]);
    if (outputEntry) {
      controlsWithOutputs.push({ control: controlEntry, output: outputEntry });
      controls.splice(controls.indexOf(controlEntry), 1); // remove it from controls array
      outputs.splice(outputs.indexOf(outputEntry), 1); // remove it from outputs array
    }
  });

  const errorTooltipContent = (
    <div>
      {errors.map((error) => (
        <div key={error.part + error.error}>{props.data.t(error.error + 'Short')}</div>
      ))}
    </div>
  );

  return (
    <NodeStyles
      selected={selected}
      width={width}
      height={height}
      errors={errors}
      lockEdit={props.data.lockEdit}
      styles={props.styles}
      data-cy={`node-${reportOrderControl.initialValue}`}
      data-icom={`node-${data.type}`}
      isComment={data.type === COMMENT_TYPE}
    >
      <div className="title" data-cy="title">
        {data.type !== COMMENT_TYPE && (
          <RefControl
            name="control"
            emit={props.emit}
            payload={reportOrderControl}
            data-cy={`control-reportOrder`}
            data-error-part={'reportOrder'}
          />
        )}
        <span className="titleText">
          {errors.length > 0 && (
            <div className="iconContainer">
              <Tooltip html={errorTooltipContent} className="errorTooltip">
                <ErrorWarningIcon />
              </Tooltip>
            </div>
          )}
          {label}
        </span>
        <div className="iconContainer">
          {props.data.lockEdit && (
            <div>
              <LockedIcon />
            </div>
          )}
          {!props.data.lockEdit &&
            props.data.data.type !== QuestionType.Ending &&
            props.data.data.type !== COMMENT_TYPE && (
              <Drag.NoDrag>
                <div
                  className="threeDots"
                  onClick={(e) => {
                    let target = e.target as HTMLElement;
                    if (target.tagName === 'circle') {
                      target = target.parentElement!;
                    }
                    const iconRight = target.getBoundingClientRect().right || 0;
                    const iconBottom = target.getBoundingClientRect().bottom || 0;
                    const contextMenuEvent = new PointerEvent('contextmenu', {
                      bubbles: true,
                      clientX: iconRight + 62,
                      clientY: iconBottom + 12,
                    });
                    e.stopPropagation();
                    e.preventDefault();
                    e.target.dispatchEvent(contextMenuEvent);
                  }}
                >
                  <ActionMenuIcon />
                </div>
              </Drag.NoDrag>
            )}
        </div>
      </div>
      <div className="inputs">
        {/* Inputs */}
        {inputs.map(
          ([key, input]) =>
            input && (
              <div className="input" key={key} data-cy={`input-${key}`} data-error-part={'input'}>
                <RefSocket
                  name="input-socket"
                  side="input"
                  socketKey={key}
                  nodeId={id}
                  emit={props.emit}
                  payload={input.socket}
                  data-cy="input-socket"
                  data-icom={'input-ariel'}
                />
                {input && (!input.control || !input.showControl) && input?.label && (
                  <div className="input-title" data-cy="input-title">
                    {input?.label}
                  </div>
                )}
                {input?.control && input?.showControl && (
                  <RefControl name="input-control" emit={props.emit} payload={input.control} data-cy="input-control" />
                )}
              </div>
            ),
        )}
      </div>
      {data.type !== COMMENT_TYPE && (
        <RefControl
          name="control"
          emit={props.emit}
          payload={questionTextControl}
          data-cy="control-questionText"
          data-error-part={`questionText`}
        />
      )}
      {data.type !== COMMENT_TYPE && Object.keys(controlsWithOutputs).length > 0 && <hr />}
      {/* Controls without outputs */}
      {controls.map(([key, control]) => {
        return control ? (
          <RefControl
            key={key}
            name="control"
            emit={props.emit}
            payload={control}
            data-cy={`control-${key}`}
            data-error-part={`choice-${key}`}
          />
        ) : null;
      })}
      {data.type !== COMMENT_TYPE && (
        <div className="horizontal">
          <Drag.NoDrag>
            <DndProvider backend={HTML5Backend}>
              <Options
                controlsWithOutputs={controlsWithOutputs}
                readOnly={!!props.data.readOnly}
                id={id}
                emit={props.emit}
              />
            </DndProvider>
          </Drag.NoDrag>
          {/* Outputs without controls */}
          {outputs.map(
            ([key, output]) =>
              output && (
                <div className="output" key={key} data-cy={`output-${key}`} data-error-part={`output-${key}`}>
                  {output?.label && (
                    <div className="output-title" data-cy="output-title">
                      {output?.label}
                    </div>
                  )}
                  <RefSocket
                    name="output-socket"
                    side="output"
                    socketKey={key}
                    nodeId={id}
                    emit={props.emit}
                    payload={output.socket}
                    data-cy="output-socket"
                    data-icom="output-socket"
                  />
                </div>
              ),
          )}
        </div>
      )}
      {addOptionControl ? (
        <RefControl name="control" emit={props.emit} payload={addOptionControl} data-cy="control-addOption" />
      ) : null}
    </NodeStyles>
  );
}
