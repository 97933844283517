import { BubbleView, SendIcon, theme } from '@arnold/common';
import { ReactComponent as Email } from '@arnold/common/lib/assets/icons/E-mail.svg';
import { ReactComponent as PersonMultiselect } from '@arnold/common/lib/assets/icons/Person-multiselect.svg';
import { ReactComponent as Phone } from '@arnold/common/lib/assets/icons/Phone.svg';
import { ReactComponent as TextFieldMandatory } from '@arnold/common/lib/assets/icons/Text-field-mandatory.svg';
import { ReactComponent as TextField } from '@arnold/common/lib/assets/icons/Text-field.svg';
import styled from '@emotion/styled/macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormInput, { FormIcon } from './FormComponents';
import { View } from './LayoutComponents';
import { QuestionDetail } from './topicStructure';
import { QUESTION_DEFINITION_TYPE } from './types';

const FreeTextWrap = styled.div`
  width: 500px;
  display: flex;
`;

const Input = styled.div`
  width: 100%;
  height: 38px !important;
  padding: 8px 16px;
  padding-right: 45px;
  outline: none;
  color: #3f3f3f;
  background-color: #f9f9f9;
  overflow-x: hidden;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  border: 1px solid #f9f9f9;
  border-radius: 23px;
  transition: border 0.15s;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const SendButton = styled.button(() => ({
  minWidth: '40px',
  width: '38px',
  height: '38px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: '8px',
  border: 'none',
  outline: 'none',
  borderRadius: '23px',
  fontWeight: 500,
  backgroundColor: theme.colors.backgroundCover.default,
  cursor: 'auto !important',
}));

const getIconAndText = (question: QuestionDetail, t: (key: string) => string) => {
  if (question.__typename === QUESTION_DEFINITION_TYPE.freetext) {
    if (question.optional) {
      return {
        icon: <TextField />,
        placeholder: t('textFieldPlaceholder'),
      };
    }
    return {
      icon: <TextFieldMandatory />,
      placeholder: t('textFieldMandatoryPlaceholder'),
    };
  }
  if (question.__typename === QUESTION_DEFINITION_TYPE.smsContact) {
    return {
      icon: <Phone />,
      placeholder: t('textFieldMandatoryPlaceholder'),
    };
  }
  if (question.__typename === QUESTION_DEFINITION_TYPE.emailContact) {
    return {
      icon: <Email />,
      placeholder: t('textFieldMandatoryPlaceholder'),
    };
  }
  if (question.__typename === QUESTION_DEFINITION_TYPE.multiselect) {
    return {
      icon: <PersonMultiselect />,
      placeholder: t('personMultiselectPlaceholder'),
    };
  }
  return null;
};

export interface IFakeInputProps {
  question: QuestionDetail;
  editMode: boolean;
}

const FakeInput: React.FC<IFakeInputProps> = ({ question, editMode }) => {
  const [t] = useTranslation('topicOverview');
  if (!editMode) {
    return question.__typename === QUESTION_DEFINITION_TYPE.freetext ||
      question.__typename === QUESTION_DEFINITION_TYPE.smsContact ||
      question.__typename === QUESTION_DEFINITION_TYPE.emailContact ? (
      <View>
        <BubbleView backgroundColor={theme.colors.chart.neutral[4]} left={true}>
          <FreeTextWrap>
            <Input>{t('sendActionMessage')}</Input>
            <SendButton>
              <SendIcon color={theme.colors.chart.neutral[4]} size={18} />
            </SendButton>
          </FreeTextWrap>
        </BubbleView>
      </View>
    ) : null;
  }
  const info = getIconAndText(question, t);
  if (!info) {
    return null;
  }
  return (
    <View>
      <FormIcon>{info.icon}</FormIcon>
      <FormInput value={''} placeholder={info?.placeholder} disabled={true} />
    </View>
  );
};

export default FakeInput;
