import { ApolloError } from '@apollo/client';
import { cleanArray, TableColumn } from '@arnold/common';
import { TFunction } from 'react-i18next';
import { GraphQLErrorCard, Loading } from '../../components';
import { useOrganizationTeamsQuery, useSurveyGroupsQuery } from '../../generated/hooks';
import { PATHS, SurveyData } from './types';

export const showResponseRate = (pathStatus?: string): boolean =>
  pathStatus === PATHS.ONGOING || pathStatus === PATHS.COMPLETED;
export const isPrepared = (pathStatus?: string): boolean => pathStatus === PATHS.PREPARED;

export const getColumns = (t: TFunction, pathStatus?: string): Array<TableColumn<SurveyData>> =>
  cleanArray([
    {
      key: 'topic',
      title: <span data-icom={'survey-title'}>{t('chat')}</span>,
      dataIndex: 'topic',
    },
    {
      key: 'info',
      title: undefined,
      dataIndex: 'info',
    },
    {
      key: 'sendAt',
      title: <span data-icom={'survey-send-at'}>{t('startDate')}</span>,
      dataIndex: 'sendAt',
    },
    {
      key: 'endsAt',
      title: <span data-icom={'survey-end-at'}>{t('endDate')}</span>,
      dataIndex: 'endsAt',
    },
    showResponseRate(pathStatus) && {
      key: 'responseRate',
      title: t('responseRate'),
      dataIndex: 'responseRate',
    },
    {
      key: 'actions',
      title: undefined,
      dataIndex: 'actions',
      width: '300px',
    },
  ]);

export const useGetSurveyGroups = () => {
  // eslint-disable-next-line prefer-const
  let { data, loading, error, refetch } = useSurveyGroupsQuery({
    fetchPolicy: 'cache-first',
  });
  loading = loading || (error == null && data == null);
  const loadingOrError = getLoadingOrError(loading, error);
  return { surveyGroups: data?.organization?.surveyGroups, loadingOrError, loading, error, refetch };
};

export const useGetOrganizationTeams = (organizationId: string) => {
  // eslint-disable-next-line prefer-const
  let { data, loading, error, refetch } = useOrganizationTeamsQuery({ variables: { organizationId } });
  loading = loading || (error == null && data == null);
  const loadingOrError = getLoadingOrError(loading, error);
  return { organizationTeams: data?.organizationTeams, loadingOrError, loading, error, refetch };
};

const getLoadingOrError = (loading: boolean, error: ApolloError | undefined) => {
  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <GraphQLErrorCard />;
  }
  return null;
};
