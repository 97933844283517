export type ProductHistory = {
  product: string;
  licenceCount?: number | null;
  validFrom: string | number;
  validTo: string | number;
};

export const isDateInValidPeriod = (date: Date, productHistory: ProductHistory[] | null) => {
  return (
    !!productHistory &&
    productHistory.some(
      (product) =>
        new Date(product.validFrom).getTime() <= date.getTime() &&
        new Date(product.validTo).getTime() >= date.getTime(),
    )
  );
};

export const findOldestSubsequentProduct = (
  currentProduct: ProductHistory,
  productHistory: ProductHistory[],
  index: number,
): ProductHistory => {
  if (
    currentProduct.product === productHistory[index]?.product &&
    Math.floor(
      (new Date(currentProduct.validFrom).getTime() - new Date(productHistory[index].validTo).getTime()) /
        (1000 * 3600 * 24),
    ) === 0
  ) {
    return findOldestSubsequentProduct(productHistory[index], productHistory, index + 1);
  }

  return currentProduct;
};

export const calculateValidPeriod = (productVersions: ProductHistory[]): ProductHistory => {
  const validPeriod = { ...productVersions[0] };

  if (productVersions.length > 1) {
    const oldestSubsequentProduct = findOldestSubsequentProduct(productVersions[0], productVersions, 1);
    validPeriod.validFrom = oldestSubsequentProduct.validFrom;

    if (new Date(validPeriod.validFrom).getTime() > new Date().getTime()) {
      const validProductIndex = productVersions.findIndex(
        (version) =>
          new Date(version.validFrom).getTime() <= new Date().getTime() &&
          new Date(version.validTo).getTime() > new Date().getTime(),
      );
      if (validProductIndex !== -1) {
        validPeriod.validTo = productVersions[validProductIndex].validTo;
        validPeriod.product = productVersions[validProductIndex].product;
        validPeriod.licenceCount = productVersions[validProductIndex].licenceCount;
        const oldestSubsequentProduct = findOldestSubsequentProduct(
          productVersions[validProductIndex],
          productVersions,
          validProductIndex + 1,
        );
        validPeriod.validFrom = oldestSubsequentProduct.validFrom;
      }
    }
  }

  return validPeriod;
};
