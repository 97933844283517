import styled from '@emotion/styled';
import { theme } from '../../theme';

export const Header = styled.span`
  display: block;
  margin-left: ${theme.spacing.f};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CardHeaderContainer = styled.div`
  display: flex;
  line-height: ${theme.typography.body.medium.regular.lineHeight};
  font-weight: ${theme.typography.body.medium.regular.fontWeight};
  font-size: ${theme.typography.body.medium.regular.fontSize};
  font-family: 'Roboto', sans-serif;
  color: ${theme.colors.text.primary};
  align-items: center;
  justify-content: center;
`;
