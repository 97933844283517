import {
  AddIcon,
  LanguageSelector,
  Modal,
  PlainButton,
  QuestionMarkIcon,
  Tooltip,
  theme,
  usePath,
  useToast,
} from '@arnold/common';
import styled from '@emotion/styled';
import classNames from 'classnames';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Transform } from 'rete-area-plugin/_types/area';
import { useRete } from 'rete-react-plugin';
import {
  IsAccessTokenValidQuery,
  OrganizationQuery,
  TopicDetailQuery,
  TopicGroupDetailQuery,
  useDeleteCommentGroupMutation,
  useImportTopicMutation,
  useUpdateCommentGroupPositionMutation,
} from '../../generated/hooks';
import ee from '../../lib/eventEmitter';
import Loading from '../Loading';
import { StyledDropdownItem, StyledDropdownMenu, StyledDropdownToggle } from '../StyledComponents';
import { ControlPanel } from './ControlPanel';
import { UndoRedoButtons } from './components/UndoRedoButtons';
import { ZoomControls } from './components/ZoomControls';
import { COMMENT_TYPE, NODE_HEIGHT, NODE_WIDTH } from './constants';
import { getMenuItems } from './contextMenu';
import { NodeData } from './dataNode';
import {
  AddLanguageModal,
  DownloadTranslationsModal,
  StyledModalContent,
  UploadTranslationsModal,
} from './importTranslations';
import './index.css';
import {
  CustomNodeEditor,
  createEditor,
  deleteAutosave,
  getAutosavedNodesData,
  processData,
  regenerateReportOrder,
  updatePositionInNodeData,
} from './rete';
import {
  clearEditor,
  fixTopicDetail,
  getNodesToCompare,
  getTGAccessHeaderContext,
  nodesEqual,
  topicDetailToNodes,
  transformNodesForMutation,
  updateNodeDataBasedOnConnections,
  useWarnUserWhenClosingTab,
} from './utils';
import { ValidationError, validateNodes } from './validation';

type TopicEditorProps = {
  preview?: boolean;
  topicDetail: TopicDetailQuery['topicDetail'] | NonNullable<TopicGroupDetailQuery['topicGroup']['lastValidTopic']>;
  topicGroup: TopicDetailQuery['topicDetail']['topicGroup'];
  organization?: OrganizationQuery['organization'];
  user?: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
  languageCodeOrId?: string;
  limitedMode?: 'READ' | 'WRITE';
  onLanguageChange?: (lang: string) => void;
};

export const TopicEditor: FC<TopicEditorProps> = ({
  topicDetail: topicDetailProp,
  preview = false,
  limitedMode,
  organization,
  user,
  topicGroup,
  languageCodeOrId: languageCodeOrIdFromProps,
  onLanguageChange,
}) => {
  const [updatePosition] = useUpdateCommentGroupPositionMutation();
  const [deleteCommentGroup] = useDeleteCommentGroupMutation();

  const { t } = useTranslation('topicEditor');
  const { search } = useLocation();
  const history = useHistory();
  const [editor, setEditor] = useState<CustomNodeEditor | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { topicEditor, topic } = usePath();
  const { addToast } = useToast();
  const initialNodesRef = useRef<NodeData[]>();
  const [issuesModalShow, setIssuesModalShow] = useState(false);
  const [issuesModalErrors, setIssuesModalErrors] = useState<ValidationError[]>([]);
  const [issuesTestSurveyModalShow, setIssuesTestSurveyModalShow] = useState(false);
  const [addLanguageModalShow, setAddLanguageModalShow] = useState(false);
  const unblockHistoryRef = useRef<() => void>();
  const [closeModalShow, setCloseModalShow] = useState<{
    open: boolean;
    callback?: (newTopicId?: string) => void;
    changingLanguages?: boolean;
  }>({
    open: false,
    callback: undefined,
    changingLanguages: false,
  });
  const [deleteNodeModalShow, setDeleteNodeModalShow] = useState(false);
  const [deleteNodeModalOnSubmit, setDeleteNodeModalOnSubmit] = useState<() => void>();
  const [fullscreen, setFullsreen] = useState(false);
  const [loadAutosaveModalShow, setLoadAutosaveModalShow] = useState<{
    open: boolean;
    callback: (keep: boolean) => void;
    date: Date;
  }>();

  const handleCommentDelete = async (commentGroupId: string) => {
    await deleteCommentGroup({
      context: getTGAccessHeaderContext(search),
      variables: {
        id: commentGroupId,
      },
    });
  };

  // topicDetail can be sometimes corrupted and data might be incompatible with the topic editor. We need to fix the data before using them
  const topicDetail = useMemo(() => fixTopicDetail(topicDetailProp), [topicDetailProp]);

  const languages = topicDetail.languages
    .map((language) => language.code)
    .filter((langCode) => (!organization ? true : organization.languages.find((lang) => lang.code === langCode)));

  const defaultTopicLanguageCode =
    topicDetail.allQuestions[0].translations.find(
      (translation) => translation.isDefault && languages.includes(translation.language.code),
    )?.language.code || languages[0];
  const [languageCode, setLanguageCode] = useState<string>(() => {
    if (languageCodeOrIdFromProps) {
      const languageCodeFoundInTrans = topicDetail.allQuestions
        .map((q) => q.translations)
        .flat()
        .find(
          (trans) =>
            trans.language.code === languageCodeOrIdFromProps || trans.language.id === languageCodeOrIdFromProps,
        )?.language.code;

      const orgSupportsWantedLanguage = organization?.languages.some((lan) => lan.code === languageCodeFoundInTrans);
      if (languageCodeFoundInTrans && orgSupportsWantedLanguage) {
        return languageCodeFoundInTrans;
      }
    }
    return defaultTopicLanguageCode;
  });
  const [conversationNodes, setConversationNodes] = useState(() =>
    topicDetailToNodes(
      topicDetail.allQuestions,
      languageCode,
      topicDetail.startingQuestion.id,
      topicDetail.commentGroups,
      topicDetail.id,
      search,
    ),
  );
  const translation = topicGroup.translations?.find((translation) => translation.language.code === languageCode);
  const [topicGroupName, setTopicGroupName] = useState(translation?.value || '');
  const [topicGroupDescription, setTopicGroupDescription] = useState(translation?.description || undefined);
  const editorBasicInfoRef = useRef<{ name: string; description: string }>();

  useEffect(() => {
    editorBasicInfoRef.current = {
      name: topicGroupName,
      description: topicGroupDescription || '',
    };
  }, [topicGroupName, topicGroupDescription]);

  const [processDataFinishedCallback, setProcessDataFinishedCallback] = useState<() => void | undefined>();
  useWarnUserWhenClosingTab(() => !isEditorWithoutChanges());
  const showDeleteNodeModal = (onSubmit: () => void) => {
    setDeleteNodeModalShow(true);
    setDeleteNodeModalOnSubmit(() => onSubmit);
  };
  const [downloadTranslationsModalShow, setDownloadTranslationsModalShow] = useState(false);
  const [uploadTranslationsModalShow, setUploadTranslationsModalShow] = useState(false);
  const [deleteTranslationsModalShow, setDeleteTranslationsModalShow] = useState<
    { languageCode: string; callback: (value: boolean) => void } | undefined
  >(undefined);
  const saveTopicRef = useRef<typeof saveTopic>();

  const languageId = topicDetail.languages.find((lan) => lan.code === languageCode)?.id;

  useEffect(() => {
    setTopicGroupName(translation?.value || '');
    setTopicGroupDescription(translation?.description || undefined);
  }, [translation]);

  useEffect(
    () => {
      const allNodes = topicDetailToNodes(
        topicDetail.allQuestions,
        languageCode,
        topicDetail.startingQuestion.id,
        topicDetail.commentGroups,
        topicDetail.id,
        search,
      );

      if (editor) {
        const commentNodes = editor?.getNodes().filter((node) => node.data.type === COMMENT_TYPE) || [];
        setConversationNodes([
          ...allNodes.filter((node) => node.type !== COMMENT_TYPE).flat(),
          ...commentNodes.map((node) => node.data),
        ]);
      } else {
        setConversationNodes(allNodes);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topicDetail.allQuestions, languageCode, topicDetail.startingQuestion.id, topicDetail.id],
  );

  const createAndSetEditor = useCallback(async (el: HTMLElement) => {
    const editor = await createEditor(
      el,
      preview,
      showDeleteNodeModal,
      t,
      topicDetail.id,
      handleCommentDelete,
      user,
      search,
    );
    setEditor(editor);
    return {
      destroy: () => {
        editor.areaPlugin.destroy();
        editor.removeEventListeners();
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editorRef] = useRete(createAndSetEditor);

  useEffect(() => {
    if (editor == null) return;
    const autosaveData = editor.firstLoad && getAutosavedNodesData(editor, topicDetail.id, conversationNodes);
    if (autosaveData && autosaveData.language && autosaveData.language !== languageCode) {
      setLanguageCode(autosaveData.language);
      // If the autosaved changes are in a different language than the current one, we need to change the language first
      // and then when the useEffect is called again, the nodes will be loaded in the correct language and hashes can be compared
      // and the user can decide if he wants to keep the autosaved changes
      return;
    }
    const nodesToLoad = (autosaveData && autosaveData.sameHash && autosaveData.nodesData) || conversationNodes;
    const autosaveInitialHash = (autosaveData && autosaveData.hash) || false;
    processData(nodesToLoad, editor, t, languageCode, false, autosaveInitialHash).then(async () => {
      if (autosaveData && autosaveData?.sameHash) {
        const keepAutosavedNodes = await new Promise<boolean>((resolve) =>
          setLoadAutosaveModalShow({
            open: true,
            callback: resolve,
            date: autosaveData.date,
          }),
        );
        if (keepAutosavedNodes) {
          initialNodesRef.current = [];
        } else {
          await processData(conversationNodes, editor, t, languageCode, false, false);
          deleteAutosave(topicDetail.id);
        }
      }
      if (initialNodesRef.current == null) {
        initialNodesRef.current = getNodesToCompare(editor);
      }
      processDataFinishedCallback?.();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor, conversationNodes, processDataFinishedCallback]);

  const [callImportTopicMutation, { loading: loadingMutation }] = useImportTopicMutation({
    context: getTGAccessHeaderContext(search),
  });

  const handleChangeLanguage = async (lang: string, ignoreChanges = false) => {
    if (languageCode === lang) return; // If the language was changed to the language that is currently selected - do nothing
    let newTopicId: string | undefined;
    if (!ignoreChanges && editor && initialNodesRef.current && !isEditorWithoutChanges()) {
      newTopicId = await new Promise<string | undefined>((resolve) => {
        setCloseModalShow({
          open: true,
          callback: resolve,
          changingLanguages: true,
        });
      });
    }
    if (newTopicId) {
      unblockHistoryRef.current?.();
      return topicEditor.toFunc(topicGroup.id, lang, newTopicId);
    }
    onLanguageChange?.(lang) || setLanguageCode(lang);
    const commentNodes = editor?.getNodes().filter((node) => node.data.type === COMMENT_TYPE) || [];
    const allNodes = topicDetailToNodes(
      topicDetail.allQuestions,
      lang,
      topicDetail.startingQuestion.id,
      topicDetail.commentGroups,
      topicDetail.id,
      search,
    );
    setConversationNodes([
      ...allNodes.filter((node) => node.type !== COMMENT_TYPE).flat(),
      ...commentNodes.map((node) => node.data),
    ]);
    initialNodesRef.current = undefined;
    // We can't use react router history here, it has side effects
    // We just want to change the URL to include language, nothing else
    window.history.replaceState(null, '', topicEditor.createPath(topicGroup.id, lang));
    // wait for the setStates and useEffects to execute and editor to rebuild nodes
    await new Promise((res) => setProcessDataFinishedCallback(() => () => res('')));
  };

  const area = editor?.areaPlugin;

  const timers = new Map<string, NodeJS.Timeout>();

  // Save the position of the comment group when it is moved
  area?.addPipe((context) => {
    if (context.type === 'nodetranslated') {
      const node = editor?.getNode(context.data.id);
      if (node?.data.type === COMMENT_TYPE && preview === false) {
        // Clear the previous timer if it exists
        const previousTimer = timers.get(node.data.questionIndex.toString());
        if (previousTimer) {
          clearTimeout(previousTimer);
        }
        // Start a new timer
        const newTimer = setTimeout(() => {
          if (
            node.data.position[0] !== Math.floor(context.data.position.x) ||
            node.data.position[1] !== Math.floor(context.data.position.y)
          ) {
            if (node.data.comments?.length && node.data.commentGroupId) {
              updatePosition({
                context: getTGAccessHeaderContext(search),
                variables: {
                  id: node.data.commentGroupId.toString(),
                  positionX: Math.floor(context.data.position.x),
                  positionY: Math.floor(context.data.position.y),
                },
              });
            }
            node.data.position = [Math.floor(context.data.position.x), Math.floor(context.data.position.y)];
            node.onChange?.();
          }
        }, 1000); // Wait for 1 second before saving the position
        timers.set(node.data.questionIndex.toString(), newTimer);
      }
    }
    return context;
  });

  const container = area?.container;
  const [hw, hh] = [(container?.clientWidth || 0) / 2, (container?.clientHeight || 0) / 2];
  const { x, y, k }: Transform | Record<string, never> = editor ? editor.areaPlugin.area.transform : {};
  const centerPosition = { x: (hw - x) / k - NODE_WIDTH / 2, y: (hh - y) / k - NODE_HEIGHT / 2 };
  const menuItems = editor && area ? getMenuItems(t, editor, area, topicDetail.id, centerPosition, user, search) : [];
  const shareLinks = (topicGroup.accessKeys || []).map((accessKey) => ({
    url: `${document.location.origin}/${accessKey.level === 'WRITE' ? 'svenEditorPublic' : 'svenPreviewPublic'}/${
      topicGroup.id
    }/${languageCode}?accessKey=${accessKey.accessKey}`,
    level: accessKey.level,
  }));

  const isEditorWithoutChanges = (isTesting = false) => {
    return (
      nodesEqual(initialNodesRef.current, editor!, isTesting) &&
      editorBasicInfoRef.current?.name === translation?.value &&
      (editorBasicInfoRef.current?.description || '') === (translation?.description || '')
    );
  };

  const onSaveButtonClicked = async (isTesting = false): Promise<string | undefined> => {
    if (editor == null) throw new Error('Clicked on save before editor was initialized');
    if (preview && !limitedMode && !isTesting)
      throw new Error('Something triggered onSave action in preview mode. This should not be possible!');
    updateNodeDataBasedOnConnections(editor.getNodes(), editor.getConnections());
    let errors = validateNodes(editor.getNodes(), editor.getNodes());
    if (isTesting) {
      // We don't want to validate report order when we are only testing the conversation
      errors = errors.filter((e) => e.part !== 'reportOrder');
    }
    if (errors.length === 0) {
      if (isEditorWithoutChanges(isTesting)) {
        if (isTesting === false) {
          addToast(t('topicAlreadySaved'), undefined, undefined, undefined, true);
          initialNodesRef.current = getNodesToCompare(editor);
        }
        return topicDetail.id;
      }
      initialNodesRef.current = getNodesToCompare(editor);
      return saveTopic(isTesting, !isTesting && !limitedMode);
    } else {
      setIssuesModalErrors(errors);
      if (isTesting) {
        setIssuesTestSurveyModalShow(true);
      } else {
        setIssuesModalShow(true);
      }
    }
  };

  const saveTopic = async (
    isTesting = false,
    openNewTopicAfterSave = true,
    isValid = true,
    langCode = languageCode,
    langId = languageId,
    topicName = topicGroupName,
    topicDescription = topicGroupDescription,
    savingNewLanguage = false,
    topicId = topicDetail.id,
  ) => {
    setIssuesModalShow(false);
    if (topicName.length < 3) {
      topicName = topicGroup.name;
    }
    try {
      const transformedTopic = transformNodesForMutation(
        editor!,
        topicName,
        topicGroup.id,
        langCode,
        langId!,
        defaultTopicLanguageCode,
        isTesting,
        topicDescription,
        isValid,
        savingNewLanguage,
        topicId,
      );
      const result = await callImportTopicMutation({ variables: transformedTopic });
      if (isTesting === false) {
        addToast(t('topicSaved'), undefined, undefined, undefined, true);
      }
      const newTopicId = result.data && result.data.importTopic.id + '';
      if (openNewTopicAfterSave && newTopicId && !limitedMode) {
        unblockHistoryRef.current?.();
        topicEditor.toFunc(topicGroup.id, langId!);
      }
      editor!.historyPlugin.clear();
      editor!.saved();
      deleteAutosave(topicId, true);
      return newTopicId || undefined;
    } catch (error) {
      addToast(
        t(isTesting === false ? 'topicSavedFail' : 'createTestSurveyFailed'),
        undefined,
        undefined,
        undefined,
        true,
      );
      throw error;
    }
  };
  saveTopicRef.current = saveTopic;

  useEffect(() => {
    if (editor) {
      const unblock = history.block((tx) => {
        if (closeModalShow.open) {
          return;
        } else if (!isEditorWithoutChanges()) {
          setCloseModalShow({
            open: true,
            callback: () => {
              history.push(tx.pathname);
            },
          });
          return false;
        }
      });

      unblockHistoryRef.current = unblock;
      return () => {
        unblock();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeModalShow.open, editor]);

  const handleClose = () => {
    if (editor && !isEditorWithoutChanges()) {
      setCloseModalShow({ open: true, callback: undefined });
    } else {
      topic.toFunc(topicDetail.id);
    }
  };

  const handleLanguageDelete = async (lang: string) => {
    const deleteTranslation = await new Promise<boolean>((res) =>
      setDeleteTranslationsModalShow({ callback: res, languageCode: lang }),
    );
    setDeleteTranslationsModalShow(undefined);
    if (deleteTranslation === false) return;
    if (lang === languageCode) {
      await handleChangeLanguage(defaultTopicLanguageCode, true);
    }
    const nodes = editor!.getNodes();
    const nodeData = nodes.map((node) => node.data);
    nodeData.forEach((nodeData) => {
      if (nodeData.originalQuestionDefinition) {
        nodeData.originalQuestionDefinition = {
          ...nodeData.originalQuestionDefinition,
          translations: nodeData.originalQuestionDefinition.translations.filter(
            (trans) => trans.language.code !== lang,
          ),
        };
      }
    });
    const errors = validateNodes(nodes, nodes);
    const isValid = errors.length === 0;
    saveTopicRef.current!(false, true, isValid);
  };

  const MenuItemsDropdown = (
    <Dropdown show={isDropdownOpen} onToggle={() => setIsDropdownOpen(!isDropdownOpen)} drop="right">
      <StyledDropdownToggle
        color="primary"
        variant={'primary'}
        data-cy="topic-editor-add"
        data-icom="btn-add-question-ariel"
        className="addNodeButton"
      >
        <AddIcon />
        {t('addNode')}
      </StyledDropdownToggle>
      <StyledDropdownMenu data-icom={'topic-editor-context-menu'}>
        {menuItems.map((item) => (
          <Tooltip title={t(`${item.label}Tooltip`)} key={item.label}>
            <StyledDropdownItem
              key={item.key}
              onClick={item.handler}
              data-cy={`topic-editor-add-${item.label.toLocaleLowerCase()}`}
            >
              {t(item.label)}
            </StyledDropdownItem>
          </Tooltip>
        ))}
      </StyledDropdownMenu>
    </Dropdown>
  );

  const Issues = issuesModalErrors.map((error, i) => (
    <div key={i}>
      <Trans
        i18nKey={`topicEditor:${error.error}`}
        values={{ section: (error.node.data.reportOrder ?? t('withoutReportOrderNumber')!) + '' }}
        components={[<strong></strong>]}
      />
    </div>
  ));

  const IssuesModal = (
    <Modal
      show={issuesModalShow}
      onHide={() => setIssuesModalShow(false)}
      onSubmit={() => {
        saveTopic(false, true, false);
      }}
      title={t('validationIssuesModalTitle')}
      content={
        <>
          <p>{t('validationIssuesModalText1')}</p>
          <p>
            <b>{t('validationIssuesModalText2')}</b>
            {Issues}
          </p>
        </>
      }
      buttons={{
        submit: { title: t('validationIssuesModalSave') },
        cancel: { title: t('cancel'), dataCy: 'btn-cancel-with-errors' },
      }}
    />
  );

  const IssuesTestSurveyModal = (
    <Modal
      show={issuesTestSurveyModalShow}
      onHide={() => setIssuesTestSurveyModalShow(false)}
      onSubmit={() => setIssuesTestSurveyModalShow(false)}
      title={t('validationIssuesTestSurveyModalTitle')}
      content={
        <>
          <p>{t('validationIssuesTestSurveyModalText1')}</p>
          <p>
            <b>{t('validationIssuesModalText2')}</b>
            {Issues}
          </p>
        </>
      }
      buttons={{
        submit: { title: t('common:close') },
      }}
    />
  );

  const CloseModal = (
    <Modal
      show={closeModalShow.open}
      onHide={() => setCloseModalShow({ open: false, callback: undefined })}
      width={540}
      onSubmit={async () => {
        const newTopicId = await saveTopic(false, false);

        if (newTopicId) {
          // pushing new version into the browser history - in order to be able to go back to the version that was just saved (and not the previous one that was being edited)
          topicEditor.toFunc(topicGroup.id, languageId!, newTopicId);
        }

        if (closeModalShow.callback) {
          closeModalShow.callback(newTopicId);
          return;
        }

        if (newTopicId) {
          topic.toFunc(newTopicId);
        }
      }}
      hideAfterSubmit={false}
      onCancel={() => {
        deleteAutosave(topicDetail.id, true);
        if (closeModalShow.callback) {
          closeModalShow.callback();
          return;
        }
        topic.toFunc(topicDetail.id);
      }}
      title={t(closeModalShow.changingLanguages ? 'closeModalLanguageChangeTitle' : 'closeModalTitle')}
      text={t(closeModalShow.changingLanguages ? 'closeModalLanguageChangeText' : 'closeModalText')}
      buttons={{
        submit: {
          title: t(
            closeModalShow.changingLanguages ? 'closeModalLanguageChangeSaveAndClose' : 'closeModalSaveAndClose',
          ),
        },
        cancel: { title: t(closeModalShow.changingLanguages ? 'closeModalLanguageChangeClose' : 'closeModalClose') },
      }}
    />
  );

  const DeleteNodeModal = (
    <Modal
      show={deleteNodeModalShow}
      onHide={() => setDeleteNodeModalShow(false)}
      onSubmit={deleteNodeModalOnSubmit}
      title={t('deleteSpecialNodeModalTitle')}
      text={t('deleteSpecialNodeModalText')}
      buttons={{
        submit: { title: t('deleteSpecialNodeModalSubmit') },
        cancel: { title: t('deleteSpecialNodeModalCancel') },
      }}
    />
  );

  const DeleteTranslationModal = (
    <Modal
      show={deleteTranslationsModalShow != null}
      onHide={() => deleteTranslationsModalShow!.callback(false)}
      onSubmit={() => deleteTranslationsModalShow!.callback(true)}
      title={t('deleteTranslationModalTitle')}
      content={
        <>
          <p>{t('deleteTranslationModalText')}</p>
          <StyledModalContent>
            <div className="language">
              <LanguageSelector
                lang={deleteTranslationsModalShow?.languageCode}
                onChange={(lang) => {
                  /* Language should not be changeable */
                }}
                allowedLanguages={[deleteTranslationsModalShow?.languageCode ?? '']}
                isAlignedToLeft
                displayAlways
                margin={`0 0 0 ${theme.spacing.d}`}
                maxWidth={200}
              />
            </div>
          </StyledModalContent>
        </>
      }
      buttons={{
        submit: { title: t('deleteTranslationModalSubmit') },
        cancel: { title: t('modal:cancel') },
      }}
    />
  );

  const LoadAutoSaveModal = (
    <Modal
      show={!!loadAutosaveModalShow?.open}
      onSubmit={() => loadAutosaveModalShow?.callback(true)}
      onClose={() => loadAutosaveModalShow?.callback(true)}
      onCancel={() => loadAutosaveModalShow?.callback(false)}
      onHide={() => setLoadAutosaveModalShow(undefined)}
      hideAfterSubmit={true}
      width={600}
      title={t('loadAutosaveModalTitle')}
      content={
        <>
          <span>
            {t('loadAutosaveModalTextPart1')} <b>{loadAutosaveModalShow?.date.toLocaleDateString()}</b>{' '}
            {t('loadAutosaveModalTextPart2')}
          </span>
          <br />
          <br />
        </>
      }
      buttons={{
        submit: { title: t('loadAutosaveModalSubmit') },
        cancel: { title: t('loadAutosaveModalCancel') },
      }}
    />
  );

  return (
    <>
      <ControlPanel
        languages={languages}
        defaultTopicLanguageCode={defaultTopicLanguageCode}
        shareLinks={shareLinks}
        topicGroupId={topicGroup.id}
        selectedLanguage={languageCode}
        topicGroupTranslation={topicGroupName}
        topicGroupDescription={topicGroupDescription}
        handleLanguageChange={(lang: string) =>
          lang === 'addLanguage' ? setAddLanguageModalShow(true) : handleChangeLanguage(lang)
        }
        handleLanguageDelete={handleLanguageDelete}
        topicId={topicDetail.id}
        limitedMode={limitedMode}
        preview={preview}
        setTopicGroupName={setTopicGroupName}
        setTopicGroupDescription={setTopicGroupDescription}
        onClose={handleClose}
        onSave={onSaveButtonClicked}
        onRearrange={() => editor?.rearrange()}
        onClear={() => editor && clearEditor(editor, t, languageCode, handleCommentDelete)}
        onRegenerateReportOrder={() => editor && regenerateReportOrder(editor)}
        onDownloadTranslations={() => setDownloadTranslationsModalShow(true)}
        onUploadTranslations={() => setUploadTranslationsModalShow(true)}
      />
      <div
        className={classNames(
          'editor-wrapper',
          defaultTopicLanguageCode !== languageCode && 'notDefaultLanguage',
          fullscreen && 'arielFullscreen',
        )}
        data-cy="sven-page"
        data-topic-id={topicDetail.id}
      >
        {!preview && MenuItemsDropdown}
        <UndoRedoHelpContainer>
          {editor && <UndoRedoButtons editor={editor} />}
          <HelpButton onClick={() => ee.emitArielHintsModal()} data-icom={'btn-help-ariel'}>
            <QuestionMarkIcon />
          </HelpButton>
        </UndoRedoHelpContainer>
        {editor && (
          <ZoomControls editor={editor} userId={user?.id} fullscreen={fullscreen} setFullscreen={setFullsreen} />
        )}
        <div ref={editorRef} id="rete-editor" className={classNames('custom-editor', preview && 'preview')}></div>
        <SelectionRectangle id="selection-rectangle" />
      </div>
      {IssuesModal}
      {IssuesTestSurveyModal}
      {CloseModal}
      {DeleteNodeModal}
      {DeleteTranslationModal}
      {LoadAutoSaveModal}
      <DownloadTranslationsModal
        show={downloadTranslationsModalShow}
        setShow={setDownloadTranslationsModalShow}
        nodes={editor?.getNodes()}
        topicName={topicGroupName}
        languageCode={languageCode}
      />
      <UploadTranslationsModal
        show={uploadTranslationsModalShow}
        setShow={setUploadTranslationsModalShow}
        nodes={editor?.getNodes()}
        languageCodes={[languageCode]}
        onTranslationsImported={async (importedLanguageCode: string) => {
          const nodes = editor!.getNodes();
          updatePositionInNodeData(editor!, nodes);
          const nodesData = nodes.map((node) => node.data);
          setConversationNodes(nodesData);
        }}
      />
      <AddLanguageModal
        show={addLanguageModalShow}
        setShow={setAddLanguageModalShow}
        languageCodes={[...new Set(organization?.languages.map((lang) => lang.code) || [])].filter((lang) =>
          topicDetail.languages.every((lang2) => lang !== lang2.code),
        )}
        onLanguageAdded={(languageCode: string, title: string, description: string) => {
          const languageId = organization!.languages.find((lang) => lang.code === languageCode)!.id;
          saveTopic(false, true, false, languageCode, languageId, title, description, true);
        }}
        orgPrimaryLang={defaultTopicLanguageCode}
      />
      {loadingMutation && <Loading />}
    </>
  );
};

const HelpButton = styled(PlainButton)`
  border: 1px solid ${theme.colors.actionPrimary.default};
`;

const UndoRedoHelpContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.d};
  position: absolute;
  bottom: ${theme.spacing.f};
  left: ${theme.spacing.f};
  z-index: 10;
`;

const SelectionRectangle = styled.div`
  display: none;
  position: absolute;
  border: 1px solid ${theme.colors.actionPrimary.default};
  z-index: 1;
  // color must be in RGBA coz of opacity
  background-color: rgba(4, 183, 239, 0.07);
`;
