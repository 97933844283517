import { HeadingSmallWDescription, usePath } from '@arnold/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { GraphQLErrorCard } from '../../components';
import { PageLayout } from '../../components/Common/PageLayout';
import Loading from '../../components/Loading';
import { UserQuery, useSurveyQuery, useUserQuery } from '../../generated/hooks';
import i18n from '../../translations/i18n';
import SurveyScheduling from './SurveyScheduling';

type Props = RouteComponentProps<any> & {
  user: UserQuery['user'];
};

export const ReopenSurveyScreen: FC<Props> = ({
  match: {
    params: { surveyId },
  },
}) => {
  const [t] = useTranslation('reopenScreen');
  const [surveysOverviewT] = useTranslation('surveysOverview');
  const { loading, data } = useSurveyQuery({ variables: { surveyId }, fetchPolicy: 'no-cache' });
  const { surveysPath, surveyDetailPath } = usePath();
  const { loading: userLoading, data: userData } = useUserQuery({ variables: { languageCode: i18n.language } });

  const lang = i18n.language;

  if (loading || userLoading || !data || !userData) {
    return <Loading />;
  }

  if (!userData.user.organization?.surveys.find((survey) => survey.id === surveyId)) {
    return <GraphQLErrorCard />;
  }

  const headingTranslation =
    data.survey?.topicGroup?.translations?.find((translation) => translation.language.code === lang) ||
    data.survey?.topicGroup?.translations?.find(
      (translation) => translation.language.code === userData.user.organization?.primaryLanguageCode,
    );

  const breadcrumbItems = [
    { title: surveysOverviewT('standaloneSurveys'), link: surveysPath.createPath() },
    {
      title: t(`survey:status-${data.survey?.status}`),
      link: surveysPath.createPath(data!.survey?.status.toLowerCase() || '/'),
    },
    {
      title: headingTranslation?.value,
      link: surveyDetailPath.createPath(surveyId),
    },
  ];

  return (
    <PageLayout breadcrumbItems={breadcrumbItems} dataIcom={'heading-reopen-survey-title'} title={t('reopen')}>
      <SurveyScheduling
        surveyId={Number.parseInt(surveyId, 10)}
        notStarted={false}
        reopenSurvey
        survey={data.survey}
        organization={userData.user.organization}
        user={userData.user}
        afterReopenCallback={() => surveyDetailPath.toFunc(surveyId, 'scheduling')}
        heading={<HeadingSmallWDescription>{t('surveyForm:datesAndTimes')}</HeadingSmallWDescription>}
      />
    </PageLayout>
  );
};
