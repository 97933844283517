import { CallingCodeInput, FormSelect, HeadingMedium, Modal, createPhoneNumberWithCallingCode } from '@arnold/common';
import { RespondentOriginator, prepareExternalUidForCandidate } from '@arnold/core';
import classNames from 'classnames';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { Button, Card, Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  ContactInput,
  ContactSource,
  ContactType,
  CreateRespondentMutationFn,
  OrganizationQuery,
  OrganizationTeamsQuery,
  RespondentInput,
  SurveyGroupsQuery,
  UpdateRespondentMutationFn,
  useVerifyPhoneNumbersMutation,
} from '../../../generated/hooks';
import { sortLanguages } from '../../../lib/common';
import ee from '../../../lib/eventEmitter';
import { getTeamLeaders } from '../../../lib/helpers/teams';
import { FormErrorFeedback, FormGroupLabel, FormRadioInput } from '../../Common';
import { SurveyGroupSurveysData } from '../../Modals/AddSGRespondentToOngoingSurveys';
import OnboardingSelect from './OnboardingSelect';
import { SurveyGroupEntries } from './ProcessSurveySelect';
import { NO_SUPERVISOR, TeamLeaderField } from './TeamLeaderField';
import { createValidationSchema, handlePhoneBlur, handlePhoneChange } from './utils';

type Organization = NonNullable<OrganizationQuery['organization']>;
type Teams = NonNullable<OrganizationTeamsQuery['organizationTeams']>;

export type FormProps = {
  workEmail?: string;
  team?: Teams[0];
  phone?: string;
  countryCallingCode?: string;
  firstName?: string;
  surname?: string;
  hireDate?: string;
  candidateId?: string;
  preferredContactType?: string;
  teamId?: number;
  language?: string;
  redirectTo?: string;
  onboardings?: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  disabled?: boolean;
  saveRespondent: UpdateRespondentMutationFn | CreateRespondentMutationFn;
  organization: Organization;
  organizationTeams: Teams;
};

const TeamioRespondentForm = ({
  workEmail,
  phone,
  countryCallingCode,
  saveRespondent,
  team,
  hireDate,
  organization,
  organizationTeams,
  candidateId,
  onboardings,
  firstName,
  surname,
  redirectTo,
}: FormProps) => {
  const { t } = useTranslation(['updateRespondent', 'addRespondent']);
  const [unverifiedPhoneNumber, setUnverifiedPhoneNumber] = useState(false);
  const [formShown, setFormShown] = useState<boolean>(false);
  const [modalShown, setModalShown] = useState<boolean>(false);
  const [onboardingSurveysToAdd, setOnboardingSurveysToAdd] = useState<SurveyGroupEntries>(
    onboardings?.reduce(
      (acc, sg, index) => ({
        ...acc,
        [sg.id]: { surveyGroupId: sg.id, addedOn: hireDate ?? '', checked: index === 0 },
      }),
      {},
    ) || {},
  );
  const [verifyPhoneNumbers] = useVerifyPhoneNumbersMutation();
  const [onboardingOngoingSurveys, setOnboardingOngoingSurveys] = useState<
    Pick<SurveyGroupSurveysData, 'id' | 'topic'>[]
  >([]);

  const TeamioRespondentFormValidationSchema = createValidationSchema(t);

  const getContacts = (values: FormikValues) => {
    const contacts: ContactInput[] = [];
    if (values.primaryEmail) {
      contacts.push({
        source: ContactSource.Primary,
        contactType: ContactType.Email,
        value: values.primaryEmail,
      });
    }
    if (values.phone) {
      contacts.push({
        source: ContactSource.Respondent,
        contactType: ContactType.Sms,
        value: values.phone ? createPhoneNumberWithCallingCode(values.phone, values.callingCode) : null,
      });
    }

    return contacts;
  };

  const createAddInput = (values: FormikValues, runOngoingSteps?: string[]) => {
    const newInput: RespondentInput = {
      firstname: values.firstname,
      surname: values.surname,
      disabled: values.disabled,
      originator: RespondentOriginator.TEAMIO,
      externalUid: candidateId ? prepareExternalUidForCandidate(candidateId) : undefined,
      preferredContactType: values.preferredContactType,
    };

    newInput.organizationId = organization.id;

    newInput.language = values.language === 'unset' || organization.languages.length <= 1 ? null : values.language;

    newInput.teamId = values.teamId === NO_SUPERVISOR ? null : (newInput.teamId = values.teamId);

    if (values.primaryEmail) {
      newInput.email = values.primaryEmail;
    }

    const contacts = getContacts(values);

    if (contacts.length > 0) {
      newInput.contacts = contacts;
    }
    newInput.surveyGroups = [];
    newInput.surveyGroups.push(
      ...Object.values(onboardingSurveysToAdd)
        .filter((sg) => sg.checked)
        .map(({ checked, ...sg }) => ({ ...sg, runOngoingSteps })),
    );

    return newInput;
  };

  const handleAddRespondentToOnboardingOngoingSurveys = (
    values: FormikValues,
    { setSubmitting }: FormikHelpers<any>,
  ) => {
    return async (runOngoingSteps: string[], addedOn: Date) => {
      try {
        setSubmitting(true);

        await (saveRespondent as CreateRespondentMutationFn)({
          variables: {
            input: createAddInput(values, runOngoingSteps),
          },
        });
      } catch (e) {
        setSubmitting(false);
      }
    };
  };

  const handleSaveRespondent = () => async (values: FormikValues, formikHelpers: FormikHelpers<any>) => {
    if (onboardingOngoingSurveys.length) {
      ee.emitAddSGRespondentToOngoingSurveys(
        handleAddRespondentToOnboardingOngoingSurveys(values, formikHelpers),
        new Date(Object.values(onboardingSurveysToAdd).filter((sg) => sg.checked)[0].addedOn),
        onboardingOngoingSurveys,
      );
    } else {
      const { setSubmitting } = formikHelpers;
      try {
        setSubmitting(true);
        await (saveRespondent as CreateRespondentMutationFn)({
          variables: {
            input: createAddInput(values),
          },
        });
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
      }
    }
  };

  const teams = getTeamLeaders(organizationTeams);

  const parsedPhoneNumber = phone ? parsePhoneNumber(phone) : null;
  return (
    <Formik
      initialValues={{
        firstname: firstName ?? '',
        surname: surname ?? '',
        primaryEmail: workEmail || '',
        phone: parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : phone || '',
        callingCode: parsedPhoneNumber ? parsedPhoneNumber.countryCallingCode : countryCallingCode || '',
        preferredContactType: ContactType.Email,
        teamId: (team && team.leader && team.id) || NO_SUPERVISOR,
        language: 'unset',
        disabled: false,
      }}
      validationSchema={TeamioRespondentFormValidationSchema}
      onSubmit={handleSaveRespondent()}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        setValues,
      }) => (
        <Form onSubmit={handleSubmit} data-icom={'form-respondent'}>
          <Card className="p-8 mb-1">
            <Card.Body className="p-0">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <HeadingMedium className="mt-0 mb-0">{t('addRespondent:employeeDetail')}</HeadingMedium>
                <Button
                  color="primary"
                  variant="outline-primary"
                  className={classNames('mr-6 mb-0 mt-0', formShown && 'invisible')}
                  onClick={() => setFormShown(true)}
                  disabled={isSubmitting}
                  type="button"
                  data-icom={'btn-add-employee'}
                >
                  {t('addRespondent:employeeEdit')}
                </Button>
              </div>

              {formShown && (
                <>
                  <Row>
                    <FormGroup as={Col} sm={4}>
                      <FormGroupLabel>{t('teamsScreen:name')}</FormGroupLabel>
                      <FormControl
                        type="text"
                        name="firstname"
                        value={values.firstname || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.firstname && !!errors.firstname}
                      />
                      <FormErrorFeedback error={errors.firstname} />
                    </FormGroup>
                    <FormGroup as={Col} sm={4}>
                      <FormGroupLabel className="form-group__label">{t('teamsScreen:surname')}</FormGroupLabel>
                      <FormControl
                        type="text"
                        name="surname"
                        value={values.surname || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.surname && !!errors.surname}
                      />
                      <FormErrorFeedback error={errors.surname} />
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup as={Col} sm={8}>
                      <FormGroupLabel>{t('addRespondent:emailShort')}</FormGroupLabel>
                      <FormControl
                        type="text"
                        name="primaryEmail"
                        value={values.primaryEmail || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={touched.primaryEmail && !!errors.primaryEmail}
                      />
                      <FormErrorFeedback error={errors.primaryEmail} />
                    </FormGroup>
                  </Row>
                  <Row>
                    <FormGroup as={Col} sm={4}>
                      <FormGroupLabel>{t('callingCode')}</FormGroupLabel>
                      <CallingCodeInput
                        callingCode={values.callingCode || ''}
                        onChange={(callingCode: string) => {
                          setValues(() => ({ ...values, callingCode }));
                        }}
                        isInvalid={!!errors.callingCode}
                      />
                      <FormErrorFeedback error={errors.callingCode} />
                    </FormGroup>
                    <FormGroup as={Col} sm={4}>
                      <FormGroupLabel>{t('teamsScreen:phone')}</FormGroupLabel>
                      <FormControl
                        type="tel"
                        name="phone"
                        value={values.phone}
                        onChange={handlePhoneChange(handleChange)}
                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handlePhoneBlur(values.callingCode, e.target.value, (internationalPhoneNumber: string) =>
                            verifyPhoneNumbers({
                              variables: { phoneNumbers: [internationalPhoneNumber] },
                              onCompleted: (data) => {
                                setUnverifiedPhoneNumber(
                                  !data.verifyPhoneNumbers.find((pn) => pn.phoneNumber === internationalPhoneNumber),
                                );
                              },
                              onError: () => {
                                setUnverifiedPhoneNumber(false);
                              },
                            }),
                          )
                        }
                        isInvalid={!!errors.phone || unverifiedPhoneNumber}
                      />
                      <FormErrorFeedback error={errors.phone || (unverifiedPhoneNumber && t('enterValidPhone'))} />
                    </FormGroup>
                  </Row>

                  <div className="d-flex flex-wrap align-items-start mt-4">
                    <Button
                      variant="primary"
                      className={'mr-6 mb-5'}
                      onClick={() => setFormShown(false)}
                      disabled={!isEmpty(errors) || unverifiedPhoneNumber}
                      type="button"
                      data-icom={'btn-add-employee'}
                    >
                      {t('addRespondent:save')}
                    </Button>
                  </div>
                </>
              )}
              {!formShown && (
                <Row>
                  <FormGroup as={Col} sm={2} className="d-flex flex-column mb-0">
                    <FormGroupLabel className="ml-0">{t('teamsScreen:name')}</FormGroupLabel>
                    <span className="mt-4">{values.firstname} </span>
                  </FormGroup>
                  <FormGroup as={Col} sm={2} className="d-flex flex-column mb-0">
                    <FormGroupLabel className="ml-0">{t('teamsScreen:surname')}</FormGroupLabel>
                    <span className="mt-4">{values.surname} </span>
                  </FormGroup>
                  <FormGroup as={Col} sm={3} className="d-flex flex-column mb-0">
                    <FormGroupLabel className="ml-0">{t('addRespondent:emailShort')}</FormGroupLabel>
                    <span className="mt-4">{values.primaryEmail} </span>
                  </FormGroup>
                  <FormGroup as={Col} sm={3} className="d-flex flex-column mb-0">
                    <FormGroupLabel className="ml-0">{t('teamsScreen:phone')}</FormGroupLabel>
                    <span className="mt-4">{values.phone ? `+${values.callingCode} ${values.phone}` : '-'} </span>
                  </FormGroup>
                </Row>
              )}
            </Card.Body>
          </Card>
          <Card className="p-8">
            <Card.Body className="p-0">
              <HeadingMedium className="mt-0">{t('addRespondent:onboardingSettings')}</HeadingMedium>
              <Row>
                <FormGroup as={Col} sm={4}>
                  <FormGroupLabel className="ml-0">{t('sendSurveyTo')}</FormGroupLabel>
                  <FormGroup className="form-check-group ml-0">
                    <FormRadioInput
                      name="preferredContactType"
                      value={ContactType.Email}
                      clicked={() => {
                        setFieldValue('preferredContactType', ContactType.Email);
                      }}
                      changed={handleChange}
                      onBlur={handleBlur}
                      defaultChecked={values.preferredContactType === ContactType.Email}
                      label={t('teamsScreen:email')}
                    />
                    <FormRadioInput
                      name="preferredContactType"
                      value={ContactType.Sms}
                      clicked={() => {
                        setFieldValue('preferredContactType', ContactType.Sms);
                      }}
                      changed={handleChange}
                      onBlur={handleBlur}
                      defaultChecked={values.preferredContactType === ContactType.Sms}
                      label={t('teamsScreen:phone')}
                      disabled={!values.phone || errors.phone || unverifiedPhoneNumber}
                    />
                  </FormGroup>
                </FormGroup>
                {organization.languages.length > 1 ? (
                  <FormGroup as={Col} sm={4}>
                    <FormGroupLabel>{t('addRespondent:language')}</FormGroupLabel>
                    <Field
                      component={FormSelect}
                      name="language"
                      options={[
                        { value: 'unset', label: t('surveyLanguage') },
                        ...sortLanguages(
                          organization.languages,
                          new Map(organization.languages.map((l) => [l.code, t(`languageSelector:${l.code}`)])),
                        ).map((language) => ({
                          value: language!.code,
                          label: t(`languageSelector:${language!.code}`),
                        })),
                      ]}
                      defaultValue={values.language}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                ) : (
                  <FormGroup as={Col} sm={4} className="d-flex flex-column">
                    <FormGroupLabel className="ml-0 mt-3">{t('addRespondent:language')}</FormGroupLabel>
                    <span className="mt-4">{t(`languageSelector:${organization.languages[0].code}`)} </span>
                  </FormGroup>
                )}
              </Row>
              <Row>
                <TeamLeaderField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  teams={teams}
                  setFieldValue={setFieldValue}
                />
              </Row>
              {!!onboardings?.length && (
                <OnboardingSelect
                  productHistory={organization.productVersionHistory}
                  onboardings={onboardings}
                  onboardingSurveysToAdd={onboardingSurveysToAdd}
                  setOnboardingSurveysToAdd={setOnboardingSurveysToAdd}
                  setOnboardingOngoingSurveys={setOnboardingOngoingSurveys}
                />
              )}
            </Card.Body>
          </Card>
          <div className="d-flex flex-wrap align-items-start mt-7 ml-8">
            <Button
              variant="primary"
              className={'mr-6 mb-10'}
              disabled={
                isSubmitting ||
                formShown ||
                Object.values(onboardingSurveysToAdd).some((sg) => sg.checked && !sg.addedOn)
              }
              type="submit"
              data-icom={'btn-add-employee'}
            >
              {t('addRespondent:startOnboarding')}
            </Button>
            <Button
              variant="outline-primary"
              className={'mr-6 mb-10'}
              disabled={isSubmitting}
              onClick={() => {
                setModalShown(true);
              }}
              type="button"
            >
              {t('addRespondent:goBackToTeamio')}
            </Button>
            <Modal
              show={modalShown}
              onHide={() => {
                setModalShown(false);
              }}
              onSubmit={() => {
                if (redirectTo) {
                  window.location.href = redirectTo;
                } else {
                  setModalShown(true);
                }
              }}
              title={t('addRespondent:goBackToTeamioHeading')}
              text={t('addRespondent:goBackToTeamioInfo')}
              buttons={{
                submit: { title: t('addRespondent:goBackToTeamioConfirm') },
                cancel: { title: t('surveyOverviewRow:cancel') },
              }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TeamioRespondentForm;
