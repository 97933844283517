import * as React from 'react';
import { Tooltip, theme, useToast } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { FormControl } from 'react-bootstrap';
import { ReactComponent as CopyIconSVG } from '@arnold/common/lib/assets/icons/CopyIcon.svg';
import { FormGroupLabel } from '../Common';
import BaseModal from './BaseModal';

interface Props {
  links: {
    url: string;
    level: string;
  }[];
  cancel: () => void;
}

function ShareLinks({ links, cancel }: Props) {
  const [t] = useTranslation('shareLinksModal');
  const { addToast } = useToast();

  const handleCopy = (link: string) => {
    navigator.clipboard.writeText(link);
    addToast?.(t('urlCopiedToClipboard'));
  };

  return (
    <BaseModal
      onClose={cancel}
      title={t('title')}
      description={
        <>
          {links.map((link, index) => (
            <React.Fragment key={link.url}>
              <FormGroupLabel>{link.level === 'READ' ? t('readLinkLabel') : t('writeLinkLabel')}</FormGroupLabel>
              <Wrapper>
                <CustomFormControl type="text" autoComplete="none" name={`copy_link_${index}`} value={link.url} />
                <Tooltip title={t('copyTooltip')}>
                  <CopyButton
                    onClick={() => handleCopy(link.url)}
                    data-cy={`share-links-${link.level.toLocaleLowerCase()}`}
                  >
                    <CopyIconSVG />
                  </CopyButton>
                </Tooltip>
              </Wrapper>
            </React.Fragment>
          ))}
        </>
      }
    />
  );
}
export default ShareLinks;

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const CopyButton = styled.button`
  max-width: 22px;
  max-height: 22px;
  object-fit: contain;
  position: absolute;
  top: 0;
  right: 20px;
  border: none;
  background: none;
  padding: 8px;
`;

const CustomFormControl = styled(FormControl)`
  padding-right: 64px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:focus {
    border: 1px solid ${theme.colors.actionPrimary.active};
    border-radius: 6px;
    box-shadow: none;
  }
`;
