// tslint:disable-next-line:no-implicit-dependencies
import {
  CardBody,
  Modal,
  createEventData,
  createPhoneNumberWithCallingCode,
  pushToDataLayer,
  theme,
  useToast,
} from '@arnold/common';
import { parseNameFromEmail } from '@arnold/core';
import styled from '@emotion/styled/macro';
import DataEditor, {
  CellArray,
  EditableGridCell,
  GridCell,
  GridCellKind,
  GridColumn,
  GridMouseEventArgs,
  Item,
  Rectangle,
} from '@glideapps/glide-data-grid';
import '@glideapps/glide-data-grid/dist/index.css';
import { i18n } from 'i18next';
import { includes } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, FormControl, FormGroup } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import sanitize from 'sanitize-filename';
import * as XLSX from 'xlsx';
import {
  ContactSource,
  ContactType,
  GetOrganizationImportFileHeadersQuery,
  LanguagesQuery,
  OrganizationQuery,
  OrganizationTeamsQuery,
  SurveyGroupRespondentImport,
  SurveyGroupsQuery,
  SurveyRespondentImport,
  TopicGroupTypeCode,
  VerifyPhoneNumbersMutation,
  useExportOrganizationLazyQuery,
  useGetOrganizationRespondentsCountLazyQuery,
  useImportOrganizatonMutation,
  useSurveysQuery,
  useVerifyPhoneNumbersMutation,
} from '../generated/hooks';
import { getOrganizationSettingsReport, getOrganizationStructure, getReportRecipients } from '../graphql/queries';
import ee from '../lib/eventEmitter';
import { isValidEmail } from '../lib/helpers';
import { ImportStatistic } from '../lib/types';
import { StyledFormGroup } from '../screens/PreviewForm/styles';
import { FormErrorFeedback } from './Common';
import { CardText, CardTitle, Loading } from '.';

interface IProps {
  organization: NonNullable<OrganizationQuery['organization']>;
  organizationTeams: NonNullable<OrganizationTeamsQuery['organizationTeams']>;
  surveyGroups: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  refetch?: () => void;
  fileHeaders: GetOrganizationImportFileHeadersQuery['getOrganizationImportFileHeaders'];
  headerKey?: string;
  firstImport?: boolean;
  languages?: LanguagesQuery;
  userId: string;
}

interface IMessage {
  text: string;
  hasErrors: boolean;
}

type Member = NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'][0];

interface TeamMember extends Member {
  structureId: string;
  primaryEmail: string;
  phoneNumber: string;
  languageCode: string;
}

interface IErrors {
  [row: number]: {
    [column: number]: (inst: any) => any;
  };
}

export type BaseRespondent = {
  firstname: string;
  surname: string;
  email: string;
  hasPhoneNumber: boolean;
};

type RespOnboarding = { addedOn: string; surveyGroupId: string };
export type RespondentOnboarding = {
  [key: string]: RespOnboarding;
};

export type RespondentOneTimeSurveys = {
  [key: string]: string[];
};

export type RespondentSurveyGroups = {
  [key: string]: { [key: string]: string | undefined };
};

const FIRST_NAME_COL = 0;
const LAST_NAME_COL = 1;
const EMAIL_COL = 2;
const SUPERVISOR_EMAIL_COL = 3;
const CALLING_CODE = 4;
const PHONE_NUMBER = 5;
const CONTACT = 6;
const IS_PARTICIPANT = 7;
const LANGUAGE = 8;
const ID_COL = 9;
const ROW_COUNT = 9;

const EMAIL_WIDTH = 200;

type FileHeaders = GetOrganizationImportFileHeadersQuery['getOrganizationImportFileHeaders'];

const getContentInitState = (fileHeaders: FileHeaders, i18n: i18n, orgLanguage: string) => {
  const currentLanguages = i18n.languages;
  const UILanguage = currentLanguages[0].length === 2 ? currentLanguages[0] : currentLanguages[1];
  const fileHeader = findHeaderForLanguage(fileHeaders, UILanguage) || findHeaderForLanguage(fileHeaders, orgLanguage);
  const emptyArray: Array<Array<string | null> | null[]> = Array.from({ length: ROW_COUNT }, () =>
    Array.from({ length: ROW_COUNT }, () => null),
  );

  if (fileHeader) {
    fileHeader.header.forEach((header, index) =>
      emptyArray[0][index] !== undefined ? (emptyArray[0][index] = header) : '',
    );
  }
  return emptyArray;
};

const findHeaderForLanguage = (fileHeaders: FileHeaders, languageCode: string) =>
  fileHeaders.find((header) => header.language.code === languageCode);

const normalizeHeader = (str: string) => {
  if (str == null) {
    return null;
  }
  const re = new RegExp(String.fromCharCode(160), 'g');
  return str
    .toLowerCase()
    .replace(re, ' ')
    .replace(/ +(?= )/g, '');
};

const validateCells = (
  userData: any[][],
  validationFn: (rowAcc: any, row: Array<string | undefined>, cellIdx: number) => any,
) => {
  return userData.reduce((acc, row, rowIdx) => {
    const rowErrors = [0, 1, 2, 3, 4, 5, 6, 7].reduce((rowAcc: any, cellIdx) => validationFn(rowAcc, row, cellIdx), {});
    return { ...acc, [rowIdx + 1]: rowErrors };
  }, {});
};

const getMembers = (teams: NonNullable<OrganizationTeamsQuery['organizationTeams']>) =>
  teams.reduce(
    (acc: TeamMember[], team) => [
      ...acc,
      ...team.members.map((member) => ({
        structureId: team.structureId || '',
        ...member,
        primaryEmail:
          member.contacts?.find(
            (contact) => contact.source === ContactSource.Primary && contact.type === ContactType.Email,
          )?.value || '',
        phoneNumber: member.contacts?.find((contact) => contact.type === ContactType.Sms)?.value || '',
        languageCode: member.language?.code || '',
      })),
    ],
    [],
  );

type ImportFileNameMismatchModalData = {
  show: boolean;
  fileName: string;
  confirmCallback?: (confirmed: boolean) => void;
};

const OrganizationImportCard = (props: IProps) => {
  const languages = props.languages ? props.languages.languages : [];
  const { addToast } = useToast();
  const [t, i18n] = useTranslation(['organizationImportCard', 'informationFooter']);
  const [error, setError] = useState<undefined | string>(undefined);
  const [message, setMessage] = useState<undefined | IMessage>(undefined);
  const [errors, setErrors] = useState<IErrors>({});
  const [cellError, setCellError] = useState<undefined | string>(undefined);
  const [fileRef, setFileRef] = useState<any | null>(null);
  const [content, setContent] = useState<Array<Array<string | null> | null[]>>(
    getContentInitState(props.fileHeaders, i18n as i18n, props.organization.primaryLanguageCode),
  );
  const [file, setFile] = useState<string>('');
  const [importFileNameMismatchModalData, setImportFileNameMismatchModalData] =
    useState<ImportFileNameMismatchModalData>({
      show: false,
      fileName: '',
    });
  const [importFileNameMismatchModalVerifyFileNameInputText, setImportFileNameMismatchModalVerifyFileNameInputText] =
    useState('');

  let respondentOnboardingSettings = {};
  const setRespondentOnboardingSettings = (data: RespondentOnboarding) => {
    respondentOnboardingSettings = data;
  };

  let respondentOneTimeSurveySettings = {};
  const setRespondentOneTimeSurveySettings = (data: RespondentOneTimeSurveys) => {
    respondentOneTimeSurveySettings = data;
  };

  let respondentSGSettings = {};
  const setRespondentSGSettings = (data: RespondentSurveyGroups) => {
    respondentSGSettings = data;
  };

  const [importInProgress, setImportInProgress] = useState<boolean>(false);
  const [fetchedRespondentsCount, setFetchedRespondentsCount] = useState<number>(0);
  const [verifiedPhoneNumbers, setVerifiedPhoneNumbers] = useState<VerifyPhoneNumbersMutation['verifyPhoneNumbers']>(
    [],
  );
  const [columns, setColumns] = useState<GridColumn[]>(
    content[0].slice(0, ROW_COUNT).map((col, index) => ({
      title: col || '',
      id: index.toString(10),
      width: index === EMAIL_COL || index === SUPERVISOR_EMAIL_COL ? EMAIL_WIDTH : undefined,
    })),
  );
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(true);

  const [getRespondentsCount] = useGetOrganizationRespondentsCountLazyQuery({
    variables: { organizationId: props.organization.id },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setFetchedRespondentsCount(data.organization?.respondentsCount!);
    },
  });

  const [exportOrganization, { loading: exportLoading }] = useExportOrganizationLazyQuery({
    variables: { organizationId: props.organization.id },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setFile(data.exportOrganization.contents);
      if (data) {
        setTableContent(data.exportOrganization.contents, 'base64');
      }
    },
  });

  const { data: surveysData, loading: surveysLoading } = useSurveysQuery({
    variables: {
      languageCode: i18n.language,
    },
  });

  const downloadExportFile = async (setTableData: boolean = true) => {
    await exportOrganization({
      onCompleted: (data) => {
        setFile(data.exportOrganization.contents);
        if (setTableData) {
          setTableContent(data.exportOrganization.contents, 'base64');
        }
      },
    });
  };

  const [verifyPhoneNumbers] = useVerifyPhoneNumbersMutation();
  const onboardings = props.surveyGroups.filter((sg) => sg.topicGroup.typeCode === TopicGroupTypeCode.Onboarding);
  const processSurveys = props.surveyGroups.filter((sg) => sg.topicGroup.typeCode !== TopicGroupTypeCode.Onboarding);

  let fileHeaders: string[][];
  const [importOrganization, status] = useImportOrganizatonMutation({
    onCompleted: () => {
      setImportInProgress(false);
      addToast(t('updateSuccess'), undefined, undefined, undefined, true);
      downloadExportFile();
      setError('');
    },
    onError: () => {
      setImportInProgress(false);
      status.client.refetchQueries({ include: [getOrganizationStructure] });
    },
    refetchQueries: [
      {
        query: getOrganizationStructure,
        variables: { organizationId: props.organization.id },
      },
      {
        query: getOrganizationSettingsReport,
        variables: { organizationId: props.organization.id },
      },
      {
        query: getReportRecipients,
        variables: { organizationId: props.organization.id },
      },
    ],
  });

  useEffect(() => {
    // This assignment into local variable is weird and probably does nothing, but I am not sure - better to leave it alone.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fileHeaders = props.fileHeaders.map((fheader) => fheader.header);
    fileHeaders = fileHeaders[0].map((_, i) => fileHeaders.map((row) => row[i]));
  });

  useEffect(() => {
    getRespondentsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (fetchedRespondentsCount > 0) {
      downloadExportFile();
    } else {
      downloadExportFile(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedRespondentsCount]);

  const hasFilledSomeData = (respondentData: (string | null)[]) => {
    const clearedData = [...respondentData];
    clearedData.splice(IS_PARTICIPANT, 1);
    clearedData.splice(ID_COL - 1, 1);
    return clearedData.some((data) => !!data);
  };

  const calculateStatistics = () => {
    const stats: ImportStatistic = {
      toDelete: 0,
      toCreate: 0,
      toUpdate: 0,
      all: 0,
      toCreateRespondents: [],
    };

    const oldRespondents = getMembers(props.organizationTeams);
    const newRespondents = content
      .slice(1)
      .filter(
        (row) => !row.filter((_, index) => index !== IS_PARTICIPANT).every((value) => value === null || value === ''),
      );
    const respondentsToCreate = newRespondents
      .filter((newRespondent) => !oldRespondents.find((oldRespondent) => oldRespondent.id === newRespondent[ID_COL]))
      .map((newRespondent) => ({
        firstname: newRespondent[FIRST_NAME_COL] || '',
        surname: newRespondent[LAST_NAME_COL] || '',
        email: newRespondent[EMAIL_COL]?.toLowerCase() || '',
        isPatricipant: newRespondent[IS_PARTICIPANT] === 'N' ? false : true,
        hasPhoneNumber: !!newRespondent[PHONE_NUMBER],
      }));
    stats.toCreate = respondentsToCreate.length;
    const autogeneratedRespondents = newRespondents
      .filter(
        (newRespondent) =>
          !oldRespondents.find(
            (oldRespondent) => oldRespondent.primaryEmail === newRespondent[SUPERVISOR_EMAIL_COL]?.toLowerCase(),
          ) &&
          newRespondent[SUPERVISOR_EMAIL_COL] &&
          !props.organizationTeams.find(
            (team) =>
              team.structureId === newRespondent[SUPERVISOR_EMAIL_COL]?.toLowerCase() ||
              team.structureId === newRespondent[SUPERVISOR_EMAIL_COL],
          ),
      )
      .map((newRespondent) => newRespondent[SUPERVISOR_EMAIL_COL]?.toLowerCase())
      .filter(
        (autogeneratedEmail) =>
          !respondentsToCreate.map((respondent) => respondent.email).includes(autogeneratedEmail!),
      )
      .filter((email, index, self) => self.indexOf(email) === index)
      .map((autogeneratedEmail) => ({
        email: autogeneratedEmail!,
        ...parseNameFromEmail(autogeneratedEmail!),
      }));
    stats.toCreate += autogeneratedRespondents.length;

    stats.toDelete = oldRespondents.filter(
      (oldRespondent) =>
        !newRespondents.find(
          (newRespondent) => oldRespondent.id === newRespondent[ID_COL] && hasFilledSomeData(newRespondent),
        ),
    ).length;

    stats.toUpdate = newRespondents.filter((newRespondent) => {
      const respondent = oldRespondents.find((oldRespondent) => oldRespondent.id === newRespondent[ID_COL]);
      if (!hasFilledSomeData(newRespondent)) {
        return false;
      }
      if (!respondent) {
        return false;
      }
      const isChanged =
        newRespondent[EMAIL_COL]?.toLowerCase() !== respondent.primaryEmail ||
        newRespondent[FIRST_NAME_COL] !== respondent.firstname ||
        newRespondent[LAST_NAME_COL] !== respondent.surname ||
        (newRespondent[SUPERVISOR_EMAIL_COL]?.toLowerCase() || '') !== respondent.structureId ||
        createPhoneNumberWithCallingCode(
          newRespondent[PHONE_NUMBER] || '',
          newRespondent[CALLING_CODE]?.toString()?.replace('+', ''),
        ) !== respondent.phoneNumber ||
        newRespondent[CONTACT] !== respondent.preferredContactType ||
        newRespondent[IS_PARTICIPANT] !== (respondent.disabled ? 'N' : 'Y') ||
        (newRespondent[LANGUAGE] || '') !== respondent.languageCode;
      return isChanged;
    }).length;

    stats.all = newRespondents.length + autogeneratedRespondents.length;
    stats.toCreateRespondents = respondentsToCreate.filter((respondent) => respondent.isPatricipant);
    return stats;
  };

  const handleOnClose = () => {
    ee.emitModalImportDiscardSettings(() => {
      setRespondentOnboardingSettings({});
      setRespondentOneTimeSurveySettings({});
      setRespondentSGSettings({});
      processImport();
    });
  };

  const handleImportClick = async (event: React.SyntheticEvent<any, MouseEvent>) => {
    event.preventDefault();
    if (!validate(content)) {
      return;
    }
    const stats = calculateStatistics();
    ee.emitModalOrgImport(
      props.organization.name,
      stats,
      processImport,
      onboardings,
      processSurveys,
      surveysData?.user.organization?.surveys!,
      respondentOnboardingSettings,
      setRespondentOnboardingSettings,
      respondentOneTimeSurveySettings,
      setRespondentOneTimeSurveySettings,
      respondentSGSettings,
      setRespondentSGSettings,
      handleOnClose,
    );
  };

  const processImport = async () => {
    const contentWithoutNulls: string[][] = [];
    let maxLength = 0;
    content.forEach((row, i) => {
      if (
        row.some((col: string | null, cellIndex: number) => cellIndex !== IS_PARTICIPANT && col !== null && col !== '')
      ) {
        const newRow = (row as Array<string | null>).map((col: string | null) => (col === null ? '' : col));
        if (i !== 0) {
          newRow[PHONE_NUMBER] = createPhoneNumberWithCallingCode(
            newRow[PHONE_NUMBER],
            newRow[CALLING_CODE]?.toString()?.replace('+', ''),
          );
        }
        if (!hasFilledSomeData(newRow)) {
          return;
        }

        newRow.splice(CALLING_CODE, 1);
        newRow.splice(ID_COL - 1, 1);

        maxLength = newRow.length > maxLength ? newRow.length : maxLength;
        contentWithoutNulls.push(newRow);
      }
    });
    contentWithoutNulls.forEach((row, index) => {
      if (row.length < maxLength) {
        contentWithoutNulls[index] = row.concat(Array.from({ length: maxLength - row.length }, () => ''));
      }
    });
    const importableContent = contentWithoutNulls.map((col) => col.join('\t')).join('\n');
    const file = new File([importableContent], encodeURIComponent(props.organization.systemName), {
      type: 'text/html',
    });

    setImportInProgress(true);
    const surveyGroups: SurveyGroupRespondentImport[] = [];
    const surveys: SurveyRespondentImport[] = [];

    const oneTimeSurveyKeys = Object.keys(respondentOneTimeSurveySettings);
    oneTimeSurveyKeys.forEach((key) => {
      surveys.push({ id: key, respondentEmails: (respondentOneTimeSurveySettings as RespondentOneTimeSurveys)[key] });
    });

    onboardings.forEach((onboarding) => {
      const emails = Object.keys(respondentOnboardingSettings).filter(
        (email) => (respondentOnboardingSettings as RespondentOnboarding)[email].surveyGroupId === onboarding.id,
      );
      const addedOns = [
        ...new Set(
          emails.map((email) => {
            return (respondentOnboardingSettings as RespondentOnboarding)[email].addedOn;
          }),
        ),
      ];
      const settings = addedOns.map((addedOn) => {
        return {
          addedOn,
          respondentEmails: Object.keys(respondentOnboardingSettings).filter(
            (email) =>
              emails.includes(email) &&
              (respondentOnboardingSettings as RespondentOnboarding)[email].addedOn === addedOn,
          ),
        };
      });
      if (settings.length) {
        surveyGroups.push({ id: onboarding.id, settings });
      }
    });

    const processSurveysKeys = Object.keys(respondentSGSettings);
    processSurveysKeys.forEach((key: string) => {
      const respondentSetting = (respondentSGSettings as RespondentSurveyGroups)[key] || {};
      const uniqueValues = [...new Set(Object.values(respondentSetting))].filter(Boolean);
      const settings = uniqueValues.map((value) => {
        return {
          addedOn: value!,
          respondentEmails: Object.keys(respondentSetting).filter((key) => respondentSetting[key] === value),
        };
      });
      if (settings.length) {
        surveyGroups.push({ id: key, settings });
      }
    });

    await importOrganization({
      variables: { file, surveyGroups, surveys },
    });
  };
  const validate = async (data: any[][]) => {
    ee.emitModalLoading();
    setMessage(undefined);
    setError(undefined);
    setErrors({});
    setCellError(undefined);
    if (!data || data.length < 1) {
      ee.emitModalLoadingDismiss();
      setError(t('noDataError'));
      return;
    }

    const header = data[0];
    const headerErrors = fileHeaders
      ? fileHeaders.reduce(
          (acc, value, idx) =>
            value.findIndex((value) => normalizeHeader(value) === normalizeHeader(header[idx])) === -1 &&
            idx !== PHONE_NUMBER
              ? {
                  ...acc,
                  [idx]: [t('headerError')],
                }
              : acc,
          {},
        )
      : {};
    const userData = data
      .filter((row, idx) => !!idx)
      .map((row) => row.map((cell) => (cell && cell.toLowerCase && cell.toLowerCase()) || cell).slice(0, -1));

    if (!userData || userData.length < 1) {
      ee.emitModalLoadingDismiss();
      setError(t('noDataError'));
      return;
    }

    const phoneNumbers = userData
      .filter((row) => row[PHONE_NUMBER])
      .map((row) => {
        return createPhoneNumberWithCallingCode(row[PHONE_NUMBER], row[CALLING_CODE]?.toString()?.replace('+', ''));
      });
    const unverifiedPhoneNumbers: string[] = phoneNumbers.filter(
      (phoneNumber: string) => !verifiedPhoneNumbers.find((vpn) => vpn.phoneNumber === phoneNumber),
    );
    let actualVerifiedPhoneNumbers = verifiedPhoneNumbers;
    if (unverifiedPhoneNumbers.length > 0) {
      await verifyPhoneNumbers({
        variables: { phoneNumbers },
        onCompleted: (data) => {
          setVerifiedPhoneNumbers(data.verifyPhoneNumbers);
          actualVerifiedPhoneNumbers = data.verifyPhoneNumbers;
        },
        onError: (err) => {
          setVerifiedPhoneNumbers(
            unverifiedPhoneNumbers.map((phoneNumber) => ({
              __typename: 'TwilioLookUpResponse',
              specifiedPhoneNumber: phoneNumber,
              phoneNumber,
            })),
          );
          actualVerifiedPhoneNumbers = unverifiedPhoneNumbers.map((phoneNumber) => ({
            __typename: 'TwilioLookUpResponse',
            specifiedPhoneNumber: phoneNumber,
            phoneNumber,
          }));
        },
      });
    }

    const emailMap = userData
      .map((row) => row[2])
      .filter((sup) => sup)
      .reduce((acc, value) => {
        const key = value.toLowerCase().trim().replace(/^#+/, '');

        return {
          ...acc,
          [key]: (acc[key] || 0) + 1,
        };
      }, {});

    const dataErrors = validateCells(userData, (rowAcc: any, row: any[], cellIdx: number) => {
      const emptyError =
        row.some((value, index) => value && index !== IS_PARTICIPANT) &&
        !row[cellIdx] &&
        (cellIdx === FIRST_NAME_COL || cellIdx === LAST_NAME_COL || cellIdx === EMAIL_COL);
      const emailError =
        row[cellIdx] &&
        (cellIdx === EMAIL_COL || cellIdx === SUPERVISOR_EMAIL_COL) &&
        !isValidEmail(row[cellIdx]) &&
        !row[cellIdx].startsWith('#');
      const duplicateEmailError =
        cellIdx === EMAIL_COL && row[cellIdx] && emailMap[row[cellIdx].toLowerCase().trim().replace(/^#+/, '')] > 1;
      const invalidPhoneNumber =
        cellIdx === PHONE_NUMBER &&
        row[cellIdx] &&
        row[cellIdx].toString().length > 0 &&
        actualVerifiedPhoneNumbers &&
        actualVerifiedPhoneNumbers.findIndex(
          (vf) => vf.phoneNumber === createPhoneNumberWithCallingCode(row[PHONE_NUMBER], row[CALLING_CODE]),
        ) === -1;
      const invalidCallingCode =
        cellIdx === CALLING_CODE &&
        row[cellIdx] &&
        row[cellIdx].toString().length > 0 &&
        actualVerifiedPhoneNumbers &&
        actualVerifiedPhoneNumbers.findIndex(
          (vf) => vf.phoneNumber === createPhoneNumberWithCallingCode(row[PHONE_NUMBER], row[CALLING_CODE]),
        ) === -1;

      const contactError =
        cellIdx === CONTACT &&
        row[cellIdx] &&
        row[cellIdx].toUpperCase() !== 'EMAIL' &&
        row[cellIdx].toUpperCase() !== 'SMS';
      const isParticipantError =
        cellIdx === IS_PARTICIPANT &&
        row[cellIdx] &&
        row[cellIdx].toUpperCase() !== 'Y' &&
        row[cellIdx].toUpperCase() !== 'N';
      const languageError =
        cellIdx === LANGUAGE &&
        languages &&
        row[cellIdx] &&
        !includes(
          row[cellIdx].toUpperCase(),
          languages.map((language) => language.code.toUpperCase()),
        );

      const emptySMSError =
        cellIdx === CONTACT &&
        row[cellIdx] &&
        row[cellIdx].toUpperCase() === 'SMS' &&
        (!row[PHONE_NUMBER] || row[PHONE_NUMBER].toString().length <= 0);

      const cellErrors = [];
      if (emptyError) {
        cellErrors.push(t('emptyFieldError'));
      }
      if (emailError) {
        cellErrors.push(t('invalidEmailError'));
      }
      if (duplicateEmailError) {
        cellErrors.push(t('duplicateEmailError'));
      }
      if (invalidPhoneNumber || invalidCallingCode) {
        cellErrors.push(t('invalidPhoneNumber'));
      }
      if (contactError) {
        cellErrors.push(t('invalidContactError'));
      }
      if (isParticipantError) {
        cellErrors.push(t('invalidParticipantError'));
      }
      if (languageError) {
        cellErrors.push(t('invalidLanguageError'));
      }
      if (emptySMSError) {
        cellErrors.push(t('emptySMSError'));
      }
      return { ...rowAcc, [cellIdx]: cellErrors };
    });

    const newErrors = {
      ...dataErrors,
      0: headerErrors,
    };

    setErrors(newErrors);

    const errorsForProcessing: {
      [rowKey: string]: { [fieldKey: string]: string[] };
    } = newErrors;

    const onlyErrors = Object.keys(errorsForProcessing).filter((key) =>
      Object.values(errorsForProcessing[key]).reduce(
        (sum: number, errors: any) => sum + ((errors && errors.length) || 0),
        0,
      ),
    );
    const firstThreeRowNumbers = onlyErrors.map((rowIndex) => parseInt(rowIndex, 10)).slice(0, 3);
    const errorCount = Object.values(newErrors).reduce((sum: number, row) => {
      ee.emitModalLoadingDismiss();
      return Object.values(row).reduce((sum: number, errors: any) => sum + ((errors && errors.length) || 0), sum);
    }, 0);
    if (!errorCount) {
      setMessage({ text: t('importDataValidMessage'), hasErrors: false });
    } else {
      setMessage({
        text: t('importDataInvalidMessage', {
          errors: errorCount,
          rowNumbers: firstThreeRowNumbers.join(', '),
        }),
        hasErrors: true,
      });
    }
    ee.emitModalLoadingDismiss();
    return !errorCount;
  };

  const setTableContent = (data: Uint8Array | string, type: 'base64' | 'array' = 'array') => {
    const wb = XLSX.read(data, { type });
    const sheet = wb.Sheets[`${wb.SheetNames[0]}`];
    let content: any[] = XLSX.utils.sheet_to_json(sheet, {
      blankrows: false,
      defval: null,
    });

    const members = getMembers(props.organizationTeams);
    content = content.map((c) => ({
      ...c,
      Id: members.find((m) => m.primaryEmail?.toLowerCase() === c.Email?.toLowerCase())?.id,
    }));

    const arrayHeader: string[] = [];
    const arrayContent = content.map((row: any) =>
      Object.entries(row as any).map(([key, value]) => {
        if (!arrayHeader.find((header) => header === (key as unknown as string))) {
          arrayHeader.push(key as unknown as string);
        }
        return typeof value === 'string' ? value.trim() : value;
      }),
    );
    arrayContent.unshift(arrayHeader);
    setContent(arrayContent as any);
  };

  useEffect(() => {
    validate(content);
    setIsSaveButtonDisabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const fileName = files[0].name;
      if (!fileName.includes(props.organization.systemName)) {
        const confirmed = await new Promise((resolve) => {
          setImportFileNameMismatchModalData({
            show: true,
            fileName,
            confirmCallback: resolve,
          });
        });
        setImportFileNameMismatchModalData({ show: false, fileName: '' });
        if (!confirmed) {
          return;
        }
      }
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target) {
          const data = new Uint8Array((event.target as any).result);
          setTableContent(data);
        }
      };
      reader.readAsArrayBuffer(files[0]);

      pushToDataLayer({
        userId: props.userId,
        orgId: props.organization.id,
        event: 'ux.organization-select-file',
        ...createEventData('organization', 'selectFile', 'organization select file'),
      });
    }
  };

  // clear file when file picker opens
  const clearFile = () => {
    if (fileRef) {
      fileRef.value = '';
    }
  };

  let localizedError = '';

  if (error && error.match(/%.*%/g) !== null) {
    const found = error.match(/%.*%/g);
    const token = found !== null ? found[0].split('%').join('') : error;
    localizedError = error.replace(/%.*%/g, t(token));
  }
  const fileName = sanitize(props.organization.systemName + '.xlsx');

  const employees = content.slice(1);

  const getData = ([col, row]: Item): GridCell => {
    const person = employees[row];
    const hasError = errors && errors[row + 1] && errors[row + 1][col] && errors[row + 1][col].length > 0;

    const themeOverride = hasError
      ? {
          bgCell: '#f2665c',
        }
      : undefined;
    if (col === IS_PARTICIPANT) {
      if (person) {
        return {
          kind: GridCellKind.Boolean,
          data: !person[col] || person[col] === 'Y',
          allowOverlay: false,
          readonly: false,
          themeOverride,
        };
      }
      return {
        kind: GridCellKind.Text,
        data: '',
        displayData: '',
        allowOverlay: false,
        readonly: false,
        themeOverride,
      };
    }
    return {
      kind: GridCellKind.Text,
      data: ((person && person[col]) || '').toString(),
      allowOverlay: true,
      displayData: ((person && person[col]) || '').toString(),
      themeOverride,
    };
  };
  const copyData = ({ x, y, width, height }: Rectangle): CellArray => {
    return Array(height)
      .fill('')
      .map((_, row) =>
        Array(width)
          .fill('')
          .map((__, col) => {
            const person = employees[y + row];
            return {
              kind: GridCellKind.Text,
              data: ((person && person[x + col]) || '').toString(),
              allowOverlay: true,
              displayData: ((person && person[x + col]) || '').toString(),
            };
          }),
      );
  };
  const updateCell = (position: Item, newValue: EditableGridCell) => {
    setContent((oldContent) => {
      const rows = position[1] + 1 < oldContent.length ? oldContent : [...oldContent, columns.map(() => '')];
      return rows.map((row, rowIndex) =>
        row.map((cell, cellIndex) =>
          rowIndex - 1 === position[1] && cellIndex === position[0]
            ? cellIndex === IS_PARTICIPANT
              ? newValue.data
                ? 'Y'
                : 'N'
              : (newValue.data || '').toString().replaceAll('\n', '')
            : cell,
        ),
      );
    });
  };
  const onItemHovered = (args: GridMouseEventArgs) => {
    if (args.kind !== 'cell') {
      setCellError(undefined);
      return;
    }
    const [col, row] = args.location;
    if (errors && errors[row + 1] && errors[row + 1][col] && errors[row + 1][col].length > 0) {
      setCellError((errors[row + 1][col] as any).join(', '));
    } else {
      setCellError(undefined);
    }
  };
  if (exportLoading || surveysLoading) {
    return <Loading />;
  }
  return (
    <>
      <Card>
        <CardTitle textLeft>{t(props.headerKey || 'teamImport')}</CardTitle>
        <CardBody>
          {importInProgress && <Loading />}
          <Form>
            <CardText>
              <ol>
                <li>
                  <Trans
                    i18nKey={
                      props.firstImport
                        ? 'organizationImportCard:firstImportInstructions'
                        : 'organizationImportCard:importInstructionsStep1'
                    }
                  >
                    Stáhněte si prosím vzorový
                    <a
                      download={fileName}
                      data-cy="download-org-structure"
                      href={'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + file}
                    >
                      soubor
                    </a>
                    , vyplňte zaměstnance, označte celou oblast (Ctrl-A), zkopírujte vyplněná data (Ctrl-C) a vložte
                    data do textového pole níže (Ctrl-V).
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey={'organizationImportCard:importInstructionsStep2'}>
                    Upravte informace v šabloně...
                  </Trans>
                </li>
                <li>
                  <Trans i18nKey={'organizationImportCard:importInstructionsStep3'} />
                  <div>
                    <HiddenInput
                      disabled={false}
                      type="file"
                      id="select-file"
                      onChange={handleSelectFile}
                      accept={'.xlsx'}
                      onClick={clearFile}
                      ref={setFileRef}
                    />
                    <CustomLabel htmlFor={'select-file'}>
                      <Button color="primary" variant={'outline-primary'} className="labelButton">
                        {t('chooseFile')}
                      </Button>
                    </CustomLabel>
                  </div>
                </li>
                <li>
                  <Trans i18nKey={'organizationImportCard:importInstructionsStep4'} />
                  {message ? (
                    <CardText color={message.hasErrors ? '#dc3545' : '#28a745'} minHeight={27}>
                      {message.text}
                    </CardText>
                  ) : null}
                  {error ? (
                    <CardText colorRed minHeight={27}>
                      {localizedError ? localizedError : error}
                    </CardText>
                  ) : null}
                  <FormGroup>
                    <label>{t('importDataTable')}</label>
                    <div
                      style={{
                        height: content.length < 11 ? undefined : 256,
                      }}
                    >
                      <DataEditor
                        getCellContent={getData}
                        columns={columns}
                        rowMarkers={'number'}
                        onCellEdited={updateCell}
                        onItemHovered={onItemHovered}
                        onCellActivated={() => setIsSaveButtonDisabled(true)}
                        onPaste={(target: Item, values: ReadonlyArray<readonly string[]>) => {
                          const [gridCol, gridRow] = target;
                          setContent((oldContent) => {
                            const newRows = gridRow + 1 - oldContent.length + values.length;
                            const rows =
                              newRows > 0
                                ? [...oldContent, ...Array.from(Array(newRows).keys()).map(() => columns.map(() => ''))]
                                : oldContent;
                            values.forEach((pastedRow, rowIndex) => {
                              pastedRow.forEach((cellValue, cellIndex) => {
                                if (rows[rowIndex + gridRow + 1][cellIndex + gridCol] !== undefined) {
                                  rows[rowIndex + gridRow + 1][cellIndex + gridCol] = cellValue.toString();
                                }
                              });
                            });
                            return rows;
                          });
                          return false;
                        }}
                        getCellsForSelection={copyData}
                        rows={employees.length + 1}
                        theme={{
                          fontFamily: 'Roboto',
                        }}
                        onColumnResize={(column: GridColumn, newSize: number) => {
                          setColumns((prevColumns) => {
                            const index = prevColumns.findIndex((ci) => ci.title === column.title);
                            const newArray = [...prevColumns];
                            newArray.splice(index, 1, {
                              ...prevColumns[index],
                              width: newSize,
                            });
                            return newArray;
                          });
                        }}
                      />
                    </div>
                    <ButtonsWrapper>
                      <Button
                        color="info"
                        onClick={handleImportClick}
                        disabled={Boolean(error || (message && message.hasErrors) || cellError || isSaveButtonDisabled)}
                      >
                        {t('importData')}
                      </Button>
                      {cellError ? (
                        <CardText colorRed minHeight={54}>
                          {cellError}
                        </CardText>
                      ) : null}
                    </ButtonsWrapper>
                  </FormGroup>
                </li>
              </ol>
            </CardText>
          </Form>
        </CardBody>
      </Card>
      <Modal
        show={importFileNameMismatchModalData.show}
        title={t('importFileNameMismatchModalTitle')}
        content={
          <ImportModalContent data-cy="import-table-verify-modal">
            <p>
              {t('importFileNameMismatchModalParagraph1Part1')}
              <b>{importFileNameMismatchModalData.fileName}</b>
              {t('importFileNameMismatchModalParagraph1Part2')}
              <RedSpan>
                <b>{t('importFileNameMismatchModalParagraph1Part3')}</b>
              </RedSpan>
            </p>
            <p data-cy="import-table-verify-org-name">
              <b>{props.organization.systemName}</b>
            </p>
            <p>
              <b>{t('importFileNameMismatchModalParagraph2Part1')} </b>
              {t('importFileNameMismatchModalParagraph2Part2')}
            </p>
            <p>{t('importFileNameMismatchModalParagraph3')}</p>
            <StyledFormGroup>
              <FormControl
                type="text"
                value={importFileNameMismatchModalVerifyFileNameInputText}
                onChange={(e) => {
                  setImportFileNameMismatchModalVerifyFileNameInputText(e.target.value);
                }}
                isInvalid={
                  !!importFileNameMismatchModalVerifyFileNameInputText &&
                  importFileNameMismatchModalVerifyFileNameInputText !== props.organization.systemName
                }
                data-cy="import-table-verify-org-name-input"
              />
              {importFileNameMismatchModalVerifyFileNameInputText &&
                importFileNameMismatchModalVerifyFileNameInputText !== props.organization.systemName && (
                  <FormErrorFeedback error={t('importFileNameMismatchModalWarning')} />
                )}
            </StyledFormGroup>
          </ImportModalContent>
        }
        buttons={{
          submit: {
            title: t('continueButton'),
            disabled: importFileNameMismatchModalVerifyFileNameInputText !== props.organization.systemName,
          },
          cancel: { title: t('common:close') },
        }}
        onHide={() => importFileNameMismatchModalData.confirmCallback?.(false)}
        onSubmit={() => importFileNameMismatchModalData.confirmCallback?.(true)}
      />
    </>
  );
};

const ImportModalContent = styled.div(() => ({
  color: theme.colors.text.primary,
}));

const RedSpan = styled.span(() => ({
  color: theme.colors.emotionDanger.default,
}));

const ButtonsWrapper = styled.div(() => ({
  margin: [16, 16, 8, 16],
  display: 'flex',
  alignItems: 'baseline',
}));

const HiddenInput = styled.input(() => ({
  display: 'none',
}));

const CustomLabel = styled.label(() => ({
  fontSize: 'initial !important',
  cursor: 'pointer',
  marginBottom: '0px !important',
  '& .labelButton': {
    opacity: 1,
    pointerEvents: 'none',
  },
  ':hover': {
    '& .labelButton': {
      backgroundColor: theme.colors.actionPrimary.active,
      color: theme.colors.textInverted.primary,
    },
  },
}));

export default OrganizationImportCard;
