import { FC } from 'react';
import { EmptyProps, usePath } from '@arnold/common';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../components';
import { StepsEditTable } from '../../components/StepsEditData';
import { PageLayout } from '../../components/Common/PageLayout';
import { TopicGroupTypeCode, useTopicGroupQuery } from '../../generated/hooks';

export const ProcessSurveyStepsEditScreen: FC<EmptyProps> = (props) => {
  const { processTopicGroupId } = useParams<{ processTopicGroupId: string }>();
  const [t] = useTranslation('surveyGroup');
  const { onboardingPath, processSurveysPath, exitPath } = usePath();

  const { loading, error, data } = useTopicGroupQuery({
    variables: { topicGroupId: processTopicGroupId },
  });

  if (loading) {
    return <Loading />;
  }
  if (error || !data?.topicGroup) {
    return null;
  }

  const isOnboarding = data.topicGroup.typeCode === TopicGroupTypeCode.Onboarding;
  const isExit = data.topicGroup.typeCode === TopicGroupTypeCode.Exit;

  return (
    <PageLayout
      title={t('topics')}
      breadcrumbItems={[
        {
          title: isOnboarding
            ? t('AuthorizedPage:onboarding')
            : isExit
              ? t('AuthorizedPage:exit')
              : t('AuthorizedPage:processSurveys'),
          link: isOnboarding
            ? onboardingPath.createPath()
            : isExit
              ? exitPath.createPath()
              : processSurveysPath.createPath(),
        },
      ]}
    >
      <StepsEditTable steps={data.topicGroup.steps} processTopicGroupId={processTopicGroupId} />
    </PageLayout>
  );
};
