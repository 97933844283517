import { Button } from 'react-bootstrap';
import { BodyMedium, BodySmall, CloseIcon, IThemeProps, PlainButton, isNullish, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Card } from '../../../components/Common';
import { TopicGroupQuery } from '../../../generated/hooks';
import {
  DAY,
  HOUR,
  MINUTES_INTERVAL,
  getTimeIntervalForOffset,
  getTimeIntervalOptions,
  prepareStepOffsets,
} from '../../../lib/process';
import {
  get360DaysOptions,
  getEndDaysOptions,
  getEndHoursOptions,
  getReminderDaysOptions,
  getReminderHoursOptions,
  startMinutesOptions,
} from '../../ProcessStepDetail/StepInfoBox/utils';
import DateTimeSelect from '../../ProcessStepDetail/DateTimeSelect';
import { ButtonLink } from '../../../components/StyledComponents';

const Content = styled.div`
  width: 444px;
`;

const Reminders = styled.div`
  flex: 1;
`;

const CloseIconWrapper = styled(PlainButton)`
  position: absolute;
  top: 0;
  right: -${theme.spacing.i};
`;

const ColTitle = styled(BodySmall)`
  color: ${({ theme }: IThemeProps) => theme?.colors.text.secondary};
  margin-bottom: ${({ theme }: IThemeProps) => theme?.spacing.d};
  margin-left: ${({ theme }: IThemeProps) => theme!.spacing.e};
`;

const InputsWrapper = styled.div`
  gap: ${theme.spacing.d};
  flex-wrap: nowrap;
`;

const RemindersWrapper = styled.div`
  border-left: 1px solid ${theme.colors.borderMain.default};
  padding-left: ${theme.spacing.g};
  margin-bottom: ${theme.spacing.h};
  position: relative;

  & .narrowDateInput {
    width: 224px;
  }
`;

const RemindersWrapperArrow = styled.div`
  position: absolute;
  left: -4.5px;
  bottom: 0;
  height: 10px;

  & svg {
    width: 8px;
    height: 8px;
  }
`;

type Props = {
  step?: NonNullable<TopicGroupQuery['topicGroup']['steps']>[0];
  onSave: (offsets: { startOffsetMins: number; remindersOffsetMins: number[]; endOffsetMins: number }) => void;
  onCancel: () => void;
  loading: boolean;
  submitTitle?: string;
};

const timeOptions = getTimeIntervalOptions();
const defaultStartOffset = 540;
const defaultReminderOffset = 2880;
const defaultSecondReminderOffset = 7200;
const defaultEndOffset = 8640;

export const TopicGroupItemEditableContent = ({ step, onCancel, onSave, loading, submitTitle }: Props) => {
  const { t } = useTranslation('processDetail');
  const offsets = prepareStepOffsets({
    startOffsetMins: step?.startOffsetMins || defaultStartOffset,
    endOffsetMins: step?.endOffsetMins || defaultEndOffset,
    remindersOffsetMins: step?.reminders.map((reminder) => reminder.reminderOffsetMins) || [
      defaultReminderOffset,
      defaultSecondReminderOffset,
    ],
  });
  const { startOffset, remindersOffset, endOffset } = offsets;
  const [remindersOffsetState, setReminderOffsetState] = useState(
    remindersOffset?.map((reminders) => ({
      days: reminders?.days,
      minutes: getTimeIntervalForOffset(timeOptions, reminders!),
      error: null,
    })) || [],
  );
  const [startOffsetDays, setStartOffsetDays] = useState<number | null>(
    !isNullish(startOffset) && !isNullish(startOffset.days) && startOffset.days >= 0
      ? startOffset.days + 1
      : startOffset?.days || null,
  );
  const [startOffsetMinutes, setStartOffsetMinutes] = useState<number | null>(
    isNullish(startOffset) ? null : getTimeIntervalForOffset(timeOptions, startOffset),
  );

  const [endOffsetDays, setEndOffsetDays] = useState<number | null>(
    endOffset?.days === undefined ? null : endOffset.days,
  );
  const [endOffsetMinutes, setEndOffsetMinutes] = useState(
    isNullish(endOffset) ? null : getTimeIntervalForOffset(timeOptions, endOffset),
  );

  const [startError, setStartError] = useState(null);
  const [endError, setEndError] = useState(null);

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(remindersOffsetState), startOffsetDays, startOffsetMinutes, endOffsetDays, endOffsetMinutes]);

  const validateInputs = (beforeSave?: boolean) => {
    if (
      isNullish(startOffsetDays) &&
      isNullish(startOffsetMinutes) &&
      isNullish(endOffsetDays) &&
      isNullish(endOffsetMinutes) &&
      remindersOffsetState.some((offsets) => offsets.days === null || offsets.minutes === null) &&
      !beforeSave
    ) {
      return;
    }

    if (isNullish(startOffsetDays) || isNullish(startOffsetMinutes)) {
      setStartError(t('required'));
      return;
    } else {
      setStartError(null);
    }

    if (isNullish(endOffsetDays) || isNullish(endOffsetMinutes)) {
      setEndError(t('required'));
      return;
    } else {
      setEndError(null);
    }

    remindersOffsetState.forEach((offset, index) => {
      if (isNullish(offset.days) || isNullish(offset.minutes)) {
        setReminderOffsetState((prev) => {
          const newArray = [...prev];
          newArray[index] = { ...newArray[index], error: t('required') };
          return newArray;
        });
      } else if (startOffsetDays === 0 && offset.days * DAY * HOUR + offset.minutes < startOffsetMinutes) {
        setReminderOffsetState((prev) => {
          const newArray = [...prev];
          newArray[index] = { ...newArray[index], error: t('reminderBetweenStartAndEnd') };
          return newArray;
        });
      } else if (offset.days * DAY * HOUR + offset.minutes > endOffsetDays * DAY * HOUR + endOffsetMinutes) {
        setReminderOffsetState((prev) => {
          const newArray = [...prev];
          newArray[index] = { ...newArray[index], error: t('reminderBetweenStartAndEnd') };
          return newArray;
        });
      } else {
        setReminderOffsetState((prev) => {
          const newArray = [...prev];
          newArray[index] = { ...newArray[index], error: null };
          return newArray;
        });
      }
    });
  };

  useEffect(() => {
    validateInputs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOffsetDays, startOffsetMinutes, endOffsetDays, endOffsetMinutes]);

  const handleSave = () => {
    if (
      isNullish(startOffsetDays) ||
      isNullish(startOffsetMinutes) ||
      isNullish(endOffsetDays) ||
      isNullish(endOffsetMinutes) ||
      remindersOffsetState.some((offsets) => offsets.days === null || offsets.minutes === null)
    ) {
      validateInputs(true);
      return;
    }

    const startOffsetMins =
      (startOffsetDays + (startOffsetDays >= 0 ? -1 : 0)) * HOUR * DAY + startOffsetMinutes * MINUTES_INTERVAL;

    const endOffsetMins =
      endOffsetDays * HOUR * DAY + (endOffsetMinutes * MINUTES_INTERVAL - startOffsetMinutes * MINUTES_INTERVAL);
    onSave({
      startOffsetMins,
      remindersOffsetMins: remindersOffsetState.map(
        (offset) =>
          offset.days! * HOUR * DAY + offset.minutes! * MINUTES_INTERVAL - startOffsetMinutes * MINUTES_INTERVAL,
      ),
      endOffsetMins,
    });
  };

  return (
    <Card style={{ marginTop: 6, paddingInline: theme.spacing.j }}>
      <Content>
        <div>
          <ColTitle>{t('start')}</ColTitle>
          <InputsWrapper style={{ gap: 6 }}>
            <DateTimeSelect
              onDaysChange={setStartOffsetDays}
              dataCy="start"
              onMinutesChange={setStartOffsetMinutes}
              offsetDays={startOffsetDays}
              offsetMinutes={startOffsetMinutes}
              optionDays={get360DaysOptions(startOffsetDays)}
              optionMinutes={startMinutesOptions}
              error={startError}
            />
          </InputsWrapper>
        </div>
        <RemindersWrapper>
          <RemindersWrapperArrow>
            <svg
              width="5.7735009"
              height="5"
              viewBox="0 0 5.7735009 5"
              fill="none"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M 2.886751,5 5.773501,0 H 0 Z" fill="#e7e7e7" />
            </svg>
          </RemindersWrapperArrow>
          <Reminders>
            <div>
              {remindersOffsetState?.map((offset, index) => {
                return (
                  <div>
                    <ColTitle>{t('noReminder', { count: index + 1 })}</ColTitle>
                    <InputsWrapper style={{ gap: 6, marginBottom: 8, position: 'relative' }}>
                      <DateTimeSelect
                        onDaysChange={(value: number) => {
                          setReminderOffsetState((prev) => {
                            const newArray = [...prev];
                            if (newArray[index]) {
                              newArray[index] = { ...newArray[index], days: value };
                            }
                            return newArray;
                          });
                        }}
                        dataCy={`reminder-${index}`}
                        onMinutesChange={(value: number) => {
                          setReminderOffsetState((prev) => {
                            const newArray = [...prev];
                            if (newArray[index]) {
                              newArray[index] = { ...newArray[index], minutes: value };
                            }
                            return newArray;
                          });
                        }}
                        offsetDays={offset?.days!}
                        offsetMinutes={offset?.minutes}
                        optionDays={getReminderDaysOptions({
                          startOffsetMinutes,
                          reminderOffsetMinutes: offset.minutes || null,
                          reminderOffsetDays: offset?.days || null,
                          endOffsetMinutes,
                          endOffsetDays,
                          t,
                        })}
                        optionMinutes={getReminderHoursOptions({
                          startOffsetMinutes,
                          reminderOffsetDays: offset?.days || 0,
                          endOffsetMinutes,
                          endOffsetDays,
                        })}
                        error={offset.error}
                      />

                      <CloseIconWrapper
                        key="close"
                        aria-label="Close"
                        onClick={() =>
                          setReminderOffsetState((prevValue) => {
                            const newArray = [...prevValue];
                            newArray.splice(index, 1);
                            return newArray;
                          })
                        }
                      >
                        <CloseIcon />
                      </CloseIconWrapper>
                    </InputsWrapper>
                  </div>
                );
              })}
            </div>
            {remindersOffsetState.length < 3 && (
              <div>
                <ButtonLink
                  onClick={() =>
                    setReminderOffsetState((prevValue) => {
                      return [...prevValue, { days: undefined, minutes: null, error: null }];
                    })
                  }
                  data-cy="add-reminder"
                >
                  <BodyMedium style={{ color: 'inherit' }}>{t('addReminder')}</BodyMedium>
                </ButtonLink>
              </div>
            )}
          </Reminders>
        </RemindersWrapper>
        <div>
          <ColTitle>{t('end')}</ColTitle>
          <InputsWrapper>
            <DateTimeSelect
              onDaysChange={setEndOffsetDays}
              dataCy="end"
              onMinutesChange={setEndOffsetMinutes}
              offsetDays={endOffsetDays}
              offsetMinutes={endOffsetMinutes}
              optionDays={getEndDaysOptions({
                startOffsetMinutes,
                endOffsetMinutes,
                endOffsetDays,
                t,
              })}
              optionMinutes={getEndHoursOptions({
                startOffsetMinutes,
                endOffsetDays,
              })}
              error={endError}
            />
          </InputsWrapper>
        </div>
      </Content>
      <div className="mt-7">
        <Button
          onClick={handleSave}
          data-cy="save-edited-topic-group-btn"
          disabled={loading || startError || endError || remindersOffsetState.some((reminder) => reminder.error)}
        >
          {submitTitle || t('save')}
        </Button>
        <Button variant="outline-primary" onClick={onCancel}>
          {t('cancel')}
        </Button>
      </div>
    </Card>
  );
};
