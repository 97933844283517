import { IThemeProps, selectMenuStyles, SelectWrapper } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { concatRange, parseRange, QuestionRange } from '../../screens/Topic/helpers';
import { QuestionDetail } from '../../screens/Topic/topicStructure';
import { QUESTION_DEFINITION_TYPE } from '../../screens/Topic/types';
import { FormGroupLabel, FormRadioInput } from '../Common';
import BaseModal from './BaseModal';

interface IProps {
  question: QuestionDetail;
  save: (question: QuestionDetail) => void;
  cancel: () => void;
}

const BaseFormGroup = styled.div`
  margin-top: ${({ theme }: IThemeProps) => theme && theme.spacing.h};
  margin-bottom: 22px;
  margin-left: 0;
  & > div:not(:last-child) {
    margin-bottom: ${({ theme }: IThemeProps) => theme && theme.spacing.f} !important;
  }
`;
const FormGroup = styled(BaseFormGroup)`
  margin-left: ${({ theme }: IThemeProps) => theme && theme.spacing.f};
`;

const StyledFormGroupLabel = styled(FormGroupLabel)`
  margin-left: 0;
`;

const DisabledLabel = styled.span`
  color: ${({ theme }: IThemeProps) => theme && theme.colors.text.disabled};
`;

const PERSON_MULTISELECT_MAX_OPTIONS = 10;
const PERSON_MULTISELECT_UNLIMITED = 5000;

function QuestionSettings({ question, save, cancel }: IProps) {
  const [t] = useTranslation('questionSettingsModal');
  const [selectedRange, setSelectedRange] = useState<QuestionRange>(parseRange(question.selectedRange));
  const [isOptional, setIsOptional] = useState(question.optional);
  const [questionType, setQuestionType] = useState<QUESTION_DEFINITION_TYPE>(question.__typename as any);
  const isMandatory = selectedRange.min > 0;

  const isSelect = [QUESTION_DEFINITION_TYPE.select, QUESTION_DEFINITION_TYPE.multiselect].includes(questionType);

  const isMultiselect = selectedRange.max !== 1;
  const defaultTranslation = question.translations.find((t) => t.isDefault);
  const numberOfChoices = defaultTranslation && defaultTranslation.choices ? defaultTranslation.choices.length : 0;

  const isMandatorySetter = (val: boolean) => () => {
    setSelectedRange((oldSelectedRange) => {
      if (val && oldSelectedRange.min === 0) {
        return { ...oldSelectedRange, min: 1 };
      } else if (!val && oldSelectedRange.min > 0) {
        return { ...oldSelectedRange, min: 0 };
      }
      return oldSelectedRange;
    });
  };
  const questionTypeSetter = (val: QUESTION_DEFINITION_TYPE) => () => {
    setQuestionType(val);
    setSelectedRange((oldSelectedRange) => {
      if (val === QUESTION_DEFINITION_TYPE.multiselect) {
        const max =
          oldSelectedRange.max === numberOfChoices || oldSelectedRange.max > PERSON_MULTISELECT_MAX_OPTIONS
            ? PERSON_MULTISELECT_UNLIMITED
            : oldSelectedRange.max;
        const min =
          oldSelectedRange.min > PERSON_MULTISELECT_MAX_OPTIONS || oldSelectedRange.min > max
            ? 1
            : oldSelectedRange.min;
        return { min, max };
      }
      if (val === QUESTION_DEFINITION_TYPE.select) {
        const max =
          oldSelectedRange.max === PERSON_MULTISELECT_UNLIMITED || oldSelectedRange.max > numberOfChoices
            ? numberOfChoices
            : oldSelectedRange.max;
        const min = oldSelectedRange.min > max ? 1 : oldSelectedRange.min;
        return { min, max };
      }
      return oldSelectedRange;
    });
  };
  const isOptionalSetter = (val: boolean) => () => {
    setIsOptional(val);
    setQuestionType((oldType) => {
      if (val) {
        return QUESTION_DEFINITION_TYPE.freetext;
      }
      return oldType;
    });
  };
  const multiplicitySetter = (val: boolean) => () => {
    setSelectedRange((oldSelectedRange) => {
      if (val && oldSelectedRange.max < 2) {
        return { ...oldSelectedRange, max: numberOfChoices };
      } else if (!val && oldSelectedRange.max > 1) {
        return { ...oldSelectedRange, max: 1 };
      }
      return oldSelectedRange;
    });
  };
  const handleMinChange = (option: { value: any; label: any }) => {
    if (option.value) {
      const min = Number.parseInt(option.value, 10);
      setSelectedRange(({ max }) => ({ max: max < min ? min : max, min }));
    }
  };
  const handleMaxChange = (option: { value: any; label: any }) => {
    if (option.value) {
      const max = Number.parseInt(option.value, 10);
      setSelectedRange(({ min }) => ({ min: min > max ? max : min, max }));
    }
  };

  const getMaxRangeOptions = () => [
    ...(numberOfChoices > 1 || questionType === QUESTION_DEFINITION_TYPE.multiselect
      ? Array.from(
          Array(
            questionType === QUESTION_DEFINITION_TYPE.multiselect
              ? PERSON_MULTISELECT_MAX_OPTIONS - 1
              : numberOfChoices - 2,
          ).keys(),
        ).map((_, index) => ({ value: index + 2, label: index + 2 }))
      : []),
    {
      value: questionType === QUESTION_DEFINITION_TYPE.multiselect ? PERSON_MULTISELECT_UNLIMITED : numberOfChoices,
      label: t('Unlimited'),
    },
  ];

  const getMinRangeOptions = () =>
    Array.from(
      Array(
        selectedRange.max === PERSON_MULTISELECT_UNLIMITED ? PERSON_MULTISELECT_MAX_OPTIONS - 1 : selectedRange.max - 1,
      ).keys(),
    ).map((_, index) => ({ value: index + 1, label: index + 1 }));

  return (
    <BaseModal
      title={t('Title')}
      description={
        <>
          {isSelect && (
            <>
              <FormGroup className="form-check-group">
                <StyledFormGroupLabel>{t('IsMandatory')}</StyledFormGroupLabel>
                <FormRadioInput
                  name="isMandatory"
                  value={true}
                  clicked={isMandatorySetter(true)}
                  changed={isMandatorySetter(true)}
                  defaultChecked={isMandatory}
                  label={t('Mandatory')}
                />
                <FormRadioInput
                  name="isMandatory"
                  value={false}
                  clicked={isMandatorySetter(false)}
                  changed={isMandatorySetter(false)}
                  defaultChecked={!isMandatory}
                  label={t('Optional')}
                />
              </FormGroup>
              <FormGroup className="form-check-group">
                <StyledFormGroupLabel>{t('Multiplicity')}</StyledFormGroupLabel>
                <FormRadioInput
                  name="multiplicity"
                  value={false}
                  clicked={multiplicitySetter(false)}
                  changed={multiplicitySetter(false)}
                  defaultChecked={!isMultiselect}
                  label={t('SingleAnswer')}
                />
                <FormRadioInput
                  name="multiplicity"
                  value={true}
                  clicked={multiplicitySetter(true)}
                  changed={multiplicitySetter(true)}
                  defaultChecked={isMultiselect}
                  label={t('MultipleAnswers')}
                />
              </FormGroup>
              <FormGroup className="form-check-group">
                <StyledFormGroupLabel>{t('SelectType')}</StyledFormGroupLabel>
                <FormRadioInput
                  name="selectType"
                  value={QUESTION_DEFINITION_TYPE.select}
                  clicked={questionTypeSetter(QUESTION_DEFINITION_TYPE.select)}
                  changed={questionTypeSetter(QUESTION_DEFINITION_TYPE.select)}
                  defaultChecked={questionType === QUESTION_DEFINITION_TYPE.select}
                  label={t('NormalSelect')}
                />
                <FormRadioInput
                  name="selectType"
                  value={QUESTION_DEFINITION_TYPE.multiselect}
                  clicked={questionTypeSetter(QUESTION_DEFINITION_TYPE.multiselect)}
                  changed={questionTypeSetter(QUESTION_DEFINITION_TYPE.multiselect)}
                  defaultChecked={questionType === QUESTION_DEFINITION_TYPE.multiselect}
                  label={t('PersonSelect')}
                />
              </FormGroup>
              {selectedRange.max > 1 && (
                <BaseFormGroup className="form-check-group">
                  <FormGroupLabel>{t('RangeMax')}</FormGroupLabel>
                  <SelectWrapper
                    name="teamId"
                    options={getMaxRangeOptions()}
                    onChange={handleMaxChange}
                    value={getMaxRangeOptions().find((o) => o.value === selectedRange.max)}
                    menuPlacement="auto"
                    styles={{
                      menu: (provided: any, state: any) => ({
                        ...provided,
                        ...selectMenuStyles,
                      }),
                    }}
                  />
                </BaseFormGroup>
              )}
              {selectedRange.max > 1 && selectedRange.min > 0 && (
                <BaseFormGroup className="form-check-group">
                  <FormGroupLabel>{t('RangeMin')}</FormGroupLabel>
                  <SelectWrapper
                    name="teamId"
                    options={getMinRangeOptions()}
                    onChange={handleMinChange}
                    value={getMinRangeOptions().find((o) => o.value === selectedRange.min)}
                    menuPlacement="auto"
                    styles={{
                      menu: (provided: any, state: any) => ({
                        ...provided,
                        ...selectMenuStyles,
                      }),
                    }}
                  />
                </BaseFormGroup>
              )}
            </>
          )}
          {!isSelect && (
            <>
              <FormGroup className="form-check-group">
                <StyledFormGroupLabel>{t('IsMandatory')}</StyledFormGroupLabel>
                <FormRadioInput
                  name="isMandatory"
                  value={false}
                  clicked={isOptionalSetter(false)}
                  changed={isOptionalSetter(false)}
                  defaultChecked={!isOptional}
                  label={t('Mandatory')}
                />
                <FormRadioInput
                  name="isMandatory"
                  value={true}
                  disabled={questionType !== QUESTION_DEFINITION_TYPE.freetext}
                  clicked={isOptionalSetter(true)}
                  changed={isOptionalSetter(true)}
                  defaultChecked={isOptional}
                  label={
                    questionType !== QUESTION_DEFINITION_TYPE.freetext ? (
                      <DisabledLabel>{t('Optional')}</DisabledLabel>
                    ) : (
                      t('Optional')
                    )
                  }
                />
              </FormGroup>
              <FormGroup className="form-check-group">
                <StyledFormGroupLabel>{t('TextType')}</StyledFormGroupLabel>
                <FormRadioInput
                  name="textType"
                  value={QUESTION_DEFINITION_TYPE.freetext}
                  clicked={questionTypeSetter(QUESTION_DEFINITION_TYPE.freetext)}
                  changed={questionTypeSetter(QUESTION_DEFINITION_TYPE.freetext)}
                  defaultChecked={questionType === QUESTION_DEFINITION_TYPE.freetext}
                  label={t('FreeText')}
                />
                <FormRadioInput
                  name="textType"
                  disabled={isOptional}
                  value={QUESTION_DEFINITION_TYPE.emailContact}
                  clicked={questionTypeSetter(QUESTION_DEFINITION_TYPE.emailContact)}
                  changed={questionTypeSetter(QUESTION_DEFINITION_TYPE.emailContact)}
                  defaultChecked={questionType === QUESTION_DEFINITION_TYPE.emailContact}
                  label={isOptional ? <DisabledLabel>{t('Email')}</DisabledLabel> : t('Email')}
                />
                <FormRadioInput
                  name="textType"
                  disabled={isOptional}
                  value={QUESTION_DEFINITION_TYPE.smsContact}
                  clicked={questionTypeSetter(QUESTION_DEFINITION_TYPE.smsContact)}
                  changed={questionTypeSetter(QUESTION_DEFINITION_TYPE.smsContact)}
                  defaultChecked={questionType === QUESTION_DEFINITION_TYPE.smsContact}
                  label={isOptional ? <DisabledLabel>{t('Phone')}</DisabledLabel> : t('Phone')}
                />
              </FormGroup>
            </>
          )}
        </>
      }
      primaryActionText={t('Save')}
      onPrimaryAction={() => {
        save({
          ...question,
          optional: isOptional,
          selectedRange: question.selectedRange ? concatRange(selectedRange) : question.selectedRange,
          __typename: questionType,
        });
      }}
      secondaryActionText={t('Cancel')}
      onSecondaryAction={cancel}
    />
  );
}
export default QuestionSettings;
