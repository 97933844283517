import { getLocale, getLocalizedDateWithoutLeadingZeroes, prepareSurveyDates } from '@arnold/core';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { isNullish } from '@arnold/common';
import { SurveyGroupQuery, SurveyGroupsQuery } from '../../generated/hooks';
import { getTopicGroupName } from '../../lib/helpers';
import { getTGTranslation } from '../../lib/helpers/surveyGroup';
import { prepareStepOffsets } from '../../lib/process';
import { Table } from '../Table/Table';

type SurveyGroupSurveysTableProps = {
  topicGroup: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]['topicGroup'];
  surveys: NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['surveys'];
  addedOn: string;
};

type SurveyGroupSurveysData = Pick<
  NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['surveys'][0],
  'id'
> & {
  topic: string;
  startDate: string;
  daysFromStart: string;
  status: string;
};

const getDataSource = (
  topicGroup: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]['topicGroup'],
  surveys: NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['surveys'],
  addedOn: string,
  language: string,
  t: TFunction,
): SurveyGroupSurveysData[] =>
  topicGroup.steps
    ?.filter((step) => !step.triggeredOn)
    ?.map((step) => {
      const survey = surveys.find((survey) => survey.topicGroup?.id === step.stepTopicGroup.id);
      const groupAddedOn = startOfDay(new Date(addedOn));
      if (survey) {
        const surveyStart = new Date(survey.sendAt);
        const diffInDays = differenceInDays(startOfDay(surveyStart), startOfDay(groupAddedOn));
        return {
          id: survey.id,
          topic: getTopicGroupName(language, survey.topicGroup),
          status: t(`survey:status-${survey.status}`),
          startDate: `${getLocalizedDateWithoutLeadingZeroes(surveyStart, language)} ${format(surveyStart, 'HH:mm')}`,
          daysFromStart: t('processDetail:dayText', {
            dayNo: diffInDays + (diffInDays >= 0 ? 1 : 0),
          }),
        };
      }
      const offsets = prepareStepOffsets({
        startOffsetMins: step.startOffsetMins,
        endOffsetMins: step.endOffsetMins,
        remindersOffsetMins: step.reminders.map((reminder) => reminder.reminderOffsetMins),
      });
      if (isNullish(offsets) || isNullish(offsets.startOffset?.days)) {
        return null;
      }
      const diffInDaysFromStep = offsets.startOffset!.days;
      const basicDescription = {
        id: step.id,
        status: t('survey:status-NOTCREATED'),
        topic: getTGTranslation(step.stepTopicGroup.translations, language)!.value || '',
        startDate: '-',
        daysFromStart: t('processDetail:dayText', {
          dayNo: diffInDaysFromStep + (diffInDaysFromStep >= 0 ? 1 : 0),
        }),
      };
      const surveyDate = prepareSurveyDates(step, groupAddedOn, true);
      if (!surveyDate) {
        return basicDescription!;
      }
      const surveyStart = surveyDate.sendAt;
      const diffInDays = differenceInDays(startOfDay(surveyDate.sendAt), startOfDay(groupAddedOn));
      return {
        ...basicDescription,
        startDate: `${getLocalizedDateWithoutLeadingZeroes(surveyStart, language)} ${format(surveyStart, 'p', {
          locale: getLocale(language),
        })}`,
        daysFromStart: t('processDetail:dayText', {
          dayNo: diffInDays + (diffInDays >= 0 ? 1 : 0),
        }),
      };
    })
    .filter((item): item is SurveyGroupSurveysData => !!item) ?? [];

export const SurveyGroupSurveysTable: FC<SurveyGroupSurveysTableProps> = ({ topicGroup, surveys, addedOn }) => {
  const { i18n, t } = useTranslation('surveyGroup');
  if (topicGroup.steps?.every((step) => step.triggeredOn)) {
    return null;
  }
  return (
    <Table<SurveyGroupSurveysData>
      thin={true}
      className="mt-8"
      columns={[
        {
          key: 'topic',
          title: t('topic'),
          dataIndex: 'topic',
          width: '25%',
        },
        {
          key: 'daysFromStart',
          title: t('SequenceSteps:startOffset'),
          dataIndex: 'daysFromStart',
          width: '25%',
        },
        {
          key: 'startDate',
          title: t('surveyStartDate'),
          dataIndex: 'startDate',
          width: '25%',
        },
        {
          key: 'status',
          title: t('surveyGroupStatus'),
          dataIndex: 'status',
          width: '25%',
        },
      ]}
      dataSources={getDataSource(topicGroup, surveys, addedOn, i18n.language, t)}
      selected={undefined}
    />
  );
};
