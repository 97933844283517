import { BodyMedium, HeadingMedium, theme } from '@arnold/common';
import { ReactComponent as CloseSVG } from '@arnold/common/lib/assets/icons/Close.svg';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import SweetModal from 'react-bootstrap-sweetalert';

export const WIDTH = {
  SMALL: 544,
  MEDIUM: 796,
  AUTO: 'auto',
};

interface IProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  primaryActionText?: string;
  secondaryActionText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
  primaryBtnDataCy?: string;
  onClose?: () => void;
  children?: ReactNode;
  width?: number | string;
  primaryActionDisabled?: boolean;
  styles?: { [key: string]: string };
}

function BaseModal({
  title,
  description,
  primaryActionText,
  secondaryActionText,
  onPrimaryAction,
  onSecondaryAction,
  primaryBtnDataCy,
  onClose,
  children,
  primaryActionDisabled,
  width,
  styles,
}: IProps) {
  return (
    <SweetModal
      customClass="leftAlignedModal"
      showConfirm={false}
      style={{
        width: width || WIDTH.SMALL,
        padding: '12px 48px 31px',
        marginTop: 0,
        marginLeft: 0,
        overflow: 'auto',
        top: '50%',
        left: '50%',
        maxHeight: 'calc(100% - 20px)',
        transform: 'translate(-50%, -50%)',
        transition: 'translate 0s',
        animation: 'none',
        borderRadius: '6px',
        boxShadow: theme.shadows.elevationContentDialog,
        ...styles,
      }}
      onCancel={onClose}
      showCancel={false}
      beforeMount={() => {
        document.body.style.overflow = 'hidden';
      }}
      beforeUnmount={() => {
        document.body.style.overflow = 'auto';
      }}
    >
      <HeadingMedium>{title}</HeadingMedium>
      {onClose && (
        <CloseButton onClick={onClose} data-cy="base-modal-close">
          <CloseSVG />
        </CloseButton>
      )}
      {description && <BodyMedium margin="0 0 16px">{description}</BodyMedium>}
      <div>{children}</div>
      <>
        {onPrimaryAction && primaryActionText && (
          <>
            <Button
              variant="primary"
              onClick={onPrimaryAction}
              data-cy={primaryBtnDataCy}
              disabled={primaryActionDisabled}
            >
              {primaryActionText}
            </Button>
          </>
        )}
        {onSecondaryAction && secondaryActionText && (
          <>
            <Button variant="outline-primary" onClick={onSecondaryAction}>
              {secondaryActionText}
            </Button>
          </>
        )}
      </>
    </SweetModal>
  );
}
export default BaseModal;

const CloseButton = styled.button`
  border: none;
  background: none;
  position: absolute;
  right: ${theme.spacing.f};
  top: ${theme.spacing.d};
  padding: ${theme.spacing.d};
`;
