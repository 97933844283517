import {
  BodyMedium,
  BodySmall,
  Breadcrumb,
  ConditionalWrapper,
  HeadingLarge,
  HeadingSmallWDescription,
  Tooltip,
  theme,
} from '@arnold/common';
import { ProductCode } from '@arnold/core';
import React, { useEffect, useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Loading } from '..';
import {
  ActiveRespondentsQuery,
  AnonymityLevel,
  UserQuery,
  useActiveRespondentsQuery,
  useOrganizationAdminsLazyQuery,
} from '../../generated/hooks';
import { sortRespondents } from '../../lib/common';
import { getDefaultRecipientOrgAdminEmail, isValidEmail } from '../../lib/helpers';
import { Card, FormGroupLabel, FormRadioInput, PageWrapper, TitleCard } from '../Common';
import NewRespondentsBox from './NewRespondentsBox';
import SurveyParticipantsInput from './SurveyParticipantsInput';
import SurveySchedulingInput from './SurveySchedulingInput';
import { FormResults } from './formTypes';

export type SurveyDates = {
  sendAt: any;
  endsAt?: any | null;
  reminders?: any[];
};

export type FormProps = {
  user: UserQuery['user'];
  subjectLabel: string;
  isEdit: boolean;
  loading: boolean;
  surveyDates: SurveyDates;
  respondents?: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'];
  onSave: (data: FormResults) => void;
  hasMissingTranslations: (
    respondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'],
  ) => boolean;
  setNewRespondents?: (respondents: string[]) => void;
  sendEmailSms?: boolean;
  onPublicSurveySave?: (endsAt?: Date) => void;
};

export enum SurveyType {
  PRIVATE,
  PUBLIC,
}

const SurveyForm = ({
  user,
  subjectLabel,
  surveyDates,
  isEdit,
  respondents = [],
  loading,
  onSave,
  hasMissingTranslations,
  setNewRespondents,
  sendEmailSms = false,
  onPublicSurveySave,
}: FormProps) => {
  const { organization } = user;
  const { t } = useTranslation('surveyForm');
  const [formValues, setFormValues] = useState<FormResults>(() => ({
    sendAt: new Date(surveyDates.sendAt),
    endsAt: new Date(surveyDates.endsAt),
    reminders: surveyDates.reminders?.filter(Boolean).map((r) => new Date(r)) || [],
    whitelist: !!respondents.length,
    respondents: sortRespondents([...respondents]),
    sendEmailSms,
    anonymityLevels: [AnonymityLevel.None, AnonymityLevel.Organization],
    type: SurveyType.PRIVATE,
  }));
  const [defaultOrgAdminRecipient, setDefaultOrgAdminRecipient] = useState<string | undefined | false>();
  const { loading: respondentsLoading, data: respondentsData } = useActiveRespondentsQuery({ fetchPolicy: 'no-cache' });
  const activeRespondents = respondentsData?.user.organization?.activeRespondents || [];

  const allEmailsValid = () =>
    !formValues.participants?.length ||
    (formValues.participants.every((email) => isValidEmail(email.trim())) &&
      new Set(formValues.participants.map((p) => p.trim())).size === formValues.participants.length);

  const [getOrgAdmins] = useOrganizationAdminsLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (responseData) => {
      const defaultRecipient = getDefaultRecipientOrgAdminEmail(responseData.organizationAdmins || [], user);
      setDefaultOrgAdminRecipient(defaultRecipient);

      if (!defaultRecipient) {
        return;
      }
      setFormValues({
        ...formValues,
        participants: [defaultRecipient],
      });
    },
  });

  useEffect(() => {
    if (organization && showNewRespondentsForm) {
      getOrgAdmins({
        variables: {
          organizationId: organization.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!organization) {
    return <></>;
  }

  const showNewRespondentsForm = !isEdit && organization?.enabledRespondentsCount <= 1;

  const handleFormSubmit = (e: React.SyntheticEvent<HTMLFormElement, Event>) => {
    e.preventDefault();
    if (formValues.type === SurveyType.PUBLIC && onPublicSurveySave) {
      onPublicSurveySave(formValues.endsAt);
      return;
    }

    if (showNewRespondentsForm) {
      setNewRespondents?.(
        (formValues.participants || []).filter((participant) => participant !== defaultOrgAdminRecipient),
      );
    }
    onSave(formValues);
  };

  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb
        classList={'mt-n6'}
        items={[
          { title: t('teamsScreen:surveys'), link: '/overview' },
          {
            title: t('surveysOverview:chooseSurveyTopic'),
            link: '/conversationSubjects',
          },
          { title: `${t('scheduleTitle')}: ${subjectLabel}`, active: true },
        ]}
      />
      <HeadingLarge>
        {t('scheduleTitle')}: {subjectLabel}
      </HeadingLarge>

      {(loading || respondentsLoading) && <Loading />}
      {!loading && !respondentsLoading && (
        <Form onSubmit={handleFormSubmit}>
          {showNewRespondentsForm && (
            <div data-cy="admin-survey-new-respondents">
              <TitleCard>
                <HeadingSmallWDescription>{t('SequenceSteps:participants')}</HeadingSmallWDescription>
                <BodySmall>{t('participantsDescription')}</BodySmall>
              </TitleCard>
              <Card margin={`0 0 ${theme.spacing.j} 0`} data-icom={'form-new-respondents'}>
                <NewRespondentsBox
                  participants={formValues.participants}
                  setParticipants={(participants: string[]) => {
                    setFormValues((oldValues) => ({
                      ...oldValues,
                      participants,
                    }));
                  }}
                />
              </Card>
            </div>
          )}
          {organization.productVersion?.product === ProductCode.Business && (
            <>
              <TitleCard>
                <HeadingSmallWDescription>{t('surveyType')}</HeadingSmallWDescription>
                <BodySmall>{t('surveyTypeDescription')}</BodySmall>
              </TitleCard>
              <Card margin={`0 0 ${theme.spacing.j} 0`} data-icom={'form-survey-type'}>
                <FormGroupLabel>{t('surveyType')}</FormGroupLabel>
                <FormGroup className="form-check-group" data-icom={'form-survey-type-select'}>
                  <FormRadioInput
                    name="type"
                    dataCy="admin-survey-form-type-private"
                    clicked={() => {
                      setFormValues((oldValues) => ({
                        ...oldValues,
                        type: SurveyType.PRIVATE,
                        endsAt: new Date(surveyDates.endsAt),
                      }));
                    }}
                    defaultChecked={formValues.type === SurveyType.PRIVATE}
                    label={t('typePrivate')}
                  />
                  <FormRadioInput
                    name="type"
                    dataCy="admin-survey-form-type-public"
                    dataIcom="admin-survey-form-type-public"
                    clicked={() => {
                      setFormValues((oldValues) => ({
                        ...oldValues,
                        type: SurveyType.PUBLIC,
                        endsAt: undefined,
                      }));
                    }}
                    defaultChecked={formValues.type === SurveyType.PUBLIC}
                    label={t('typePublic')}
                  />
                  <BodyMedium color={theme.colors.text.secondary} margin={`${theme.spacing.f} 0 0 0`}>
                    {t('typeDescription')}
                  </BodyMedium>
                </FormGroup>
              </Card>
            </>
          )}
          <TitleCard>
            <HeadingSmallWDescription data-icom="heading-dates-and-times">
              {t('datesAndTimes')}
            </HeadingSmallWDescription>
            <BodySmall>{t('dateAndTimesDescription')}</BodySmall>
          </TitleCard>
          <SurveySchedulingInput
            notStarted={true}
            formValues={formValues}
            setFormValues={setFormValues}
            showNote={formValues.type === SurveyType.PRIVATE}
            isPublic={formValues.type === SurveyType.PUBLIC}
          />
          {formValues.type === SurveyType.PRIVATE && (
            <>
              <TitleCard>
                <HeadingSmallWDescription>{t('anonymityTitle')}</HeadingSmallWDescription>
                <BodySmall>{t('anonymityDescription')}</BodySmall>
              </TitleCard>
              <Card margin={`0 0 ${theme.spacing.j} 0`} data-icom={'form-survey-anonymity'}>
                <FormGroupLabel>{t('anonymityQuestion')}</FormGroupLabel>
                <FormGroup className="form-check-group" data-icom={'form-survey-anonymity-select'}>
                  <FormRadioInput
                    name="anonymity"
                    dataCy="admin-edit-survey-anonymity-both"
                    clicked={() => {
                      setFormValues((oldValues) => ({
                        ...oldValues,
                        anonymityLevels: [AnonymityLevel.None, AnonymityLevel.Organization],
                      }));
                    }}
                    defaultChecked={
                      formValues.anonymityLevels.includes(AnonymityLevel.None) &&
                      formValues.anonymityLevels.includes(AnonymityLevel.Organization)
                    }
                    label={t('anonymityOptionBoth')}
                  />
                  <FormRadioInput
                    name="anonymity"
                    dataCy="admin-edit-survey-anonymity-name"
                    clicked={() => {
                      setFormValues((oldValues) => ({
                        ...oldValues,
                        anonymityLevels: [AnonymityLevel.None],
                      }));
                    }}
                    defaultChecked={
                      formValues.anonymityLevels.includes(AnonymityLevel.None) &&
                      !formValues.anonymityLevels.includes(AnonymityLevel.Organization)
                    }
                    label={t('anonymityOptionName')}
                  />
                  <FormRadioInput
                    name="anonymity"
                    dataCy="admin-edit-survey-anonymity-anonymous"
                    clicked={() => {
                      setFormValues((oldValues) => ({
                        ...oldValues,
                        anonymityLevels: [AnonymityLevel.Organization],
                      }));
                    }}
                    defaultChecked={
                      !formValues.anonymityLevels.includes(AnonymityLevel.None) &&
                      formValues.anonymityLevels.includes(AnonymityLevel.Organization)
                    }
                    label={t('anonymityOptionAnonymous')}
                  />
                </FormGroup>
              </Card>

              {!showNewRespondentsForm && (
                <>
                  <TitleCard>
                    <HeadingSmallWDescription>{t('respondentsLabel')}</HeadingSmallWDescription>
                    <BodySmall>{t('respondentsDescription')}</BodySmall>
                  </TitleCard>
                  <SurveyParticipantsInput<FormResults>
                    notStarted={true}
                    setFormValues={setFormValues}
                    formValues={formValues}
                    activeRespondents={activeRespondents}
                    teams={respondentsData?.user.organization?.teams}
                    organization={organization}
                  />
                </>
              )}
            </>
          )}

          <div className="d-flex flex-wrap align-items-start mt-7 ml-8">
            <ConditionalWrapper
              condition={hasMissingTranslations(
                formValues.respondents.length ? formValues.respondents : activeRespondents,
              )}
              wrapper={(children) => <Tooltip title={t('surveysOverview:cannotSchedule')}>{children}</Tooltip>}
            >
              <Button
                variant="primary"
                className={'mr-6 mb-5'}
                disabled={
                  loading ||
                  hasMissingTranslations(formValues.respondents.length ? formValues.respondents : activeRespondents) ||
                  !allEmailsValid() ||
                  (formValues.whitelist && !formValues.respondents.length) ||
                  formValues.reminders?.some(
                    (r) =>
                      r &&
                      (r.getTime() > formValues.endsAt?.getTime() ||
                        r.getTime() < formValues.sendAt.getTime() ||
                        r.getTime() < new Date().getTime()),
                  )
                }
                type="submit"
                data-icom={isEdit ? undefined : 'submit-plan-survey'}
              >
                {isEdit
                  ? t('weekPicker:save')
                  : formValues.type === SurveyType.PUBLIC
                    ? t('startSurvey')
                    : t('saveButton')}
              </Button>
            </ConditionalWrapper>
          </div>
        </Form>
      )}
    </PageWrapper>
  );
};

export default SurveyForm;
