import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { Button } from 'react-bootstrap';
import { BodySmall, Breadcrumb, createEventData, HeadingLarge, pushToDataLayer, useToast } from '@arnold/common';
import SurveyParticipantsInput from '../../components/Forms/SurveyParticipantsInput';
import { getOperationName, sortRespondents } from '../../lib/common';
import { PageWrapper, TitleCard } from '../../components/Common';
import { updateSurveyMutation } from '../../graphql/mutations';
import { Loading } from '../../components';
import { getSurvey } from '../../graphql/queries';
import { SurveyScreenHeading } from '../../components/StyledComponents';
import { SurveyQuery, UserQuery, ActiveRespondentsQuery } from '../../generated/hooks';

interface IProps {
  notStarted: boolean;
  respondents: NonNullable<SurveyQuery['survey']>['whitelistedRespondents'];
  activeRespondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'];
  surveyId: number;
  user: UserQuery['user'];
  teams: NonNullable<ActiveRespondentsQuery['user']['organization']>['teams'];
  sendEmailSms: boolean;
  organization: UserQuery['user']['organization'];
  closeForm: () => void;
  breadcrumbItems: Array<{ title: string; link: string }>;
}

interface FormResults {
  respondents: NonNullable<SurveyQuery['survey']>['whitelistedRespondents'];
  whitelist: boolean;
  sendEmailSms: boolean;
}

const EditSurveyParticipants: FC<IProps> = ({
  respondents,
  notStarted,
  activeRespondents,
  teams,
  surveyId,
  user,
  sendEmailSms,
  organization,
  closeForm,
  breadcrumbItems,
}) => {
  const [t] = useTranslation('surveyForm');
  const [formValues, setFormValues] = useState<FormResults>(() => ({
    whitelist: !!respondents.length,
    respondents: sortRespondents([...respondents]),
    sendEmailSms,
  }));
  const [loading, setLoading] = useState<boolean>(false);
  const [updateSurvey] = useMutation(updateSurveyMutation, { refetchQueries: [getOperationName(getSurvey) || ''] });
  const { addToast } = useToast();

  const handleFormSubmit = async (values: FormResults) => {
    try {
      setLoading(true);
      await updateSurvey({
        variables: {
          surveyId,
          input: {
            recipientIds: notStarted
              ? formValues.whitelist
                ? (formValues.respondents.map((respondent) => respondent.id).filter(Boolean) as string[])
                : []
              : undefined,
          },
        },
      });
      setLoading(false);
      addToast(t('toast:changesSaved'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.edit-survey-success',
        ...createEventData('survey', 'editSuccess', 'survey edit success'),
      });
      closeForm();
    } catch (e) {
      addToast(t('toast:changesFailedToSave'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.edit-survey-error',
        ...createEventData('survey', 'editError', 'survey edit error'),
      });
    }
  };
  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb items={breadcrumbItems} />
      <SurveyScreenHeading>
        <div style={{ width: '100%' }}>
          <HeadingLarge>{t('survey:changeParticipants')}</HeadingLarge>
        </div>
      </SurveyScreenHeading>
      <TitleCard>
        <BodySmall>{t('respondentsDescription')}</BodySmall>
      </TitleCard>
      {loading && <Loading />}
      {!loading && (
        <Formik onSubmit={handleFormSubmit} initialValues={formValues}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <SurveyParticipantsInput<FormResults>
                notStarted={notStarted}
                setFormValues={setFormValues}
                formValues={formValues}
                activeRespondents={activeRespondents}
                teams={teams}
                organization={organization}
              />
              {notStarted && (
                <>
                  <Button
                    variant="primary"
                    className={'mr-6 mb-5'}
                    disabled={formValues.whitelist && !formValues.respondents.length}
                    type="submit"
                  >
                    {t('weekPicker:save')}
                  </Button>
                  <Button variant="outline-primary" className={'mr-6 mb-5'} type="button" onClick={closeForm}>
                    {t('weekPicker:cancel')}
                  </Button>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </PageWrapper>
  );
};

export default EditSurveyParticipants;
