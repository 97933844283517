import { Form, Formik } from 'formik';
import { Button, Col, FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { HeadingMedium } from '@arnold/common';
import * as Yup from 'yup';
import { FormErrorFeedback, FormGroupLabel } from '../../Common';
import { NO_SUPERVISOR, TeamLeaderField } from '../../Forms/RespondentForm/TeamLeaderField';
import { getTeamLeaders } from '../../../lib/helpers/teams';
import {
  ContactSource,
  ContactType,
  OrganizationTeamsQuery,
  useCreateRespondentMutation,
  useCreateTeamMutation,
} from '../../../generated/hooks';

type Props = {
  goBack: () => void;
  onChooseTeam: (teamId: string) => void;
  organizationTeams: OrganizationTeamsQuery['organizationTeams'];
};

type FormikValue = {
  teamName: string;
  firstname: string;
  surname: string;
  email: string;
  teamId: string | null;
};

export function CreateNewLeader({ goBack, onChooseTeam, organizationTeams }: Props) {
  const { t } = useTranslation('newTeamModal');
  const [createRespondent] = useCreateRespondentMutation();
  const [createTeam] = useCreateTeamMutation();

  const newLeaderSchema = Yup.object().shape({
    teamName: Yup.string().required(t('enterTeamName')),
    firstname: Yup.string().required(t('enterFirstname')),
    surname: Yup.string().required(t('enterSurname')),
    email: Yup.lazy((value: string = '') => {
      return value.startsWith('#')
        ? Yup.string().required(t('enterValidEmail'))
        : Yup.string().required(t('enterValidEmail')).email(t('enterValidEmail'));
    }),
    teamId: Yup.string().required(t('enterTeamleader')),
  });

  const handleSubmit = (values: FormikValue) => {
    const { teamName, ...respondentData } = values;
    createRespondent({
      variables: {
        input: {
          ...respondentData,
          teamId: values.teamId === NO_SUPERVISOR ? null : values.teamId,
          contacts: [{ source: ContactSource.Primary, contactType: ContactType.Email, value: respondentData.email }],
        },
      },
      onCompleted(data) {
        if (data) {
          createTeam({
            variables: {
              teamName,
              respondentId: data.createRespondent.id,
            },
            onCompleted(data) {
              onChooseTeam(data.createTeam.id);
            },
          });
        }
      },
    });
  };

  const teams = getTeamLeaders(organizationTeams);

  return (
    <Formik<FormikValue>
      onSubmit={handleSubmit}
      validationSchema={newLeaderSchema}
      initialValues={{
        teamName: '',
        firstname: '',
        surname: '',
        email: '',
        teamId: '',
      }}
    >
      {({ handleBlur, handleChange, values, touched, errors }) => (
        <Form>
          <FormGroup as={Col} className="pl-0">
            <FormGroupLabel>{t('teamName')}</FormGroupLabel>
            <FormControl
              type="text"
              name="teamName"
              value={values.teamName || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched.teamName && !!errors.teamName}
            />
            <FormErrorFeedback error={errors.teamName} />
          </FormGroup>
          <HeadingMedium>{t('teamLeader')}</HeadingMedium>
          <FormGroup as={Col} sm={8} className="pl-0">
            <FormGroupLabel>{t('firstname')}</FormGroupLabel>
            <FormControl
              type="text"
              name="firstname"
              value={values.firstname || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched.firstname && !!errors.firstname}
            />
            <FormErrorFeedback error={errors.firstname} />
          </FormGroup>
          <FormGroup as={Col} sm={8} className="pl-0">
            <FormGroupLabel>{t('surname')}</FormGroupLabel>
            <FormControl
              type="text"
              name="surname"
              value={values.surname || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched.surname && !!errors.surname}
            />
            <FormErrorFeedback error={errors.surname} />
          </FormGroup>
          <FormGroup as={Col} className="pl-0">
            <FormGroupLabel>{t('workingEmail')}</FormGroupLabel>
            <FormControl
              type="text"
              name="email"
              value={values.email || ''}
              onBlur={handleBlur}
              onChange={handleChange}
              isInvalid={touched.email && !!errors.email}
            />
            <FormErrorFeedback error={errors.email} />
          </FormGroup>
          <TeamLeaderField
            disableCreating
            teams={teams}
            handleBlur={handleBlur}
            id="new-team-modal-team-leader"
            error={errors.teamId}
            className="pl-0"
          />
          <div>
            <Button color="primary" type="submit" data-cy="new-team-modal-create">
              {t('create')}
            </Button>
            <Button color="primary" variant="outline-primary" onClick={goBack}>
              {t('back')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
