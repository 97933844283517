import { FC } from 'react';
import { EmptyProps, HeadingLarge } from '@arnold/common';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/Common';
import { Loading } from '../../components';
import { ProcessStatus, SurveyStatus, useUserQuery } from '../../generated/hooks';
import { NoSurveysInfoBase } from '../../components/NoSurveysInfo/NoSurveysInfoBase';
import { SurveyArchiveTable } from './SurveyArchiveTable';

export const SurveyArchiveScreen: FC<EmptyProps> = () => {
  const [t, i18n] = useTranslation('surveyArchive');

  const { data, loading, error } = useUserQuery({ variables: { languageCode: i18n.language } });

  if (loading) {
    return <Loading />;
  }
  if (error || !data?.user || !data.user.organization) {
    return <></>;
  }

  const surveys = data!.user.organization!.surveys.filter((survey) => survey.status === SurveyStatus.Archived);
  const processSurveysAndOnboardings = data!.user.organization!.surveyGroups.filter(
    (survey) => survey.status === ProcessStatus.Archived,
  );

  return (
    <PageWrapper data-cy="admin-page-content">
      <HeadingLarge data-icom={'heading-archive-survey'}>{t('archive')}</HeadingLarge>
      <Card>
        {surveys.length || processSurveysAndOnboardings.length ? (
          <SurveyArchiveTable
            organization={data!.user.organization}
            surveys={[...surveys, ...processSurveysAndOnboardings]}
            user={data!.user}
          />
        ) : (
          <NoSurveysInfoBase
            translationNamespace="surveyArchive"
            navigateTo="/conversationSubjects"
            dataCy="admin-no-archived-surveys"
          />
        )}
      </Card>
    </PageWrapper>
  );
};
