import { IThemeProps, theme } from '@arnold/common';
import { differenceInDays, format, startOfDay } from 'date-fns';
import styled from '@emotion/styled/macro';
import React, { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getLocale, getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import { ErrorWarning } from '../../assets/images';
import { EDITION } from '../../lib/common';
import { OrganizationLicence } from '../../lib/types';
import { ClassicLink } from '../StyledComponents';

export interface ILicenceInfoProps {
  organizationLicence: OrganizationLicence;
  product?: string;
}

interface IOrderLinkProps {
  product?: string;
}

const formatDate = (date: Date, lang: string) =>
  lang === 'en' ? format(date, 'PP', { locale: getLocale(lang) }) : getLocalizedDateWithoutLeadingZeroes(date, lang);

const LicenceContainer = styled.div`
  font-family: Roboto;
  width: 100%;
  font-size: 12px;
  line-height: 17px;
  z-index: 5;
  color: ${({ theme }: IThemeProps) => theme && theme.colors.text.secondary};
  padding: ${theme.spacing.f} ${theme.spacing.h} ${theme.spacing.f} ${theme.spacing.h};
  display: flex;
  flex-direction: column;
  align-items: start;
  transition: opacity 0.2s linear;
  transition-delay: 0.3s;
  background-color: ${theme.colors.backgroundCover.default};

  & strong {
    color: #3f3f3f;
  }

  .sidebar-mini .sidebar:not(:hover) & {
    transition-delay: 0;
    transition: opacity 0s;
    opacity: 0;
    z-index: -1;
  }
`;

const WarningFlag = styled.img`
  margin-right: 5.75px;
  width: 13.5px;
  position: relative;
  top: 1.5px;
`;

const OrderLink = ({ product }: IOrderLinkProps) => {
  const [t] = useTranslation(['licenceInfo', 'informationFooter']);
  return (
    <>
      &nbsp;-&nbsp;
      <ClassicLink weight={500} href={`/settings/order/${product}`}>
        {t('order')}
      </ClassicLink>
    </>
  );
};

const LicenceInfo: FunctionComponent<ILicenceInfoProps> = ({ organizationLicence }) => {
  const validFrom = new Date(organizationLicence.validFrom);
  const validTo = new Date(organizationLicence.validTo);
  const [t, i18n] = useTranslation(['licenceInfo', 'common']);
  const now = new Date();
  const today = startOfDay(now);

  if (today.getTime() < startOfDay(validFrom).getTime()) {
    return <>{t('startsInFuture', { date: formatDate(validFrom, i18n.language) })}</>;
  }
  if (today.getTime() >= startOfDay(validTo).getTime()) {
    return (
      <span>
        {t('expired')}
        <OrderLink product={EDITION.BUSINESS.name} />
      </span>
    );
  }

  const numberOfDaysToExpiration = differenceInDays(startOfDay(validTo), today);

  if (numberOfDaysToExpiration <= 14 && organizationLicence.product !== EDITION.FREE.name) {
    const days = t('common:days', {
      postProcess: 'interval',
      count: numberOfDaysToExpiration,
    });

    return (
      <>
        <WarningFlag src={ErrorWarning} />
        <span>
          <Trans i18nKey="licenceInfo:nearExpiration" values={{ days }}>
            <strong>{days}</strong> until Arnold licence expires
          </Trans>
          <OrderLink product={organizationLicence.product} />
        </span>
      </>
    );
  }

  if (!organizationLicence.existsFollowingSubscription && organizationLicence.product === EDITION.FREE.name) {
    return t('alwaysFree');
  }

  return <>{t('active', { date: formatDate(validTo, i18n.language) })}</>;
};

const LicenceInfoWrapper: FunctionComponent<ILicenceInfoProps> = (props) => (
  <LicenceContainer>
    <div>
      {props.product === EDITION.TEAMIO_ARNOLD.name
        ? 'Teamio & Arnold Onboarding'
        : 'Arnold ' + (props.product?.charAt(0).toUpperCase() || '') + (props.product?.toLowerCase().slice(1) || '')}
    </div>
    <LicenceInfo {...props} />
  </LicenceContainer>
);

export default LicenceInfoWrapper;
