import { gql } from '@apollo/client';

export const resetPasswordMutation = gql`
  mutation resetPassword($username: String!, $origin: Origin!) {
    resetPassword(username: $username, origin: $origin)
  }
`;

export const createRespondent = gql`
  mutation CreateRespondent($input: RespondentInput!) {
    createRespondent(input: $input) {
      id
      firstname
      surname
    }
  }
`;

export const upsertTopicGroupTranslationMutation = gql`
  mutation upsertTopicGroupTranslation($language: String!, $value: String!, $description: String!, $topicGroupId: ID!) {
    upsertTopicGroupTranslation(
      input: { topicGroupId: $topicGroupId, language: $language, value: $value, description: $description }
    ) {
      id
      value
      description
    }
  }
`;

export const updateRespondent = gql`
  mutation UpdateRespondent($id: ID!, $input: UpdateRespondentInput!) {
    updateRespondent(respondentId: $id, input: $input) {
      id
      firstname
      surname
      originator
      createdAt
      contacts {
        type
        source
        value
        phoneNumber {
          countryCallingCode
          nationalNumber
        }
      }
      preferredContactType
      team {
        id
        teamLeaderId
      }
      language {
        id
        code
      }
      disabled
    }
  }
`;

export const changePasswordMutation = gql`
  mutation changePassword($newPassword: String!, $consent: Boolean, $marketingConsent: Boolean, $deviceToken: String!) {
    changePassword(
      newPassword: $newPassword
      consent: $consent
      marketingConsent: $marketingConsent
      deviceToken: $deviceToken
    ) {
      username
    }
  }
`;

export const createSurveyGroupMutation = gql`
  mutation createSurveyGroup($input: CreateSurveyGroupInput!) {
    createSurveyGroup(input: $input) {
      id
    }
  }
`;

export const createSurveyGroupWithRespondentsMutation = gql`
  mutation createSurveyGroupWithRespondents($input: CreateSurveyGroupWithRespondentsInput!) {
    createSurveyGroupWithRespondents(input: $input) {
      id
    }
  }
`;

export const createQuestionDefinitionMutation = gql`
  mutation createQuestionDefinition(
    $topicId: ID!
    $input: QuestionDefinitionInput!
    $followingQuestionIndex: Int
    $precedingQuestionIndexes: [Int]
    $precedingRuleValue: String
  ) {
    createQuestionDefinition(
      topicId: $topicId
      input: $input
      followingQuestionIndex: $followingQuestionIndex
      precedingQuestionIndexes: $precedingQuestionIndexes
      precedingRuleValue: $precedingRuleValue
    ) {
      id
      index
      topic {
        id
        startingQuestion {
          id
        }
        allQuestions {
          id
          text
          index
          translations {
            language {
              id
            }
            text
          }
          rules {
            order
            operator
            nextQuestion {
              id
              index
            }
            origValue
            isFromDefault
          }
        }
        topicGroup {
          id
        }
      }
      rules {
        order
        origValue
        operator
        nextQuestion {
          id
        }
      }
    }
  }
`;

export const updateQuestionDefinitionMutation = gql`
  mutation updateQuestionDefinition($id: ID!, $input: QuestionDefinitionInput!) {
    updateQuestionDefinition(id: $id, input: $input) {
      id
      startingQuestion {
        id
      }
      allQuestions {
        index
        id
      }
    }
  }
`;

export const importTopicMutation = gql`
  mutation importTopic($topic: TopicInput!, $questions: [QuestionDefinitionInput!]!, $isTesting: Boolean) {
    importTopic(topic: $topic, questions: $questions, isTesting: $isTesting) {
      id
    }
  }
`;

export const cloneTopicWithTopicGroupMutation = gql`
  mutation cloneTopicWithTopicGroup($id: ID!) {
    cloneTopicWithTopicGroup(topicId: $id) {
      topicGroup {
        id
      }
    }
  }
`;

export const cloneProcessTopicGroupMutation = gql`
  mutation cloneProcessTopicGroup($id: ID!) {
    cloneProcessTopicGroup(topicGroupId: $id) {
      id
    }
  }
`;

export const deleteQuestionDefinitionMutation = gql`
  mutation deleteQuestionDefinition($id: ID!, $replaceWithQuestionWithIndex: ID) {
    deleteQuestionDefinition(id: $id, replaceWithQuestionWithIndex: $replaceWithQuestionWithIndex) {
      id
      startingQuestion {
        id
      }
      allQuestions {
        index
        id
      }
    }
  }
`;

export const addRespondentsToSurveyGroupMutation = gql`
  mutation addRespondentsToSurveyGroup(
    $surveyGroupId: ID!
    $respondentIds: [ID!]
    $addedOn: Date!
    $runOngoingSteps: [ID!]
  ) {
    addRespondentsToSurveyGroup(
      respondentIds: $respondentIds
      surveyGroupId: $surveyGroupId
      addedOn: $addedOn
      runOngoingSteps: $runOngoingSteps
    )
  }
`;

export const createTopicGroupFromDefaultMutation = gql`
  mutation createTopicGroupFromDefault(
    $organizationId: ID!
    $name: String!
    $description: String!
    $typeCode: TopicGroupTypeCode
  ) {
    createTopicGroupFromDefault(
      organizationId: $organizationId
      name: $name
      description: $description
      typeCode: $typeCode
    ) {
      id
      topics {
        id
      }
    }
  }
`;

export const createTopicGroupMutation = gql`
  mutation createTopicGroup($input: createTopicGroupInput!) {
    createTopicGroup(input: $input) {
      id
    }
  }
`;

export const changeAddedOnForSurveyGroupMutation = gql`
  mutation changeAddedOnForSurveyGroup($surveyGroupId: ID!, $oldAddedOn: Date!, $newAddedOn: Date!) {
    changeAddedOnForSurveyGroup(surveyGroupId: $surveyGroupId, oldAddedOn: $oldAddedOn, newAddedOn: $newAddedOn)
  }
`;

export const removeRespondentsFromSurveyGroupMutation = gql`
  mutation removeRespondentsFromToSurveyGroup($surveyGroupId: ID!, $respondentIds: [ID!]) {
    removeRespondentsFromSurveyGroup(surveyGroupId: $surveyGroupId, respondentIds: $respondentIds)
  }
`;

export const deleteOrganizationSurveyMutation = gql`
  mutation deleteOrganizationSurvey($surveyId: ID!) {
    deleteOrganizationSurvey(surveyId: $surveyId)
  }
`;

export const setTimezoneAndCountry = gql`
  mutation setTimezoneAndCountry($input: UpdateOrganizationConfigInput!) {
    updateOrganizationConfig(input: $input) {
      timezone
      countryCode
    }
  }
`;

export const updateSurveyMutation = gql`
  mutation UpdateSurvey($surveyId: ID!, $input: UpdateSurveyInput!) {
    updateSurvey(surveyId: $surveyId, input: $input) {
      id
      topic {
        id
      }
      topicGroup {
        id
      }
    }
  }
`;

export const reopenSurveyMutation = gql`
  mutation ReopenSurvey($input: ReopenSurveyInput!) {
    reopenSurvey(input: $input) {
      id
    }
  }
`;

export const sendInvitationAgainMutation = gql`
  mutation SendInvitationAgain($surveyId: ID!, $respondentId: ID!, $templateUid: String) {
    sendInvitationAgain(surveyId: $surveyId, respondentId: $respondentId, templateUid: $templateUid)
  }
`;

export const createSurveyMutation = gql`
  mutation CreateSurvey($input: CreateSurveyInput!) {
    createSurvey(input: $input) {
      id
      sendAt
      topic {
        id
      }
      topicGroup {
        id
      }
    }
  }
`;

export const createTestSurveyMutation = gql`
  mutation CreateTestSurvey($input: ICreateTestSurveyInput!) {
    createTestSurvey(input: $input)
  }
`;

export const createTeamMutation = gql`
  mutation createTeam($respondentId: ID!, $teamName: String) {
    createTeam(respondentId: $respondentId, teamName: $teamName) {
      id
      name
    }
  }
`;

export const updateOrganizationConfigMutation = gql`
  mutation UpdateOrganizationConfig($input: UpdateOrganizationConfigInput!) {
    updateOrganizationConfig(input: $input) {
      timezone
      countryCode
      countryCallingCode
      organization {
        primaryLanguageCode
        primaryLanguageId
      }
    }
  }
`;

export const setTeamNameMutation = gql`
  mutation setTeamName($teamId: ID!, $teamName: String!) {
    setTeamName(teamId: $teamId, teamName: $teamName) {
      id
    }
  }
`;

export const verifyPhoneNumbersMutation = gql`
  mutation verifyPhoneNumbers($phoneNumbers: [String!]!) {
    verifyPhoneNumbers(phoneNumbers: $phoneNumbers) {
      specifiedPhoneNumber
      phoneNumber
    }
  }
`;

export const deleteRespondentMutation = gql`
  mutation DeleteRespondent($respondentId: ID!) {
    deleteRespondent(respondentId: $respondentId)
  }
`;

export const setUserMutation = gql`
  mutation SetUser($input: UserInput!) {
    setUser(input: $input) {
      id
    }
  }
`;

export const assignOrganizationMutation = gql`
  mutation assignOrganization($organizationId: ID!) {
    assignOrganization(organizationId: $organizationId)
  }
`;

export const updateReportRecipient = gql`
  mutation UpdateReportRecipient($id: ID!, $language: ID!) {
    updateReportRecipient(id: $id, language: $language) {
      id
    }
  }
`;

export const acceptReports = gql`
  mutation AcceptReports($reportAccepts: [ReportAccept!]!) {
    acceptReports(reportAccepts: $reportAccepts)
  }
`;

export const updateProcessStepMutation = gql`
  mutation updateProcessStep($id: ID!, $input: UpdateProcessStepInput!) {
    updateProcessStep(id: $id, input: $input) {
      id
      startOffsetMins
      reminders {
        reminderOffsetMins
      }
      endOffsetMins
    }
  }
`;

export const createProcessStepMutation = gql`
  mutation createProcessStep($input: CreateProcessStepInput!) {
    createProcessStep(input: $input) {
      id
      stepTopicGroup {
        id
        lastValidTopic {
          id
        }
      }
    }
  }
`;

export const deleteProcessStepMutation = gql`
  mutation deleteProcessStep($id: ID!) {
    deleteProcessStep(id: $id)
  }
`;

export const registrationMutation = gql`
  mutation registration($userInput: UserInput!, $organizationInput: CreateOrganizationInput!, $password: String!) {
    registration(userInput: $userInput, organizationInput: $organizationInput, password: $password) {
      id
    }
  }
`;

export const updateOrganizationMutation = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      id
      name
    }
  }
`;

export const setSurveyArchivedMutation = gql`
  mutation setSurveyArchived($surveyId: ID!, $reverse: Boolean) {
    setSurveyArchived(surveyId: $surveyId, reverse: $reverse)
  }
`;

export const setSurveyGroupArchivedMutation = gql`
  mutation setSurveyGroupArchived($surveyGroupId: ID!, $reverse: Boolean) {
    setSurveyGroupArchived(surveyGroupId: $surveyGroupId, reverse: $reverse)
  }
`;

export const generateOtpMutation = gql`
  mutation generateOtp($username: String!) {
    generateOtp(username: $username)
  }
`;

export const sendEmailConfirmationMutation = gql`
  mutation sendEmailConfirmation($email: String!, $language: String!) {
    sendEmailConfirmation(email: $email, language: $language)
  }
`;

export const confirmEmailMutation = gql`
  mutation confirmEmail($deviceToken: String!) {
    confirmEmail(deviceToken: $deviceToken)
  }
`;

export const sendOrderMutation = gql`
  mutation sendOrder($input: SendOrderInput!) {
    sendOrder(input: $input)
  }
`;

export const createSalesOrganizationMutation = gql`
  mutation createSalesOrganization($input: CreateSalesOrganizationInput!) {
    createSalesOrganization(input: $input) {
      id
    }
  }
`;

export const importOrganizationMutation = gql`
  mutation importOrganizaton(
    $file: Upload!
    $surveyGroups: [SurveyGroupRespondentImport]!
    $surveys: [SurveyRespondentImport]!
  ) {
    importOrganization(file: $file, surveyGroups: $surveyGroups, surveys: $surveys) {
      statistics {
        added
        autogenerated
        deleted
        imported
        updated
      }
    }
  }
`;

export const enableFeatureMutation = gql`
  mutation enableFeature($organizationId: ID!, $feature: Features!) {
    enableFeature(organizationId: $organizationId, feature: $feature)
  }
`;

export const disableFeatureMutation = gql`
  mutation disableFeature($organizationId: ID!, $feature: Features!) {
    disableFeature(organizationId: $organizationId, feature: $feature)
  }
`;

export const logFEEvent = gql`
  mutation logFEEvent($eventType: FEEventType!, $payload: FEEventPayload!) {
    logFEEvent(eventType: $eventType, payload: $payload)
  }
`;

export const renameSurveyGroupMutation = gql`
  mutation renameSurveyGroup($name: String!, $surveyGroupId: ID!) {
    renameSurveyGroup(name: $name, surveyGroupId: $surveyGroupId) {
      name
      id
    }
  }
`;

export const createNewRespondentChannelsMutation = gql`
  mutation createNewRespondentChannels($surveyId: ID!, $respondentIds: [Int!]) {
    createNewRespondentChannels(surveyId: $surveyId, respondentIds: $respondentIds) {
      id
    }
  }
`;

export const deleteTopicGroupMutation = gql`
  mutation deleteTopicGroup($id: ID!) {
    deleteTopicGroup(id: $id)
  }
`;

export const sendPreviewConversationMutation = gql`
  mutation sendPreviewConversation(
    $organizationName: String!
    $topicGroupId: ID!
    $languageCode: String!
    $contactType: ContactType!
    $contactValue: String!
  ) {
    sendPreviewConversation(
      organizationName: $organizationName
      topicGroupId: $topicGroupId
      languageCode: $languageCode
      contactType: $contactType
      contactValue: $contactValue
    )
  }
`;

export const sendPreviewReportMutation = gql`
  mutation sendPreviewReport($topicGroupId: ID!, $languageCode: String!, $email: String!) {
    sendPreviewReport(topicGroupId: $topicGroupId, languageCode: $languageCode, email: $email)
  }
`;

export const createPublicSurveyMutation = gql`
  mutation createPublicSurvey($input: CreatePublicSurveyInput!) {
    createPublicSurvey(input: $input) {
      id
      accessKey
      topic {
        name
      }
    }
  }
`;

export const createCommentMutation = gql`
  mutation createComment($input: CreateCommentInput!) {
    createComment(input: $input) {
      id
      positionX
      positionY
      topicId
      comments {
        id
        text
        originator {
          id
          firstname
          surname
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const createCommentGroupMutation = gql`
  mutation createCommentGroup($input: CreateCommentGroupInput!) {
    createCommentGroup(input: $input) {
      id
      positionX
      positionY
      topicId
      comments {
        id
        text
        originator {
          id
          firstname
          surname
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const deleteCommentMutation = gql`
  mutation deleteCommentGroup($id: ID!) {
    deleteCommentGroup(id: $id)
  }
`;

export const updateCommentGroupPositionMutation = gql`
  mutation updateCommentGroupPosition($id: ID!, $positionX: Int!, $positionY: Int!) {
    updateCommentGroupPosition(id: $id, positionX: $positionX, positionY: $positionY) {
      id
      positionX
      positionY
      topicId
      comments {
        id
        text
        originator {
          id
          firstname
          surname
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const updateCommentMutation = gql`
  mutation updateCommentMutation($id: ID!, $text: String!) {
    updateComment(id: $id, text: $text) {
      id
      positionX
      positionY
      topicId
      comments {
        id
        text
        originator {
          id
          firstname
          surname
        }
        createdAt
        updatedAt
      }
    }
  }
`;

export const deleteSurveyGroupMutation = gql`
  mutation deleteSurveyGroup($surveyGroupId: ID!) {
    deleteSurveyGroup(surveyGroupId: $surveyGroupId)
  }
`;
