import { BodySmall, Breadcrumb, HeadingLarge, HeadingMedium, theme } from '@arnold/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import TopicPreview from '../Topic/TopicPreview';
import { getTopicGroupTranslationByLanguageId } from '../../lib/common';
import { PageWrapper } from '../../components/Common';
import { prepareQuestion, prepareSections, QuestionDetail, TopicStructure } from '../Topic/topicStructure';
import { ErrorPage } from '../../components/StyledComponents';
import { Loading } from '../../components';
import { prepareStepOffsets } from '../../lib/process';
import {
  TopicGroupQuery,
  useCreateProcessStepMutation,
  useTopicDetailLazyQuery,
  useTopicGroupLazyQuery,
  useUserQuery,
} from '../../generated/hooks';
import { StepInfoBox } from './StepInfoBox';

const defaultStartOffset = 540;
const defaultReminderOffset = 2880;
const defaultSecondReminderOffset = 7200;
const defaultEndOffset = 8640;

const ProcessAddStepScreen = (props: RouteComponentProps<any>) => {
  const {
    match: { params },
  } = props;
  const { t } = useTranslation('processDetail');
  const [topicStructure, setTopicStructure] = useState<TopicStructure | null>(null);
  const [allQuestions, setAllQuestions] = useState<QuestionDetail[]>([]);
  let languageId: string = params.language;

  const [createStep] = useCreateProcessStepMutation();

  const [loadTopicDetail, { loading: topicDetailLoading, error: topicDetailError, data: topicDetailData }] =
    useTopicDetailLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: (responseData) => {
        const allQuestions = responseData.topicDetail.allQuestions.map(prepareQuestion);
        setAllQuestions(allQuestions);
        const newTopicStructure = prepareSections(allQuestions);
        setTopicStructure(newTopicStructure);
      },
    });
  const [loadTopicGroup, { loading: topicGroupLoading, error: topicGroupError, data: topicGroupData }] =
    useTopicGroupLazyQuery();

  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useUserQuery({
    onCompleted: (responseData) => {
      if (!responseData.user.organization) {
        return;
      }
      languageId = params.language || responseData.user.organization.primaryLanguageId.toString(10);
      loadTopicDetail({
        variables: {
          topicId: params.topicId,
        },
      });
      loadTopicGroup({
        variables: { topicGroupId: params.topicGroupId, language: languageId },
      });
    },
  });

  if (topicGroupError || userError || topicDetailError) {
    return <ErrorPage>{t('common:loadError')}</ErrorPage>;
  }
  if (
    topicGroupLoading ||
    topicDetailLoading ||
    userLoading ||
    !topicGroupData ||
    !userData ||
    !topicDetailData ||
    !topicStructure
  ) {
    return <Loading />;
  }

  const { organization } = userData.user;
  if (!organization || !topicDetailData) {
    return null;
  }
  const processTopicGroupTranslation = getTopicGroupTranslationByLanguageId(
    languageId,
    topicGroupData.topicGroup.translations,
  );
  const stepTopicGroupTranslation = getTopicGroupTranslationByLanguageId(
    languageId,
    topicDetailData.topicDetail.topicGroup.translations,
  );
  const offsets = prepareStepOffsets({
    startOffsetMins: defaultStartOffset,
    remindersOffsetMins: [defaultReminderOffset, defaultSecondReminderOffset],
    endOffsetMins: defaultEndOffset,
  });

  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb
        classList={'mt-n6'}
        items={[
          { title: t('teamsScreen:surveys'), link: '/overview' },
          { title: t('ReportAccess:topics'), link: '/topics' },
          {
            title: processTopicGroupTranslation ? processTopicGroupTranslation.value : topicGroupData.topicGroup.name,
            link: `/process/${params.topicGroupId}`,
          },
          {
            title: t('processDetail:chooseTopic'),
            link: `/process/${params.topicGroupId}/step/add`,
          },
          {
            title: stepTopicGroupTranslation
              ? stepTopicGroupTranslation.value
              : topicDetailData.topicDetail.topicGroup.name,
            active: true,
          },
        ]}
      />
      <HeadingLarge>
        {stepTopicGroupTranslation ? stepTopicGroupTranslation.value : topicDetailData.topicDetail.topicGroup.name}
      </HeadingLarge>
      <p>
        {stepTopicGroupTranslation
          ? stepTopicGroupTranslation.description
          : topicDetailData.topicDetail.topicGroup.description}
      </p>

      <HeadingMedium margin={`${theme.spacing.h} 0 ${theme.spacing.c}`}>{t('datesTitle')}</HeadingMedium>
      <BodySmall>{t('datesDescription')}</BodySmall>
      <StepInfoBox
        start={offsets.startOffset}
        reminder={offsets.remindersOffset?.[0] || null}
        secondReminder={offsets.remindersOffset?.[1] || null}
        thirdReminder={offsets.remindersOffset?.[2] || null}
        end={offsets.endOffset}
        topicGroupId={params.topicGroupId}
        isNew={true}
        saveForm={async (
          offsets: Pick<NonNullable<TopicGroupQuery['topicGroup']['steps']>[0], 'startOffsetMins' | 'endOffsetMins'> & {
            remindersOffsetMins: number[];
          },
        ) => {
          await createStep({
            variables: {
              input: {
                processTopicGroupId: params.topicGroupId,
                stepTopicGroupId: topicDetailData.topicDetail.topicGroup.id,
                startOffsetMins: offsets.startOffsetMins,
                remindersOffsetMins: offsets.remindersOffsetMins,
                endOffsetMins: offsets.endOffsetMins,
              },
            },
          });
        }}
      />
      <HeadingMedium>{t('chat')}</HeadingMedium>
      <TopicPreview
        isEditable={false}
        activeSection={null}
        setActiveSection={() => null}
        sectionInEditMode={null}
        setSectionInEditMode={() => null}
        topicDetail={topicDetailData.topicDetail}
        topicStructure={topicStructure}
        allQuestions={allQuestions}
        languageId={organization.primaryLanguageId.toString(10)}
        urlBase={`/process/${params.topicGroupId}/step/add/${params.topicId}`}
        organization={organization}
        reloadTopic={async () => {
          loadTopicDetail({
            variables: {
              topicId: params.topicId,
            },
          });
        }}
      />
    </PageWrapper>
  );
};

export default ProcessAddStepScreen;
