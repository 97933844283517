import { Field, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { CardBody, FormSelect, getFrontendConfigValue, Option } from '@arnold/common';
import { Button, FormControl } from 'react-bootstrap';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { ENVIRONMENT } from '@arnold/core';
import { GraphQLErrorCard, Loading } from '../../components';
import i18n from '../../translations/i18n';
import { usePublicTopicGroupsQuery, useSendPreviewReportMutation } from '../../generated/hooks';
import { Card, FormErrorFeedback, FormGroupLabel, FormRadioInput } from '../../components/Common';
import { languages } from './constants';
import { StyledFormGroup, Notification } from './styles';

export const PreviewReportForm = () => {
  const [t] = useTranslation('preview');
  const showCaptcha = ![ENVIRONMENT.LOCAL, ENVIRONMENT.DEVEL].includes(getFrontendConfigValue('ENVIRONMENT'));
  const [isConfirmed, setIsConfirmed] = useState(!showCaptcha);
  const [isSent, setIsSent] = useState(false);

  const {
    loading: topicGroupLoading,
    error,
    data,
  } = usePublicTopicGroupsQuery({
    variables: {
      language: i18n.language,
      product: 'PREVIEW',
    },
  });
  const [sendPreviewReportMutation, { loading }] = useSendPreviewReportMutation();

  const initialValues = {
    name: '',
    topicGroup: data?.publicTopicGroups[0]?.id,
    language: languages[0],
    email: '',
  };

  const PreviewReportFormValidationSchema = Yup.object().shape({
    name: Yup.string().required(t('enterName')),
    email: Yup.string().email(t('registration:enterValidEmail')).required(t('registration:enterValidEmail')),
  });

  const handleSubmit = async (values: FormikValues) => {
    sendPreviewReportMutation({
      variables: {
        topicGroupId: values.topicGroup,
        languageCode: values.language,
        email: values.email,
      },
    }).then(() => {
      values = initialValues;
      setIsSent(true);
    });
  };

  if (loading || topicGroupLoading) {
    return <Loading />;
  }

  if (error) {
    return <GraphQLErrorCard />;
  }

  if (isSent) {
    return (
      <Notification>
        <p>{t('reportSent')}</p>
      </Notification>
    );
  }

  return (
    <Formik initialValues={initialValues} validationSchema={PreviewReportFormValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, handleSubmit, handleChange, handleBlur, setFieldValue, isSubmitting, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <StyledFormGroup>
                <FormGroupLabel>{t('name')}</FormGroupLabel>
                <FormControl
                  type="text"
                  name="name"
                  value={values.name || undefined}
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                  }}
                  isInvalid={!!errors.name}
                />
                {errors.name && <FormErrorFeedback error={errors.name} />}
              </StyledFormGroup>
              <StyledFormGroup>
                <FormGroupLabel>{t('report')}</FormGroupLabel>
                <Field
                  component={FormSelect}
                  name="topicGroup"
                  options={data?.publicTopicGroups.map((topicGroup) => ({
                    value: topicGroup?.id,
                    label: topicGroup?.name,
                  }))}
                  defaultValue={values.topicGroup}
                  onChange={(option: Option) => {
                    setFieldValue('topicGroup', option.value);
                  }}
                  onBlur={handleBlur}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <FormGroupLabel>{t('language')}</FormGroupLabel>
                {languages.map((language) => (
                  <FormRadioInput
                    name="language"
                    value={values.language}
                    clicked={() => {
                      setFieldValue('language', language);
                    }}
                    changed={handleChange}
                    onBlur={handleBlur}
                    defaultChecked={values.language === language}
                    label={t(`languageSelector:${language}`)}
                  />
                ))}
              </StyledFormGroup>
              <StyledFormGroup>
                <FormGroupLabel>{t('email')}</FormGroupLabel>
                <FormControl
                  type="text"
                  name="email"
                  value={values.email || undefined}
                  onChange={(e) => {
                    setFieldValue('email', e.target.value);
                  }}
                  isInvalid={!!errors.email}
                />
                {errors.email && <FormErrorFeedback error={errors.email} />}
              </StyledFormGroup>
              {showCaptcha && (
                <ReCAPTCHA
                  sitekey={getFrontendConfigValue('RECAPTCHA_SITE_KEY')}
                  onChange={() => setIsConfirmed(true)}
                  onExpired={() => setIsConfirmed(false)}
                />
              )}
              <div>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isEmpty(errors) || isSubmitting || !isConfirmed || !dirty}
                >
                  {t('send')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
