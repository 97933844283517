import { ENVIRONMENT } from '@arnold/core';
import styled from '@emotion/styled';
import { theme } from '../../theme';

type IProps = {
  env: string;
};

const Ribbon = styled.span`
  z-index: 2147483642;
  width: 100px;
  padding: ${theme.spacing.c};
  position: fixed;
  text-align: center;
  top: ${theme.spacing.c};
  left: -29px;
  transform: rotate(-45deg);
  background-color: ${theme.colors.emotionDanger.active};
  color: ${theme.colors.actionInvertedPrimary.default};
  font-weight: 600;
`;

const DevRibbon = styled(Ribbon)`
  background-color: ${theme.colors.emotionDanger.active};
`;

const DeployRibbon = styled(Ribbon)`
  background-color: ${theme.colors.emotionWarning.active};
`;

export const EnvironmentRibbon = ({ env }: IProps) => {
  if (env === ENVIRONMENT.PROD) {
    return <></>;
  }
  if (env === ENVIRONMENT.DEV) {
    return <DevRibbon>{env}</DevRibbon>;
  }
  if (env === ENVIRONMENT.DEPLOY) {
    return <DeployRibbon>{env}</DeployRibbon>;
  }
  return <Ribbon>{env}</Ribbon>;
};
