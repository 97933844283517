import { useTranslation } from 'react-i18next';
import { FormControl, FormGroup } from 'react-bootstrap';
import { FC } from 'react';
import { FormGroupLabel } from '../Common';

type RenameProcessSurveyFormProps = {
  name: string;
  setName: (name: string) => void;
  isOnboarding?: boolean;
};

export const RenameProcessSurveyForm: FC<RenameProcessSurveyFormProps> = ({ name, setName, isOnboarding }) => {
  const [onboardingT] = useTranslation('onboarding');
  const [surveyGroupT] = useTranslation('surveyGroup');

  return (
    <FormGroup>
      <FormGroupLabel data-icom={'label-process-survey-name'}>
        {isOnboarding ? onboardingT('surveyGroupName') : surveyGroupT('surveyGroupName')}
      </FormGroupLabel>
      <FormControl
        type="text"
        name={'name'}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
    </FormGroup>
  );
};
