import { ProductCode, getLocale, getLocalizedDateWithoutLeadingZeroes, prepareSurveyDates } from '@arnold/core';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { ConditionalWrapper, ProductHistory, Tooltip, isNullish, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { SurveyGroupQuery, SurveyGroupsQuery, SurveyStatus } from '../../generated/hooks';
import { getTGTranslation } from '../../lib/helpers/surveyGroup';
import { prepareStepOffsets } from '../../lib/process';
import { Table } from '../Table/Table';

type SurveyGroupSurveysTableProps = {
  topicGroup: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]['topicGroup'];
  addedOn: Date | null;
  productHistory?: ProductHistory[] | null;
};

type SurveyGroupSurveysData = Pick<
  NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['surveys'][0],
  'id'
> & {
  topic: string;
  startDate: string;
  status: string;
  rawStatus?: SurveyStatus;
  daysFromStart: string;
  willNotBeSent: boolean;
};

const StyledTooltip = styled(Tooltip)`
  display: contents !important;
  & td {
    color: ${theme.colors.emotionDanger.default} !important;
  }
`;
export const getDataSource = (
  topicGroup: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]['topicGroup'],
  addedOn: Date | null,
  language: string,
  t: TFunction,
  productHistory?: ProductHistory[] | null,
): SurveyGroupSurveysData[] =>
  topicGroup.steps
    ?.map((step) => {
      const offsets = prepareStepOffsets({
        startOffsetMins: step.startOffsetMins,
        endOffsetMins: step.endOffsetMins,
        remindersOffsetMins: step.reminders.map((reminder) => reminder.reminderOffsetMins),
      });
      if (isNullish(offsets) || isNullish(offsets.startOffset?.days)) {
        return null;
      }
      const diffInDaysFromStep = offsets.startOffset!.days;
      const basicDescription = {
        id: step.id,
        status: '-',
        rawStatus: undefined,
        topic: getTGTranslation(step.stepTopicGroup.translations, language)!.value || '',
        startDate: '-',
        daysFromStart: t('processDetail:dayText', {
          dayNo: diffInDaysFromStep + (diffInDaysFromStep >= 0 ? 1 : 0),
        }),
      };
      if (!addedOn) {
        return basicDescription;
      }
      const groupAddedOn = startOfDay(addedOn);
      const surveyDate = prepareSurveyDates(step, groupAddedOn, true);
      if (!surveyDate) {
        return basicDescription!;
      }
      const surveyStart = surveyDate.sendAt;
      const now = new Date();
      const diffInDays = differenceInDays(startOfDay(surveyDate.sendAt), startOfDay(groupAddedOn));
      const status =
        surveyDate.endsAt < now
          ? SurveyStatus.Completed
          : surveyDate.sendAt < now
            ? SurveyStatus.Ongoing
            : SurveyStatus.Prepared;

      const activeProductAtTheSurveyStart = productHistory?.find(
        (ph) =>
          new Date(ph.validFrom).getTime() <= surveyStart.getTime() &&
          new Date(ph.validTo).getTime() >= surveyStart.getTime(),
      );
      const willNotBeSent =
        surveyStart > new Date() &&
        (!activeProductAtTheSurveyStart || activeProductAtTheSurveyStart.product === ProductCode.Free);
      return {
        ...basicDescription,
        rawStatus: status,
        willNotBeSent,
        status: t(`survey:status-${status === SurveyStatus.Completed || willNotBeSent ? 'NOTCREATED' : status}`),
        startDate: `${getLocalizedDateWithoutLeadingZeroes(surveyStart, language)} ${format(surveyStart, 'p', {
          locale: getLocale(language),
        })}`,
        daysFromStart: t('processDetail:dayText', {
          dayNo: diffInDays + (diffInDays >= 0 ? 1 : 0),
        }),
      };
    })
    .filter((item): item is SurveyGroupSurveysData => !!item) ?? [];

export const SurveyGroupSurveysPreviewTable: FC<SurveyGroupSurveysTableProps> = ({
  topicGroup,
  addedOn,
  productHistory,
}) => {
  const { i18n, t } = useTranslation('surveyGroup');

  return (
    <Table<SurveyGroupSurveysData>
      thin={true}
      className="mt-8"
      columns={[
        {
          key: 'topic',
          title: t('topic'),
          dataIndex: 'topic',
          width: '25%',
        },
        {
          key: 'daysFromStart',
          title: t('SequenceSteps:startOffset'),
          dataIndex: 'daysFromStart',
          width: '25%',
        },
        {
          key: 'startDate',
          title: t('surveyStartDate'),
          dataIndex: 'startDate',
          width: '25%',
        },
        {
          key: 'status',
          title: t('surveyGroupStatus'),
          dataIndex: 'status',
          width: '25%',
        },
      ]}
      wrapRow={(data, component) => {
        return (
          <ConditionalWrapper
            condition={data.willNotBeSent}
            wrapper={(children) => <StyledTooltip title={t('teamioOnb:willNotBeSent')}>{children}</StyledTooltip>}
          >
            {component}
          </ConditionalWrapper>
        );
      }}
      dataSources={getDataSource(topicGroup, addedOn, i18n.language, t, productHistory)}
      selected={undefined}
    />
  );
};
