import { IThemeProps } from '@arnold/common';
import styled from '@emotion/styled/macro';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ClassicLink } from './StyledComponents';

const InformationFooter = () => {
  const [t] = useTranslation('informationFooter');

  return (
    <FooterWrapper>
      <InformationTextBlock>{t('helpQuestion')}</InformationTextBlock>
      <InformationTextBlock>
        <ClassicLink href={'mailto:' + t('helpEmail')}>{t('helpEmail')}</ClassicLink>
      </InformationTextBlock>
      <InformationTextBlock>
        <ClassicLink href={'tel:' + t('helpNumber')}>{t('helpNumber')}</ClassicLink>
      </InformationTextBlock>
    </FooterWrapper>
  );
};

const FooterWrapper = styled.div(({ theme }: IThemeProps) => ({
  width: '100%',
  padding: '0px 30px',
  margin: '64px 0px 16px 0px',
}));

const InformationTextBlock = styled.span`
  margin: 0px 6px 0px 0px;
`;

export default InformationFooter;
