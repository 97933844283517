import { FormControl, FormGroup } from 'react-bootstrap';
import { FormikErrors } from 'formik';
import { checkValueOrThrowError, isString, StringKeysOf } from '@arnold/common';
import { FormErrorFeedback, FormGroupLabel } from '../../../../components/Common';
import { ValueType } from './types';

// @todo - move me if needed
type TextInputProps<V extends ValueType> = {
  errors: FormikErrors<V>;
  setFieldValue: (key: StringKeysOf<V>, value: string) => void;
  values: V;
  title: string;
  name: StringKeysOf<V>;
};
export const TextInput = <V extends ValueType>({
  title,
  name,
  values,
  errors,
  setFieldValue,
}: TextInputProps<V>): JSX.Element => {
  return (
    <FormGroup>
      <FormGroupLabel>{title}</FormGroupLabel>
      <FormControl
        type="text"
        name={name}
        value={checkValueOrThrowError(values[name], isString) || undefined}
        onChange={(e) => {
          setFieldValue(name, e.target.value);
        }}
        isInvalid={!!errors[name]}
      />
      {errors[name] && <FormErrorFeedback error={errors[name]} />}
    </FormGroup>
  );
};
