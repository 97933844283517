import { TFunction } from 'react-i18next';
import { isNullish } from '@arnold/common';
import { getIsReminderSet, prepareStepOffsets } from '../../lib/process';
import { TopicGroupQuery } from '../../generated/hooks';
import { getOffsetString } from './utils';
import { ProcessStepLink } from './ProcessStepLink';

type GetDataSourceArgs = {
  steps: TopicGroupQuery['topicGroup']['steps'];
  processTopicGroupId: string;
  t: TFunction;
  hideActions?: boolean;
};

export const getDataSource = ({ steps, processTopicGroupId, t, hideActions }: GetDataSourceArgs) => {
  if (!steps) {
    return [];
  }
  return steps.map((step) => {
    const offsets = prepareStepOffsets({
      startOffsetMins: step.startOffsetMins,
      endOffsetMins: step.endOffsetMins,
      remindersOffsetMins: step.reminders.map((reminder) => reminder.reminderOffsetMins),
    });
    return {
      id: step.id,
      name: hideActions ? (
        step.stepTopicGroup.lastValidTopic?.name
      ) : (
        <ProcessStepLink
          step={step}
          processTopicGroupId={processTopicGroupId}
          label={step.stepTopicGroup.lastValidTopic?.name}
        />
      ),
      startOffsetMins: isNullish(offsets.startOffset) ? '-' : getOffsetString(offsets.startOffset, t),
      reminderOffsetMins:
        isNullish(offsets.startOffset) || isNullish(offsets.remindersOffset?.[0])
          ? '-'
          : !getIsReminderSet(offsets.startOffset, offsets.remindersOffset?.[0])
            ? '-'
            : getOffsetString(offsets.remindersOffset?.[0]!, t),
      secondReminderOffsetMins:
        isNullish(offsets.startOffset) || isNullish(offsets.remindersOffset?.[1])
          ? '-'
          : !getIsReminderSet(offsets.startOffset, offsets.remindersOffset?.[1])
            ? '-'
            : getOffsetString(offsets.remindersOffset?.[1]!, t),
      thirdReminderOffsetMins:
        isNullish(offsets.startOffset) || isNullish(offsets.remindersOffset?.[2])
          ? '-'
          : !getIsReminderSet(offsets.startOffset, offsets.remindersOffset?.[2])
            ? '-'
            : getOffsetString(offsets.remindersOffset?.[2]!, t),
      endOffsetMins: isNullish(offsets.endOffset) ? '-' : getOffsetString(offsets.endOffset, t),
      actions: !hideActions && (
        <ProcessStepLink step={step} processTopicGroupId={processTopicGroupId} label={t('arnoldConversation')} />
      ),
    };
  });
};
