import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router';
import { Loading } from '../../components';
import { ErrorPage } from '../../components/StyledComponents';
import { TopicEditor } from '../../components/TopicEditor';
import { IsAccessTokenValidQuery, OrganizationQuery, useTopicDetailQuery } from '../../generated/hooks';

interface IParams {
  languageId?: string;
  topicGroupId: string;
}

interface IProps extends RouteComponentProps<IParams> {
  organization: OrganizationQuery['organization'];
  user: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
}

export const SvenPreviewScreen: FC<IProps> = ({ match, user, organization }) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const topicGroupId = match.params.topicGroupId;
  const topicIdFromParams = new URLSearchParams(history.location.search).get('topicId') || undefined;
  const languageId = match.params.languageId;

  const { loading, error, data } = useTopicDetailQuery({
    variables: {
      topicGroupId,
      topicId: topicIdFromParams,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ErrorPage>{t('loadError')}</ErrorPage>;
  }

  return (
    <>
      <TopicEditor
        preview={true}
        topicDetail={data.topicDetail}
        topicGroup={data.topicDetail.topicGroup}
        organization={organization}
        user={user}
        languageCodeOrId={languageId}
      />
    </>
  );
};
