import { ActionMenu, theme, useToast } from '@arnold/common';
import { ReactComponent as ActionMenuIcon } from '@arnold/common/lib/assets/icons/ActionMenuIcon.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { flatten, uniq } from 'ramda';
import React, { useEffect, useReducer, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Element, animateScroll } from 'react-scroll';
import { HoverWrapper, SectionControls } from '../../components/Common';
import ee from '../../lib/eventEmitter';
import EmptySectionContent from './EmptySectionContent';
import { View } from './LayoutComponents';
import Question from './Question';
import { SectionBubbleView, TopicSection } from './StyledTopicComponents';
import { getPreviousSectionRoutingQuestions, getTranslation, scrollToElement } from './helpers';
import useSectionUpdater from './hooks';
import { ActionTypes, BaseAction, getInitialState, reducer } from './sectionState';
import { QuestionDetail, QuestionMappingByOrder, Section, getPathsFrom, getPathsTo } from './topicStructure';
import { QUESTION_DEFINITION_TYPE } from './types';

const getRefTopPosition = (ref: React.RefObject<HTMLDivElement>) => {
  if (ref && ref.current) {
    const boundingBox = ref.current.getBoundingClientRect();
    return boundingBox.top;
  }
  return 0;
};
const scrollBackToOriginalPosition = (ref: React.RefObject<HTMLDivElement>, originalTopPosition: number) => {
  setTimeout(() => {
    if (ref && ref.current) {
      const newTopPosition = getRefTopPosition(ref);
      animateScroll.scrollMore(-1 * (originalTopPosition - newTopPosition), {
        duration: 250,
        smooth: true,
      });
    }
  }, 1250);
};

type SectionProps = {
  section: Section;
  allQuestions: QuestionDetail[];
  isEditable: boolean;
  allowStructureChanges?: boolean;
  allowDelete: boolean;
  topicId: string;
  sectionIndex: number;
  activeSection: number | null;
  setActiveSection: (newActiveSection: number | null) => void;
  sectionInEditMode: number | null;
  setSectionInEditMode: (newActiveSection: number | null) => void;
  topPositionOfNewSection: number | null;
  setTopPositionOfNewSection: (newPosition: number | null) => void;
  reloadTopic: () => Promise<void>;
  previousSectionRoutingQuestions?: QuestionDetail[];
  nextSectionStartingQuestion?: QuestionDetail;
  mapping: QuestionMappingByOrder;
  languageId: string;
  languageCode?: string;
  sectionIndexMapping: { [key: number]: number };
};

const SectionComponent = ({
  allQuestions,
  section,
  topicId,
  sectionIndex,
  allowStructureChanges,
  allowDelete,
  activeSection,
  setActiveSection,
  reloadTopic,
  previousSectionRoutingQuestions,
  nextSectionStartingQuestion,
  isEditable,
  mapping,
  languageId,
  languageCode,
  sectionInEditMode,
  setSectionInEditMode,
  sectionIndexMapping,
  topPositionOfNewSection,
  setTopPositionOfNewSection,
}: SectionProps) => {
  const sectionControlsRef = useRef<HTMLDivElement>(null);
  const newSectionControlsRef = useRef<HTMLDivElement>(null);
  const [state, originalDispatch] = useReducer(
    reducer,
    getInitialState(
      section,
      sectionIndex,
      languageId,
      languageCode,
      previousSectionRoutingQuestions,
      nextSectionStartingQuestion,
    ),
  );

  const dispatch = (value: BaseAction) => {
    originalDispatch({
      ...value,
      activeSection,
      setActiveSection,
    });
  };

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_STATE,
      newState: getInitialState(
        section,
        sectionIndex,
        languageId,
        languageCode,
        previousSectionRoutingQuestions,
        nextSectionStartingQuestion,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  const [newSectionState, originalNewSectionDispatch] = useReducer(
    reducer,
    getInitialState(
      null,
      sectionIndex,
      languageId,
      languageCode,
      getPreviousSectionRoutingQuestions(section, nextSectionStartingQuestion?.id),
      nextSectionStartingQuestion,
    ),
  );

  const newSectionDispatch = (value: BaseAction) => {
    originalNewSectionDispatch({
      ...value,
      activeSection,
      setActiveSection,
    });
  };

  const { t } = useTranslation('topicOverview');
  const { addToast } = useToast();
  const updateSection = useSectionUpdater({
    languageId,
    reloadTopic,
    sectionIndex,
    topicId,
    state,
  });

  const updateNewSection = useSectionUpdater({
    languageId,
    reloadTopic,
    sectionIndex,
    topicId,
    state: newSectionState,
  });

  useEffect(() => {
    const closeForm = () => {
      dispatch({
        type: ActionTypes.CLOSE_FORM,
      });
    };
    ee.subscribeSectionFormClose(sectionIndex, closeForm);

    const closeNewSectionForm = () => {
      newSectionDispatch({
        type: ActionTypes.CLOSE_NEW_SECTION_FORM,
      });
    };
    ee.subscribeNewSectionFormClose(sectionIndex, closeNewSectionForm);

    return () => {
      ee.unsubscribeSectionFormClose(sectionIndex, closeForm);
      ee.unsubscribeNewSectionFormClose(sectionIndex, closeForm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionIndex]);

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_STATE,
      newState: getInitialState(
        section,
        sectionIndex,
        languageId,
        languageCode,
        previousSectionRoutingQuestions,
        nextSectionStartingQuestion,
      ),
    });

    newSectionDispatch({
      type: ActionTypes.SET_STATE,
      newState: getInitialState(
        null,
        sectionIndex,
        languageId,
        languageCode,
        getPreviousSectionRoutingQuestions(section, nextSectionStartingQuestion?.id),
        nextSectionStartingQuestion,
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section, languageId]);

  useEffect(() => {
    if (topPositionOfNewSection) {
      scrollBackToOriginalPosition(sectionControlsRef, topPositionOfNewSection);
      setTopPositionOfNewSection(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActionMenuItems = () => {
    const items = [
      {
        label: t('actionMenu:addSelect'),
        action: () => displayNewSectionForm(QUESTION_DEFINITION_TYPE.select),
      },
      {
        label: t('actionMenu:addFreeText'),
        action: () => displayNewSectionForm(QUESTION_DEFINITION_TYPE.freetext),
      },
      {
        label: t('actionMenu:addChatBubble'),
        action: () => displayNewSectionForm(QUESTION_DEFINITION_TYPE.tell),
      },
    ];

    if (allowDelete) {
      items.push({
        label: t('delete'),
        action: deleteWholeSection,
      });
    }

    return items;
  };

  const displayEditForm = () => {
    if (activeSection !== null) {
      ee.emitModalDiscardChanges(
        () => {
          ee.emitSectionFormClose(activeSection);
          ee.emitNewSectionFormClose(activeSection);
          dispatch({
            type: ActionTypes.SHOW_FORM,
          });
          setActiveSection(null);
          setSectionInEditMode(sectionIndex);
        },
        () => {
          scrollToElement(`section_${activeSection}`);
        },
      );
    } else {
      if (sectionInEditMode !== null) {
        ee.emitSectionFormClose(sectionInEditMode);
        ee.emitNewSectionFormClose(sectionInEditMode);
      }
      dispatch({ type: ActionTypes.SHOW_FORM });
      setSectionInEditMode(sectionIndex);
    }
  };

  const displayNewSectionForm = (questionType: QUESTION_DEFINITION_TYPE) => {
    const createForm = () => {
      newSectionDispatch({
        type: ActionTypes.SHOW_NEW_SECTION_FORM,
        questionType,
      });
      newSectionDispatch({
        type: ActionTypes.SHOW_ADD_QUESTION_FORM,
        questionType,
        index: 0,
        sectionIndex: sectionIndex + 2,
      });
      setSectionInEditMode(sectionIndex);
    };
    if (activeSection !== null) {
      ee.emitModalDiscardChanges(
        () => {
          ee.emitSectionFormClose(activeSection);
          ee.emitNewSectionFormClose(activeSection);
          createForm();
        },
        () => {
          scrollToElement(`section_${activeSection}`);
        },
      );
    } else {
      if (sectionInEditMode !== null) {
        ee.emitSectionFormClose(sectionInEditMode);
        ee.emitNewSectionFormClose(sectionInEditMode);
      }
      createForm();
    }
  };

  const displayAddTellToSubsectionForm =
    (usedDispatch: React.Dispatch<BaseAction>) =>
    (question: QuestionDetail, parentQuestionId: string, choiceIndex: number, subsectionIndex: number) => {
      usedDispatch({
        type: ActionTypes.SHOW_ADD_QUESTION_SUBSECTION_FORM,
        question,
        parentQuestionId,
        choiceIndex,
        subsectionIndex,
      });
    };

  const deleteWholeSection = () => {
    if (section.questions.length === 0) {
      return;
    }
    const pathsToSection = uniq(
      getPathsTo(allQuestions, section.questions[0].id).map((path) => mapping[path.question.id] + 1),
    ).sort((a, b) => a - b);
    const lastQuestion = state.questions[state.questions.length - 1];
    const pathsFromSection = getPathsFrom(lastQuestion, section, mapping);

    ee.emitModalDeleteQuestion(sectionIndex + 1, pathsToSection.filter(Boolean), pathsFromSection, () => {
      const questionsToDelete = flatten(
        section.questions.map((question) => {
          if (state.questionSubsections) {
            const questionSubsection = state.questionSubsections.get(question.id);
            if (questionSubsection) {
              return [
                question,
                ...Object.keys(questionSubsection).reduce((acc: QuestionDetail[], key) => {
                  return [...acc, ...flatten(questionSubsection[key])];
                }, []),
              ];
            }
          }
          return [question];
        }),
      );
      updateSection([], questionsToDelete, []);
    });
  };

  const displayAddQuestionForm =
    (usedDispatch: React.Dispatch<BaseAction>, sectionIndex: number) =>
    (questionType: QUESTION_DEFINITION_TYPE, index: number) => {
      usedDispatch({
        type: ActionTypes.SHOW_ADD_QUESTION_FORM,
        questionType,
        index,
        sectionIndex,
      });
    };

  const setFocusedQuestion = (usedDispatch: React.Dispatch<BaseAction>) => (questionId: string) => {
    usedDispatch({
      type: ActionTypes.SET_FOCUSED_QUESTION,
      questionId,
    });
  };

  const deleteQuestion =
    (usedDispatch: React.Dispatch<BaseAction>) => (question: QuestionDetail, parentQuestionId?: string) => {
      usedDispatch({
        type: ActionTypes.DELETE_QUESTION,
        question,
        questionId: question.id,
        parentQuestionId,
      });

      addToast(
        t(parentQuestionId ? 'choiceRemoved' : 'questionRemoved', {
          no: sectionIndex + 1,
        }),
        5000,
        t('takeBack'),
        () => {
          usedDispatch({
            type: ActionTypes.GO_BACK,
          });
        },
        true,
      );
    };

  const updateQuestion =
    (usedDispatch: React.Dispatch<BaseAction>) => (question: QuestionDetail, parentQuestionId?: string) =>
      usedDispatch({
        type: ActionTypes.SET_QUESTION,
        question,
        questionId: question.id,
        parentQuestionId,
      });

  const deleteQuestionsForChoice =
    (usedDispatch: React.Dispatch<BaseAction>) =>
    (question: QuestionDetail, choiceIndex: number, textBubbleIndex?: number) => {
      usedDispatch({
        type: ActionTypes.DELETE_QUESTIONS_FOR_CHOICE,
        questionId: question.id,
        choiceIndex,
        textBubbleIndex,
      });
      addToast(
        t('choiceRemoved'),
        5000,
        t('takeBack'),
        () => {
          usedDispatch({
            type: ActionTypes.GO_BACK,
          });
        },
        true,
      );
    };

  const submitNewSectionForm = async () => {
    setTopPositionOfNewSection(getRefTopPosition(newSectionControlsRef));
    const questionsToUpdate = Array.from(newSectionState.questionsToUpdate.values());
    const questionsToDelete = Array.from(newSectionState.questionsToDelete.values());
    const questionsToCreate = Array.from(newSectionState.questionsToCreate.values());
    await updateNewSection(questionsToUpdate, questionsToDelete, questionsToCreate);
  };

  const submitForm = async () => {
    const originalTop = getRefTopPosition(sectionControlsRef);
    setTopPositionOfNewSection(originalTop);
    const questionsToUpdate = Array.from(state.questionsToUpdate.values());
    const questionsToDelete = Array.from(state.questionsToDelete.values());
    const questionsToCreate = Array.from(state.questionsToCreate.values());
    await updateSection(questionsToUpdate, questionsToDelete, questionsToCreate);
    scrollBackToOriginalPosition(sectionControlsRef, originalTop);
  };

  const closeForm = () => {
    const questionsToUpdate = Array.from(state.questionsToUpdate.values());
    const questionsToDelete = Array.from(state.questionsToDelete.values());
    const questionsToCreate = Array.from(state.questionsToCreate.values());
    if (questionsToUpdate.length || questionsToDelete.length || questionsToCreate.length) {
      ee.emitModalDiscardChanges(() => {
        dispatch({
          type: ActionTypes.CLOSE_FORM,
        });
        setActiveSection(null);
        setSectionInEditMode(null);
      });
    } else {
      dispatch({
        type: ActionTypes.CLOSE_FORM,
      });
      setSectionInEditMode(null);
    }
  };

  const closeAddQuestionForm = () => {
    const questionsToUpdate = Array.from(newSectionState.questionsToUpdate.values());
    const questionsToDelete = Array.from(newSectionState.questionsToDelete.values());
    const questionsToCreate = Array.from(newSectionState.questionsToCreate.values());
    if (questionsToUpdate.length || questionsToDelete.length || questionsToCreate.length) {
      ee.emitModalDiscardChanges(() => {
        newSectionDispatch({
          type: ActionTypes.CLOSE_NEW_SECTION_FORM,
        });
        setActiveSection(null);
        setSectionInEditMode(null);
      });
    } else {
      newSectionDispatch({
        type: ActionTypes.CLOSE_NEW_SECTION_FORM,
      });
      setSectionInEditMode(null);
    }
  };

  const isEmptySection = !state.questions.length;
  const isEmptyNewSection = newSectionState.displayNewSectionForm && !newSectionState.questions.length;

  return (
    <HoverWrapper>
      <Element name={`section_${sectionIndex}`}>
        <TopicSection id={`section_${sectionIndex}`} withBackground={state.displayEditForm}>
          <View>
            <SectionBubbleView theme={theme}>{sectionIndex + 1}</SectionBubbleView>
          </View>
          <div style={{ maxWidth: '90%', flex: 1 }}>
            <AnimatePresence>
              {isEmptySection && (
                <EmptySectionContent displayAddQuestionForm={displayAddQuestionForm(dispatch, sectionIndex + 1)} />
              )}
              {!isEmptySection &&
                state.questions.map((question, index) => {
                  const translation = getTranslation(question, languageId);
                  if (!translation) {
                    return <h5>{t('missingTranslation')}</h5>;
                  }
                  return (
                    <Question
                      question={question}
                      isInNewSection={false}
                      sectionOrder={sectionIndex}
                      subsections={state.questionSubsections && state.questionSubsections.get(question.id)}
                      mapping={mapping}
                      allowStructureChanges={allowStructureChanges}
                      nextSectionStartingQuestion={nextSectionStartingQuestion}
                      updateQuestion={updateQuestion(dispatch)}
                      deleteQuestion={deleteQuestion(dispatch)}
                      deleteQuestionsForChoice={deleteQuestionsForChoice(dispatch)}
                      displayAddQuestionForm={displayAddQuestionForm(dispatch, sectionIndex + 1)}
                      displayAddTellToSubsectionForm={displayAddTellToSubsectionForm(dispatch)}
                      setFocusedQuestion={setFocusedQuestion(dispatch)}
                      key={question.id}
                      editMode={state.displayEditForm}
                      focusedQuestionId={state.focusedQuestionId}
                      questionIndex={index}
                      isLastInSection={index + 1 === state.questions.length}
                      chatBubbleOnlySection={state.chatBubbleOnlySection}
                      languageId={languageId}
                      sectionIndexMapping={sectionIndexMapping}
                    />
                  );
                })}
            </AnimatePresence>
          </div>
        </TopicSection>
        <SectionControls visible={state.displayEditForm} ref={sectionControlsRef}>
          {isEditable && !state.displayEditForm && (
            <div style={{ display: 'flex', gap: '16px' }}>
              <Button color="primary" onClick={displayEditForm}>
                {t('edit')}
              </Button>
              {allowStructureChanges && (
                <ActionMenu toggleIcon={<ActionMenuIcon />} margin={'10px 0 0'} items={getActionMenuItems()} />
              )}
            </div>
          )}
          {state.displayEditForm && (
            <>
              {!isEmptySection && (
                <Button color="primary" disabled={!state.isValid} onClick={submitForm}>
                  {t('save')}
                </Button>
              )}
              {isEmptySection && (
                <Button color="primary" disabled={!allowDelete} onClick={deleteWholeSection}>
                  {t('delete')}
                </Button>
              )}
              <Button color="primary" variant={'outline-primary'} onClick={closeForm}>
                {t('cancel')}
              </Button>
            </>
          )}
        </SectionControls>

        <AnimatePresence>
          {newSectionState.displayNewSectionForm && (
            <motion.div
              id={`new_section_${sectionIndex}`}
              transition={{ type: 'easeInOut', duration: 0.25 }}
              initial={{ height: 0 }}
              animate={{ height: 'auto' }}
              exit={{ height: 0 }}
            >
              <TopicSection id={`new_section_${sectionIndex}`} withBackground={true}>
                <View>
                  <SectionBubbleView theme={theme}>-</SectionBubbleView>
                </View>
                <div style={{ maxWidth: '90%', flex: 1 }}>
                  <AnimatePresence>
                    {isEmptyNewSection && (
                      <EmptySectionContent
                        displayAddQuestionForm={displayAddQuestionForm(newSectionDispatch, sectionIndex + 2)}
                      />
                    )}
                    {newSectionState.questions.map((question, index) => {
                      return (
                        <Question
                          isInNewSection={true}
                          question={question}
                          sectionOrder={sectionIndex}
                          subsections={
                            newSectionState.questionSubsections && newSectionState.questionSubsections.get(question.id)
                          }
                          mapping={mapping}
                          allowStructureChanges={allowStructureChanges}
                          nextSectionStartingQuestion={nextSectionStartingQuestion}
                          updateQuestion={updateQuestion(newSectionDispatch)}
                          deleteQuestion={deleteQuestion(newSectionDispatch)}
                          deleteQuestionsForChoice={deleteQuestionsForChoice(newSectionDispatch)}
                          displayAddTellToSubsectionForm={displayAddTellToSubsectionForm(newSectionDispatch)}
                          displayAddQuestionForm={displayAddQuestionForm(newSectionDispatch, sectionIndex + 2)}
                          setFocusedQuestion={setFocusedQuestion(newSectionDispatch)}
                          key={question.id}
                          editMode={true}
                          focusedQuestionId={newSectionState.focusedQuestionId}
                          questionIndex={index}
                          isLastInSection={index + 1 === newSectionState.questions.length}
                          chatBubbleOnlySection={newSectionState.chatBubbleOnlySection}
                          languageId={languageId}
                          sectionIndexMapping={sectionIndexMapping}
                        />
                      );
                    })}
                  </AnimatePresence>
                </div>
              </TopicSection>
              <SectionControls visible={true} ref={newSectionControlsRef}>
                {!isEmptyNewSection && (
                  <Button color="primary" disabled={!newSectionState.isValid} onClick={submitNewSectionForm}>
                    {t('save')}
                  </Button>
                )}
                {isEmptyNewSection && (
                  <Button color="primary" onClick={closeAddQuestionForm}>
                    {t('delete')}
                  </Button>
                )}
                <Button color="primary" variant={'outline-primary'} onClick={closeAddQuestionForm}>
                  {t('cancel')}
                </Button>
              </SectionControls>
            </motion.div>
          )}
        </AnimatePresence>
      </Element>
    </HoverWrapper>
  );
};

export default SectionComponent;
