import { useTranslation } from 'react-i18next';
import { ConversationSubjects } from '../../components/ConversationSubjects';
import { getLinkToTopicOverview } from '../../lib/topic';
import { AllowedConversationSubjectQuery } from '../../generated/hooks';

const TopicsScreen = () => {
  const { t } = useTranslation('surveysOverview');

  const notProcessAndHasEmptyTopic = (
    subject: NonNullable<AllowedConversationSubjectQuery['user']['organization']>['allowedConversationSubjects'][0],
  ) => !subject.process && !subject.topic;

  return (
    <ConversationSubjects
      getLink={getLinkToTopicOverview}
      isDisabled={notProcessAndHasEmptyTopic}
      heading={t('ReportAccess:topics')}
      dataIcom="heading-topics"
    />
  );
};

export default TopicsScreen;
