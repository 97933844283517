import { clone } from 'ramda';
import { TFunction } from 'react-i18next';
import { ClassicPreset, GetSchemes } from 'rete';
import { HistoryAction } from 'rete-history-plugin';
import { QuestionType, SelectedRange, TopicDetailQuery } from '../../generated/hooks';
import { COMMENT_TYPE } from './constants';
import { AddOptionControlData } from './controls/AddOptionControl';
import { CheckboxControlData } from './controls/CheckboxControl';
import { Comments } from './controls/Comments';
import { CommentsControlData } from './controls/CommentsControl';
import { DeleteCommentButton } from './controls/DeleteCommentButton';
import { DeleteCommentControl } from './controls/DeleteCommentControl';
import { MinMaxControlData } from './controls/MinMaxControl';
import { NumberWithTooltipControlData } from './controls/NumberWithTooltipControl';
import { OptionControlData } from './controls/OptionControl';
import { TextAreaControlData } from './controls/TextAreaControl';
import { ValidationError } from './validation';

export type OutputKey = `opt-${number}`;

export interface NodeData {
  questionIndex: number;
  questionText: string;
  isRequired: boolean;
  isMetric: boolean;
  isQuestionTrigger: boolean;
  choices: { [key: OutputKey]: string };
  type: QuestionType | typeof COMMENT_TYPE;
  range?: SelectedRange;
  answersToHighlight?: { [key: OutputKey]: number };
  input: boolean;
  outputs: { [key: OutputKey]: number | null };
  position: (number | null)[];
  reportOrder: number | null;
  originalQuestionDefinition?: TopicDetailQuery['topicDetail']['allQuestions'][0];
  removeConnectionFromOutput?: (node: DataNode, outputName: string) => void;
  onChange?: (node: DataNode) => void;
  onDelete?: () => DataNode;
  comments?: NonNullable<NonNullable<TopicDetailQuery['topicDetail']['commentGroups']>[0]['comments']>;
  topicId?: string;
  search?: string;
  commentGroupId?: string;
  onDataChange?: (data: NodeData) => void;
  addHistoryAction?: (action: HistoryAction) => void;
}

const sharedSocket = new ClassicPreset.Socket('socket');

export class DataNode extends ClassicPreset.Node<
  { in?: ClassicPreset.Socket },
  { [key in string]: ClassicPreset.Socket },
  {
    [key: string]:
      | TextAreaControlData
      | OptionControlData
      | AddOptionControlData
      | CheckboxControlData
      | NumberWithTooltipControlData
      | ClassicPreset.Control
      | ClassicPreset.InputControl<'number'>
      | ClassicPreset.InputControl<'text'>;
    questionText: TextAreaControlData;
    reportOrder: NumberWithTooltipControlData;
  }
> {
  width = 250; // needed by autoarrange plugin
  height?: number;
  nextOptionNumber: number;
  errors: ValidationError[] = [];
  lockEdit = false;
  optionsOrder: OutputKey[] = []; // order of options in select/multiselect - used only for undo/redo actions

  constructor(
    public data: NodeData,
    public t: TFunction,
    public readOnly = false,
    public isSysAdmin = false,
  ) {
    super(getLocalizedLabel(data.type, t));
    if (Object.keys(data.outputs).length > 1 && Object.keys(data.outputs).length !== Object.keys(data.choices).length) {
      throw new Error('Number of outputs and choices must be the same');
    }
    this.nextOptionNumber = Object.keys(data.outputs).length ?? 0;
    // Some special nodes like metric, sms, email node or question trigger for McD should be locked and not editable for normal users
    if (
      this.isSysAdmin === false &&
      ([QuestionType.SmsContact, QuestionType.EmailContact].includes(this.data.type as QuestionType) ||
        this.data.isMetric ||
        this.data.isQuestionTrigger)
    ) {
      this.readOnly = true;
      this.lockEdit = true;
    }
    this.addInputsFromData();
    this.addOutputsFromData();
    this.addControlsFromData();
  }

  addInputsFromData() {
    if (this.data.input) {
      this.addInput('in', new ClassicPreset.Input(sharedSocket, undefined, true));
    }
  }

  addOutputsFromData() {
    Object.keys(this.data.outputs).forEach((outputName) => {
      this.addOutput(outputName, new ClassicPreset.Output(sharedSocket, undefined, false));
    });
  }

  addControlsFromData() {
    this.addControl(
      'questionText',
      new TextAreaControlData(
        this.data.questionText || '',
        (newValue: string) => {
          const state = this.saveStateBeforeChanges();
          this.data.questionText = newValue;
          this.saveStateAfterChanges(state);
          this.onChange();
        },
        this.readOnly,
      ),
    );

    this.addControl(
      'reportOrder',
      new NumberWithTooltipControlData(
        this.data.reportOrder,
        this.t('reportOrderTooltip'),
        'reportOrderInput',
        (newValue: number | null) => {
          const state = this.saveStateBeforeChanges();
          this.data.reportOrder = newValue;
          this.saveStateAfterChanges(state);
          this.onChange();
        },
        this.readOnly,
      ),
    );
    if (
      (this.data.type === QuestionType.Multiselect || this.data.type === QuestionType.PersonMultiselect) &&
      this.data.range
    ) {
      this.addControl(
        'mixMax',
        new MinMaxControlData(
          this.data.range,
          (range: SelectedRange) => {
            const state = this.saveStateBeforeChanges();
            this.data.range = range;
            this.saveStateAfterChanges(state);
            this.onChange();
          },
          this.readOnly,
        ),
      );
    }
    if ([QuestionType.Select, QuestionType.Multiselect].includes(this.data.type as QuestionType) && !this.readOnly) {
      this.addControl(
        'addOption',
        new AddOptionControlData(() => {
          this.createNewOption();
        }),
      );
    }
    Object.keys(this.data.choices).forEach((controlKey, index) => {
      this.createOptionControl(controlKey as OutputKey, index);
    });
    if (this.data.type === QuestionType.Freetext) {
      this.addControl(
        'requiredCheckbox',
        new CheckboxControlData(
          this.data.isRequired,
          this.t('answerRequired'),
          (value) => {
            const state = this.saveStateBeforeChanges();
            this.data.isRequired = value;
            this.saveStateAfterChanges(state);
          },
          this.readOnly,
        ),
      );
    }
    if (this.data.type === COMMENT_TYPE) {
      this.addControl(
        'comment',
        new CommentsControlData(this.data, Comments, (data) => this.onDataChange(data), this.readOnly),
      );
      this.addControl('deleteCommentButton', new DeleteCommentControl(this.data, DeleteCommentButton, this.readOnly));
    }
  }

  createNewOption() {
    const state = this.saveStateBeforeChanges();
    const optionNumber = this.nextOptionNumber;
    this.nextOptionNumber = optionNumber + 1;
    const controlKey: OutputKey = `opt-${optionNumber}`;
    this.data.outputs[controlKey] = null;
    this.data.choices[controlKey] = '';
    this.createOptionControl(controlKey, optionNumber);
    this.addOutput(controlKey, new ClassicPreset.Output(sharedSocket, undefined, false));
    this.saveStateAfterChanges(state);
    this.onChange();
  }

  createOptionControl(controlKey: OutputKey, index: number) {
    this.addControl(
      controlKey,
      new OptionControlData(
        this.data.choices[controlKey],
        index,
        controlKey,
        this.data.answersToHighlight?.[controlKey] ?? 0,
        (newValue: string) => {
          const state = this.saveStateBeforeChanges();
          this.data.choices[controlKey] = newValue;
          this.saveStateAfterChanges(state);
          this.onChange();
        },
        (saveState = true) => {
          const state = saveState && this.saveStateBeforeChanges();
          delete this.data.choices[controlKey];
          delete this.data.outputs[controlKey];
          this.removeControl(controlKey);
          this.removeOutput(controlKey);
          state && this.saveStateAfterChanges(state);
          if (this.data.removeConnectionFromOutput) {
            this.data.removeConnectionFromOutput(this, controlKey);
          }
          this.onChange();
        },
        (newChoiceSeverity) => {
          const state = this.saveStateBeforeChanges();
          const newAnswersToHighlight = { ...this.data.answersToHighlight };
          newAnswersToHighlight[controlKey] = newChoiceSeverity;
          this.data.answersToHighlight = newAnswersToHighlight;
          this.saveStateAfterChanges(state);
          this.onChange();
        },
        (from: number, to: number) => {
          const state = this.saveStateBeforeChanges();
          // Get orderer control keys
          const controlKeys = Object.values(this.controls)
            .filter((c) => c instanceof OptionControlData)
            .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
            .map((x) => (x as OptionControlData).key as OutputKey);

          // Switch the order in the array
          const dragged = controlKeys.splice(from, 1)[0];
          controlKeys.splice(to, 0, dragged);

          for (let i = 0; i < controlKeys.length; i++) {
            const key = controlKeys[i];
            this.controls[key].index = i;
          }
          // Update the order in optionsOrder - used for undo/redo actions
          this.optionsOrder = controlKeys;
          this.saveStateAfterChanges(state);
          this.onChange();
        },
        this.readOnly,
      ),
    );
    this.optionsOrder.push(controlKey as OutputKey);
  }
  onChange() {
    this.data.onChange && this.data.onChange(this);
  }
  onDataChange(data?: NodeData) {
    this.data.onDataChange && this.data.onDataChange(data ? data : this.data);
  }

  saveStateBeforeChanges() {
    const action = new CustomNodeAction(this);
    setTimeout(() => {
      // just checking if the devs did not forget about saving the state after changes
      if (action.newNodeData === undefined) {
        throw new Error(
          'saveStateAfterChanges was not called after saveStateBeforeChanges. You need to call both methods in a row to save history',
        );
      }
    }, 1000);
    return action;
  }

  saveStateAfterChanges(action: CustomNodeAction) {
    action.saveChanges(this);
    this.data.addHistoryAction && this.data.addHistoryAction(action);
  }

  /**
   * Update the controls directly with the NodeData - used in the undo/redo actions
   */
  updateControlsFromData() {
    this.controls.questionText.initialValue = this.data.questionText;
    this.controls.questionText.initialValueChangedIndicator = Date.now();
    this.controls.reportOrder.initialValue = this.data.reportOrder;
    this.controls.reportOrder.initialValueChangedIndicator = Date.now();
    if (this.controls.requiredCheckbox as CheckboxControlData) {
      (this.controls.requiredCheckbox as CheckboxControlData).initialValue = this.data.isRequired;
      (this.controls.requiredCheckbox as CheckboxControlData).initialValueChangedIndicator = Date.now();
    }
    if (
      (this.data.type === QuestionType.Multiselect || this.data.type === QuestionType.PersonMultiselect) &&
      this.data.range
    ) {
      (this.controls.mixMax as MinMaxControlData).initialValue = this.data.range;
    }
    Object.keys(this.data.choices).forEach((controlKey) => {
      let optionControl = this.controls[controlKey] as OptionControlData | undefined;
      if (!optionControl) {
        // Create new control
        this.createOptionControl(controlKey as OutputKey, this.optionsOrder.indexOf(controlKey as OutputKey));
        optionControl = this.controls[controlKey] as OptionControlData;
        this.addOutput(controlKey, new ClassicPreset.Output(sharedSocket, undefined, false));
      }
      // Update existing controls
      optionControl.initialValue = this.data.choices[controlKey as OutputKey];
      optionControl.initialValueChangedIndicator = Date.now();
      optionControl.severity = this.data.answersToHighlight?.[controlKey as OutputKey] ?? 0;
      optionControl.index = this.optionsOrder.indexOf(controlKey as OutputKey);
    });
    // Remove controls that are not in the data
    Object.entries(this.controls).forEach(([key, control]) => {
      if (key.startsWith('opt-') && this.data.choices[key as OutputKey] == null) {
        (control as OptionControlData).onDelete(false);
      }
    });
  }

  updateRequested = false;
  /**
   * We are using this method to request an update of the controls and rerender the node only once to avoid multiple rerenders
   */
  requestUpdateOfControlsAndRerender() {
    if (this.updateRequested) return;
    this.updateRequested = true;
    setTimeout(() => {
      this.updateControlsFromData();
      this.onChange();
      this.updateRequested = false;
    });
  }
}

export interface DataNodeWithDimensions extends DataNode {
  // autoarrange plugin needs defined dimensions
  width: number;
  height: number;
}

export class CustomConnection<N extends DataNodeWithDimensions> extends ClassicPreset.Connection<N, N> {}

export type Schemes = GetSchemes<DataNodeWithDimensions, CustomConnection<DataNodeWithDimensions>>;

function getLocalizedLabel(type: QuestionType | typeof COMMENT_TYPE, t: TFunction): string {
  const translation = questionTypeToLocalization[type as keyof typeof questionTypeToLocalization] as string | undefined;
  return translation ? t(translation) : type;
}

const questionTypeToLocalization = {
  [QuestionType.Tell]: 'tellTitle',
  [QuestionType.Freetext]: 'freetextTitle',
  [QuestionType.Select]: 'selectTitle',
  [QuestionType.Multiselect]: 'multiselectTitle',
  [QuestionType.PersonMultiselect]: 'personalMultiselectTitle',
  [QuestionType.Ending]: 'endingTitle',
  [COMMENT_TYPE]: 'commentTitle',
};

export class CustomNodeAction implements HistoryAction {
  persistentNode: DataNode;
  previousNodeData: NodeData;
  previousOptionsOrder: OutputKey[];
  newNodeData?: NodeData;
  newOptionsOrder?: OutputKey[];

  undo(): void | Promise<void> {
    this.persistentNode.data = clone(this.previousNodeData);
    this.persistentNode.optionsOrder = this.previousOptionsOrder;
    this.persistentNode.requestUpdateOfControlsAndRerender();
    document.getSelection()?.removeAllRanges(); // blur - needed workaround
  }

  redo(): void | Promise<void> {
    if (this.newNodeData === undefined || this.newOptionsOrder === undefined)
      throw new Error('redo called before saveChanges');
    this.persistentNode.data = clone(this.newNodeData);
    this.persistentNode.optionsOrder = this.newOptionsOrder;
    this.persistentNode.requestUpdateOfControlsAndRerender();
  }

  constructor(dataNodeBeforeChanges: DataNode) {
    this.persistentNode = dataNodeBeforeChanges;
    this.previousNodeData = clone(dataNodeBeforeChanges.data);
    this.previousOptionsOrder = clone(dataNodeBeforeChanges.optionsOrder);
  }

  saveChanges(dataNodeAfterChanges: DataNode) {
    this.newNodeData = clone(dataNodeAfterChanges.data);
    this.newOptionsOrder = clone(dataNodeAfterChanges.optionsOrder);
  }
}
