import { useMutation } from '@apollo/client';
import { BodySmall, HeadingMedium, theme, useToast } from '@arnold/common';
import styled from '@emotion/styled';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { FormErrorFeedback, FormGroupLabel, LinkButton } from '../../components/Common';
import { ActionsWrap, AppLink, LinkWrap } from '../../components/StyledComponents';
import { generateOtpMutation } from '../../graphql/mutations';
import ee from '../../lib/eventEmitter';

interface IProps {
  email: string;
  password: string;
  otpValidTo: string;
  handleSignIn: (
    email: string,
    password: string,
    otp: string | undefined,
    setErrors: (errors: FormikErrors<IFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void;
  contactEmail: string;
  goBack: () => void;
  lang?: string;
}

const LoginButton = styled(Button)`
  width: 100%;
  margin: 0;
  @media (min-width: ${theme.breakpoint.small}) {
    width: auto;
  }
`;

const ResendCodeInfo = styled.div`
  padding: ${theme.spacing.d} 0;
`;

const InfoText = styled.div`
  & br {
    display: block;
    margin: 10px 0;
    content: ' ';
  }
`;

interface IFormValues {
  otp: string;
  responseError?: string;
}

const OTP_LENGTH = 6;

export const OtpForm = ({ email, password, otpValidTo, handleSignIn, contactEmail, lang, goBack }: IProps) => {
  const [t] = useTranslation('loginForm');
  const [counter, setCounter] = useState(0);
  const [generateOtp] = useMutation(generateOtpMutation);
  const { addToast } = useToast();

  const initialValues: IFormValues = {
    otp: '',
  };

  const startCountdown = (seconds: number) => {
    let index = seconds;

    const interval = setInterval(() => {
      index--;
      setCounter(index - 1);
      if (index === 0) {
        clearInterval(interval);
        setCounter(0);
      }
    }, 1000);
  };

  const handleSubmit = async (values: any, { setErrors, setSubmitting }: FormikHelpers<IFormValues>) => {
    await handleSignIn(email, password, values.otp, setErrors, setSubmitting);
  };

  const handleClick = async () => {
    if (counter === 0) {
      startCountdown(60);
      await generateOtp({
        variables: {
          username: email,
        },
      });
      addToast(t('loginForm:modalTitle'));
    } else {
      ee.emitOtpAlreadySentModal(counter);
    }
  };

  const ResendButton = () => <LinkButton clicked={handleClick}>{t('resendOtpButton')}</LinkButton>;

  return (
    <div>
      <HeadingMedium margin={`${theme.spacing.h} 0`}>{t('verifyAccount')}</HeadingMedium>
      <InfoText>
        <Trans
          i18nKey="loginForm:verifyAccountInfo"
          values={{
            email: contactEmail,
            otpValidTo: new Date(otpValidTo).toLocaleTimeString(lang, { hour: '2-digit', minute: '2-digit' }),
          }}
        >
          Na email <strong>{email}</strong> jsme poslali ověřovací kód, který je platný do <strong>{otpValidTo}</strong>
          .
        </Trans>
        <br />
      </InfoText>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ errors, handleSubmit, isSubmitting, values, setFieldValue }) => (
          <Form noValidate={true} onSubmit={handleSubmit}>
            <FormGroup>
              <FormGroupLabel>{t('verificationCode')}</FormGroupLabel>
              <FormControl
                style={{ width: '200px' }}
                type="text"
                name="otp"
                value={values.otp || ''}
                onChange={(e) => {
                  e.preventDefault();
                  const { value } = e.target;
                  const regex = /^\d+$/;
                  if (!value || regex.test(value.toString())) {
                    setFieldValue('otp', value.slice(0, OTP_LENGTH));
                  }
                }}
                isInvalid={!!errors.responseError}
              />
              {errors.responseError && <FormErrorFeedback error={errors.responseError} />}
            </FormGroup>
            <ResendCodeInfo>
              <BodySmall>
                <Trans i18nKey={'loginForm:resendOtp'} components={[<ResendButton />]}>
                  Nedostali jste kód, nebo jeho platnost vypršela?
                </Trans>
              </BodySmall>
            </ResendCodeInfo>

            <ActionsWrap>
              <LoginButton variant="primary" type="submit" disabled={isSubmitting || values.otp.length !== OTP_LENGTH}>
                {t('verify')}
              </LoginButton>
              <LinkWrap>
                <AppLink to={'/login'} onClick={goBack}>
                  {t('backToLogin')}
                </AppLink>
              </LinkWrap>
            </ActionsWrap>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OtpForm;
