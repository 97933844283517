import { ApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { LanguageSelector, PlainButton, theme } from '@arnold/common';
import { ReactComponent as Settings } from '@arnold/common/lib/assets/icons/Settings.svg';
import { ReactComponent as UniIcon } from '@arnold/common/lib/assets/icons/uniIcon.svg';
import styled from '@emotion/styled/macro';
import compose from 'lodash.flowright';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { Collapse, Dropdown, Nav, Navbar as NavbatStrap } from 'react-bootstrap';
import { SideCollapse, SideShow } from '../../assets/images';
import auth from '../../lib/auth';
import { allowedLanguages, handleLanguageChange } from '../../lib/common';
import { vanillaCookieConsent } from '../CookieConsent';

const LanguageSelectorContainer = styled.div`
  margin-top: -3px;
`;

const ToggleSideBar = styled.img`
  max-height: 128px;
  object-fit: contain;
  margin-right: ${theme.spacing.g};
`;

const MobileToggleSidebar = styled(PlainButton)`
  background: transparent !important;
  @media screen and (min-width: 992px) {
    display: none;
  }
`;

interface IProps extends RouteComponentProps<any> {
  userId?: number;
  client: ApolloClient<any>;
}

function Navbar(props: IProps) {
  const [t, i18n] = useTranslation('Navbar');
  const [collapse, setCollapse] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const toggleSidebarVisibility = () => {
    document.documentElement.classList.toggle('nav-open');
    setIsSidebarVisible((prevValue) => !prevValue);
  };

  const toggleCollapse = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setCollapse((prevCollapse) => !prevCollapse);
  };

  const handleSignOut = (event: React.SyntheticEvent<any, MouseEvent>) => {
    event.preventDefault();
    auth.logout();
    props.client.cache.reset();
    props.history.push('/login');
  };

  const handleCookieSettingsClick = () => {
    // @ts-ignore
    vanillaCookieConsent?.showSettings(0);
  };

  const handleClickOnAcademy = () => {
    window.open('https://intercom.help/arnold-robot/cs', '_blank')?.focus();
  };

  return (
    <NavbatStrap className={`navbar-absolute fixed-top ${collapse ? 'bg-white' : 'navbar-transparent'}`} expand="lg">
      <div className="container-fluid">
        <div className="navbar-wrapper">
          <MobileToggleSidebar onClick={toggleSidebarVisibility}>
            <ToggleSideBar src={isSidebarVisible ? SideCollapse : SideShow} />
          </MobileToggleSidebar>
        </div>
        <button
          aria-controls="navigation-index"
          aria-expanded={collapse}
          aria-label="Toggle navigation"
          className="navbar-toggler"
          // data-target="#navigation"
          data-toggle="collapse"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </button>
        <Collapse className="justify-content-end navbar-collapse" in={collapse}>
          <Nav navbar>
            <LanguageSelectorContainer>
              <LanguageSelector onChange={handleLanguageChange} allowedLanguages={[...allowedLanguages]} />
            </LanguageSelectorContainer>
            <Dropdown>
              <DropdownToggle
                aria-haspopup={true}
                color="default"
                data-toggle="dropdown"
                id="navbarDropdownMenuLink"
                className="nav-link"
                as="a"
              >
                <i className="nc-icon nc-circle-10" />
                <p>
                  <span className="d-lg-none d-md-block">{t('menu')}</span>
                </p>
              </DropdownToggle>
              <DropdownMenu aria-labelledby="navbarDropdownMenuLink" align="right">
                {['cs', 'sk'].includes(i18n.language) && (
                  <StyledDropdownItem onClick={handleClickOnAcademy}>
                    <StyledDropdownIcon>
                      <UniIcon />
                    </StyledDropdownIcon>
                    <DropdownText>{t('arnoldAcademy')}</DropdownText>
                  </StyledDropdownItem>
                )}
                <StyledDropdownItem onClick={handleCookieSettingsClick}>
                  <StyledDropdownIcon>
                    <Settings />
                  </StyledDropdownIcon>
                  <DropdownText>{t('cookieSettings')}</DropdownText>
                </StyledDropdownItem>
                <DropdownItem onClick={handleSignOut}>
                  <DropdownIcon>
                    <i className="nc-icon nc-button-power" />
                  </DropdownIcon>
                  <DropdownText>{t('signOut')}</DropdownText>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Nav>
        </Collapse>
      </div>
    </NavbatStrap>
  );
}

const StyledDropdownIcon = styled.p`
  svg {
    width: 14px;
    height: 14px;
  }
`;

const StyledDropdownItem = styled(DropdownItem)`
  svg path {
    fill: ${theme.colors.text.primary};
  }
  &:hover {
    svg path {
      fill: ${theme.colors.textInverted.primary};
    }
  }
`;

const DropdownText = styled.p(() => ({
  marginLeft: '8px !important',
}));

const DropdownIcon = styled.p(() => ({
  verticalAlign: 'middle !important',
}));
export default compose(withApollo, withRouter)(Navbar);
