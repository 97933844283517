import styled from '@emotion/styled/macro';
import React from 'react';
import { useTranslation, Trans } from 'react-i18next';

import { CardButton, theme } from '@arnold/common';
import { ReactComponent as TellIcon } from '@arnold/common/lib/assets/icons/TellIconBig.svg';
import { ReactComponent as OptionsIcon } from '@arnold/common/lib/assets/icons/OptionsIconBig.svg';
import { ReactComponent as OpenEndedIcon } from '@arnold/common/lib/assets/icons/OpenEndedIconBig.svg';

import { motion } from 'framer-motion';
import { QUESTION_DEFINITION_TYPE } from './types';

const Content = styled(motion.div)`
  margin-left: ${theme.spacing.f};
`;

const CardContainer = styled.div`
  margin-top: ${theme.spacing.h};
  display: flex;
`;

type EmptySectionContentProps = {
  displayAddQuestionForm: (questionType: QUESTION_DEFINITION_TYPE, index: number) => void;
};

const EmptySectionContent = ({ displayAddQuestionForm }: EmptySectionContentProps) => {
  const [t] = useTranslation(['actionMenu', 'topicOverview']);
  return (
    <Content
      transition={{ type: 'easeInOut', duration: 0.25 }}
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      style={{
        overflow: 'hidden',
      }}
    >
      <Trans i18nKey="topicOverview:emptySection">
        Arnold tu nevidí žádnou otázku. Buď můžeš přidat novou otázku nebo to celé smazat tlačítkem{' '}
        <strong>Smazat</strong> nebo vrátit všechny změny tlačítkem <strong>Zrušit</strong>.
      </Trans>
      <CardContainer>
        <CardButton
          image={<OptionsIcon />}
          title={t('select')}
          onClick={() => {
            displayAddQuestionForm(QUESTION_DEFINITION_TYPE.select, 0);
          }}
        />
        <CardButton
          image={<OpenEndedIcon />}
          title={t('freeText')}
          onClick={() => displayAddQuestionForm(QUESTION_DEFINITION_TYPE.freetext, 0)}
        />
        <CardButton
          image={<TellIcon />}
          title={t('chatBubble')}
          onClick={() => displayAddQuestionForm(QUESTION_DEFINITION_TYPE.tell, 0)}
        />
      </CardContainer>
    </Content>
  );
};

export default EmptySectionContent;
