import { FC, ReactNode } from 'react';
import { SecondarySpan, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { css } from '@emotion/react/macro';

type InfoBoxProps = {
  title?: string | null;
  children: ReactNode;
  showSeparator?: boolean;
  dataIcom?: string;
};

const Wrapper = styled.div<{ showSeparator?: boolean }>`
  ${({ showSeparator }) =>
    showSeparator &&
    css`
      border-left: solid ${theme.colors.borderMain.default} 1px;
      padding-left: ${theme.spacing.d};
    `}
  display: grid;
`;

export const InfoBox: FC<InfoBoxProps> = ({ showSeparator, title, children, dataIcom }) => (
  <Wrapper showSeparator={showSeparator}>
    <SecondarySpan data-icom={dataIcom}>{title}</SecondarySpan>
    {children}
  </Wrapper>
);
