import Lottie from 'react-lottie';
import * as loadingAnimation from '../assets/loading.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: (loadingAnimation as any).default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export const LoadingCard = () => {
  return (
    <Lottie
      options={defaultOptions}
      height={128}
      width={128}
      isStopped={false}
      isPaused={false}
      isClickToPauseDisabled={true}
    />
  );
};
