import styled from '@emotion/styled';
import { theme } from '../../theme';
import { EmptyTable } from './EmptyTable';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import type { TableProps, TableDataType } from './types';

const StyledTable = styled.table`
  width: 100%;
  background-color: ${theme.background.primary};
`;

export const Table = <T extends TableDataType>({
  labels,
  emptyImage,
  dataSources = [],
  columns,
  thin,
  dataCypressTestFlag,
  rowSelection,
  className,
  ...props
}: TableProps<T>): JSX.Element => {
  if (dataSources.length === 0) {
    return <EmptyTable labels={labels} emptyImage={emptyImage} />;
  }

  const allSelected =
    !!rowSelection && dataSources.every((dataRow) => rowSelection.selected.includes(dataRow.id!.toString(10)));
  const partiallySelected = !allSelected && !!rowSelection?.selected.length;
  const bulkOnChange = rowSelection?.onChange
    ? () => rowSelection.onChange(allSelected ? [] : dataSources.map((dataRow) => dataRow.id!.toString(10)))
    : undefined;
  return (
    <StyledTable data-cy={dataCypressTestFlag} className={className}>
      <TableHead
        columns={columns}
        thin={thin}
        partiallySelected={partiallySelected}
        selected={allSelected}
        onChange={bulkOnChange}
      />
      <TableBody<T> columns={columns} dataSources={dataSources} thin={thin} rowSelection={rowSelection} {...props} />
    </StyledTable>
  );
};
