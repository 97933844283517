import { QuestionType } from '../../generated/hooks';
import { COMMENT_TYPE } from './constants';
import { DataNode, OutputKey } from './dataNode';

export type ValidationError = {
  node: DataNode;
  part:
    | 'questionText'
    | 'input'
    | 'reportOrder'
    | 'min'
    | 'max'
    | 'min/max'
    | `choice-opt-${number}`
    | `output-opt-${number}`;
  error:
    | 'validationNoConnection'
    | 'validationEmpty'
    | 'validationMinLesserThan1'
    | 'validationMinLesserThan0'
    | 'validationMaxLesserThan2'
    | 'validationMaxLesserThan1'
    | 'validationMaxLesserThanMin'
    | 'validationMaxGreaterThanOptions'
    | 'validationReportOrderUnique';
};

/**
 * Used for validation before save
 * @param nodes nodes to be validated
 * @param allNodes all nodes in the editor (used for checking connections to the node being validated)
 * @param t translation function
 * @returns array of validationError
 */
export const validateNodes = (nodes: DataNode[], allNodes: DataNode[]): ValidationError[] => {
  const errors: ValidationError[] = [];
  nodes.forEach((node) => {
    node.errors = [];
    if (node.data.type === COMMENT_TYPE) {
      return;
    }

    // Validate empty title
    if (node.data.questionText.length === 0) {
      node.errors.push({ error: 'validationEmpty', part: 'questionText', node });
    }

    // Validate empty options
    Object.entries(node.data.choices).forEach((choiceEntry) => {
      const [choiceKey, choiceText] = choiceEntry as [OutputKey, string];
      if (choiceText.length === 0) {
        node.errors.push({ error: 'validationEmpty', part: `choice-${choiceKey}`, node });
      }
    });

    // Validate empty report order
    if (node.data.reportOrder === null) {
      node.errors.push({ error: 'validationEmpty', part: 'reportOrder', node });
    }

    // Validate not connected input
    if (
      node.data.input &&
      allNodes.some((otherNode) => Object.values(otherNode.data.outputs).includes(node.data.questionIndex)) === false
    ) {
      node.errors.push({ error: 'validationNoConnection', part: 'input', node });
    }

    // Validate not connected outputs
    Object.entries(node.data.outputs).forEach((entry) => {
      const [key, value] = entry as [OutputKey, number | null];
      if (value == null) {
        node.errors.push({ error: 'validationNoConnection', part: `output-${key}`, node });
      }
    });

    // Validate report order can't be the same in more questions
    if (
      // if two different nodes have the reportOrder
      allNodes.some(
        (otherNode) =>
          otherNode.data.reportOrder === node.data.reportOrder &&
          otherNode.data.questionIndex !== node.data.questionIndex,
      )
    ) {
      node.errors.push({ error: 'validationReportOrderUnique', part: 'reportOrder', node });
    }

    if (node.data.type === QuestionType.Multiselect || node.data.type === QuestionType.PersonMultiselect) {
      // Validate multiselect max smaller than min
      if (node.data.range!.max < node.data.range!.min) {
        node.errors.push({ error: 'validationMaxLesserThanMin', part: 'min/max', node });
      }
    }

    if (node.data.type === QuestionType.Multiselect) {
      // Validate multiselect min smaller than 1
      if (node.data.range!.min < 1) {
        node.errors.push({ error: 'validationMinLesserThan1', part: 'min', node });
      }

      // Validate multiselect max smaller than 2
      if (node.data.range!.max < 2) {
        node.errors.push({ error: 'validationMaxLesserThan2', part: 'max', node });
      }

      // Validate multiselect max bigger than the number of options
      if (node.data.range!.max > Object.keys(node.data.choices).length) {
        node.errors.push({ error: 'validationMaxGreaterThanOptions', part: 'max', node });
      }
    }

    if (node.data.type === QuestionType.PersonMultiselect) {
      // Validate multiselect min smaller than 1
      if (node.data.range!.min < 0) {
        node.errors.push({ error: 'validationMinLesserThan0', part: 'min', node });
      }

      // Validate multiselect max smaller than 2
      if (node.data.range!.max < 1) {
        node.errors.push({ error: 'validationMaxLesserThan1', part: 'max', node });
      }
    }

    errors.push(...node.errors);
  });
  return errors;
};
