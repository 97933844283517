import * as React from 'react';
import { ClassicPreset } from 'rete';
import { Drag } from 'rete-react-plugin';

export class TextAreaControlData extends ClassicPreset.Control {
  constructor(
    public initialValue: string,
    public change: (value: string) => void,
    public readOnly = false,
  ) {
    super();
  }
  initialValueChangedIndicator?: number;
}

export const TextAreaControl = React.forwardRef((props: { data: TextAreaControlData }, ref: any) => {
  const [value, setValue] = React.useState(props.data.initialValue);
  const innerRef = React.useRef<HTMLElement>(null);

  // Use the ref passed in or the innerRef if no ref is passed in
  const actualRef = ref || innerRef;

  Drag.useNoDrag(actualRef);

  React.useEffect(() => {
    if (value === props.data.initialValue) {
      setValue(props.data.initialValue + ' ');
      setTimeout(() => {
        setValue(props.data.initialValue);
      }, 0);
    } else {
      setValue(props.data.initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.initialValue, props.data.initialValueChangedIndicator]);

  return (
    <span
      className="textAreaSpan textAreaControl"
      ref={actualRef}
      data-icom="input-question-text-ariel"
      data-cy="input-question-text-ariel"
      tabIndex={0}
      contentEditable={!props.data.readOnly}
      suppressContentEditableWarning={true}
      role="textbox"
      onInput={(e) => {
        const val = (e.target as HTMLElement).innerText;
        props.data.change(val);
      }}
      onPaste={(e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        document.execCommand('insertHTML', false, text);
      }}
      onFocus={() => {
        const el = actualRef.current;
        if (el) {
          const range = document.createRange();
          const sel = window.getSelection();
          range.selectNodeContents(el);
          range.collapse(false);
          sel?.removeAllRanges();
          sel?.addRange(range);
        }
      }}
    >
      {value}
    </span>
  );
});
