import { format } from 'date-fns';
import { getLocale } from '@arnold/core';
import styled from '@emotion/styled';
import { BodySmall, CloseIcon, EditIcon, PlainButton, SubtleButton, theme } from '@arnold/common';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Drag } from 'rete-react-plugin';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TopicDetailQuery } from '../../../generated/hooks';
import { TextAreaControl } from '../controls/TextAreaControl';
import { updateCommentMutation } from '../../../graphql/mutations';
import i18n from '../../../translations/i18n';
import { NodeData } from '../dataNode';

const ButtonContainer = styled.div`
  padding: ${theme.spacing.d} ${theme.spacing.f};
`;

const Comment = styled.div`
  margin: ${theme.spacing.d} ${theme.spacing.f};
  padding: ${theme.spacing.d} ${theme.spacing.f};
  background-color: ${theme.colors.backgroundBasic.default};
  border-radius: ${theme.spacing.d};
  margin-top: ${theme.spacing.d};
  border: ${theme.spacing.a} solid ${theme.colors.backgroundBasic.default};
  margin-bottom: ${theme.spacing.f};
`;

const StyledPlainButton = styled(PlainButton)`
  background-color: transparent;
  padding: ${theme.spacing.b};
  width: 22px;
  height: 22px;

  & path {
    fill: ${theme.colors.text.secondary};
  }

  &:hover path {
    fill: ${theme.colors.iconAction.primary};
  }
`;

const CreatedBy = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${theme.spacing.d} ${theme.spacing.f};
`;

type Props = {
  comment: NonNullable<NonNullable<TopicDetailQuery['topicDetail']['commentGroups']>[0]['comments']>[0];
  signedUserId?: string;
  setIsEditModeOpened: (opened: boolean) => void;
  editedId?: string;
  setEditedId: (id: string | undefined) => void;
  data: NodeData;
  onChange: (data: NodeData) => void;
};

export const CommentContainer: FC<Props> = ({
  comment,
  signedUserId,
  setIsEditModeOpened,
  editedId,
  setEditedId,
  data,
  onChange,
}) => {
  const { t } = useTranslation('topicEditor');
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [commentState, setCommentState] = useState(comment);
  const [text, setText] = useState(comment.text);
  const ref = React.useRef({
    text: comment.text,
  });

  useEffect(() => {
    if (editedId !== comment.id) {
      setIsEdit(false);
    }
  }, [editedId, comment.id]);

  const handleEdit = (isEdit: boolean) => {
    setIsEdit(isEdit);
    setIsEditModeOpened(isEdit);
  };

  const [updateComment] = useMutation(updateCommentMutation);

  const handleUpdateComment = async () => {
    setIsDisabled(true);
    const newData = await updateComment({
      variables: {
        id: commentState.id,
        text: ref.current.text,
      },
    });
    handleEdit(false);
    setCommentState(
      newData.data.updateComment.comments.find(
        (comment: NonNullable<NonNullable<TopicDetailQuery['topicDetail']['commentGroups']>[0]['comments']>[0]) =>
          comment.id === commentState.id,
      ),
    );
    onChange({
      ...data,
      comments: newData.data.updateComment.comments,
    });
    setIsDisabled(false);
  };

  const textAreaRef = useRef<any>(null);
  useEffect(() => {
    if (isEdit && textAreaRef.current) {
      const textarea = textAreaRef.current;
      textarea.focus();
    }
  }, [isEdit]);

  return (
    <div>
      <CreatedBy>
        <div>
          <BodySmall bold>
            {commentState.originator
              ? `${commentState.originator?.firstname} ${commentState.originator.surname}`
              : t('anonymous')}
          </BodySmall>
          <BodySmall color={theme.colors.text.secondary}>
            {format(new Date(commentState.updatedAt), 'd. MMMM yyyy, HH:mm', {
              locale: getLocale(i18n.language),
            })}
          </BodySmall>
        </div>
        <Drag.NoDrag>
          {isEdit ? (
            <StyledPlainButton onClick={() => handleEdit(false)}>
              <CloseIcon />
            </StyledPlainButton>
          ) : (
            commentState.originator?.id &&
            commentState.originator.id === signedUserId && (
              <StyledPlainButton
                onClick={() => {
                  handleEdit(true);
                  setEditedId(commentState.id);
                }}
              >
                <EditIcon />
              </StyledPlainButton>
            )
          )}
        </Drag.NoDrag>
      </CreatedBy>
      {isEdit ? (
        <>
          <TextAreaControl
            ref={textAreaRef}
            data={{
              readOnly: false,
              initialValue: commentState.text,
              id: commentState.id,
              change: (value) => {
                ref.current.text = value;
                setText(value);
              },
            }}
          />
          <ButtonContainer>
            <Drag.NoDrag>
              <SubtleButton
                buttons={[
                  { title: t('editComment'), onClick: handleUpdateComment, disabled: isDisabled || text.length === 0 },
                ]}
              />
            </Drag.NoDrag>
          </ButtonContainer>
        </>
      ) : (
        <Comment>{commentState.text}</Comment>
      )}
    </div>
  );
};
