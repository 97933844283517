import { FormControl, FormGroup } from 'react-bootstrap';
import { FormikErrors } from 'formik';
import { checkValueOrThrowError, isNumber, StringKeysOf } from '@arnold/common';
import { FormErrorFeedback, FormGroupLabel } from '../../../../components/Common';
import { ValueType } from './types';

// @todo - move me if needed
// @toto - duplicate with TextInput
type TextInputProps<V extends ValueType> = {
  errors: FormikErrors<V>;
  setFieldValue: (key: StringKeysOf<V>, value: number) => void;
  values: V;
  title: string;
  name: StringKeysOf<V>;
};
export const NumberInput = <V extends ValueType>({
  title,
  name,
  values,
  errors,
  setFieldValue,
}: TextInputProps<V>): JSX.Element => {
  return (
    <FormGroup>
      <FormGroupLabel>{title}</FormGroupLabel>
      <FormControl
        type="number"
        name={name}
        value={checkValueOrThrowError(values[name], isNumber) || undefined}
        onChange={(e) => {
          setFieldValue(name, parseInt(e.target.value, 10));
        }}
        isInvalid={!!errors[name]}
      />
      {errors[name] && <FormErrorFeedback error={errors[name]} />}
    </FormGroup>
  );
};
