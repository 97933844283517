import { createContext, useContext } from 'react';
import { ApolloQueryResult } from '@apollo/client';
import { Exact, OrganizationQuery } from '../../generated/hooks';

type Props = {
  children: React.ReactNode;
  organization: OrganizationQuery['organization'];
  refetch: (
    variables?:
      | Partial<
          Exact<{
            organizationId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<OrganizationQuery>>;
};

type OrganizationContentType = {
  organization: OrganizationQuery['organization'];
  refetch: (
    variables?:
      | Partial<
          Exact<{
            organizationId: string;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<OrganizationQuery>>;
};

export const OrganizationContext = createContext<OrganizationContentType>({
  organization: null,
  refetch: undefined!,
});

export function OrganizationProvider({ children, organization, refetch }: Props) {
  const context = {
    organization,
    refetch,
  };

  return <OrganizationContext.Provider value={context}>{children}</OrganizationContext.Provider>;
}

export const useOrganization = () => useContext(OrganizationContext);
