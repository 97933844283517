import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { theme } from '../../theme';

const BoxWrapper = styled.div`
  background-color: ${theme.colors.backgroundCover.default};
  color: ${theme.colors.text.primary};
  box-shadow: ${theme.shadows.elevationContentBlock};
  border-radius: 6px;
  padding: ${theme.spacing.h};
`;

export interface IBoxProps {
  className?: string;
  children?: ReactNode;
}

export const Box = ({ children, className }: IBoxProps) => {
  return <BoxWrapper className={className}>{children}</BoxWrapper>;
};

export const BoxSmallLabel = styled.h3`
  padding-left: ${theme.spacing.f};
  font-size: ${theme.typography.body.small.regular.fontSize};
  line-height: 140%;
  color: ${theme.colors.text.secondary};
  margin-bottom: ${theme.spacing.c};
`;
