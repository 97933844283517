import { theme } from '@arnold/common';
import { ReactComponent as AddIcon } from '@arnold/common/lib/assets/icons/AddIcon.svg';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ClassicPreset } from 'rete';
import { Drag } from 'rete-react-plugin';

export class AddOptionControlData extends ClassicPreset.Control {
  constructor(
    public onClick: () => void,
    public readOnly = false,
  ) {
    super();
  }
}

const CreateButton = styled(Button)`
  width: calc(100% - 2 * ${theme.spacing.f});
  border-radius: 6px;
  color: ${theme.colors.textInverted.primary};
  margin: ${theme.spacing.f};
  padding: ${theme.spacing.c} ${theme.spacing.d};
  font-size: ${theme.typography.body.small.regular.fontSize};

  & svg {
    margin-right: ${theme.spacing.d};
    margin-bottom: ${theme.spacing.b};
  }
`;

export const AddOptionControl = (props: { data: AddOptionControlData }) => {
  const { t } = useTranslation('topicEditor');

  if (props.data.readOnly) {
    return null;
  }
  return (
    <Drag.NoDrag>
      <CreateButton
        variant="primary"
        data-cy={`btn-create-new-option`}
        data-icom={'btn-create-new-option-ariel'}
        onClick={props.data.onClick}
        className={'addOptionButton'}
      >
        <AddIcon />
        {t('addOption')}
      </CreateButton>
    </Drag.NoDrag>
  );
};
