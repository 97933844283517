import { CustomNodeEditor } from './rete';

export const addAreaSelectionListener = (editor: CustomNodeEditor) => {
  const container = editor.areaPlugin.container;
  const selectionRectangle = container.parentElement!.querySelector('#selection-rectangle') as HTMLElement;

  let holding = false;
  let startX = 0;
  let startY = 0;

  const downListener = (e: MouseEvent) => {
    if (e.button === 2) {
      holding = true;
      startX = e.clientX;
      startY = e.clientY;
    }
  };

  const upListener = (e: MouseEvent) => {
    if (e.button === 2 && selectionRectangle) {
      holding = false;
      selectionRectangle.style.display = 'none';
    }
  };

  const moveListener = (e: MouseEvent) => {
    if (holding && selectionRectangle) {
      const containerRect = container.getBoundingClientRect();
      const containerOffsetX = containerRect.x;
      const containerOffsetY = containerRect.y;
      const x = Math.min(startX, e.clientX);
      const y = Math.min(startY, e.clientY);
      const xWithOffset = x - containerOffsetX;
      const yWithOffset = y - containerOffsetY;
      const width = Math.abs(startX - e.clientX);
      const height = Math.abs(startY - e.clientY);

      selectionRectangle.style.display = 'block';
      selectionRectangle.style.left = xWithOffset + 'px';
      selectionRectangle.style.top = yWithOffset + 'px';
      selectionRectangle.style.width = width + 'px';
      selectionRectangle.style.height = height + 'px';

      const nodes = editor.getNodes();
      const selectedNodes = nodes.filter((node) => {
        const nodeView = editor.areaPlugin.nodeViews.get(node.id);
        if (!nodeView) return false;
        const nodeRect = nodeView.element.getBoundingClientRect();
        return nodeRect.left < x + width && nodeRect.right > x && nodeRect.top < y + height && nodeRect.bottom > y;
      });

      for (const node of nodes) {
        if (selectedNodes.includes(node)) {
          editor.nodeSelectorInterface.select(node.id, true);
        } else {
          editor.nodeSelectorInterface.unselect(node.id);
        }
      }
    }
  };

  container.addEventListener('mousedown', downListener);
  container.addEventListener('mouseup', upListener);
  selectionRectangle.addEventListener('mouseup', upListener);
  container.addEventListener('mousemove', moveListener);
  selectionRectangle.addEventListener('mousemove', moveListener);

  editor.removeEventListernersFunctions.push(() => {
    container.removeEventListener('mousedown', downListener);
    container.removeEventListener('mouseup', upListener);
    selectionRectangle.removeEventListener('mouseup', upListener);
    container.removeEventListener('mousemove', moveListener);
    selectionRectangle.removeEventListener('mousemove', moveListener);
  });
};
