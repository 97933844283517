import { HeadingMedium, theme } from '@arnold/common';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import PublicLayout from '../../components/Common/PublicLayout';
import storage from '../../lib/storage';
import RecoverPasswordForm from './RecoverPasswordForm';
import RecoverPasswordAside from './RecoverPasswordAside';

const RecoverPasswordScreen = ({ match, history }: RouteComponentProps<any>) => {
  const [t] = useTranslation('recoverPassword');
  const lang = match.params.lang;
  const detectedLang = lang || storage.getDetectedLanguage()?.substring(0, 2);

  return (
    <PublicLayout basePath={'recoverPassword'} history={history} lang={detectedLang} aside={<RecoverPasswordAside />}>
      <>
        <HeadingMedium margin={`0 0 ${theme.spacing.h}`}>{t('changePassword')}</HeadingMedium>
        <RecoverPasswordForm lang={detectedLang} />
      </>
    </PublicLayout>
  );
};

export default RecoverPasswordScreen;
