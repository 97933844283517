import { getLocalizedDateWithoutLeadingZeroes, removeAccents } from '@arnold/core';
import { Button } from 'react-bootstrap';
import { TFunction } from 'react-i18next';
import Highlighter from 'react-highlight-words';
import { theme } from '@arnold/common';
import { getFullName, goToTalkProcessResults } from '../../../lib/helpers';
import i18n from '../../../translations/i18n';
import {
  NotstartedSurveyReason,
  OrganizationQuery,
  SurveyGroupRespondentsQuery,
  SurveyGroupWithoutRespondentsQuery,
  UserQuery,
} from '../../../generated/hooks';
import { ParticipantDetail } from './ParticipantDetail/ParticipantDetail';
import { showAnswers } from './ParticipantDetail/utils';
import { ProcessSurveyParticipantsData } from './types';
import { getParticipantStatus } from './utils';

type GetDataSourceParams = {
  surveyGroupRespondents?: SurveyGroupRespondentsQuery['surveyGroupRespondents']['data'];
  surveyGroup: NonNullable<SurveyGroupWithoutRespondentsQuery['surveyGroup']>;
  t: TFunction;
  topLevelReportAccess?: string | boolean;
  isOnboarding?: boolean;
  searchQuery: string;
  organization: UserQuery['user']['organization'] | OrganizationQuery['organization'];
};

export const getDataSource = ({
  surveyGroupRespondents,
  t,
  topLevelReportAccess,
  surveyGroup,
  isOnboarding,
  searchQuery,
  organization,
}: GetDataSourceParams): ProcessSurveyParticipantsData[] | undefined | null =>
  surveyGroupRespondents
    ?.flat(1)
    .map((group) => {
      return group.respondents.slice().map((respondent) => {
        const reasons = group.surveys
          .filter((survey) => survey.notstartedReason)
          .map((survey) => survey.notstartedReason);
        const uniqueReasons = [...new Set(reasons)] as NotstartedSurveyReason[];

        return {
          id: respondent.id,
          surveyParticipant: (
            <Highlighter
              highlightStyle={{
                fontWeight: '700',
                backgroundColor: 'transparent',
                color: theme.colorPrimary,
                padding: 0,
              }}
              textToHighlight={getFullName(respondent.firstname, respondent.surname, true) || ''}
              searchWords={searchQuery.split(' ')}
              sanitize={removeAccents}
            />
          ),
          startDate: getLocalizedDateWithoutLeadingZeroes(new Date(group.addedOn), i18n.language),
          team: (
            <Highlighter
              highlightStyle={{
                fontWeight: '700',
                backgroundColor: 'transparent',
                padding: 0,
              }}
              textToHighlight={respondent.team?.name ? respondent.team.name : t('teams:withoutLeader')}
              searchWords={searchQuery.split(' ')}
              sanitize={removeAccents}
            />
          ),
          status: getParticipantStatus(
            [
              ...group.surveys,
              ...group.hiringSurveys.filter((s) => s.whitelistedRespondents.some((r) => r.id === respondent.id)),
            ],
            true,
            organization,
            surveyGroup.status,
            t,
            uniqueReasons,
          ),
          actions: showAnswers(group) && (
            <Button
              onClick={
                topLevelReportAccess
                  ? () => goToTalkProcessResults(topLevelReportAccess, respondent.id, '_blank')
                  : undefined
              }
              variant={'outline-primary'}
              size="sm"
              disabled={!topLevelReportAccess}
            >
              {t('answers')}
            </Button>
          ),
          participantDetail: (
            <ParticipantDetail
              surveyGroup={surveyGroup}
              respondent={respondent}
              respondentGroup={group}
              isOnboarding={isOnboarding}
              organizationId={organization?.id!}
            />
          ),
        };
      });
    })
    .flat(1);
