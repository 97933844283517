import { BodySmall, CardBody, CardTitle, HeadingSmall, cleanArray, theme } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Button, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { SurveyGroupQuery } from '../../generated/hooks';
import { Table } from '../Table';
import { goToRespondentResults } from './utils';
import { UninvitedRespondentData } from './types';

type Props = {
  respondents: NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['respondents'];
  reportAccessKey?: string;
  stepId: string;
};

const RespondentResultButton = styled(Button)`
  float: right;
`;

const StyledCard = styled(Card)`
  margin-top: ${theme.spacing.f};
`;

export const UninvitedRespondents: FC<Props> = ({ respondents, reportAccessKey, stepId }) => {
  const { t } = useTranslation('onboarding');

  return (
    <StyledCard>
      <CardBody>
        <CardTitle>
          <HeadingSmall>{t('uninvitedEmployeesTitle')}</HeadingSmall>
          <BodySmall>{t('uninvitedEmployeesDescription')}</BodySmall>
        </CardTitle>
        <Table<UninvitedRespondentData>
          columns={cleanArray([
            {
              key: 'respondent',
              title: t('respondent'),
              dataIndex: 'respondent',
            },
            {
              key: 'team',
              title: t('team'),
              dataIndex: 'team',
            },
            {
              key: 'results',
              title: undefined,
              dataIndex: 'results',
            },
          ])}
          dataSources={respondents.map((respondent) => {
            return {
              id: respondent.id,
              respondent: `${respondent.firstname} ${respondent.surname}`,
              team: respondent.team?.name ?? t('teams:withoutLeader'),
              results: reportAccessKey && (
                <RespondentResultButton
                  onClick={() => goToRespondentResults(reportAccessKey, respondent.id, stepId)}
                  variant={'outline-primary'}
                  size={'sm'}
                >
                  {t('results')}
                </RespondentResultButton>
              ),
            };
          })}
        />
      </CardBody>
    </StyledCard>
  );
};
