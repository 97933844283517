import { ReactComponent as Check } from '@arnold/common/lib/assets/icons/Check.svg';
import { ReactComponent as Line } from '@arnold/common/lib/assets/icons/Line.svg';
import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { theme } from '../../theme';
import { Checkbox } from '../others';
import { TableBodyCell } from './TableBodyCell';
import { TableBodyCellExpandable } from './TableBodyCellExpandable';
import { TableThinBodyCell } from './TableThinBodyCell';
import type { TableDataType, TableProps } from './types';

type Props<T extends TableDataType> = Pick<
  TableProps<T>,
  'columns' | 'onClick' | 'renderDetailPanel' | 'expandDetailPanel' | 'thin' | 'rowSelection'
> & {
  rowIndex: number;
  data: T;
  isSelected: boolean;
  dataCypressTestFlag?: string;
};

type TrProps<T extends TableDataType> = Pick<TableProps<T>, 'onClick' | 'thin'> & {
  isSelected?: boolean;
  isHovered?: boolean;
};

type CheckBoxColumnProps = {
  selected: boolean;
  onChange: () => void;
};

const getThBackgroundColor = <T extends TableDataType>({ isSelected, isHovered, onClick }: TrProps<T>) => {
  if (isSelected) {
    return theme.colors.backgroundBasic.active;
  } else if (isHovered && onClick) {
    return theme.colors.backgroundCover.hover;
  } else {
    return 'none';
  }
};

const StyledTr = styled.tr<TrProps<TableDataType>>`
  border-top: ${({ thin }) => (thin ? 'none' : `${theme.spacing.a} solid ${theme.colors.borderSeparator.default}`)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  background-color: ${(props) => getThBackgroundColor<TableDataType>(props)};
  color: ${theme.colors.text.primary};

  &:active {
    background-color: ${({ onClick }) => (onClick ? theme.colors.backgroundBasic.active : undefined)};
  }

  &:nth-child(2) {
    border-top: none;
  }
`;

const StyledExpandedRow = styled.td`
  padding: 0 ${theme.spacing.f} ${theme.spacing.g} ${theme.spacing.f};
`;

const CheckboxColumn: FC<CheckBoxColumnProps> = ({ selected, onChange }) => (
  <Checkbox
    onToggle={onChange}
    selected={selected}
    partlySelected={false}
    text={''}
    checkIcon={<Check />}
    lineIcon={<Line />}
    paddingMultiplier={0}
    labelStyles={'bottom: 12px;'}
  />
);

export const TableBodyRow = <T extends TableDataType>({
  rowIndex,
  columns,
  data,
  onClick,
  isSelected,
  renderDetailPanel,
  expandDetailPanel,
  thin,
  rowSelection,
  dataCypressTestFlag,
}: Props<T>): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(expandDetailPanel?.(data) || false);
  const BodyCell = thin ? TableThinBodyCell : TableBodyCell;

  useEffect(() => {
    setIsExpanded(expandDetailPanel?.(data) || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandDetailPanel?.(data)]);

  return (
    <>
      <StyledTr
        data-cy={dataCypressTestFlag}
        onClick={onClick ? () => onClick(data) : undefined}
        onMouseOver={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        isHovered={isHovered}
        isSelected={isSelected}
        thin={thin}
      >
        {rowSelection && (
          <BodyCell stylesOverridden={'padding-right: 0 !important;'} key={`cell-${rowIndex}-checkbox`}>
            <CheckboxColumn
              selected={rowSelection.selected.includes(data.id!.toString(10))}
              onChange={() => rowSelection.onChange(data.id!.toString())}
            />
          </BodyCell>
        )}
        {columns.map((column, index) =>
          renderDetailPanel && index === 0 ? (
            <TableBodyCellExpandable
              key={`cell-${rowIndex}-${index}`}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            >
              {data[column.dataIndex]}
            </TableBodyCellExpandable>
          ) : (
            <BodyCell key={`cell-${rowIndex}-${index}`}>{data[column.dataIndex]}</BodyCell>
          ),
        )}
      </StyledTr>
      {renderDetailPanel && isExpanded && (
        <tr data-cy={dataCypressTestFlag}>
          <StyledExpandedRow colSpan={100}>{renderDetailPanel(data)}</StyledExpandedRow>
        </tr>
      )}
    </>
  );
};
