import { pushToDataLayer, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { Form, Formik, FormikValues } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { History } from 'history';
import { validatePassword } from '@arnold/core';
import auth from '../../lib/auth';
import { useChangePasswordMutation } from '../../generated/hooks';
import RegistrationFormSetPassword from './RegistrationFormSetPassword';

interface IProps {
  firstname?: string | null;
  surname?: string | null;
  username: string;
  organizationName: string;
  history: History;
  accessToken: string;
}

const Separator = styled.hr`
  margin: ${theme.spacing.g} 0;
  border-top: ${theme.spacing.a} solid ${theme.colors.borderSeparator.default};
`;

const ConfirmRegistrationForm = ({ firstname, surname, username, organizationName, history, accessToken }: IProps) => {
  const { t } = useTranslation(['registration', 'informationFooter']);

  const [changePassword] = useChangePasswordMutation();
  const handleSubmit = async (values: FormikValues, { setSubmitting }: any) => {
    try {
      await changePassword({
        variables: {
          newPassword: values.password,
          deviceToken: auth.getDeviceToken(),
          consent: values.consent,
          // revert value, because question is negation
          marketingConsent: !values.marketingConsent,
        },
        context: { headers: { Authorization: `Bearer ${accessToken}` } },
      });

      try {
        pushToDataLayer({
          event: 'ux.form',
          eventCategory: 'form',
          eventAction: 'submit',
          eventLabel: `Confirm registration`,
          eventValue: 0,
          eventNonInteraction: false,
        });
        await auth.login(username, values.password);
        setSubmitting(false);
        history.push('/overview');
      } catch (e) {
        let response;
        try {
          response = await (e as Response).json();
        } catch (err) {
          // Do nothing
        }
        if ((e as Response).status === 403) {
          history.push({
            pathname: '/login',
            state: {
              otpEmail: response.email,
              otpValidTo: response.otpValidTo,
              password: values.password,
              username,
            },
          });
        } else {
          history.push('/login');
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  const ConfirmRegisterFormValidationSchema = Yup.object().shape({
    password: Yup.string().test(
      'is-password-valid',
      t('registration:enterValidPassword'),
      (value) => value && validatePassword(value, username || undefined),
    ),
  });

  return (
    <div>
      <Trans
        i18nKey={'registration:prefilledAccountDescription'}
        components={[
          <strong key="0" />,
          <a key="1" href={`mailto:${t('informationFooter:helpEmail')}`} target="_blank" rel="noreferrer">
            {t('informationFooter:helpEmail')}
          </a>,

          <a key="2" href={`tel:${t('informationFooter:helpNumber')}`}>
            {' '}
            {t('informationFooter:helpNumber')}
          </a>,
        ]}
        values={{
          firstname,
          surname,
          username,
          organizationName,
        }}
      >
        Účet jsem připravil na jméno <strong>{`${firstname} ${surname} (${username})`}</strong> ze společnosti{' '}
        <strong>{organizationName}</strong>. Pokud potřebujete cokoliv změnit, dejte mi vědět na{' '}
        <a href={`mailto:${t('informationFooter:helpEmail')}`} target="_blank" rel="noreferrer">
          {t('informationFooter:helpEmail')}
        </a>{' '}
        nebo na <a href={`tel:${t('informationFooter:helpNumber')}`}> {t('informationFooter:helpNumber')}</a>.
      </Trans>
      <Separator />
      <Formik validationSchema={ConfirmRegisterFormValidationSchema} onSubmit={handleSubmit} initialValues={{}}>
        {({ values, errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <RegistrationFormSetPassword
              values={values}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched}
              errors={errors}
              isSubmitting={isSubmitting}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConfirmRegistrationForm;
