import { SelectMenuProps, SelectWrapper, notEmpty, theme } from '@arnold/common';
import styled from '@emotion/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveRespondentsQuery, SurveyQuery } from '../../generated/hooks';
import CustomMenu from './CustomMenu';

const Placeholder = styled.span`
  position: absolute;
  color: ${theme.colors.text.secondary};
`;

export type Team = NonNullable<ActiveRespondentsQuery['user']['organization']>['teams'][0] & {
  teams?: NonNullable<ActiveRespondentsQuery['user']['organization']>['teams'];
};

interface ITeamSelect {
  respondents: NonNullable<SurveyQuery['survey']>['whitelistedRespondents'];
  teams?: Team[];
  setRespondents: (
    respondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'],
  ) => void;
  activeRespondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'];
  alreadySelectedRespondents?: string[];
  loading?: boolean;
  isDisabled?: boolean;
  remainingCount?: number;
}

const TeamSelect = ({
  respondents,
  teams,
  setRespondents,
  activeRespondents,
  alreadySelectedRespondents = [],
  loading,
  isDisabled,
  remainingCount,
}: ITeamSelect) => {
  const { t } = useTranslation('respondentSelect');
  const scrollPositionRef = React.useRef<number>(0);

  const [search, setSearch] = useState('');
  const rootTeams = useMemo(() => teams?.filter((team) => team.rootTeam), [teams]);
  const activeRespondentsId = activeRespondents
    .map((respondent) => respondent.id)
    .filter((id) => !alreadySelectedRespondents.includes(id));

  const findSubteams = (team: Team, teams: Team[]): Team | null => {
    const newTeams = team.members.map((member) => {
      if (team.leader && member.id === team.leader.id) {
        return undefined;
      }

      const newTeam = teams.find((t) => t.leader && t.leader.id === member.id);
      if (newTeam) {
        return findSubteams(newTeam, teams);
      }
      return undefined;
    });

    const members = team.members;
    if (members.length === 0) {
      return null;
    }

    const filteredTeamMembers = team.members.filter((member) => activeRespondentsId.includes(member.id));

    if (filteredTeamMembers.length > 0 || (filteredTeamMembers.length === 0 && newTeams.filter(Boolean).length > 0)) {
      return {
        ...team,
        members: filteredTeamMembers,
        teams: newTeams.filter(notEmpty),
      };
    }
    return null;
  };

  const teamsStructure = useMemo(
    () => (rootTeams && teams ? rootTeams.map((team) => findSubteams(team, teams)).filter(notEmpty) : []),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(teams), activeRespondentsId],
  );

  useEffect(() => {
    if (teamsStructure) {
      setFilteredTeams(teamsStructure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(teamsStructure)]);

  const recursiveSearchTeam = (team: Team, teams: Team[] | undefined, search: string, searchedTeams: Team[]) => {
    if (team.name?.toLowerCase().includes(search.toLowerCase())) {
      return [...searchedTeams, team];
    } else {
      if (team.teams && team.teams.length) {
        // CHECK
        searchedTeams = team.teams
          .map((t) => {
            const foundTeams = recursiveSearchTeam(t, teams, search, searchedTeams).flat();
            return foundTeams as Team[];
          })
          .flat();
      }
      if (team.members && team.members.length) {
        return [
          ...searchedTeams,
          team.members
            .filter((respondent) =>
              `${respondent.firstname} ${respondent.surname}`.toLowerCase().includes(search.toLowerCase()),
            )
            .map((respondent) => ({
              id: -1,
              members: [respondent],
            })),
        ];
      }
    }
    return searchedTeams;
  };

  useEffect(() => {
    setFilteredTeams(teamsStructure.map((team) => recursiveSearchTeam(team, teams, search, [])).flat(2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // @ts-ignore
  const [filteredTeams, setFilteredTeams] = useState<Teams>([]);

  const CustomPlaceholder = () => {
    return <Placeholder>{t('selectRespondentOrTeamPlaceholder')}</Placeholder>;
  };

  const displayWarning = typeof remainingCount === 'number' && remainingCount < 1;

  return (
    <SelectWrapper
      blurInputOnSelect={false}
      menuShouldScrollIntoView={false}
      components={{
        Placeholder: CustomPlaceholder,
        Menu: (menuProps: SelectMenuProps) => (
          <CustomMenu
            {...menuProps}
            teams={filteredTeams}
            value={respondents}
            onToggle={setRespondents}
            search={search}
            ref={scrollPositionRef}
            loading={loading}
            remainingCount={remainingCount}
          />
        ),
        SingleValue: CustomPlaceholder,
      }}
      isSearchable={true}
      isMulti={false}
      isDisabled={isDisabled}
      closeMenuOnSelect={false}
      value={respondents}
      filterOption={(option: any, search: string) => setSearch(search)}
      // HACK - filterOption is not called without defined options
      options={[{ label: 'test', value: 'test' }]}
      styles={{
        container: (provided: any) => ({
          ...provided,
          width: '50%',
        }),
        control: (provided: any, state: { isFocused: boolean }) => ({
          ...provided,
          paddingLeft: theme.spacing.f,
          boxShadow: 'none',
          borderColor: displayWarning
            ? theme.colors.emotionWarning.active
            : state.isFocused
              ? `${theme.colors.borderMain.active} !important`
              : theme.colors.borderMain.default,
        }),
        menu: (provided: any) => ({
          ...provided,
          borderRadius: '6px',
          fontWeight: `${theme.typography.body.medium.regular.fontWeight}`,
          fontSize: `${theme.typography.body.medium.regular.fontSize}`,
          marginRight: `${theme.spacing.f}`,
          boxShadow: `${theme.shadows.elevationContentDialog}`,
          width: '100%',
          marginTop: '0',
          padding: `${theme.spacing.d} 0`,
        }),
      }}
    />
  );
};

export default TeamSelect;
