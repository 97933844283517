import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { Font } from '../../theme';

export interface IBubbleViewProps {
  left?: boolean;
  isSending?: boolean;
  runAnimation?: boolean;
  backgroundColor?: string;
  color?: string;
  font?: Font;
}

export interface IBubbleProps {
  children?: ReactNode;
}

const resize = keyframes`
    0% {
      transform: scaleY(0)
    }
    100% {
      transform: scaleY(1);
    }
`;

const ArrowLeft = css`
  border-bottom-left-radius: 0;
`;

const ArrowRight = css`
  border-bottom-right-radius: 0;
`;

const bubbleAnime = css`
  animation: ${resize} 0.3s ease-in-out;
`;

export const BubbleContainer = styled.div`
  max-width: 80%;
`;

export const BubbleView = styled.div`
  position: relative;
  background-color: ${(props: IBubbleViewProps) =>
    props.backgroundColor || (props.left ? '#fff' : props.isSending ? '#04b7ef' : '#04b7ef')};
  float: ${(props: IBubbleViewProps) => (props.left ? 'left' : 'right')};
  color: ${(props: IBubbleViewProps) => props.color || (props.left ? '#3f3f3f' : '#fff')};
  min-width: 10px;
  min-height: 18px;
  word-wrap: break-word;
  word-break: break-word;
  padding: 8px 16px;
  border-radius: 23px;
  margin-left: ${(props: IBubbleViewProps) => (props.left ? '12px' : '0')};
  ${(props: IBubbleViewProps) => (props.left ? ArrowLeft : ArrowRight)};
  ${(props: IBubbleViewProps) => (props.runAnimation ? bubbleAnime : undefined)};
  font-weight: ${({ font }: IBubbleViewProps) => (font ? font.fontWeight : null)};
  font-size: ${({ font }: IBubbleViewProps) => (font ? font.fontSize : null)};
`;
export const Bubble = (props: IBubbleProps & IBubbleViewProps) => (
  <BubbleContainer>
    <BubbleView
      backgroundColor={props.backgroundColor}
      left={props.left}
      isSending={props.isSending}
      runAnimation={props.runAnimation}
      color={props.color}
      font={props.font}
    >
      {props.children}
    </BubbleView>
  </BubbleContainer>
);
