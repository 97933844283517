import { getFrontendConfigValue, theme } from '@arnold/common';
import { FC } from 'react';
import styled from '@emotion/styled/macro';
import { REPORT_LINK } from '@arnold/core';
import { Button } from 'react-bootstrap';
import { ButtonLink } from '../../components/StyledComponents';
import { getTopLevelReportAccess, isLoggedUserSysAdmin } from '../../lib/helpers';
import { UserQuery } from '../../generated/hooks';
import { GetDataSourceParams } from './types';

type SurveyGroupActionsProps = Pick<GetDataSourceParams, 'toSurveyGroupDetail' | 't'> & {
  surveyGroup: NonNullable<UserQuery['user']['organization']>['surveyGroups'][0];
};
export const SurveyGroupActions: FC<SurveyGroupActionsProps> = ({ surveyGroup, toSurveyGroupDetail, t }) => {
  const commonAccessKey = getTopLevelReportAccess(surveyGroup.reports)?.accessKey;
  return (
    <Actions>
      <ButtonLink
        weight={500}
        onClick={() => toSurveyGroupDetail(surveyGroup.id)}
        data-cy="admin-survey-group-detail"
        data-icom={'survey-group-detail-link'}
      >
        {t('detail')}
      </ButtonLink>

      <ResultsButton
        onClick={() => {
          if (commonAccessKey) {
            window.open(
              `${getFrontendConfigValue('ARNOLD_URL')}/${REPORT_LINK.REPORT_SEQUENCE}/${commonAccessKey}${
                isLoggedUserSysAdmin() ? '?sysAdmin' : ''
              }`,
              '_blank',
            );
          }
        }}
        disabled={!commonAccessKey}
        data-icom={'survey-group-results-link'}
      >
        {t('results')}
      </ResultsButton>
    </Actions>
  );
};

const ResultsButton = styled(Button)`
  background-color: inherit;
  text-transform: none;
  color: ${theme.colors.actionPrimary.default};
`;

const Actions = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: end;
  gap: ${theme.spacing.f};
`;
