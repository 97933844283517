import { differenceInDays, startOfDay } from 'date-fns';
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { getLocalizedDateTimeLongFormWHoursAndMinutes } from '@arnold/core';
import { Tooltip } from '@arnold/common';
import { formatFullDate } from '../../../lib/common';
import { DateInfoProps } from './types';
import { getEndDateTitle } from './utils';

type DateTooltipProps = Pick<DateInfoProps, 'startsAt' | 'endsAt' | 'reminders' | 'displayOnlyStartDate'> & {
  children: ReactNode;
};

export const DateTooltip: FC<DateTooltipProps> = ({ startsAt, endsAt, reminders, displayOnlyStartDate, children }) => {
  const { t, i18n } = useTranslation('surveyOverviewRow');

  const titleStartDate = `${t('surveyStart', { ns: 'weekPicker' })}: ${formatFullDate(
    startsAt,
    i18n.language,
    displayOnlyStartDate,
  )}`;
  const titleEndDate = getEndDateTitle(t, i18n.language, false, endsAt);

  const titleLength = `${t('length', {
    length: endsAt ? differenceInDays(startOfDay(new Date(endsAt)), startOfDay(new Date(startsAt))) + 1 : '-',
  })}`;

  const reminderInfo = reminders
    .map(
      (r) =>
        t('reminder', { ns: 'weekPicker' }) +
        ': ' +
        getLocalizedDateTimeLongFormWHoursAndMinutes(new Date(r.reminderAt), i18n.language) +
        '<br>',
    )
    .join('');

  return (
    <Tooltip
      title={`${titleStartDate}${displayOnlyStartDate ? '' : `<br>${reminderInfo}${titleEndDate}<br>${titleLength}`}`}
    >
      {children}
    </Tooltip>
  );
};
