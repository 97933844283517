import { RouteComponentProps } from 'react-router';
import { GeneralProcessSurveyDetailScreen } from '../../components/GeneralProcessSurveyDetail/GeneralProcessSurveyDetailScreen';
import { IsAccessTokenValidQuery, OrganizationQuery } from '../../generated/hooks';

interface IProps extends RouteComponentProps {
  user: NonNullable<IsAccessTokenValidQuery['isAccessTokenValid']['user']>;
  organization: NonNullable<OrganizationQuery['organization']>;
}

export const ProcessSurveyDetailScreen = (props: IProps) => {
  return <GeneralProcessSurveyDetailScreen {...props} />;
};
