import { HeadingLarge } from '@arnold/common';
import { Query } from '@apollo/client/react/components';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../../components/Common';
import Loading from '../../components/Loading';
import RecipientsTable from '../../components/Settings/RecipientsTable';
import { getOrganizationSettingsReport, getReportRecipients } from '../../graphql/queries';
import { PickerChoice } from '../../lib/types';
import { sortLanguages } from '../../lib/common';
import {
  OrganizationQuery,
  OrganizationSettingReportQuery,
  OrganizationSettingReportQueryVariables,
  ReportRecipientsQuery,
  ReportRecipientsQueryVariables,
  UserQuery,
} from '../../generated/hooks';

interface IProps {
  user: UserQuery['user'];
  organization: OrganizationQuery['organization'];
}

function getPickerChoice(value: string, label: string): PickerChoice {
  return {
    value,
    label,
  };
}

const ReportScreen = (props: IProps) => {
  const [t] = useTranslation('report');

  if (!props.organization) {
    return null;
  }

  const organizationId = props.organization ? props.organization.id : '1';
  return (
    <Query<OrganizationSettingReportQuery, OrganizationSettingReportQueryVariables>
      query={getOrganizationSettingsReport}
      variables={{ organizationId }}
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return null;
        }
        if (data && data.organization) {
          const organization = data.organization;
          const languageOptions = sortLanguages(
            organization.languages,
            new Map(organization.languages.map((l) => [l.code, t(`languageSelector:${l.code}`)])),
          ).map((language) => getPickerChoice(language!.id, t(`languageSelector:${language!.code}`)));
          return (
            <Query<ReportRecipientsQuery, ReportRecipientsQueryVariables>
              query={getReportRecipients}
              variables={{ organizationId }}
            >
              {({ loading, error, data }) => {
                if (loading) {
                  return <Loading />;
                }
                if (error) {
                  return null;
                }
                if (data && data.reportRecipients !== null) {
                  return (
                    <PageWrapper data-cy="admin-page-content">
                      <HeadingLarge data-icom="heading-report-recipients">{t('reportScreenHeader')}</HeadingLarge>
                      <p className="p">{t('reportScreenTitle')}</p>
                      <br />
                      <br />

                      <h2 data-icom="heading-additional-report-recipients">{t('unitReports')}</h2>
                      <p className="p">{t('report:unitReportsTitle')}</p>

                      <Card>
                        <RecipientsTable
                          recipients={data.reportRecipients}
                          organization={organization}
                          type="UNIT"
                          languageOptions={languageOptions}
                        />
                      </Card>
                    </PageWrapper>
                  );
                }
                return null;
              }}
            </Query>
          );
        }
        return null;
      }}
    </Query>
  );
};

export default ReportScreen;
