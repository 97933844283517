import styled from '@emotion/styled';
import { theme } from '../../theme';

type PlainButtonProps = {
  disabled?: boolean;
};

export const PlainButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border: none;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  transition: background 0.15s ease;

  background: ${theme.colors.backgroundCover.default};

  ${({ disabled }: PlainButtonProps) =>
    disabled
      ? `
      & svg path {
          fill: ${theme.colors.iconText.disabled} !important;
      }`
      : `
      &:hover {
        background: ${theme.colors.backgroundCover.hover} !important;
      }
      &:focus {
        background: ${theme.colors.backgroundCover.active};
      }
    `}
`;

export default PlainButton;
