import styled from '@emotion/styled';
import { FormCheck, FormControl } from 'react-bootstrap';

export const FormLabelStyled = styled.label`
  font-size: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  color: #767676 !important;
`;

export const CheckBoxLabelStyled = styled.label`
  font-size: 1em !important;
  color: #3f3f3f !important;
`;

export const FormGroupLabel = (props: any) => (
  <FormLabelStyled className={`form-group__label ${props.className ? props.className : ''}`} {...props}>
    {props.children}
  </FormLabelStyled>
);

export const FormErrorFeedback = ({ error }: any) => (
  <FormControl.Feedback type="invalid" className="form-text text-danger mr-6 w-auto">
    {error}
  </FormControl.Feedback>
);

export const FormRadioInput = ({
  name,
  dataCy,
  dataIcom,
  className,
  value,
  clicked,
  changed,
  defaultChecked,
  label,
  disabled,
}: any) => (
  <FormCheck>
    <FormCheck.Input
      name={name}
      type="radio"
      value={value}
      onChange={() => changed()}
      className={`form-check__control ${className || ''}`}
      checked={defaultChecked}
      disabled={disabled}
    />
    <CheckBoxLabelStyled
      data-cy={dataCy}
      data-icom={dataIcom}
      className="form-check__label"
      onClick={() => !disabled && clicked()}
    >
      {label}
    </CheckBoxLabelStyled>
  </FormCheck>
);
