import { ENVIRONMENT } from '@arnold/core';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';

import { getFrontendConfigValue } from '@arnold/common';
import cs from './locales/cs.json';
import en from './locales/en.json';
import sk from './locales/sk.json';

const resources = { cs, en, sk };

i18n
  .use(intervalPlural)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    lowerCaseLng: true,
    debug: getFrontendConfigValue('ENVIRONMENT') === ENVIRONMENT.LOCAL,
    interpolation: {
      escapeValue: false,
    },
    react: {
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'li', 'ul', 'strong', 'i'], // don't convert to <1></1> if simple react elements
    },
  });

export default i18n;
