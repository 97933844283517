import { clone, equals, findLastIndex } from 'ramda';
import { uniqueId } from '../../lib/helpers';
import { RuleOperator } from '../../generated/hooks';
import { MINIMUM_NUMBER_OF_CHOICES } from './constants';
import {
  areAllQuestionsBubbles,
  createEmptyQuestion,
  extractValueFromRules,
  getElseRules,
  getRulesForValue,
  isMultiselect,
} from './helpers';
import { QuestionDetail, QuestionSubsectionsMap, Section } from './topicStructure';
import { QUESTION_DEFINITION_TYPE } from './types';

function isQuestionInvalid(question: QuestionDetail, languageId: string) {
  const translation = question.translations.find((t) => t.language.id === languageId);
  const hasEmptyChoice =
    translation &&
    translation.choices &&
    translation.choices.some((choice) => choice !== null && choice.text !== null && !choice.text.trim());
  const hasLowNumberOfChoices =
    !translation || (translation.choices && translation.choices.length < MINIMUM_NUMBER_OF_CHOICES);

  const hasLowNumberOfAnonymities = !translation;

  return (
    !translation ||
    !translation.text ||
    !translation.text.trim() ||
    hasEmptyChoice ||
    hasLowNumberOfChoices ||
    hasLowNumberOfAnonymities
  );
}

function isEqual(question: QuestionDetail, state: SectionState) {
  let foundQuestion = state.questions.find((q) => q.id === question.id);
  if (!foundQuestion && state.questionSubsections) {
    Array.from(state.questionSubsections.values()).forEach((subsectionsMap) => {
      Object.values(subsectionsMap).forEach((subsections) => {
        subsections.forEach((subsection) => {
          const indirectQuestion = subsection.find((q) => q.id === question.id);
          if (indirectQuestion) {
            foundQuestion = indirectQuestion;
          }
        });
      });
    });
  }
  if (!foundQuestion) {
    throw new Error('Question not found');
  }

  return equals(foundQuestion, question);
}

export type SectionState = {
  questions: QuestionDetail[];
  questionsToUpdate: Map<string, QuestionDetail>;
  questionsToDelete: Map<string, QuestionDetail>;
  questionsToCreate: Map<string, QuestionDetail>;
  previousSectionRoutingQuestions?: QuestionDetail[];
  nextSectionStartingQuestion?: QuestionDetail;
  questionSubsections?: QuestionSubsectionsMap;
  displayEditForm: boolean;
  displayNewSectionForm: boolean;
  isValid: boolean;
  sectionIndex: number;
  languageId: string;
  languageCode?: string;
  stateBeforeChange?: SectionState;
  savedState?: SectionState;
  stateBeforeDeletion?: SectionState;
  chatBubbleOnlySection: boolean;
  focusedQuestionId?: string | null;
};

export enum ActionTypes {
  SHOW_FORM,
  SET_STATE,
  SET_QUESTION,
  DELETE_QUESTION,
  DELETE_QUESTIONS_FOR_CHOICE,
  SAVE_SUCCESS,
  GO_BACK,
  CLOSE_FORM,
  SHOW_NEW_SECTION_FORM,
  CLOSE_NEW_SECTION_FORM,
  SHOW_ADD_QUESTION_FORM,
  SHOW_ADD_QUESTION_SUBSECTION_FORM,
  SET_FOCUSED_QUESTION,
}

type ActiveSection = {
  activeSection: number | null;
  setActiveSection: (newActiveSection: number | null) => void;
};

type SetQuestionAction = {
  type: ActionTypes.SET_QUESTION;
  questionId: string;
  parentQuestionId?: string;
  question: QuestionDetail;
};
type DeleteQuestionsForChoiceAction = {
  type: ActionTypes.DELETE_QUESTIONS_FOR_CHOICE;
  choiceIndex: number;
  questionId: string;
  textBubbleIndex?: number;
};

type DeleteQuestion = {
  type: ActionTypes.DELETE_QUESTION;
  questionId: string;
  parentQuestionId?: string;
  question: QuestionDetail;
};

type AddSection = {
  type: ActionTypes.SHOW_NEW_SECTION_FORM;
  questionType: QUESTION_DEFINITION_TYPE;
};

type AddQuestionToSection = {
  type: ActionTypes.SHOW_ADD_QUESTION_FORM;
  questionType: QUESTION_DEFINITION_TYPE;
  index: number;
  sectionIndex: number;
};

type AddQuestionToSubsection = {
  type: ActionTypes.SHOW_ADD_QUESTION_SUBSECTION_FORM;
  question: QuestionDetail;
  parentQuestionId: string;
  choiceIndex: number;
  subsectionIndex: number;
};

type SetFocusedQuestion = {
  type: ActionTypes.SET_FOCUSED_QUESTION;
  questionId: string;
};

export type BaseAction =
  | { type: ActionTypes.SHOW_FORM }
  | { type: ActionTypes.SET_STATE; newState: SectionState }
  | { type: ActionTypes.SAVE_SUCCESS }
  | { type: ActionTypes.GO_BACK }
  | { type: ActionTypes.CLOSE_FORM }
  | { type: ActionTypes.CLOSE_NEW_SECTION_FORM }
  | DeleteQuestion
  | DeleteQuestionsForChoiceAction
  | SetQuestionAction
  | AddSection
  | AddQuestionToSubsection
  | AddQuestionToSection
  | SetFocusedQuestion;

export type Action = ActiveSection & BaseAction;

export function getInitialState(
  section: Section | null,
  sectionIndex: number,
  languageId: string,
  languageCode?: string,
  previousSectionRoutingQuestions?: QuestionDetail[],
  nextSectionStartingQuestion?: QuestionDetail,
): SectionState {
  const initialState = {
    sectionIndex,
    questions: section?.questions || [],
    questionSubsections: section?.questionSubsections,
    questionsToCreate: new Map(),
    questionsToUpdate: new Map(),
    questionsToDelete: new Map(),
    previousSectionRoutingQuestions,
    nextSectionStartingQuestion,
    isValid: true,
    languageId,
    languageCode,
    displayEditForm: false,
    displayNewSectionForm: false,
    chatBubbleOnlySection: !!section && areAllQuestionsBubbles(section.questions),
  };

  return {
    ...initialState,
    savedState: initialState,
  };
}

const handleActiveSection = (
  sectionIndex: number,
  questionsToUpdate: Map<string, QuestionDetail>,
  questionsToDelete: Map<string, QuestionDetail>,
  questionsToCreate: Map<string, QuestionDetail>,
  activeSection: number | null,
  setActiveSection: (newActiveSection: number | null) => void,
) => {
  const isThisSectionInactive =
    questionsToUpdate.size === 0 && questionsToDelete.size === 0 && questionsToCreate.size === 0;

  if (isThisSectionInactive && activeSection === sectionIndex) {
    setActiveSection(null);
  }

  if (!isThisSectionInactive && activeSection === null) {
    setActiveSection(sectionIndex);
  }
};

function setQuestion(state: SectionState, action: SetQuestionAction & ActiveSection) {
  const questions = state.questions.map((question) => (question.id === action.questionId ? action.question : question));
  const questionsToUpdate = new Map(state.questionsToUpdate);
  const questionsToCreate = new Map(state.questionsToCreate);

  const questionToUpdateArrayIndex = questions.findIndex((q) => q.id === action.questionId);
  if (
    questionsToCreate.has(action.question.id) &&
    questionToUpdateArrayIndex &&
    questionToUpdateArrayIndex === questions.length - 1 &&
    !questionsToCreate.has(questions[questionToUpdateArrayIndex - 1].id)
  ) {
    questions[questionToUpdateArrayIndex - 1].rules = [
      action.question.rules.find((r) => !r.origValue) || {
        ...action.question.rules[0],
        origValue: '',
        operator: null,
      },
    ];
    questionsToUpdate.set(questions[questionToUpdateArrayIndex - 1].id, questions[questionToUpdateArrayIndex - 1]);
  }

  // Replace question in subsections
  const questionSubsections = state.questionSubsections && new Map(state.questionSubsections);
  if (questionSubsections && action.parentQuestionId) {
    const questionSubsection = questionSubsections.get(action.parentQuestionId);
    if (questionSubsection) {
      const choice = Object.keys(questionSubsection).find((key) =>
        questionSubsection[key].some((sqs) => sqs.some((sq) => sq.id === action.questionId)),
      );
      if (choice) {
        const flattenQuestionSubsection = questionSubsection[choice].flat();
        if (
          questionsToCreate.has(action.questionId) &&
          flattenQuestionSubsection[flattenQuestionSubsection.length - 1].id === action.questionId
        ) {
          const usedRules = action.question.rules.length
            ? action.question.rules
            : state.questions.find((q) => q.id === action.parentQuestionId)!.rules.filter((r) => !r.origValue);
          const lastNotCreatedTellIndex = findLastIndex(
            (sq) => !questionsToCreate.has(sq.id),
            flattenQuestionSubsection,
          );
          if (lastNotCreatedTellIndex !== -1) {
            flattenQuestionSubsection[lastNotCreatedTellIndex].rules = usedRules;
            questionSubsection[choice] = [flattenQuestionSubsection];
            questionsToUpdate.set(
              flattenQuestionSubsection[lastNotCreatedTellIndex].id,
              flattenQuestionSubsection[lastNotCreatedTellIndex],
            );
          } else {
            const parentQuestionArrayIndex = questions.findIndex((q) => q.id === action.parentQuestionId);
            if (parentQuestionArrayIndex !== -1) {
              const parentQuestion = clone(questions[parentQuestionArrayIndex]);
              parentQuestion.rules = [
                ...extractValueFromRules(parentQuestion.rules, choice),
                ...usedRules.map((r) => ({
                  ...r,
                  origValue: isMultiselect(parentQuestion) ? `[${choice}]` : choice,
                  operator: isMultiselect(parentQuestion) ? RuleOperator.Inany : null,
                })),
              ];

              questions[parentQuestionArrayIndex] = parentQuestion;
              if (questionsToCreate && questionsToCreate.has(questions[parentQuestionArrayIndex].id)) {
                questionsToCreate.set(questions[parentQuestionArrayIndex].id, questions[parentQuestionArrayIndex]);
              } else if (state.savedState && isEqual(questions[parentQuestionArrayIndex], state.savedState)) {
                // Save for updating and validate
                questionsToUpdate.delete(questions[parentQuestionArrayIndex].id);
              } else {
                questionsToUpdate.set(questions[parentQuestionArrayIndex].id, questions[parentQuestionArrayIndex]);
              }
            }
          }
        }
      }

      questionSubsections.set(
        action.parentQuestionId,
        Object.keys(questionSubsection).reduce((acc, key) => {
          return {
            ...acc,
            [key]: questionSubsection[key].map((subsection) =>
              subsection.map((question) => (question.id === action.questionId ? action.question : question)),
            ),
          };
        }, {}),
      );
    }
  }

  if (state.questionsToCreate && state.questionsToCreate.has(action.questionId)) {
    questionsToUpdate.delete(action.questionId);
    questionsToCreate.set(action.questionId, action.question);
  } else if (state.savedState && isEqual(action.question, state.savedState)) {
    // Save for updating and validate
    questionsToUpdate.delete(action.questionId);
  } else {
    questionsToUpdate.set(action.questionId, action.question);
  }
  const isValid = Array.from(questionsToUpdate.values())
    .concat(Array.from(questionsToCreate.values()))
    .every((q) => !isQuestionInvalid(q, state.languageId));

  handleActiveSection(
    state.sectionIndex,
    questionsToUpdate,
    questionsToCreate,
    state.questionsToDelete,
    action.activeSection,
    action.setActiveSection,
  );
  return {
    ...state,
    questionsToUpdate,
    questionsToCreate,
    stateBeforeDeletion: state,
    chatBubbleOnlySection: areAllQuestionsBubbles(questions),
    questionSubsections,
    isValid,
    questions,
  };
}

function deleteQuestion(state: SectionState, action: DeleteQuestion & ActiveSection) {
  const questionsToCreate = new Map(state.questionsToCreate);
  const questionsToUpdate = new Map(state.questionsToUpdate);
  const questionsToDelete = new Map(state.questionsToDelete);

  const questionToDeleteArrayIndex = state.questions.findIndex((q) => q.id === action.questionId);
  const questionsAfterDeletion = state.questions.filter((question) => question.id !== action.questionId);

  // Move flag isFirstInSectionWithNumber to the new first question
  const firstQuestionInSection = state.questions[0];
  if (
    questionsAfterDeletion.length > 0 &&
    firstQuestionInSection &&
    firstQuestionInSection.id === action.questionId &&
    firstQuestionInSection.isFirstInSectionWithNumber
  ) {
    questionsAfterDeletion[0] = {
      ...questionsAfterDeletion[0],
      isFirstInSectionWithNumber: firstQuestionInSection.isFirstInSectionWithNumber,
    };
    if (questionsToCreate.get(questionsAfterDeletion[0].id)) {
      questionsToCreate.set(questionsAfterDeletion[0].id, questionsAfterDeletion[0]);
    } else {
      questionsToUpdate.set(questionsAfterDeletion[0].id, questionsAfterDeletion[0]);
    }
  }

  if (
    questionsAfterDeletion.length &&
    questionToDeleteArrayIndex === questionsAfterDeletion.length &&
    action.question.rules.length
  ) {
    questionsAfterDeletion[questionsAfterDeletion.length - 1].rules = [
      action.question.rules.find((r) => !r.origValue) || {
        ...action.question.rules[0],
        origValue: '',
        operator: null,
      },
    ];
  }

  // Replace question in subsections
  const questionSubsectionsAfterDeletion = state.questionSubsections && new Map(state.questionSubsections);
  if (questionSubsectionsAfterDeletion && action.parentQuestionId) {
    const questionSubsection = questionSubsectionsAfterDeletion.get(action.parentQuestionId);
    if (questionSubsection) {
      questionSubsectionsAfterDeletion.set(
        action.parentQuestionId,
        Object.keys(questionSubsection).reduce((acc, key) => {
          return {
            ...acc,
            [key]: questionSubsection[key].map((subsection) =>
              subsection.filter((question) => question.id !== action.questionId),
            ),
          };
        }, {}),
      );
    }
  }

  if (questionSubsectionsAfterDeletion) {
    const questionSubsection = questionSubsectionsAfterDeletion.get(action.questionId);
    if (questionSubsection) {
      questionSubsectionsAfterDeletion.delete(action.questionId);
      if (!questionsToCreate.has(action.questionId)) {
        Object.keys(questionSubsection).forEach((key) =>
          questionSubsection[key].forEach((subsection) =>
            subsection.forEach((question) => questionsToDelete.set(question.id, question)),
          ),
        );
      }
    }
  }

  if (questionsToCreate.has(action.questionId)) {
    questionsToCreate.delete(action.questionId);
  } else {
    questionsToDelete.set(action.questionId, action.question);
  }
  handleActiveSection(
    state.sectionIndex,
    questionsToUpdate,
    questionsToCreate,
    questionsToDelete,
    action.activeSection,
    action.setActiveSection,
  );
  return {
    ...state,
    questionsToDelete,
    questionsToCreate,
    questionsToUpdate,
    stateBeforeDeletion: state,
    questionSubsections: questionSubsectionsAfterDeletion,
    questions: questionsAfterDeletion,
    chatBubbleOnlySection: areAllQuestionsBubbles(questionsAfterDeletion),
  };
}

function deleteSingleQuestionForChoice(state: SectionState, action: DeleteQuestionsForChoiceAction & ActiveSection) {
  if (action.textBubbleIndex === undefined) {
    return state;
  }

  const questions = state.questions.slice();
  const parentQuestionArrayIndex = questions.findIndex((q) => q.id === action.questionId);
  const questionSubsectionsAfterDeletion = state.questionSubsections && new Map(state.questionSubsections);
  const questionsToCreate = new Map(state.questionsToCreate);
  const questionsToUpdate = new Map(state.questionsToUpdate);
  const questionsToDelete = new Map(state.questionsToDelete);
  if (questionSubsectionsAfterDeletion) {
    const questionSubsection = questionSubsectionsAfterDeletion.get(action.questionId);
    if (questionSubsection) {
      const questionsInSubsection = questionSubsection[action.choiceIndex].flat();
      const subQuestionToDelete = questionsInSubsection.splice(action.textBubbleIndex, 1)[0];
      if (subQuestionToDelete) {
        if (questionsInSubsection.length) {
          if (action.textBubbleIndex) {
            questionsInSubsection[action.textBubbleIndex - 1].rules = subQuestionToDelete.rules;
          }
        } else if (parentQuestionArrayIndex !== -1) {
          questions[parentQuestionArrayIndex].rules = [
            ...extractValueFromRules(questions[parentQuestionArrayIndex].rules, action.choiceIndex.toString(10)),
            ...subQuestionToDelete.rules.map((r) => ({
              ...r,
              origValue: isMultiselect(questions[parentQuestionArrayIndex])
                ? `[${action.choiceIndex.toString(10)}]`
                : action.choiceIndex.toString(10),
              operator: isMultiselect(questions[parentQuestionArrayIndex]) ? RuleOperator.Inany : null,
            })),
          ];

          questionsToUpdate.set(questions[parentQuestionArrayIndex].id, questions[parentQuestionArrayIndex]);
        }

        if (questionsToCreate.has(subQuestionToDelete.id)) {
          questionsToCreate.delete(subQuestionToDelete.id);
        } else {
          questionsToDelete.set(subQuestionToDelete.id, subQuestionToDelete);
        }
      }

      questionSubsectionsAfterDeletion.set(action.questionId, {
        ...questionSubsection,
        [action.choiceIndex]: [questionsInSubsection],
      });
    }
  }
  handleActiveSection(
    state.sectionIndex,
    questionsToUpdate,
    questionsToCreate,
    questionsToDelete,
    action.activeSection,
    action.setActiveSection,
  );
  return {
    ...state,
    questions,
    questionsToUpdate,
    questionsToDelete,
    questionSubsections: questionSubsectionsAfterDeletion,
  };
}

function deleteQuestionsForChoice(state: SectionState, action: DeleteQuestionsForChoiceAction & ActiveSection) {
  const questionSubsectionsAfterDeletion = state.questionSubsections && new Map(state.questionSubsections);
  const questionsToCreate = new Map(state.questionsToCreate);
  const questionsToDelete = new Map(state.questionsToDelete);
  if (questionSubsectionsAfterDeletion) {
    const questionSubsection = questionSubsectionsAfterDeletion.get(action.questionId);
    if (questionSubsection) {
      const questionsInSubsection = questionSubsection[action.choiceIndex];
      if (questionsInSubsection) {
        questionsInSubsection.flat().forEach((question) => {
          if (questionsToCreate.has(question.id)) {
            questionsToCreate.delete(question.id);
          } else {
            questionsToDelete.set(question.id, question);
          }
        });
      }
      questionSubsectionsAfterDeletion.set(
        action.questionId,
        Object.keys(questionSubsection)
          .filter((key) => key !== action.choiceIndex.toString())
          .reduce((acc, key) => {
            const newKey =
              key !== 'default' && parseInt(key, 10) > action.choiceIndex ? (parseInt(key, 10) - 1).toString() : key;
            return {
              ...acc,
              [newKey]: questionSubsection[key].slice(),
            };
          }, {}),
      );
    }
  }
  handleActiveSection(
    state.sectionIndex,
    state.questionsToUpdate,
    state.questionsToCreate,
    questionsToDelete,
    action.activeSection,
    action.setActiveSection,
  );
  return {
    ...state,
    questionsToDelete,
    questionSubsections: questionSubsectionsAfterDeletion,
  };
}

const addQuestion = (state: SectionState, action: AddQuestionToSection & ActiveSection) => {
  const newQuestion = createEmptyQuestion(action.questionType, state.languageId);
  const questions = state.questions.slice();
  const questionsToCreate = new Map(state.questionsToCreate);
  const questionsToUpdate = new Map(state.questionsToUpdate);
  // Move flag isFirstInSectionWithNumber to first question
  if (questions.length && action.index === 0 && questions[0].isFirstInSectionWithNumber) {
    newQuestion.isFirstInSectionWithNumber = questions[0].isFirstInSectionWithNumber;
    questions[0] = { ...questions[0], isFirstInSectionWithNumber: null };

    if (questionsToCreate.has(questions[0].id)) {
      questionsToCreate.set(questions[0].id, questions[0]);
    } else {
      questionsToUpdate.set(questions[0].id, questions[0]);
    }
  } else if (questions.length === 0) {
    newQuestion.isFirstInSectionWithNumber = action.sectionIndex;
  }

  const defaultRule: any = questions.length && questions[questions.length - 1].rules.find((r) => r.origValue === '');

  if (action.index === questions.length) {
    if (defaultRule) {
      newQuestion.rules.push(defaultRule);
    } else if (state.nextSectionStartingQuestion) {
      newQuestion.rules.push(
        defaultRule ||
          ({
            __typename: 'AlwaysMatchingRule',
            id: uniqueId(),
            origValue: '',
            nextQuestion: state.nextSectionStartingQuestion,
            operator: null,
            order: 1,
            isFromDefault: null,
          } as any),
      );
    }
  } else if (defaultRule) {
    newQuestion.rules.push(defaultRule);
  }

  questions.splice(action.index, 0, newQuestion);
  questionsToCreate.set(newQuestion.id, newQuestion);

  handleActiveSection(
    state.sectionIndex,
    questionsToUpdate,
    questionsToCreate,
    state.questionsToDelete,
    action.activeSection,
    action.setActiveSection,
  );
  return {
    ...state,
    questions,
    chatBubbleOnlySection: areAllQuestionsBubbles(questions),
    questionsToCreate,
    questionsToUpdate,
    isValid: false,
    focusedQuestionId: newQuestion.id,
  };
};

const addQuestionToSubsection = (state: SectionState, action: AddQuestionToSubsection & ActiveSection) => {
  const questions = state.questions.slice();
  const questionsToCreate = new Map(state.questionsToCreate);
  const questionsToUpdate = new Map(state.questionsToUpdate);
  const questionSubsectionsAfterCreation = state.questionSubsections && new Map(state.questionSubsections);
  const questionSubsection = questionSubsectionsAfterCreation?.get(action.parentQuestionId);
  const choiceIndexSubsection = (questionSubsection && questionSubsection[action.choiceIndex]?.flat()) || [];

  const sub = createEmptyQuestion(QUESTION_DEFINITION_TYPE.tell, state.languageId);
  const rulesForChoice = getRulesForValue(action.question, action.choiceIndex.toString(10));
  sub.rules =
    action.subsectionIndex && choiceIndexSubsection.length
      ? choiceIndexSubsection[choiceIndexSubsection.length - 1].rules
      : action.parentQuestionId && action.choiceIndex !== undefined
        ? rulesForChoice.length
          ? rulesForChoice.map((r) => ({ ...r, origValue: '', operator: null }))
          : getElseRules(action.question).map((r) => ({
              ...r,
              origValue: '',
              isFromDefault: true,
            }))
        : action.question.rules.map((r) => ({ ...r, origValue: '' }));
  questionsToCreate.set(sub.id, sub);

  if (questionSubsectionsAfterCreation) {
    choiceIndexSubsection.splice(action.subsectionIndex, 0, sub);

    questionSubsectionsAfterCreation.set(action.parentQuestionId, {
      ...questionSubsection,
      [action.choiceIndex]: [choiceIndexSubsection],
    });
  }
  handleActiveSection(
    state.sectionIndex,
    questionsToUpdate,
    questionsToCreate,
    state.questionsToDelete,
    action.activeSection,
    action.setActiveSection,
  );
  return {
    ...state,
    questions,
    questionsToCreate,
    questionsToUpdate,
    questionSubsections:
      questionSubsectionsAfterCreation ||
      new Map().set(action.parentQuestionId, {
        [action.choiceIndex]: [[sub]],
      }),
    focusedQuestionId: sub.id,
  };
};

export function reducer(state: SectionState, action: Action) {
  switch (action.type) {
    case ActionTypes.SHOW_FORM:
      return { ...state, displayEditForm: true };
    case ActionTypes.GO_BACK:
      return state.stateBeforeDeletion ? state.stateBeforeDeletion : state;
    case ActionTypes.SET_QUESTION:
      return setQuestion(state, action);
    case ActionTypes.DELETE_QUESTION:
      return deleteQuestion(state, action);
    case ActionTypes.DELETE_QUESTIONS_FOR_CHOICE:
      return action.textBubbleIndex !== undefined
        ? deleteSingleQuestionForChoice(state, action)
        : deleteQuestionsForChoice(state, action);
    case ActionTypes.SHOW_ADD_QUESTION_FORM:
      return addQuestion(state, action);
    case ActionTypes.SHOW_ADD_QUESTION_SUBSECTION_FORM:
      return addQuestionToSubsection(state, action);
    case ActionTypes.CLOSE_FORM:
      return state.savedState
        ? {
            ...state.savedState,
            savedState: state.savedState,
            displayEditForm: false,
          }
        : state;
    case ActionTypes.SAVE_SUCCESS:
      return {
        ...state,
        displayEditForm: false,
        questionsToCreate: new Map(),
        questionsToUpdate: new Map(),
        questionsToDelete: new Map(),
        savedState: state,
      };
    case ActionTypes.SET_STATE:
      return action.newState;
    case ActionTypes.SHOW_NEW_SECTION_FORM:
      return {
        ...state,
        displayNewSectionForm: true,
        isValid: false,
      };
    case ActionTypes.CLOSE_NEW_SECTION_FORM:
      return state.savedState
        ? {
            ...state.savedState,
            savedState: state.savedState,
            displayNewSectionForm: false,
          }
        : state;
    case ActionTypes.SET_FOCUSED_QUESTION:
      return {
        ...state,
        focusedQuestionId: action.questionId,
      };
    default:
      throw new Error();
  }
}
