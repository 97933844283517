import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PublicLayout from '../../components/Common/PublicLayout';
import Tabs, { LandingPage } from '../../components/LandingPageTabs';
import storage from '../../lib/storage';
import LoginAside from './LoginAside';
import LoginContainer from './LoginContainer';

const Login = () => {
  const { lang } = useParams<{ lang?: string; showOnlySelected?: string }>();
  const detectedLang = lang || storage.getDetectedLanguage()?.substring(0, 2);
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const showOnlySelected = query.get('showOnlySelected') === 'true';

  const handleSuccessFullSingIn = () => {
    history.push('/overview');
  };

  return (
    <PublicLayout basePath={'login'} history={history} lang={detectedLang} aside={<LoginAside />}>
      <>
        <Tabs history={history} selected={LandingPage.login} showOnlySelected={showOnlySelected} />
        <LoginContainer onSuccessFullSingIn={handleSuccessFullSingIn} lang={detectedLang} />
      </>
    </PublicLayout>
  );
};

export default Login;
