import { FC, ReactElement, useRef } from 'react';
import {
  BarChart as RechartsBarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  Text,
  LabelList,
  Tooltip as ChartTooltip,
  CartesianGrid,
  Legend,
} from 'recharts';
import styled from '@emotion/styled';
import { theme } from '../../../theme';

type ChartData = {
  x: string;
  y?: number;
  fill?: string;
};

type BarChartProps = {
  data: ChartData[];
  width?: number;
  height?: number;
  hideYAxis?: boolean;
  dataKeys?: string[];
  barNames?: string[];
  onClick?: (chartData: OnClickProps) => void;
  CustomBar?: (props: CustomBarProps) => JSX.Element;
  CustomXAxisTick?: (props: XAxisTickProps) => JSX.Element;
  CustomYAxisTick?: (props: XAxisTickProps) => JSX.Element;
  yTicks?: number[];
  xAxisHeight?: number;
  barSize?: number;
  noBackground?: boolean;
  legendAllignment?: 'left' | 'center' | 'right';
  topLabel?: boolean;
  valueFormatter?: (value: any, index?: number, dataKey?: string) => string;
  valueAccessor?: (data: ChartData, dataKey: string) => number | string;
  dataRange?: [number, number];
  tooltipContent?: (payload: any, dataKey?: string) => string;
  showGrid?: boolean;
  barColors?: { [key: string]: string };
};

type XAxisTickPayload = {
  value: string;
};

export type OnClickProps = {
  x: number;
  y: number;
  fill: string;
  id: string;
  width: string;
  step: number;
};

export type CustomBarProps = {
  fill: string;
  step?: number;
  tooltipPayload: Array<{ dataKey: string }>;
};

export type XAxisTickProps = {
  x: number;
  y: number;
  payload: XAxisTickPayload;
};

const RADIUS_BAR = 4;
const DEFAULT_CONTAINER_HEIGHT = 100;
const DEFAULT_X_AXIS_HEIGHT = 50;
const DEFAULT_BAR_SIZE = 28;

const XAxisTick: FC<XAxisTickProps> = ({ x, y, payload }) => (
  <Text x={x} y={y} width={78} textAnchor="middle" verticalAnchor="start">
    {payload.value}
  </Text>
);

export const BarChart: FC<BarChartProps> = ({
  data,
  width,
  height,
  hideYAxis,
  onClick,
  CustomBar,
  CustomXAxisTick,
  CustomYAxisTick,
  yTicks,
  xAxisHeight,
  barSize,
  noBackground,
  topLabel,
  valueFormatter,
  dataRange,
  tooltipContent,
  showGrid,
  dataKeys,
  barNames,
  valueAccessor,
  barColors,
  legendAllignment,
}) => {
  const XAxisTickComponent = CustomXAxisTick ?? (XAxisTick as (props: any) => ReactElement<SVGElement>);
  const tooltipRef = useRef<string>();
  const allDataKeys = dataKeys ?? ['y'];
  return (
    <ResponsiveContainer height={height || DEFAULT_CONTAINER_HEIGHT} width={width}>
      <RechartsBarChart data={data} margin={{ top: 25, right: 5, bottom: 5, left: 5 }}>
        <XAxis
          dataKey="x"
          axisLine={false}
          tickLine={false}
          tick={XAxisTickComponent}
          interval={0}
          height={xAxisHeight || DEFAULT_X_AXIS_HEIGHT}
        />
        {!hideYAxis && (
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={valueFormatter}
            domain={dataRange}
            ticks={yTicks}
            minTickGap={0}
            tick={CustomYAxisTick}
          />
        )}
        {showGrid && (
          <CartesianGrid
            vertical={false}
            stroke={theme.colors.borderSeparator.default}
            horizontalCoordinatesGenerator={
              yTicks
                ? (props) => {
                    const height = props.height - props.offset.bottom - props.offset.top;
                    const max = dataRange?.[1] ?? 100;
                    return yTicks.map((tick) => height - (tick / max) * height + props.offset.top);
                  }
                : undefined
            }
          />
        )}
        {allDataKeys.map((dk, index) => (
          <Bar
            key={dk}
            dataKey={dk}
            name={barNames?.[index]}
            onMouseOver={(payload, _, event) => {
              tooltipRef.current = dk;
              if (payload.hoverFillColor) {
                (event.target as HTMLElement).style.fill = payload.hoverFillColor;
              }
            }}
            onMouseOut={(payload, _, event) => {
              if (payload.hoverFillColor) {
                (event.target as HTMLElement).style.fill = payload.fill;
              }
            }}
            shape={CustomBar}
            fill={theme.colors.brand.primary}
            barSize={barSize ?? DEFAULT_BAR_SIZE}
            radius={RADIUS_BAR}
            background={!noBackground && { fill: `${theme.colors.backgroundBasic.default}`, radius: RADIUS_BAR }}
            onClick={onClick}
            className={onClick ? 'clickable' : undefined}
          >
            {topLabel && (
              <LabelList
                position="top"
                dataKey={valueAccessor ? undefined : dk}
                formatter={valueFormatter ? (value, index) => valueFormatter(value, index, dk) : undefined}
                valueAccessor={valueAccessor ? (data) => valueAccessor(data, dk) : undefined}
                fill={theme.colors.text.primary}
              />
            )}
          </Bar>
        ))}
        {tooltipContent && (
          <ChartTooltip
            cursor={false}
            content={(props) => {
              const payload = props.payload?.[0]?.payload;
              return payload ? <CustomTooltip>{tooltipContent(payload, tooltipRef.current)}</CustomTooltip> : <></>;
            }}
          />
        )}

        {allDataKeys.length > 1 && (
          <Legend
            wrapperStyle={{ padding: 12, paddingLeft: 80 }}
            align={legendAllignment ?? 'center'}
            payload={
              dataKeys &&
              barNames &&
              barColors &&
              allDataKeys.map((item, index) => ({
                id: dataKeys[index],
                type: 'square',
                value: barNames[index],
                color: barColors[dataKeys[index]],
              }))
            }
          />
        )}
      </RechartsBarChart>
    </ResponsiveContainer>
  );
};

const CustomTooltip = styled.div({
  color: theme.colors.textInverted.primary,
  backgroundColor: theme.colors.backgroundDark.default,
  padding: `${theme.spacing.c} ${theme.spacing.e}`,
  borderRadius: 4,
  fontWeight: 300,
});
