import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import { createClient } from 'graphql-ws';
import { captureException, captureMessage, Severity } from '@sentry/browser';
import fetch from 'unfetch';
import { getMainDefinition } from '@apollo/client/utilities';
import { getFrontendConfigValue } from '@arnold/common';
import auth from './lib/auth';
import ee from './lib/eventEmitter';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (
    graphQLErrors &&
    // @ts-ignore
    graphQLErrors.some((error) => !error.info?.supressErrorModal)
  ) {
    ee.emitModalGraphQlError(graphQLErrors[0]);
    graphQLErrors.forEach((error) => {
      // this is in fact NOT an error object despite ts type so use captureMessage instead
      captureMessage(`GraphQL error: ${error.message}`, Severity.Error);
      // tslint:disable-next-line no-console
      console.error('[GraphQL error]:', error);
    });
  }
  if (networkError) {
    // tslint:disable-next-line no-console
    captureException(networkError);
    console.error('[Network error]:', networkError);
  }
});

const fetchWithAuth = async (uri: string, options: any) => {
  const user = auth.getUser();
  if (user != null) {
    options.headers.Authorization = `Bearer ${user.accessToken}`;
  }
  return fetch(uri, options);
};

const GRAPHQL_URI = getFrontendConfigValue('API_URL') + '/graphql';

const uploadLink = createUploadLink({
  uri: GRAPHQL_URI,
  fetch: fetchWithAuth as any,
  fetchOptions: {
    mode: 'cors',
  },
});

const getCorrectProtocol = () => (window.location.protocol === 'http:' ? 'ws' : 'wss');

const getWsUri = (path: string) => `${getCorrectProtocol()}://${getFrontendConfigValue('API_HOST')}/${path}`;

const wsLink = new GraphQLWsLink(
  createClient({
    url: getWsUri('graphql'),
  }),
);

const requestLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([requestLink, errorLink, uploadLink]),
});

export default client;
