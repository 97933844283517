import { BodyMedium, theme } from '@arnold/common';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PATHS } from '../../screens/Surveys/types';
import { NoSurveysInfoBase } from './NoSurveysInfoBase';

type Props = {
  status: PATHS;
  isWithoutSurveys?: boolean;
  dataCy?: string;
};

export const NoSurveysInfo: FC<Props> = ({ status, isWithoutSurveys, dataCy }) => {
  const [t] = useTranslation('surveysOverview');

  const title =
    isWithoutSurveys && status === PATHS.PREPARED
      ? t('beforeFirstSurvey')
      : t(`emptySurveyTitle-${status ? status : PATHS.ONGOING}`);

  const text =
    status === PATHS.PREPARED ? (
      <Trans
        i18nKey={`surveysOverview:${isWithoutSurveys ? 'beforeFirstSurveyDescription' : 'noPlannedSurveysDescription'}`}
        components={[
          <a key="0" href={t('publicSurveyLink')} target="_blank" rel="noreferrer" />,
          <a key="1" href={t('exampleReportLink')} target="_blank" rel="noreferrer" />,
        ]}
      >
        Nový průzkum spustíš tlačítkem Naplánovat. Dál si vybereš téma a nastavíš si detaily průzkumu....
      </Trans>
    ) : (
      <BodyMedium>{t(`emptySurveyDescription-${status ? status : PATHS.ONGOING}`)}</BodyMedium>
    );

  const showPlanSurveyButton = !status || status === PATHS.ONGOING || (status === PATHS.PREPARED && !isWithoutSurveys);
  const showAddEmployeesButton = (status === PATHS.PREPARED && isWithoutSurveys) || status === PATHS.ONGOING;

  const buttonText = showPlanSurveyButton
    ? t('planSurveyButton')
    : showAddEmployeesButton
      ? t('addEmployees')
      : undefined;
  const navigateTo = showPlanSurveyButton ? '/conversationSubjects' : showAddEmployeesButton ? '/teams' : undefined;

  return (
    <div style={{ marginTop: theme.spacing.a }}>
      <NoSurveysInfoBase title={title} text={text} navigateTo={navigateTo} buttonText={buttonText} dataCy={dataCy} />
    </div>
  );
};
