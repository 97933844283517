import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { HeadingSmall } from '@arnold/common';
import BaseModal, { WIDTH } from '../BaseModal';
import { SurveyGroupsQuery } from '../../../generated/hooks';
import { Table } from '../../Table';
import { BaseRespondent, RespondentSurveyGroups } from '../../OrganizationImportCard';
import { ProcessSurveyData, Step } from './types';
import { getProcessSurveyColumns, getProcessSurveyDataSource } from './utils';
import { Progress } from './Progress';
import { Line, TableContainer } from './styled';
import { AddedEmployees } from './AddedEmployees';

interface IProps {
  cancel: () => void;
  processSurveys: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  employees: BaseRespondent[];
  onChange: (data: RespondentSurveyGroups) => void;
  save: () => void;
  steps: Step[];
}

function ChooseProcessSurveyModal({ cancel, processSurveys, employees, onChange, save, steps }: IProps) {
  const [t] = useTranslation('modal');
  const [importOrganizationT] = useTranslation('organizationImportCard');
  const [processSurveysSettings, setProcessSurveysSettings] = useState<RespondentSurveyGroups>({});
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handeDataChange = (data: RespondentSurveyGroups) => {
    onChange(data);
    setProcessSurveysSettings(data);
  };

  useEffect(() => {
    handeDataChange(processSurveys.reduce((object, processSurvey) => ({ ...object, [processSurvey.id]: [] }), {}));
    setIsDisabled(
      Object.values(processSurveysSettings).some((settings) => {
        return Object.values(settings).some((setting) => !setting);
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabled(
      Object.values(processSurveysSettings).some((settings) => {
        return Object.values(settings).some((setting) => !setting);
      }),
    );
  }, [processSurveysSettings]);

  const handleToggle = (email: string, id: string, checked: boolean, addedOn?: string) => {
    if (checked) {
      const processSurveyEmployees = { ...processSurveysSettings[id], [email]: addedOn };
      handeDataChange({ ...processSurveysSettings, [id]: processSurveyEmployees });
    } else {
      const { [email]: removed, ...rest } = processSurveysSettings[id];
      handeDataChange({ ...processSurveysSettings, [id]: rest });
    }
  };

  return (
    <BaseModal
      title={
        <>
          {importOrganizationT('addToProcessSurveyTitle')} <Progress steps={steps} step={'process-survey'} />
        </>
      }
      description={importOrganizationT('addToProcessSurveyDescription')}
      primaryActionText={
        steps.indexOf('process-survey') === steps.length - 1 ? t('save') : importOrganizationT('continueButton')
      }
      onPrimaryAction={save}
      secondaryActionText={t('cancel')}
      onSecondaryAction={cancel}
      width={WIDTH.AUTO}
      styles={{
        maxWidth: '100%',
        overflow: 'auto',
      }}
      primaryActionDisabled={isDisabled}
    >
      <AddedEmployees count={employees.length} />
      <Line />
      <HeadingSmall>{importOrganizationT('selectSurvey')}</HeadingSmall>
      <TableContainer>
        <Table<ProcessSurveyData>
          columns={getProcessSurveyColumns(
            importOrganizationT,
            processSurveys,
            handeDataChange,
            processSurveysSettings,
            employees,
          )}
          dataSources={getProcessSurveyDataSource(processSurveys, employees, processSurveysSettings, handleToggle)}
          selected={undefined}
        />
      </TableContainer>
    </BaseModal>
  );
}
export default ChooseProcessSurveyModal;
