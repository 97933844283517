import { Form, Formik } from 'formik';
import { Button, Col, FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Option } from '@arnold/common';
import * as Yup from 'yup';
import { FormErrorFeedback, FormGroupLabel } from '../../Common';
import { OrganizationTeamsQuery, useCreateTeamMutation } from '../../../generated/hooks';
import { getPossibleTeamLeaders } from '../../../lib/helpers/teams';
import { EmployeeField } from '../../Forms/RespondentForm/EmployeeField';

type Props = {
  goBack: () => void;
  onChooseTeam: (teamId: string) => void;
  organizationTeams: OrganizationTeamsQuery['organizationTeams'];
};

type FormikValue = {
  teamName: string;
  memberId: string | null;
};

export function ChooseExistingEmployee({ goBack, onChooseTeam, organizationTeams }: Props) {
  const { t } = useTranslation('newTeamModal');
  const [createTeam] = useCreateTeamMutation();
  const leaderSchema = Yup.object().shape({
    teamName: Yup.string().required(t('enterTeamName')),
    memberId: Yup.string().required(t('enterTeamleader')),
  });

  const members = getPossibleTeamLeaders(organizationTeams);

  const handleSubmit = (values: FormikValue) => {
    const { teamName, memberId } = values;
    const member = members.find((team) => team.id === memberId);
    createTeam({
      variables: {
        teamName,
        respondentId: member?.id!,
      },
      onCompleted(data) {
        onChooseTeam(data.createTeam.id);
      },
    });
  };

  return (
    <Formik<FormikValue>
      onSubmit={handleSubmit}
      validationSchema={leaderSchema}
      initialValues={{
        teamName: '',
        memberId: '',
      }}
    >
      {({ handleBlur, handleChange, values, touched, errors, setFieldValue }) => {
        const handleChangeTeamLeader = (opt: Option) => {
          const foundMember = members.find((member) => member.id === opt.value);
          if (foundMember) {
            setFieldValue('teamName', foundMember.firstname + ' ' + foundMember.surname);
          }
        };

        return (
          <Form>
            <EmployeeField
              id="new-team-modal-team-leader"
              members={members}
              handleBlur={handleBlur}
              onChange={handleChangeTeamLeader}
              error={errors.memberId}
              className="pl-2"
            />
            <FormGroup as={Col} className="pl-0">
              <FormGroupLabel>{t('teamName')}</FormGroupLabel>
              <FormControl
                type="text"
                name="teamName"
                value={values.teamName || ''}
                onBlur={handleBlur}
                onChange={handleChange}
                isInvalid={touched.teamName && !!errors.teamName}
              />
              <FormErrorFeedback error={errors.teamName} />
            </FormGroup>
            <div>
              <Button color="primary" type="submit" data-cy="new-team-modal-create">
                {t('create')}
              </Button>
              <Button color="primary" variant="outline-primary" onClick={goBack}>
                {t('back')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
