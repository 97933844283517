import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { theme } from '../../theme';

type CardButtonProps = {
  image: ReactNode;
  title: string;
  onClick: () => void;
};

const CardButtonStyled = styled.button`
  background-color: ${theme.background.primary};
  border: ${theme.spacing.a} solid ${theme.colors.borderMain.default};
  border-radius: 6px;
  height: 146px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: ${theme.spacing.h};
  flex: 1 1 0;

  &:hover,
  &:focus {
    background: ${theme.colors.iconAction.primary};
    & svg path {
      fill: ${theme.colors.textInverted.primary};
      color: ${theme.colors.textInverted.primary};
    }

    div {
      color: ${theme.colors.textInverted.primary};
    }
  }
}
`;

const CardButtonTitle = styled.div`
  font-size: 15px;
  line-height: 145%;
  text-align: center;
  letter-spacing: 0.05em;
  color: ${theme.colors.actionPrimary.default};
  margin-top: ${theme.spacing.f};
`;

export const CardButton = ({ image, title, onClick }: CardButtonProps) => {
  return (
    <CardButtonStyled onClick={onClick}>
      <div>{image}</div>
      <CardButtonTitle>{title}</CardButtonTitle>
    </CardButtonStyled>
  );
};

export default CardButton;
