import { SurveyGroupsQuery } from '../../generated/hooks';
import i18n from '../../translations/i18n';

export const getTGTranslation = (
  translations?: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]['topicGroup']['translations'],
  primaryLanguage?: string,
) => {
  return (
    translations?.find((translation) => translation.language.code === i18n.language) ||
    translations?.find((translation) => translation.language.code === primaryLanguage) ||
    (translations && translations.length > 0 ? translations[0] : undefined)
  );
};

export const getShortenedTitle = (title: string) => {
  if (title.length > 23) {
    return `${title.slice(0, 23)}...`;
  }

  return title;
};
