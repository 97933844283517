import { theme } from '@arnold/common';
import * as R from 'ramda';
import { FC } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { isSurveyPlanned } from '../../../lib/helpers';
import { UserQuery, SurveyStatus, ProcessStatus, OrganizationQuery } from '../../../generated/hooks';
import { WarningMessage } from './WarningMessage';

type SurveyInfoProps = {
  isSequential: boolean;
  notstartedReason?: NonNullable<NonNullable<UserQuery['user']['organization']>['surveys'][0]>['notstartedReason'][];
  inLicenceInterval: boolean;
  organization: UserQuery['user']['organization'] | OrganizationQuery['organization'];
  conversationLanguages?: Array<{
    code: string;
    id: string;
  }>;
  status: SurveyStatus | ProcessStatus;
};

type GetActiveMessagesParams = Pick<
  SurveyInfoProps,
  'isSequential' | 'notstartedReason' | 'inLicenceInterval' | 'organization'
>;

export const getActiveMessages = (
  { isSequential, notstartedReason, inLicenceInterval, organization }: GetActiveMessagesParams,
  t: TFunction,
): string[] => {
  const notStartedMessage = isSequential ? 'notStartedSequenceSurveyReason' : 'notStartedReason';
  if (notstartedReason?.length) {
    return notstartedReason && notstartedReason.length > 0
      ? notstartedReason.map((reason) => t(notStartedMessage, { reason: t(`reason-${reason}`) }))
      : [t('notStartedWarning')];
  } else {
    return !inLicenceInterval
      ? [
          t('surveysNotInValidPeriod_' + organization?.productVersion?.product, {
            defaultValue: t('surveysNotInValidPeriod'),
          }),
        ]
      : [];
  }
};

type GetLanguageMessageParams = Pick<SurveyInfoProps, 'conversationLanguages' | 'organization'>;
const getLanguageMessage = (
  { conversationLanguages, organization }: GetLanguageMessageParams,
  surveyOverviewRowT: TFunction,
  languageSelectorT: TFunction,
): string | null => {
  const isSubset = R.curry((subset, set) => R.equals(R.intersection(subset, set), subset));

  return !conversationLanguages ||
    isSubset(
      R.uniq([
        organization?.primaryLanguageId.toString(),
        ...(organization?.languages || []).map((language) => language.id),
      ]).map((language) => language?.toString()),
      conversationLanguages.map((language) => language.id),
    )
    ? null
    : surveyOverviewRowT('languageWarning') + conversationLanguages.map((l) => languageSelectorT(l.code)).join(', ');
};

export const SurveyInfo: FC<SurveyInfoProps> = ({
  isSequential,
  notstartedReason,
  inLicenceInterval,
  organization,
  status,
  conversationLanguages,
}) => {
  const [surveyOverviewRowT] = useTranslation('surveyOverviewRow');
  const [languageSelectorT] = useTranslation('languageSelector');

  const activeMessages = getActiveMessages(
    {
      isSequential,
      notstartedReason,
      inLicenceInterval,
      organization,
    },
    surveyOverviewRowT,
  );

  const languageMessage = getLanguageMessage(
    { conversationLanguages, organization },
    surveyOverviewRowT,
    languageSelectorT,
  );
  return (
    <>
      {(isSurveyPlanned(status) || isSequential) && (activeMessages.length > 0 || languageMessage) && (
        <WarningMessage
          title={[...activeMessages, languageMessage].filter((t) => !!t).join('<hr />')}
          color={theme.colors.chart.warning[0]}
        />
      )}
    </>
  );
};
