import { EmptyProps, usePath } from '@arnold/common';
import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRouter from './components/PrivateRouter';
import {
  AddRespondentScreen,
  ChangePasswordScreen,
  ChooseProcessSurveyScreen,
  ConfirmEmailScreen,
  CreateOnboardingScreen,
  CreateExitScreen,
  CreateProcessSurveyScreen,
  CreateSurveyGroupScreen,
  CreateSurveyScreen,
  LoginScreen,
  ProcessSurveyStepsEditScreen,
  OrderEditionScreen,
  OrganizationsScreen,
  OverviewScreen,
  PricingScreen,
  ProcessSurveyDetailScreen,
  ProcessSurveyTopicDetailScreen,
  ProcessSurveysScreen,
  RecoverPasswordScreen,
  RegistrationScreen,
  ReportAccessScreen,
  ReportScreen,
  ResultsScreen,
  SalesScreen,
  SettingsScreen,
  SurveyArchiveScreen,
  SurveysScreen,
  SvenEditorScreen,
  SvenPreviewScreen,
  TeamsScreen,
  UpdateRespondentScreen,
  UpdateTeamScreen,
  WebOrderModal,
  ChooseProcessTopicGroupScreen,
} from './screens';

// @todo - import me via index from ./screens
import AddRespondentFromTeamioScreen from './screens/AddRespondentFromTeamio/AddRespondentFromTeamioScreen';
import ConversationSubjectsScreen from './screens/ConversationSubjects/ConversationSubjectsScreen';
import EditSurveyGroupScreen from './screens/EditSurveyGroup/EditSurveyGroupScreen';
import { OnboardingDetailScreen } from './screens/OnboardingDetail/OnboardingDetailScreen';
import { OnboardingTopicDetailScreen } from './screens/OnboardingTopicDetail/OnboardingTopicDetailScreen';
import { PreviewFormScreen } from './screens/PreviewForm/PreviewFormScreen';
import AddTopicScreen from './screens/Process/AddTopicScreen';
import ProcessScreen from './screens/Process/ProcessScreen';
import ProcessAddStepScreen from './screens/ProcessStepDetail/ProcessAddStepScreen';
import ProcessStepDetailScreen from './screens/ProcessStepDetail/ProcessStepDetailScreen';
import { ReopenSurveyScreen } from './screens/SurveyDetail/ReopenSurveyScreen';
import { SurveyDetailScreen } from './screens/SurveyDetail/SurveyDetailScreen';
import { SvenEditorPublicScreen } from './screens/SvenEditorPublic';
import TopicScreen from './screens/Topic/TopicScreen';
import TopicsScreen from './screens/Topics/TopicsScreen';
import { ExitDetailScreen } from './screens/ExitDetail/ExitDetailScreen';

export const Routes: FC<EmptyProps> = () => {
  const { processStepEditPath } = usePath();

  return (
    <Switch>
      <Route path="/login/:lang?" component={LoginScreen} />
      <Route path="/recoverPassword/:lang?" component={RecoverPasswordScreen} />
      <Route path="/changePassword/:accessToken/:lang?" component={ChangePasswordScreen} />
      <Route path="/register/confirm/:accessToken/:lang?" component={ConfirmEmailScreen} />
      <Route path="/register/:lang?" component={RegistrationScreen} />
      <Route path="/order/:lang?" component={WebOrderModal} />
      <Route path="/sales" component={SalesScreen} />
      <Route path="/preview/:lang?" component={PreviewFormScreen} />
      <Route path="/svenEditorPublic/:topicGroupId/:lang?" component={SvenEditorPublicScreen} />
      <Route path="/svenPreviewPublic/:topicGroupId/:lang?" component={SvenEditorPublicScreen} />
      <PrivateRouter
        // this should be optimized, path should know his own parameters name
        path={processStepEditPath.createPath(':processTopicGroupId')}
        component={ProcessSurveyStepsEditScreen}
      />
      <PrivateRouter path="/overview/:surveyGroupId?" component={OverviewScreen} />
      <PrivateRouter path="/surveys/:status?" component={SurveysScreen} />
      <PrivateRouter path="/teams" component={TeamsScreen} />
      <PrivateRouter path="/updateRespondent" component={UpdateRespondentScreen} />
      <PrivateRouter path="/addRespondent" component={AddRespondentScreen} />
      <PrivateRouter
        path="/addRespondentFromTeamio"
        component={AddRespondentFromTeamioScreen}
        leftNavbarOnly
        hideSidebar
        disableSavingAccessToken
      />
      <PrivateRouter path="/updateTeam" component={UpdateTeamScreen} />
      <PrivateRouter path="/settings/pricing" component={PricingScreen} />
      <PrivateRouter path="/settings/order/:edition?" component={OrderEditionScreen} />
      <PrivateRouter path="/settings" component={SettingsScreen} />
      <PrivateRouter path="/report" component={ReportScreen} />
      <PrivateRouter path="/reportAccess/:surveyId" component={ReportAccessScreen} />
      <PrivateRouter path="/createSurvey/:subjectType/:subjectId/:subjectLabel" component={CreateSurveyScreen} />
      <PrivateRouter path="/createSurveyGroup/:subjectId" component={CreateSurveyGroupScreen} />
      <PrivateRouter path="/organizations" component={OrganizationsScreen} />
      <PrivateRouter path="/editSurveyGroup/:id" component={EditSurveyGroupScreen} />
      <PrivateRouter path="/topic/:id/:language?" component={TopicScreen} />
      <PrivateRouter path="/process/:topicGroupId/step/add/:topicId/:language?" component={ProcessAddStepScreen} />
      <PrivateRouter path="/process/:topicGroupId/step/add" component={AddTopicScreen} />
      <PrivateRouter path="/process/:topicGroupId/step/:stepId/:language?" component={ProcessStepDetailScreen} />
      <PrivateRouter path="/process/:topicGroupId" component={ProcessScreen} />
      <PrivateRouter path="/conversationSubjects" component={ConversationSubjectsScreen} exact={true} />
      <PrivateRouter path="/topics" component={TopicsScreen} />
      <PrivateRouter path="/surveyDetail/:surveyId" component={SurveyDetailScreen} exact={true} />
      <PrivateRouter path="/reopenSurvey/:surveyId" component={ReopenSurveyScreen} exact={true} />
      <PrivateRouter path="/surveyDetail/:surveyId/:type" component={SurveyDetailScreen} />
      <PrivateRouter path="/onboarding/stepDetail/:surveyGroupId/:step" component={OnboardingTopicDetailScreen} />
      <PrivateRouter path="/onboarding/:surveyGroupId?/:type?" component={OnboardingDetailScreen} />
      <PrivateRouter path="/processSurvey/stepDetail/:surveyGroupId/:step" component={ProcessSurveyTopicDetailScreen} />
      <PrivateRouter path="/processSurvey/:surveyGroupId/:type?" component={ProcessSurveyDetailScreen} />
      <PrivateRouter path="/processSurveyConversationSubjects" component={ChooseProcessSurveyScreen} />
      <PrivateRouter path="/createOnboarding/:subjectId" component={CreateOnboardingScreen} />
      <PrivateRouter path="/createProcessSurvey/:subjectId" component={CreateProcessSurveyScreen} />
      <PrivateRouter path="/createExit/:subjectId" component={CreateExitScreen} />
      <PrivateRouter path="/processSurveys" component={ProcessSurveysScreen} />
      <PrivateRouter path="/exit/:surveyGroupId?/:type?" component={ExitDetailScreen} />
      <PrivateRouter path="/conversationSubjects/:type?" component={ChooseProcessTopicGroupScreen} />
      <PrivateRouter path="/results" component={ResultsScreen} />
      <PrivateRouter path="/archivedSurveyDetail/:surveyId" component={SurveyDetailScreen} exact={true} />
      <PrivateRouter path="/archivedOnboarding/:surveyGroupId" component={OnboardingDetailScreen} exact={true} />
      <PrivateRouter path="/archivedProcessSurvey/:surveyGroupId" component={ProcessSurveyDetailScreen} exact={true} />
      <PrivateRouter path="/surveyArchive" component={SurveyArchiveScreen} />
      <PrivateRouter
        path="/topicEditorPreview/:topicGroupId/:languageId?"
        component={SvenPreviewScreen}
        exact={true}
        leftNavbarOnly
      />
      <PrivateRouter
        path="/topicEditor/:topicGroupId/:languageId?"
        component={SvenEditorScreen}
        exact={true}
        leftNavbarOnly
      />
      <Redirect from="/" to="overview" />
    </Switch>
  );
};
