import { FetchResult } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import { listTimeZones } from 'timezone-support';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BodyMedium,
  BodySmall,
  CardBody,
  CardTitle,
  HeadingSmall,
  selectMenuStyles,
  SelectWrapper,
  theme,
} from '@arnold/common';
import { Button, Card, FormControl, FormGroup, Col, Row } from 'react-bootstrap';
import { clone } from 'ramda';
import { updateOrganizationConfigMutation } from '../graphql/mutations';
import { getOrganizationSettings } from '../graphql/queries';
import { ModalType } from '../lib/enums';
import ee from '../lib/eventEmitter';
import { PickerChoice } from '../lib/types';
import { EDITION, sortLanguages } from '../lib/common';
import {
  OrganizationQuery,
  OrganizationSettingQuery,
  UpdateOrganizationConfigMutation,
  UpdateOrganizationConfigMutationVariables,
  useLanguagesLazyQuery,
} from '../generated/hooks';

interface IProps {
  language: string;
  languages: NonNullable<OrganizationQuery['organization']>['languages'];
  timezone: string;
  countryCallingCode: string;
  product?: string;
  organizationId: string;
}

function getPickerChoice(value: string, label: string): PickerChoice {
  return {
    value,
    label,
  };
}

const LanguageAndLocalizationCard = (props: IProps) => {
  const [t] = useTranslation('settings');

  const [language, setLanguage] = useState<PickerChoice>(
    getPickerChoice(props.language, t(`languageSelector:${props.language}`)),
  );
  const [languageOptions, setLanguageOptions] = useState<PickerChoice[] | null>(null);
  const [timezone, setTimezone] = useState<PickerChoice>(getPickerChoice(props.timezone, props.timezone));
  const [countryCallingCode, setCountryCallingCode] = useState(props.countryCallingCode);

  const [getProductLanguages] = useLanguagesLazyQuery({
    variables: {
      filter: {
        product: props.product,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setLanguageOptions(
        sortLanguages(
          data.languages,
          new Map(data.languages.map((l) => [l.code, t(`languageSelector:${l.code}`)])),
        ).map((language) => getPickerChoice(language!.code, t(`languageSelector:${language!.code}`))),
      );
    },
  });

  useEffect(() => {
    if (props.product === EDITION.ENTERPRISE.name) {
      setLanguageOptions(
        sortLanguages(
          props.languages,
          new Map(props.languages.map((l) => [l.code, t(`languageSelector:${l.code}`)])),
        ).map((language) => getPickerChoice(language!.code, t(`languageSelector:${language!.code}`))),
      );
    } else {
      getProductLanguages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSetLanguage = (choice: PickerChoice) => setLanguage(choice);
  const handleSetTimezone = (choice: PickerChoice) => setTimezone(choice);
  const handleMutationCompleted = () => {
    ee.emitModal(ModalType.SUCCESS, 'saveOrgTitle', 'saveOrgText');
  };
  const updateCache = (
    cache: any,
    update: FetchResult<UpdateOrganizationConfigMutation, Record<string, any>, Record<string, any>>,
  ) => {
    const cacheData: OrganizationSettingQuery | null = clone(
      cache.readQuery({
        query: getOrganizationSettings,
        variables: { organizationId: props.organizationId },
      }),
    );
    const data = update.data;
    if (cacheData && cacheData.organization && cacheData.organization.config && data) {
      cacheData.organization.config.countryCallingCode = data.updateOrganizationConfig.countryCallingCode;
      cacheData.organization.config.timezone = data.updateOrganizationConfig.timezone;
      cacheData.organization.primaryLanguageCode = data.updateOrganizationConfig.organization.primaryLanguageCode;
      cache.writeQuery({
        query: getOrganizationSettings,
        variables: { organizationId: props.organizationId },
        data: { organization: cacheData.organization },
      });
    }
  };

  const timezoneOptions = listTimeZones().map((timezone) => getPickerChoice(timezone, timezone));

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <HeadingSmall>{t('primaryLanguageTitle')}</HeadingSmall>
          <BodySmall margin={`${theme.spacing.c} 0 ${theme.spacing.f} 0`}>{t('primaryLanguageDescription')}</BodySmall>
        </CardTitle>
        <FormGroup>
          <Row>
            <Col xs={12} md={6}>
              <SelectWrapper
                value={language}
                options={languageOptions}
                onChange={handleSetLanguage}
                styles={{
                  menu: (provided: any, state: any) => ({
                    ...provided,
                    ...selectMenuStyles,
                  }),
                }}
                menuPlacement="auto"
              />
            </Col>
          </Row>
        </FormGroup>
        <CardTitle>
          <HeadingSmall>{t('allLanguageTitle')}</HeadingSmall>
          <BodySmall margin={`${theme.spacing.c} 0 0 0`}>{t('allLanguageDescription')}</BodySmall>

          <BodyMedium margin={`${theme.spacing.f} 0 ${theme.spacing.f} 0`}>
            {props.languages
              .map((lang) => `${lang.code}-${t('languageSelector:' + lang.code).toLowerCase()}`)
              .join(', ')}
          </BodyMedium>
        </CardTitle>
        <CardTitle>
          <HeadingSmall>{t('timezone')}</HeadingSmall>
          <BodySmall margin={`${theme.spacing.c} 0 ${theme.spacing.f} 0`}>{t('timezoneDescription')}</BodySmall>
        </CardTitle>
        <FormGroup>
          <Row>
            <Col sm={6}>
              <SelectWrapper
                value={timezone}
                options={timezoneOptions}
                onChange={handleSetTimezone}
                styles={{
                  menu: (provided: any, state: any) => ({
                    ...provided,
                    ...selectMenuStyles,
                  }),
                }}
                menuPlacement="auto"
              />
            </Col>
          </Row>
        </FormGroup>
        <CardTitle>
          <HeadingSmall>{t('countryCallingCodeTitle')}</HeadingSmall>
          <BodySmall margin={`${theme.spacing.c} 0 ${theme.spacing.f} 0`}>
            {t('countryCallingCodeDescription')}
          </BodySmall>
        </CardTitle>
        <FormGroup>
          <Row>
            <Col sm={6}>
              <FormControl
                type="number"
                name="countryCallingCode"
                required
                value={countryCallingCode}
                onChange={(event) => setCountryCallingCode(event?.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>
        <Mutation<UpdateOrganizationConfigMutation, UpdateOrganizationConfigMutationVariables>
          mutation={updateOrganizationConfigMutation}
          onCompleted={handleMutationCompleted}
          update={updateCache}
          variables={{
            input: {
              organizationId: props.organizationId,
              primaryLanguage: language.value,
              timezone: timezone.value,
              countryCallingCode,
            },
          }}
        >
          {(saveOrganisation: () => void) => (
            <Button variant="info" style={{ height: 45, margin: '10px 0px' }} onClick={saveOrganisation}>
              {t('save')}
            </Button>
          )}
        </Mutation>
      </CardBody>
    </Card>
  );
};

export default LanguageAndLocalizationCard;
