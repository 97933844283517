import { Box, BoxWithLabel, BreadcrumbItem } from '@arnold/common';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Loading } from '..';
import {
  OrganizationQuery,
  ProcessStatus,
  SurveyGroupWithGroupedRespondentsQuery,
  SurveyGroupsQuery,
  UserQuery,
  useActiveRespondentsQuery,
} from '../../generated/hooks';
import { PageLayout } from '../Common/PageLayout';
import CreateWithRespondents from './CreateWithRespondents';
import NewSubGroup from './NewSubGroup';
import RespondentGroup from './RespondentGroup';

type SurveyGroupProps = {
  headings?: string;
  description?: string | null;
  breadcrumb?: BreadcrumbItem[];
  respondentGroups?: NonNullable<SurveyGroupWithGroupedRespondentsQuery['surveyGroup']>['groupedRespondents'];
  organization: UserQuery['user']['organization'] | OrganizationQuery['organization'] | null;
  addRespondents: (respondentIds: string[], addedOn: Date) => Promise<void>;
  removeRespondent: (respondentIds?: string[]) => Promise<void>;
  changeAddedOn: (oldAddedOn: Date, newAddedOn: Date) => Promise<void>;
  createSubGroup: (
    respondentIds: string[],
    newAddedOn: Date,
    runOngoingSteps: string[],
    newRecipientEmails?: string[],
  ) => Promise<boolean>;
  displayPlanButton?: boolean;
  isPlanButtonDisabled?: boolean;
  isEdit?: boolean;
  handlePlanClick?: () => void;
  user: UserQuery['user'];
  topicGroup: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]['topicGroup'];
  status?: ProcessStatus;
  loading?: boolean;
};

const SurveyGroup = ({
  headings,
  description,
  breadcrumb,
  respondentGroups,
  organization,
  addRespondents,
  createSubGroup,
  topicGroup,
  removeRespondent,
  changeAddedOn,
  displayPlanButton,
  isPlanButtonDisabled,
  handlePlanClick,
  isEdit,
  user,
  status,
  loading,
}: SurveyGroupProps) => {
  const { t } = useTranslation('surveyGroup');
  const { loading: respondentsLoading, data: respondentsData } = useActiveRespondentsQuery({ fetchPolicy: 'no-cache' });
  const [newSelectedRespondents, setNewSelectedRespondents] = useState<string[]>([]);

  if (respondentsLoading) {
    return <Loading />;
  }

  const showNewRespondentsForm =
    !isEdit &&
    respondentsData &&
    respondentsData.user.organization &&
    respondentsData.user.organization.enabledRespondentsCount <= 1;

  const selectedRespondents = respondentGroups
    ? respondentGroups.reduce((acc: string[], group) => {
        return [
          ...acc,
          ...group.respondents
            .filter((respondent) => !group.deletedRespondents?.includes(respondent.id))
            .map((r) => r.id),
        ];
      }, [])
    : [];

  const deletedRespondents = respondentGroups
    ? respondentGroups.reduce((acc: string[], group) => {
        return [
          ...acc,
          ...group.respondents
            .filter((respondent) => group.deletedRespondents?.includes(respondent.id))
            .map((r) => r.id),
        ];
      }, [])
    : [];
  return (
    <PageLayout breadcrumbItems={breadcrumb} title={headings} description={description}>
      <BoxWithLabel
        headline={t('respondents')}
        description={t(showNewRespondentsForm ? 'newRespondentsInfo' : 'respondentsInfo')}
        className="mb-3 mt-8"
      />
      {!showNewRespondentsForm && status !== ProcessStatus.Archived && (
        <NewSubGroup
          topicGroup={topicGroup}
          alreadySelectedRespondents={selectedRespondents}
          selectedRespondents={newSelectedRespondents}
          setSelectedRespondents={setNewSelectedRespondents}
          activeRespondents={
            respondentsData && respondentsData.user.organization
              ? respondentsData.user.organization.activeRespondents
              : []
          }
          addRespondents={createSubGroup}
          user={user}
          organization={organization}
          hasAnotherRespondentGroup={!!respondentGroups?.length}
          teams={respondentsData?.user.organization?.teams}
          respondentGroups={respondentGroups}
        />
      )}
      <>
        {respondentGroups &&
          respondentGroups.map((group) => {
            const groupAddedOn = new Date(group.addedOn);

            const notDeletedRespondents = group.respondents.filter(
              (respondent) => !group.deletedRespondents?.includes(respondent.id),
            );

            if (!notDeletedRespondents.length) {
              return undefined;
            }
            return (
              <RespondentGroup
                key={group.addedOn.toString()}
                group={group}
                topicGroup={topicGroup}
                addedOn={new Date(group.addedOn)}
                removeRespondent={removeRespondent}
                alreadySelectedRespondents={[...selectedRespondents, ...newSelectedRespondents].filter(
                  (rid) => !group.respondents.map((r) => r.id).includes(rid),
                )}
                onSubmit={(newDate) => changeAddedOn(groupAddedOn, newDate)}
                activeRespondents={
                  respondentsData && respondentsData.user.organization
                    ? respondentsData.user.organization.activeRespondents
                    : []
                }
                teams={respondentsData?.user.organization?.teams}
                addRespondents={(respondents) =>
                  respondents.length > notDeletedRespondents.length
                    ? addRespondents(
                        respondents
                          .filter((r) => !notDeletedRespondents.map((gr) => gr.id).includes(r.id))
                          .map((r) => r.id),
                        groupAddedOn,
                      )
                    : removeRespondent(
                        notDeletedRespondents
                          .filter((gr) => !respondents.map((r) => r.id).includes(gr.id))
                          .map((r) => r.id),
                      )
                }
                submitButtonText={t('changeAddedOn')}
                status={status}
                loading={loading}
                deletedRespondents={deletedRespondents}
                respondentGroups={respondentGroups}
              />
            );
          })}
      </>
      {showNewRespondentsForm && status !== ProcessStatus.Archived && (
        <CreateWithRespondents organization={organization} addRespondents={createSubGroup} user={user} />
      )}

      {displayPlanButton && (
        <Box className="mb-8">
          <Button
            variant="primary"
            className={'mr-6 mb-5 btn-empty'}
            type="submit"
            disabled={isPlanButtonDisabled}
            onClick={handlePlanClick}
          >
            {t('planSurveyGroup')}
          </Button>
        </Box>
      )}
    </PageLayout>
  );
};

export default SurveyGroup;
