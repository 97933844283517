import { ReactNode } from 'react';
import { ReportAccessQuery, SequenceReportAccessesQuery } from '../../generated/hooks';

type Survey = NonNullable<ReportAccessQuery['survey']>;

export type ReportData = {
  id: NonNullable<Survey['reports']>[0]['id'];
  sendAt: Survey['sendAt'];
  reportLabel: ReactNode;
  actions: ReactNode;
  status?: string;
  reportOpened?: ReactNode | undefined;
  topicName: string;
  teams: string;
  recipients: ReactNode;
  responseRate?: string;
};

export enum SurveyTableItemType {
  Survey,
  Sequence,
}

export interface ISurveyTableItem {
  data: Survey;
  timestamp: any;
  type: SurveyTableItemType.Survey;
  isOngoing: boolean;
  reportBasePath: string;
}
export interface ISequenceTableItem {
  data: SequenceReportAccessesQuery['surveyGroup'];
  timestamp: any;
  type: SurveyTableItemType.Sequence;
  isOngoing: boolean;
  reportBasePath: string;
}
export type TableItem = ISurveyTableItem | ISequenceTableItem;
