import { cleanArray, TableColumn } from '@arnold/common';
import { TFunction } from 'react-i18next';
import { isSurveyPlanned } from '../../../../lib/helpers';
import { SurveyGroupQuery } from '../../../../generated/hooks';
import { ParticipantDetailData } from './types';

export const getColumns = (t: TFunction, isOnboarding?: boolean): Array<TableColumn<ParticipantDetailData>> =>
  cleanArray([
    {
      key: 'sentAt',
      title: t('sentAt'),
      dataIndex: 'sentAt',
    },
    {
      key: 'topicGroupName',
      title: t('topicGroupName'),
      dataIndex: 'topicGroupName',
    },
    !!isOnboarding && {
      key: 'feeling',
      title: t('feeling'),
      dataIndex: 'feeling',
    },
    {
      key: 'resend',
      title: '',
      dataIndex: 'resend',
    },
  ]);

export const showAnswers = (group: Partial<NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]>) => {
  return !(group?.surveys || []).every((survey) => isSurveyPlanned(survey.status));
};
