import { LinkButton, getFrontendConfigValue, usePath } from '@arnold/common';
import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import styled from '@emotion/styled';
import ee from '../../lib/eventEmitter';
import { RenameProcessSurveyForm } from '../RenameProcessSurveyForm';
import { Card } from '../Common';
import { PageLayout } from '../Common/PageLayout';
import { StepsEditTable } from '../StepsEditData';
import GraphQLErrorCard from '../GraphQLErrorCard';
import Loading from '../Loading';
import {
  ProcessStatus,
  TopicGroupTypeCode,
  useCreateSurveyGroupMutation,
  useTopicGroupQuery,
  useUserQuery,
} from '../../generated/hooks';
import { getOrganizationStructure } from '../../graphql/queries';

type NewProcessSurveyProps = {
  title: string;
  topicGroupId: string;
  typeCode?: TopicGroupTypeCode;
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FormContainer = styled.div`
  width: 70%;
`;

export const NewProcessSurvey: FC<NewProcessSurveyProps> = ({ title, topicGroupId, typeCode }) => {
  const { i18n, t } = useTranslation('surveyGroup');
  const { t: topicT } = useTranslation('topicOverview');

  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useUserQuery({
    variables: {
      languageCode: i18n.language,
    },
  });

  const { loading, error, data } = useTopicGroupQuery({
    variables: { topicGroupId },
  });

  const { editSurveyGroupPath, conversationSubjectsPath, onboardingPath, exitPath, processSurveysPath } = usePath();

  const isOnboarding = typeCode === TopicGroupTypeCode.Onboarding;
  const isExit = typeCode === TopicGroupTypeCode.Exit;

  const [name, setName] = useState<string>(data?.topicGroup.name || '');
  const [createSurveyGroup] = useCreateSurveyGroupMutation();
  const history = useHistory();

  const handleSubmit = async () => {
    if (userData?.user.organization?.id) {
      try {
        const result = await createSurveyGroup({
          variables: {
            input: {
              name,
              topicGroupId,
              organizationId: userData?.user.organization?.id,
            },
          },
          refetchQueries: [
            {
              query: getOrganizationStructure,
              variables: { organizationId: userData?.user.organization?.id },
            },
          ],
        });
        const surveyGroupId = result.data?.createSurveyGroup.id;
        if (surveyGroupId) {
          history.push(editSurveyGroupPath.createPath(surveyGroupId));
        }
      } catch (error) {
        // do nothing
      }
    }
  };

  useEffect(() => {
    const surveyGroups =
      (userData &&
        userData?.user?.organization?.surveyGroups &&
        userData?.user?.organization.surveyGroups.filter(
          (sg) =>
            sg.topicGroup.id === topicGroupId && [ProcessStatus.Prepared, ProcessStatus.Ongoing].includes(sg.status),
        )) ||
      [];
    if (surveyGroups.length > 0) {
      ee.emitSgAlreadyExistsModal(
        surveyGroups.length === 1
          ? () => {
              window.location.replace(
                `${getFrontendConfigValue('SELF_CARE_URL')}/editSurveyGroup/${surveyGroups[0].id}`,
              );
            }
          : () => window.location.replace(`${getFrontendConfigValue('SELF_CARE_URL')}/overview`),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userData)]);

  useEffect(() => {
    setName(data?.topicGroup.name || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  if (loading || userLoading || !data) {
    return <Loading />;
  }

  if (error || userError) {
    return <GraphQLErrorCard />;
  }

  const dataIcom = isOnboarding ? 'heading-new-onboarding' : isExit ? 'heading-new-exit' : 'heading-new-process-survey';
  return (
    <PageLayout
      title={title}
      dataIcom={dataIcom}
      breadcrumbItems={[
        {
          title: `< ${t('changeTopic')}`,
          link: isOnboarding
            ? conversationSubjectsPath.createPath(TopicGroupTypeCode.Onboarding)
            : isExit
              ? conversationSubjectsPath.createPath(TopicGroupTypeCode.Exit)
              : conversationSubjectsPath.createPath(),
        },
      ]}
    >
      <Card>
        <Container>
          <FormContainer>
            <RenameProcessSurveyForm name={name} setName={setName} isOnboarding={isOnboarding} />
          </FormContainer>
          <LinkButton
            onClick={() => {
              history.push(`/process/${topicGroupId}`);
            }}
          >
            {topicT('detailLink')}
          </LinkButton>
        </Container>
      </Card>
      {data?.topicGroup && (
        <StepsEditTable steps={data.topicGroup.steps} processTopicGroupId={topicGroupId} hideActions />
      )}

      <Button
        color="primary"
        onClick={handleSubmit}
        data-icom={'btn-schedule-survey'}
        data-cy={'btn-schedule-process-survey'}
      >
        {t('save')}
      </Button>
      <Button
        color="primary"
        variant={'outline-primary'}
        href={
          isOnboarding ? onboardingPath.createPath() : isExit ? exitPath.createPath() : processSurveysPath.createPath()
        }
        data-icom={'btn-cancel'}
      >
        {t('cancel')}
      </Button>
    </PageLayout>
  );
};
