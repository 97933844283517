import { usePath } from '@arnold/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../components';
import { PageLayout } from '../../components/Common/PageLayout';
import { TopicGroupOverview } from '../../components/TopicGroupOverview';
import i18n from '../../translations/i18n';
import { OrganizationQuery, TopicGroupTypeCode, useAllowedConversationSubjectQuery } from '../../generated/hooks';

interface IProps {
  organization: OrganizationQuery['organization'];
}

export const ChooseProcessSurveyScreen: FC<IProps> = ({ organization }) => {
  const { t } = useTranslation('surveyGroup');
  const { processSurveysPath } = usePath();
  const { loading, data, error } = useAllowedConversationSubjectQuery({
    variables: {
      languageCode: i18n.language,
    },
  });

  const filteredTopicGroups = (data?.user.organization?.allowedConversationSubjects || []).filter(
    (conversationSubject) =>
      conversationSubject.typeCode !== TopicGroupTypeCode.Onboarding && conversationSubject.process,
  );

  return (
    <PageLayout
      dataIcom={'heading-process-survey-topics-title'}
      title={t('newSurveyGroup')}
      breadcrumbItems={[{ title: `< ${t('back')}`, link: processSurveysPath.createPath() }]}
    >
      {loading || error || !data || !data?.user.organization ? (
        <Loading />
      ) : (
        <TopicGroupOverview topicGroups={filteredTopicGroups} organization={organization} />
      )}
    </PageLayout>
  );
};
