import { REGEXP_PHONE_NUMBER } from '@arnold/common';
import { TFunction } from 'i18next';
import parsePhoneNumber from 'libphonenumber-js';
import * as Yup from 'yup';
import { ContactType } from '../../../generated/hooks';

export const createValidationSchema = (t: TFunction) =>
  Yup.object().shape({
    firstname: Yup.string().required(t('enterName')),
    surname: Yup.string().required(t('enterSurname')),
    primaryEmail: Yup.lazy((value: string = '') => {
      return value.startsWith('#')
        ? Yup.string().required(t('enterValidWorkEmail'))
        : Yup.string().required(t('enterValidWorkEmail')).email(t('enterValidWorkEmail'));
    }),
    teamId: Yup.string().required(t('enterTeamleader')).nullable(),
    secondaryEmail: Yup.string().email(t('enterValidEmail')),
    phone: Yup.string().matches(REGEXP_PHONE_NUMBER, t('enterValidPhone')),
    callingCode: Yup.string()
      .matches(/^(\+?\d{1,3})$/, t('enterValidCallingCode'))
      .when('phone', (phone, schema) => {
        return phone ? schema.required(t('enterCallingCode')) : schema;
      }),
  });

export const handlePhoneChange = (handler: any) => (e: any) => {
  const { target } = e;
  const { value } = target;

  handler({ target });

  if (value) {
    const parsedPhoneNumber = parsePhoneNumber(value);
    if (parsedPhoneNumber) {
      handler({
        target: { name: 'callingCode', value: parsedPhoneNumber.countryCallingCode },
      });
      handler({
        target: { name: target.name, value: parsedPhoneNumber.nationalNumber },
      });
    }
  }

  if (!value) {
    handler({
      target: { name: 'preferredContactType', value: ContactType.Email },
    });
  }
};

export const handlePhoneBlur = async (cc: string, phoneNumber: string, verifyNumber: (phoneNumber: string) => void) => {
  const internationalPhoneNumber = `+${cc}${phoneNumber}`;
  if (
    /^((\+[1-9]{1,4}[ -]*)|(\([0-9]{2,3}\)[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/.test(
      internationalPhoneNumber,
    )
  ) {
    verifyNumber(internationalPhoneNumber);
  }
};
