import { LanguageSelector, Modal, theme } from '@arnold/common';
import { ReactComponent as CancelCloseIcon } from '@arnold/common/lib/assets/icons/CancelClose.svg';
import styled from '@emotion/styled';
import { useState } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import { default as UploadIcon } from '../../assets/images/upload.svg';
import { FormErrorFeedback, FormGroupLabel } from '../Common';
import { DataNode } from './dataNode';

interface DownloadTranslationsModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  nodes?: DataNode[];
  topicName: string;
  languageCode: string;
}

export const DownloadTranslationsModal = ({
  show,
  setShow,
  nodes,
  topicName,
  languageCode,
}: DownloadTranslationsModalProps) => {
  const { t } = useTranslation('topicEditor');

  const downloadTranslations = () => {
    if (nodes == null) throw new Error('Download translations was triggered before the editor was loaded!');
    const translationData = nodes
      .sort((a, b) => (a.data.reportOrder ?? 999) - (b.data.reportOrder ?? 999))
      .map((node) => ({
        'Internal ID': node.data.questionIndex,
        Text: node.data.questionText,
        Answers: Object.entries(node.data.choices)
          .sort((a, b) => {
            // sort by choice key (opt-1)
            const aIndex = Number(a[0].slice(4));
            const bIndex = Number(b[0].slice(4));
            return aIndex > bIndex ? 1 : -1;
          })
          .map((choice) => choice[1]) // extract value
          .join(' | '),
      }));
    const firstColumnWidth = translationData.reduce((w, r) => Math.max(w, r['Internal ID'].toString().length), 10);
    const secondColumnWidth = translationData.reduce((w, r) => Math.max(w, r.Text.length), 10);
    const thirdColumnWidth = translationData.reduce((w, r) => Math.max(w, r.Answers.length), 10);
    const worksheet = XLSX.utils.json_to_sheet(translationData);
    worksheet['!cols'] = [{ wch: firstColumnWidth + 2 }, { wch: secondColumnWidth }, { wch: thirdColumnWidth }];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, `translations_${languageCode}`);
    XLSX.writeFile(workbook, `${topicName}_translations_${languageCode}.xlsx`);
  };

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      onSubmit={downloadTranslations}
      title={t('downloadTranslationsModalTitle')}
      text={t('downloadTranslationsModalText')}
      buttons={{
        submit: { title: t('downloadTranslationsModalSubmit') },
        cancel: { title: t('common:close') },
      }}
    />
  );
};

export const StyledModalContent = styled.div`
  & .hiddenFileInput {
    display: none;
  }
  & .pickFileLabel {
    color: ${theme.colors.text.primary};
  }
  & .filePicker {
    cursor: pointer;
    color: ${theme.colors.actionPrimary.default};
    font-weight: 500;
    width: fit-content;
    display: flex;
    align-items: center;
    margin-top: ${theme.spacing.e};
    margin-bottom: ${theme.spacing.e};
    & img {
      margin-right: ${theme.spacing.d};
    }
  }
  & .textInput {
    width: 100%;
  }
  & .file,
  .languagePicker,
  .textInput {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid ${theme.colors.borderMain.default};
    border-radius: 6px;
    padding: ${theme.spacing.d};
    height: ${theme.spacing.i};
    padding-left: ${theme.spacing.e};
    color: ${theme.colors.text.primary};
    margin-top: ${theme.spacing.c};
    margin-bottom: ${theme.spacing.f};
  }
  .language {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-top: ${theme.spacing.g};
    margin-bottom: ${theme.spacing.g};
    pointer-events: none;
    & .label {
      font-size: 14px;
      color: ${theme.colors.text.secondary};
      margin-left: 0px;
      margin-right: ${theme.spacing.e};
    }
    & .reactSelect__indicators {
      & svg {
        display: none;
      }
    }
  }
  .languagePicker {
    max-width: 250px;
    padding: 0;
    & .reactSelect__indicators {
      width: ${theme.spacing.h} !important;
      & svg {
        transform: scale(1.5);
      }
    }
  }
  .language,
  .languagePicker {
    & > div {
      width: 100%;
      height: 100%;
      margin: 0;
      & > .reactSelect {
        height: 100%;
      }
    }
    & .reactSelect__control {
      display: flex;
      justify-content: space-between;
      height: 100%;
      padding-left: 8px;
      & .reactSelect__indicators {
        & .reactSelect__indicator-separator {
          display: none;
        }
        & svg {
          transform: scale(1.5);
        }
      }
    }
  }
  & .label {
    font-size: 12px;
    color: ${theme.colors.text.secondary};
    margin-left: ${theme.spacing.f};
  }

  & .errorText {
    color: ${theme.colors.text.primary};
    margin-bottom: ${theme.spacing.e};
  }
`;

interface UploadTranslationsModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  nodes?: DataNode[];
  languageCodes: string[];
  onTranslationsImported: (importedLanguageCode: string) => void;
}
export const UploadTranslationsModal = ({
  show,
  setShow,
  nodes,
  languageCodes,
  onTranslationsImported,
}: UploadTranslationsModalProps) => {
  const { t } = useTranslation('topicEditor');
  const [filename, setFilename] = useState<string | undefined>();
  const [errorText, setErrorText] = useState<string | undefined>();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [importedData, setImportedData] = useState<ImportedTranslation[] | undefined>();
  const [indexesNotFoundInEditor, setIndexesNotFoundInEditor] = useState<number[]>([]);
  const [reportOrdersOfIndexesNotFoundInImportedData, setReportOrdersOfIndexesNotFoundInImportedData] = useState<
    number[]
  >([]);
  const clear = () => {
    setFilename(undefined);
    setErrorText(undefined);
    setSubmitDisabled(true);
    setImportedData(undefined);
  };
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          clear();
          setShow(false);
        }}
        onSubmit={() => {
          if (importedData == null) throw new Error('Submit on imported data was clicked but the data are undefined!');
          if (nodes == null) throw new Error('Submit on imported data was clicked but nodes are undefined!');
          const reportOrdersOfIndexesNotFoundInImportedData: number[] = []; // question indexes that are in editor, but not in the data imported - some new node was added
          const indexesNotFoundInEditor: number[] = []; // question indexes that are in data imported, but not in the editor - some node could have been removed
          importedData.forEach((data) => {
            const node = nodes.find((node) => node.data.questionIndex === data.questionIndex);
            if (node) {
              node.data.questionText = data.questionText;
              data.choices.forEach((choice, i) => {
                node.data.choices[`opt-${i}`] = choice;
              });
            } else {
              indexesNotFoundInEditor.push(data.questionIndex);
            }
          });
          nodes.forEach((node) => {
            const found = importedData.some((data) => data.questionIndex === node.data.questionIndex);
            if (found === false) {
              reportOrdersOfIndexesNotFoundInImportedData.push(node.data.reportOrder || 0);
            }
          });
          setIndexesNotFoundInEditor(indexesNotFoundInEditor);
          setReportOrdersOfIndexesNotFoundInImportedData(reportOrdersOfIndexesNotFoundInImportedData);
          onTranslationsImported(languageCodes[0]);
        }}
        title={t('uploadTranslationsModalTitle')}
        content={
          <StyledModalContent>
            <div className="language">
              <span className="label">{t('uploadTranslationsModalLanguage')}</span>
              <LanguageSelector
                lang={languageCodes[0]}
                onChange={(lang) => {
                  /* Language should not be changeable */
                }}
                allowedLanguages={languageCodes}
                isAlignedToLeft
                displayAlways
                margin={`0 0 0 ${theme.spacing.d}`}
                maxWidth={200}
              />
            </div>
            <input
              className="hiddenFileInput"
              disabled={false}
              type="file"
              id="select-file"
              onChange={(event) => {
                if (nodes == null)
                  throw new Error('Import of translations was triggered before the editor was loaded!');
                const filename = event.target.files?.[0]?.name;
                setFilename(filename);
                parseAndValidateUploadedTranslations(event.target.files, nodes)
                  .then((data) => {
                    setSubmitDisabled(false);
                    setImportedData(data);
                  })
                  .catch((err) => {
                    setSubmitDisabled(true);
                    if (err instanceof InvalidFormatError) {
                      setErrorText(t('uploadTranslationsModalFormatError'));
                    } else if (err instanceof InvalidStructureError) {
                      setErrorText(t('uploadTranslationsModalStructureError'));
                    } else if (err instanceof InvalidChoicesError) {
                      setErrorText(t('uploadTranslationsModalChoicesError', { ids: err.ids.join(', ') }));
                    } else {
                      setErrorText(t('uploadTranslationsModalFormatError'));
                      throw err;
                    }
                  });
              }}
              accept={'.xlsx'}
              onClick={(event) => {
                // Remove previously selected file
                (event.target as HTMLInputElement).value = '';
              }}
            />
            {filename == null ? (
              <>
                <div className="pickFileLabel">{t('uploadTranslationsModalPickFile')}</div>
                <label className="filePicker" htmlFor={'select-file'}>
                  <img src={UploadIcon} alt="upload icon" />
                  {t('uploadTranslationsModalPicker')}
                </label>{' '}
              </>
            ) : (
              <>
                <div className="label">{t('uploadTranslationsModalFile')}</div>
                <div className="file">
                  <span>{filename}</span> <CancelCloseIcon onClick={clear} />
                </div>
              </>
            )}
            {errorText && <div className="errorText">{errorText}</div>}
            {filename != null && errorText == null && (
              <div className="errorText">{t('uploadTranslationsModalFileValid')}</div>
            )}
          </StyledModalContent>
        }
        buttons={{
          submit: { title: t('uploadTranslationsModalSubmit'), disabled: submitDisabled },
          cancel: { title: t('common:close') },
        }}
      />
      <Modal
        show={indexesNotFoundInEditor.length > 0 || reportOrdersOfIndexesNotFoundInImportedData.length > 0}
        onHide={() => {
          setIndexesNotFoundInEditor([]);
          setReportOrdersOfIndexesNotFoundInImportedData([]);
        }}
        content={
          <>
            {indexesNotFoundInEditor.length > 0 && (
              <p>{t('missingTranslationsModalMissingFromEditor', { ids: indexesNotFoundInEditor.join(', ') })}</p>
            )}
            {reportOrdersOfIndexesNotFoundInImportedData.length > 0 && (
              <p>
                {t('missingTranslationsModalMissingFromXLSX', {
                  reportOrderNumbers: reportOrdersOfIndexesNotFoundInImportedData.join(', '),
                })}
              </p>
            )}
          </>
        }
        buttons={{
          submit: { title: t('modal:ok') },
        }}
      />
    </>
  );
};

interface ImportedTranslation {
  questionIndex: number;
  questionText: string;
  choices: string[];
}

class InvalidFormatError extends Error {}
class InvalidStructureError extends Error {}
class InvalidChoicesError extends Error {
  constructor(public ids: number[]) {
    super();
  }
}

const parseAndValidateUploadedTranslations = (
  files: FileList | null,
  nodes: DataNode[],
): Promise<ImportedTranslation[]> => {
  return new Promise((resolve, reject) => {
    const selectedFile = files?.[0];
    if (selectedFile == null) return reject(new InvalidFormatError());
    // Ensure the selected file is an .xlsx file
    if (selectedFile.name.endsWith('.xlsx') === false) return reject(new InvalidFormatError());
    // Read the selected file using FileReader
    const fileReader = new FileReader();

    fileReader.onload = (e) => {
      // Parse the Excel file data
      const data = e.target?.result;

      // Use xlsx library to parse the Excel data into an array of objects
      const workbook = XLSX.read(data, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const parsedDataWithHeaders = XLSX.utils.sheet_to_json(worksheet, {
        header: ['id', 'text', 'choices'],
      }) as {
        id?: string | number;
        text?: string;
        choices?: string;
      }[];

      if (!(parsedDataWithHeaders[0].id && parsedDataWithHeaders[0].text && parsedDataWithHeaders[0].choices))
        reject(new InvalidStructureError());
      // Parse the data and slice off the first row (header)
      const parsedData = parsedDataWithHeaders.slice(1);

      const invalidChoiceIndexes: number[] = [];
      const formattedData = parsedData
        .map((row) => {
          const id = row.id ?? '';
          const text = row.text ?? '';
          const choices = row.choices ?? '';
          return { id: String(id).trim(), text: text.trim(), choices: choices.trim() };
        })
        .filter((row) => row.id.length > 0 || row.text.length > 0 || row.choices.length > 0)
        .map((row) => {
          if (/^[0-9]+$/.test(row.id) === false) reject(new InvalidStructureError());
          const questionIndex = parseInt(row.id, 10);
          const questionText = row.text;

          const originalQuestionData = nodes.find((node) => node.data.questionIndex === questionIndex)?.data;
          const originalChoicesLength = originalQuestionData ? Object.values(originalQuestionData.choices).length : 0;
          const choices = originalChoicesLength
            ? row.choices
                .split('|')
                .map((s) => s.trim())
                .filter((s) => s)
            : [];
          if (originalQuestionData) {
            if (choices.length !== originalChoicesLength) {
              invalidChoiceIndexes.push(questionIndex);
            }
          }
          return { questionIndex, questionText, choices };
        });
      if (invalidChoiceIndexes.length > 0) {
        reject(new InvalidChoicesError(invalidChoiceIndexes));
      }

      resolve(formattedData);
    };
    // Read the file as binary data
    fileReader.readAsBinaryString(selectedFile);
  });
};

interface AddLanguageModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  languageCodes: string[];
  onLanguageAdded: (languageCode: string, title: string, description: string) => void;
  orgPrimaryLang: string;
}
export const AddLanguageModal = ({
  show,
  setShow,
  languageCodes,
  onLanguageAdded,
  orgPrimaryLang,
}: AddLanguageModalProps) => {
  const { t } = useTranslation('topicEditor');
  const [translatedTitle, setTranslatedTitle] = useState<string>('');
  const [translatedDescription, setTranslatedDescription] = useState<string>('');
  const [language, setLanguage] = useState<string | undefined>(languageCodes[0]);
  const [infoModalShow, setInfoModalShow] = useState(false);
  const clear = () => {
    setTranslatedTitle('');
    setTranslatedDescription('');
    setLanguage(languageCodes[0]);
  };

  if (languageCodes.length < 1) {
    return (
      <Modal
        show={show}
        onHide={() => setShow(false)}
        title={t('addLanguageModalTitle')}
        content={
          <Trans
            i18nKey={`topicEditor:addLanguageModalNoLanguages`}
            components={[
              <a href={`mailto:${t('informationFooter:helpEmail')}`} target="_blank" rel="noreferrer">
                {t('informationFooter:helpEmail')}
              </a>,

              <a href={`tel:${t('informationFooter:helpNumber')}`}>{t('informationFooter:helpNumber')}</a>,
            ]}
          />
        }
        buttons={{
          cancel: { show: false },
          submit: { title: t('common:close') },
        }}
      />
    );
  }
  return (
    <>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        onSubmit={() => setInfoModalShow(true)}
        title={t('addLanguageModalTitle')}
        content={
          <StyledModalContent>
            <div className="label">{t('addLanguageModalLanguageLabel')}</div>
            <div className="languagePicker">
              <LanguageSelector
                lang={language}
                onChange={(lang) => setLanguage(lang)}
                allowedLanguages={languageCodes}
                isAlignedToLeft
                displayAlways
                margin={`0 0 0 ${theme.spacing.d}`}
                maxWidth={250}
              />
            </div>
            <FormGroup>
              <FormGroupLabel>{t('addLanguageModalTitleLabel')}</FormGroupLabel>
              <FormControl
                type="text"
                name={'name'}
                onChange={(e) => setTranslatedTitle(e.target.value)}
                isInvalid={translatedTitle.length < 3}
              />
              {translatedTitle.length < 3 && <FormErrorFeedback error={t('createNewTopic:nameTooShort')} />}
            </FormGroup>
            <div className="label">{t('addLanguageModalDescriptionLabel')}</div>
            <textarea
              className="textInput"
              style={{ height: theme.spacing.m, resize: 'none' }}
              onChange={(e) => setTranslatedDescription(e.target.value)}
            />
          </StyledModalContent>
        }
        buttons={{
          submit: { title: t('addLanguageModalSubmit'), disabled: translatedTitle.length < 3 },
          cancel: { title: t('common:close') },
        }}
      />
      <Modal
        show={infoModalShow}
        onHide={() => {
          setInfoModalShow(false);
          onLanguageAdded(language!, translatedTitle, translatedDescription);
          clear();
        }}
        title={t('languageAddedModalTitle')}
        text={t('languageAddedModalText', { lang: orgPrimaryLang })}
        buttons={{
          submit: { title: t('modal:ok') },
        }}
      />
    </>
  );
};
