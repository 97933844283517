import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Pagination, SearchInput, ToggleButton, theme } from '@arnold/common';
import { useState } from 'react';
import styled from '@emotion/styled';
import { Loading } from '../..';
import { ErrorPage } from '../../StyledComponents';
import { Table } from '../../Table/Table';
import {
  OrganizationQuery,
  SurveyGroupWithoutRespondentsQuery,
  UserQuery,
  useSurveyGroupRespondentsQuery,
} from '../../../generated/hooks';
import { LoadingCard } from '../../LoadingCard';
import { getDataSource } from './getDataSource';
import { ProcessSurveyParticipantsData } from './types';
import { RESPONDENTS_ON_PAGE, getColumns } from './utils';

interface IProps {
  surveyGroup: NonNullable<SurveyGroupWithoutRespondentsQuery['surveyGroup']>;
  topLevelReportAccess?: string | boolean;
  isOnboarding?: boolean;
  onChangePage: (page: number) => void;
  organization: UserQuery['user']['organization'] | OrganizationQuery['organization'];
}

const ToggleContainer = styled.div`
  display: flex;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ToggleTitle = styled.div`
  margin-right: ${theme.spacing.f};
`;

const PaginationContainer = styled.div`
  padding: 0 ${theme.spacing.e} ${theme.spacing.e} ${theme.spacing.e};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProcessSurveyParticipants = ({
  surveyGroup,
  topLevelReportAccess,
  isOnboarding,
  onChangePage,
  organization,
}: IProps) => {
  const [searchQuery, setSearchQuery] = useState('');
  const savedCheckedValue = localStorage.getItem(`hideRespondents:${surveyGroup.id}`);
  const [checked, setChecked] = useState(savedCheckedValue ? JSON.parse(savedCheckedValue) : false);
  const location = useLocation();
  const { t } = useTranslation('surveyGroup');
  const search = new URLSearchParams(location.search);
  const currentPage = search.get('page') || '1';

  const { loading, error, data, previousData } = useSurveyGroupRespondentsQuery({
    variables: {
      surveyGroupId: surveyGroup.id,
      pagination: {
        offset: (Number(currentPage) - 1) * RESPONDENTS_ON_PAGE,
        limit: RESPONDENTS_ON_PAGE,
      },
      searchQuery,
      withoutDeleted: checked,
    },
  });

  const handleChange = async (value: boolean) => {
    if (currentPage && currentPage !== '1') {
      onChangePage(1);
    }
    setChecked(value);
    localStorage.setItem(`hideRespondents:${surveyGroup.id}`, value.toString());
  };

  const handleChangeSearchQuery = (value: string) => {
    if (currentPage && currentPage !== '1' && value !== searchQuery) {
      onChangePage(1);
    }
    setSearchQuery(value);
  };

  if (loading && previousData == null) {
    return <Loading />;
  }

  if (error) {
    return <ErrorPage>{t('common:loadError')}</ErrorPage>;
  }

  const dataSources = getDataSource({
    surveyGroupRespondents: (data || previousData)?.surveyGroupRespondents.data,
    surveyGroup,
    t,
    topLevelReportAccess,
    isOnboarding,
    searchQuery,
    organization,
  });

  return (
    <>
      <FilterContainer>
        <div className="mt-3 mb-6" style={{ maxWidth: 250 }}>
          <SearchInput onChange={handleChangeSearchQuery} value={searchQuery} delay={500} name="respondents-search" />
        </div>
        <ToggleContainer>
          <ToggleTitle>{t('hideDeletedRespondents')}</ToggleTitle>
          <ToggleButton
            value={checked}
            onChange={handleChange}
            dataIcom={'hide-respondents-toggle'}
            dataCy={'hide-respondents-toggle'}
          />
        </ToggleContainer>
      </FilterContainer>
      {loading ? (
        <LoadingCard />
      ) : (
        <Table<ProcessSurveyParticipantsData>
          rowDataCypressTestFlag={'respondent-item'}
          columns={getColumns(t, isOnboarding)}
          dataSources={dataSources || []}
          selected={undefined}
          renderDetailPanel={(rowData) => rowData.participantDetail}
        />
      )}
      {!!data?.surveyGroupRespondents.totalCount && (
        <PaginationContainer>
          <span style={{ marginTop: 20 }}>
            {t('paginationInfoText', {
              total: data.surveyGroupRespondents.totalCount,
              from: (Number(currentPage) - 1) * RESPONDENTS_ON_PAGE + 1,
              to:
                (Number(currentPage) - 1) * RESPONDENTS_ON_PAGE +
                data.surveyGroupRespondents.data.reduce((acc: number, group) => acc + group.respondents.length, 0),
            })}
          </span>
          <Pagination
            totalPages={Math.floor((data.surveyGroupRespondents.totalCount - 1) / RESPONDENTS_ON_PAGE) + 1}
            currentPage={Number(currentPage)}
            onPageChange={onChangePage}
          />
        </PaginationContainer>
      )}
    </>
  );
};
