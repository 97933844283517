import { format, setHours, setMinutes } from 'date-fns';
import { getLocale } from '@arnold/core';
import { isNullish } from '@apollo/client/cache/inmemory/helpers';
import i18n from '../translations/i18n';
import { TopicGroupQuery } from '../generated/hooks';

export const HOUR = 60;
export const DAY = 24;
export const MINUTES_INTERVAL_PER_HOUR = 4;
export const MINUTES_INTERVAL = HOUR / MINUTES_INTERVAL_PER_HOUR;

export type Offset = {
  days?: number;
  hours?: number;
  minutes?: number;
};

export const parseOffsetInMinutes = (minutes: number): Offset | null => {
  if (isNullish(minutes)) {
    return null;
  }

  const days = Math.floor(minutes / HOUR / DAY);
  const remainingHours = Math.floor((minutes - days * HOUR * DAY) / HOUR);
  const remainingMinutes = Math.floor(minutes - days * HOUR * DAY - remainingHours * HOUR);

  return {
    days,
    hours: remainingHours,
    minutes: remainingMinutes,
  };
};

export const parseMinutesInOffset = (offset: Offset | null, base: number = 0) => {
  if (isNullish(offset) || isNullish(offset.days) || isNullish(offset.hours) || isNullish(offset.minutes)) {
    return null;
  }
  return offset.days * HOUR * DAY + offset.hours * HOUR + offset.minutes + base;
};

export const prepareStepOffsets = (
  step: Pick<NonNullable<TopicGroupQuery['topicGroup']['steps']>[0], 'startOffsetMins' | 'endOffsetMins'> & {
    remindersOffsetMins: number[];
  },
) => {
  if (isNullish(step.startOffsetMins) || isNullish(step.endOffsetMins)) {
    return {
      baseHoursAndMinutes: null,
      startOffset: null,
      remindersOffset: null,
      endOffset: null,
    };
  }
  const startOffset = parseOffsetInMinutes(step.startOffsetMins);
  const baseHoursAndMinutes = startOffset!.hours! * HOUR + startOffset!.minutes!;
  const remindersOffset = step.remindersOffsetMins
    ?.map((reminderOffsetMins) => {
      if (reminderOffsetMins) {
        return parseOffsetInMinutes(reminderOffsetMins + baseHoursAndMinutes);
      } else {
        return null;
      }
    })
    .filter((reminder) => reminder !== null) as Offset[];
  const endOffset = parseOffsetInMinutes(step.endOffsetMins + baseHoursAndMinutes);
  return {
    baseHoursAndMinutes,
    startOffset,
    remindersOffset,
    endOffset,
  };
};

export const getTimeStringForOffset = (offset: Offset | null) => {
  if (isNullish(offset) || isNullish(offset.days) || isNullish(offset.hours) || isNullish(offset.minutes)) {
    return null;
  }
  return format(setMinutes(setHours(new Date(), offset.hours), offset.minutes), 'p', {
    locale: getLocale(i18n.language),
  });
};

export const getTimeStringForOffsetInCzech = (offset: Offset) => {
  if (isNullish(offset.days) || isNullish(offset.hours) || isNullish(offset.minutes)) {
    return null;
  }
  return format(setMinutes(setHours(new Date(), offset.hours), offset.minutes), 'H:mm', {
    locale: getLocale(i18n.language),
  });
};

export const getIsReminderSet = (start: Offset | null | undefined, reminder: Offset | null | undefined) =>
  !(
    (reminder?.days === 0 && start?.hours === reminder.hours && start?.minutes === reminder.minutes) ||
    reminder === null ||
    reminder === undefined
  );

export const getTimeIntervalOptions = () => {
  const timeOptions = [];

  for (let interval = 0; interval < DAY * MINUTES_INTERVAL_PER_HOUR; interval++) {
    const timeInterval = setMinutes(
      setHours(new Date(), Math.floor(interval / MINUTES_INTERVAL_PER_HOUR)),
      (interval % MINUTES_INTERVAL_PER_HOUR) * MINUTES_INTERVAL,
    );
    timeOptions.push(format(timeInterval, 'H:mm'));
  }
  return timeOptions;
};

const roundTo15 = (num: number) => {
  return Math.round(num / 15) * 15;
};

export const getTimeIntervalForOffset = (timeIntervalOptions: string[], offset: Offset | null) => {
  if (isNullish(offset) || isNullish(offset.days) || isNullish(offset.hours) || isNullish(offset.minutes)) {
    return null;
  }
  const roundedMinutes = roundTo15(offset.minutes);
  const preparedOffset = {
    minutes: roundedMinutes === HOUR ? 0 : roundedMinutes,
    hours: roundedMinutes === HOUR ? offset.hours + 1 : offset.hours,
    days: offset.days,
  };
  return timeIntervalOptions.findIndex(
    (timeInterval) => timeInterval === getTimeStringForOffsetInCzech(preparedOffset),
  );
};
