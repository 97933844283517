import styled from '@emotion/styled';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { ReactComponent as ClearIcon } from '../../../assets/icons/Cancel.svg';
import { ReactComponent as SearchIcon } from '../../../assets/icons/Search.svg';

interface IProps {
  value: string;
  onChange?: (searchQuery: string) => void;
  name?: string;
  className?: string;
  // delay for callback in ms
  delay?: number;
}

const IconButton = styled.button`
  position: absolute;
  right: 14px;
  top: 6px;
  border: none;
  background: none;

  svg {
    color: #c1c1c1;
    fill: #c1c1c1;
  }
`;

const Container = styled.div`
  position: relative;
`;

export const SearchInput = ({ value, onChange, name, className, delay = 500 }: IProps) => {
  const [currentValue, setCurrentValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      onChange?.(currentValue);
    }, delay);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentValue]);

  const handleClick = () => {
    inputRef.current?.focus();
    if (value) {
      setCurrentValue('');
    }
  };

  const handleChange = (e: ChangeEvent<any>) => {
    setCurrentValue(e.currentTarget.value);
  };

  return (
    <Container className={className}>
      <FormControl ref={inputRef} type="text" name={name} value={currentValue} onChange={handleChange} />
      <IconButton onClick={handleClick}>{currentValue ? <ClearIcon /> : <SearchIcon />}</IconButton>
    </Container>
  );
};
