import { default as attractivityIcon } from '@arnold/common/lib/assets/icons/categories/attractivity.svg';
import { default as customIcon } from '@arnold/common/lib/assets/icons/categories/custom.svg';
import { default as cultureIcon } from '@arnold/common/lib/assets/icons/categories/culture.svg';
import { default as farewellIcon } from '@arnold/common/lib/assets/icons/categories/farewell.svg';
import { default as hiringIcon } from '@arnold/common/lib/assets/icons/categories/hiring.svg';
import { default as maintenanceIcon } from '@arnold/common/lib/assets/icons/categories/maintenance.svg';
import { default as motivationIcon } from '@arnold/common/lib/assets/icons/categories/motivation.svg';
import { default as onboardingIcon } from '@arnold/common/lib/assets/icons/categories/onboarding.svg';
import { default as organizationIcon } from '@arnold/common/lib/assets/icons/categories/organization.svg';
import { default as peopleDevelopmentIcon } from '@arnold/common/lib/assets/icons/categories/peopleDevelopment.svg';
import { default as productivityIcon } from '@arnold/common/lib/assets/icons/categories/productivity.svg';
import { default as remoteWorkIcon } from '@arnold/common/lib/assets/icons/categories/remoteWork.svg';
import { default as fallbackIcon } from '@arnold/common/lib/assets/icons/categories/fallback.svg';
import { default as publicIcon } from '@arnold/common/lib/assets/icons/categories/public.svg';

import { ProcessStatus, SurveyStatus, UserQuery } from '../../generated/hooks';

export const isSurveyStarted = (status: SurveyStatus): boolean =>
  [SurveyStatus.Ongoing, SurveyStatus.Completed, SurveyStatus.Archived].includes(status);

/**
 * @param status @todo - there is hidden error,
 * SurveyStatus.ARCHIVED === ProcessStatus.ARCHIVED are true but are different type
 */
export const isSurveyPlanned = (status: SurveyStatus | ProcessStatus): boolean =>
  status === SurveyStatus.Prepared || status === SurveyStatus.Notstarted;

export const isSurveyFinished = (status: SurveyStatus | ProcessStatus): boolean =>
  status === SurveyStatus.Completed || status === SurveyStatus.Archived || status === ProcessStatus.Archived;

export const getSurveyDescription = (survey: NonNullable<UserQuery['user']['organization']>['surveys'][0]) => {
  if (survey.topic) {
    return survey.topic.description;
  } else if (survey.topicGroup) {
    return survey.topicGroup.description;
  } else {
    return null;
  }
};

export const isSurveyActive = (status: SurveyStatus): boolean =>
  [SurveyStatus.Prepared, SurveyStatus.Ongoing, SurveyStatus.Notstarted].includes(status);

export const getCategoryIcon = (custom: boolean, categoryCode?: string | null, isPublic?: boolean): string => {
  if (isPublic) {
    return publicIcon;
  }

  if (custom === true) {
    return customIcon;
  }
  switch (categoryCode) {
    case 'attr':
      return attractivityIcon;
    case 'onboard':
      return onboardingIcon;
    case 'remote':
      return remoteWorkIcon;
    case 'devel':
      return peopleDevelopmentIcon;
    case 'motiv':
      return motivationIcon;
    case 'culture':
      return cultureIcon;
    case 'hiring':
      return hiringIcon;
    case 'prod':
      return productivityIcon;
    case 'succ':
      return organizationIcon;
    case 'offboard':
      return farewellIcon;
    case 'reten':
      return maintenanceIcon;
    case 'change':
    case 'intro':
    default:
      return fallbackIcon;
  }
};
