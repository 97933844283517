import styled from '@emotion/styled';
import { BodySmall, CloseIcon, PlainButton, SubtleButton, theme } from '@arnold/common';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Drag } from 'rete-react-plugin';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { TextAreaControl } from '../controls/TextAreaControl';
import { NodeData } from '../dataNode';
import { createCommentMutation, createCommentGroupMutation } from '../../../graphql/mutations';
import { getTGAccessHeaderContext } from '../utils';
import { loggedUser } from '../../../lib/helpers';
import { CommentContainer } from './CommentContainer';

const CreatedBy = styled.div`
  padding: ${theme.spacing.d} ${theme.spacing.f};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledPlainButton = styled(PlainButton)`
  background-color: transparent;
  padding: ${theme.spacing.b};
  width: 22px;
  height: 22px;

  & path {
    fill: ${theme.colors.iconText.primary};
  }

  &:hover path {
    fill: ${theme.colors.iconAction.primary};
  }
`;

const ButtonContainer = styled.div`
  padding: ${theme.spacing.d} ${theme.spacing.f};
`;

type Props = {
  data: NodeData;
  onChange: (data: NodeData) => void;
  readOnly: boolean;
};

export const Comments: FC<Props> = ({ data, onChange, readOnly }) => {
  const { t } = useTranslation('topicEditor');
  const [nodeData, setNodeData] = useState(data);
  const [showNewComment, setShowNewComment] = useState(!nodeData.comments);
  const [isEditModeOpened, setIsEditModeOpened] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [editedId, setEditedId] = useState<string | undefined>(undefined);
  const [text, setText] = useState<string>('');
  const ref = React.useRef({
    text: '',
  });
  const user = loggedUser;

  const [createComment] = useMutation(createCommentMutation);
  const [createCommentGroup] = useMutation(createCommentGroupMutation);

  const onCommentDelete = () => {
    setShowNewComment(false);
    if (!nodeData.comments) {
      nodeData.onDelete?.();
    }
  };

  useEffect(() => {
    if (isEditModeOpened) {
      setShowNewComment(false);
    }
  }, [isEditModeOpened]);

  const handleCreateComment = async () => {
    let newData;
    const context = data.search ? getTGAccessHeaderContext(data.search) : {};

    setIsDisabled(true);
    if (nodeData.comments) {
      newData = await createComment({
        variables: {
          input: {
            text: ref.current.text,
            commentGroupId: nodeData.commentGroupId,
          },
        },
        context,
      });
      setNodeData({ ...nodeData, comments: newData.data.createComment.comments });
      onChange({
        ...data,
        commentGroupId: newData.data.createComment.id,
        comments: newData.data.createComment.comments,
      });
    } else {
      newData = await createCommentGroup({
        variables: {
          input: {
            positionX: data.position[0]!,
            positionY: data.position[1]!,
            topicId: data.topicId,
            comment: {
              text: ref.current.text,
            },
          },
        },
        context,
      });
      setNodeData({
        ...nodeData,
        commentGroupId: newData.data.createCommentGroup.id,
        comments: newData.data.createCommentGroup.comments,
      });
      onChange({
        ...data,
        commentGroupId: newData.data.createCommentGroup.id,
        comments: newData.data.createCommentGroup.comments,
      });
    }
    setIsDisabled(false);
    setShowNewComment(false);
    setText('');
    ref.current.text = '';
  };

  const textAreaRef = useRef<any>(null);
  useEffect(() => {
    if (showNewComment && textAreaRef.current) {
      const textarea = textAreaRef.current;
      textarea.focus();
    }
  }, [showNewComment]);

  return (
    <>
      {nodeData.comments &&
        nodeData.comments.map((comment) => {
          return (
            <CommentContainer
              comment={comment}
              signedUserId={user?.id}
              setIsEditModeOpened={setIsEditModeOpened}
              editedId={editedId}
              setEditedId={setEditedId}
              data={nodeData}
              onChange={onChange}
            />
          );
        })}

      {!isEditModeOpened && (
        <>
          {showNewComment && !readOnly ? (
            <>
              <CreatedBy>
                <BodySmall bold>{user ? `${user?.firstname} ${user.surname}` : t('anonymous')}</BodySmall>
                <Drag.NoDrag>
                  <StyledPlainButton onClick={onCommentDelete}>
                    <CloseIcon />
                  </StyledPlainButton>
                </Drag.NoDrag>
              </CreatedBy>
              <TextAreaControl
                ref={textAreaRef}
                data={{
                  readOnly: false,
                  initialValue: '',
                  id: 'comment',
                  change: (value) => {
                    ref.current.text = value;
                    setText(value);
                  },
                }}
              />
              <ButtonContainer>
                <Drag.NoDrag>
                  <SubtleButton
                    buttons={[
                      {
                        title: t('addComment'),
                        onClick: handleCreateComment,
                        disabled: isDisabled || text.length === 0,
                      },
                    ]}
                  />
                </Drag.NoDrag>
              </ButtonContainer>
            </>
          ) : (
            !readOnly && (
              <ButtonContainer>
                <Drag.NoDrag>
                  <SubtleButton
                    buttons={[
                      { title: t('addNewComment'), onClick: () => setShowNewComment(true), disabled: isDisabled },
                    ]}
                  />
                </Drag.NoDrag>
              </ButtonContainer>
            )
          )}{' '}
        </>
      )}
    </>
  );
};
