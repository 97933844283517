import { TFunction } from 'react-i18next';
import { isNullish } from '@arnold/common';
import { getTimeIntervalOptions } from '../../../lib/process';
import { getOffsetDaysLabel, getStartOffsetDaysLabel } from './label';

const timeOptions = getTimeIntervalOptions();

const DAYS_IN_PAST = 180;
const DAYS_IN_FUTURE = 720;
const OFFSET_DAY_OPTIONS = 30;

export const get360DaysOptions = (startOffsetDays: number | null) =>
  Array.from(Array(DAYS_IN_PAST + DAYS_IN_FUTURE)).map((value, index) => {
    let dayIndex = index - DAYS_IN_PAST;
    dayIndex += dayIndex >= 0 ? 1 : 0;
    return {
      value: dayIndex,
      label: getStartOffsetDaysLabel(dayIndex, dayIndex === startOffsetDays),
    };
  });
export const startMinutesOptions = timeOptions.map((value, index) => ({
  value: index,
  label: value,
}));

type GetReminderDaysOptionsParams = {
  startOffsetMinutes: number | null;
  reminderOffsetMinutes: number | null;
  reminderOffsetDays: number | null;
  endOffsetMinutes: number | null;
  endOffsetDays: number | null;
  t: TFunction;
};
export const getReminderDaysOptions = ({
  startOffsetMinutes,
  reminderOffsetMinutes = 0,
  reminderOffsetDays = 0,
  endOffsetMinutes,
  endOffsetDays,
  t,
}: GetReminderDaysOptionsParams) =>
  Array.from(Array(OFFSET_DAY_OPTIONS)).map((value, index) => {
    const isDisabled =
      !isNullish(startOffsetMinutes) &&
      !isNullish(endOffsetDays) &&
      !isNullish(endOffsetMinutes) &&
      ((index === 0 && startOffsetMinutes + 1 > reminderOffsetMinutes!) ||
        index * 60 * 24 + reminderOffsetMinutes! * 15 > (endOffsetDays || 0) * 60 * 24 + endOffsetMinutes * 15);
    return {
      value: index,
      label: getOffsetDaysLabel(index, t, index === reminderOffsetDays, isDisabled),
      isDisabled,
    };
  });

type GetReminderHoursOptionsParams = {
  startOffsetMinutes: number | null;
  reminderOffsetDays: number | null;
  endOffsetMinutes: number | null;
  endOffsetDays: number | null;
};
export const getReminderHoursOptions = ({
  startOffsetMinutes,
  reminderOffsetDays,
  endOffsetMinutes,
  endOffsetDays,
}: GetReminderHoursOptionsParams) =>
  timeOptions.map((value, index) => {
    const isDisabled =
      !isNullish(startOffsetMinutes) &&
      !isNullish(endOffsetDays) &&
      !isNullish(endOffsetMinutes) &&
      ((reminderOffsetDays === 0 && index < startOffsetMinutes + 1) ||
        (reminderOffsetDays === endOffsetDays && index >= endOffsetMinutes));
    return {
      value: index,
      label: value,
      isDisabled,
    };
  });

type GetSecondReminderDaysOptionsParams = {
  reminderOffsetMinutes: number | null;
  reminderOffsetDays: number | null;
  secondReminderOffsetMinutes: number | null;
  endOffsetMinutes: number | null;
  endOffsetDays: number | null;
  t: TFunction;
};
export const getSecondReminderDaysOptions = ({
  reminderOffsetMinutes,
  reminderOffsetDays,
  secondReminderOffsetMinutes,
  endOffsetMinutes,
  endOffsetDays,
  t,
}: GetSecondReminderDaysOptionsParams) =>
  Array.from(Array(OFFSET_DAY_OPTIONS)).map((value, index) => {
    const isDisabled =
      !isNullish(reminderOffsetDays) &&
      !isNullish(reminderOffsetMinutes) &&
      !isNullish(secondReminderOffsetMinutes) &&
      !isNullish(endOffsetDays) &&
      !isNullish(endOffsetMinutes) &&
      (index < reminderOffsetDays ||
        endOffsetDays < index ||
        (index === reminderOffsetDays && secondReminderOffsetMinutes < reminderOffsetMinutes) ||
        (index === endOffsetDays && secondReminderOffsetMinutes >= endOffsetMinutes));
    return {
      value: index,
      label: getOffsetDaysLabel(index, t, index === reminderOffsetDays, isDisabled),
      isDisabled,
    };
  });

type GetSecondReminderHoursOptionsParams = {
  reminderOffsetMinutes: number | null;
  reminderOffsetDays: number | null;
  secondReminderOffsetDays: number | null;
  endOffsetMinutes: number | null;
  endOffsetDays: number | null;
};
export const getSecondReminderHoursOptions = ({
  reminderOffsetMinutes,
  reminderOffsetDays,
  secondReminderOffsetDays,
  endOffsetMinutes,
  endOffsetDays,
}: GetSecondReminderHoursOptionsParams) =>
  timeOptions.map((value, index) => {
    const isDisabled =
      !isNullish(reminderOffsetDays) &&
      !isNullish(reminderOffsetMinutes) &&
      !isNullish(endOffsetDays) &&
      !isNullish(endOffsetMinutes) &&
      ((reminderOffsetDays === secondReminderOffsetDays && index < reminderOffsetMinutes + 1) ||
        (secondReminderOffsetDays === endOffsetDays && index >= endOffsetMinutes));
    return {
      value: index,
      label: value,
      isDisabled,
    };
  });

type GetEndDaysOptionsParams = {
  startOffsetMinutes: number | null;
  endOffsetMinutes: number | null;
  endOffsetDays: number | null;
  t: TFunction;
};
export const getEndDaysOptions = ({
  startOffsetMinutes,
  endOffsetMinutes,
  endOffsetDays,
  t,
}: GetEndDaysOptionsParams) => {
  return Array.from(Array(OFFSET_DAY_OPTIONS)).map((value, index) => {
    const isDisabled =
      !isNullish(startOffsetMinutes) &&
      !isNullish(endOffsetDays) &&
      !isNullish(endOffsetMinutes) &&
      index === 0 &&
      startOffsetMinutes + 2 > endOffsetMinutes;
    return {
      value: index,
      label: getOffsetDaysLabel(index, t, index === endOffsetDays, isDisabled),
      isDisabled,
    };
  });
};

type GetEndHoursOptionsParams = {
  startOffsetMinutes: number | null;
  endOffsetDays: number | null;
};
export const getEndHoursOptions = ({ startOffsetMinutes, endOffsetDays }: GetEndHoursOptionsParams) => {
  return timeOptions.map((value, index) => ({
    value: index,
    label: value,
    isDisabled:
      !isNullish(endOffsetDays) &&
      !isNullish(startOffsetMinutes) &&
      endOffsetDays === 0 &&
      index < startOffsetMinutes + 2,
  }));
};
