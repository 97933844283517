import styled from '@emotion/styled';
import { FormGroup } from 'react-bootstrap';

export const StyledFormGroup = styled(FormGroup)`
  width: 100%;
  text-align: left;
`;

export const Notification = styled.div`
  padding: 60px 0;
`;
