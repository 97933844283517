import { BodySmall, calculateValidPeriod, HeadingLarge, theme } from '@arnold/common';
import { ReactComponent as Check } from '@arnold/common/lib/assets/icons/Check.svg';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { Row } from 'react-bootstrap';
import { TFunctionResult } from 'i18next';
import { Card, CenteredCol, Col, NormalRow } from '../../components/Common';
import { EDITION } from '../../lib/common';
import { IsAccessTokenValidQuery, OrganizationQuery, useGetPricelistQuery } from '../../generated/hooks';
import i18n from '../../translations/i18n';
import { GraphQLErrorCard, Loading } from '../../components';
import Edition from './Edition';

enum SubscriptionDuration {
  Year = 'YEAR',
  Month = 'MONTH',
}

const CenteredHeadingWrap = styled.div`
  text-align: center;
`;

const PeriodSwitchWrapper = styled.div`
  text-align: center;
  padding-top: ${theme.spacing.f};
`;

const PeriodSwitch = styled.div`
  height: 32px;
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: ${theme.colors.actionPrimary.default};
  background-color: #fff;
  border-radius: 50px;
`;

const PeriodSwitchItem = styled.div`
  position: relative;
`;

const PeriodSwitchRadio = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

const EditionsWrap = styled.div`
  margin-top: ${theme.spacing.l};
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 4rem;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PeriodSwitchLabel = styled.label<{ checked: boolean }>((props) => ({
  padding: `${theme.spacing.c} ${theme.spacing.h}`,
  borderRadius: '50px',
  cursor: 'pointer',
  userSelect: 'none',
  color: props.checked ? theme?.colors.textInverted.primary : 'inherit',
  backgroundColor: props.checked ? theme?.colors.actionPrimary.active : 'inherit',
  ...theme.typography.body.medium?.button,
}));

const NoteSpan = styled(BodySmall)`
  color: ${theme.colors.text.disabled};
`;

const HeaderRow = styled(Row)`
  padding: ${theme.spacing.h} 0 ${theme.spacing.f};
  color: ${theme.colors.text.primary};
  font-size: ${theme.typography.heading.small?.default?.fontSize};
  font-weight: ${theme.typography.heading.small?.default?.fontWeight};
  line-height: ${theme.typography.heading.small?.default?.lineHeight};
`;

const SubHeaderRow = styled(HeaderRow)`
  padding: ${theme.spacing.j} 0 ${theme.spacing.f};
  border-top: 1px solid ${theme.colors.borderSeparator.default};
`;

const StyledNormalRow = styled(NormalRow)<{ first: boolean }>(({ first }) => ({
  borderTop: first ? 'none' : `1px solid ${theme.colors.borderSeparator.default}`,
  ...theme.typography.body.small?.regular,
}));

const CheckIconStyled = styled(Check)`
  & path {
    fill: ${theme.colors.iconText.primary};
  }
`;

interface IPriceListProps {
  user: NonNullable<IsAccessTokenValidQuery['isAccessTokenValid']['user']>;
  organization: NonNullable<OrganizationQuery['organization']>;
  onClick: (value: string) => void;
  activeProduct?: string;
}

interface IFeaturesRowProps {
  featureName: string;
  values: Array<boolean | string | TFunctionResult>;
  first: boolean;
}

const FeaturesRow = ({ featureName, values, first }: IFeaturesRowProps) => (
  <StyledNormalRow first={first}>
    <Col sm="6" xl="6">
      {featureName}
    </Col>
    {values.map((v) => (
      <CenteredCol sm="2" xl="2">
        {typeof v === 'string' ? v : v && <CheckIconStyled />}
      </CenteredCol>
    ))}
  </StyledNormalRow>
);

const Pricelist = ({ organization, onClick, activeProduct }: IPriceListProps) => {
  const [t] = useTranslation('pricelist');
  const [subscriptionDuration, setSubscriptionDuration] = useState<SubscriptionDuration>(SubscriptionDuration.Year);
  const {
    loading,
    data: pricelistData,
    error,
  } = useGetPricelistQuery({
    variables: {
      language: i18n.language,
    },
  });
  const pricelist = pricelistData?.getPricelist;

  if (error) {
    return <GraphQLErrorCard />;
  }

  if (loading) {
    return <Loading />;
  }

  const findEditionPriceInfo = (product: string, duration: SubscriptionDuration) =>
    pricelist?.find((pl) => pl && pl.product === product && pl.duration === duration);

  const calculateSave = (product: string, duration: SubscriptionDuration) => {
    if (duration === SubscriptionDuration.Month) {
      return 0;
    }
    const monthlyPrice =
      pricelist?.find((pl) => pl && pl.product === product && pl.duration === SubscriptionDuration.Month)?.price || 0;
    const anualPrice =
      pricelist?.find((pl) => pl && pl.product === product && pl.duration === SubscriptionDuration.Year)?.price || 0;
    return Math.round(((12 * monthlyPrice - 12 * anualPrice) / (12 * monthlyPrice)) * 100);
  };

  const activeEdition =
    (organization?.productVersionHistory && calculateValidPeriod(organization?.productVersionHistory).product) ||
    EDITION.FREE.name;

  return (
    <div data-cy={'admin-pricelist'}>
      <PeriodSwitchWrapper>
        <PeriodSwitch>
          <PeriodSwitchItem>
            <PeriodSwitchLabel
              onClick={() => setSubscriptionDuration(SubscriptionDuration.Year)}
              checked={subscriptionDuration === SubscriptionDuration.Year}
            >
              <PeriodSwitchRadio
                id={SubscriptionDuration.Year}
                checked={subscriptionDuration === SubscriptionDuration.Year}
                defaultChecked
                type={'radio'}
                value={SubscriptionDuration.Year}
                name={'period'}
              />
              {t('annualSubscription')}
            </PeriodSwitchLabel>
          </PeriodSwitchItem>
          <PeriodSwitchItem>
            <PeriodSwitchLabel
              onClick={() => setSubscriptionDuration(SubscriptionDuration.Month)}
              checked={subscriptionDuration === SubscriptionDuration.Month}
            >
              <PeriodSwitchRadio
                id={SubscriptionDuration.Month}
                checked={subscriptionDuration === SubscriptionDuration.Month}
                type={'radio'}
                value={SubscriptionDuration.Month}
                name={'period'}
              />
              {t('monthlySubscription')}
            </PeriodSwitchLabel>
          </PeriodSwitchItem>
        </PeriodSwitch>
      </PeriodSwitchWrapper>
      <EditionsWrap>
        <Edition
          product={EDITION.FREE.name}
          price={findEditionPriceInfo('FREE', subscriptionDuration)?.price || 0}
          currency={t(`currency:${findEditionPriceInfo('BUSINESS', subscriptionDuration)?.currency || 'EUR'}`)}
          priceInfo={t('upTo15Respondents')}
          buttonText={t('basicEdition')}
          onClick={() => onClick('FREE')}
          buttonCallToAction={t('useForFree')}
          titleCallToAction={t('callToActionFree')}
          titleHighlights={t('highlightsTitleFree')}
          active={false}
          disabled={true}
        />
        <Edition
          product={EDITION.ESSENTIAL.name}
          save={calculateSave('ESSENTIAL', subscriptionDuration)}
          price={findEditionPriceInfo('ESSENTIAL', subscriptionDuration)?.price || 0}
          currency={t(`currency:${findEditionPriceInfo('BUSINESS', subscriptionDuration)?.currency || 'EUR'}`)}
          priceInfo={t('respondentPerMonth')}
          buttonText={t('choose')}
          onClick={() => onClick('ESSENTIAL')}
          buttonCallToAction={t('useTrial')}
          titleCallToAction={t('callToActionEssential')}
          titleHighlights={t('highlightsTitleEssential')}
          active={activeEdition === EDITION.ESSENTIAL.name}
          disabled={activeProduct ? activeProduct === EDITION.BUSINESS.name : false}
        />
        <Edition
          product={EDITION.BUSINESS.name}
          save={calculateSave('BUSINESS', subscriptionDuration)}
          price={findEditionPriceInfo('BUSINESS', subscriptionDuration)?.price || 0}
          currency={t(`currency:${findEditionPriceInfo('BUSINESS', subscriptionDuration)?.currency || 'EUR'}`)}
          priceInfo={t('respondentPerMonth')}
          buttonText={t('choose')}
          onClick={() => onClick('BUSINESS')}
          buttonCallToAction={t('useTrial')}
          titleCallToAction={t('callToActionBusiness')}
          titleHighlights={t('highlightsTitleBusiness')}
          active={activeEdition === EDITION.BUSINESS.name}
          disabled={false}
        />
      </EditionsWrap>
      <NoteSpan>{t('vat')}</NoteSpan>
      <br />
      <NoteSpan>{t('notes')}</NoteSpan>
      <NoteSpan>{t('note1')}</NoteSpan>
      <NoteSpan>
        <Trans
          i18nKey={'pricelist:noteAboutProductTerms'}
          components={[<a key="0" href={t('common:productTermsUrl')} target="_blank" rel="noreferrer" />]}
        >
          All Data and Reports in Arnold will be anonymized after 25 months from the collection of such Data. See our
          Product Terms for more information.
        </Trans>
      </NoteSpan>
      <CenteredHeadingWrap>
        <HeadingLarge margin={`${theme.spacing.l} 0 ${theme.spacing.h}`}>{t('compareEditions')}</HeadingLarge>
      </CenteredHeadingWrap>
      <Card>
        <HeaderRow>
          <Col sm="6" xl="6" />
          <CenteredCol sm="2" xl="2">
            {t(`editionSettings:label-${EDITION.FREE.name}`)}
          </CenteredCol>
          <CenteredCol sm="2" xl="2">
            {t(`editionSettings:label-${EDITION.ESSENTIAL.name}`)}
          </CenteredCol>
          <CenteredCol sm="2" xl="2">
            {t(`editionSettings:label-${EDITION.BUSINESS.name}`)}
          </CenteredCol>
        </HeaderRow>
        <SubHeaderRow>
          <Col sm="12">{t('topicContent')}</Col>
        </SubHeaderRow>
        <FeaturesRow
          featureName={t('topicsCount')}
          values={[`5 ${t('topicsGenitiv')}`, `35 ${t('topicsGenitiv')}`, `35 ${t('topicsGenitiv')}`]}
          first={true}
        />
        <FeaturesRow featureName={t('onboardingAndHiring')} values={[false, true, true]} first={false} />
        <FeaturesRow featureName={t('topicEditable')} values={[false, false, true]} first={false} />
        <FeaturesRow featureName={t('onbHiringTopicEditable')} values={[false, false, true]} first={false} />
        <FeaturesRow featureName={t('publicSurveys')} values={[false, false, true]} first={false} />
        <FeaturesRow featureName={t('methodologicalSupport')} values={[false, false, true]} first={false} />
        <FeaturesRow featureName={t('oneLanguageSurveys')} values={[true, true, true]} first={false} />
        <SubHeaderRow>
          <Col sm="12">{t('results')}</Col>
        </SubHeaderRow>
        <FeaturesRow featureName={t('unlimitedSurveys')} values={[true, true, true]} first={true} />
        <FeaturesRow featureName={t('unlimitedHistory')} values={[t('thirtyDaysHistory'), true, true]} first={false} />
        <FeaturesRow featureName={t('teamioIntegration')} values={[false, false, true]} first={false} />
        <SubHeaderRow>
          <Col sm="12">{t('customerSupport')}</Col>
        </SubHeaderRow>
        <FeaturesRow featureName={t('intercomSupport')} values={[false, true, true]} first={false} />
        <SubHeaderRow>
          <Col sm="12">{t('knowledgeBase')}</Col>
        </SubHeaderRow>
        <FeaturesRow featureName={t('arnoldUniversity')} values={[false, true, true]} first={false} />
        <FeaturesRow featureName={t('microlearning')} values={[false, true, true]} first={false} />
        <FeaturesRow featureName={t('individualMentoring')} values={[false, false, true]} first={false} />
      </Card>
    </div>
  );
};

export default Pricelist;
