import { BodyMedium, BodySmall, Box, IThemeProps, theme, useToast } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useState, FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getTimeStringForOffset, Offset } from '../../../lib/process';
import { GraySpan, HoverWrapper, SectionControls } from '../../../components/Common';
import { TopicGroupItemEditableContent } from '../../Process/TopicGroupItem/TopicGroupItemEditableContent';
import { TopicGroupQuery } from '../../../generated/hooks';

type StepInfoBoxProps = {
  start: Offset | null;
  reminder: Offset | null;
  secondReminder: Offset | null;
  thirdReminder: Offset | null;
  end: Offset | null;
  topicGroupId?: number;
  isNew: boolean;
  isEditable?: boolean;
  saveForm?: (offsets: { startOffsetMins: number; remindersOffsetMins: number[]; endOffsetMins: number }) => void;
  step?: NonNullable<TopicGroupQuery['topicGroup']['steps']>[0];
};

const StyledBoxContainer = styled.div`
  margin-bottom: 48px;
`;

const StyledBox = styled(Box)`
  margin-top: ${({ theme }: IThemeProps) => theme?.spacing.f};
  margin-bottom: ${theme.spacing.f};
  display: flex;
  flex-direction: column;
`;

const Row = styled.div``;

const ColTitle = styled(BodySmall)`
  color: ${({ theme }: IThemeProps) => theme?.colors.text.secondary};
  margin-bottom: ${({ theme }: IThemeProps) => theme?.spacing.d};
`;

const Col = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ColAfterCol = styled(Col)`
  padding-left: ${({ theme }: IThemeProps) => theme?.spacing.h};
  padding-bottom: ${({ theme }: IThemeProps) => theme?.spacing.d};
  border-left: ${theme.spacing.a} solid ${({ theme }: IThemeProps) => theme?.colors.borderSeparator.default};
`;

const ButtonContainer = styled.div`
  margin-top: -10px;
  padding-left: ${({ theme }: IThemeProps) => theme?.spacing.h};
`;

export const StepInfoBox: FC<StepInfoBoxProps> = ({
  start,
  reminder,
  secondReminder,
  thirdReminder,
  end,
  topicGroupId,
  isNew,
  isEditable,
  saveForm,
  step,
}) => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const history = useHistory();

  const { t } = useTranslation('processDetail');
  const { addToast } = useToast();

  const submitForm = async (offsets: {
    startOffsetMins: number;
    remindersOffsetMins: number[];
    endOffsetMins: number;
  }) => {
    try {
      if (saveForm) {
        await saveForm({
          startOffsetMins: offsets.startOffsetMins,
          remindersOffsetMins: offsets.remindersOffsetMins,
          endOffsetMins: offsets.endOffsetMins,
        });
      }
      if (isNew) {
        history.push(`/process/${topicGroupId}`);
        addToast(t('addTopicSuccess'));
      } else {
        setIsEdit(false);
      }
    } catch (err) {
      // TODO: handle error
    }
  };
  //   return Math.floor((minutesInterval * MINUTES_INTERVAL) / HOUR);
  // };

  // const getMinutes = (minutesInterval: number) => {
  //   return (minutesInterval * MINUTES_INTERVAL) % HOUR;
  // };
  // const base =
  //   !isNullish(startOffsetDays) && !isNullish(startOffsetMinutes)
  //     ? parseMinutesInOffset({
  //         days: startOffsetDays - 1,
  //         hours: getHours(startOffsetMinutes),
  //         minutes: getMinutes(startOffsetMinutes),
  //       })
  //     : null;

  // const isChanged = !(
  //   parseMinutesInOffset(start) === base &&
  //   hasFirstReminder === getIsReminderSet(start, reminder) &&
  //   (!hasFirstReminder ||
  //     (!isNullish(reminderOffsetDays) &&
  //       !isNullish(reminderOffsetMinutes) &&
  //       parseMinutesInOffset(reminder) ===
  //         parseMinutesInOffset({
  //           days: reminderOffsetDays,
  //           hours: getHours(reminderOffsetMinutes),
  //           minutes: getMinutes(reminderOffsetMinutes),
  //         }))) &&
  //   hasSecondReminder === getIsReminderSet(start, secondReminder) &&
  //   (!hasSecondReminder ||
  //     (!isNullish(secondReminderOffsetDays) &&
  //       !isNullish(secondReminderOffsetMinutes) &&
  //       parseMinutesInOffset(secondReminder) ===
  //         parseMinutesInOffset({
  //           days: secondReminderOffsetDays,
  //           hours: getHours(secondReminderOffsetMinutes),
  //           minutes: getMinutes(secondReminderOffsetMinutes),
  //         }))) &&
  //   !isNullish(endOffsetDays) &&
  //   !isNullish(endOffsetMinutes) &&
  //   parseMinutesInOffset(end) ===
  //     parseMinutesInOffset({
  //       days: endOffsetDays,
  //       hours: getHours(endOffsetMinutes),
  //       minutes: getMinutes(endOffsetMinutes),
  //     })
  // );

  return (
    <HoverWrapper>
      {!isEdit && !isNew && (
        <StyledBoxContainer>
          <StyledBox>
            <Row>
              <ColTitle>{t('start')}</ColTitle>
              <BodyMedium>
                <Trans i18nKey={'processDetail:day'} values={{ dayNo: start?.days }} components={[<GraySpan />]}>
                  Day 1
                </Trans>
                <GraySpan>,</GraySpan> {getTimeStringForOffset(start)}
              </BodyMedium>
            </Row>
            <Row>
              <ColAfterCol>
                <ColTitle>{t('noReminder', { count: 1 })}</ColTitle>
                <BodyMedium>
                  {reminder?.days || reminder?.minutes ? (
                    <>
                      {`+${reminder?.days} `}
                      <GraySpan>
                        {t('days', {
                          count: reminder?.days,
                          postProcess: 'interval',
                        })}
                        ,
                      </GraySpan>{' '}
                      {getTimeStringForOffset(reminder)}
                    </>
                  ) : (
                    '-'
                  )}
                </BodyMedium>
              </ColAfterCol>
            </Row>
            <ColAfterCol>
              <ColTitle>{t('noReminder', { count: 2 })}</ColTitle>
              <BodyMedium>
                {secondReminder?.days || secondReminder?.minutes ? (
                  <>
                    {`+${secondReminder?.days} `}
                    <GraySpan>
                      {t('days', {
                        count: secondReminder?.days,
                        postProcess: 'interval',
                      })}
                      ,
                    </GraySpan>{' '}
                    {getTimeStringForOffset(secondReminder)}
                  </>
                ) : (
                  '-'
                )}
              </BodyMedium>
            </ColAfterCol>
            <Row>
              <ColAfterCol>
                <ColTitle>{t('noReminder', { count: 3 })}</ColTitle>
                <BodyMedium>
                  {thirdReminder?.days || thirdReminder?.minutes ? (
                    <>
                      {`+${thirdReminder?.days} `}
                      <GraySpan>
                        {t('days', {
                          count: thirdReminder?.days,
                          postProcess: 'interval',
                        })}
                        ,
                      </GraySpan>{' '}
                      {getTimeStringForOffset(thirdReminder)}
                    </>
                  ) : (
                    '-'
                  )}
                </BodyMedium>
              </ColAfterCol>
              <Col>
                <ColTitle>{t('end')}</ColTitle>
                <BodyMedium>
                  {`+${end?.days} `}
                  <GraySpan>{t('days', { count: end?.days, postProcess: 'interval' })},</GraySpan>{' '}
                  {getTimeStringForOffset(end)}
                </BodyMedium>
              </Col>
            </Row>
          </StyledBox>
          {isEditable && (
            <SectionControls paddingLeft={'0'} visible={isEdit}>
              <ButtonContainer>
                <Button color="primary" onClick={() => setIsEdit(true)}>
                  {t('edit')}
                </Button>
              </ButtonContainer>
            </SectionControls>
          )}
        </StyledBoxContainer>
      )}
      {(isEdit || isNew) && (
        <StyledBoxContainer>
          <TopicGroupItemEditableContent
            step={step}
            onSave={submitForm}
            onCancel={() =>
              isNew ? (topicGroupId ? history.push(`/process/${topicGroupId}`) : history.goBack()) : setIsEdit(false)
            }
            loading={false}
            submitTitle={isNew ? t('addTopic') : t('save')}
          />
        </StyledBoxContainer>
      )}
    </HoverWrapper>
  );
};
