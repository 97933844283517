import styled from '@emotion/styled';
import { FC, ReactNode, useCallback, useState } from 'react';
import { Modal as BootstrapModal } from 'react-bootstrap';
import closeIcon from '../../assets/icons/Close.svg';
import { isFunction } from '../../helpers';
import { theme } from '../../theme';
import { HeadingMedium } from '../Typography';
import { CleanButton } from '../buttons';
import { CancelButton, SubmitButton } from './Buttons';
import { ModalButton } from './types';

export type ModalProps = {
  title?: string;
  text?: string | ReactNode;
  onClose?: () => void;
  onSubmit?: (() => void) | (() => Promise<void>);
  onCancel?: () => void;
  content?: ReactNode;
  buttons?: {
    cancel?: ModalButton;
    submit?: ModalButton;
  };
  onHide: () => void;
  show: boolean;
  underline?: ReactNode;
  hideAfterSubmit?: boolean;
  width?: number;
  hideCloseButton?: boolean;
};
export const Modal: FC<ModalProps> = ({
  title,
  text,
  onClose,
  onSubmit,
  onCancel,
  content,
  buttons,
  onHide,
  show,
  underline,
  width,
  hideAfterSubmit = true,
  hideCloseButton = false,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const onHideFunction = useCallback(() => {
    if (isFunction(onClose)) {
      onClose();
    }
    onHide();
  }, [onHide, onClose]);

  const onSubmitFunction = useCallback(async () => {
    try {
      if (isFunction(onSubmit)) {
        setIsLoading(true);
        // If the onSubmit function returns a Promise, wait for the result.
        await Promise.resolve(onSubmit());
        setIsLoading(false);
      }
      if (hideAfterSubmit) {
        onHide();
      }
    } catch (e) {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onHide, onSubmit]);

  const onCancelFunction = useCallback(() => {
    if (isFunction(onCancel)) {
      onCancel();
    }
    onHideFunction();
  }, [onHideFunction, onCancel]);

  return (
    <BootstrapModal show={show} onHide={onHideFunction}>
      <Container style={width ? { width } : {}}>
        {!hideCloseButton && (
          <CloseContainer>
            <CleanButton onClick={onHideFunction}>
              <img src={closeIcon} alt="close icon" />
            </CleanButton>
          </CloseContainer>
        )}
        <HeadingMedium>{title}</HeadingMedium>
        {text && <p>{text}</p>}
        {content}
        {buttons && (
          <>
            <Buttons>
              <SubmitButton
                onClick={onSubmitFunction}
                button={buttons.submit && { ...buttons.submit, disabled: buttons.submit?.disabled || isLoading }}
              />
              <CancelButton
                onClick={onCancelFunction}
                button={buttons.cancel && { ...buttons.cancel, disabled: buttons.cancel?.disabled || isLoading }}
              />
            </Buttons>
          </>
        )}
        {underline && (
          <>
            <hr className="mx-n8 my-8" />
            {underline}
          </>
        )}
      </Container>
    </BootstrapModal>
  );
};

const Container = styled.div`
  // top has different
  padding-top: ${theme.spacing.e};
  padding-right: ${theme.spacing.j};
  padding-left: ${theme.spacing.j};
  padding-bottom: ${theme.spacing.j};

  @media (min-width: ${theme.breakpoint.small}) {
    min-width: 450px;
  }
`;

const Buttons = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: ${theme.spacing.f};
`;

const CloseContainer = styled.div`
  position: absolute;
  right: ${theme.spacing.f};
  top: ${theme.spacing.f};
`;
