import { BaseArea, BaseAreaPlugin } from 'rete-area-plugin';
import { Accumulating, Selectable } from 'rete-area-plugin/_types/extensions/selectable';
import { NodeEditor, NodeId } from 'rete';
import { Schemes } from './dataNode';

export function customSelectableNodes<T>(
  base: BaseAreaPlugin<Schemes, T>,
  core: Selectable,
  options: { accumulating: Accumulating },
) {
  let editor: null | NodeEditor<Schemes> = null;
  const area = base as BaseAreaPlugin<Schemes, BaseArea<Schemes>>;
  const getEditor = () => editor || (editor = area.parentScope<NodeEditor<Schemes>>(NodeEditor));

  let twitch: null | number = 0;

  function selectNode(node: Schemes['Node']) {
    if (!node.selected) {
      node.selected = true;
      area.update('node', node.id);
    }
  }

  function unselectNode(node: Schemes['Node']) {
    if (node.selected) {
      node.selected = false;
      area.update('node', node.id);
    }
  }

  function add(nodeId: NodeId, accumulate: boolean) {
    const node = getEditor().getNode(nodeId);

    if (!node) return;

    core.add(
      {
        label: 'node',
        id: node.id,
        translate(dx, dy) {
          const view = area.nodeViews.get(node.id);
          const current = view?.position;

          if (current) {
            view.translate(current.x + dx, current.y + dy);
          }
        },
        unselect() {
          unselectNode(node);
        },
      },
      accumulate,
    );
    selectNode(node);
  }

  function remove(nodeId: NodeId) {
    core.remove({ id: nodeId, label: 'node' });
  }

  area.addPipe((context) => {
    if (!context || typeof context !== 'object' || !('type' in context)) return context;

    if (context.type === 'nodepicked') {
      const pickedId = context.data.id;
      const accumulate = options.accumulating.active();

      core.pick({ id: pickedId, label: 'node' });
      twitch = null;
      add(pickedId, accumulate);
    } else if (context.type === 'nodetranslated') {
      const { id, position, previous } = context.data;
      const dx = position.x - previous.x;
      const dy = position.y - previous.y;

      if (core.isPicked({ id, label: 'node' })) core.translate(dx, dy);
    } else if (context.type === 'pointerdown') {
      twitch = 0;
    } else if (context.type === 'pointermove') {
      if (twitch !== null) twitch++;
    } else if (context.type === 'pointerup') {
      if (twitch !== null && twitch < 4) {
        if (context.data.event.button !== 2) {
          // Do not deselect nodes if user right clicks
          core.unselectAll();
        }
      }
      twitch = null;
    }
    return context;
  });

  return {
    select: add,
    unselect: remove,
  };
}
