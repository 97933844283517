import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { theme, Tooltip } from '@arnold/common';

const GreySpan = styled.span`
  color: ${theme.colors.text.secondary};
`;

export interface IPopulationInfoProps {
  population?: number | null;
  answered?: number | null;
  recipientEmails?: string[] | null;
}

export const PopulationInfo: FC<IPopulationInfoProps> = ({ population, answered, recipientEmails }) => {
  const [t] = useTranslation('surveyOverviewRow');
  const validOpened = answered || 0;
  const baseTitle = `${t('population', { population })}${validOpened ? t('answered', { answered: validOpened }) : ''}`;
  const recipientsTitle =
    recipientEmails && recipientEmails.length
      ? `<br />${t('whitelist', {
          emails: recipientEmails.join(', '),
        })}`
      : '';
  return (
    <Tooltip title={`${baseTitle}${recipientsTitle}`}>
      {population && (
        <>
          {Math.round(population ? (validOpened / population) * 100 : 100)}%{' '}
          <GreySpan>
            ({validOpened}/{population})
          </GreySpan>
        </>
      )}
    </Tooltip>
  );
};
