import { ApolloProvider } from '@apollo/client';
import { theme, EnvironmentRibbon, getFrontendConfigValue, ToastProvider } from '@arnold/common';
import { ThemeProvider } from '@emotion/react/macro';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import { ScrollToTop } from './components';
import Modal from './components/Modals/ModalController';
import CookieConsent from './components/CookieConsent';
import apolloClient from './createApollo';
import ErrorBoundary from './ErrorBoundary';
import { allowedLanguages } from './lib/common';
import i18n from './translations/i18n';
import { Routes } from './Routes';
const App = () => {
  const languages = i18n.languages;
  const browserLang = languages[0];
  const lang = allowedLanguages.find((al) => al === browserLang.substring(0, 2)) || languages[1];
  i18n.changeLanguage(lang);

  return (
    <BrowserRouter>
      <EnvironmentRibbon env={getFrontendConfigValue('ENVIRONMENT')} />
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={theme}>
          <ToastProvider>
            <Modal>
              <ErrorBoundary>
                <CookieConsent />
                <ScrollToTop />
                <Routes />
              </ErrorBoundary>
            </Modal>
          </ToastProvider>
        </ThemeProvider>
      </ApolloProvider>
    </BrowserRouter>
  );
};

export default App;
