import { FormikErrors } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LinkButton, theme } from '@arnold/common';
import styled from '@emotion/styled';
import auth from '../../lib/auth';
import LoginForm from './LoginForm';
import OtpForm from './OtpForm';

interface IFormValues {
  email: string;
  password: string;
  responseError?: string;
  emailError?: string;
}

interface IProps {
  onSuccessFullSingIn: () => void;
  lang?: string;
}

interface ILocationState {
  otpEmail?: string;
  otpValidTo?: string;
  password?: string;
  username?: string;
}

export const LoginContainer = ({ onSuccessFullSingIn, lang }: IProps) => {
  const [t] = useTranslation('loginForm');
  const location = useLocation<ILocationState>();
  const [email, setEmail] = useState(location.state?.username || '');
  const [contactEmail, setContactEmail] = useState(location.state?.otpEmail || '');
  const [password, setPassword] = useState(location.state?.password || '');
  const [otpValidTo, setOtpValidTo] = useState(location.state?.otpValidTo || '');
  const [showOtpForm, setShowOtpForm] = useState(!!(location.state?.otpEmail && location.state?.otpValidTo));

  const handleSignIn = async (
    email: string,
    password: string,
    otp: string | undefined,
    setErrors: (errors: FormikErrors<IFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    setEmail(email);
    setPassword(password);
    try {
      setSubmitting(true);
      await auth.login(email, password, otp);
      onSuccessFullSingIn();
    } catch (e) {
      setSubmitting(false);
      let response;
      try {
        response = await (e as Response).json();
      } catch (err) {
        // Do nothing
      }
      if ((e as Response).status === 403) {
        setShowOtpForm(true);
        setContactEmail(response.email);
        setOtpValidTo(response.otpValidTo);
      }
      if (response?.confirmedEmail === false) {
        setErrors({
          emailError: t('emailNotConfirmed'),
        });
      } else {
        setErrors({
          responseError: otp
            ? (e as Response).status === 410
              ? t('expiredCode')
              : t('invalidCode')
            : (e as Response).status === 401
              ? t('badCredentials')
              : t('somethingWentWrong'),
        });
      }
    }
  };

  return (
    <>
      {showOtpForm ? (
        <OtpForm
          email={email}
          password={password}
          otpValidTo={otpValidTo}
          handleSignIn={handleSignIn}
          contactEmail={contactEmail}
          lang={lang}
          goBack={() => setShowOtpForm(false)}
        />
      ) : (
        <LoginForm lang={lang} handleSignIn={handleSignIn} />
      )}
      <SecurityNote />
    </>
  );
};

export default LoginContainer;

const SecurityNote = () => {
  const [t] = useTranslation('loginForm');
  const translation = t('2FaSecurityNote');
  const normalText = translation.split('[')[0];
  const linkText = translation.split('[')[1].split(']')[0];
  const link = translation.split('(')[1].split(')')[0];
  const normalText2 = translation.split(')')[1];

  return (
    <>
      <Line />
      <StyledTextDescription>
        {normalText}
        <LinkButton href={link} target="_blank">
          {linkText}
        </LinkButton>
        {normalText2}
      </StyledTextDescription>
    </>
  );
};

const StyledTextDescription = styled.div`
  color: ${theme.colors.text.secondary};
  & a {
    margin: 0 !important;
  }
`;

const Line = styled.hr`
  width: 100%;
  margin-top: ${theme.spacing.i};
  margin-bottom: ${theme.spacing.g};
  border-top: ${theme.spacing.a} solid ${theme.colors.borderSeparator.default};
`;
