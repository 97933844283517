// tslint:disable-next-line:ordered-imports
import { ENVIRONMENT } from '@arnold/core';
import '@lmc-eu/cookie-consent-manager/LmcCookieConsentManager.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-app-polyfill/ie11';
import './assets/scss/paper-dashboard.scss';
// tslint:disable-next-line:ordered-imports
import './assets/scss/main.scss';
import './index.css';
import './translations/i18n';
import { init } from '@sentry/browser';
import { getFrontendConfigValue } from '@arnold/common';
import { contains } from 'ramda';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';

// tslint:disable-next-line:no-var-requires
require('es7-object-polyfill');

const appEnvironment = getFrontendConfigValue('ENVIRONMENT');

const isSentryEnabled = contains(appEnvironment, [ENVIRONMENT.PROD]);

if (isSentryEnabled) {
  init({
    dsn: getFrontendConfigValue('ADMIN_SENTRY_DSN'),
    release: process.env.REACT_APP_DOCKER_VERSION,
    environment: appEnvironment,
  });
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
