export const saveFile = (name: string, contents: string, mimetype: string) => {
  const blob = new Blob([contents], {
    type: mimetype,
  });
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = window.URL.createObjectURL(blob);
  link.download = name;
  document.body.appendChild(link);
  link.click();
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  }, 0);
};
