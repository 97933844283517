import {
  ActionMenu,
  ActionMenuIcon,
  Breadcrumb,
  ConditionalWrapper,
  HeadingLarge,
  Tooltip,
  theme,
  usePath,
  useToast,
} from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import { ProductCode } from '@arnold/core';
import { Loading } from '../../components';
import { ActionButton, PageWrapper } from '../../components/Common';
import { ErrorPage } from '../../components/StyledComponents';
import { getTopicGroupTranslationByLanguageId } from '../../lib/common';
import {
  OrganizationQuery,
  TopicGroupTypeCode,
  UserQuery,
  UserSysRole,
  useCloneProcessTopicGroupMutation,
  useCreateProcessStepMutation,
  useCreateTopicGroupFromDefaultMutation,
  useDeleteTopicGroupMutation,
  useTopicGroupQuery,
  useUpsertTopicGroupTranslationMutation,
  useUserQuery,
} from '../../generated/hooks';
import { getAllowedConversationSubjects, getTopicGroup } from '../../graphql/queries';
import { EditTopicGroupInfoButton } from '../../components/GeneralProcessSurveyDetail/EditTopicGroupInfoButton';
import { EditTopicGroupInfoModal } from '../../components/TopicEditor/components/EditTopicGroupInfoModal';
import { NoTopicsInfo } from './NoTopicsInfo';
import { TopicGroupStep } from './TopicGroupItem/TopicGroupStep';

interface IProps extends RouteComponentProps<any> {
  user: UserQuery['user'];
  organization: OrganizationQuery['organization'];
}

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  padding-bottom: ${theme.spacing.d};
`;

const StyledButton = styled(Button)`
  margin-left: ${theme.spacing.f};
`;

const ProcessScreen = (props: IProps) => {
  const {
    match: { params },
  } = props;
  const { t, i18n } = useTranslation('processDetail');
  const { t: topicT } = useTranslation('topicOverview');

  const [newTopicGroupLoading, setNewTopicGroupLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToast();
  const { loading: userLoading, data: userData, error: userError } = useUserQuery();
  const {
    loading: topicGroupLoading,
    error,
    data: currentData,
    previousData,
  } = useTopicGroupQuery({
    variables: { topicGroupId: params.topicGroupId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });
  const data = currentData || previousData;
  const history = useHistory();
  const location = useLocation();

  const [isCreateTopicGroupModalOpen, setCreateTopicGroupModalOpen] = useState(false);

  const [updateTopicGroup] = useUpsertTopicGroupTranslationMutation();
  const [createTopicGroup] = useCreateTopicGroupFromDefaultMutation();
  const [createProcessTopicGroup] = useCreateProcessStepMutation();
  const { createOnboardingPath, createProcessSurveyPath } = usePath();

  const [cloneProcessTopicGroup] = useCloneProcessTopicGroupMutation();
  const [deleteTopicGroupMutation] = useDeleteTopicGroupMutation();

  if (error || userError) {
    return <ErrorPage>{t('common:loadError')}</ErrorPage>;
  }
  if (loading || (topicGroupLoading && !data) || userLoading || !data || !userData || newTopicGroupLoading) {
    return <Loading />;
  }

  const { organization } = userData.user;

  if (!organization) {
    return null;
  }
  const languageId = organization.primaryLanguageId.toString(10);

  const isCustom = data.topicGroup.organization?.id === organization.id;
  const isEditable = isCustom || userData.user.systemRole === UserSysRole.SysAdmin;

  const topicGroupTranslation = getTopicGroupTranslationByLanguageId(languageId, data.topicGroup.translations);

  const handleClone = async () => {
    setLoading && setLoading(true);
    await cloneProcessTopicGroup({
      variables: {
        id: data.topicGroup.id!,
      },
      onCompleted: (data) => {
        addToast(topicT('copyCreated'));
        setLoading && setLoading(false);
        history.push(`/process/${data.cloneProcessTopicGroup?.id}`);
      },
      onError: () => {
        setLoading && setLoading(false);
      },
    });
  };

  const handleDelete = async () => {
    setLoading && setLoading(true);
    await deleteTopicGroupMutation({
      variables: {
        id: data.topicGroup.id!,
      },
      onCompleted: () => {
        setLoading && setLoading(false);
        history.push('/topics');
      },
      onError: () => {
        setLoading && setLoading(false);
      },
    });
  };

  const handleSave = async ({ name, description }: { name: string; description: string }) => {
    setNewTopicGroupLoading(true);
    createTopicGroup({
      variables: {
        organizationId: props.organization?.id!,
        name,
        description,
        typeCode:
          data.topicGroup.typeCode === TopicGroupTypeCode.Onboarding ? TopicGroupTypeCode.Onboarding : undefined,
      },
      refetchQueries: [
        {
          query: getAllowedConversationSubjects,
          variables: { languageCode: i18n.language },
        },
      ],
      onCompleted: (data) => {
        createProcessTopicGroup({
          variables: {
            input: {
              processTopicGroupId: params.topicGroupId!,
              stepTopicGroupId: data.createTopicGroupFromDefault?.id!,
            },
          },
          onCompleted: (data) => {
            addToast(t('topicCreatedToast'));
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('editing', data.createProcessStep.stepTopicGroup.lastValidTopic?.id!);
            history.push({
              pathname: location.pathname,
              search: searchParams.toString(),
            });
            setNewTopicGroupLoading(false);
          },
          refetchQueries: [
            {
              query: getTopicGroup,
              variables: { topicGroupId: params.topicGroupId },
            },
          ],
          awaitRefetchQueries: true,
        });
      },
    });
  };

  const handleUpdateInfo = (values: { name: string; description: string; language?: string }) => {
    updateTopicGroup({
      variables: {
        value: values.name,
        description: values.description,
        language: values.language!,
        topicGroupId: params.topicGroupId!,
      },
      refetchQueries: [{ query: getTopicGroup, variables: { topicGroupId: params.topicGroupId } }],
    });
  };

  const title = topicGroupTranslation ? topicGroupTranslation.value : data.topicGroup.name;
  const description = topicGroupTranslation ? topicGroupTranslation.description : data.topicGroup.description;
  const actionMenuItems = [
    {
      label: topicT('copy'),
      action: handleClone,
    },
    {
      label: topicT('remove'),
      action: handleDelete,
    },
  ];

  const freeOrgWithCustomTopic = isCustom && userData.user.organization?.productVersion?.product === ProductCode.Free;

  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb
        classList={'mt-n6'}
        items={[
          { title: t('teamsScreen:surveys'), link: '/overview' },
          { title: t('ReportAccess:topics'), link: '/topics' },
          {
            title,
            active: true,
          },
        ]}
      />
      <HeadingLarge data-cy="process-title">{title}</HeadingLarge>
      <p data-cy="process-description">{description}</p>
      <ButtonContainer>
        <ConditionalWrapper
          condition={freeOrgWithCustomTopic}
          wrapper={(children) => (
            <Tooltip title={t('surveysOverview:freeOrganizationsCannotPlanCustomTopics')}>{children}</Tooltip>
          )}
        >
          <StyledButton
            variant="primary"
            data-cy={`admin-schedule-topic-button-${data.topicGroup.id}`}
            data-icom={'btn-conversation-subject-schedule'}
            disabled={freeOrgWithCustomTopic}
            onClick={() => {
              data.topicGroup.typeCode === TopicGroupTypeCode.Onboarding
                ? createOnboardingPath.toFunc(data.topicGroup.id)
                : createProcessSurveyPath.toFunc(data.topicGroup.id);
            }}
          >
            {topicT('planTopic')}
          </StyledButton>
        </ConditionalWrapper>
        {isEditable && (
          <EditTopicGroupInfoButton
            onSave={handleUpdateInfo}
            name={title}
            modalTitle={t('editInfoModalTitle')}
            description={description!}
            language={topicGroupTranslation?.language.code!}
            languages={props.organization?.languages.map((l) => l.code)!}
            type={data.topicGroup.typeCode === TopicGroupTypeCode.Onboarding ? 'onboarding' : 'processSurvey'}
          />
        )}
        {isCustom && (
          <ActionMenu toggleIcon={<ActionMenuIcon />} items={actionMenuItems} dataIcom={'btn-dropdown'} inline />
        )}
        {!isCustom && (
          <Button variant="outline-primary" onClick={() => handleClone()}>
            {t('copyAndEdit')}
          </Button>
        )}
      </ButtonContainer>
      {!data.topicGroup.steps?.length ? (
        <NoTopicsInfo />
      ) : (
        data.topicGroup.steps
          .slice()
          .map((step) => (
            <TopicGroupStep
              step={step}
              key={step.id}
              organization={props.organization!}
              topicId={step.stepTopicGroup.lastValidTopic?.id}
              topicGroupId={params.topicGroupId as string}
              isEditable={isEditable}
            />
          ))
      )}
      {isEditable && (
        <ConditionalWrapper
          condition={freeOrgWithCustomTopic}
          wrapper={(children) => (
            <Tooltip title={t('surveysOverview:freeOrganizationsCannotPlanCustomTopics')}>{children}</Tooltip>
          )}
        >
          <div>
            <ActionButton
              variant="outline-primary"
              disabled={loading || freeOrgWithCustomTopic}
              type="submit"
              margin={`${theme.spacing.f} 0 0 ${theme.spacing.h}`}
              onClick={() => props.history.push(`/process/${params.topicGroupId}/step/add`)}
              data-cy="process-add-topic"
            >
              {t('addTopic')}
            </ActionButton>

            <ActionButton
              variant="outline-primary"
              disabled={loading || freeOrgWithCustomTopic}
              data-cy="process-create-new-topic-btn"
              type="submit"
              margin={`${theme.spacing.f} 0 0 ${theme.spacing.h}`}
              onClick={() => setCreateTopicGroupModalOpen(true)}
            >
              {t('createNewTopic')}
            </ActionButton>
          </div>
        </ConditionalWrapper>
      )}
      {isCreateTopicGroupModalOpen && (
        <EditTopicGroupInfoModal
          isModalOpen
          setModalOpen={setCreateTopicGroupModalOpen}
          name={undefined}
          description=""
          onSave={handleSave}
          type="survey"
        />
      )}
    </PageWrapper>
  );
};

export default ProcessScreen;
