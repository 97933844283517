import {
  BodySmall,
  CardBody,
  HeadingSmall,
  LinkButton,
  Modal,
  selectMenuStyles,
  SelectWrapper,
  theme,
  useToast,
  Tooltip,
  Checkbox,
} from '@arnold/common';
import { ReactComponent as CloseIcon } from '@arnold/common/lib/assets/icons/Close.svg';
import styled from '@emotion/styled';
import { ReactNode, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { Table } from '../../components/Table/Table';
import {
  ContactSource,
  ContactType,
  OrganizationSettingQuery,
  useDeleteRespondentMutation,
  UserOrgRole,
  UserSysRole,
  useSetUserMutation,
  useUpdateRespondentMutation,
} from '../../generated/hooks';
import { getOrganizationSettings } from '../../graphql/queries';

const RemoveButton = styled(LinkButton)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  &:disabled svg path {
    fill: ${theme.colors.actionPrimary.disabled};
  }
`;
const AddRow = styled.div`
  display: flex;
  margin-top: ${theme.spacing.g};

  & > div {
    min-width: 300px;
  }
`;
const RemoveButtonText = styled.span`
  align-self: flex-start;
  margin-left: ${theme.spacing.f};
`;

type OrgAdmin = {
  id: string;
  name: string;
  email: string;
  actions: ReactNode;
};

type AdminRespondent = NonNullable<OrganizationSettingQuery['organization']>['organizationAdmins'][0];
type Respondent = NonNullable<OrganizationSettingQuery['organization']>['allRespondents'][0];

interface IProps {
  organizationAdmins: AdminRespondent[];
  allRespondents: Respondent[];
  organizationId: string;
  userId: string;
}

export const OrganizationAdminsCard = ({ organizationAdmins, organizationId, userId, allRespondents }: IProps) => {
  const { t } = useTranslation('organizationAdmins');
  const { addToast } = useToast();
  const [adminToRemove, setAdminToRemove] = useState<AdminRespondent | null>(null);
  const [respondentToAdd, setRespondentToAdd] = useState<{ label: string; value: string; email?: string } | null>(null);
  const [showAddAdminModal, setShowAddAdminModal] = useState<boolean>(false);
  const [removeAlsoRespondent, setRemoveAlsoRespondent] = useState<boolean>(false);

  const refetchQueries = [
    {
      query: getOrganizationSettings,
      variables: {
        organizationId,
      },
    },
  ];
  const removeMutationSettings = {
    onCompleted: () => {
      addToast(t('removeAdminToastSuccess'));
      setRemoveAlsoRespondent(false);
    },
    onError: () => {
      addToast(t('removeAdminToastError'));
    },
    refetchQueries,
  };
  const [updateRespondentMutation, { loading: updateRespondentLoading }] =
    useUpdateRespondentMutation(removeMutationSettings);
  const [deleteRespondentMutation, { loading: deleteRespondentLoading }] =
    useDeleteRespondentMutation(removeMutationSettings);
  const [setUserMutation, { loading: setUserLoading }] = useSetUserMutation({
    onCompleted: () => {
      addToast(t('addAdminToastSuccess'));
      setRespondentToAdd(null);
    },
    onError: () => {
      addToast(t('addAdminToastError'));
    },
    refetchQueries,
  });
  const adminsIds = organizationAdmins.map((a) => a.id);
  const reportRecipients = allRespondents
    .filter((respondent) => !adminsIds.includes(respondent.id))
    .map((respondent) => {
      const email = respondent.contacts?.find(
        (contact) => contact.type === ContactType.Email && contact.source === ContactSource.Primary,
      )?.value;
      return {
        label: `${respondent.firstname} ${respondent.surname} (${email})`,
        email,
        value: respondent.id.toString(),
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  return (
    <>
      <Modal
        show={!!adminToRemove}
        onHide={() => setAdminToRemove(null)}
        onSubmit={() => {
          if (adminToRemove) {
            if (removeAlsoRespondent) {
              return deleteRespondentMutation({
                variables: {
                  respondentId: adminToRemove.id,
                },
              });
            }
            return updateRespondentMutation({
              variables: {
                id: adminToRemove.id,
                input: {
                  organizationRole: UserOrgRole.OrgRespondent,
                },
              },
            });
          }
        }}
        title={t('deleteDialogTitle')}
        content={
          <>
            <p>
              <Trans
                i18nKey={'organizationAdmins:deleteDialogDescription'}
                values={{ email: adminToRemove?.user?.username }}
                components={[<strong />]}
              />
            </p>
            <div className="ml-4 mb-8">
              <Checkbox
                selected={removeAlsoRespondent}
                onToggle={() => setRemoveAlsoRespondent((prevHasReminder) => !prevHasReminder)}
                text={t('deleteCheckbox')}
              />
            </div>
          </>
        }
        buttons={{
          submit: { title: t('deleteBtnText') },
          cancel: { title: t('cancel') },
        }}
      />
      <Modal
        show={showAddAdminModal}
        onHide={() => setShowAddAdminModal(false)}
        onSubmit={() => {
          if (respondentToAdd?.email) {
            return setUserMutation({
              variables: {
                input: {
                  organizationId,
                  email: respondentToAdd.email,
                  orgRole: UserOrgRole.OrgAdmin,
                  sysRole: UserSysRole.SysUser,
                },
              },
            });
          }
        }}
        title={t('addDialogTitle')}
        content={
          <>
            <p>
              <Trans
                i18nKey={'organizationAdmins:addDialogDescription'}
                values={{
                  email: respondentToAdd?.email,
                }}
                components={[<strong />]}
              />
            </p>
          </>
        }
        buttons={{
          submit: { title: t('addDialogButton') },
          cancel: { title: t('cancel') },
        }}
      />
      <Card data-cy="settings-admins-card">
        <CardBody>
          <HeadingSmall>{t('settings:organizationAdmins')}</HeadingSmall>
          <BodySmall margin={`${theme.spacing.c} 0 ${theme.spacing.f} 0`}>{t('description')}</BodySmall>
          <Table<OrgAdmin>
            rowDataCypressTestFlag={'org-admin-item'}
            columns={[
              {
                key: 'name',
                title: t('teamsScreen:name'),
                dataIndex: 'name',
              },
              {
                key: 'email',
                title: t('teamsScreen:email'),
                dataIndex: 'email',
              },
              {
                key: 'actions',
                title: undefined,
                dataIndex: 'actions',
              },
            ]}
            dataSources={organizationAdmins.map((admin) => ({
              ...admin,
              name: `${admin.firstname} ${admin.surname}`,
              email: admin.user?.username ?? '',
              actions: (
                <Tooltip
                  title={admin.user?.id === userId ? t('deleteSelfTooltip') : ''}
                  disabled={admin.user?.id !== userId}
                >
                  <RemoveButton
                    margin={'0'}
                    onClick={() => setAdminToRemove(admin)}
                    disabled={
                      updateRespondentLoading ||
                      deleteRespondentLoading ||
                      organizationAdmins.length < 2 ||
                      admin.user?.id === userId
                    }
                  >
                    <CloseIcon />
                    <RemoveButtonText>{t('remove')}</RemoveButtonText>
                  </RemoveButton>
                </Tooltip>
              ),
            }))}
            selected={undefined}
          />
          <AddRow>
            <SelectWrapper
              value={respondentToAdd}
              options={reportRecipients}
              placeholder={t('selectEmployee')}
              onChange={setRespondentToAdd}
              menuPlacement="auto"
              styles={{
                menu: (provided: any, state: any) => ({
                  ...provided,
                  ...selectMenuStyles,
                }),
              }}
            />
            <Button
              className="ml-8 mt-0 mb-0"
              data-cy="admin-add-org-odmin"
              disabled={!respondentToAdd || setUserLoading}
              onClick={() => {
                setShowAddAdminModal(true);
              }}
              variant="outline-primary"
            >
              {t('addAdmin')}
            </Button>
          </AddRow>
        </CardBody>
      </Card>
    </>
  );
};

export default OrganizationAdminsCard;
