import {
  calculateValidPeriod,
  createEventData,
  getFrontendConfigValue,
  logOutIntercomUser,
  pushToDataLayer,
  updateIntercomUser,
  useToast,
} from '@arnold/common';
import { ENVIRONMENT, ProductCode } from '@arnold/core';
import { ComponentType, FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router';
import {
  AccessTokenStatus,
  IsAccessTokenValidQuery,
  UserOrgRole,
  useIsAccessTokenValidQuery,
  useOrganizationQuery,
} from '../generated/hooks';
import auth, { USER_KEY, User } from '../lib/auth';
import { getUserHash } from '../lib/common';
import ee from '../lib/eventEmitter';
import { getOrganizationName, setLoggedUser } from '../lib/helpers';
import storage from '../lib/storage';
import AuthorizedPage from './AuthorizedPage/AuthorizedPage';
import Loading from './Loading';
import { OrganizationProvider } from './providers/OrganizationProvider';

type Props = {
  user: User;
  search?: string;
  leftNavbarOnly?: boolean;
  hideSidebar?: boolean;
  isWithoutPadding?: boolean;
  Component: ComponentType<any>;
};

let limitExceededAlreadyDisplayed = false;

export const AuthorizedPageLoader: FC<Props> = ({ user, search, leftNavbarOnly, hideSidebar, Component, ...props }) => {
  const { i18n, t } = useTranslation('toast');
  const { addToast } = useToast();
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const handleError = () => {
    logOutIntercomUser();
  };

  const handleSuccess = (responseData: IsAccessTokenValidQuery) => {
    if (responseData.isAccessTokenValid) {
      if (responseData.isAccessTokenValid.status !== AccessTokenStatus.Valid) {
        localStorage.removeItem(USER_KEY);
        ee.emitAccessTokenValidationModal(responseData.isAccessTokenValid.status || '');
      }

      if (!responseData.isAccessTokenValid.consent) {
        localStorage.removeItem(USER_KEY);
        ee.emitAccessTokenValidationModal(AccessTokenStatus.ConsentNotFound);
      }
    }
  };

  const { data, loading, error } = useIsAccessTokenValidQuery({
    variables: {
      accessToken: user.accessToken,
    },
    onCompleted: handleSuccess,
    onError: handleError,
  });

  const {
    data: organizationData,
    loading: organizationLoading,
    error: organizationError,
    refetch: refetchOrganizationQuery,
  } = useOrganizationQuery();

  useEffect(() => {
    setLoggedUser(data?.isAccessTokenValid.user);
    return () => {
      setLoggedUser(undefined);
    };
  }, [data?.isAccessTokenValid.user]);

  useEffect(() => {
    pushToDataLayer({ userId: data?.isAccessTokenValid.user?.id, orgId: organizationData?.organization?.id });
  }, [location, data?.isAccessTokenValid.user?.id, organizationData?.organization?.id]);

  useEffect(() => {
    const user = data?.isAccessTokenValid.user;
    const organization = organizationData?.organization;
    if (user && organization) {
      if (!storage.getDetectedLanguage()) {
        storage.setDetectedLanguage(organization.primaryLanguageCode);
        i18n.changeLanguage(organization.primaryLanguageCode);
      }
      const validPeriod =
        organization?.productVersionHistory && calculateValidPeriod(organization.productVersionHistory);

      const satismeter = (window as any).satismeter;
      if (
        getFrontendConfigValue('ENVIRONMENT') === ENVIRONMENT.PROD &&
        satismeter &&
        validPeriod?.validFrom &&
        validPeriod.validTo
      ) {
        const validFrom = new Date(validPeriod.validFrom);
        const validTo = new Date(validPeriod.validTo);
        const midDate = new Date((validFrom.getTime() + validTo.getTime()) / 2);
        satismeter({
          writeKey: getFrontendConfigValue('SATISMETER_WRITE_KEY'),
          userId: user.id,
          traits: {
            name: `${user.firstname} ${user.surname}`,
            contractHalf: midDate.toISOString(),
            contractValidFrom: validFrom.toISOString(),
            contractValidTo: validTo.toISOString(),
            createdAt: user.createdAt,
            plan: validPeriod.product,
            isPaidPlan: validPeriod.product !== ProductCode.Free,
            role: UserOrgRole.OrgAdmin,
            organizationName: organization.name,
            organizationId: organization.id,
            email: user.username,
          },
          language: i18n.language,
        });
      }

      updateIntercomUser({
        user_id: user.id,
        user_hash: getUserHash(),
        name: `${user.firstname} ${user.surname}`,
        email: user.username,
        language: i18n.language,
        language_override: i18n.language,
        'Organization role': UserOrgRole.OrgAdmin,
        company: organization
          ? {
              id: organization.id,
              name: organization.name,
              'System name': organization.systemName,
              plan: validPeriod?.product,
              'Primary language': organization.primaryLanguageCode,
              'All languages': organization.languages.map(({ code }) => code).join(', '),
              'Company ID': organization.companyId,
              'Licence count': validPeriod?.licenceCount,
              'Valid from': validPeriod?.validFrom,
              'Valid to': validPeriod?.validTo,
            }
          : {},
      });
      // Test simplesat for NPS
      const evt = new CustomEvent('UserLoggedIn', {
        detail: { name: `${user.firstname} ${user.surname}`, email: user.username },
      });
      window.dispatchEvent(evt);
      if (location?.search) {
        pushToDataLayer({
          userId: user.id,
          event: 'ux.admin-access-with-token',
          ...createEventData('user', 'admin-access-with-token', 'admin-access-with-token'),
        });
      }
      const limitExceededSnackbarShowed =
        !limitExceededAlreadyDisplayed &&
        validPeriod?.licenceCount &&
        validPeriod.product !== ProductCode.Teamio_Arnold &&
        organization.activeRespondentsInCurrentPeriodCount &&
        organization.activeRespondentsInCurrentPeriodCount > validPeriod.licenceCount;

      if (limitExceededSnackbarShowed) {
        limitExceededAlreadyDisplayed = true;
        addToast(t('toast:respondentLimitExceeded'), 7000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, organizationData]);

  if (loading || organizationLoading) {
    return <Loading />;
  }
  if (
    error ||
    organizationError ||
    data?.isAccessTokenValid.status !== AccessTokenStatus.Valid ||
    !data?.isAccessTokenValid.consent
  ) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }
  if (data && organizationData) {
    auth.saveExpireAt(data.isAccessTokenValid.expireAt);
    const organization = organizationData.organization;
    let orgName = '';
    const validPeriod = organization?.productVersionHistory && calculateValidPeriod(organization.productVersionHistory);
    const organizationLicence = {
      validFrom: validPeriod?.validFrom ? new Date(validPeriod.validFrom) : new Date(),
      validTo: validPeriod?.validTo ? new Date(validPeriod.validTo) : new Date(),
    };
    const existsFollowingSubscription =
      (organizationLicence.validTo &&
        organization?.productVersionHistory?.some(
          (subscription) => new Date(subscription.validFrom).getTime() >= organizationLicence.validTo.getTime(),
        )) ||
      false;
    if (organization && organization.config) {
      orgName = getOrganizationName(organization.systemName, organization.name);
    }
    return (
      <AuthorizedPage
        organizationName={orgName}
        userId={user.id || data.isAccessTokenValid.user?.id ? Number(data.isAccessTokenValid.user?.id) : undefined}
        organizationId={organization?.id}
        organizationLicence={{
          ...organizationLicence,
          existsFollowingSubscription,
          product: validPeriod?.product,
        }}
        allowedMoreOrganizations={data.isAccessTokenValid.user?.allowedMoreOrganizations || false}
        product={validPeriod?.product}
        hasProcessSurveys={organizationData?.organization?.hasProcessSurveys || false}
        leftNavbarOnly={leftNavbarOnly}
        hideSidebar={hideSidebar}
      >
        <OrganizationProvider organization={organization} refetch={refetchOrganizationQuery}>
          <Component
            {...props}
            user={data.isAccessTokenValid.user}
            organization={organizationData.organization}
            history={history}
            location={location}
            match={match}
          />
        </OrganizationProvider>
      </AuthorizedPage>
    );
  }

  return null;
};
