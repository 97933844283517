import { BodyMedium, BoxWithImage, HeadingMedium, theme } from '@arnold/common';
import { ReactComponent as EmptySurveys } from '@arnold/common/src/assets/images/EmptySurveys.svg';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router';

interface Props {
  translationNamespace?: string;
  title?: React.ReactNode;
  text?: React.ReactNode;
  buttonText?: React.ReactNode;
  navigateTo?: string;
  dataCy?: string;
}

export const NoSurveysInfoBase: FC<Props> = ({ translationNamespace, title, text, buttonText, navigateTo, dataCy }) => {
  const [t] = useTranslation(translationNamespace);
  const history = useHistory();

  return (
    <BoxWithImage image={<EmptySurveys />}>
      <div data-cy={dataCy}>
        <HeadingMedium margin="12.5px 0 8px">{title || t('noSurveyInfoTitle')}</HeadingMedium>
        <BodyMedium>{text || t('noSurveyInfoText')}</BodyMedium>
        <div style={{ marginTop: `${theme.spacing.f}` }}>
          {(buttonText || translationNamespace) && navigateTo && (
            <Button variant="outline-primary" onClick={() => history.push(navigateTo)}>
              {buttonText || t('noSurveyInfoButton')}
            </Button>
          )}
        </div>
      </div>
    </BoxWithImage>
  );
};
