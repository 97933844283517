import {
  ActionMenu,
  BodyMedium,
  ConditionalWrapper,
  HeadingLarge,
  Tabs,
  Tooltip,
  cleanArray,
  getFrontendConfigValue,
  theme,
  usePath,
  useToast,
} from '@arnold/common';
import { ReactComponent as ActionMenuIcon } from '@arnold/common/lib/assets/icons/ActionMenuIcon.svg';
import { ProductCode } from '@arnold/core';
import styled from '@emotion/styled';
import { ReactNode, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useParams } from 'react-router-dom';
import { GraphQLErrorCard } from '../../components';
import { PageWrapper } from '../../components/Common';
import { InfoBlock } from '../../components/Common/InfoBlock';
import Loading from '../../components/Loading';
import { SequenceStepsTable } from '../../components/SequenceStepsTable';
import { RedText, SurveyDetailContent, SurveyScreenHeading } from '../../components/StyledComponents';
import {
  OrganizationQuery,
  ProcessStatus,
  SurveyGroupWithoutRespondentsQuery,
  SurveyGroupsQuery,
  TopicGroupTypeCode,
  useRenameSurveyGroupMutation,
  useSetSurveyGroupArchivedMutation,
  useSurveyGroupWithoutRespondentsLazyQuery,
  useDeleteSurveyGroupMutation,
  IsAccessTokenValidQuery,
} from '../../generated/hooks';
import { EDITION, handleSummaryResultsClick } from '../../lib/common';
import { isLoggedUserSysAdmin } from '../../lib/helpers';
import { getShortenedTitle } from '../../lib/helpers/surveyGroup';
import { useGetSurveyGroups } from '../../screens/Surveys/utils';
import i18n from '../../translations/i18n';
import { getSurveyGroups } from '../../graphql/queries';
import ee from '../../lib/eventEmitter';
import { EmptySurveyGroupScreen } from './EmptySurveyGroupScreen';
import { ProcessSurveyParticipants } from './ProcessSurveyPatricipants/ProcessSurveyParticipants';
import { ProcessSurveyResultsSettings } from './ProcessSurveyResultsSettings';
import { RenameProcessSurveyModal } from './RenameProcessSurveyModal';

interface IProps extends RouteComponentProps {
  organization: NonNullable<OrganizationQuery['organization']>;
  typeCode?: TopicGroupTypeCode;
  user: NonNullable<IsAccessTokenValidQuery['isAccessTokenValid']['user']>;
}

const OnboardingInfo = styled.div`
  display: flex;
  margin: ${theme.spacing.f} 0;
  width: 100%;
  white-space: nowrap;
  justify-content: space-between;
`;

const UpperTabsWrap = styled.div`
  margin-bottom: ${theme.spacing.f};
`;

const PATHS = {
  RESPONDENTS: 'respondents',
  RESULTS: 'results',
  TOPICS: 'topics',
};

export const GeneralProcessSurveyDetailScreen = ({ organization, history, typeCode, location, user }: IProps) => {
  const [t] = useTranslation('onboarding');
  const [surveyGroupT] = useTranslation('surveyGroup');
  const [surveyT] = useTranslation('survey');
  const { onboardingPath, processSurveyDetailPath, exitPath, conversationSubjectsPath, processSurveysPath } = usePath();
  const [isModalOpen, setModalOpen] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [filteredSGByTypeIds, setFileredSGByTypeIds] = useState<string[]>([]);
  const { surveyGroupId, type } = useParams<{ surveyGroupId: string; type: string }>();
  const { addToast } = useToast();
  const { surveyArchivePath } = usePath();

  const { surveyGroups, error: sgError, loading: sgLoading } = useGetSurveyGroups();
  const tabType = Object.values(PATHS).includes(type) ? type : PATHS.RESPONDENTS;

  const isOnboarding = typeCode === TopicGroupTypeCode.Onboarding;
  const isExit = typeCode === TopicGroupTypeCode.Exit;
  const isProcessSurvey = !isOnboarding && !isExit;

  const filteredSGByType = surveyGroups?.filter(
    (sg) =>
      ((isOnboarding && sg.topicGroup.typeCode === TopicGroupTypeCode.Onboarding) ||
        (isExit && sg.topicGroup.typeCode === TopicGroupTypeCode.Exit) ||
        (isProcessSurvey &&
          (!sg.topicGroup.typeCode ||
            ![TopicGroupTypeCode.Onboarding, TopicGroupTypeCode.Exit].includes(sg.topicGroup.typeCode) ||
            (!sg.topicGroup.typeCode &&
              ![TopicGroupTypeCode.Onboarding, TopicGroupTypeCode.Exit].includes(sg.topicGroup.typeCode))))) &&
      sg.status !== ProcessStatus.Archived &&
      sg.status !== ProcessStatus.Anonymized,
  );
  useEffect(() => {
    setFileredSGByTypeIds(filteredSGByType?.map((sg) => sg.id) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filteredSGByType)]);

  const handleMutationCompleted = () => {
    addToast(t('archiveSurveySuccess'));
    history.push(surveyArchivePath.createPath());
  };

  const handleMutationError = () => {
    addToast(t('archiveSurveyFailed'));
  };

  const [getDetail, { loading, data, error }] = useSurveyGroupWithoutRespondentsLazyQuery({
    fetchPolicy: 'cache-first',
  });
  const [renameSurveyGroup] = useRenameSurveyGroupMutation();
  const [setSurveyGroupArchived] = useSetSurveyGroupArchivedMutation({
    onCompleted: handleMutationCompleted,
    onError: handleMutationError,
  });
  const [deleteSurveyGroup] = useDeleteSurveyGroupMutation();

  const selectedSGIndex = filteredSGByTypeIds.indexOf(data?.surveyGroup?.id!);
  const sortedSGIds = [...filteredSGByTypeIds];
  if (selectedSGIndex > 2) {
    sortedSGIds.splice(2, 0, sortedSGIds.splice(selectedSGIndex, 1)[0]);
  }

  const handleSubmit = async (name: string, surveyGroupId: string) => {
    try {
      const result = await renameSurveyGroup({
        variables: {
          name,
          surveyGroupId,
        },
      });

      if (result.data?.renameSurveyGroup.id) {
        setModalOpen(false);
      }
    } catch (error) {
      // do nothing
    }
  };
  useEffect(() => {
    // Load only if sgId is different or data are not available
    if (
      (surveyGroupId && surveyGroupId !== data?.surveyGroup?.id) ||
      (!surveyGroupId && filteredSGByTypeIds.length && !data && !loading)
    ) {
      getDetail({
        variables: {
          surveyGroupId:
            surveyGroupId || filteredSGByTypeIds.sort((sg1, sg2) => parseInt(sg2, 10) - parseInt(sg1, 10))[0],
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyGroupId, JSON.stringify(filteredSGByTypeIds), data, loading]);

  if (error || sgError) {
    return <GraphQLErrorCard />;
  }

  if ((filteredSGByTypeIds.length && (loading || !data || pageLoading)) || sgLoading) {
    return <Loading />;
  }

  if (!data?.surveyGroup || filteredSGByTypeIds.length === 0) {
    return <EmptySurveyGroupScreen organization={organization} typeCode={typeCode} />;
  }

  const getTGTranslation = (
    sg:
      | NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]
      | NonNullable<SurveyGroupWithoutRespondentsQuery['surveyGroup']>,
  ) =>
    sg.topicGroup.translations?.find((translation) => translation.language.code === i18n.language) ||
    sg.topicGroup.translations?.find(
      (translation) => translation.language.code === organization?.primaryLanguageCode,
    ) ||
    (sg.topicGroup.translations && sg.topicGroup.translations.length > 0 ? sg.topicGroup.translations[0] : undefined);
  const headingTranslation = getTGTranslation(data.surveyGroup);

  const topLevelReportAccess = data.surveyGroup.topLevelReport;

  const surveyGroupName = data.surveyGroup.name || headingTranslation?.value;

  const sgId = surveyGroupId || data.surveyGroup.id;

  const handleArchive = async () => {
    setPageLoading(true);
    await setSurveyGroupArchived({
      variables: {
        surveyGroupId: sgId,
        reverse: false,
      },
    });
    setPageLoading(false);
  };

  const handleDearchive = async () => {
    setPageLoading(true);
    await setSurveyGroupArchived({
      variables: {
        surveyGroupId: sgId,
        reverse: true,
      },
    });
    setPageLoading(false);
  };

  const handleChangePage = (page: number) => {
    history.push(`${location.pathname}?page=${page}`);
  };

  const handleCreateNewSG = (path: string) => window.open(path, '_self');

  const handleDeleteSG = async (surveyGroupId: string, allIds: string[]) => {
    setPageLoading(true);
    await deleteSurveyGroup({
      variables: {
        surveyGroupId,
      },
      refetchQueries: [
        {
          query: getSurveyGroups,
        },
      ],
      onCompleted: () => {
        history.push(getLinkToSurveys(isOnboarding, isExit, allIds.filter((id) => id !== surveyGroupId)[0]));
      },
    });
    setPageLoading(false);
  };

  const actionMenuItems = cleanArray([
    data.surveyGroup.status !== ProcessStatus.Archived && {
      label: t('surveyGroup:rename'),
      action: () => setModalOpen(true),
      dataIcom: 'btn-survey-rename',
    },
    data.surveyGroup.status === ProcessStatus.Completed && {
      label: t('archive'),
      action: handleArchive,
    },
    data.surveyGroup.status === ProcessStatus.Archived && {
      label: t('surveyOverviewRow:dearchive'),
      action: handleDearchive,
    },
    data.surveyGroup.status !== ProcessStatus.Archived &&
      organization?.productVersion?.product !== EDITION.FREE.name &&
      isOnboarding && {
        label: t('scheduleNewOnboarding'),
        action: () => handleCreateNewSG(conversationSubjectsPath.createPath(TopicGroupTypeCode.Onboarding)),
      },
    data.surveyGroup.status !== ProcessStatus.Archived &&
      organization?.productVersion?.product &&
      ![EDITION.FREE.name, EDITION.TEAMIO_ARNOLD.name].includes(organization?.productVersion?.product) &&
      isExit && {
        label: surveyGroupT('scheduleNewExit'),
        action: () => handleCreateNewSG(conversationSubjectsPath.createPath(TopicGroupTypeCode.Exit)),
      },
    data.surveyGroup.status === ProcessStatus.Prepared && {
      label: surveyGroupT('delete'),
      action: () => ee.emitModalDeleteSurveyGroup(() => handleDeleteSG(sgId, filteredSGByTypeIds)),
    },
  ]);

  const getLinkToDetail = (id: string, isOnboarding: boolean, isExit: boolean, type?: string) => {
    if (isOnboarding) {
      return onboardingPath.createPath(id, type);
    }

    if (isExit) {
      return exitPath.createPath(id, type);
    }

    return processSurveyDetailPath.createPath(id, type);
  };

  const getLinkToSurveys = (isOnboarding: boolean, isExit: boolean, id?: string) => {
    if (isOnboarding) {
      return onboardingPath.createPath(id);
    }

    if (isExit) {
      return exitPath.createPath(id);
    }

    return processSurveysPath.createPath();
  };

  const dataIcomPrefix = isOnboarding ? 'onboarding' : isExit ? 'exit' : 'process-survey';

  const numberOfUsedParticipants = organization.activeRespondentsInCurrentPeriodCount ?? 0;
  const numberOfMaxParticipants = organization.productVersion?.licenceCount ?? 0;

  return (
    <PageWrapper data-cy="admin-page-content">
      <SurveyScreenHeading>
        <div style={{ width: '100%' }}>
          <HeadingLarge data-icom={`heading-${isOnboarding ? 'onboarding' : isExit ? 'exit' : 'process-survey'}`}>
            {headingTranslation?.value}
          </HeadingLarge>
          {organization.productVersion?.product === ProductCode.Teamio_Arnold && (
            <BodyMedium margin={`${theme.spacing.e} 0 ${theme.spacing.f}`} className="w-100">
              <ConditionalWrapper
                condition={numberOfUsedParticipants >= numberOfMaxParticipants}
                wrapper={(children: ReactNode) => (
                  <RedText>
                    {children} {t('teamioOnb:usageExceeded')}
                  </RedText>
                )}
              >
                <Trans
                  i18nKey={`teamioOnb:usageInfo`}
                  values={{ total: numberOfMaxParticipants, used: numberOfUsedParticipants }}
                  components={[<strong />]}
                />
              </ConditionalWrapper>{' '}
              {numberOfMaxParticipants - numberOfUsedParticipants <= 5 && (
                <Trans i18nKey={`teamioOnb:orderMore`} components={[<a href={'/settings/order'} />]} />
              )}
            </BodyMedium>
          )}
          {data.surveyGroup.status !== ProcessStatus.Archived &&
          (isOnboarding || isExit) &&
          filteredSGByTypeIds.length &&
          filteredSGByTypeIds.length > 1 ? (
            <UpperTabsWrap data-icom={`${dataIcomPrefix}-tabs`}>
              <Tabs
                items={cleanArray([
                  ...sortedSGIds.slice(0, 3).map((id) => {
                    const surveyGroup = surveyGroups!.find((sg) => sg.id === id);
                    return {
                      title: getShortenedTitle(
                        surveyGroup ? surveyGroup.name || getTGTranslation(surveyGroup)?.value! : '',
                      ),
                      link: getLinkToDetail(id, isOnboarding, isExit),
                      active: data.surveyGroup?.id === id,
                    };
                  }),
                  sortedSGIds.length > 3 && {
                    title: (
                      <ActionMenu
                        toggleIcon={<ActionMenuIcon />}
                        items={sortedSGIds.slice(3).map((id) => {
                          const surveyGroup = surveyGroups!.find((sg) => sg.id === id);
                          return {
                            label: getShortenedTitle(
                              surveyGroup ? surveyGroup.name || getTGTranslation(surveyGroup)?.value! : '',
                            ),
                            action: () => history.push(getLinkToDetail(id, isOnboarding, isExit)),
                          };
                        })}
                      />
                    ),
                  },
                ])}
                theme={theme}
              />
            </UpperTabsWrap>
          ) : (
            <></>
          )}
          <BodyMedium>{(headingTranslation as any)?.description!}</BodyMedium>
          <OnboardingInfo>
            <InfoBlock
              dataIcom={`${dataIcomPrefix}-status`}
              title={t('state')}
              value={
                isOnboarding ? t(`status-${data.surveyGroup.status}`) : surveyT(`status-${data.surveyGroup.status}`)
              }
            />
            <InfoBlock
              dataIcom={`${dataIcomPrefix}-name`}
              title={isOnboarding ? t('surveyGroupName') : surveyGroupT('surveyGroupName')}
              value={<Tooltip title={surveyGroupName}>{getShortenedTitle(surveyGroupName || '')}</Tooltip>}
            />
            <ButtonContainer>
              {actionMenuItems.length > 0 && (
                <ActionMenu
                  toggleIcon={<ActionMenuIcon />}
                  items={actionMenuItems}
                  margin={`0 ${theme.spacing.f} 0 0`}
                  dataIcom={'btn-dropdown'}
                />
              )}
              {data.surveyGroup.status !== ProcessStatus.Archived && (
                <Button
                  variant={'primary'}
                  href={`/editSurveyGroup/${data.surveyGroup.id}`}
                  data-icom="btn-add-participants"
                  data-cy="btn-add-participants"
                >
                  {t('addParticipants')}
                </Button>
              )}
              <RenameProcessSurveyModal
                isModalOpen={isModalOpen}
                setModalOpen={setModalOpen}
                handleSubmit={handleSubmit}
                surveyGroupName={surveyGroupName}
                surveyGroupId={sgId}
                isOnboarding={isOnboarding}
              />
              {topLevelReportAccess && (
                <Button
                  variant={'primary'}
                  href={`${getFrontendConfigValue('ARNOLD_URL')}/report-sequence/${topLevelReportAccess.accessKey}${
                    isLoggedUserSysAdmin() ? '?sysAdmin' : ''
                  }`}
                  target={'_blank'}
                  data-icom={'btn-show-results'}
                  onClick={() => {
                    handleSummaryResultsClick(user.id, organization.id);
                  }}
                >
                  {t('results')}
                </Button>
              )}
            </ButtonContainer>
          </OnboardingInfo>
        </div>
      </SurveyScreenHeading>

      {data.surveyGroup.status !== ProcessStatus.Archived && (
        <Tabs
          items={[
            {
              title: t('participants'),
              link: getLinkToDetail(sgId, isOnboarding, isExit, PATHS.RESPONDENTS),
              active: tabType === PATHS.RESPONDENTS,
              icomTag: `tab-${dataIcomPrefix}-participants`,
            },
            {
              title: isOnboarding ? t('topics') : surveyGroupT('topics'),
              link: getLinkToDetail(sgId, isOnboarding, isExit, PATHS.TOPICS),
              active: tabType === PATHS.TOPICS,
              icomTag: `tab-${dataIcomPrefix}-topics`,
            },
            {
              title: t('reportSettings'),
              link: getLinkToDetail(sgId, isOnboarding, isExit, PATHS.RESULTS),
              active: tabType === PATHS.RESULTS,
              icomTag: `tab-${dataIcomPrefix}-report-settings`,
            },
          ]}
          theme={theme}
        />
      )}
      <SurveyDetailContent>
        {tabType === PATHS.RESPONDENTS && (
          <ProcessSurveyParticipants
            topLevelReportAccess={topLevelReportAccess ? topLevelReportAccess.accessKey : undefined}
            isOnboarding={isOnboarding}
            surveyGroup={data.surveyGroup}
            onChangePage={handleChangePage}
            organization={organization}
          />
        )}
        {tabType === PATHS.RESULTS && <ProcessSurveyResultsSettings surveyGroupId={data.surveyGroup.id} />}

        {tabType === PATHS.TOPICS && (
          <SequenceStepsTable
            surveyGroupId={parseInt(surveyGroupId, 10)}
            topicGroupId={Number.parseInt(data.surveyGroup.topicGroup.id, 10)}
            isOnboarding={isOnboarding}
          />
        )}
      </SurveyDetailContent>
    </PageWrapper>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;
