import { theme } from '@arnold/common';
import { ReactComponent as GrabHandle } from '@arnold/common/lib/assets/icons/GrabHandle.svg';
import styled from '@emotion/styled';
import classNames from 'classnames';
import type { Identifier, XYCoord } from 'dnd-core';
import { useRef } from 'react';
import { DragPreviewImage, useDrag, useDrop } from 'react-dnd';

export const ItemTypes = {
  OPTION: 'option',
};

const transparent1x1Pixel =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAA+gAAABkCAQAAAC/MNJRAAABWklEQVR42u3VQREAAAzCsOHf9HTAJRL6aQ4AqBcJAMDQAQBDBwAMHQAwdAAwdADA0AEAQwcADB0ADB0AMHQAwNABAEMHAEMHAAwdADB0AMDQAcDQAQBDBwAMHQAwdAAwdADA0AEAQwcADB0ADB0AMHQAwNABAEMHAEMHAAwdADB0AMDQAcDQAQBDBwAMHQAwdAAwdADA0AEAQwcADB0ADB0AMHQAwNABAEMHAAwdAAwdADB0AMDQAQBDBwBDBwAMHQAwdADA0AHA0AEAQwcADB0AMHQAMHQAwNABAEMHAAwdAAwdADB0AMDQAQBDBwBDBwAMHQAwdADA0AHA0AEAQwcADB0AMHQAMHQAwNABAEMHAAwdAAwdADB0AMDQAQBDBwBDBwAMHQAwdADA0AHA0CUAAEMHAAwdADB0AMDQAcDQAQBDBwAMHQAwdAAwdADA0AEAQwcADB0AFj1HsQBlME/e2wAAAABJRU5ErkJggg==';

export interface DraggableProps {
  id: string;
  children: React.ReactNode;
  index: number;
  readOnly: boolean;
  isSomethingDragged: boolean;
  move: (dragIndex: number, hoverIndex: number) => void;
}

interface DragItem {
  index: number;
  id: string;
  type: string;
}

const DraggableContainer = styled.div`
  & .dragHandle {
    cursor: move;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  & .dragHandlePlaceholder {
    width: ${theme.spacing.c};
  }
  &:not(.isSomethingElseDragged):hover > .dragHandle,
  &.isDragged .dragHandle {
    opacity: 1;
  }
  & .dragHandle > svg {
    cursor: move;
  }
  &:not(.isSomethingElseDragged) .dragHandle:hover > svg path,
  &.isDragged .dragHandle > svg path {
    fill: ${theme.colors.actionPrimary.default};
  }
  &.isDragged .optionControl {
    border: 1px solid ${theme.colors.actionPrimary.default};
    box-shadow: 0px 10px 30px -20px rgba(0, 0, 0, 0.2);

    box-shadow: 0px 0px 10px -3px #707070;
  }
`;

export const Draggable = ({ id, children, index, move, readOnly, isSomethingDragged }: DraggableProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: ItemTypes.OPTION,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },

    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      move(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.OPTION,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <>
      <DragPreviewImage connect={preview} src={transparent1x1Pixel} />

      <DraggableContainer
        className={classNames(
          'd-flex align-items-center flex-grow-1',
          isDragging && 'isDragged',
          isSomethingDragged && 'isSomethingElseDragged',
        )}
        data-handler-id={handlerId}
        ref={ref}
      >
        {!readOnly ? (
          <span className="dragHandle">
            <GrabHandle />
          </span>
        ) : (
          <span className="dragHandlePlaceholder" />
        )}
        {children}
      </DraggableContainer>
    </>
  );
};
