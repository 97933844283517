import styled from '@emotion/styled';
import { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { csFlag, otherFlag, plFlag, skFlag } from '../../assets/images/languageFlags';
import { theme } from '../../theme';

const Flag = styled.img`
  max-width: 22px;
  max-height: 22px;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 17px;
  margin-top: 9px;
`;

const mapping = [
  {
    value: '420',
    flag: csFlag,
    isoCode: 'cs',
  },
  {
    value: '48',
    flag: plFlag,
    isoCode: 'pl',
  },
  {
    value: '421',
    flag: skFlag,
    isoCode: 'sk',
  },
];

const Wrapper = styled.div`
  position: relative;
`;

const Plus = styled.div`
  position: absolute;
  top: 0;
  left: 54px;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${theme.colors.text.secondary};
`;

const CustomFormControl = styled(FormControl)`
  padding-left: 71px;

  &:focus {
    border: 1px solid ${theme.colors.actionPrimary.active};
    border-radius: 6px;
    box-shadow: none;
  }
`;

const AlloverWrapper = styled.div`
  position: relative;
`;

const Autocomplete = styled.div`
  position: absolute;
  z-index: 123;
  top: 37.5px;
  left: 0;
  background: ${theme.colors.backgroundCover.default};
  box-shadow: 0px 5px 30px -20px rgb(0, 0, 0, 50%);
  border-radius: 6px;
  width: 100%;
`;

const AutocompleteFlag = styled.img`
  max-width: 22px;
  max-height: 22px;
`;

const AutocompleteItem = styled.div`
  display: flex;
  padding: ${theme.spacing.f};
  color: ${theme.colors.text.secondary};
  cursor: pointer;
  $: hover,
  $: focus {
    background: ${theme.colors.backgroundCover.hover};
  }
`;

const AutocompleteCountry = styled.span`
  margin: 0 ${theme.spacing.f};
  color: ${theme.colors.text.primary};
`;

const CallingCodeInput = ({ callingCode, onChange, isInvalid }: any) => {
  const { t } = useTranslation('countries');
  const value = mapping.find((lang) => lang.value === callingCode);
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);

  const offers = mapping.filter((item) => item.value.startsWith(callingCode));

  return (
    <AlloverWrapper>
      {autocompleteOpen && (
        <Autocomplete>
          {offers.map((offer) => (
            <AutocompleteItem
              onClick={() => {
                onChange(offer.value);
                setAutocompleteOpen(false);
              }}
              onMouseDown={(e) => e.preventDefault()}
              key={offer.value}
            >
              <AutocompleteFlag src={offer.flag} /> <AutocompleteCountry>{t(offer.isoCode)}</AutocompleteCountry> +{' '}
              {offer.value}
            </AutocompleteItem>
          ))}
          {(offers.length === 0 || callingCode.length < 3) && (
            <AutocompleteItem>
              <AutocompleteFlag src={otherFlag} /> <AutocompleteCountry>{t('other')}</AutocompleteCountry>
            </AutocompleteItem>
          )}
        </Autocomplete>
      )}
      <Wrapper>
        <Flag src={value?.flag || otherFlag} />
        <Plus>+</Plus>
        <CustomFormControl
          type="text"
          autoComplete="none"
          name="callingCode"
          value={callingCode}
          onChange={(event) => {
            onChange(event.target.value);
            setAutocompleteOpen(true);
          }}
          onFocus={() => {
            setAutocompleteOpen(true);
          }}
          onBlur={() => {
            setAutocompleteOpen(false);
            setTimeout(() => null, 200);
          }}
          isInvalid={isInvalid}
        />
      </Wrapper>
    </AlloverWrapper>
  );
};

export default CallingCodeInput;
