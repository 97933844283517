import { theme } from '@arnold/common';
import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoBlock } from '../../components/Common/InfoBlock';
import { ReportData } from './types';

type ReportDetailProps = {
  rowData: ReportData;
};

const ReportDetailRow = styled.div`
  display: flex;
  gap: ${theme.spacing.j};
`;

export const ReportAccessDetail: FC<ReportDetailProps> = ({ rowData }) => {
  const [t] = useTranslation('ReportAccess');
  return (
    <div>
      <ReportDetailRow>
        <InfoBlock title={t('surveyInReport')} value={rowData.topicName} />
        <InfoBlock title={t('surveyStart')} value={rowData.sendAt} />
        {rowData.responseRate && <InfoBlock title={t('responseRate')} value={rowData.responseRate} />}
      </ReportDetailRow>
      <InfoBlock title={t('teams')} value={rowData.teams} />
      <InfoBlock title={t('reportRecipient')} value={rowData.recipients} dataIcom={'reports-recipients'} />
    </div>
  );
};
