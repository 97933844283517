import { Field, Form, Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';
import { CardBody, FormSelect, getFrontendConfigValue, Option, REGEXP_PHONE_NUMBER } from '@arnold/common';
import { Button, FormControl } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useState } from 'react';
import { ENVIRONMENT } from '@arnold/core';
import { ContactType, usePublicTopicGroupsQuery, useSendPreviewConversationMutation } from '../../generated/hooks';
import i18n from '../../translations/i18n';
import { GraphQLErrorCard, Loading } from '../../components';
import { Card, FormErrorFeedback, FormGroupLabel, FormRadioInput } from '../../components/Common';
import { languages } from './constants';
import { StyledFormGroup, Notification } from './styles';

export const PreviewChatForm = () => {
  const [t] = useTranslation('preview');
  const showCaptcha = ![ENVIRONMENT.LOCAL, ENVIRONMENT.DEVEL].includes(getFrontendConfigValue('ENVIRONMENT'));
  const [isConfirmed, setIsConfirmed] = useState(!showCaptcha);
  const [isSent, setIsSent] = useState(false);
  const {
    loading: topicGroupLoading,
    error,
    data,
  } = usePublicTopicGroupsQuery({
    variables: {
      language: i18n.language,
      product: 'PREVIEW',
    },
  });

  const [sendPreviewConversationMutation, { loading }] = useSendPreviewConversationMutation();

  const initialValues = {
    name: '',
    topicGroup: data?.publicTopicGroups[0]?.id,
    language: languages[0],
    contactType: ContactType.Sms,
    phone: '+420',
    email: '',
  };

  const PreviewChatFormValidationSchema = Yup.object().shape({
    name: Yup.string().required(t('enterName')),
    email: Yup.string().when('contactType', {
      is: ContactType.Email,
      then: Yup.string().email(t('registration:enterValidEmail')).required(t('registration:enterValidEmail')),
    }),
    phone: Yup.string().when('contactType', {
      is: ContactType.Sms,
      then: Yup.string()
        .matches(REGEXP_PHONE_NUMBER, t('registration:enterValidPhoneNumber'))
        .required(t('registration:enterValidPhoneNumber')),
    }),
  });

  const handleSubmit = async (values: FormikValues) => {
    sendPreviewConversationMutation({
      variables: {
        organizationName: values.name,
        topicGroupId: values.topicGroup,
        languageCode: values.language,
        contactType: values.contactType,
        contactValue: values.contactType === ContactType.Sms ? values.phone : values.email,
      },
    }).then(() => {
      values = initialValues;
      setIsSent(true);
    });
  };

  if (loading || topicGroupLoading) {
    return <Loading />;
  }

  if (error) {
    return <GraphQLErrorCard />;
  }

  if (isSent) {
    return (
      <Notification>
        <p>{t('converstionSent')}</p>
      </Notification>
    );
  }

  return (
    <Formik initialValues={initialValues} validationSchema={PreviewChatFormValidationSchema} onSubmit={handleSubmit}>
      {({ values, errors, handleSubmit, handleChange, handleBlur, setFieldValue, isSubmitting, dirty }) => (
        <Form onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <StyledFormGroup>
                <FormGroupLabel>{t('name')}</FormGroupLabel>
                <FormControl
                  type="text"
                  name="name"
                  value={values.name}
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                  }}
                  isInvalid={!!errors.name}
                />
                {errors.name && <FormErrorFeedback error={errors.name} />}
              </StyledFormGroup>
              <StyledFormGroup>
                <FormGroupLabel>{t('topicGroup')}</FormGroupLabel>
                <Field
                  component={FormSelect}
                  name="topicGroup"
                  options={data?.publicTopicGroups.map((topicGroup) => ({
                    value: topicGroup?.id,
                    label: topicGroup?.name,
                  }))}
                  defaultValue={values.topicGroup}
                  onChange={(option: Option) => {
                    setFieldValue('topicGroup', option.value);
                  }}
                  onBlur={handleBlur}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <FormGroupLabel>{t('language')}</FormGroupLabel>
                {languages.map((language) => (
                  <FormRadioInput
                    name="language"
                    value={values.language}
                    clicked={() => {
                      setFieldValue('language', language);
                    }}
                    changed={handleChange}
                    onBlur={handleBlur}
                    defaultChecked={values.language === language}
                    label={t(`languageSelector:${language}`)}
                  />
                ))}
              </StyledFormGroup>
              <StyledFormGroup>
                <FormGroupLabel>{t('sendSurveyTo')}</FormGroupLabel>
                <FormRadioInput
                  name="contactType"
                  value={ContactType.Sms}
                  clicked={() => {
                    setFieldValue('contactType', ContactType.Sms);
                  }}
                  changed={handleChange}
                  onBlur={handleBlur}
                  defaultChecked={values.contactType === ContactType.Sms}
                  label={t('teamsScreen:phone')}
                  dataCy="radio-contactTypeSms"
                />
                <FormRadioInput
                  name="contactType"
                  value={ContactType.Email}
                  clicked={() => {
                    setFieldValue('contactType', ContactType.Email);
                  }}
                  changed={handleChange}
                  onBlur={handleBlur}
                  defaultChecked={values.contactType === ContactType.Email}
                  label={t('teamsScreen:email')}
                  dataCy="radio-contactTypeEmail"
                />
              </StyledFormGroup>
              {values.contactType === ContactType.Sms && (
                <StyledFormGroup>
                  <FormGroupLabel>{t('phone')}</FormGroupLabel>
                  <FormControl
                    type="text"
                    name="phone"
                    value={values.phone || undefined}
                    onChange={(e) => {
                      setFieldValue('phone', e.target.value);
                    }}
                    isInvalid={!!errors.phone}
                    formNoValidate={values.contactType !== ContactType.Sms}
                  />
                  {errors.phone && <FormErrorFeedback error={errors.phone} />}
                </StyledFormGroup>
              )}

              {values.contactType === ContactType.Email && (
                <StyledFormGroup>
                  <FormGroupLabel>{t('email')}</FormGroupLabel>
                  <FormControl
                    type="text"
                    name="email"
                    value={values.email || undefined}
                    onChange={(e) => {
                      setFieldValue('email', e.target.value);
                    }}
                    isInvalid={!!errors.email}
                    formNoValidate={values.contactType !== ContactType.Email}
                  />
                  {errors.email && <FormErrorFeedback error={errors.email} />}
                </StyledFormGroup>
              )}
              {showCaptcha && (
                <ReCAPTCHA
                  sitekey={getFrontendConfigValue('RECAPTCHA_SITE_KEY')}
                  onChange={() => setIsConfirmed(true)}
                  onExpired={() => setIsConfirmed(false)}
                />
              )}
              <div>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={!isEmpty(errors) || isSubmitting || !isConfirmed || !dirty}
                >
                  {t('send')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};
