import { HeadingLarge, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { GraphQLErrorCard, Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import { IsAccessTokenValidQuery, ProcessStatus, TopicGroupTypeCode, useUserQuery } from '../../generated/hooks';
import i18n from '../../translations/i18n';
import { ProcessSurveysTable } from './ProcessSurveysTable';

type ProcessSurveysScreenProps = {
  user: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
};

export const ProcessSurveysScreen: FC<ProcessSurveysScreenProps> = ({ user }) => {
  const { t } = useTranslation('surveyGroup');

  const { data, loading, error } = useUserQuery({ variables: { languageCode: i18n.language } });

  const processSurveys = (
    data?.user.organization?.surveyGroups.filter(
      (surveyGroup) =>
        surveyGroup.topicGroup.typeCode !== TopicGroupTypeCode.Onboarding &&
        surveyGroup.status !== ProcessStatus.Archived,
    ) || []
  ).sort((sg1, sg2) => (sg2.from == null ? -1 : sg2.from?.localeCompare(sg1.from)));

  if (loading || !data?.user?.organization) {
    return <Loading />;
  }
  if (error) {
    return <GraphQLErrorCard />;
  }
  return (
    <PageWrapper data-cy="admin-page-content">
      <HeadingLarge data-icom={'heading-process-surveys'}>{t('processSurveysTitle')}</HeadingLarge>
      <Button variant={'primary'} href={`/processSurveyConversationSubjects`} data-icom={'btn-create-process-survey'}>
        {t('scheduleSurvey')}
      </Button>
      <Content>
        {processSurveys.length > 0 && (
          <ProcessSurveysTable organization={data?.user?.organization} surveyGroups={processSurveys} />
        )}
      </Content>
    </PageWrapper>
  );
};

const Content = styled.div`
  padding-top: ${theme.spacing.h};
`;
