import { PlainButton, theme } from '@arnold/common';
import { ReactComponent as Close } from '@arnold/common/lib/assets/icons/Close.svg';
import { ReactComponent as Settings } from '@arnold/common/lib/assets/icons/Settings.svg';
import styled from '@emotion/styled';
import * as React from 'react';
import { ClassicPreset } from 'rete';
import ee from '../../../lib/eventEmitter';

export class OptionControlData extends ClassicPreset.Control {
  constructor(
    public initialValue: string,
    public index: number,
    public key: string,
    public severity: number,
    public onChange: (value: string) => void,
    public onDelete: (saveState?: boolean) => void,
    public onSeverityChange: (value: number) => void,
    public onMove: (from: number, to: number) => void,
    public readOnly = false,
  ) {
    super();
  }
  initialValueChangedIndicator?: number;
}

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border: ${({ focused }: { focused: boolean }) =>
    `1px solid ${focused ? theme.colors.borderMain.active : theme.colors.backgroundBasic.default}`};
  border-radius: 5px !important;
  background-color: ${theme.colors.backgroundBasic.default};

  ${({ focused }: { focused: boolean }) => (focused ? `box-shadow: 0 0 0 0.2rem rgba(4, 183, 239, 0.25);` : '')};

  position: relative;

  & .textAreaSpan {
    flex: 1;
    background-color: transparent !important;
    outline: none;
    padding: 0 !important;
    min-height: 15px;
    margin: ${theme.spacing.d} ${theme.spacing.d} ${theme.spacing.d} 14px !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
  }
`;

const StyledPlainButton = styled(PlainButton)`
  background-color: transparent;
  padding: ${theme.spacing.b};
  width: ${theme.spacing.f};
  height: ${theme.spacing.f};
  margin-right: ${theme.spacing.d};

  & path {
    fill: ${theme.colors.iconText.secondary};
  }

  &:hover path {
    fill: ${theme.colors.iconAction.primary};
  }
`;

const SeverityIndicator = styled.div`
  height: 100%;
  background: ${({ severity }: { severity: number }) =>
    severity === 1
      ? theme.colors.emotionWarning.default
      : severity > 1
        ? theme.colors.emotionDanger.default
        : 'transparent'};
  position: absolute;
  width: 6px;
  border-radius: 4px 0px 0px 4px;
`;

export const OptionControl = (props: { data: OptionControlData }) => {
  const [value, setValue] = React.useState(props.data.initialValue);
  const [severity, setSeverity] = React.useState(props.data.severity);
  const [focused, setFocused] = React.useState(false);

  React.useEffect(() => {
    if (value === props.data.initialValue) {
      setValue(props.data.initialValue + ' ');
      setTimeout(() => {
        setValue(props.data.initialValue);
      }, 0);
    } else {
      setValue(props.data.initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data.initialValue, props.data.initialValueChangedIndicator]);

  React.useEffect(() => {
    setSeverity(props.data.severity);
  }, [props.data.severity, props.data.initialValueChangedIndicator]);

  return (
    <OptionContainer
      focused={focused}
      className="optionControl"
      data-icom="btn-option-ariel"
      data-cy="btn-option-ariel"
    >
      <SeverityIndicator severity={severity} />
      <span
        className="textAreaSpan"
        contentEditable={!props.data.readOnly}
        data-cy="option-txt-input"
        suppressContentEditableWarning={true}
        role="textbox"
        onInput={(e) => {
          const val = (e.target as HTMLElement).innerText;
          props.data.onChange(val);
        }}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        onPaste={(e) => {
          e.preventDefault();
          const text = e.clipboardData.getData('text/plain');
          document.execCommand('insertHTML', false, text);
        }}
      >
        {value}
      </span>
      {!props.data.readOnly && (
        <>
          <StyledPlainButton
            className="ml-4 settingsOptionButton"
            data-icom={'btn-option-settings-ariel'}
            data-cy="btn-option-settings-ariel"
            onClick={() => {
              ee.emitModalChoiceSettings(severity, (newChoiceSeverity) => {
                setSeverity(newChoiceSeverity);
                props.data.onSeverityChange(newChoiceSeverity);
              });
            }}
          >
            <Settings />
          </StyledPlainButton>
          <StyledPlainButton
            onClick={() => props.data.onDelete()}
            data-icom={'btn-remove-option-ariel'}
            data-cy="btn-remove-option-ariel"
            className="deleteOptionButton"
          >
            <Close />
          </StyledPlainButton>
        </>
      )}
    </OptionContainer>
  );
};
