export const MENU_TERMS = {
  TELL: 'tellTitle',
  FREETEXT: 'freetextTitle',
  SELECT: 'selectTitle',
  MULTISELECT: 'multiselectTitle',
  PERSONAL_MULTISELECT: 'personalMultiselectTitle',
  COMMENT: 'commentTitle',
};

export const ITEMS_WITH_TOOLTIP = [
  MENU_TERMS.TELL,
  MENU_TERMS.FREETEXT,
  MENU_TERMS.SELECT,
  MENU_TERMS.MULTISELECT,
  MENU_TERMS.PERSONAL_MULTISELECT,
  MENU_TERMS.COMMENT,
];

export const NODE_WIDTH = 250;

export const NODE_HEIGHT = 126;

export const COMMENT_TYPE = 'comment';
