import { Global, css } from '@emotion/react';
import { theme } from '../../theme';

export const CookieConsentStyle = () => {
  return (
    <Global
      styles={css`
        :root {
          --lmcccm-base-font-size: ${theme.typography.body?.medium?.regular?.fontSize};
          --lmcccm-font-family: ${theme.typography.body?.medium?.regular?.family};
          --lmcccm-bg: ${theme.colors.backgroundCover.default};
          --lmcccm-text: ${theme.colors.text.primary};
          --lmcccm-link-text: ${theme.colors.actionPrimary.default};
          --lmcccm-link-hover-text: ${theme.colors.actionPrimary.hover};
          --lmcccm-link-active-text: ${theme.colors.actionPrimary.active};

          /* Cookies */
          --lmcccm-cookie-category-bg: ${theme.colors.backgroundBasic.default};
          --lmcccm-cookie-category-hover-bg: ${theme.colors.backgroundBasic.default};

          /* Toggle */
          --lmcccm-toggle-bg-off: ${theme.colors.actionPrimary.disabled};
          --lmcccm-toggle-bg-on: ${theme.colors.actionPrimary.default};
          --lmcccm-toggle-bg-readonly: ${theme.colors.actionSecondary.default};
          --lmcccm-toggle-knob-bg: ${theme.colors.backgroundCover.default};
          --lmcccm-toggle-knob-icon-color: ${theme.colors.actionInvertedPrimary.default};

          /* Buttons */
          --lmcccm-btn-font-weight: ${theme.typography.body?.medium?.button?.fontWeight};
          --lmcccm-btn-border-radius: 2rem;
          --lmcccm-btn-border-width: 1px;
          --lmcccm-btn-border-style: solid;

          --lmcccm-btn-primary-bg: ${theme.colors.actionPrimary.default};
          --lmcccm-btn-primary-border: ${theme.colors.actionPrimary.default};
          --lmcccm-btn-primary-text: ${theme.colors.textInverted.primary};
          --lmcccm-btn-primary-hover-bg: ${theme.colors.actionPrimary.hover};
          --lmcccm-btn-primary-hover-border: ${theme.colors.actionPrimary.hover};
          --lmcccm-btn-primary-hover-text: ${theme.colors.textInverted.primary};
          --lmcccm-btn-primary-active-bg: ${theme.colors.actionPrimary.active};
          --lmcccm-btn-primary-active-border: ${theme.colors.actionPrimary.active};
          --lmcccm-btn-primary-active-text: ${theme.colors.textInverted.primary};

          --lmcccm-btn-secondary-bg: ${theme.colors.backgroundCover.default};
          --lmcccm-btn-secondary-border: ${theme.colors.actionPrimary.default};
          --lmcccm-btn-secondary-text: ${theme.colors.actionPrimary.default};
          --lmcccm-btn-secondary-hover-bg: ${theme.colors.textInverted.primary};
          --lmcccm-btn-secondary-hover-border: ${theme.colors.actionSecondary.hover};
          --lmcccm-btn-secondary-hover-text: ${theme.colors.actionSecondary.hover};
          --lmcccm-btn-secondary-active-bg: ${theme.colors.textInverted.primary};
          --lmcccm-btn-secondary-active-border: ${theme.colors.actionSecondary.active};
          --lmcccm-btn-secondary-active-text: ${theme.colors.actionSecondary.active};
        }
      `}
    />
  );
};
