import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormGroup } from 'react-bootstrap';
import { BodyMedium, Breadcrumb, HeadingLarge, theme, usePath, useToast } from '@arnold/common';
import styled from '@emotion/styled';
import { Card, FormGroupLabel, PageWrapper } from '../../components/Common';
import { GraphQLErrorCard, Loading } from '../../components';
import { getSurvey } from '../../graphql/queries';
import { SurveyScreenHeading } from '../../components/StyledComponents';
import {
  ActiveRespondentsQuery,
  useActiveRespondentsQuery,
  useCreateNewRespondentChannelsMutation,
} from '../../generated/hooks';
import TeamSelect from '../../components/SurveyGroup/TeamSelect';
import { SurveyRespondentsTable } from '../../components/Forms/SurveyParticipantsTable';
import { PATHS } from './SurveyDetailScreen';

interface IProps {
  breadcrumbItems: Array<{ title: string; link: string }>;
  id?: string;
  respondentIds?: string[];
}

const ButtonContainer = styled.div`
  padding: ${theme.spacing.e} ${theme.spacing.h};
`;

export const AddRespondentToOngoingSurveyScreen: FC<IProps> = ({ breadcrumbItems, respondentIds, id }) => {
  const [t] = useTranslation('survey');
  const { addToast } = useToast();
  const [respondents, setRespondents] = useState<
    NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents']
  >([]);
  const { surveyDetailPath } = usePath();

  const { loading: respondentsLoading, data: respondentsData } = useActiveRespondentsQuery({ fetchPolicy: 'no-cache' });
  const activeRespondents = respondentsData?.user.organization?.activeRespondents || [];
  const filteredRespondents = respondentIds
    ? activeRespondents.filter((respondent) => !respondentIds.includes(respondent.id))
    : activeRespondents;

  const [createNewRespondentChannelsMutation, { loading, error }] = useCreateNewRespondentChannelsMutation();

  const handleSubmit = async () => {
    await createNewRespondentChannelsMutation({
      variables: {
        surveyId: id!,
        respondentIds: respondents.map((respondent) => Number.parseInt(respondent.id, 10)),
      },
      onCompleted: (data) => {
        addToast(t('addedParticipants', { count: data.createNewRespondentChannels?.length }));
        id && surveyDetailPath.toFunc(id, PATHS.RESPONDENTS);
      },
      refetchQueries: [
        {
          query: getSurvey,
          variables: { surveyId: id },
        },
      ],
    });
  };

  const removeRespondent = (ids: string[]) => {
    setRespondents((respondents) => respondents.filter((respondent) => !ids.includes(respondent.id)));
  };

  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb items={breadcrumbItems} />
      <SurveyScreenHeading>
        <div style={{ width: '100%' }}>
          <HeadingLarge>{t('survey:addParticipants')}</HeadingLarge>
        </div>
      </SurveyScreenHeading>
      {(loading || respondentsLoading) && <Loading />}
      {error && <GraphQLErrorCard />}
      {!error && (
        <>
          <Card>
            <FormGroup>
              <FormGroupLabel>{t('respondentSelect:selectRespondentOrTeamPlaceholder')}</FormGroupLabel>
              <TeamSelect
                setRespondents={setRespondents}
                respondents={respondents}
                activeRespondents={filteredRespondents}
                teams={respondentsData?.user.organization?.teams}
              />
            </FormGroup>
            <FormGroup>
              <SurveyRespondentsTable respondents={respondents} removeRespondent={removeRespondent} />
            </FormGroup>
            <BodyMedium>{t('numberOfParticipants', { count: respondents.length })}</BodyMedium>
          </Card>
          <ButtonContainer>
            <Button variant="primary" disabled={!respondents.length || loading} type="submit" onClick={handleSubmit}>
              {t('add')}
            </Button>
            <Button variant="outline-primary" type="button" onClick={() => id && surveyDetailPath.toFunc(id)}>
              {t('weekPicker:cancel')}
            </Button>
          </ButtonContainer>
        </>
      )}
    </PageWrapper>
  );
};
