import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip, formatDate } from '@arnold/common';

type DateInfoProps = {
  sendAt: string;
  endsAt?: string;
};

export const DateInfo: FC<DateInfoProps> = ({ sendAt, endsAt }) => {
  const { t } = useTranslation('topicOverview');
  return (
    <Tooltip
      position="top"
      animation="none"
      title={t('startAtInfo', {
        start: sendAt && formatDate(sendAt),
        end: endsAt && formatDate(endsAt),
      })}
    >
      {sendAt && formatDate(sendAt)}
    </Tooltip>
  );
};
