import { GraphQLError } from 'graphql';

export function getGraphQLErrorTranslationKey(error?: GraphQLError) {
  let headerKey;
  let contentKey;
  if (error) {
    switch (error.name) {
      case 'PhoneNumberIsNotUnique':
        headerKey = 'PhoneNumberIsNotUniqueTitle';
        contentKey = 'PhoneNumberIsNotUniqueText';
        break;
      case 'MissingFirstName':
        headerKey = 'MissingFirstNameTitle';
        contentKey = 'MissingFirstNameText';
        break;
      case 'MissingLastName':
        headerKey = 'MissingLastNameTitle';
        contentKey = 'MissingLastNameText';
        break;
      case 'WrongEmail':
        headerKey = 'WrongEmailTitle';
        contentKey = 'WrongEmailText';
        break;
      case 'NoCEO':
        headerKey = 'NoCEOTitle';
        contentKey = 'NoCEOText';
        break;
      case 'NoLanguageExists':
        headerKey = 'NoLanguageExistsTitle';
        contentKey = 'NoLanguageExistsText';
        break;
      case 'UnsupportedContactType':
        headerKey = 'UnsupportedContactTypeTitle';
        contentKey = 'invalidContactError';
        break;
      case 'InvalidBooleanValue':
        headerKey = 'invalidParticipantTitle';
        contentKey = 'invalidParticipantError';
        break;
      case 'CycleError':
        headerKey = 'CycleErrorTitle';
        contentKey = 'CycleErrorText';
        break;
      case 'OrphanCycleError':
        headerKey = 'OrphanCycleError';
        contentKey = error.message;
        break;
      case 'AccessTokenNotFound':
        headerKey = 'AccessTokenNotFoundTitle';
        contentKey = 'AccessTokenNotFoundText';
        break;
      case 'AccessTokenAlreadyRevoked':
        headerKey = 'AccessTokenAlreadyRevokedTitle';
        contentKey = 'AccessTokenAlreadyRevokedText';
        break;
      case 'AccessTokenAlreadyExpired':
        headerKey = 'AccessTokenAlreadyExpiredTitle';
        contentKey = 'AccessTokenAlreadyExpiredText';
        break;
      case 'EmailDuplicityError':
      case 'EmailIsNotUnique':
        headerKey = 'EmailDuplicityErrorTitle';
        contentKey = 'EmailDuplicityErrorText';
        break;
      case 'InvalidOrganizationChangeError':
        headerKey = 'InvalidOrganizationChangeErrorTitle';
        contentKey = 'InvalidOrganizationChangeErrorText';
        break;
      case 'RespondentExists':
        headerKey = 'RespondentExistsTitle';
        contentKey = 'RespondentExistsText';
        break;
      case 'NoRespondentForUser':
        headerKey = 'NoRespondentForUserTitle';
        contentKey = 'UnspecifiedErrorText';
        break;
      case 'SurveyAlreadyStarted':
        headerKey = 'SurveyAlreadyStartedTitle';
        contentKey = 'SurveyAlreadyStartedText';
        break;
      case 'WelcomeEmailTemplateNotFound':
        headerKey = 'MissingWelcomeEmailTemplateTitle';
        contentKey = 'MissingWelcomeEmailTemplateText';
        break;
      case 'RespondentIsTeamLeader':
        headerKey = 'RespondentIsTeamLeaderErrorTitle';
        contentKey = 'RespondentIsTeamLeaderErrorDescription';
        break;
      case 'ReminderTemplateNotFound':
        headerKey = 'MissingReminderEmailTemplateTitle';
        contentKey = 'MissingReminderEmailTemplateText';
        break;
      case 'WelcomeSMSTemplateNotFound':
        headerKey = 'MissingWelcomeSMSTemplateTitle';
        contentKey = 'MissingWelcomeSMSTemplateText';
        break;
      case 'ReminderSMSTemplateNotFound':
        headerKey = 'MissingReminderSMSTemplateTitle';
        contentKey = 'MissingReminderSMSTemplateText';
        break;
      case 'OrganizationStructureCycle':
        headerKey = 'OrganizationStructureCycleTitle';
        contentKey = 'OrganizationStructureCycleText';
        break;
      case 'OrganizationAlreadyExist':
        headerKey = 'OrganizationAlreadyExistTitle';
        contentKey = 'OrganizationAlreadyExistText';
        break;
      default:
        headerKey = 'UnspecifiedErrorTitle';
        contentKey = 'UnspecifiedErrorText';
        break;
    }
  } else {
    headerKey = 'UnspecifiedErrorTitle';
    contentKey = 'UnspecifiedErrorText';
  }
  return [headerKey, contentKey];
}
