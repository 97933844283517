import {
  createEventData,
  getFrontendConfigValue,
  logInIntercomUser,
  logOutIntercomUser,
  pushToDataLayer,
} from '@arnold/common';
import fetch from 'unfetch';

export const USER_KEY = '@Arnold/SelfCareUser';
export const DEVICE_TOKEN_KEY = '@Arnold/DeviceToken';
const ACCESS_TOKEN_PARAM = 'accessToken';

export type User = {
  id?: number;
  orgId?: number;
  accessToken: string;
  expireAt: string | null;
};

class Auth {
  user: User | null = null;

  getDeviceToken() {
    const deviceToken = localStorage.getItem(DEVICE_TOKEN_KEY);
    if (deviceToken) {
      return deviceToken;
    }
    // Generate random token of length 20
    const newToken = Array.from({ length: 20 }, () => Math.floor(Math.random() * 36).toString(36)).join('');
    localStorage.setItem(DEVICE_TOKEN_KEY, newToken);
    return newToken;
  }

  async login(username: string, password: string, otp?: string) {
    const response = await fetch(getFrontendConfigValue('API_URL') + '/auth/session', {
      body: JSON.stringify({ username, password, deviceToken: this.getDeviceToken(), otp, origin: 'SELF_CARE' }),
      // unfetch has incorrectly defined types
      // @ts-ignore
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!response.ok) {
      const error: any = new Error(`fetch error, status ${response.status}`);
      error.status = response.status;
      error.json = response.json;
      throw error;
    }

    const { id, orgId, accessToken, intercomHash, createdAt } = await response.json();

    pushToDataLayer({
      userId: id,
      orgId,
      event: 'ux.user-login',
      ...createEventData('user', 'login', 'user login'),
    });

    logInIntercomUser(id, intercomHash, new Date(createdAt));
    return this.saveUser(accessToken, intercomHash);
  }

  saveUser(accessToken: string, intercomHash?: string, id?: number, orgId?: number) {
    if (accessToken == null) {
      throw new Error('Empty accessToken!');
    }
    localStorage.setItem(USER_KEY, JSON.stringify({ id, orgId, accessToken, expireAt: null, intercomHash }));
    this.user = { id, orgId, accessToken, expireAt: null };
    return this.user;
  }

  saveExpireAt(expireAt: string | null) {
    if (this.user) {
      this.user.expireAt = expireAt;
    }
  }

  getExpireAt() {
    return this.user ? this.user.expireAt : null;
  }

  getUser(search?: string, allowSavingAccessTokenFromSearch = true): User | null {
    let user = this.user;
    if (!user) {
      const storageUser = localStorage.getItem(USER_KEY);
      if (storageUser) {
        user = JSON.parse(storageUser);
      }
      if (search && search.includes(ACCESS_TOKEN_PARAM)) {
        const accessToken = this.parceAccessToken(search);
        if (allowSavingAccessTokenFromSearch) {
          this.saveUser(accessToken);
          user = this.user;
        } else {
          user = { accessToken, expireAt: null };
        }
      }
      this.user = user;
    }
    return user;
  }

  isLoggedIn(search?: string, allowSavingAccessTokenFromSearch = true) {
    const user = this.getUser(search, allowSavingAccessTokenFromSearch);
    return user != null;
  }

  async logout() {
    const user = this.getUser();
    if (user == null) {
      return false;
    }
    localStorage.removeItem(USER_KEY);
    this.user = null;

    await fetch(getFrontendConfigValue('API_URL') + '/auth/session', {
      headers: {
        Authorization: `Bearer ${user.accessToken}`,
        'Content-Type': 'application/json',
      },
      // unfetch has incorrectly defined types
      // @ts-ignore
      mode: 'cors',
      method: 'DELETE',
    }).catch((err) => {
      // We don't care about the result. If this fails, server will have stale session but
      // it's servers bussiness only and client doesn't care
    });

    logOutIntercomUser();

    return true;
  }

  private parceAccessToken(search: string) {
    const accessTokenPosition = search.indexOf(ACCESS_TOKEN_PARAM);
    const equalPosition = search.indexOf('=', accessTokenPosition);
    const ampersandPosition = search.indexOf('&', accessTokenPosition);
    return search.substring(equalPosition + 1, ampersandPosition > 0 ? ampersandPosition : undefined);
  }
}

const auth = new Auth();
export default auth;
