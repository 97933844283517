import {
  AddIcon,
  MinusIcon,
  PlainButton,
  Tooltip,
  WidthIcon,
  FullscreenIcon,
  ExitFullscreenIcon,
  createEventData,
  pushToDataLayer,
  theme,
} from '@arnold/common';
import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AreaExtensions } from 'rete-area-plugin';
import { CustomNodeEditor } from '../rete';

type ZoomControlsProps = {
  editor: CustomNodeEditor;
  userId?: string;
  fullscreen: boolean;
  setFullscreen: (fullsreen: boolean) => void;
};

const StyledButton = styled(PlainButton)`
  & path {
    fill: ${theme.colors.actionPrimary.default};
  }
  &:hover path {
    fill: ${theme.colors.actionPrimary.hover};
  }
  &:focus path {
    fill: ${theme.colors.actionPrimary.focused};
  }
  &:focus {
    background: ${theme.background.primary};
  }
  padding-left: ${theme.spacing.f};
  padding-right: ${theme.spacing.f};
  margin: 0;
  border-radius: 0;
  width: inherit;
`;
const StyledButtonGroup = styled.div`
  box-shadow: 0px 0px 10px -3px #707070;
  border-radius: 8px;
  background: ${theme.colors.backgroundBasic.default};
  display: inline-flex;
  vertical-align: middle;
  overflow: hidden;
`;
const Container = styled.div`
  position: absolute;
  top: ${theme.spacing.f};
  right: ${theme.spacing.f};
  z-index: 10;
`;

export const ZoomControls: FC<ZoomControlsProps> = ({ editor, userId, fullscreen, setFullscreen }) => {
  const { t } = useTranslation('arielZoomControls');

  return (
    <Container>
      <StyledButtonGroup>
        <Tooltip title={t('zoomOut')}>
          <StyledButton
            data-icom="ariel-zoom-out"
            onClick={(e) => {
              if (userId) {
                pushToDataLayer({
                  userId,
                  event: 'ux.user-ariel-zoom',
                  ...createEventData('ariel', 'zoomButton', 'user clicked on zoom buttons'),
                });
              }
              const positionInfo = editor.areaPlugin.container.firstElementChild?.getBoundingClientRect();
              const containerPositionInfo = editor.areaPlugin.container?.getBoundingClientRect();
              if (!positionInfo) {
                return;
              }
              // Replicate calculation from rete-area-plugin.
              const ox =
                (positionInfo.left -
                  containerPositionInfo.left -
                  (window.innerWidth - containerPositionInfo.left) / 2) *
                -0.2;
              const oy =
                (positionInfo.top - containerPositionInfo.top - (window.innerHeight - containerPositionInfo.top) / 2) *
                -0.2;
              editor.areaPlugin.area.zoom(editor.areaPlugin.area.transform.k * 0.8, ox, oy);
            }}
          >
            <MinusIcon />
          </StyledButton>
        </Tooltip>

        <Tooltip title={t('zoomIn')}>
          <StyledButton
            data-icom="ariel-zoom-in"
            onClick={() => {
              if (userId) {
                pushToDataLayer({
                  userId,
                  event: 'ux.user-ariel-zoom',
                  ...createEventData('ariel', 'zoomButton', 'user clicked on zoom buttons'),
                });
              }

              const positionInfo = editor.areaPlugin.container.firstElementChild?.getBoundingClientRect();
              const containerPositionInfo = editor.areaPlugin.container?.getBoundingClientRect();
              if (!positionInfo) {
                return;
              }
              // Replicate calculation from rete-area-plugin.
              const ox =
                (positionInfo.left -
                  containerPositionInfo.left -
                  (window.innerWidth - containerPositionInfo.left) / 2) *
                0.25;
              const oy =
                (positionInfo.top - containerPositionInfo.top - (window.innerHeight - containerPositionInfo.top) / 2) *
                0.25;
              editor.areaPlugin.area.zoom(editor.areaPlugin.area.transform.k * 1.25, ox, oy);
            }}
          >
            <AddIcon />
          </StyledButton>
        </Tooltip>

        <Tooltip title={t('pinToScreen')}>
          <StyledButton
            data-icom="ariel-pin-to-screen"
            onClick={() => {
              if (userId) {
                pushToDataLayer({
                  userId,
                  event: 'ux.user-ariel-pin-to-screen',
                  ...createEventData('ariel', 'pinToScreenButton', 'user clicked on pin to screen button'),
                });
              }
              AreaExtensions.zoomAt(editor.areaPlugin, editor.getNodes());
            }}
          >
            <WidthIcon />
          </StyledButton>
        </Tooltip>
        {fullscreen ? (
          <Tooltip key="fullscreen" title={t('exitFullscreen')}>
            <StyledButton
              data-icom="ariel-exit-fullscreen"
              onClick={() => {
                if (userId) {
                  pushToDataLayer({
                    userId,
                    event: 'ux.user-ariel-exit-fullscreen',
                    ...createEventData('ariel', 'exitFullscreenButton', 'user clicked on exit fullscreen button'),
                  });
                }
                setFullscreen(false);
              }}
            >
              <ExitFullscreenIcon />
            </StyledButton>
          </Tooltip>
        ) : (
          <Tooltip key="fullscreen" title={t('fullscreen')}>
            <StyledButton
              data-icom="ariel-fullscreen"
              onClick={() => {
                if (userId) {
                  pushToDataLayer({
                    userId,
                    event: 'ux.user-ariel-fullscreen',
                    ...createEventData('ariel', 'fullscreenButton', 'user clicked on fullscreen button'),
                  });
                }
                setFullscreen(true);
              }}
            >
              <FullscreenIcon />
            </StyledButton>
          </Tooltip>
        )}
      </StyledButtonGroup>
    </Container>
  );
};
