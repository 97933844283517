import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { OrganizationQuery, OrganizationSurveysQuery } from '../../generated/hooks';

export enum PATHS {
  ONGOING = 'ongoing',
  PREPARED = 'prepared',
  COMPLETED = 'completed',
}

export type SurveyData = Pick<
  NonNullable<OrganizationSurveysQuery['organizationSurveys']>[0],
  'endsAt' | 'sendAt' | 'id'
> & {
  topic: ReactNode;
  actions: ReactNode;
  info: ReactNode;
  responseRate?: ReactNode;
};

export type GetDataSourceParams = {
  surveys: NonNullable<OrganizationSurveysQuery['organizationSurveys']>;
  organization: NonNullable<OrganizationQuery['organization']>;
  pathStatus?: string;
  t: TFunction;
  toSurveyDetail: (id: string) => void;
  toReportDetail: (id: string) => void;
  i18nLanguage: string;
};
