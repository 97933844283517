import { ApolloError } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getGraphQLErrorTranslationKey } from '../graphql/errors';
import NotificationCard from './NotificationCard';

interface IProps {
  error?: ApolloError;
}

const GraphQLErrorCard = (props: IProps) => {
  const [t] = useTranslation('modal');
  const [headerKey, contentKey] = props.error
    ? getGraphQLErrorTranslationKey(props.error.graphQLErrors[0])
    : getGraphQLErrorTranslationKey();

  return <NotificationCard isError={true} header={t(headerKey)} content={t(contentKey)} />;
};

export default GraphQLErrorCard;
