import { useEffect, useState } from 'react';
import { ImportStatistic } from '../../../lib/types';
import { SurveyGroupsQuery, SurveyStatus, SurveysQuery } from '../../../generated/hooks';
import { RespondentOnboarding, RespondentOneTimeSurveys, RespondentSurveyGroups } from '../../OrganizationImportCard';
import ImportModal from './ImportModal';
import ChooseOnboardingModal from './ChooseOnboardingModal';
import ChooseProcessSurveyModal from './ChooseProcessSurveyModal';
import { Step } from './types';
import ChooseOneTimeSurveyModal from './ChooseOneTimeSurveyModal';

interface IProps {
  name: string;
  statistic: ImportStatistic;
  save: () => void;
  cancel: () => void;
  onboardings: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  processSurveys: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  surveys: NonNullable<SurveysQuery['user']['organization']>['surveys'];
  respondentOnboardingSettings: RespondentOnboarding;
  setRespondentOnboardingSettings: (data: RespondentOnboarding) => void;
  respondentOneTimeSurveySettings: RespondentOneTimeSurveys;
  setRespondentOneTimeSurveySettings: (data: RespondentOneTimeSurveys) => void;
  respondentSGSettings: RespondentSurveyGroups;
  setRespondentSGSettings: (data: RespondentSurveyGroups) => void;
  onClose: () => void;
}

function OrganizationImport({
  name,
  statistic,
  save,
  cancel,
  onboardings,
  processSurveys,
  surveys,
  setRespondentOnboardingSettings,
  setRespondentOneTimeSurveySettings,
  setRespondentSGSettings,
  onClose,
}: IProps) {
  const [step, setStep] = useState<Step>('import');
  const [steps, setSteps] = useState<Step[]>([]);
  const [showSteps, setShowSteps] = useState<boolean>(false);
  const [defaultSteps, setDefaultSteps] = useState<Step[]>([]);

  const ongoingSurveys = surveys.filter((survey) => survey.status === SurveyStatus.Ongoing && !survey.public);

  useEffect(() => {
    const importSteps: Step[] = [];
    if (onboardings.length > 0) {
      importSteps.push('onboarding');
    }

    if (ongoingSurveys.length > 0) {
      importSteps.push('one-time-survey');
    }

    if (processSurveys.length > 0) {
      importSteps.push('process-survey');
    }

    setSteps(importSteps);
    setDefaultSteps(importSteps);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePrimaryAction = () => {
    const [step, ...rest] = steps;
    if (step && statistic.toCreateRespondents.length && showSteps) {
      setStep(step);
      setSteps(rest);
    } else {
      save();
    }
  };

  const renderContent = () => {
    switch (step) {
      case 'onboarding':
        return (
          <ChooseOnboardingModal
            cancel={onClose}
            onboardings={onboardings}
            employees={statistic.toCreateRespondents}
            onChange={setRespondentOnboardingSettings}
            save={handlePrimaryAction}
            steps={defaultSteps}
          />
        );
      case 'one-time-survey':
        return (
          <ChooseOneTimeSurveyModal
            cancel={onClose}
            surveys={ongoingSurveys}
            employees={statistic.toCreateRespondents}
            onChange={setRespondentOneTimeSurveySettings}
            save={handlePrimaryAction}
            steps={defaultSteps}
          />
        );
      case 'process-survey':
        return (
          <ChooseProcessSurveyModal
            cancel={onClose}
            processSurveys={processSurveys}
            employees={statistic.toCreateRespondents}
            onChange={setRespondentSGSettings}
            save={handlePrimaryAction}
            steps={defaultSteps}
          />
        );
      default:
        return (
          <ImportModal
            name={name}
            statistic={statistic}
            save={handlePrimaryAction}
            cancel={cancel}
            steps={steps}
            setShowSteps={setShowSteps}
            showSteps={showSteps}
          />
        );
    }
  };
  return renderContent();
}

export default OrganizationImport;
