import { Mutation } from '@apollo/client/react/components';
import { ascend, head, path, sortWith } from 'ramda';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { selectMenuStyles, SelectWrapper, theme, Tooltip } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { ErrorWarning } from '../../assets/images';
import { updateReportRecipient } from '../../graphql/mutations';
import {
  addRespondentToReportSubscribe,
  getReportRecipients,
  removeRespondentFromReportSubscribe,
} from '../../graphql/queries';
import notEmpty from '../../lib/common';
import { ModalType } from '../../lib/enums';
import ee from '../../lib/eventEmitter';
import { PickerChoice } from '../../lib/types';
import {
  AddRespondentToReportSubscribeMutation,
  AddRespondentToReportSubscribeMutationVariables,
  ContactType,
  OrganizationSettingReportQuery,
  RemoveRespondentfromReportSubscribeMutation,
  RemoveRespondentfromReportSubscribeMutationVariables,
  ReportRecipientsQuery,
  UpdateReportRecipientMutation,
  UpdateReportRecipientMutationVariables,
} from '../../generated/hooks';

interface ITableHeaderProps {
  width?: string;
  textAlign?: string;
}

const RecipientsTableHeader = styled.th`
  width: ${({ width }: ITableHeaderProps) => width || 'initial'};
  text-align: ${({ textAlign }: ITableHeaderProps) => textAlign || 'center'};
`;

export type RespondentPickerChoice = {
  label: string;
  value: string;
  isDisabled?: boolean;
};

type Recipient = {
  id: string;
  __typename: string;
  firstname: string | null;
  surname: string | null;
  contacts: Array<{
    type: ContactType;
    value: string;
  }> | null;
};

interface IProps {
  recipients: ReportRecipientsQuery['reportRecipients'];
  organization: NonNullable<OrganizationSettingReportQuery['organization']>;
  type: string;
  languageOptions: PickerChoice[];
}

const StyledTooltip = styled(Tooltip)`
  height: ${theme.spacing.f};
`;

const nameSortTable = sortWith([ascend(path(['respondent', 'surname'])), ascend(path(['respondent', 'firstname']))]);

const nameSortSelect = sortWith([ascend(path(['surname'])), ascend(path(['firstname']))]);

const sortTeamSelect = sortWith([ascend(path(['label']))]);

const handleChangeLanguage = (changeLanguage: (arg: any) => void, id: string) => (option: PickerChoice) =>
  changeLanguage({ variables: { language: option.value, id } });

const RecipientsTable = (props: IProps) => {
  const defaultLanguage =
    props.languageOptions.find((option) => option.value === props.organization.primaryLanguageId.toString()) ||
    props.languageOptions[0];
  const [t] = useTranslation('settings');
  const [recipient, setRecipient] = useState<RespondentPickerChoice | null>(null);
  const [team, setTeam] = useState<PickerChoice | null>(null);
  const [language, setLanguage] = useState<PickerChoice>(defaultLanguage);

  const getUsersName = (respondent: Recipient) => {
    const { firstname, surname } = respondent;

    return firstname + ' ' + surname;
  };

  const getUsersEmail = (respondent: Recipient) => {
    return respondent.contacts
      ? head(respondent.contacts.filter((c) => c.type === ContactType.Email).map((c) => c.value.trim())) || ''
      : '';
  };

  const getUsersLabel = (respondent: Recipient) => {
    const name = getUsersName(respondent);
    const email = getUsersEmail(respondent);

    return `${name || email}${name && email ? ' (' + email + ')' : ''}`;
  };

  const handleMutationCompleted = () => {
    setRecipient(null);
    setTeam(null);
    setLanguage(defaultLanguage);
    ee.emitModal(ModalType.SUCCESS, 'saveOrgTitle', 'saveOrgText');
  };
  const reportRecipients = nameSortSelect(props.organization.allRespondents).map((respondent: Recipient) => ({
    label: getUsersLabel(respondent),
    value: respondent.id.toString(),
  }));

  // @ts-ignore
  return (
    <table className="table">
      <thead>
        <tr>
          <RecipientsTableHeader scope="col" width={'45%'} textAlign={'left'}>
            {t('recipient')}
          </RecipientsTableHeader>
          <RecipientsTableHeader scope="col" width={'20%'}>
            {t(props.type.toLowerCase())}
          </RecipientsTableHeader>
          <RecipientsTableHeader scope="col" width={'18.5%'}>
            {t('language')}
          </RecipientsTableHeader>
          <RecipientsTableHeader scope="col" width={'4%'}>
            &nbsp;
          </RecipientsTableHeader>
          <RecipientsTableHeader scope="col" width={'12.5%'}>
            {t('action')}
          </RecipientsTableHeader>
        </tr>
      </thead>
      <tbody>
        {nameSortTable(props.recipients.filter(notEmpty).filter((recipient) => recipient.type === props.type)).map(
          // eslint-disable-next-line array-callback-return
          (reportRecipient) => {
            const recipient = reportRecipient.respondent;
            if (recipient) {
              return (
                <tr
                  key={`${recipient.id}_${reportRecipient.team ? reportRecipient.team.id : 'null'}_${
                    reportRecipient.originator
                  }`}
                >
                  <td>{getUsersLabel(recipient)}</td>
                  <td>
                    {reportRecipient.team ? reportRecipient.team.name || t('withoutLeader', { ns: 'teams' }) : t('all')}
                  </td>
                  <td>
                    <Mutation<UpdateReportRecipientMutation, UpdateReportRecipientMutationVariables>
                      mutation={updateReportRecipient}
                      refetchQueries={[
                        {
                          query: getReportRecipients,
                          variables: { organizationId: props.organization.id },
                        },
                      ]}
                    >
                      {(changeLanguage: (arg: any) => void) => (
                        <SelectWrapper
                          value={{
                            label: t(`languageSelector:${reportRecipient.language.code}`),
                            value: reportRecipient.language.id,
                          }}
                          options={props.languageOptions}
                          menuPlacement="auto"
                          styles={{
                            menu: (provided: any, state: any) => ({
                              ...provided,
                              ...selectMenuStyles,
                            }),
                          }}
                          onChange={handleChangeLanguage(changeLanguage, reportRecipient.id)}
                        />
                      )}
                    </Mutation>
                  </td>
                  <td>
                    {(reportRecipient.teamDeleted || reportRecipient.respondentDeleted) && (
                      <StyledTooltip
                        title={
                          reportRecipient.respondentDeleted
                            ? t('respondentDeleted', {
                                respondent: `${recipient.firstname} ${recipient.surname}`,
                              })
                            : t('teamDeleted', {
                                team: reportRecipient.team.name,
                              })
                        }
                      >
                        <img src={ErrorWarning} alt="Error warning icon" />
                      </StyledTooltip>
                    )}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Mutation<
                      RemoveRespondentfromReportSubscribeMutation,
                      RemoveRespondentfromReportSubscribeMutationVariables
                    >
                      mutation={removeRespondentFromReportSubscribe}
                      variables={{
                        respondentId: recipient.id,
                        teamId: reportRecipient.team ? reportRecipient.team.id : null,
                        type: props.type,
                      }}
                      onCompleted={handleMutationCompleted}
                      refetchQueries={[
                        {
                          query: getReportRecipients,
                          variables: {
                            organizationId: props.organization ? props.organization.id : '1',
                          },
                        },
                      ]}
                    >
                      {(removeRespondentFromReportSubscribe: () => void) => {
                        return (
                          <i
                            className="nc-icon nc-simple-remove"
                            color="info"
                            onClick={removeRespondentFromReportSubscribe}
                            style={{ marginTop: '7px' }}
                          />
                        );
                      }}
                    </Mutation>
                  </td>
                </tr>
              );
            }
          },
        )}
        <tr key={'unique_' + props.type}>
          <td>
            <SelectWrapper
              value={recipient}
              options={reportRecipients}
              placeholder={t('selectRecipient')}
              onChange={setRecipient}
              menuPlacement="auto"
              styles={{
                menu: (provided: any, state: any) => ({
                  ...provided,
                  ...selectMenuStyles,
                }),
              }}
            />
          </td>
          <td>
            <SelectWrapper
              value={team}
              options={(props.type === 'UNIT' ? [{ label: t('all').toString() || 'All', value: '' }] : []).concat(
                sortTeamSelect(
                  props.organization.teams.map((team) => ({
                    label: team.name || t('withoutLeader', { ns: 'teams' }),
                    value: team.id.toString(),
                  })),
                ),
              )}
              placeholder={t('selectTeam')}
              onChange={setTeam}
              menuPlacement="auto"
              styles={{
                menu: (provided: any, state: any) => ({
                  ...provided,
                  ...selectMenuStyles,
                }),
              }}
            />
          </td>
          <td>
            <SelectWrapper
              value={language}
              options={props.languageOptions}
              onChange={setLanguage}
              menuPlacement="auto"
              styles={{
                menu: (provided: any, state: any) => ({
                  ...provided,
                  ...selectMenuStyles,
                }),
              }}
            />
          </td>
          <td>&nbsp;</td>
          <td>
            <Mutation<AddRespondentToReportSubscribeMutation, AddRespondentToReportSubscribeMutationVariables>
              mutation={addRespondentToReportSubscribe}
              variables={{
                respondentId: recipient ? recipient.value : (null as any),
                teamId: team && team.value.length > 0 ? team.value : null,
                type: props.type,
                language: language?.value,
              }}
              onCompleted={handleMutationCompleted}
              refetchQueries={[
                {
                  query: getReportRecipients,
                  variables: {
                    organizationId: props.organization ? props.organization.id : '1',
                  },
                },
              ]}
            >
              {(addRespondentToReportSubscribe: () => void) => {
                return (
                  <Button variant="primary" disabled={!recipient || !team} onClick={addRespondentToReportSubscribe}>
                    {t('add')}
                  </Button>
                );
              }}
            </Mutation>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default RecipientsTable;
