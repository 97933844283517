import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import {
  SecondarySpan,
  formatDate,
  HeadingSmall,
  theme,
  usePath,
  Tooltip,
  ContentCopyIcon,
  ActionMenuIcon,
  notEmpty,
  useToast,
  BodySmall,
} from '@arnold/common';
import { ReactComponent as WarningIcon } from '@arnold/common/lib/assets/icons/ErrorWarningIcon.svg';
import { Link, useHistory } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import { getFullName } from '../../../lib/helpers';
import { getAllowedConversationSubjects } from '../../../graphql/queries';
import i18n from '../../../translations/i18n';
import { ButtonLink, StyledDropdownItem, StyledDropdownMenu } from '../../StyledComponents';
import {
  useCloneTopicWithTopicGroupMutation,
  useDeleteTopicGroupMutation,
  AllowedConversationSubjectQuery,
  TopicGroupTypeCode,
  useCloneProcessTopicGroupMutation,
  useCreateProcessStepMutation,
} from '../../../generated/hooks';
import ee from '../../../lib/eventEmitter';
import { CategoryIcon } from './CategoryIcons';
import { getCategoryName, getTopicLanguages, getTopicTitleForLink } from './utils';
import { LanguagesIcons } from './LanguagesIcons';
import { InfoBox } from './InfoBox';
import { DateInfo } from './DateInfo';

type Organization = NonNullable<AllowedConversationSubjectQuery['user']['organization']>;

type ConversationSubjectCardProps = {
  subject: Organization['allowedConversationSubjects'][0];
  toDetailLink?: string;
  organizationLanguages: { code: string }[];
  languageId: number;
  processTopicGroupId?: string;
  showOptions?: boolean;
  setLoading?: (loading: boolean) => void;
  stepTopicGroupId?: string;
  cannotScheduleReason?: string;
};

const Buttons = styled.div`
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: auto auto;
`;

const InfoBoxes = styled.div`
  display: grid;
  align-items: end;
  grid-template-columns: 3fr 2fr 2fr;
`;

const Description = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const Card = styled.div`
  background-color: ${theme.background.primary};
  width: 456px;
  padding: ${theme.spacing.h};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.f};
`;

const CategoryBox = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  grid-gap: ${theme.spacing.d};
  height: min-content;
  padding-bottom: ${theme.spacing.d};
`;
const CategoryTexts = styled.div``;
const CategoryName = styled.div`
  font-weight: 500;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDropdownToggle = styled(DropdownToggle)`
  display: flex;
  top: 6px;
  left: ${theme.spacing.f};
  z-index: 10;
  padding: 0;
  background: transparent;
  border: 0;
  &:after {
    border: none;
  }
`;

const StyledDropdown = styled(Dropdown)`
  & > .btn.dropdown-toggle,
  & > .btn.dropdown-toggle:hover {
    background-color: transparent !important;
  }
`;

const IconsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.d};
  align-items: center;
`;

export const ConversationSubjectCard: FC<ConversationSubjectCardProps> = ({
  subject: {
    name,
    description,
    categoryName,
    process,
    custom,
    categoryCode,
    topic,
    languages,
    lastSurvey,
    lastSurveyGroup,
    id,
    typeCode,
    isValid,
    numberOfComments,
  },
  organizationLanguages,
  toDetailLink,
  languageId,
  processTopicGroupId,
  showOptions,
  setLoading,
  stepTopicGroupId,
  cannotScheduleReason,
}) => {
  const history = useHistory();
  const { t } = useTranslation('topicOverview');
  const { t: processDetailT } = useTranslation('processDetail');
  const { createSurveyPath, createOnboardingPath, createProcessSurveyPath, createExitPath } = usePath();
  const fullCategoryName = getCategoryName(categoryName, custom, t('customTopic'));
  const [cloneTopicWithTopicGroup] = useCloneTopicWithTopicGroupMutation({
    awaitRefetchQueries: true,
  });
  const [createProcessTopicGroup] = useCreateProcessStepMutation();
  const [cloneProcessTopicGroup] = useCloneProcessTopicGroupMutation({
    awaitRefetchQueries: true,
  });
  const [deleteTopicGroupMutation] = useDeleteTopicGroupMutation({
    variables: {
      id,
    },
    update: (cache) => {
      const cachedData = cache.read({
        query: getAllowedConversationSubjects,
        variables: { languageCode: i18n.language },
        optimistic: true,
      });
      const orgId = cachedData?.user?.organization?.id;
      cache.modify({
        id: `Organization:${orgId}`,
        fields: {
          allowedConversationSubjects(existing) {
            return existing.filter((subject: { __ref: string }) => subject.__ref !== `ConversationSubject:${id}`);
          },
        },
      });
    },
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const { addToast } = useToast();

  const handleClone = async () => {
    setLoading && setLoading(true);
    process
      ? await cloneProcessTopicGroup({
          variables: {
            id,
          },
          refetchQueries: [
            {
              query: getAllowedConversationSubjects,
              variables: { languageCode: i18n.language },
            },
          ],
          onCompleted: () => {
            addToast(t('copyCreated'));
            setLoading && setLoading(false);
          },
          onError: () => {
            setLoading && setLoading(false);
          },
        })
      : await cloneTopicWithTopicGroup({
          variables: {
            id: topic?.id!,
          },
          refetchQueries: [
            {
              query: getAllowedConversationSubjects,
              variables: { languageCode: i18n.language },
            },
          ],
          onCompleted: () => {
            addToast(t('copyCreated'));
            setLoading && setLoading(false);
          },
          onError: () => {
            setLoading && setLoading(false);
          },
        });
  };

  const handleDeleteTopicGroup = async () => {
    setLoading && setLoading(true);
    await deleteTopicGroupMutation({
      variables: {
        id,
      },
      onCompleted: () => {
        setLoading && setLoading(false);
      },
      onError: () => {
        setLoading && setLoading(false);
      },
    });
  };

  const handleAddTopicToProcess = () => {
    createProcessTopicGroup({
      variables: {
        input: {
          processTopicGroupId: processTopicGroupId!,
          stepTopicGroupId: stepTopicGroupId!,
        },
      },
      onCompleted: () => {
        history.push(`/process/${processTopicGroupId}?editing=${topic?.id!}`);
      },
    });
  };

  const menuItems = [
    (topic?.id || process) && {
      label: t('copy'),
      key: 'copy',
      handler: () => handleClone(),
    },
    {
      label: t('delete'),
      key: 'delete',
      handler: () => ee.emitDeleteTopicGroupModal(name, handleDeleteTopicGroup),
      disabled: !!lastSurvey || !!lastSurveyGroup,
      tooltipTitle: lastSurvey || lastSurveyGroup ? t('topicCannotBeDeleted') : undefined,
    },
  ].filter(notEmpty);

  const MenuItemsDropdown = () => (
    <StyledDropdown
      show={isDropdownOpen}
      onToggle={() => setIsDropdownOpen(!isDropdownOpen)}
      drop="down"
      data-cy="btn-options"
    >
      <StyledDropdownToggle color="secondary" variant={'link'} outline={false}>
        <ActionMenuIcon />
      </StyledDropdownToggle>
      <StyledDropdownMenu data-cy="options-menu">
        {menuItems.map((item) => {
          const dropdownItem = (
            <StyledDropdownItem
              key={item.key}
              onClick={item.handler}
              data-cy={`topic-editor-add-${item.label.toLocaleLowerCase()}`}
              disabled={item.disabled}
            >
              {t(item.label)}
            </StyledDropdownItem>
          );
          return item.tooltipTitle ? (
            <Tooltip title={item.tooltipTitle} key={item.label}>
              {dropdownItem}
            </Tooltip>
          ) : (
            dropdownItem
          );
        })}
      </StyledDropdownMenu>
    </StyledDropdown>
  );

  return (
    <Card data-icom={`conversation-subject-card-${id}`} data-cy={`conversation-subject-card-${id}`}>
      <CardHeader>
        <CategoryBox>
          <CategoryIcon categoryCode={categoryCode} custom={custom} />
          <CategoryTexts>
            <CategoryName data-icom={'conversation-subject-card-category'}>{fullCategoryName}</CategoryName>
            <SecondarySpan data-icom={'conversation-subject-card-type'}>
              {t(`surveysOverview:${process ? 'processTopicGroup' : 'normalTopicGroup'}`)}
            </SecondarySpan>
          </CategoryTexts>
        </CategoryBox>
        <IconsContainer>
          {!isValid && (
            <Tooltip title={t('topicCannotBeScheduled')}>
              <WarningIcon />
            </Tooltip>
          )}
          {cannotScheduleReason && (
            <Tooltip title={cannotScheduleReason}>
              <WarningIcon />
            </Tooltip>
          )}
          {!custom && showOptions && (
            <Tooltip title={t('copyButtonTooltip')}>
              <ButtonLink onClick={() => handleClone()} data-icom="btn-copy-topic-group" data-cy="btn-copy-topic-group">
                <ContentCopyIcon />
              </ButtonLink>
            </Tooltip>
          )}
          {custom && showOptions && <MenuItemsDropdown />}
        </IconsContainer>
      </CardHeader>
      <div>
        <HeadingSmall data-icom={'conversation-subject-card-title'}>{name}</HeadingSmall>
        {!!numberOfComments && (
          <BodySmall color={theme.colors.emotionWarning.default} margin={`${theme.spacing.c} 0 0 0`}>
            {`${numberOfComments} ${t('numberOfComments', {
              count: numberOfComments,
              postProcess: 'interval',
            })}`}
          </BodySmall>
        )}
      </div>
      <Description data-icom={'conversation-subject-card-description'}>{description}</Description>
      <InfoBoxes>
        <InfoBox title={t('allLanguageTitle')} dataIcom={'conversation-subject-card-languages'}>
          <LanguagesIcons languages={getTopicLanguages(languages, organizationLanguages)} />
        </InfoBox>
        {topic?.updatedBy ? (
          <InfoBox
            showSeparator
            title={getFullName(topic.updatedBy.firstname, topic.updatedBy.surname)}
            dataIcom={'conversation-subject-card-updated-by'}
          >
            {topic.updatedAt ? formatDate(topic.updatedAt) : formatDate(topic.createdAt)}
          </InfoBox>
        ) : (
          <div
          // keep div here to grid work properly
          />
        )}
        {lastSurvey && (
          <InfoBox
            showSeparator
            title={t(new Date(lastSurvey.sendAt) > new Date() ? 'prepared' : 'startAt')}
            dataIcom={'conversation-subject-card-last-survey'}
          >
            <DateInfo sendAt={lastSurvey.sendAt} endsAt={lastSurvey.endsAt} />
          </InfoBox>
        )}
      </InfoBoxes>
      <Buttons>
        {toDetailLink && (
          <Link
            to={toDetailLink}
            data-cy={`admin-topic-detail-link-${id}`}
            data-icom={'btn-conversation-subject-card-detail'}
          >
            {t('detailLink')}
          </Link>
        )}
        <Button
          variant="primary"
          data-cy={`admin-schedule-topic-button-${id}`}
          data-icom={'btn-conversation-subject-card-schedule'}
          disabled={!isValid || !!cannotScheduleReason}
          onClick={() => {
            process
              ? typeCode === TopicGroupTypeCode.Onboarding
                ? createOnboardingPath.toFunc(id)
                : typeCode === TopicGroupTypeCode.Exit
                  ? createExitPath.toFunc(id)
                  : createProcessSurveyPath.toFunc(id)
              : processTopicGroupId
                ? handleAddTopicToProcess()
                : createSurveyPath.toFunc(topic?.topicGroup.id!, getTopicTitleForLink(topic, languageId)!);
          }}
        >
          {processTopicGroupId ? processDetailT('addTopic') : t('planTopic')}
        </Button>
      </Buttons>
    </Card>
  );
};
