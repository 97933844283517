import React, { useEffect, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { DayPicker, useInput } from 'react-day-picker';
import { theme } from '@arnold/common';
import { ReactComponent as Calendar } from '@arnold/common/src/assets/icons/CalendarIcon.svg';
import { isMatch, isValid, parse } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { getLocale, getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { FormErrorFeedback } from './FormComponents';

const DateInputFormControl = styled(FormControl)`
  width: unset;
`;
const DayPickerWrap = styled.div`
  .rdp-nav_button {
    outline: none;
    color: #04b7ef;
  }
  .rdp-nav_button:focus {
    background: none;
    border: none;
  }
  .rdp-nav_button:hover {
    background: none;
    border: none;
  }
`;

const InputWrap = styled.div`
  display: flex;
`;

const CalendarIcon = styled.span`
  position: relative;
  cursor: pointer;
  right: 28px;
  top: 8px;
`;

interface IProps {
  onDateChange: (date: Date) => void;
  inputName: string;
  date?: string;
  placeholder?: string;
  dataCy?: string;
  disabled?: boolean;
}

const DateInput = ({ onDateChange, inputName, date, disabled, placeholder, dataCy }: IProps) => {
  const { t, i18n } = useTranslation('dateInput');
  const { inputProps, dayPickerProps, setSelected } = useInput({
    defaultSelected: date ? new Date(date) : undefined,
    format: 'P',
    required: true,
    locale: getLocale(i18n.language),
  });
  const [dateInputValue, setDateInputValue] = useState<string | null>(null);
  const [dateInputError, setDateInputError] = useState<string | null>(null);
  const [isDayPickerOpen, setIsDayPickerOpen] = useState<boolean>(false);
  const [closeDayPickerTimer, setCloseDayPickerTimer] = useState<any>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);

  const blockDayPickerClose = () => {
    clearTimeout(closeDayPickerTimer);
  };

  const getDayInUtc = (year: number, month: number, day: number) => new Date(Date.UTC(year, month, day, 0, 0, 0));

  const parseInputValue = (inputValue: string) => {
    const trimValue = inputValue.replace(/\s/g, '');

    const inputDate =
      (isMatch(trimValue, 'd.M.yyyy') && parse(trimValue, 'd.M.yyyy', new Date())) ||
      (isMatch(trimValue, 'd/M/yyyy') && parse(trimValue, 'd/M/yyyy', new Date())) ||
      (isMatch(trimValue, 'dd.MM.yyyy') && parse(trimValue, 'dd.MM.yyyy', new Date())) ||
      (isMatch(trimValue, 'dd/MM/yyyy') && parse(trimValue, 'dd/MM/yyyy', new Date())) ||
      (isMatch(trimValue, 'yyyy.MM.dd') && parse(trimValue, 'yyyy.MM.dd', new Date())) ||
      (isMatch(trimValue, 'yyyy.MM.dd.') && parse(trimValue, 'yyyy.MM.dd.', new Date())) ||
      (isMatch(trimValue, 'yyyy/MM/dd') && parse(trimValue, 'yyyy/MM/dd', new Date())) ||
      (isMatch(trimValue, 'yyyy.M.d') && parse(trimValue, 'yyyy.M.d', new Date())) ||
      (isMatch(trimValue, 'yyyy.M.d.') && parse(trimValue, 'yyyy.M.d.', new Date())) ||
      (isMatch(trimValue, 'yyyy/M/d') && parse(trimValue, 'yyyy/M/d', new Date()));

    if (!inputDate || !isValid(inputDate)) {
      setDateInputError(t('incorrectDate'));
      return;
    }

    setDateInputError(null);
    return getDayInUtc(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
  };

  const handleDateChange = (selectedDay: Date, modifiers: any) => {
    selectedDay.setHours(0);
    const selectedDayInUtc = getDayInUtc(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate());
    setDateInputError(null);
    setDateInputValue(
      selectedDayInUtc ? getLocalizedDateWithoutLeadingZeroes(new Date(selectedDayInUtc), i18n.language) : null,
    );
    onDateChange(selectedDayInUtc);
  };

  const handleInputChange = (e?: any) => {
    setCloseDayPickerTimer(setTimeout(() => setIsDayPickerOpen(false), 500));
    if (dateInputValue === '') {
      setDateInputError(null);
      return;
    }

    if (dateInputValue) {
      const parsedDate = parseInputValue(dateInputValue!);

      if (parsedDate) {
        onDateChange(parsedDate);
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleInputChange();
    }
  };

  useEffect(() => {
    if (date) {
      setDateInputValue(date ? getLocalizedDateWithoutLeadingZeroes(new Date(date || ''), i18n.language) : null);
      setSelected(new Date(date));
    }
    setIsDayPickerOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <>
      <InputWrap>
        <DateInputFormControl
          type={'text'}
          name={inputName}
          {...inputProps}
          onChange={(e) => {
            e.preventDefault();
            setDateInputValue(e.target.value);
          }}
          onBlur={(e: React.FocusEvent) => {
            // propagate input value to dayPicker only if focusout is not from click on day button
            if (!e.relatedTarget?.className?.includes('rdp-day')) {
              handleInputChange();
            }
          }}
          onFocus={() => setIsDayPickerOpen(true)}
          onKeyDown={handleKeyDown}
          value={dateInputValue || ''}
          placeholder={placeholder ?? t('dateInputPlaceholder')}
          isAlert={!!dateInputError}
          disabled={disabled}
          ref={inputRef}
          data-cy={dataCy}
        />
        <CalendarIcon
          onClick={() => {
            if (!isDayPickerOpen) {
              inputRef.current?.focus();
            }
            setIsDayPickerOpen(disabled ? false : !isDayPickerOpen);
          }}
        >
          <Calendar />
        </CalendarIcon>
      </InputWrap>
      {dateInputError && <FormErrorFeedback error={dateInputError} />}
      {isDayPickerOpen && (
        <DayPickerWrap onClick={blockDayPickerClose}>
          <DayPicker
            {...dayPickerProps}
            mode={'default'}
            defaultMonth={new Date(date || '')}
            selected={new Date(date || '')}
            onDayClick={handleDateChange}
            locale={getLocale(i18n.language)}
            styles={{
              root: {
                position: 'absolute',
                backgroundColor: theme.colors.backgroundCover.default,
                margin: 0,
                width: 'fit-content',
                boxShadow: theme.shadows.elevationContentDialog,
                borderRadius: '6px',
                zIndex: 10,
              },
              months: { margin: theme.spacing.f },
              caption_label: { ...theme.typography.heading.medium!.default },
              head_cell: {
                ...theme.typography.body.medium!.regular,
                color: theme.colors.text.secondary,
                textTransform: 'none',
              },
              day: { width: theme.spacing.h, height: theme.spacing.h },
            }}
          />
        </DayPickerWrap>
      )}
    </>
  );
};

export default DateInput;
