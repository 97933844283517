import { BodyMedium, BoxWithImage, HeadingMedium, theme, usePath } from '@arnold/common';
import { ReactComponent as EmptySurveys } from '@arnold/common/src/assets/images/EmptySurveys.svg';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EDITION } from '../../lib/common';
import { TopicGroupTypeCode } from '../../generated/hooks';

interface Props {
  edition?: string;
}

export const NoExitsInfo: FC<Props> = ({ edition }) => {
  const { t } = useTranslation('surveyGroup');
  const { conversationSubjectsPath, orderArnoldPath } = usePath();
  const withoutExits = !edition || [EDITION.FREE.name, EDITION.TEAMIO_ARNOLD.name].includes(edition);

  return (
    <BoxWithImage image={<EmptySurveys />}>
      <div data-cy={'admin-no-exit'}>
        <HeadingMedium margin={`0 0 ${theme.spacing.f} 0`}>{t('noExit')}</HeadingMedium>
        <BodyMedium>{withoutExits ? t('noFreeExitText') : t('noExitText')}</BodyMedium>
        <Button
          variant={'outline-primary'}
          data-icom={withoutExits ? 'open-order-form' : 'start-exit'}
          data-cy={withoutExits ? 'btn-open-order-form' : 'btn-create-exit'}
          className="align-self-start"
          onClick={() =>
            withoutExits ? orderArnoldPath.toFunc() : conversationSubjectsPath.toFunc(TopicGroupTypeCode.Exit)
          }
        >
          {withoutExits ? t('editionSettings:orderButton') : t('startExit')}
        </Button>
      </div>
    </BoxWithImage>
  );
};
