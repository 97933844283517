import { HeadingLarge, theme } from '@arnold/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { isSurveyActive } from '../../lib/helpers';
import { DashboardQuery, SurveyStatus, OrganizationQuery } from '../../generated/hooks';
import { handleSummaryResultsClick } from '../../lib/common';
import { ActiveSurveys } from './ActiveSurveys';
import { CompletedSurveys } from './CompletedSurveys';
import { Onboarding } from './Onboarding';
import { ProcessSurveys } from './ProcessSurveys';

type DashboardProps = {
  organization: NonNullable<OrganizationQuery['organization']>;
  dashboardData: DashboardQuery['user']['organization'];
  user: NonNullable<DashboardQuery['user']>;
};

export const Dashboard: FC<DashboardProps> = ({ organization, dashboardData, user }) => {
  const { t } = useTranslation('dashboard');

  const activeSurveys = dashboardData?.currentSurveys.filter((survey) => isSurveyActive(survey.status));
  const completedSurveys = dashboardData?.currentSurveys.filter((survey) => survey.status === SurveyStatus.Completed);

  const onboardings = dashboardData?.activeOnboadings;

  const isWithoutSurveys =
    dashboardData?.currentSurveys.length === 0 ||
    (dashboardData?.activeProcessSurveys.length === 0 && dashboardData.activeOnboadings.length === 0);
  return (
    <>
      <HeadingLarge margin={`128px 0 ${theme.spacing.f}`} data-icom={'heading-dashboard'} data-cy={'heading-dashboard'}>
        {t('overview')}
      </HeadingLarge>
      {!!onboardings?.length && <Onboarding surveyGroups={onboardings} organization={organization} />}
      <ActiveSurveys
        surveys={activeSurveys}
        enabledRespondentsCount={organization.enabledRespondentsCount}
        organization={organization}
        isWithoutSurveys={isWithoutSurveys}
        onResultsClick={() => handleSummaryResultsClick(user.id, organization.id)}
      />
      <ProcessSurveys
        organization={organization}
        activeProcessSurveys={dashboardData?.activeProcessSurveys}
        onResultsClick={() => handleSummaryResultsClick(user.id, organization.id)}
      />
      <CompletedSurveys
        surveys={completedSurveys}
        onResultsClick={() => handleSummaryResultsClick(user.id, organization.id)}
      />
      {!onboardings?.length && <Onboarding surveyGroups={[]} organization={organization} />}
    </>
  );
};
