import { MutableRefObject, useEffect, useMemo } from 'react';

export const useIsVisible = (ref: MutableRefObject<Element | null>, handle: (isVisible: boolean) => void) => {
  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        handle(entry.isIntersecting);
      }),
    [handle],
  );

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);
};
