import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { theme } from '../theme';

interface IHeadingProps {
  margin?: string;
  color?: string;
  bold?: boolean;
  ellipsis?: number;
}

const ellipsisStyle = (rows: number) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${rows};
  -webkit-box-orient: vertical;
`;

export const HeadingXLarge = styled.h1`
  margin: ${({ margin }: IHeadingProps) => margin || '32px 0 16px'};
  line-height: ${theme.typography.heading.xLarge.default.lineHeight};
  font-weight: ${theme.typography.heading.xLarge.default.fontWeight};
  font-size: ${theme.typography.heading.xLarge.default.fontSize};
  font-family: 'Quicksand';
  color: ${({ color }: IHeadingProps) => color || `${theme.colors.text.primary}`};
`;

export const HeadingLarge = styled.h1`
  margin: ${({ margin }: IHeadingProps) => margin || '32px 0 16px'};
  line-height: ${theme.typography.heading.large.default.lineHeight};
  font-weight: ${theme.typography.heading.large.default.fontWeight};
  font-size: ${theme.typography.heading.large.default.fontSize};
  font-family: 'Roboto';
  color: ${({ color }: IHeadingProps) => color || `${theme.colors.text.primary}`};
`;

export const HeadingMedium = styled.h2`
  margin: ${({ margin }: IHeadingProps) => margin || '32px 0 16px'};
  line-height: ${theme.typography.heading.medium.default.lineHeight};
  font-weight: ${theme.typography.heading.medium.default.fontWeight};
  font-size: ${theme.typography.heading.medium.default.fontSize};
  font-family: 'Roboto';
  color: ${({ color }: IHeadingProps) => color || `${theme.colors.text.primary}`};
`;

export const HeadingSmall = styled.h3`
  margin: ${({ margin }: IHeadingProps) => margin || '0'};
  line-height: ${theme.typography.heading.small.default.lineHeight};
  font-weight: ${theme.typography.heading.small.default.fontWeight};
  font-size: ${theme.typography.heading.small.default.fontSize};
  font-family: 'Roboto';
  color: ${({ color }: IHeadingProps) => color || `${theme.colors.text.primary}`};
`;

export const HeadingSmallWDescription = styled(HeadingSmall)`
  margin-bottom: ${theme.spacing.c};
  color: ${({ color }: IHeadingProps) => color || `${theme.colors.text.primary}`};
`;

export const BodyLarge = styled.p<IHeadingProps>`
  margin: ${({ margin }) => margin || '0'};
  color: ${theme.colors.text.primary};
  line-height: ${theme.typography.body.large.regular.lineHeight};
  font-weight: ${({ bold }) =>
    bold ? `${theme.typography.body.large.bold.fontWeight}` : `${theme.typography.body.large.regular.fontWeight}`};
  font-size: ${theme.typography.body.large.regular.fontSize};
  font-family: 'Roboto', sans-serif;
  color: ${({ color }) => color || `${theme.colors.text.primary}`};
  ${({ ellipsis }) => (ellipsis ? ellipsisStyle(ellipsis) : ``)}
`;

export const BodyMedium = styled.p<IHeadingProps>`
  margin: ${({ margin }) => margin || '0'};
  color: ${theme.colors.text.primary};
  line-height: ${theme.typography.body.medium.regular.lineHeight};
  font-weight: ${({ bold }) =>
    bold ? `${theme.typography.body.medium.bold.fontWeight}` : `${theme.typography.body.medium.regular.fontWeight}`};
  font-size: ${theme.typography.body.medium.regular.fontSize};
  font-family: 'Roboto', sans-serif;
  color: ${({ color }) => color || `${theme.colors.text.primary}`};
  ${({ ellipsis }) => (ellipsis ? ellipsisStyle(ellipsis) : ``)}
`;

export const BodySmall = styled.p<IHeadingProps>`
  margin: ${({ margin }) => margin || '0'};
  line-height: ${theme.typography.body.small.regular.lineHeight};
  font-weight: ${({ bold }) =>
    bold ? `${theme.typography.body.small.bold.fontWeight}` : `${theme.typography.body.small.regular.fontWeight}`};
  font-size: ${theme.typography.body.small.regular.fontSize};
  font-family: 'Roboto', sans-serif;
  color: ${({ color }) => color || `${theme.colors.text.primary}`};
  ${({ ellipsis }) => (ellipsis ? ellipsisStyle(ellipsis) : ``)}
`;

export const SecondarySpan = styled.span`
  color: ${theme.colors.text.secondary};
`;
