import { getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import styled from '@emotion/styled';
import { isMatch, isValid, parse } from 'date-fns';
import * as locale from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { DayPicker, useInput } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CancelCloseIcon } from '../../assets/icons/CancelClose.svg';
import { ThemeType, theme } from '../../theme';
import { CleanButton } from '../buttons';
import { Tooltip } from '../others';

type IProps = {
  fromYear?: Date;
  toYear?: Date;
  onDateChange: (value: string | null) => void;
  value?: string | null;
  label: string;
  className?: string;
  placeholder?: string;
  dataCy?: string;
  baseTranslationPath?: string;
};

type IDateInputProps = {
  theme?: ThemeType;
  isAlert: boolean;
};

const CancelButton = styled(CleanButton)`
  position: absolute;
  bottom: 11px;
  right: ${theme.spacing.f};
  height: 18.33px;
  padding: 0;
  margin: 0;
  &:focus {
    outline: none;
  }
`;

const DateError = styled.div`
  position: absolute;
  margin-left: ${theme.spacing.f};
  margin-top: ${theme.spacing.c};
  margin-bottom: ${theme.spacing.e};
  color: ${theme.colors.emotionDanger.default};
  font-size: ${theme &&
  theme.typography.body.small &&
  theme.typography.body.small.regular &&
  theme.typography.body.small.regular.fontSize};
`;

const DateInput = styled.input`
  border: ${({ isAlert }: IDateInputProps) =>
    `${theme?.spacing.a} solid ${isAlert ? theme?.colors.emotionDanger.default : theme?.colors.borderMain.default}`};
  color: ${theme.colors.text.primary};
  height: 39px;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  padding: ${theme.spacing.d} ${theme.spacing.l} ${theme.spacing.d} ${theme.spacing.f};
  font-size: initial;
  outline: none;
`;

const Label = styled.div`
  color: ${theme.colors.text.secondary};
  padding-left: ${theme.spacing.f};
  padding-bottom: ${theme.spacing.c};
  font-size: 0.75rem !important;
`;

const Container = styled.div`
  position: relative;
`;

const getDayInUtc = (year: number, month: number, date: number) => new Date(Date.UTC(year, month, date, 0, 0, 0));

export const DatePicker = ({
  fromYear,
  toYear,
  onDateChange,
  value,
  label,
  className,
  placeholder,
  dataCy,
  baseTranslationPath,
}: IProps) => {
  const { t, i18n } = useTranslation(baseTranslationPath ?? 'common');
  const [isDayPickerOpen, setIsDayPickerOpen] = useState<boolean>(false);
  const [month, setMonth] = useState<Date>(new Date());
  const [dateInputValue, setDateInputValue] = useState<string | null>(null);
  const [dateInputError, setDateInputError] = useState<string | null>(null);
  // has to be type any ReturnType<typeof setTimeout> does not match type number of clearTimeout
  const [closeDayPickerTimer, setCloseDayPickerTimer] = useState<any>(undefined);

  const blockDayPickerClose = () => {
    clearTimeout(closeDayPickerTimer);
  };

  useEffect(() => {
    setDateInputValue(value ? getLocalizedDateWithoutLeadingZeroes(new Date(value), i18n.language) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setMonth(value ? new Date(value) : new Date());
    setDateInputValue(value ? getLocalizedDateWithoutLeadingZeroes(new Date(value), i18n.language) : null);
    setIsDayPickerOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { inputProps, dayPickerProps } = useInput({
    defaultSelected: new Date(),
    format: 'P',
    required: true,
    locale: locale[i18n.language],
  });

  const handleDateChange = (selectedDay: Date, modifiers: any) => {
    selectedDay.setHours(0);
    const selectedDayInUtc = getDayInUtc(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate());
    onDateChange(selectedDayInUtc.toISOString());
    setDateInputError(null);
    setDateInputValue(
      selectedDayInUtc ? getLocalizedDateWithoutLeadingZeroes(new Date(selectedDayInUtc), i18n.language) : null,
    );
  };

  const handleClearFilter = () => {
    onDateChange(null);
    setDateInputError(null);
    setDateInputValue(null);
  };

  const parseInputValue = (inputValue: string) => {
    const trimValue = inputValue.replace(/\s/g, '');

    const inputDate =
      (isMatch(trimValue, 'd.M.yyyy') && parse(trimValue, 'd.M.yyyy', new Date())) ||
      (isMatch(trimValue, 'd/M/yyyy') && parse(trimValue, 'd/M/yyyy', new Date())) ||
      (isMatch(trimValue, 'dd.MM.yyyy') && parse(trimValue, 'dd.MM.yyyy', new Date())) ||
      (isMatch(trimValue, 'dd/MM/yyyy') && parse(trimValue, 'dd/MM/yyyy', new Date())) ||
      (isMatch(trimValue, 'yyyy.MM.dd') && parse(trimValue, 'yyyy.MM.dd', new Date())) ||
      (isMatch(trimValue, 'yyyy.MM.dd.') && parse(trimValue, 'yyyy.MM.dd.', new Date())) ||
      (isMatch(trimValue, 'yyyy/MM/dd') && parse(trimValue, 'yyyy/MM/dd', new Date())) ||
      (isMatch(trimValue, 'yyyy.M.d') && parse(trimValue, 'yyyy.M.d', new Date())) ||
      (isMatch(trimValue, 'yyyy.M.d.') && parse(trimValue, 'yyyy.M.d.', new Date())) ||
      (isMatch(trimValue, 'yyyy/M/d') && parse(trimValue, 'yyyy/M/d', new Date()));

    if (!inputDate || !isValid(inputDate)) {
      setDateInputError(t('incorrectDate'));
      return;
    }

    setDateInputError(null);
    return getDayInUtc(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleInputChange();
    }
    if (e.key === 'Escape') {
      setIsDayPickerOpen(false);
      e.target.blur();
    }
  };

  const handleInputChange = (e?: any) => {
    setCloseDayPickerTimer(setTimeout(() => setIsDayPickerOpen(false), 200));
    if (dateInputValue === '') {
      setDateInputError(null);
      return;
    }

    if (dateInputValue) {
      const date = parseInputValue(dateInputValue!);

      if (date) {
        onDateChange(date.toISOString());
      }
    }
  };

  return (
    <Container data-cy={dataCy} className={className}>
      <Label>{label}</Label>
      <DateInput
        {...inputProps}
        onChange={(e) => setDateInputValue(e.target.value)}
        onBlur={(e) => handleInputChange(e)}
        onFocus={() => setIsDayPickerOpen(true)}
        onKeyDown={handleKeyDown}
        value={dateInputValue || ''}
        placeholder={placeholder ?? t('dateFilterPlaceholder')}
        isAlert={!!dateInputError}
        data-cy={dataCy ? `${dataCy}-input` : null}
      />
      {dateInputError && <DateError>{dateInputError}</DateError>}

      {value && (
        <Tooltip title={t('clear')}>
          <CancelButton onClick={handleClearFilter} data-cy="report-cancel-date-filter">
            <CancelCloseIcon />
          </CancelButton>
        </Tooltip>
      )}
      {isDayPickerOpen && (
        <div onClick={blockDayPickerClose}>
          <DayPicker
            {...dayPickerProps}
            mode={'single'}
            month={month}
            onMonthChange={setMonth}
            selected={value ? new Date(value) : undefined}
            fromYear={fromYear?.getFullYear()}
            toYear={toYear?.getFullYear()}
            onDayClick={handleDateChange}
            locale={locale[i18n.language]}
            styles={{
              root: {
                position: 'absolute',
                backgroundColor: theme.colors.backgroundCover.default,
                margin: 0,
                minWidth: '290px',
                boxShadow: theme.shadows.elevationContentDialog,
                borderRadius: '6px',
                zIndex: 1000,
              },
              months: { margin: theme.spacing.f },
              caption_label: { ...theme.typography.heading.medium!.default },
              head_cell: {
                ...theme.typography.body.medium!.regular,
                color: theme.colors.text.secondary,
                textTransform: 'none',
              },
              day: { width: theme.spacing.h, height: theme.spacing.h },
            }}
          />
        </div>
      )}
    </Container>
  );
};

export default DatePicker;
