import styled from '@emotion/styled';
import { Form, Formik, FormikErrors, FormikHelpers } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import { ReactComponent as VisibilityOnIcon } from '@arnold/common/lib/assets/icons/VisibilityOn.svg';
import { ReactComponent as VisibilityOffIcon } from '@arnold/common/lib/assets/icons/VisibilityOff.svg';
import { theme } from '@arnold/common';
import { FormControlPassword, FormErrorFeedback, FormGroupLabel } from '../../components/Common';
import { ActionsWrap, AppLink, LinkWrap, ShowPasswordButton } from '../../components/StyledComponents';

const LoginButton = styled(Button)`
  width: 100%;
  margin: 0;
  @media (min-width: ${theme.breakpoint.small}) {
    width: auto;
  }
`;

interface IProps {
  lang?: string;
  handleSignIn: (
    email: string,
    password: string,
    otp: string | undefined,
    setErrors: (errors: FormikErrors<IFormValues>) => void,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => void;
}

interface IFormValues {
  email: string;
  password: string;
  responseError?: string;
  emailError?: string;
}

const LoginForm = ({ lang, handleSignIn }: IProps) => {
  const [t] = useTranslation('loginForm');
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().required(t('fillEmail')),
    password: Yup.string().required(t('fillPassword')),
  });

  const initialValues: IFormValues = {
    email: '',
    password: '',
    responseError: undefined,
    emailError: undefined,
  };

  const handleSubmit = async (values: any, { setErrors, setSubmitting }: FormikHelpers<IFormValues>) => {
    await handleSignIn(values.email, values.password, undefined, setErrors, setSubmitting);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={LoginSchema}>
      {({ errors, handleChange, handleSubmit, isSubmitting, values }) => (
        <Form noValidate={true} onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel data-icom="login-email-label">{t('email')}</FormGroupLabel>
            <FormControl
              type="text"
              name="email"
              value={values.email || ''}
              onChange={handleChange}
              isInvalid={!!errors.responseError || !!errors.emailError}
            />
            {errors.emailError && <FormErrorFeedback error={errors.emailError} />}
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>{t('password')}</FormGroupLabel>
            <FormControlPassword
              style={{
                paddingRight: 40,
              }}
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={values.password || ''}
              onChange={handleChange}
              isInvalid={!!errors.responseError}
            />
            <ShowPasswordButton
              onClick={(e) => {
                if (e.screenX || e.screenY) {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }
              }}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
            </ShowPasswordButton>
            {errors.responseError && <FormErrorFeedback error={errors.responseError} />}
          </FormGroup>
          <ActionsWrap>
            <LoginButton variant="primary" type="submit" disabled={isSubmitting} data-icom="login-submit">
              {t('signIn')}
            </LoginButton>
            <LinkWrap>
              <AppLink to={`/recoverPassword${lang ? `/${lang}` : ''}`} data-cy={'admin-reset-password-button'}>
                {t('forgottenPassword')}
              </AppLink>
            </LinkWrap>
          </ActionsWrap>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
