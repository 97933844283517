import { QuestionType, QuestionType as QuestionTypeFromHooks } from '../../generated/hooks';

export enum QUESTION_DEFINITION_TYPE {
  select = 'SelectQuestionDefinition',
  multiselect = 'PersonMultiselectQuestionDefinition',
  freetext = 'FreeTextQuestionDefinition',
  smsContact = 'SmsContactQuestionDefinition',
  emailContact = 'EmailContactQuestionDefinition',
  ending = 'EndingQuestionDefinition',
  tell = 'CommentQuestionDefinition',
}

export enum QUESTION_RULE_TYPE {
  rangeRule = 'RangeRule',
  arrayRule = 'ArrayRule',
  textRule = 'TextRule',
  alwaysMatchingRule = 'AlwaysMatchingRule',
}

export const QUESTION_TYPE_MAPPING = {
  SelectQuestionDefinition: QuestionType.Select,
  FreeTextQuestionDefinition: QuestionType.Freetext,
  EndingQuestionDefinition: QuestionType.Ending,
  CommentQuestionDefinition: QuestionType.Tell,
  PersonMultiselectQuestionDefinition: QuestionType.PersonMultiselect,
  SmsContactQuestionDefinition: QuestionType.SmsContact,
  EmailContactQuestionDefinition: QuestionType.EmailContact,
};

export const QUESTION_TYPE_FROM_HOOKS_MAPPING = {
  SelectQuestionDefinition: QuestionTypeFromHooks.Select,
  FreeTextQuestionDefinition: QuestionTypeFromHooks.Freetext,
  EndingQuestionDefinition: QuestionTypeFromHooks.Ending,
  CommentQuestionDefinition: QuestionTypeFromHooks.Tell,
  PersonMultiselectQuestionDefinition: QuestionTypeFromHooks.PersonMultiselect,
  SmsContactQuestionDefinition: QuestionTypeFromHooks.SmsContact,
  EmailContactQuestionDefinition: QuestionTypeFromHooks.EmailContact,
};
