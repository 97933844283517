import { BodyLarge, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import i18n from '../../translations/i18n';
import { PreviewChatForm } from './PreviewChatForm';
import { PreviewReportForm } from './PreviewReportForm';

enum Options {
  Chat = 'CHAT',
  Report = 'REPORT',
}

const Container = styled.div`
  text-align: center;
`;

const Header = styled.h1`
  font-size: 33px;
  font-style: normal;
  font-weight: 500;
  line-height: 145%;
`;

const PeriodSwitchWrapper = styled.div`
  text-align: center;
  padding-top: ${theme.spacing.f};
`;

const PeriodSwitch = styled.div`
  height: 32px;
  display: inline-flex;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: ${theme.colors.actionPrimary.default};
  background-color: #fff;
  border-radius: 50px;
`;

const PeriodSwitchItem = styled.div`
  position: relative;
`;

const PeriodSwitchRadio = styled.input`
  position: absolute;
  z-index: -1;
  opacity: 0;
`;

const PeriodSwitchLabel = styled.label<{ checked: boolean }>((props) => ({
  padding: `${theme.spacing.c} ${theme.spacing.h}`,
  borderRadius: '50px',
  cursor: 'pointer',
  userSelect: 'none',
  color: props.checked ? theme?.colors.textInverted.primary : 'inherit',
  backgroundColor: props.checked ? theme?.colors.actionPrimary.active : 'inherit',
  ...theme.typography.body.medium?.button,
}));

const FormContainer = styled.div`
  max-width: 387px;
  margin: auto;
`;

export const PreviewFormScreen = () => {
  const [t] = useTranslation('preview');
  const [selectedOption, setSelectedOption] = useState<Options>(Options.Chat);
  const { lang } = useParams<{ lang: string }>();

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <Container data-cy="preview-form">
      <Header>{t('tryArnold')}</Header>
      <BodyLarge>{t('tryArnoldInfo')}</BodyLarge>
      <PeriodSwitchWrapper>
        <PeriodSwitch>
          <PeriodSwitchItem>
            <PeriodSwitchLabel
              onClick={() => setSelectedOption(Options.Chat)}
              checked={selectedOption === Options.Chat}
              data-cy="radio-chat"
            >
              <PeriodSwitchRadio
                id={Options.Chat}
                checked={selectedOption === Options.Chat}
                defaultChecked
                type={'radio'}
                value={Options.Chat}
                name={'option'}
              />
              {t('chat')}
            </PeriodSwitchLabel>
          </PeriodSwitchItem>
          <PeriodSwitchItem>
            <PeriodSwitchLabel
              onClick={() => setSelectedOption(Options.Report)}
              checked={selectedOption === Options.Report}
              data-cy="radio-report"
            >
              <PeriodSwitchRadio
                id={Options.Report}
                checked={selectedOption === Options.Report}
                type={'radio'}
                value={Options.Report}
                name={'option'}
              />
              {t('report')}
            </PeriodSwitchLabel>
          </PeriodSwitchItem>
        </PeriodSwitch>
      </PeriodSwitchWrapper>
      <FormContainer>
        {selectedOption === Options.Chat && <PreviewChatForm />}
        {selectedOption === Options.Report && <PreviewReportForm />}
      </FormContainer>
    </Container>
  );
};
