import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { OrganizationTeamsQuery } from '../../generated/hooks';
import TreeView from './TreeView';

export type Props = {
  teams: NonNullable<OrganizationTeamsQuery['organizationTeams']>;
};

type Tree =
  | {
      parent: string;
      team: NonNullable<OrganizationTeamsQuery['organizationTeams']>[0];
      peopleCount: number;
    }
  | undefined;

export const TeamTree = ({ teams }: Props) => {
  const { t } = useTranslation('teams');

  const roots = teams.filter((value) => value.rootTeam);
  const rest = teams.filter((value) => !value.rootTeam);

  const tree: Tree[] = rest
    .map((team) => {
      const root = teams.filter(
        (candidateTeam) => candidateTeam.members.filter((member) => team.leader && member.id === team.leader.id)[0],
      );
      if (root.length === 0) {
        return null;
      }
      return { parent: root[0].id, team, peopleCount: 0 };
    })
    .filter((a) => a !== null) as Tree[];

  roots.forEach((team) => {
    tree.push({ parent: '_', team, peopleCount: 0 });
  });

  const countSubs = (teamId: string, count: number = 0): number => {
    const children: Tree[] = tree.filter((value) => value && value.parent === teamId);

    children.forEach((value) => {
      if (value) {
        count += value.team.members.length;
        value.team.members.forEach((member) => {
          const team = rest.filter((team) => team.leader && team.leader.id === member.id)[0];
          if (team) {
            count += countSubs(team.id, team.members.length - 1);
          }
        });
      }
    });

    return count + 1;
  };

  const prepareRender = (root: NonNullable<OrganizationTeamsQuery['organizationTeams']>[0], tree: Tree[]): any => {
    const children: Tree[] = tree.filter((value) => value && value.parent === root.id);
    return (
      <TreeView
        nodeLabel={root.name || t('withoutLeader')}
        peopleCount={countSubs(root.id, root.members.length - 1)}
        key={root.id}
        defaultCollapsed={false}
        labelLink={root.id}
      >
        {children.map((value) => value && prepareRender(value.team, tree))}
      </TreeView>
    );
  };

  const toRender = roots.map((root) => prepareRender(root, tree));

  const Section = styled.div`
    background: #ffffff;
    box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.01);
    border-radius: 6px;
    padding: 38px 38px 46px 38px;
    margin: 16px 0 0;
  `;

  return <Section>{toRender}</Section>;
};
