import { PureQueryOptions } from '@apollo/client';
import { ActionMenu, LinkButton, theme, useToast } from '@arnold/common';
import { ReactComponent as ActionMenuIcon } from '@arnold/common/lib/assets/icons/ActionMenuIcon.svg';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { TFunction } from 'react-i18next';
import { goToTalkResults } from '../../lib/helpers';
import { ReportAccessQuery, SequenceReportAccessesQuery } from '../../generated/hooks';
import { getPrimaryActionTitle, useAcceptReport, useSuspendReport } from './utils';
import { SurveyTableItemType } from './types';

type ActionButtonsProps = {
  report:
    | NonNullable<NonNullable<ReportAccessQuery['survey']>['reports']>[0]
    | NonNullable<SequenceReportAccessesQuery['surveyGroup']>['reports'][0];
  reportBasePath: string;
  isOngoing: boolean;
  type: SurveyTableItemType;
  t: TFunction;
  dependantQueries: Array<string | PureQueryOptions>;
};

const ActionMenuContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.f};
  float: right;
`;

const DropdownContainer = styled.span`
  display: inline-block;
  margin: 5px 0;
`;

export const ActionButtons: FC<ActionButtonsProps> = ({
  type,
  reportBasePath,
  report,
  isOngoing,
  t,
  dependantQueries,
}) => {
  const { addToast } = useToast();

  const isProcess = type === SurveyTableItemType.Sequence;
  const canBeChanged = dependantQueries.length > 0;

  const acceptResults = useAcceptReport(t, addToast, report, dependantQueries);
  const suspendReport = useSuspendReport(t, addToast, report, dependantQueries);

  const acceptEnabled = !report.accepted || !isOngoing;
  const suspendEnabled = !(report.sent || report.accepted === false);

  return (
    <ActionMenuContainer>
      {report.accepted === null && canBeChanged && (isProcess || isOngoing) && (
        <DropdownContainer>
          <ActionMenu
            dataIcom="btn-reports-action-menu"
            toggleIcon={<ActionMenuIcon />}
            items={[
              {
                label: t('dontAccept'),
                action: () => suspendReport(),
              },
            ]}
          />
        </DropdownContainer>
      )}
      {(acceptEnabled || suspendEnabled) && canBeChanged && (
        <LinkButton
          onClick={() => (acceptEnabled ? acceptResults() : suspendReport())}
          data-cy={acceptEnabled ? 'btn-accept-report' : 'btn-suspend-report'}
        >
          {getPrimaryActionTitle(isOngoing, report.accepted!, report.sent, t)}
        </LinkButton>
      )}
      <Button
        onClick={() => goToTalkResults(reportBasePath, report.accessKey)}
        variant={'outline-primary'}
        size="sm"
        data-icom={'btn-report'}
        data-cy={'btn-report'}
      >
        {dependantQueries.length > 0 ? t('report') : t('results')}
      </Button>
    </ActionMenuContainer>
  );
};
