import styled from '@emotion/styled';
import { BodyMedium, Breadcrumb, HeadingLarge, theme } from '@arnold/common';
import { FC, JSXElementConstructor, PropsWithChildren, ReactElement } from 'react';
import { SurveyScreenHeading } from '../StyledComponents';
import { PageWrapper } from './Layout';

type PageLayoutProps = {
  title?: string;
  description?: string | null;
  headingBox?: ReactElement<any, string | JSXElementConstructor<any>>;
  buttons?: Array<ReactElement<any, string | JSXElementConstructor<any>>>;
  breadcrumbItems?: Array<{ title: React.ReactNode; link?: string }>;
  dataIcom?: string;
};

const HeadingParagraphWrap = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: ${theme.spacing.k};
  display: flex;
  align-items: center;
  margin-left: ${theme.spacing.k};
`;

export const PageLayout: FC<PropsWithChildren<PageLayoutProps>> = ({
  breadcrumbItems,
  title,
  description,
  buttons,
  headingBox,
  children,
  dataIcom,
}) => {
  return (
    <PageWrapper data-cy="admin-page-content">
      {breadcrumbItems && <Breadcrumb items={breadcrumbItems} />}
      <SurveyScreenHeading>
        <HeadingParagraphWrap>
          {title && <HeadingLarge data-icom={dataIcom}>{title}</HeadingLarge>}
          {description && <BodyMedium>{description}</BodyMedium>}
          {headingBox}
        </HeadingParagraphWrap>
        {buttons?.length && <ButtonContainer>{buttons.map((button) => button)}</ButtonContainer>}
      </SurveyScreenHeading>
      {children}
    </PageWrapper>
  );
};
