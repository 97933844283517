import React from 'react';
import { useTranslation } from 'react-i18next';

import { BodyMedium, HeadingXLarge, theme } from '@arnold/common';
import { ReactComponent as LogoInBubble } from '@arnold/common/lib/assets/logo/LogoInBubble.svg';
import { ReactComponent as LogoPrimary } from '@arnold/common/lib/assets/logo/LogoPrimaryDarkBkg.svg';

import { HeadingContainer, LogoContainer, RegistrationAsideContent } from '../../components/StyledComponents';

export const RecoverPasswordAside = () => {
  const { t } = useTranslation('recoverPassword');
  return (
    <RegistrationAsideContent>
      <LogoInBubble />
      <HeadingContainer>
        <HeadingXLarge color={theme.colors.textInverted.primary} margin={'0'}>
          {t('resetPassword')}
        </HeadingXLarge>
      </HeadingContainer>
      <BodyMedium color={theme.colors.textInverted.primary} margin={`0 0 ${theme.spacing.h}`}>
        {t('recoverPasswordInfo')}
      </BodyMedium>
      <LogoContainer>
        <LogoPrimary width="228" height="57" />
      </LogoContainer>
    </RegistrationAsideContent>
  );
};

export default RecoverPasswordAside;
