import { useTranslation } from 'react-i18next';
import { ConversationSubjects } from '../../components/ConversationSubjects';
import { getLinkToTopicOverview } from '../../lib/topic';

const ConversationSubjectsScreen = () => {
  const { t } = useTranslation('surveysOverview');

  return (
    <ConversationSubjects getLink={getLinkToTopicOverview} heading={t('chooseSurveyTopic')} breadcrumbItems={[]} />
  );
};

export default ConversationSubjectsScreen;
