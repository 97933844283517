import styled from '@emotion/styled';
import { ReactNode, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { theme } from '../../theme';

export interface BeautifulToastConfig {
  message?: string;
  icon?: ReactNode;
  buttonText?: string;
  buttonOnClick?: () => void;
  textColor?: string;
  backgroundColor?: string;
  duration?: number;
  lastCalledTime?: number;
}

export const BeautifulToast = (props: BeautifulToastConfig) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    requestAnimationFrame(() => setVisible(true));
    const id = setTimeout(() => {
      setVisible(false);
    }, props.duration || 5000);
    return () => {
      clearTimeout(id);
      setVisible(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.lastCalledTime]);

  if (!visible) {
    return null;
  }
  const textColor = props.textColor || 'white';
  const backgroundColor = props.backgroundColor || theme.colors.ai.default;
  const duration = props.duration || 5000;

  return (
    <ToastWrapper>
      <ToastContainer backgroundColor={backgroundColor} textColor={textColor} duration={duration}>
        <div className="d-flex align-items-center" style={{ gap: '12px' }}>
          {props.icon}
          <div>{props.message}</div>
          {props.buttonText && (
            <StyledButton
              size="sm"
              backgroundColor={textColor}
              textColor={backgroundColor}
              onClick={() => {
                props.buttonOnClick?.();
                setVisible(false);
              }}
            >
              {props.buttonText}
            </StyledButton>
          )}
        </div>
      </ToastContainer>
    </ToastWrapper>
  );
};

const StyledButton = styled(Button)<{ backgroundColor: string; textColor: string }>`
  background-color: ${(props) => props.backgroundColor};
  border-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  margin-left: ${theme.spacing.f};
  font-size: ${theme.typography.body.small.regular.fontSize};
  &:hover,
  &:focus,
  &:active {
    background-color: ${(props) => props.textColor} !important;
    border-color: ${(props) => props.backgroundColor} !important;
    color: ${(props) => props.backgroundColor} !important;
  }
`;

const ToastWrapper = styled.div`
  position: fixed;
  z-index: 10000000;
  bottom: ${theme.spacing.f};
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

const ToastContainer = styled.div<{ backgroundColor: string; textColor: string; duration: number }>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  padding: 24px 20px;
  border-radius: 4px;
  box-shadow: 0px 5px 30px 0px #0000004d;
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 600px;
  pointer-events: auto;

  animation: toast-animation ${(props) => props.duration / 1000}s linear;

  @keyframes toast-animation {
    0% {
      transform: translateY(100px);
    }
    3% {
      transform: translateY(0px);
    }
    95% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(100px);
    }
  }
`;
