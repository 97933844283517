import { IThemeProps, LanguageSelector, ThemeType, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { Col, Row } from 'react-bootstrap';
import { ArnoldLogo } from '../../assets/images';
import { allowedLanguages, handleLanguageChange } from '../../lib/common';

export { Col } from 'react-bootstrap';

interface IComponentStyleProps extends IThemeProps {
  margin?: string;
}

export const Card = styled.div`
  padding: 16px 32px;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  background: ${({ theme }: IComponentStyleProps) => theme && theme.colors.backgroundCover.default};
  margin: ${({ margin }: IComponentStyleProps) => margin || '0'};
`;

export const TitleCard = styled(Card)`
  margin-bottom: ${({ theme }: IThemeProps) => theme && theme.spacing.c};
`;

export const HeaderRow = styled(Row)`
  padding: 16px 0;
  color: ${({ theme }: IThemeProps) => theme && theme.colors.text.secondary};
  font-size: 12px;
  line-height: 140%;
`;

export const NormalRow = styled(Row)`
  border-top: 1px solid #e7e7e7;
  padding: 16px 0;
  color: ${({ theme }: IThemeProps) => theme && theme.colors.text.primary};
`;

export const NormalRowHovered = styled(NormalRow)`
  font-weight: ${({ theme }: IThemeProps) => theme && theme.typography.body.medium?.regular?.fontWeight};
  &:hover {
    border-radius: 6px;
    background: ${({ theme }: IThemeProps) => theme && theme.colors.backgroundCover.hover};
    &:first-child {
      color: ${({ theme }: IThemeProps) => theme && theme.colors.actionPrimary.hover};
    }
  }
`;

export const CenteredCol = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const PageWrapper = styled.div`
  max-width: 928px;
  margin-left: auto;
  margin-right: auto;
`;

export const GraySpan = styled.span`
  color: ${({ theme }: IThemeProps) => theme?.colors.text.secondary};
  display: inline-block;
`;

export const DisabledSpan = styled.span`
  color: ${({ theme }: IThemeProps) => theme?.colors.text.disabled};
  margin-right: ${({ theme }: IThemeProps) => theme?.spacing.d};
  display: inline-block;
`;

export const SelectedSpan = styled.span`
  color: ${({ theme }: IThemeProps) => theme?.colors.actionPrimary};
  display: inline-block;
`;

const Brand = styled.div`
  width: 126px;

  @media (min-width: 992px) {
    width: 192px;
  }
`;

const RowWrapper = styled.div`
  width: 100%;
  padding: ${theme.spacing.b} ${theme.spacing.h} ${theme.spacing.d};
  background: ${theme.colors.backgroundCover.default};
  margin-bottom: ${theme.spacing.i};
`;

const NavRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PublicNavbar = () => (
  <RowWrapper>
    <NavRow>
      <Brand>
        <img src={ArnoldLogo} width={192} height={48} alt="Arnold" />
      </Brand>
      <div style={{ flex: '1' }} />
      <div className="mt-5">
        <LanguageSelector onChange={handleLanguageChange} allowedLanguages={[...allowedLanguages]} />
      </div>
    </NavRow>
  </RowWrapper>
);

export const ScreenWrapper = styled.div((props: { theme?: ThemeType }) => ({
  width: '100%',
  height: '100%',
  backgroundColor: props.theme ? props.theme.colors.backgroundBasic.default : 'initial',
}));
