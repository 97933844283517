import styled from '@emotion/styled';
import { FC } from 'react';
import { ReactComponent as LeftChevron } from '../../assets/icons/Left-Chevron.svg';
import { theme } from '../../theme';

export interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 18px;
`;

const PageNumber = styled.span<{ selected: boolean; isNumber: boolean }>`
  cursor: ${({ isNumber, selected }) => (isNumber && selected === false ? 'pointer' : 'default')};
  width: ${theme.spacing.h};
  margin-left: ${theme.spacing.c};
  margin-right: ${theme.spacing.c};
  text-align: center;
  padding-bottom: ${theme.spacing.b};
  color: ${({ selected }) => (selected ? theme.colors.text.primary : theme.colors.text.secondary)};
  ${({ selected }) => selected === true && `box-shadow: 0px 2px 0px ${theme.colors.actionPrimary.default};`}
  ${({ isNumber, selected }) =>
    isNumber &&
    selected === false &&
    `
    &:hover {
      cursor: 'pointer';
      color: ${theme.colors.actionPrimary.hover};
    }
  `};
`;

const Arrow = styled.div<{ disabled: boolean; right?: boolean }>`
  width: ${theme.spacing.f};
  padding-bottom: ${theme.spacing.b};
  line-height: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  transform: ${({ right }) => (right ? 'scaleX(-1)' : 'initial')};
  svg {
    transform: scale(1.2);
  }
  path {
    height: 22px;
    fill: ${({ disabled }) => (disabled ? theme.colors.text.disabled + ' !important' : theme.colors.text.secondary)};
  }
  &:hover {
    path {
      fill: ${theme.colors.actionPrimary.hover};
    }
  }
`;

export const Pagination: FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers: (string | number)[] = [];

  if (totalPages <= 1) {
    return <></>;
  }

  pageNumbers.push(1);
  for (let i = 2; i < totalPages; i++) {
    if (i + 1 >= currentPage && i - 1 <= currentPage) {
      // if this number is close to current page, we should show it
      pageNumbers.push(i);
    } else {
      // else show ..… if they have not been shown
      if (pageNumbers[pageNumbers.length - 1] !== '…') {
        if ((i + 2 >= currentPage && i - 1 <= currentPage) || i + 1 === totalPages) {
          pageNumbers.push(i);
        } else {
          pageNumbers.push('…');
        }
      }
    }
  }
  pageNumbers.push(totalPages);

  const handlePageClick = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      onPageChange(page);
    }
  };

  return (
    <PaginationContainer>
      <Arrow disabled={currentPage === 1} onClick={() => handlePageClick(currentPage - 1)}>
        <LeftChevron />
      </Arrow>
      {pageNumbers.map((pageNumber, index) => (
        <PageNumber
          key={index}
          data-cy={`page-${pageNumber}`}
          selected={pageNumber === currentPage}
          isNumber={typeof pageNumber === 'number'}
          onClick={() => typeof pageNumber === 'number' && handlePageClick(pageNumber)}
        >
          {pageNumber}
        </PageNumber>
      ))}
      <Arrow disabled={currentPage === totalPages} onClick={() => handlePageClick(currentPage + 1)} right>
        <LeftChevron />
      </Arrow>
    </PaginationContainer>
  );
};
