import { TFunction } from 'react-i18next';
import { cleanArray, isNullish, TableColumn } from '@arnold/common';
import { getTimeStringForOffset, Offset } from '../../lib/process';
import { StepsEditData } from './types';

export const getColumns = (t: TFunction, hideActions?: boolean): Array<TableColumn<StepsEditData>> =>
  cleanArray([
    {
      key: 'name',
      title: t('name'),
      dataIndex: 'name',
    },
    {
      key: 'startOffsetMins',
      title: t('start'),
      dataIndex: 'startOffsetMins',
    },
    {
      key: 'reminderOffsetMins',
      title: t('noReminder', { count: 1 }),
      dataIndex: 'reminderOffsetMins',
    },
    {
      key: 'secondReminderOffsetMins',
      title: t('noReminder', { count: 2 }),
      dataIndex: 'secondReminderOffsetMins',
    },
    {
      key: 'thirdReminderOffsetMins',
      title: t('noReminder', { count: 3 }),
      dataIndex: 'thirdReminderOffsetMins',
    },
    {
      key: 'endOffsetMins',
      title: t('end'),
      dataIndex: 'endOffsetMins',
    },
    !hideActions && {
      key: 'actions',
      title: undefined,
      dataIndex: 'actions',
    },
  ]);

export const getOffsetString = (offset: Offset, t: TFunction) => {
  if (isNullish(offset) || isNullish(offset.days)) {
    return null;
  }
  return `${t('processDetail:dayText', {
    dayNo: offset.days,
  })}, ${getTimeStringForOffset(offset)}`;
};
