import { BodyMedium, BoxWithImage } from '@arnold/common';
import { ReactComponent as EmptySurveys } from '@arnold/common/src/assets/images/EmptySurveys.svg';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const NoTopicsInfo: FC = () => {
  const { t } = useTranslation('processDetail');
  return (
    <BoxWithImage image={<EmptySurveys />}>
      <div data-cy="process-no-topics-info">
        <BodyMedium>{t('noTopicsInfo')}</BodyMedium>
      </div>
    </BoxWithImage>
  );
};
