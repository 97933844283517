import { FC } from 'react';
import { usePath } from '@arnold/common';
import { AppLink } from '../StyledComponents';
import { TopicGroupQuery } from '../../generated/hooks';

type ProcessStepLinkProps = {
  step: NonNullable<TopicGroupQuery['topicGroup']['steps']>[0];
  processTopicGroupId: string;
  label?: string;
};

export const ProcessStepLink: FC<ProcessStepLinkProps> = ({ step, processTopicGroupId, label }) => {
  const { processStepPath } = usePath();

  return <AppLink to={processStepPath.createPath(processTopicGroupId, step.id)}>{label}</AppLink>;
};
