import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { theme } from '../../../theme';
import { CardHeaderContainer, Header } from '../SurveyCardComponents';

type SmallSurveyCardProps = {
  header: string;
  headerIcon: string;
  results: ReactNode;
  footer: ReactNode;
  dataIcom?: string;
};

export const SmallSurveyCard: FC<SmallSurveyCardProps> = ({ header, headerIcon, results, footer, dataIcom }) => {
  return (
    <StylesSurveyCard data-icom={dataIcom}>
      <StyledCardHeaderContainer>
        <HeaderIcon src={headerIcon} />
        <Header>{header}</Header>
      </StyledCardHeaderContainer>
      {results && <Content>{results}</Content>}
      <Footer>{footer}</Footer>
    </StylesSurveyCard>
  );
};

const StylesSurveyCard = styled.div`
  width: 288px;
  background-color: ${theme.background.primary};
  border-radius: 6px;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.01);
  padding: ${theme.spacing.g} 20px 20px 20px;
`;

const HeaderIcon = styled.img`
  width: ${theme.spacing.g};
  height: ${theme.spacing.g};
`;

const StyledCardHeaderContainer = styled(CardHeaderContainer)`
  justify-content: center;
  padding-bottom: ${theme.spacing.e};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;
