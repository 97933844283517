import { Trans, useTranslation } from 'react-i18next';
import { BodySmall, HeadingMedium, HeadingSmall, theme } from '@arnold/common';
import React from 'react';
import styled from '@emotion/styled/macro';
import { Button, ButtonProps } from 'react-bootstrap';

interface IEditionCardProps {
  active: boolean;
}

const EditionCard = styled.div`
  width: 100%;
  max-width: 400px;
  flex: 1 0 0%;
  display: flex;
  flex-direction: column;
  margin-inline: auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.3125rem;
  border: ${({ active }: IEditionCardProps) =>
    active ? `${theme.spacing.a} solid ${theme.colors.brand.primary}` : 'none'};
`;

const ButtonWrap = styled.div`
  margin-top: ${theme.spacing.h};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: end;
`;

const BottomButtonWrap = styled(ButtonWrap)`
  height: -webkit-fill-available;
`;

const StyledButton = styled(Button)<ButtonProps>(({ disabled }) => ({
  height: 'fit-content',
  margin: 0,
}));

const TitleCallToActionWrap = styled.div`
  margin: ${theme.spacing.h} 0 0 0;
  min-height: 61.2px;
`;

interface IEditionProps {
  product: string;
  save?: number;
  price: number;
  currency: string;
  priceInfo: string;
  buttonText: string;
  buttonCallToAction: string;
  titleCallToAction: string;
  titleHighlights: string;
  active: boolean;
  disabled: boolean;
  onClick: () => void;
}

const Edition = ({
  product,
  save,
  price,
  currency,
  priceInfo,
  buttonText,
  buttonCallToAction,
  titleCallToAction,
  titleHighlights,
  active,
  disabled,
  onClick,
}: IEditionProps) => {
  const [t] = useTranslation('pricelist');

  const Button = () => (
    <StyledButton variant={active ? 'primary' : 'outline-primary'} disabled={disabled} onClick={() => onClick()}>
      {buttonText}
    </StyledButton>
  );

  return (
    <EditionCard active={active} data-cy={`admin-plan-${product}`}>
      <HeadingSmall margin={`0 0 ${theme.spacing.h} 0`}>{t(`editionSettings:label-${product}`)}</HeadingSmall>
      {!!save && (
        <BodySmall color={theme.colors.emotionInformative.default}>
          {t('savings')} {save} %
        </BodySmall>
      )}
      <HeadingMedium margin={`${save ? theme.spacing.d : '25.4px'} 0 0 0`}>{`${price} ${currency}`}</HeadingMedium>
      <BodySmall color={theme.colors.text.secondary}>{priceInfo}</BodySmall>
      <ButtonWrap>
        <Button />
      </ButtonWrap>
      <BodySmall margin={`${theme.spacing.f} 0 0 0`} color={theme.colors.text.secondary}>
        {buttonCallToAction}
      </BodySmall>
      <TitleCallToActionWrap>
        <HeadingSmall>{titleCallToAction}</HeadingSmall>
      </TitleCallToActionWrap>
      <HeadingSmall margin={`${theme.spacing.l} 0 0 0`}>{titleHighlights}:</HeadingSmall>
      <Trans
        i18nKey={`pricelist:highlights${product}`}
        components={[
          ...Array(7)
            .fill('_')
            .map((_, i) => <BodySmall key={i} margin={`${theme.spacing.f} 0 0 0`} />),
        ]}
      >
        Edition highlights
      </Trans>
      <BottomButtonWrap>
        <Button />
      </BottomButtonWrap>
    </EditionCard>
  );
};

export default Edition;
