import { Checkbox, Tooltip, theme } from '@arnold/common';
import { getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import { Col, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SurveyStatus, SurveysQuery } from '../../../generated/hooks';
import {
  StyledFormGroupLabel,
  StyledTable,
  SurveysTableColumn,
  SurveysTableHeader,
  SurveysTableHeaderWithRadio,
} from './SharedComponents';

interface Props {
  surveys: NonNullable<SurveysQuery['user']['organization']>['surveys'];
  standaloneSurveysToAdd: string[];
  setStandaloneSurveysToAdd: React.Dispatch<React.SetStateAction<string[]>>;
}

const StandaloneSurveySelect: React.FC<Props> = ({ surveys, standaloneSurveysToAdd, setStandaloneSurveysToAdd }) => {
  const { t, i18n } = useTranslation(['updateRespondent', 'addRespondent']);

  return (
    <FormGroup as={Col} sm={8} data-cy={'standalone-survey-list-to-add'}>
      <StyledFormGroupLabel>{t('addToOneTimeSurveys')}</StyledFormGroupLabel>
      <StyledTable>
        <thead>
          <tr>
            <SurveysTableHeaderWithRadio style={{ marginLeft: `${theme.spacing.i}` }}>
              {t('name')}
            </SurveysTableHeaderWithRadio>
            <SurveysTableHeader style={{ width: '25%' }}>{t('startDate')}</SurveysTableHeader>
            <SurveysTableHeader style={{ width: '20%' }}>{t('participants')}</SurveysTableHeader>
          </tr>
        </thead>
        <tbody>
          {surveys.map((survey) => {
            const disabled = survey.status === SurveyStatus.Prepared && survey.whitelistedRespondents.length > 0;
            return (
              <tr key={survey.id} data-cy={'onboarding-row-to-add'}>
                <SurveysTableColumn disabled={disabled}>
                  <Tooltip title={survey.topic?.name || survey.topicGroup?.name}>
                    <FormGroup>
                      <Checkbox
                        disabled={disabled}
                        selected={standaloneSurveysToAdd.includes(survey.id) || disabled}
                        onToggle={() =>
                          standaloneSurveysToAdd.includes(survey.id)
                            ? setStandaloneSurveysToAdd(standaloneSurveysToAdd.filter((id) => id !== survey.id))
                            : setStandaloneSurveysToAdd([...standaloneSurveysToAdd, survey.id])
                        }
                        text={survey.topic?.name || survey.topicGroup?.name}
                        labelStyles="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100%;vertical-align: middle;margin-bottom: 0;"
                      />
                    </FormGroup>
                  </Tooltip>
                </SurveysTableColumn>
                <SurveysTableColumn disabled={disabled} data-cy={'added-on-date'}>
                  {getLocalizedDateWithoutLeadingZeroes(new Date(survey.sendAt), i18n.language)}
                </SurveysTableColumn>
                <SurveysTableColumn disabled={disabled} textAlign={'center'}>
                  {survey.resultsInfo?.answered}/{survey.resultsInfo?.total}
                </SurveysTableColumn>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </FormGroup>
  );
};

export default StandaloneSurveySelect;
