import { useTranslation } from 'react-i18next';
import { Button, Card } from 'react-bootstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { isEmpty } from 'ramda';
import { BodyMedium, CardBody } from '@arnold/common';
import { FC } from 'react';
import { SalesValues } from '../types';
import { CS_LANGUAGE } from '../constants';
import { SalesFields } from './ScreenFields';
import { OrgAdminAlreadyExistsModal } from './OrgAdminAlreadyExistsModal';

type SalesFormProps = {
  handleSubmit: (values: SalesValues) => void;
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
};
export const SalesForm: FC<SalesFormProps> = ({ handleSubmit: handleSubmitMaster, isModalOpen, setModalOpen }) => {
  const { t } = useTranslation('salesForm');

  const OrderFormValidationSchema = Yup.object().shape({
    organizationName: Yup.string().required(t('fields.organizationName.validation')),
    language: Yup.string().required(t('fields.language.validation')),
    vatNumber: Yup.string().required(t('fields.vatNumber.validation')),

    respondentsCount: Yup.number().required(t('fields.respondentsCount.validation')),

    adminEmail: Yup.string().required(t('fields.adminEmail.validation')).email(t('fields.adminEmail.validation')),
    adminName: Yup.string().required(t('fields.adminName.validation')),
    adminSurname: Yup.string().required(t('fields.adminSurname.validation')),
  });

  return (
    <Formik<SalesValues>
      initialValues={{
        organizationName: '',
        language: CS_LANGUAGE,
        vatNumber: '',

        respondentsCount: 100,

        adminEmail: '@',
        adminName: '',
        adminSurname: '',
      }}
      validationSchema={OrderFormValidationSchema}
      onSubmit={handleSubmitMaster}
    >
      {({ values, errors, handleSubmit, setFieldValue, isSubmitting }) => (
        <>
          <OrgAdminAlreadyExistsModal
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
            handleSubmit={() => handleSubmitMaster({ ...values, ignoreOrgAdminAlreadyExists: true })}
          />
          <Form onSubmit={handleSubmit}>
            <Card>
              <CardBody>
                <BodyMedium>{t('info')}</BodyMedium>
                <SalesFields errors={errors} setFieldValue={setFieldValue} values={values} />
                <div>
                  <Button variant="primary" type="submit" disabled={!isEmpty(errors) || isSubmitting}>
                    {t('submitButton')}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </Form>
        </>
      )}
    </Formik>
  );
};
