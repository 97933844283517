import { ActionMenu, ActionMenuIcon, TabItem, Tabs, getFrontendConfigValue, theme, usePath } from '@arnold/common';
import { REPORT_LINK } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { subDays } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { useHistory } from 'react-router-dom';
import { GraphQLErrorCard } from '../../components';
import { PageLayout } from '../../components/Common/PageLayout';
import { goToTopicDetail } from '../../components/GeneralProcessSurveyTopicDetail/utils';
import Loading from '../../components/Loading';
import { SurveyDetailContent } from '../../components/StyledComponents';
import ArchiveSurvey from '../../components/SurveysOverview/ArchiveSurvey';
import useArchive from '../../components/SurveysOverview/useArchive';
import {
  AnonymityLevel,
  SurveyStatus,
  UserQuery,
  useActiveRespondentsQuery,
  useSurveyQuery,
  useUserQuery,
} from '../../generated/hooks';
import ee from '../../lib/eventEmitter';
import { getTopLevelReportAccess, goToTalkResults, isSurveyFinished, isSurveyPlanned } from '../../lib/helpers';
import i18n from '../../translations/i18n';
import { AddRespondentToOngoingSurveyScreen } from './AddRespondentToOngoingSurveyScreen';
import EditSurveyParticipants from './EditSurveyParticipants';
import { SurveyDetailTable } from './SurveyDetailTable';
import { SurveyInfo } from './SurveyInfo';
import SurveyReportAccess from './SurveyReportAccess';
import SurveyScheduling from './SurveyScheduling';

type Props = RouteComponentProps<any> & {
  user: UserQuery['user'];
};

const AddRespondentsButton = styled(Button)`
  margin-left: 12px;
  white-space: nowrap;
`;

const ArchivedSurveyText = styled.span`
  color: ${theme.colors.text.primary};
  font-size: ${theme.typography.body.small.regular.fontSize};
  font-weight: ${theme.typography.body.small.regular.fontWeight};
  line-height: ${theme.typography.body.small.regular.lineHeight};
`;

export const PATHS = {
  RESPONDENTS: 'respondents',
  EDIT_RESPONDENTS: 'editRespondents',
  RESULTS: 'results',
  SCHEDULING: 'scheduling',
  ADD_RESPONDENTS_TO_ONGOING_SURVEY: 'addRespondents',
};

export const SurveyDetailScreen: FC<Props> = ({
  match: {
    params: { surveyId, type },
  },
}) => {
  const [t] = useTranslation('survey');
  const [surveysOverviewT] = useTranslation('surveysOverview');
  const { loading, data } = useSurveyQuery({ variables: { surveyId }, fetchPolicy: 'network-only' });

  const history = useHistory();
  const { surveyArchivePath, surveysPath, surveyDetailPath, reopenSurveyPath } = usePath();

  const { loading: respondentsLoading, data: respondentsData } = useActiveRespondentsQuery({ fetchPolicy: 'no-cache' });

  const { loading: userLoading, data: userData } = useUserQuery({ variables: { languageCode: i18n.language } });

  const { handleArchive, loading: archiveLoading } = useArchive({
    survey: data?.survey,
    organization: userData?.user.organization,
  });

  const lang = i18n.language;
  const [tabType, setTabType] = useState(Object.values(PATHS).includes(type) ? type : PATHS.RESULTS);

  useEffect(() => {
    setTabType(Object.values(PATHS).includes(type) ? type : PATHS.RESULTS);
  }, [type]);

  if (loading || respondentsLoading || userLoading || archiveLoading || !data || !userData) {
    return <Loading />;
  }

  if (!userData.user.organization?.surveys.find((survey) => survey.id === surveyId)) {
    return <GraphQLErrorCard />;
  }

  const respondents = data.survey?.whitelistedRespondents || [];
  const isArchived = data!.survey?.status === SurveyStatus.Archived;

  const headingTranslation =
    data.survey?.topicGroup?.translations?.find((translation) => translation.language.code === lang) ||
    data.survey?.topicGroup?.translations?.find(
      (translation) => translation.language.code === userData.user.organization?.primaryLanguageCode,
    );

  const emptyReport = getTopLevelReportAccess(data.survey?.reports);
  const reopenSurveyButton =
    data.survey?.status === SurveyStatus.Completed &&
    data.survey.endsAt &&
    new Date(data.survey.endsAt) >=
      subDays(new Date(), Number(getFrontendConfigValue('ALLOW_REOPENING_IN_DAYS', '14')));

  const headingButtons = () => {
    const isPlanned = isSurveyPlanned(data.survey!.status);
    const buttons = [];
    const menuItems = [];

    if (data.survey?.status === SurveyStatus.Ongoing) {
      if (!!data.survey.topic?.id) {
        menuItems.push({
          label: t('topicDetail'),
          action: () => goToTopicDetail(Number.parseInt(data.survey!.topic!.id, 10)),
        });
      }
      if (!data.survey.public) {
        menuItems.push({
          label: t('addParticipants'),
          action: () => history.push(`/surveyDetail/${surveyId}/${PATHS.ADD_RESPONDENTS_TO_ONGOING_SURVEY}`),
        });
      }
    }

    if (reopenSurveyButton) {
      menuItems.push({
        label: t('surveyOverviewRow:archive'),
        action: () => handleArchive(),
      });

      menuItems.push({
        label: t('reopenScreen:reopen'),
        action: () => reopenSurveyPath.toFunc(surveyId),
      });
    } else if (isSurveyFinished(data.survey!.status)) {
      buttons.push(
        <ArchiveSurvey
          survey={data.survey}
          organization={userData.user.organization}
          user={userData.user}
          showButton={true}
        />,
      );
    }

    if (!!menuItems.length) {
      buttons.push(
        <div className="d-inline-block my-5">
          <ActionMenu
            toggleIcon={<ActionMenuIcon />}
            items={menuItems}
            margin={`0 ${theme.spacing.f} 0 0`}
            dataIcom={'btn-dropdown'}
          />
        </div>,
      );
    }

    if (isPlanned) {
      buttons.push(
        <AddRespondentsButton
          onClick={() => history.push(`/surveyDetail/${surveyId}/${PATHS.EDIT_RESPONDENTS}`)}
          data-cy="change-respondents"
        >
          {t('changeParticipants')}
        </AddRespondentsButton>,
      );
    }

    if (data.survey?.public && data.survey?.status === SurveyStatus.Ongoing) {
      buttons.push(
        <Button
          onClick={() => ee.emitModalSharePublicSurvey(data.survey?.accessKey!, headingTranslation?.value || '')}
          variant={'primary'}
          size="sm"
        >
          {t('surveyForm:shareSurvey')}
        </Button>,
      );
    }

    if (!isPlanned) {
      buttons.push(
        <Button
          onClick={() => {
            if (emptyReport?.accessKey) {
              goToTalkResults(REPORT_LINK.REPORT, emptyReport.accessKey);
            }
          }}
          variant={'primary'}
          size="sm"
          disabled={!emptyReport?.accessKey}
        >
          {t('surveyOverviewRow:summaryReport')}
        </Button>,
      );
    }

    return buttons;
  };

  const answered = data.survey?.resultsInfo?.answered || 0;
  const total = data.survey?.resultsInfo?.total;

  const surveyInFuture =
    data.survey?.status === SurveyStatus.Prepared || data.survey?.status === SurveyStatus.Notstarted;
  const activeRespondents = respondentsData?.user.organization?.activeRespondents || [];

  const breadcrumbItems = isArchived
    ? [{ title: surveysOverviewT('surveyArchive'), link: surveyArchivePath.createPath() }]
    : [
        { title: surveysOverviewT('standaloneSurveys'), link: surveysPath.createPath() },
        {
          title: t(`status-${data.survey?.status}`),
          link: surveysPath.createPath(data!.survey?.status.toLowerCase() || '/'),
        },
      ];

  if (tabType === PATHS.EDIT_RESPONDENTS) {
    return (
      <EditSurveyParticipants
        respondents={respondents}
        notStarted={surveyInFuture}
        activeRespondents={activeRespondents}
        teams={respondentsData?.user.organization?.teams || []}
        surveyId={Number.parseInt(surveyId, 10)}
        user={userData.user}
        sendEmailSms={!!data.survey?.sendEmailSms}
        organization={userData.user.organization}
        closeForm={() => surveyDetailPath.toFunc(surveyId, PATHS.RESPONDENTS)}
        breadcrumbItems={[
          ...breadcrumbItems,
          { title: headingTranslation?.value ?? '', link: surveyDetailPath.createPath(surveyId) },
        ]}
      />
    );
  }

  if (tabType === PATHS.ADD_RESPONDENTS_TO_ONGOING_SURVEY) {
    return (
      <AddRespondentToOngoingSurveyScreen
        respondentIds={data.survey?.channels
          ?.filter((channel) => !!channel.respondent)
          .map((channel) => channel.respondent!.id)}
        breadcrumbItems={[
          ...breadcrumbItems,
          { title: headingTranslation?.value ?? '', link: surveyDetailPath.createPath(surveyId) },
        ]}
        id={data.survey?.id}
      />
    );
  }

  return (
    <PageLayout
      breadcrumbItems={breadcrumbItems}
      dataIcom={'heading-survey-title'}
      title={headingTranslation?.value}
      description={headingTranslation?.description || undefined}
      headingBox={
        <SurveyInfo
          answered={answered}
          total={total}
          status={data.survey?.status ?? ''}
          sendAt={new Date(data.survey?.sendAt).toLocaleDateString(lang)}
          endsAt={data.survey?.endsAt ? new Date(data.survey.endsAt).toLocaleDateString(lang) : undefined}
          isPublic={!!data.survey?.public}
        />
      }
      buttons={headingButtons()}
    >
      {!isArchived && (
        <Tabs
          items={
            [
              {
                title: t('reportSettings'),
                link: `/surveyDetail/${surveyId}/${PATHS.RESULTS}`,
                active: tabType === PATHS.RESULTS,
                icomTag: 'tab-survey-detail-report',
              },
              !data.survey?.public && {
                title: t('surveyTeams'),
                link: `/surveyDetail/${surveyId}/${PATHS.RESPONDENTS}`,
                active: tabType === PATHS.RESPONDENTS,
                icomTag: 'tab-survey-detail-teams',
              },
              {
                title: t('scheduling'),
                link: `/surveyDetail/${surveyId}/${PATHS.SCHEDULING}`,
                active: tabType === PATHS.SCHEDULING,
                icomTag: 'tab-survey-detail-scheduling',
              },
            ].filter(Boolean) as TabItem[]
          }
          theme={theme}
        />
      )}
      {isArchived ? (
        <SurveyDetailContent>
          <ArchivedSurveyText>{t('surveyIsArchived')}</ArchivedSurveyText>
        </SurveyDetailContent>
      ) : (
        <SurveyDetailContent>
          {tabType === PATHS.RESPONDENTS && (
            <SurveyDetailTable
              surveyTeamResults={data.survey?.teamsResultInfo?.filter(Boolean) || undefined}
              emptyReportAccess={emptyReport?.accessKey}
              channels={data.survey?.channels || []}
              isSurveyOngoing={data.survey?.status === SurveyStatus.Ongoing}
              surveyId={String(surveyId)}
              surveyEndsAt={data.survey?.endsAt || ''}
              isAnonymityEnabled={!!data.survey?.anonymitySettings.allowedLevels?.includes(AnonymityLevel.Organization)}
            />
          )}

          {tabType === PATHS.RESULTS && <SurveyReportAccess surveyId={Number.parseInt(surveyId, 10)} />}

          {tabType === PATHS.SCHEDULING && (
            <SurveyScheduling
              surveyId={Number.parseInt(surveyId, 10)}
              notStarted={surveyInFuture}
              survey={data.survey}
              displayReopenButton={reopenSurveyButton}
              organization={userData.user.organization}
              user={userData.user}
            />
          )}
        </SurveyDetailContent>
      )}
    </PageLayout>
  );
};
