import * as React from 'react';

interface ISendButtonProps {
  color: string;
  size: number;
}

const SendButton = ({ color, size }: ISendButtonProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width={size} height={size} viewBox="0 0 24 24" fill={color}>
    <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
  </svg>
);

export default SendButton;
