import { BodyMedium, Box, BoxSmallLabel, ProductHistory, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ActiveRespondentsQuery,
  ProcessStatus,
  SurveyGroupWithGroupedRespondentsQuery,
  SurveyGroupsQuery,
  SurveyStatus,
} from '../../generated/hooks';
import { sortRespondents } from '../../lib/common';
import DateInput from '../Common/DateInput';
import { SurveyRespondentsTable } from '../Forms/SurveyParticipantsTable';
import { DateSelector, DateSelectorInputGroup, WarningText } from '../StyledComponents';
import { ChangeAddedOnButton } from './CommonComponents';
import { SurveyGroupSurveysPreviewTable } from './SurveyGroupSurveysPreviewTable';
import { SurveyGroupSurveysTable } from './SurveyGroupSurveysTable';
import TeamSelect from './TeamSelect';

const LayoutComplement = styled.span`
  display: inline-block;
  width: 370px;
  height: 10px;
  padding-left: 32px;
`;

const SelectDescription = styled(BoxSmallLabel)`
  width: 400px;
`;

type RespondentGroupProps = {
  group: NonNullable<SurveyGroupWithGroupedRespondentsQuery['surveyGroup']>['groupedRespondents'][0];
  removeRespondent: (respondentIds: string[]) => void;
  alreadySelectedRespondents: string[];
  addedOn: Date | null;
  forceDisplaySubmit?: boolean;
  allowAddNew?: boolean;
  submitButtonText: string;
  topicGroup?: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]['topicGroup'];
  activeRespondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'];
  addRespondents: (
    respondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'],
  ) => Promise<void>;
  onSubmit: (newAddedOn: Date) => void;
  teams?: NonNullable<ActiveRespondentsQuery['user']['organization']>['teams'];
  status?: ProcessStatus;
  loading?: boolean;
  deletedRespondents?: string[];
  respondentGroups?: NonNullable<SurveyGroupWithGroupedRespondentsQuery['surveyGroup']>['groupedRespondents'];
  remainingCount?: number;
  productHistory?: ProductHistory[] | null;
};

const RespondentGroup = ({
  addedOn,
  onSubmit,
  group,
  removeRespondent,
  forceDisplaySubmit,
  alreadySelectedRespondents,
  activeRespondents,
  submitButtonText,
  addRespondents,
  teams,
  status,
  loading,
  deletedRespondents,
  respondentGroups,
  allowAddNew,
  topicGroup,
  remainingCount,
  productHistory,
}: RespondentGroupProps) => {
  const { t } = useTranslation('surveyGroup');
  const [activeDate, setActiveDate] = useState(addedOn);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const submitAddedOn = async () => {
    setIsSubmitDisabled(true);
    if (activeDate) {
      await onSubmit(activeDate);
    }
    setIsSubmitDisabled(false);
  };
  const [respondents, setRespondents] = useState(
    activeRespondents.filter(
      (respondent) => !deletedRespondents?.includes(respondent.id) || group.deletedRespondents?.includes(respondent.id),
    ),
  );
  const addedOnHasChanged = addedOn?.getTime() !== activeDate?.getTime();
  const isDateSelectorDisabled = group.surveys.some((survey) => survey.status !== SurveyStatus.Prepared);
  const isCompleted =
    group.surveys.length > 0 && group.surveys.every((survey) => survey.status === SurveyStatus.Completed);

  const notDeletedRespondents = group.respondents.filter(
    (respondent) => !group.deletedRespondents?.includes(respondent.id),
  );

  useEffect(() => {
    const deletedGroupedRespondentIds = respondentGroups
      ?.find((group) => new Date(group.addedOn).getTime() === activeDate?.getTime())
      ?.respondents.map((respondent) => respondent.id);
    setRespondents(
      activeRespondents.filter(
        (respondent) =>
          deletedGroupedRespondentIds?.includes(respondent.id) || !deletedRespondents?.includes(respondent.id),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDate]);

  return (
    <Box className={'mb-3'}>
      {
        <>
          <BoxSmallLabel data-icom={'label-survey-group-added-on'}>{t('start')}</BoxSmallLabel>
          <DateSelectorInputGroup>
            <DateSelector>
              <DateInput
                onDateChange={(value) => setActiveDate(new Date(value.toISOString().substring(0, 10) + 'T00:00:00Z'))}
                inputName={'addedOn'}
                placeholder={t('selectDate')}
                date={activeDate?.toISOString()}
                disabled={isDateSelectorDisabled}
              />
            </DateSelector>
            {(addedOnHasChanged || forceDisplaySubmit) && (
              <ChangeAddedOnButton
                variant="primary"
                className={'btn-empty'}
                type="submit"
                disabled={isSubmitDisabled || !group.respondents.length || !activeDate}
                onClick={submitAddedOn}
              >
                {submitButtonText}
              </ChangeAddedOnButton>
            )}
          </DateSelectorInputGroup>
        </>
      }
      {status !== ProcessStatus.Archived && allowAddNew && (
        <>
          <SelectDescription className="mt-8" data-icom={'label-survey-group-respondents'}>
            {t('addRespondentsTitle')}
          </SelectDescription>
          <div className="d-flex mb-4" data-cy="admin-respondent-group">
            <TeamSelect
              setRespondents={addRespondents}
              teams={teams}
              activeRespondents={respondents}
              respondents={notDeletedRespondents}
              alreadySelectedRespondents={alreadySelectedRespondents}
              loading={loading}
              isDisabled={isCompleted}
              remainingCount={remainingCount}
            />
            <LayoutComplement />
          </div>

          {typeof remainingCount === 'number' && remainingCount < 1 && (
            <BodyMedium margin={`0 0 0 ${theme.spacing.f}`}>
              <WarningText>{t('teamioOnb:usageExceededForSelect')}</WarningText>
            </BodyMedium>
          )}
        </>
      )}

      {notDeletedRespondents.length > 0 && (
        <div className="mt-8">
          <SurveyRespondentsTable
            respondents={sortRespondents(notDeletedRespondents)}
            removeRespondent={removeRespondent}
            isDisabled={isCompleted}
          />
          <BodyMedium margin={`${theme.spacing.g} 0 0 0`}>
            {t('surveyForm:respondentsCount', {
              count: notDeletedRespondents.length,
            })}
          </BodyMedium>
        </div>
      )}

      {
        <>
          {!addedOnHasChanged && activeDate && topicGroup && group.respondents.length > 0 && (
            <SurveyGroupSurveysTable
              topicGroup={topicGroup}
              surveys={group.surveys.filter((s) => !s.changedSeqDate)}
              addedOn={group.addedOn}
            />
          )}
        </>
      }
      {
        <>
          {allowAddNew && topicGroup && (
            <SurveyGroupSurveysPreviewTable
              topicGroup={topicGroup}
              addedOn={activeDate}
              productHistory={productHistory}
            />
          )}
        </>
      }
    </Box>
  );
};

export default RespondentGroup;
