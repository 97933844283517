import { keyframes } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import React from 'react';

const ErrorIcon = () => (
  <Circle>
    <Wrapper>
      <Left />
      <Right />
    </Wrapper>
  </Circle>
);

const AnimateCircle = keyframes`
  0% {
    transform: rotateX(100deg);
    -webkit-transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    -webkit-transform: rotateX(0deg);
    opacity: 1;
  }
`;
const Circle = styled.div(() => ({
  width: 88,
  height: 88,
  borderWidth: 4,
  borderStyle: 'solid',
  borderColor: 'rgb(212, 63, 58)',
  borderRadius: '50%',
  margin: '20px auto',
  position: 'relative',
  boxSizing: 'border-box',
  animation: `0.5s ease 0s 1 normal none running ${AnimateCircle}`,
}));

const AnimateCicrleContent = keyframes`
  0% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    transform: scale(0.4);
    -webkit-transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    transform: scale(1.15);
    -webkit-transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
`;

const Wrapper = styled.span(() => ({
  position: 'relative',
  display: 'block',
  animation: `0.5s ease 0s 1 normal none running ${AnimateCicrleContent}`,
}));

const Left = styled.span(() => ({
  position: 'absolute',
  height: 5,
  width: 47,
  backgroundColor: 'rgb(217, 83, 79)',
  display: 'block',
  top: 37,
  borderRadius: 2,
  transform: 'rotate(45deg)',
  left: 17,
}));

const Right = styled.span(() => ({
  position: 'absolute',
  height: 5,
  width: 47,
  backgroundColor: 'rgb(217, 83, 79)',
  display: 'block',
  top: 37,
  borderRadius: 2,
  transform: 'rotate(-45deg)',
  right: 16,
}));

export default ErrorIcon;
