import { usePath, useToast } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { SurveyQuery, SurveyStatus, UserQuery, useSetSurveyArchivedMutation } from '../../generated/hooks';
import { getSurvey, getUserQuery } from '../../graphql/queries';
import { getOperationName } from '../../lib/common';

type ArchiveSurveyProps = {
  survey?: SurveyQuery['survey'] | NonNullable<UserQuery['user']['organization']>['surveys'][0];
  organization?: UserQuery['user']['organization'] | null;
};

const useArchive = ({ survey, organization }: ArchiveSurveyProps) => {
  const [t] = useTranslation('surveyOverviewRow');
  const { surveysPath, surveyArchivePath } = usePath();

  const { addToast } = useToast();

  const isArchived = survey?.status === SurveyStatus.Archived;

  const handleMutationError = () => {
    addToast(isArchived ? t('dearchiveSurveyFailed') : t('archiveSurveyFailed'));
  };

  const handleMutationCompleted = (archived: boolean) => {
    addToast(archived ? t('dearchiveSurveySuccess') : t('archiveSurveySuccess'));
    if (archived) {
      surveysPath.toFunc(SurveyStatus.Completed.toLowerCase());
    } else {
      surveyArchivePath.toFunc();
    }
  };

  const [setSurveyArchived, { loading }] = useSetSurveyArchivedMutation({
    onCompleted: () => handleMutationCompleted(!isArchived),
    onError: handleMutationError,
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(getUserQuery) || '', getOperationName(getSurvey) || ''],
  });

  const handleArchive = async () => {
    if (!survey) {
      return;
    }
    await setSurveyArchived({
      variables: {
        surveyId: survey.id,
        reverse: isArchived,
      },
    });
  };

  return {
    handleArchive,
    loading,
    isArchived,
  };
};

export default useArchive;
