import React from 'react';
import { useTranslation } from 'react-i18next';
import { BodyMedium, BodySmall, CardBody, CardTitle, HeadingSmall, theme, Tooltip } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { Card } from 'react-bootstrap';
import { ReactComponent as InfoIcon } from '../assets/images/InfoIcon.svg';

interface IProps {
  customName: string;
  systemName: string;
}

const CardContainer = styled.div`
  display: flex;
  padding-top: ${theme.spacing.g};
`;

const NameContainer = styled.div`
  width: 50%;
`;

const BodySmallDisabled = styled(BodySmall)`
  color: ${theme.colors.text.disabled};
  padding-botom: ${theme.spacing.c};
`;

const TooltipContainer = styled.div`
  display: flex;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: ${theme.spacing.d};
  display: flex;
  align-items: center;
`;

const OrganizationCard = ({ customName, systemName }: IProps) => {
  const [t] = useTranslation('settings');

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <HeadingSmall>{t('organizationName')}</HeadingSmall>
          <BodySmall>{t('organizationNameInfo')}</BodySmall>
        </CardTitle>
        <CardContainer>
          <NameContainer>
            <BodySmallDisabled>{t('systemName')}</BodySmallDisabled>
            <BodyMedium>{systemName}</BodyMedium>
          </NameContainer>
          <NameContainer>
            <BodySmallDisabled>{t('customName')}</BodySmallDisabled>
            <TooltipContainer>
              <BodyMedium>{customName}</BodyMedium>

              <StyledTooltip title={t('customNameTooltip')}>
                <InfoIcon />
              </StyledTooltip>
            </TooltipContainer>
          </NameContainer>
        </CardContainer>
      </CardBody>
    </Card>
  );
};

export default OrganizationCard;
