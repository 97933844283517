import { useToast } from '@arnold/common';
import { uniq } from 'ramda';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  MessageEventType,
  SurveyGroupRespondentsQuery,
  SurveyGroupWithoutRespondentsQuery,
  useLastMessagesForRespondentLazyQuery,
} from '../../../../generated/hooks';
import { Table } from '../../../Table/Table';
import { getDataSource } from './getDataSource';
import { ParticipantDetailData } from './types';
import { getColumns } from './utils';

export type ParticipantDetailProps = {
  surveyGroup: NonNullable<SurveyGroupWithoutRespondentsQuery['surveyGroup']>;
  respondent: SurveyGroupRespondentsQuery['surveyGroupRespondents']['data'][0]['respondents'][0];
  respondentGroup: Partial<NonNullable<SurveyGroupRespondentsQuery['surveyGroupRespondents']>['data']>[0];
  isOnboarding?: boolean;
  organizationId: string;
};

export const ParticipantDetail: FC<ParticipantDetailProps> = ({
  surveyGroup,
  respondent,
  respondentGroup,
  isOnboarding,
  organizationId,
}) => {
  const { t } = useTranslation('surveyGroup');
  const [resendingInvitations, setResendingInvitations] = useState<string[]>([]);
  const [deliveredInvitations, setDeliveredInvitations] = useState<string[]>([]);
  const { addToast } = useToast();
  const [getLastMessages] = useLastMessagesForRespondentLazyQuery();

  useEffect(() => {
    const interval = setTimeout(() => {
      if (resendingInvitations.length === 0) return;
      getLastMessages({
        variables: {
          surveyIds: resendingInvitations,
          respondentIds: [respondent.id],
        },
        onCompleted: (data) => {
          if (
            data.lastMessagesForRespondent.filter(
              (message) =>
                message.error ||
                [
                  MessageEventType.Bounced,
                  MessageEventType.Spam,
                  MessageEventType.Unsubscribed,
                  'undelivered',
                ].includes(message.messageStatus!),
            ).length > 2
          ) {
            addToast(t('contactSupport'));
          }
          setResendingInvitations((prev) =>
            prev.filter(
              (x) => !data.lastMessagesForRespondent.some((y) => y.surveyId === x && (y.error || y.messageStatus)),
            ),
          );
          setDeliveredInvitations((prev) =>
            uniq([
              ...prev,
              ...data.lastMessagesForRespondent
                .filter((x) => x.messageStatus?.toUpperCase() === MessageEventType.Delivered)
                .map((x) => x.surveyId),
            ]),
          );
        },
      });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendingInvitations, respondent.id]);

  return (
    <Table<ParticipantDetailData>
      columns={getColumns(t, isOnboarding)}
      dataSources={getDataSource({
        surveys: respondentGroup?.surveys,
        hiringSurveys: respondentGroup?.hiringSurveys,
        steps: surveyGroup.steps,
        metricQuestionsForRespondent: respondentGroup?.metricQuestionsForRespondent || [],
        respondent,
        t,
        deletedRespondents: respondentGroup?.deletedRespondents || [],
        undeliveredInvitations: respondentGroup?.undeliveredInvitations,
        addedOn: respondentGroup?.addedOn,
        surveyGroupId: surveyGroup.id,
        organizationId,
        resendingInvitations,
        deliveredInvitations,
        waitForInvitation: (x: string) => setResendingInvitations((prev) => [...prev, x]),
      })}
      selected={undefined}
      thin
    />
  );
};
