import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { theme } from '../../../theme';

type TextBubbleProps = {
  label: ReactNode;
  backgroundColor?: string;
  color?: string;
  padding?: string;
};

export const TextBubble: FC<TextBubbleProps> = ({ label, backgroundColor, color, padding }) => {
  return (
    <Bubble backgroundColor={backgroundColor} padding={padding}>
      <BubbleBody color={color}>{label}</BubbleBody>
    </Bubble>
  );
};

const Bubble = styled.span<{ backgroundColor?: string; padding?: string }>`
  padding: ${({ padding }) => (padding ? padding : `${theme.spacing.c} ${theme.spacing.e}`)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : theme.colors.backgroundBasic.active};
  border-radius: 19px;
`;

const BubbleBody = styled.span<{ color?: string }>`
  line-height: ${theme.typography.body.small.regular.lineHeight};
  font-weight: ${theme.typography.body.small.regular.fontWeight};
  font-size: ${theme.typography.body.small.regular.fontSize};
  font-family: 'Roboto', sans-serif;
  color: ${({ color }) => color || `${theme.colors.text.secondary}`};
  vertical-align: middle;
`;
