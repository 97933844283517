import { ApolloError } from '@apollo/client';
import { useState } from 'react';
import { BodyMedium, CardBody } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { useCreateSalesOrganizationMutation } from '../../generated/hooks';
import { SalesForm } from './components';
import { SalesValues } from './types';

export const SalesScreen = () => {
  const [createSalesOrganization] = useCreateSalesOrganizationMutation();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const { t } = useTranslation('salesForm');

  const handleSubmit = async (values: SalesValues) => {
    try {
      const result = await createSalesOrganization({
        variables: {
          input: values,
        },
      });

      if (result?.data?.createSalesOrganization.id) {
        setModalOpen(false);
        setIsCreated(true);
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        error.graphQLErrors.forEach((error) => {
          if (error.name === 'EmailAlreadyExists') {
            setModalOpen(true);
          }
        });
      }
    }
  };

  if (isCreated) {
    return (
      <Card>
        <CardBody>
          <BodyMedium>{t('created')}</BodyMedium>
        </CardBody>
      </Card>
    );
  }
  return <SalesForm handleSubmit={handleSubmit} isModalOpen={isModalOpen} setModalOpen={setModalOpen} />;
};
