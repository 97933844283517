import { theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { FormGroupLabel } from '../../Common';

export const SurveysTableHeader = styled.th`
  padding: 0 ${theme.spacing.d} 0 ${theme.spacing.e};
  margin-top: 0.25rem;
  color: ${theme.colors.text.secondary};
  font-size: 80%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: ${theme.spacing.c};
`;

export const SurveysTableHeaderWithRadio = styled(SurveysTableHeader)`
  padding-left: 42px;
`;

export const StyledFormGroupLabel = styled(FormGroupLabel)`
  margin-bottom: ${theme.spacing.e} !important;
`;

export const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
`;

export interface ISurveysTableColumnProps {
  textAlign?: string;
  disabled?: boolean;
}

export const SurveysTableColumn = styled.td`
  padding: 0 ${theme.spacing.d};
  text-align: ${(props: ISurveysTableColumnProps) => props.textAlign || 'left'};
  vertical-align: baseline;
  white-space: nowrap;
  &,
  & label {
    color: ${(props: ISurveysTableColumnProps) =>
      props.disabled ? `${theme.colors.text.disabled} !important` : theme.colors.text.primary};
  }
`;
