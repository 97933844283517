import styled from '@emotion/styled';
import { createPortal } from 'react-dom';
import { ArnoldToast, IToastProps } from './Toast';
import { BeautifulToast, BeautifulToastConfig } from './BeautifulToast';

export type Props = {
  toasts: IToastProps[] | undefined;
  beautifulToast: BeautifulToastConfig | undefined;
};

const ToastContainer = (props: Props) => {
  return createPortal(
    <>
      <Wrapper>{props.toasts && props.toasts.map((item, key) => <ArnoldToast key={key} {...item} />)}</Wrapper>
      {props.beautifulToast && <BeautifulToast {...props.beautifulToast} />}
    </>,
    document.body,
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
`;

export default ToastContainer;
