import { TableColumn } from '@arnold/common';
import styled from '@emotion/styled';
import { TFunction } from 'react-i18next';
import {
  NotstartedSurveyReason,
  OrganizationQuery,
  ProcessStatus,
  SurveyGroupQuery,
  SurveyStatus,
  UserQuery,
} from '../../../generated/hooks';
import { SurveyInfo } from '../../SurveysOverview/RowParts';
import { ProcessSurveyParticipantsData } from './types';

const NotStartedStatus = styled.div`
  display: flex;
  gap: 8px;
`;

export const getColumns = (t: TFunction, isOnboarding?: boolean): Array<TableColumn<ProcessSurveyParticipantsData>> => [
  {
    key: 'surveyParticipant',
    title: t('surveyParticipant'),
    dataIndex: 'surveyParticipant',
  },
  {
    key: 'startDate',
    title: t('startDate'),
    dataIndex: 'startDate',
  },
  {
    key: 'team',
    title: t('team'),
    dataIndex: 'team',
  },
  {
    key: 'status',
    title: isOnboarding ? t('status') : t('surveyGroupStatus'),
    dataIndex: 'status',
  },
  {
    key: 'actions',
    title: undefined,
    dataIndex: 'actions',
  },
];

export const RESPONDENTS_ON_PAGE = 15;

export const getParticipantStatus = (
  surveys: NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['surveys'],
  inLicenceInterval: boolean,
  organization: UserQuery['user']['organization'] | OrganizationQuery['organization'],
  status: ProcessStatus,
  t: TFunction,
  notstartedReason?: NotstartedSurveyReason[],
) => {
  if (surveys.every((survey) => survey.status === SurveyStatus.Prepared) && surveys.length > 0) {
    return t('scheduled');
  }

  if (surveys.every((survey) => [SurveyStatus.Completed, SurveyStatus.Archived].includes(survey.status))) {
    return t('completed');
  }

  if (surveys.every((survey) => survey.status === SurveyStatus.Notstarted)) {
    return (
      <NotStartedStatus>
        {t('notstarted')}
        <SurveyInfo
          isSequential={true}
          inLicenceInterval={inLicenceInterval}
          organization={organization}
          status={status}
          notstartedReason={notstartedReason}
        />
      </NotStartedStatus>
    );
  }

  if (
    surveys.find(
      (survey) =>
        survey.status === SurveyStatus.Notstarted &&
        survey.notstartedReason !== NotstartedSurveyReason.NoRespondentsInSg,
    )
  ) {
    return (
      <NotStartedStatus>
        {t('partiallyNotstarted')}
        <SurveyInfo
          isSequential={true}
          inLicenceInterval={inLicenceInterval}
          organization={organization}
          status={status}
          notstartedReason={notstartedReason}
        />
      </NotStartedStatus>
    );
  }

  return t('ongoing');
};
