import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { Table } from '../Table/Table';
import { TopicGroupQuery } from '../../generated/hooks';
import { getColumns } from './utils';
import { StepsEditData } from './types';
import { getDataSource } from './getDataSource';

type StepsEditTableProps = {
  steps: TopicGroupQuery['topicGroup']['steps'];
  processTopicGroupId: string;
  hideActions?: boolean;
};
export const StepsEditTable: FC<StepsEditTableProps> = ({ steps, processTopicGroupId, hideActions }) => {
  const [t] = useTranslation('onboardingSteps');

  return (
    <Card>
      <Table<StepsEditData>
        columns={getColumns(t, hideActions)}
        dataSources={getDataSource({
          steps,
          processTopicGroupId,
          t,
          hideActions,
        })}
        selected={undefined}
      />
    </Card>
  );
};
