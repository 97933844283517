import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageLayout } from '../Common/PageLayout';
import { OrganizationQuery, TopicGroupTypeCode } from '../../generated/hooks';
import { NoExitsInfo, NoOnboardingsInfo } from '../NoSGInfo';

interface Props {
  organization?: OrganizationQuery['organization'];
  typeCode?: TopicGroupTypeCode;
}

export const EmptySurveyGroupScreen: FC<Props> = ({ organization, typeCode }) => {
  const { t } = useTranslation('AuthorizedPage');
  const edition = organization?.productVersion?.product;
  if (typeCode === TopicGroupTypeCode.Onboarding) {
    return (
      <PageLayout title={t('onboarding')} dataIcom="heading-onboarding">
        <NoOnboardingsInfo edition={edition} />
      </PageLayout>
    );
  }

  if (typeCode === TopicGroupTypeCode.Exit) {
    return (
      <PageLayout title={t('exit')} dataIcom="heading-exit">
        <NoExitsInfo edition={edition} />
      </PageLayout>
    );
  }

  return null;
};
