import { PlainButton } from '@arnold/common';
import { ReactComponent as CloseIcon } from '@arnold/common/lib/assets/icons/Close.svg';
import React, { useState } from 'react';
import { FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import FormInput, { SemanticCheckType } from '../../screens/Topic/FormComponents';
import { Col, FormGroupLabel } from '../Common';

type NewRespondentsBoxProps = {
  participants?: string[];
  labelForMultipleSurveys?: boolean;
  setParticipants: (newParticipants: string[]) => void;
};

const NewRespondentsBox = ({ participants, labelForMultipleSurveys, setParticipants }: NewRespondentsBoxProps) => {
  const { t } = useTranslation(['surveyForm']);
  const [participantFocused, setParticipantFocused] = useState<number | null>(null);
  const handleParticipantInputFocus = (index: number, addInput = false) => {
    setParticipantFocused(index);

    if (addInput) {
      const newParticipants = participants?.slice() || [];
      newParticipants.push('');
      setParticipants(newParticipants);
    }
  };

  const handleRemoveEmail = (e: any, index: number) => {
    e.preventDefault();

    if (participants && index < participants.length) {
      const newParticipants = participants?.slice();
      newParticipants.splice(index, 1);
      setParticipants(newParticipants);
    }
  };

  const handleParticipantEmailChange = (participantEmail: string, index: number) => {
    const newParticipants = participants?.slice() || [];
    if (newParticipants.length > index) {
      newParticipants[index] = participantEmail;
    } else {
      newParticipants.push(participantEmail);
    }

    setParticipants(newParticipants);
  };
  return (
    <FormGroup>
      <FormGroupLabel>{t(labelForMultipleSurveys ? 'orgAdminEmailMultipleSurveys' : 'orgAdminEmail')}</FormGroupLabel>
      <Row>
        <Col sm={8}>
          <FormInput
            value={participants && participants.length ? participants[0] : ''}
            semanticCheck={{
              [SemanticCheckType.EMAIL]: {
                message: t('updateRespondent:enterValidEmail'),
              },
              [SemanticCheckType.UNIQUE]: {
                message: t('duplicateEmail'),
                duplicities: participants?.slice(1),
              },
            }}
            required
            requiredErrorMessage={t('emptyEmail')}
            onChange={(value) => handleParticipantEmailChange(value, 0)}
            onFocus={() => handleParticipantInputFocus(0)}
            onBlur={() => null}
            focused={participantFocused === 0}
          />
        </Col>
      </Row>
      <FormGroupLabel>{t('participantEmails')}</FormGroupLabel>
      {participants?.slice(1).map((participant, index) => (
        <Row>
          <Col sm={8}>
            <FormInput
              value={participant}
              semanticCheck={{
                [SemanticCheckType.EMAIL]: {
                  message: t('updateRespondent:enterValidEmail'),
                },
                [SemanticCheckType.UNIQUE]: {
                  message: t('duplicateEmail'),
                  duplicities: participants?.filter((_, i) => i !== index + 1),
                },
              }}
              required
              requiredErrorMessage={t('emptyEmail')}
              onChange={(value) => handleParticipantEmailChange(value, index + 1)}
              onFocus={() => handleParticipantInputFocus(index + 1)}
              onBlur={() => null}
              focused={participantFocused === index + 1}
            />
          </Col>
          <PlainButton onClick={(e) => handleRemoveEmail(e, index + 1)} disabled={false}>
            <CloseIcon />
          </PlainButton>
        </Row>
      ))}
      <Row>
        <Col sm={8}>
          <FormInput
            value={''}
            placeholder={t('addParticipantEmail')}
            dashed={true}
            onFocus={() => handleParticipantInputFocus(participants?.length || 0, true)}
            onBlur={() => null}
            onChange={(value) => handleParticipantEmailChange(value, participants?.length || 0)}
          />
        </Col>
      </Row>
    </FormGroup>
  );
};

export default NewRespondentsBox;
