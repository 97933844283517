import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { NewProcessSurvey } from '../../components/NewProcessSurvey';

type ParamsProps = {
  subjectId: string;
};

export const CreateProcessSurveyScreen: FC<RouteComponentProps<ParamsProps>> = ({ match }) => {
  const { params } = match;
  const { t } = useTranslation('surveyGroup');
  return <NewProcessSurvey title={t('newSurveyGroup')} topicGroupId={params.subjectId} />;
};
