import { ReactNode, useEffect } from 'react';
import { BodyMedium, BoxWithImage, HeadingMedium, theme, createEventData, pushToDataLayer } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoRespondentsImage } from '../../assets/images/NoRespondents.svg';
import { OrganizationQuery, UserQuery } from '../../generated/hooks';

const BoxButtonsContainer = styled.div`
  margin-top: 28px;
`;
const StyledBoxWithImage = styled(BoxWithImage)`
  margin-bottom: ${theme.spacing.j};
`;

interface IProps {
  children: ReactNode;
  user: UserQuery['user'];
  organization: OrganizationQuery['organization'];
}

export const NoRespondents = ({ children, user, organization }: IProps) => {
  const [t] = useTranslation(['teamsScreen']);

  useEffect(() => {
    pushToDataLayer({
      userId: user.id,
      orgId: organization?.id,
      event: 'ux.organization-no-respondents',
      ...createEventData('organization', 'noRespondents', 'organization no respondents'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledBoxWithImage image={<NoRespondentsImage />}>
      <HeadingMedium margin="12.5px 0 8px">{t('noTeamsTitle')}</HeadingMedium>
      <BodyMedium>{t('noTeamsDescription')}</BodyMedium>
      <BoxButtonsContainer>{children}</BoxButtonsContainer>
    </StyledBoxWithImage>
  );
};

export default NoRespondents;
