import { theme, CleanButton } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { ReactComponent as VisibilityOnIcon } from '@arnold/common/lib/assets/icons/VisibilityOn.svg';
import { ReactComponent as VisibilityOffIcon } from '@arnold/common/lib/assets/icons/VisibilityOff.svg';
import { isEmpty } from 'ramda';
import React, { useState } from 'react';
import { FormGroup, Button, FormControl } from 'react-bootstrap';
import { useTranslation, Trans } from 'react-i18next';
import { Field, FormikErrors, FormikTouched, FormikValues } from 'formik';

import { FormErrorFeedback, FormGroupLabel, FormInputInfo } from '../../components/Common';
import { Required } from '../../components/Common/Required';
import { CheckboxGroup } from '../../components/StyledComponents';

export const CreateButton = styled(Button)`
  width: 100%;
  margin: 0;
  @media (min-width: ${theme.breakpoint.small}) {
    width: auto;
  }
`;

export const FormControlPassword = styled(FormControl)`
  &.is-invalid {
    background-image: none;
  }
`;

const ShowPasswordButton = styled(CleanButton)`
  position: absolute;
  top: 38px;
  right: ${theme.spacing.f};
  path {
    fill: ${theme.colors.actionPrimary.default};
  }
`;

type IProps = {
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: FormikValues;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  isSubmitting: boolean;
  unverifiedPhoneNumber?: boolean;
};

const RegistrationFormSetPassword = ({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  isSubmitting,
  unverifiedPhoneNumber,
}: IProps) => {
  const { t } = useTranslation('registration');
  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <FormGroup>
        <FormGroupLabel>
          {t('registration:password')}
          <Required />
        </FormGroupLabel>
        <FormControlPassword
          style={{
            paddingRight: 40,
          }}
          type={showPassword ? 'text' : 'password'}
          name="password"
          value={values.password || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          isInvalid={touched.password && !!errors.password}
        />
        <ShowPasswordButton
          onClick={(e) => {
            if (e.screenX || e.screenY) {
              e.preventDefault();
              setShowPassword(!showPassword);
            }
          }}
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
        </ShowPasswordButton>
        {!errors.password && <FormInputInfo>{t('registration:enterValidPassword')}</FormInputInfo>}
        <FormErrorFeedback error={errors.password} />
      </FormGroup>
      <FormGroup>
        <CheckboxGroup>
          <Field type="checkbox" name="consent" />
          <span style={{ marginLeft: '18px' }}>
            <Trans
              i18nKey={'registration:agreeWithT&C'}
              components={[<a href={t('registration:T&CLink')} target="_blank" rel="noreferrer" />]}
            >
              I agree with the LMC s.r.o. Terms and Conditions.
            </Trans>
          </span>
        </CheckboxGroup>
        <CheckboxGroup>
          <Field type="checkbox" name="marketingConsent" />
          <span style={{ marginLeft: '18px' }}>{t('registration:agreeWithMarketingConsent')}</span>
        </CheckboxGroup>
      </FormGroup>
      <div className="d-flex flex-wrap align-items-start mt-7">
        <CreateButton
          variant="primary"
          disabled={!isEmpty(errors) || isSubmitting || !values.consent || unverifiedPhoneNumber}
          type="submit"
        >
          {t('registration:createButton')}
        </CreateButton>
      </div>
    </>
  );
};

export default RegistrationFormSetPassword;
