import { ActionMenu, BubbleView, theme } from '@arnold/common';
import { ReactComponent as ActionMenuIcon } from '@arnold/common/lib/assets/icons/ActionMenuIcon.svg';
import { ReactComponent as CheckBox } from '@arnold/common/lib/assets/icons/Check-box.svg';
import { ReactComponent as RadioButton } from '@arnold/common/lib/assets/icons/Radio-button.svg';
import { default as Tell } from '@arnold/common/lib/assets/icons/Tell.svg';
import styled from '@emotion/styled/macro';
import { motion } from 'framer-motion';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChatPreviewArrow } from '../../assets/images';
import ee from '../../lib/eventEmitter';
import { uniqueId } from '../../lib/helpers';
import { RuleOperator, TopicDetailQuery } from '../../generated/hooks';
import { MINIMUM_NUMBER_OF_CHOICES } from './constants';
import FormInput, { FormIcon } from './FormComponents';
import {
  concatRange,
  filterShownSectionLink,
  getTranslation,
  handleQuestionTextChange,
  isMultiselect,
  parseRange,
} from './helpers';
import {
  EditRow,
  SettingsButton,
  SectionLinkView,
  SubsectionArrow,
  SubsectionIcon,
  SubsectionWrap,
  SectionWrap,
  RoutingSectionLinkView,
  RoutingView,
  View,
} from './LayoutComponents';
import SectionLink from './SectionLink';
import SelectRouting from './SelectRouting';
import {
  Choice,
  QuestionDetail,
  QuestionMappingByOrder,
  QuestionSubsections,
  trimRuleOrigValue,
} from './topicStructure';
import { QUESTION_DEFINITION_TYPE, QUESTION_RULE_TYPE } from './types';

const TopicLayoutInput = styled.input`
  height: 16px;
  width: 16px;
  vertical-align: text-top;
  margin-right: 16px;
`;

type Rules = TopicDetailQuery['topicDetail']['allQuestions'][0]['rules'];

export const getIconColor = (choiceSeverity: number, isNew?: boolean) => {
  if (isNew) {
    return theme.colors.iconText.disabled;
  }
  if (choiceSeverity > 0) {
    return choiceSeverity === 1 ? theme.colors.chart.warning[1] : theme.colors.chart.alert[1];
  }
};

type QuestionChoiceProps = {
  question: QuestionDetail;
  subsections?: QuestionSubsections;
  mapping: QuestionMappingByOrder;
  sectionOrder: number;
  isInNewSection: boolean;
  allowStructureChanges?: boolean;
  updateQuestion: (question: QuestionDetail, parentQuestionId?: string) => void;
  deleteQuestionsForChoice: (question: QuestionDetail, choiceIndex: number, textBubbleIndex?: number) => void;
  displayAddTellToSubsectionForm: (
    question: QuestionDetail,
    parentQuestionId: string,
    choiceIndex: number,
    subsectionIndex: number,
  ) => void;
  editMode: boolean;
  nextSectionStartingQuestion?: QuestionDetail;
  isNew?: boolean;
  languageId: string;
  choice: Choice;
  choiceIndex: number;
  sectionIndexMapping: QuestionMappingByOrder;
  focusedQuestionId?: string | null;
};

function copyAndDeleteItem<Type>(arg: Type[], index: number): Type[] {
  const copy = [...arg];
  copy.splice(index, 1);
  return copy;
}

const QuestionChoice = ({
  question,
  isInNewSection,
  sectionOrder,
  subsections,
  mapping,
  editMode,
  updateQuestion,
  allowStructureChanges,
  deleteQuestionsForChoice,
  displayAddTellToSubsectionForm,
  nextSectionStartingQuestion,
  languageId,
  isNew,
  choice,
  choiceIndex,
  sectionIndexMapping,
  focusedQuestionId,
}: QuestionChoiceProps) => {
  const [t] = useTranslation('topicOverview');
  const translation = getTranslation(question, languageId);
  if (!translation) {
    return <h5>{t('missingTranslation')}</h5>;
  }
  const numberOfChoices = translation.choices ? translation.choices.length : 0;
  const isQuestionMultiselect = isMultiselect(question);

  const handleQuestionChoiceChange = (text: string) => {
    const translations = question.translations.map((t) =>
      t.language.id === languageId
        ? {
            ...t,
            choices: t.choices && t.choices.map((val, index) => (index === choiceIndex ? { ...val, text } : val)),
          }
        : t,
    );
    updateQuestion({
      ...question,
      translations,
    });
  };

  const handleNewQuestionChoice = () => {
    const rules = [...question.rules];
    if (!question.rules.some((rule) => rule.origValue === '') && nextSectionStartingQuestion) {
      rules.push({
        __typename: isQuestionMultiselect ? 'ArrayRule' : 'RangeRule',
        origValue: isQuestionMultiselect ? `[${choiceIndex}]` : `${choiceIndex}`,
        order: question.rules.length,
        operator: isQuestionMultiselect ? RuleOperator.Inany : null,
        id: uniqueId(),
        nextQuestion: nextSectionStartingQuestion,
        isFromDefault: null,
      });
    }
    const translations = question.translations.map((t) => ({
      ...t,
      choices: t.choices ? [...t.choices, choice] : [choice],
    }));
    const range = parseRange(question.selectedRange);
    const selectedRange =
      question.selectedRange && translation.choices && range.max === numberOfChoices && range.max !== 1
        ? concatRange({ ...range, max: numberOfChoices + 1 })
        : question.selectedRange;
    updateQuestion({
      ...question,
      selectedRange,
      rules,
      translations,
    });
  };

  const deleteChoice = () => {
    const answersToHighlight =
      question.answersToHighlight &&
      question.answersToHighlight
        .filter((answerIndex) => answerIndex !== choiceIndex.toString())
        .map((answerIndex) => {
          const parsedAnswerIndex = parseInt(answerIndex, 10);
          if (parsedAnswerIndex > choiceIndex) {
            return (parsedAnswerIndex - 1).toString();
          }
          return answerIndex;
        });
    const rules = (question.rules as any).reduce((acc: any[], rule: any) => {
      const cleanedValue = rule.origValue.replace(/^\[+|\]+$/g, '');
      if (cleanedValue) {
        const parsedValue = parseInt(cleanedValue, 10);
        if (parsedValue === choiceIndex) {
          return acc;
        } else if (parsedValue > choiceIndex) {
          return [
            ...acc,
            {
              ...rule,
              origValue: rule.origValue.replace(cleanedValue, (parsedValue - 1).toString()),
            },
          ];
        }
      }
      return [...acc, rule];
    }, [] as Rules);
    const translations = question.translations.map((t) => ({
      ...t,
      choices: t.choices && copyAndDeleteItem(t.choices, choiceIndex),
    }));
    const range = parseRange(question.selectedRange);
    let selectedRange = question.selectedRange;
    if (question.selectedRange && translation.choices) {
      const max = range.max >= numberOfChoices ? numberOfChoices - 1 : range.max;
      const min = range.min >= numberOfChoices - 1 ? (numberOfChoices - 2 >= 0 ? numberOfChoices - 2 : 0) : range.min;
      selectedRange = concatRange({ min, max });
    }
    updateQuestion({
      ...question,
      selectedRange,
      rules,
      answersToHighlight,
      translations,
    });
    deleteQuestionsForChoice(question, choiceIndex);
  };

  const editChoice = () => {
    ee.emitModalChoiceSettings(choiceSeverity, (newChoiceSeverity) => {
      const originalAnswersToHighlight = (question.answersToHighlight || []).filter(
        (answerIndex) => answerIndex !== choiceIndex.toString(),
      );
      const answersToHighlight = originalAnswersToHighlight.concat(
        Array.from(Array(newChoiceSeverity).keys()).map(() => choiceIndex.toString()),
      );
      updateQuestion({
        ...question,
        answersToHighlight,
      });
    });
  };

  const displayAddQuestionForm = (subsectionIndex: number) => {
    displayAddTellToSubsectionForm(question, question.id, choiceIndex, subsectionIndex);
  };

  const handleDeleteRouting = (
    origQuestion: QuestionDetail,
    parentQuestionId?: string,
    defaultIndex?: number | null,
  ) => {
    const origRule = origQuestion.rules[0];

    const newRules = parentQuestionId
      ? defaultIndex
        ? [
            {
              ...origRule,
              isFromDefault: true,
              nextQuestion: {
                index: defaultIndex,
                __typename: QUESTION_DEFINITION_TYPE.tell,
                id: uniqueId(),
              },
            },
          ]
        : []
      : origQuestion.rules.filter((r) => trimRuleOrigValue(r.origValue) !== choiceIndex.toString());

    updateQuestion(
      {
        ...origQuestion,
        rules: newRules,
      },
      parentQuestionId,
    );
  };

  const handleAddRouting = (origQuestion: QuestionDetail, parentQuestionId?: string) => {
    const newRules = parentQuestionId
      ? origQuestion.rules.find((rule) => rule.isFromDefault)
        ? origQuestion.rules.map((rule) => {
            if (rule.isFromDefault) {
              return {
                ...rule,
                isFromDefault: false,
              };
            }

            return rule;
          })
        : [
            ...origQuestion.rules,
            {
              id: uniqueId(),

              operator: null,
              order: 0,
              origValue: '',
              __typename: QUESTION_RULE_TYPE.rangeRule,
              nextQuestion: {
                index: nextSectionStartingQuestion?.index || null,
                id: nextSectionStartingQuestion?.id || '',
                __typename: QUESTION_DEFINITION_TYPE.tell,
              },
              isFromDefault: false,
            },
          ]
      : [
          ...origQuestion.rules.map((rule) => {
            if (rule.origValue === '') {
              return {
                ...rule,
                order: Number.POSITIVE_INFINITY,
              };
            }

            return rule;
          }),
          {
            id: uniqueId(),

            operator: null,
            order: choiceIndex,
            origValue: choiceIndex.toString(),
            __typename: QUESTION_RULE_TYPE.rangeRule,
            nextQuestion: {
              index: nextSectionStartingQuestion?.index || null,
              id: nextSectionStartingQuestion?.id || '',
              __typename: QUESTION_DEFINITION_TYPE.tell,
            },
            isFromDefault: false,
          },
        ];

    const filteredNewRules = newRules.filter((rule, index) => {
      const otherRuleIndex = newRules.findIndex((otherRule, otherIndex) => {
        return otherRule.nextQuestion.index === rule.nextQuestion.index && index !== otherIndex;
      });
      return otherRuleIndex < index;
    });

    updateQuestion(
      {
        ...origQuestion,
        rules: filteredNewRules,
      },
      parentQuestionId,
    );
  };

  const defaultRules = (question.rules || []).filter((rule) => rule.origValue === '');

  const choiceRules = question.rules.filter(
    (rule) => rule.origValue === choiceIndex.toString() || rule.origValue === `[${choiceIndex}]`,
  );

  const choiceSeverity = (question.answersToHighlight || []).filter((index) => index === choiceIndex.toString()).length;

  const choiceSubsections =
    subsections &&
    (subsections[choiceIndex] ||
      (!choiceRules.filter((chr) => trimRuleOrigValue(chr.origValue)).length && subsections.default));

  const shownSectionLinks = filterShownSectionLink(choiceRules, false, subsections).filter(
    (rule) => rule.origValue !== '',
  );

  const actionItems = [
    {
      label: t('actionMenu:addChatBubble'),
      action: () => displayAddQuestionForm(0),
    },
    {
      label: t('delete'),
      action: deleteChoice,
    },
  ];

  if (!choiceSubsections && !shownSectionLinks.length) {
    actionItems.unshift({
      label: t('actionMenu:addRouting'),
      action: () => handleAddRouting(question),
    });
  }

  return (
    <motion.div
      transition={{ type: 'easeInOut', duration: isNew ? 0 : 0.25 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
    >
      <EditRow>
        <View shouldGrow={true} style={{ overflow: 'hidden' }}>
          {editMode ? (
            <>
              {question.__typename === QUESTION_DEFINITION_TYPE.select && (
                <FormIcon pathColor={getIconColor(choiceSeverity, isNew)}>
                  {isQuestionMultiselect ? <CheckBox /> : <RadioButton />}
                </FormIcon>
              )}
              <FormInput
                value={(choice && choice.text) || ''}
                required
                dashed={isNew}
                warnings={isNew && choiceIndex < MINIMUM_NUMBER_OF_CHOICES ? [t('minimumNumberOfChoices')] : []}
                onFocus={isNew ? handleNewQuestionChoice : undefined}
                placeholder={isNew ? t('newChoice') : undefined}
                requiredErrorMessage={t('emptyInputWarning')}
                onChange={handleQuestionChoiceChange}
              />
            </>
          ) : (
            <BubbleView
              backgroundColor={
                choiceSeverity > 0
                  ? choiceSeverity === 1
                    ? theme.colors.chart.warning[3]
                    : theme.colors.chart.alert[3]
                  : theme.colors.chart.neutral[4]
              }
              color={theme.colors.textInverted.primary}
              left={true}
            >
              {question.__typename === QUESTION_DEFINITION_TYPE.select && (
                <TopicLayoutInput type={isQuestionMultiselect ? 'checkbox' : 'radio'} />
              )}
              {choice.text}
            </BubbleView>
          )}
        </View>
        {editMode && !isNew && allowStructureChanges && (
          <>
            <SettingsButton onClick={editChoice} />
            <ActionMenu toggleIcon={<ActionMenuIcon />} items={actionItems} />
          </>
        )}
      </EditRow>
      {!choiceSubsections && !!shownSectionLinks.length && !editMode && (
        <>
          <SubsectionWrap>
            <SubsectionArrow src={ChatPreviewArrow} />
            <div style={{ width: '100%' }}>
              {shownSectionLinks.map((rule) => (
                <SectionLink rule={rule} mapping={mapping} key={rule.order} questionId={question.id} />
              ))}
            </div>
          </SubsectionWrap>
        </>
      )}
      {(!choiceSubsections || !choiceSubsections.flat().length) &&
        !!shownSectionLinks.length &&
        editMode &&
        allowStructureChanges && (
          <RoutingView>
            <SectionWrap>
              <div style={{ width: '100%', marginRight: '16px' }}>
                {shownSectionLinks.map((rule) => (
                  <SelectRouting
                    isInNewSection={isInNewSection}
                    sectionOrder={sectionOrder}
                    sectionIndexMapping={sectionIndexMapping}
                    rule={rule}
                    question={question}
                    updateQuestion={updateQuestion}
                    parentQuestionId={question.id}
                  />
                ))}
              </div>

              <ActionMenu
                toggleIcon={<ActionMenuIcon />}
                items={[
                  {
                    label: t('delete'),
                    action: () => handleDeleteRouting(question),
                  },
                ]}
              />
            </SectionWrap>
          </RoutingView>
        )}
      {!!choiceSubsections &&
        !isNew &&
        !!choiceSubsections[0] &&
        !!choiceSubsections[0].length &&
        choiceSubsections.map((subWrap) => {
          return (
            <SubsectionWrap key={subWrap[0] && subWrap[0].id}>
              {!editMode ? <SubsectionArrow src={ChatPreviewArrow} /> : null}

              <div
                style={{
                  width: '80%',
                  marginLeft: editMode ? '76px' : undefined,
                }}
              >
                {subWrap.map((sub, index) => {
                  const subTranslation =
                    sub.translations.find((t) => t.language.id === languageId) || sub.translations[0];

                  const shownSubSectionLink = filterShownSectionLink([sub.rules[0]], true, subsections);

                  const subActionItems = [];

                  if (!index) {
                    subActionItems.push({
                      label: t('actionMenu:addChatBubbleAboveChoice'),
                      action: () => displayAddQuestionForm(0),
                    });
                  }

                  subActionItems.push({
                    label: t('actionMenu:addChatBubble'),
                    action: () => displayAddQuestionForm(index + 1),
                  });

                  if (
                    index === subWrap.length - 1 &&
                    ((sub && sub.rules && sub.rules.length === 0) || sub.rules[0].isFromDefault)
                  ) {
                    subActionItems.push({
                      label: t('actionMenu:addRouting'),
                      action: () => handleAddRouting(sub, question.id),
                    });
                  }

                  subActionItems.push({
                    label: t('delete'),
                    action: () => deleteQuestionsForChoice(question, choiceIndex, index),
                  });

                  return (
                    <React.Fragment key={`${choice.uid}-${index}`}>
                      <EditRow>
                        {editMode && <SubsectionIcon src={Tell} />}
                        <SectionLinkView shouldGrow={true}>
                          {editMode ? (
                            <FormInput
                              value={subTranslation ? subTranslation.text : ''}
                              required
                              requiredErrorMessage={t('emptyInputWarning')}
                              onChange={handleQuestionTextChange(sub, updateQuestion, languageId, question.id)}
                              focused={sub.id === focusedQuestionId}
                            />
                          ) : (
                            <BubbleView backgroundColor={theme.colors.backgroundCover.default} left={true}>
                              {subTranslation.text}
                            </BubbleView>
                          )}
                        </SectionLinkView>
                        {editMode && allowStructureChanges && (
                          <ActionMenu toggleIcon={<ActionMenuIcon />} items={subActionItems} />
                        )}
                      </EditRow>

                      {index + 1 === subWrap.length &&
                      !editMode &&
                      !!shownSubSectionLink.length &&
                      !sub.rules[0].isFromDefault ? (
                        <SectionLink
                          rule={sub.rules[0]}
                          mapping={mapping}
                          key={sub.rules[0].id}
                          questionId={question.id}
                        />
                      ) : null}
                      {index + 1 === subWrap.length &&
                      editMode &&
                      !!shownSubSectionLink.length &&
                      !sub.rules[0].isFromDefault &&
                      allowStructureChanges ? (
                        <RoutingSectionLinkView>
                          <SelectRouting
                            isInNewSection={isInNewSection}
                            sectionOrder={sectionOrder}
                            sectionIndexMapping={sectionIndexMapping}
                            rule={sub.rules[0]}
                            question={sub}
                            updateQuestion={updateQuestion}
                            parentQuestionId={question.id}
                          />
                          <ActionMenu
                            toggleIcon={<ActionMenuIcon />}
                            items={[
                              {
                                label: t('delete'),
                                action: () =>
                                  handleDeleteRouting(
                                    sub,
                                    question.id,
                                    (defaultRules &&
                                      defaultRules[0] &&
                                      defaultRules[0].nextQuestion &&
                                      defaultRules[0].nextQuestion.index) ||
                                      nextSectionStartingQuestion?.index,
                                  ),
                              },
                            ]}
                          />
                        </RoutingSectionLinkView>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </div>
            </SubsectionWrap>
          );
        })}
    </motion.div>
  );
};

export default QuestionChoice;
