import { createEventData, pushToDataLayer } from '@arnold/common';
import { MD5 } from 'crypto-js';
import { debounce } from 'lodash';
import { compress, decompress } from 'lz-string';
import { createRoot } from 'react-dom/client';
import { TFunction } from 'react-i18next';
import { ClassicPreset, NodeEditor, Pipe, Root } from 'rete';
import { AreaExtensions, AreaPlugin, BaseArea, Zoom } from 'rete-area-plugin';
import { SelectorEntity } from 'rete-area-plugin/_types/extensions/selectable';
import { OnZoom } from 'rete-area-plugin/_types/zoom';
import { ArrangeAppliers, Presets as ArrangePresets, AutoArrangePlugin } from 'rete-auto-arrange-plugin';
import { ConnectionPlugin, Presets as ConnectionPresets } from 'rete-connection-plugin';
import { ContextMenuExtra } from 'rete-context-menu-plugin';
import { HistoryAction, HistoryPlugin, Presets as HistoryPresets } from 'rete-history-plugin';
import { ReactArea2D, ReactPlugin, Presets as ReactPresets } from 'rete-react-plugin';
import { ReadonlyPlugin } from 'rete-readonly-plugin';
import { getDOMSocketPosition } from 'rete-render-utils';
import { IsAccessTokenValidQuery, UserSysRole } from '../../generated/hooks';
import { COMMENT_TYPE } from './constants';
import { createContextMenuCustomizedComponents, createContextMenuPlugin } from './contextMenu';
import { AddOptionControl, AddOptionControlData } from './controls/AddOptionControl';
import { CheckboxControl, CheckboxControlData } from './controls/CheckboxControl';
import { CommentsControlData } from './controls/CommentsControl';
import { DeleteCommentControl } from './controls/DeleteCommentControl';
import { MinMaxControl, MinMaxControlData } from './controls/MinMaxControl';
import { NumberWithTooltipControl, NumberWithTooltipControlData } from './controls/NumberWithTooltipControl';
import { OptionControl, OptionControlData } from './controls/OptionControl';
import { TextAreaControl, TextAreaControlData } from './controls/TextAreaControl';
import { addKeydownEventListenerForCopyAndPasteAndDelete } from './copyPasteDelete';
import { customSelectableNodes } from './customSelectableNodes';
import { DataNode, DataNodeWithDimensions, NodeData, Schemes } from './dataNode';
import { CustomNode } from './nodes/CustomNode';
import { addAreaSelectionListener } from './selectionSquare';
import { updateNodeDataBasedOnConnections, waitFor } from './utils';
import { validateNodes } from './validation';

export const RETE_ID = 'arnold-robot@0.1.0';
interface EditorProperties {
  preview: boolean;
  isSysAdmin: boolean;
}

class CustomZoom extends Zoom {
  private intercomEventAlreadySent = false;
  private userId;

  constructor(userId?: string) {
    super(0.1);
    this.userId = userId;
  }

  initialize(container: HTMLElement, element: HTMLElement, onzoom: OnZoom) {
    super.initialize(container, element, onzoom);
    this.container.addEventListener('wheel', this.sendWheelEventToIntercom);
  }

  destroy() {
    super.destroy();
    this.container.removeEventListener('wheel', this.sendWheelEventToIntercom);
  }

  protected sendWheelEventToIntercom = (e: MouseEvent) => {
    if (this.intercomEventAlreadySent || !this.userId) {
      return;
    }
    this.intercomEventAlreadySent = true;
    pushToDataLayer({
      userId: this.userId,
      event: 'ux.user-ariel-scroll',
      ...createEventData('ariel', 'scroll', 'user used scroll in Ariel'),
    });
  };

  protected dblclick = (e: MouseEvent) => {
    // Do nothing instead of zooming in
  };
}

export class CustomNodeEditor extends NodeEditor<Schemes> {
  firstLoad = true;
  initialNodesDataHash: string | undefined;
  language: string | undefined;
  applier = new ArrangeAppliers.TransitionApplier<Schemes, never>({
    duration: 1000,
    timingFunction: (t) => t,
    onTick: async () => {
      await AreaExtensions.zoomAt(this.areaPlugin, this.getNodes());
    },
  });
  loaded = false;

  constructor(
    public properties: EditorProperties,
    public areaPlugin: AreaPlugin<Schemes, AreaExtra>,
    public connectionPlugin: ConnectionPlugin<Schemes, AreaExtra>,
    public reactPlugin: ReactPlugin<Schemes, AreaExtra>,
    public arrangePlugin: AutoArrangePlugin<Schemes>,
    public historyPlugin: HistoryPlugin<Schemes, HistoryAction>,
    public nodeSelectorInterface: ReturnType<typeof customSelectableNodes>,
    public customNodeSelector: CustomSelector,
    public readonly: ReadonlyPlugin<Schemes>,
  ) {
    super();
  }

  rearrange() {
    rearrangeNodes(this);
  }

  removeEventListernersFunctions: (() => void)[] = [];
  removeEventListeners() {
    this.removeEventListernersFunctions.forEach((f) => f());
  }

  historyChangeListeners: ((editor: CustomNodeEditor) => void)[] = [];
  addHistoryChangeListener(f: (editor: CustomNodeEditor) => void) {
    this.historyChangeListeners.push(f);
  }
  removeHistoryChangeListener(f: (editor: CustomNodeEditor) => void) {
    this.historyChangeListeners = this.historyChangeListeners.filter((listener) => listener !== f);
  }
  // Debounce is used to prevent multiple historyChangeListeners from being called in a short time
  onHistoryMightHaveChanged = debounce(
    () => {
      this.historyChangeListeners.forEach((f) => f(this));
    },
    200,
    { maxWait: 1000 },
  );

  saved() {
    this.initialNodesDataHash = MD5(JSON.stringify(this.getNodes().map((node) => node.data))).toString();
  }
}

export type AreaExtra = ReactArea2D<Schemes> | ContextMenuExtra;

export const createEditor = async (
  container: HTMLElement,
  preview: boolean,
  showDeleteNodeModal: (onSubmit: () => void) => void,
  t: TFunction,
  topicId: string,
  onCommentDelete: (id: string) => void,
  user?: IsAccessTokenValidQuery['isAccessTokenValid']['user'],
  search?: string,
): Promise<CustomNodeEditor> => {
  const areaPlugin = new AreaPlugin<Schemes, AreaExtra>(container);
  const connectionPlugin = new ConnectionPlugin<Schemes, AreaExtra>();
  const reactPlugin = new ReactPlugin<Schemes, AreaExtra>({ createRoot });
  const readonly = new ReadonlyPlugin<Schemes>();
  const arrangePlugin = new AutoArrangePlugin<Schemes>();
  const historyPlugin = new HistoryPlugin<Schemes, HistoryAction>({ timing: 500 });
  if (!preview) {
    const contextMenu = createContextMenuPlugin(showDeleteNodeModal, t, topicId, onCommentDelete, user, search);
    areaPlugin.use(contextMenu);
    reactPlugin.addPreset(
      ReactPresets.contextMenu.setup({
        customize: createContextMenuCustomizedComponents(),
      }),
    );
  }
  const customNodeSelector = new CustomSelector();
  const nodeSelector = customSelectableNodes(areaPlugin, customNodeSelector, {
    accumulating: accumulateOnKeyPress(),
  });

  areaPlugin.area.setZoomHandler(new CustomZoom(user?.id));

  const editor = new CustomNodeEditor(
    { preview, isSysAdmin: user?.systemRole === UserSysRole.SysAdmin },
    areaPlugin,
    connectionPlugin,
    reactPlugin,
    arrangePlugin,
    historyPlugin,
    nodeSelector,
    customNodeSelector,
    readonly,
  );
  addContextMenuEventListener(editor);
  addAreaSelectionListener(editor);
  addKeydownEventListenerForCopyAndPasteAndDelete(editor, t, onCommentDelete);
  addUndoRedoEventListener(editor);
  reactPlugin.addPreset(
    ReactPresets.classic.setup({
      customize: {
        node() {
          return CustomNode;
        },
        control(context) {
          if (context.payload instanceof TextAreaControlData) {
            return TextAreaControl;
          }
          if (context.payload instanceof CheckboxControlData) {
            return CheckboxControl;
          }
          if (context.payload instanceof AddOptionControlData) {
            return AddOptionControl;
          }
          if (context.payload instanceof OptionControlData) {
            return OptionControl;
          }
          if (context.payload instanceof MinMaxControlData) {
            return MinMaxControl;
          }
          if (context.payload instanceof NumberWithTooltipControlData) {
            return NumberWithTooltipControl;
          }
          if (context.payload instanceof CommentsControlData) {
            return context.payload.component;
          }
          if (context.payload instanceof DeleteCommentControl) {
            return context.payload.component;
          }
          if (context.payload instanceof ClassicPreset.InputControl) {
            return ReactPresets.classic.Control as any; // I tried really hard to make the types match, but I was not successful
          }
        },
      },
      socketPositionWatcher: getDOMSocketPosition({
        offset({ x, y }, nodeId, side, key) {
          // We have much smaller sockets than Rete defaults.
          // We need to offset the DOM socket position, otherwise the connections will start and end next to the socket,
          // instead of inside the socket, which is visual bug.
          return {
            x: x + 7 * (side === 'input' ? -1 : 1),
            y: y + 2,
          };
        },
      }),
    }),
  );
  arrangePlugin.addPreset(ArrangePresets.classic.setup({ spacing: 100 }));
  AreaExtensions.simpleNodesOrder(areaPlugin);

  editor.use(areaPlugin);
  areaPlugin.use(reactPlugin);
  areaPlugin.use(arrangePlugin);
  areaPlugin.use(historyPlugin);
  editor.use(readonly.root);

  if (!preview) {
    connectionPlugin.addPreset(ConnectionPresets.classic.setup());
    areaPlugin.use(connectionPlugin);
    editor.addPipe(async (context) => {
      if (editor.loaded === false) return context;
      if (['connectioncreated', 'connectionremoved', 'nodecreated', 'noderemoved'].includes(context.type)) {
        updateNodeDataBasedOnConnections(editor.getNodes(), editor.getConnections());
        validateNodes(editor.getNodes(), editor.getNodes());
        for (const node of editor.getNodes()) {
          await editor.areaPlugin.update('node', node.id);
        }
      }
      return context;
    });
    // History plugin needs to be added as the last one and also the disableCommentUndoRedo pipe needs to be added before the history preset
    historyPlugin.addPipe(disableCommentUndoRedo(editor));
    historyPlugin.addPipe(historyChangesChecker(editor));
    historyPlugin.addPreset(HistoryPresets.classic.setup());
    setupAutosave(editor, topicId, t);
  }
  return editor;
};

export const processData = async (
  nodesData: NodeData[],
  editor: CustomNodeEditor,
  t: TFunction,
  language?: string,
  pasteNewNodes = false,
  autosaveInitialHash?: string | false,
) => {
  editor.firstLoad = false;
  editor.loaded = false;
  editor.language = language ?? editor.language;
  if (autosaveInitialHash) {
    // we need to keep the initial hash from autosave
    editor.initialNodesDataHash = autosaveInitialHash;
  } else if (autosaveInitialHash === false) {
    // false explicitly means that we want to compute the hash from the data
    editor.initialNodesDataHash = MD5(JSON.stringify(nodesData)).toString();
  }
  if (!pasteNewNodes) {
    editor.readonly.disable();
    await editor.clear();
  }

  const nodes = await Promise.all(
    nodesData.map(async (nodeData) => {
      const node: DataNode = new DataNode(
        {
          ...nodeData,
          onChange: () => nodeOnChange(editor),
          onDataChange: (data) => {
            node.data = data;
            nodeOnChange(editor);
          },
          removeConnectionFromOutput: (node, outputName) => nodeRemoveConnectionFromOutput(node, outputName, editor),
          onDelete: () => {
            const removedNode = editor.getNode(node.id);
            editor.removeNode(node.id);
            return removedNode;
          },
          addHistoryAction: (action) => {
            editor.historyPlugin.add(action);
          },
        },
        t,
        editor.properties.preview,
        editor.properties.isSysAdmin,
      );
      await editor.addNode(node as DataNodeWithDimensions);
      return node;
    }),
  );

  await Promise.all(
    nodes.map((node) =>
      Promise.all(
        Object.entries(node.data.outputs || []).map(async (entry) => {
          const [outputName, outputConnectedNodeQuestionIndex] = entry;
          if (outputConnectedNodeQuestionIndex == null) return;
          const nodeToConnectTo = nodes.find((n) => n.data.questionIndex === outputConnectedNodeQuestionIndex);
          if (nodeToConnectTo) {
            await editor.addConnection(new ClassicPreset.Connection(node, outputName, nodeToConnectTo, 'in'));
          } else if (!pasteNewNodes) {
            // if we are not pasting new nodes, we need to throw an error, because the output node was not found
            throw new Error(
              'Node was not find when trying to create connection. Node with questionId=' +
                outputConnectedNodeQuestionIndex,
            );
          }
        }),
      ),
    ),
  );

  // auto rearrange or use saved positions from DB if available
  if (nodes.some((node) => node.data.position[0] == null || node.data.position[1] == null) && !pasteNewNodes) {
    await rearrangeNodes(editor);
  } else {
    await Promise.all(
      nodes.map(async (node) =>
        editor.areaPlugin.translate(node.id, { x: node.data.position[0]!, y: node.data.position[1]! }),
      ),
    );
    if (!pasteNewNodes) {
      await AreaExtensions.zoomAt(editor.areaPlugin, editor.getNodes());
    }
  }
  if (nodes.every((node) => node.data.reportOrder == null)) {
    // If nodes do not have reportOrder they were not probably loaded into Ariel ever before.
    // We will generate reportOrder based on the position.
    await regenerateReportOrder(editor);
  }

  validateNodes(nodes, nodes);
  setTimeout(async () => {
    // We will wait for height to calculate properly and then call update on all nodes.
    // That repositions their connections to adjust for socket position change.
    for (const node of nodes) {
      await editor.areaPlugin.update('node', node.id);
    }
    if (editor.properties.preview) {
      editor.readonly.enable();
    }
    editor.loaded = true;
    if (!pasteNewNodes) {
      editor.historyPlugin.clear();
    }
  }, 200); // 200ms delay works better than requestIdleCallback and requestAnimationFrame
};

const rearrangeNodes = async (editor: CustomNodeEditor) => {
  const nodes = editor.getNodes() as DataNode[];
  await Promise.all(
    nodes.map(async (node) => {
      if (editor.areaPlugin.nodeViews.get(node.id)!.element.clientHeight === 0) {
        // autoarrange plugin needs to have the height and width of nodes, but we do not have height before the nodes are rendered
        // we wait for them to render or throw an error after 10s
        await waitFor(
          () => editor.areaPlugin.nodeViews.get(node.id)!.element.clientHeight > 0,
          undefined,
          undefined,
          // 'rearrange-' + node.data.questionIndex is important only for development - when saving tsx file, HMR is screwing up state with editor and rearrange is called many times
          // editor is initialized twice and waitFor is called with method that depends on editor loading, but only the last editor will fully load
          'rearrange-' + node.data.questionIndex,
        );
      }
      const height = editor.areaPlugin.nodeViews.get(node.id)!.element.clientHeight;
      node.height = height;
    }),
  );

  await Promise.all(
    editor
      .getNodes()
      .filter((node) => node.data.type === COMMENT_TYPE)
      .map(async (node) =>
        editor.areaPlugin.translate(node.id, { x: node.data.position[0]!, y: node.data.position[1]! }),
      ),
  );

  if (editor.getNodes().every((node) => !!node.height)) {
    await editor.arrangePlugin.layout({
      applier: editor.applier,
      options: {},
      nodes: editor.getNodes().filter((node) => node.data.type !== COMMENT_TYPE),
    });
  }
  nodes.forEach((node) => {
    // we need to put the height back to undefined otherwise it would be set in CSS styles - we want it to be dynamic
    node.height = undefined;
  });
};

export const regenerateReportOrder = async (editor: CustomNodeEditor) => {
  const sortedNodes = editor.getNodes().sort((a, b) => {
    const aPosition = editor.areaPlugin.nodeViews.get(a.id)!.position;
    const bPosition = editor.areaPlugin.nodeViews.get(b.id)!.position;
    return aPosition.x - bPosition.x || aPosition.y - bPosition.y;
  });
  for (let i = 0; i < sortedNodes.length; i++) {
    const node = sortedNodes[i];
    node.data.reportOrder = i + 1;
    node.controls.reportOrder.initialValue = i + 1;
    await editor.areaPlugin.update('control', node.controls.reportOrder!.id);
  }
};

export const nodeRemoveConnectionFromOutput = async (node: DataNode, outputName: string, editor: CustomNodeEditor) => {
  const connections = editor.getConnections().filter((c) => {
    return c.source === node.id && c.sourceOutput === outputName;
  });
  for (const connection of connections) {
    await editor.removeConnection(connection.id);
  }
};

export const nodeOnChange = async (editor: CustomNodeEditor) => {
  validateNodes(editor.getNodes(), editor.getNodes());

  for (const node of editor.getNodes()) {
    await editor.areaPlugin.update('node', node.id);
  }
};

export const updatePositionInNodeData = async (editor: CustomNodeEditor, nodes: DataNode[]) => {
  nodes.forEach((node) => {
    const nodePosition = editor.areaPlugin.nodeViews.get(node.id)?.position;
    const posX = nodePosition?.x ? Math.floor(nodePosition.x) : null;
    const posY = nodePosition?.y ? Math.floor(nodePosition.y) : null;
    node.data.position = [posX, posY];
  });
};

const accumulateOnKeyPress = () => {
  let pressed = false;

  function keydown(e: KeyboardEvent) {
    if (e.key === 'Control' || e.key === 'Meta') pressed = true;
  }
  function keyup(e: KeyboardEvent) {
    if (e.key === 'Control' || e.key === 'Meta') pressed = false;
  }

  document.addEventListener('keydown', keydown);
  document.addEventListener('keyup', keyup);

  return {
    active() {
      return pressed;
    },
    destroy() {
      document.removeEventListener('keydown', keydown);
      document.removeEventListener('keyup', keyup);
    },
  };
};

/**
 * Custom selector class that extends the default selector class.
 * Used to override the default behavior which prevents dragging selected nodes without holding ctrl.
 */
class CustomSelector extends AreaExtensions.Selector<SelectorEntity> {
  add(entity: SelectorEntity, accumulate: boolean): void {
    const item = this.entities.get(`${entity.label}_${entity.id}`);
    if (item == null && !accumulate) this.unselectAll();
    this.entities.set(`${entity.label}_${entity.id}`, entity);
  }
}

/**
 * Custom context menu listener that disables the default one
 * Needed for our custom actions on right click and 3 dots
 * Also has tweaks for smoother work with the selection rectangle and context menu
 */
const addContextMenuEventListener = (editor: CustomNodeEditor) => {
  const areaPlugin = editor.areaPlugin;
  const container = areaPlugin.container;
  const selectionRectangle = container.parentElement!.querySelector('#selection-rectangle') as HTMLElement;
  const areaOnContextMenu = (areaPlugin as any).onContextMenu as (e: Event) => void;

  // We need to remove the rete default context menu listener, because we want to handle it differently
  container.removeEventListener('contextmenu', areaOnContextMenu);
  // Prevent browser context menu
  container.addEventListener('contextmenu', (e) => {
    e.preventDefault();
    e.stopPropagation();
  });

  let startX = 0;
  let startY = 0;
  const downListener = (e: MouseEvent) => {
    if (e.button === 2) {
      startX = e.clientX;
      startY = e.clientY;
      container.style.cursor = 'default';
    }
  };
  const upListener = (e: MouseEvent) => {
    container.style.cursor = null!;
    // allow some small movement before showing context menu - people sometimes right click and move the mouse slightly
    const pixelsMoved = Math.abs(startX - e.clientX) + Math.abs(startY - e.clientY);
    if (e.button === 2 && pixelsMoved < 50 && (e.target === container || e.target === selectionRectangle)) {
      areaOnContextMenu(e);
    }
  };

  container.addEventListener('mousedown', downListener);
  container.addEventListener('mouseup', upListener);
  // We need to listen on the selection rectangle as well, because it is next to the container
  selectionRectangle.addEventListener('mouseup', upListener);

  editor.removeEventListernersFunctions.push(() => {
    container.removeEventListener('mousedown', downListener);
    container.removeEventListener('mouseup', upListener);
    selectionRectangle.removeEventListener('mouseup', upListener);
  });
};

const addUndoRedoEventListener = (editor: CustomNodeEditor) => {
  const undoListener = (e: KeyboardEvent) => {
    if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'z' && !e.shiftKey) {
      editor.historyPlugin.undo();
      e.preventDefault();
      e.stopPropagation();
    }
  };
  const redoListener = (e: KeyboardEvent) => {
    if ((e.ctrlKey || e.metaKey) && (e.key.toLowerCase() === 'y' || (e.shiftKey && e.key.toLowerCase() === 'z'))) {
      editor.historyPlugin.redo();
      e.preventDefault();
      e.stopPropagation();
    }
  };
  document.addEventListener('keydown', undoListener);
  document.addEventListener('keydown', redoListener);
  editor.removeEventListernersFunctions.push(() => {
    document.removeEventListener('keydown', undoListener);
    document.removeEventListener('keydown', redoListener);
  });
};

const disableCommentUndoRedo =
  (editor: CustomNodeEditor): Pipe<BaseArea<Schemes> | Root<Schemes>> =>
  (context) => {
    // stop piping context to history plugin if the action is on a comment node
    if ((context.type === 'nodecreated' || context.type === 'noderemoved') && context.data.data.type === COMMENT_TYPE) {
      return undefined;
    }
    if (context.type === 'nodetranslated') {
      const dataNode = editor.getNode(context.data.id);
      if (dataNode?.data.type === COMMENT_TYPE) {
        return undefined;
      }
    }
    return context;
  };

const historyChangesChecker =
  (editor: CustomNodeEditor): Pipe<BaseArea<Schemes> | Root<Schemes>> =>
  (context) => {
    // We need to check it here because the history might have changed by many ways, even the ones that we do not control, but rete does
    editor.onHistoryMightHaveChanged();
    return context;
  };

const AUTOSAVE_KEY = 'topicEditorAutosave-';

type AutosaveData = {
  time: number;
  hash: string;
  language: string;
  nodesData: string;
};

const setupAutosave = (editor: CustomNodeEditor, topicId: string, t: TFunction) => {
  removeOldAutosaveRecords();
  editor.addHistoryChangeListener(
    debounce(
      () => {
        const time = Date.now();
        const savedDataHash = editor.initialNodesDataHash!;
        updatePositionInNodeData(editor, editor.getNodes() as DataNode[]);
        updateNodeDataBasedOnConnections(editor.getNodes(), editor.getConnections());
        const nodesData = editor.getNodes().map((node) => node.data);
        const currentDataHash = MD5(JSON.stringify(nodesData)).toString();
        if (savedDataHash === currentDataHash) return; // No need to save if the data did not change
        const data: AutosaveData = {
          time,
          hash: savedDataHash,
          language: editor.language!,
          nodesData: compress(JSON.stringify(nodesData)),
        };
        const compressedData = JSON.stringify(data);
        localStorage.setItem(AUTOSAVE_KEY + topicId, compressedData);
      },
      2000,
      { maxWait: 3000 },
    ),
  );
};

export const getAutosavedNodesData = (editor: CustomNodeEditor, topicId: string, nodesDataFromServer: NodeData[]) => {
  const stringifiedData = localStorage.getItem(AUTOSAVE_KEY + topicId);
  if (stringifiedData) {
    const data = JSON.parse(stringifiedData) as AutosaveData;
    const serverDataHash = MD5(JSON.stringify(nodesDataFromServer)).toString();
    return {
      language: data.language,
      date: new Date(data.time),
      nodesData: JSON.parse(decompress(data.nodesData)) as NodeData[],
      hash: data.hash,
      sameHash: data.hash === serverDataHash,
    };
  }
  return null;
};

export const deleteAutosave = (topicId: string, wait = false) => {
  if (wait) {
    // We need to wait a bit before deleting the autosave, because debounce on save might save it again after we delete it
    setTimeout(() => {
      localStorage.removeItem(AUTOSAVE_KEY + topicId);
    }, 3500);
  } else {
    localStorage.removeItem(AUTOSAVE_KEY + topicId);
  }
};

/**
 * Removes old autosave records from the local storage that are older than 7 days
 */
const removeOldAutosaveRecords = () => {
  const keys = Object.keys(localStorage);
  keys.forEach((key) => {
    if (key.startsWith(AUTOSAVE_KEY)) {
      const stringifiedData = localStorage.getItem(key)!;
      const data = JSON.parse(stringifiedData) as AutosaveData;
      if (data.time < Date.now() - 7 * 24 * 60 * 60 * 1000) {
        localStorage.removeItem(key);
      }
    }
  });
};
