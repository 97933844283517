import { BodyMedium, BoxWithImage, HeadingMedium, theme, usePath } from '@arnold/common';
import { ReactComponent as EmptySurveys } from '@arnold/common/src/assets/images/EmptySurveys.svg';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { EDITION } from '../../lib/common';
import { TopicGroupTypeCode } from '../../generated/hooks';

interface Props {
  edition?: string;
}

const FREE_ONB_TOPIC_GROUP_ID = 11512;

export const NoOnboardingsInfo: FC<Props> = ({ edition }) => {
  const { t } = useTranslation('onboarding');
  const { conversationSubjectsPath, createSurveyGroupPath } = usePath();
  const isFree = edition === EDITION.FREE.name;

  return (
    <BoxWithImage image={<EmptySurveys />}>
      <div data-cy={'admin-no-onboarding'}>
        <HeadingMedium margin={`0 0 ${theme.spacing.f} 0`}>{t('noOnboarding')}</HeadingMedium>
        <BodyMedium>{isFree ? t('noFreeOnboardingText') : t('noOnboardingText')}</BodyMedium>
        <Button
          variant={'outline-primary'}
          data-icom={isFree ? 'open-price-list' : 'start-onboarding'}
          data-cy={isFree ? 'btn-show-pricelist' : 'btn-create-onboarding'}
          className="align-self-start"
          onClick={() =>
            isFree
              ? createSurveyGroupPath.toFunc(FREE_ONB_TOPIC_GROUP_ID.toString())
              : conversationSubjectsPath.toFunc(TopicGroupTypeCode.Onboarding)
          }
        >
          {t('startOnboarding')}
        </Button>
      </div>
    </BoxWithImage>
  );
};
