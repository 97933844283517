import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { usePath } from '@arnold/common';
import { Table } from '../../components/Table/Table';
import { UserQuery, UserSysRole } from '../../generated/hooks';
import { getDataSource } from './getDataSource';
import { SurveyArchiveData } from './types';

type SurveyArchiveTableProps = {
  organization: NonNullable<UserQuery['user']['organization']>;
  surveys: (
    | NonNullable<UserQuery['user']['organization']>['surveys'][0]
    | NonNullable<UserQuery['user']['organization']>['surveyGroups'][0]
  )[];
  user: UserQuery['user'];
};

export const SurveyArchiveTable: FC<SurveyArchiveTableProps> = ({ surveys, organization, user }) => {
  const [surveysOverviewT] = useTranslation('surveysOverview');
  const [archiveT, i18n] = useTranslation('surveyArchive');
  const { archivedSurveyDetailPath, archivedProcessSurveyDetailPath, archivedOnboardingPath } = usePath();

  return (
    <Table<SurveyArchiveData>
      rowDataCypressTestFlag="archive-list-item"
      columns={[
        {
          key: 'topic',
          title: surveysOverviewT('chat'),
          dataIndex: 'topic',
        },
        {
          key: 'sendAt',
          title: surveysOverviewT('startDate'),
          dataIndex: 'sendAt',
        },
        {
          key: 'archivedAt',
          title: archiveT('archiveDate'),
          dataIndex: 'archivedAt',
        },
        {
          key: 'actions',
          title: undefined,
          dataIndex: 'actions',
        },
      ]}
      dataSources={getDataSource({
        surveys,
        organization,
        toSurveyDetail: archivedSurveyDetailPath.toFunc,
        toProcessSurveyDetail: archivedProcessSurveyDetailPath.toFunc,
        toOnboarding: archivedOnboardingPath.toFunc,
        t: archiveT,
        i18nLanguage: i18n.language,
        sysAdmin: user.systemRole === UserSysRole.SysAdmin,
      })}
      selected={undefined}
    />
  );
};
