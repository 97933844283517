import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Col, FormCheck, FormGroup } from 'react-bootstrap';
import BaseModal from '../BaseModal';
import { CheckBoxLabelStyled, FormLabelStyled } from '../../Common';
import { OrganizationQuery } from '../../../generated/hooks';
import { useGetOrganizationTeams } from '../../../screens/Surveys/utils';
import { CreateNewLeader } from './CreateNewLeader';
import { ChooseExistingEmployee } from './ChooseExistingEmployee';

type Props = {
  cancel: () => void;
  onChooseTeam: (teamId: string) => void;
  organization: OrganizationQuery['organization'];
};

export function NewTeam({ cancel, onChooseTeam, organization }: Props) {
  const [step, setStep] = useState<'choosingType' | 'creatingNew' | 'choosingExisting'>('choosingType');
  const [creatingNew, setCreatingNew] = useState(false);
  const { t } = useTranslation('newTeamModal');

  const handleChooseTeam = (teamId: string) => {
    onChooseTeam(teamId);
    cancel();
  };

  const { organizationTeams, loadingOrError } = useGetOrganizationTeams(organization?.id || '');

  if (loadingOrError) {
    return loadingOrError;
  }

  const renderChoosingType = () => (
    <>
      <FormGroup as={Col} className="mb-1">
        <FormLabelStyled>{t('leaderOfNewTeam')}</FormLabelStyled>
        <FormCheck>
          <FormCheck.Input
            type="radio"
            value="false"
            onChange={() => setCreatingNew(false)}
            checked={!creatingNew}
            data-cy="choose-existing-employee"
          />
          <CheckBoxLabelStyled className="form-check__label" onClick={() => setCreatingNew(false)}>
            {t('choseExistingEmployee')}
          </CheckBoxLabelStyled>
        </FormCheck>
      </FormGroup>
      <FormGroup as={Col}>
        <FormCheck>
          <FormCheck.Input
            type="radio"
            value="true"
            onChange={() => setCreatingNew(true)}
            checked={creatingNew}
            data-cy="create-new-employee"
          />
          <CheckBoxLabelStyled className="form-check__label" onClick={() => setCreatingNew(true)}>
            {t('createNewLeader')}
          </CheckBoxLabelStyled>
        </FormCheck>
      </FormGroup>
      <div>
        <Button
          color="primary"
          onClick={() => setStep(creatingNew ? 'creatingNew' : 'choosingExisting')}
          data-cy="new-team-modal-continue"
        >
          {t('continue')}
        </Button>
        <Button color="primary" variant="outline-primary" onClick={cancel}>
          {t('cancel')}
        </Button>
      </div>
    </>
  );

  const renderContent = () => {
    switch (step) {
      case 'choosingType':
        return renderChoosingType();
      case 'choosingExisting':
        return (
          <ChooseExistingEmployee
            goBack={() => setStep('choosingType')}
            onChooseTeam={handleChooseTeam}
            organizationTeams={organizationTeams || []}
          />
        );
      case 'creatingNew':
        return (
          <CreateNewLeader
            goBack={() => setStep('choosingType')}
            onChooseTeam={handleChooseTeam}
            organizationTeams={organizationTeams || []}
          />
        );
    }
  };

  return <BaseModal onClose={cancel} title={t('title')} description={renderContent()} />;
}
