import { useMutation } from '@apollo/client';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useState, useEffect } from 'react';
import { FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormErrorFeedback, FormGroupLabel } from '../../components/Common';
import { ActionsWrap, AppLink, LinkWrap, PrimaryButton } from '../../components/StyledComponents';
import { resetPasswordMutation } from '../../graphql/mutations';
import { USER_KEY } from '../../lib/auth';
import { Origin } from '../../generated/hooks';
import RecoverPasswordSuccess from './RecoverPasswordSuccess';

interface IFormValues {
  email: string;
  responseError?: string;
}

interface IProps {
  lang?: string;
}

const RecoverPasswordForm = ({ lang }: IProps) => {
  const [t] = useTranslation('recoverPassword');
  const [emailSent, setEmailSent] = useState('');
  const [resetPassword] = useMutation(resetPasswordMutation);

  const ResetPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('fillEmail')),
  });

  useEffect(() => {
    localStorage.removeItem(USER_KEY);
  }, []);

  const handleOnSubmit = async (values: FormikValues, { setErrors, setSubmitting }: FormikHelpers<IFormValues>) => {
    try {
      const username = values.email;
      setSubmitting(true);
      await resetPassword({ variables: { username, origin: Origin.SelfCare } });
      setEmailSent(username);
      setSubmitting(false);
    } catch (e) {
      setSubmitting(false);
      setErrors({
        responseError: (e as Error).message,
      });
    }
  };

  const initialValues: IFormValues = {
    email: '',
    responseError: undefined,
  };

  if (!!emailSent) {
    return <RecoverPasswordSuccess email={emailSent} />;
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={ResetPasswordSchema}>
      {({ errors, values, handleChange, handleSubmit, isSubmitting, touched }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <FormGroupLabel>{t('email')}</FormGroupLabel>
              <FormControl
                type="text"
                name="email"
                value={values.email || ''}
                onChange={handleChange}
                isInvalid={touched.email && (!!errors.email || !!errors.responseError)}
              />
              {!!errors.email && <FormErrorFeedback error={errors.email} />}
            </FormGroup>
            <ActionsWrap>
              <PrimaryButton variant="primary" type="submit" disabled={isSubmitting}>
                {t('forgottenPassword')}
              </PrimaryButton>
              <LinkWrap>
                <AppLink to={`/login${lang ? `/${lang}` : ''}`}>{t('loginForm:signIn')}</AppLink>
              </LinkWrap>
            </ActionsWrap>
          </Form>
        );
      }}
    </Formik>
  );
};

export default RecoverPasswordForm;
