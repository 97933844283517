import { formatDate, theme } from '@arnold/common';
import { default as ErrorWarningIcon } from '@arnold/common/src/assets/icons/ErrorWarningIcon.svg';
import { default as Vector } from '@arnold/common/src/assets/icons/Vector.svg';
import { differenceInDays } from 'date-fns';
import { TFunction } from 'i18next';
import { DashboardQuery, SurveyStatus } from '../../generated/hooks';

type Survey = NonNullable<DashboardQuery['user']['organization']>['currentSurveys'][0];

export const getValue = (survey: Survey) => {
  if ([SurveyStatus.Prepared, SurveyStatus.Notstarted].includes(survey.status)) {
    return undefined;
  }

  if (survey.status === SurveyStatus.Ongoing && survey.public) {
    return survey.resultsInfo?.answered;
  }

  return `${Math.round(
    survey.resultsInfo?.total ? (survey.resultsInfo?.answered / survey.resultsInfo?.total) * 100 : 0,
  )} %`;
};

export const getTitle = (survey: Survey, t: TFunction) => {
  if ([SurveyStatus.Prepared, SurveyStatus.Notstarted].includes(survey.status)) {
    return t('startAt');
  }

  if (survey.status === SurveyStatus.Ongoing && survey.public) {
    return '';
  }

  return t('responseRate');
};

export const getSubtitle = (survey: Survey, t: TFunction) => {
  if (survey.status === SurveyStatus.Prepared) {
    return formatDate(survey.sendAt);
  }

  if (survey.status === SurveyStatus.Notstarted) {
    return t('notstarted');
  }

  if (survey.status === SurveyStatus.Ongoing && survey.public) {
    return t('publicParticipants', {
      count: survey.resultsInfo?.answered,
      postProcess: 'interval',
    });
  }

  return `${survey.resultsInfo?.answered} / ${survey.resultsInfo?.total}`;
};

export const getIcon = (survey: Survey) => {
  if (survey.status === SurveyStatus.Prepared) {
    return Vector;
  }

  if (survey.status === SurveyStatus.Notstarted) {
    return ErrorWarningIcon;
  }

  return undefined;
};

const getBarChartFillValue = (value: number) => {
  if (value < 4) {
    return theme.colors.emotionDanger.backgroundActive;
  }

  if (value >= 5) {
    return theme.colors.emotionSuccess.backgroundActive;
  }

  return theme.colors.emotionWarning.backgroundActive;
};

export const getBarChartData = (
  surveyGroup?: NonNullable<DashboardQuery['user']['organization']>['activeOnboadings'][0],
) => {
  if (!surveyGroup) {
    return [];
  }

  return surveyGroup?.steps
    .map((step, index) => {
      const stepTitle = step.processStep.stepTopicGroup.name;
      return {
        x: stepTitle,
        y: step.avgMetric ?? 0,
        fill: getBarChartFillValue(step.avgMetric || 0),
        id: surveyGroup.id,
        step: index,
        chatSatisfactionScale: step.processStep.stepTopicGroup.chatSatisfactionScale,
      };
    })
    .filter((step) => !!step.chatSatisfactionScale);
};

export const getSurveyGroup = (
  surveyGroups: NonNullable<DashboardQuery['user']['organization']>['activeOnboadings'],
  surveyGroupId: string,
) => surveyGroups.find((surveyGroup) => surveyGroup.id === surveyGroupId);

export const getLabel = (survey: Survey, t: TFunction) => {
  if (survey.status === SurveyStatus.Ongoing) {
    if (survey.public) {
      return t('publicOngoingSurvey');
    }
    return `${t('ongoingSurvey')} ${t('days', {
      count: differenceInDays(new Date(survey.endsAt), new Date()),
      postProcess: 'interval',
    })}.`;
  }

  if (survey.status === SurveyStatus.Prepared) {
    return `${t('preparedSurvey')} ${t('days', {
      count: differenceInDays(new Date(survey.sendAt), new Date()),
      postProcess: 'interval',
    })}.`;
  }

  if (survey.status === SurveyStatus.Notstarted) {
    return t('notStartedSurvey');
  }

  return '';
};
