import { BodySmall, Breadcrumb, HeadingLarge, HeadingMedium, theme } from '@arnold/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import { ErrorPage } from '../../components/StyledComponents';
import { getTopicGroupTranslationByLanguageId } from '../../lib/common';
import { prepareStepOffsets } from '../../lib/process';
import TopicPreview from '../Topic/TopicPreview';
import { prepareQuestion, prepareSections, QuestionDetail, TopicStructure } from '../Topic/topicStructure';
import {
  TopicGroupQuery,
  useTopicDetailLazyQuery,
  useTopicGroupQuery,
  useUpdateProcessStepMutation,
  useUserQuery,
} from '../../generated/hooks';
import { StepInfoBox } from './StepInfoBox/StepInfoBox';

const ProcessStepDetailScreen = (props: RouteComponentProps<any>) => {
  const {
    match: { params },
  } = props;
  const getStep = (data?: TopicGroupQuery) => data?.topicGroup?.steps?.find((step) => step.id === params.stepId);
  const [topicStructure, setTopicStructure] = useState<TopicStructure | null>(null);
  const [allQuestions, setAllQuestions] = useState<QuestionDetail[]>([]);
  const [activeSection, setActiveSection] = useState<number | null>(null);
  const [sectionInEditMode, setSectionInEditMode] = useState<number | null>(null);
  const { t } = useTranslation('processDetail');
  const [saveProcessStep] = useUpdateProcessStepMutation();

  // Load topic detail
  const { loading: userLoading, data: userData, error: userError } = useUserQuery();
  const [loadTopicDetail, { loading: topicDetailLoading, error: topicDetailError, data: topicDetailData }] =
    useTopicDetailLazyQuery({
      fetchPolicy: 'no-cache',
      onCompleted: (responseData) => {
        setActiveSection(null);
        const allQuestions = responseData.topicDetail.allQuestions.map(prepareQuestion);
        setAllQuestions(allQuestions);
        const newTopicStructure = prepareSections(allQuestions);
        setTopicStructure(newTopicStructure);
      },
    });
  // Load process topic group with steps
  const {
    loading: topicGroupLoading,
    error: topicGroupError,
    data: topicGroupData,
  } = useTopicGroupQuery({
    variables: { topicGroupId: params.topicGroupId, language: params.language },
    onCompleted: (responseData) => {
      const step = getStep(responseData);
      if (step?.stepTopicGroup.lastValidTopic?.id) {
        loadTopicDetail({
          variables: {
            topicId: step?.stepTopicGroup.lastValidTopic?.id,
          },
        });
      }
    },
  });

  const step = getStep(topicGroupData);

  if (topicGroupError || userError || topicDetailError || (!step && topicGroupData)) {
    return <ErrorPage>{t('common:loadError')}</ErrorPage>;
  }
  if (
    topicGroupLoading ||
    topicDetailLoading ||
    userLoading ||
    !topicGroupData ||
    !userData ||
    !topicDetailData ||
    !topicStructure
  ) {
    return <Loading />;
  }

  const { organization } = userData.user;
  if (!organization || !step) {
    return null;
  }
  const languageId = params.language || organization.primaryLanguageId.toString(10);
  const processTopicGroupTranslation = getTopicGroupTranslationByLanguageId(
    languageId,
    topicGroupData.topicGroup.translations,
  );
  const stepTopicGroupTranslation = getTopicGroupTranslationByLanguageId(
    languageId,
    topicDetailData.topicDetail.topicGroup.translations,
  );
  const offsets = prepareStepOffsets({
    startOffsetMins: step.startOffsetMins,
    remindersOffsetMins: step.reminders.map((reminder) => reminder.reminderOffsetMins),
    endOffsetMins: step.endOffsetMins,
  });

  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb
        classList={'mt-n6'}
        items={[
          { title: t('teamsScreen:surveys'), link: '/overview' },
          { title: t('ReportAccess:topics'), link: '/topics' },
          {
            title: processTopicGroupTranslation ? processTopicGroupTranslation.value : topicGroupData.topicGroup.name,
            link: `/process/${params.topicGroupId}`,
          },
          {
            title: stepTopicGroupTranslation
              ? stepTopicGroupTranslation.value
              : topicDetailData.topicDetail.topicGroup.name,
            active: true,
          },
        ]}
      />
      <HeadingLarge>
        {stepTopicGroupTranslation ? stepTopicGroupTranslation.value : topicDetailData.topicDetail.topicGroup.name}
      </HeadingLarge>
      <p>
        {stepTopicGroupTranslation
          ? stepTopicGroupTranslation.description
          : topicDetailData.topicDetail.topicGroup.description}
      </p>

      <HeadingMedium margin={`${theme.spacing.h} 0 ${theme.spacing.c}`}>{t('datesTitle')}</HeadingMedium>
      <BodySmall>{t('datesDescription')}</BodySmall>
      <StepInfoBox
        start={offsets.startOffset}
        reminder={offsets.remindersOffset?.[0] || null}
        secondReminder={offsets.remindersOffset?.[1] || null}
        thirdReminder={offsets.remindersOffset?.[2] || null}
        end={offsets.endOffset}
        isEditable={topicGroupData.topicGroup.organization?.id === organization.id}
        isNew={false}
        step={step}
        saveForm={async (offsets: {
          startOffsetMins: number;
          remindersOffsetMins: number[];
          endOffsetMins: number;
        }) => {
          await saveProcessStep({
            variables: {
              id: params.stepId,
              input: {
                startOffsetMins: offsets.startOffsetMins,
                remindersOffsetMins: offsets.remindersOffsetMins,
                endOffsetMins: offsets.endOffsetMins,
              },
            },
          });
        }}
      />
      <HeadingMedium>{t('chat')}</HeadingMedium>
      <TopicPreview
        isEditable={false}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
        sectionInEditMode={sectionInEditMode}
        setSectionInEditMode={setSectionInEditMode}
        topicDetail={topicDetailData.topicDetail}
        topicStructure={topicStructure}
        allQuestions={allQuestions}
        languageId={languageId}
        urlBase={`/process/${params.topicGroupId}/step/${params.stepId}`}
        organization={organization}
        reloadTopic={async () => {
          if (step?.stepTopicGroup.lastValidTopic?.id) {
            loadTopicDetail({
              variables: {
                topicId: step?.stepTopicGroup.lastValidTopic?.id,
              },
            });
          }
        }}
      />
    </PageWrapper>
  );
};

export default ProcessStepDetailScreen;
