import { FormGroup, Col } from 'react-bootstrap';
import { BodyLarge, BodySmall, Checkbox, theme } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction } from 'react';
import { Card, FormGroupLabel, FormRadioInput } from '../Common';
import TeamSelect from '../SurveyGroup/TeamSelect';
import { Features, SurveyQuery, UserQuery, ContactType, ActiveRespondentsQuery } from '../../generated/hooks';
import { SurveyRespondentsTable } from './SurveyParticipantsTable';

interface IProps<T> {
  notStarted: boolean;
  setFormValues: Dispatch<SetStateAction<T>>;
  formValues: T;
  activeRespondents?: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'];
  teams?: NonNullable<ActiveRespondentsQuery['user']['organization']>['teams'];
  organization: UserQuery['user']['organization'];
}

interface MandatoryFormData {
  respondents: NonNullable<SurveyQuery['survey']>['whitelistedRespondents'];
  whitelist: boolean;
  sendEmailSms: boolean;
}

export const SurveyParticipantsInput = <T extends MandatoryFormData>({
  notStarted,
  setFormValues,
  formValues,
  activeRespondents = [],
  teams,
  organization,
}: IProps<T>) => {
  const { t } = useTranslation('surveyForm');

  const setRespondents = (
    respondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'],
  ) => {
    const respondentsIds = respondents.map((respondent) => respondent.id);
    setFormValues((oldValues) => ({
      ...oldValues,
      whitelist: true,
      respondents: activeRespondents.filter((activeRespondent) => respondentsIds.includes(activeRespondent.id)),
    }));
  };

  const removeRespondent = (ids: string[]) => {
    setFormValues((oldValues) => ({
      ...oldValues,
      respondents: oldValues.respondents.filter((respondent) => !ids.includes(respondent.id)),
    }));
  };

  return (
    <Card data-icom={'form-survey-participants'}>
      <FormGroupLabel>{t('whitelistSwitch')}</FormGroupLabel>
      <FormGroup className="form-check-group" data-icom={'form-survey-whitelist'}>
        <FormRadioInput
          name="whitelist"
          dataCy="admin-edit-survey-wholeOrganization"
          disabled={!notStarted}
          value={false}
          clicked={() => {
            setFormValues((oldValues) => ({
              ...oldValues,
              respondents: [],
              whitelist: false,
            }));
          }}
          defaultChecked={!formValues.whitelist}
          label={t('wholeOrganization')}
        />
        <FormRadioInput
          name="whitelist"
          dataCy="admin-edit-survey-selectParticipants"
          disabled={!notStarted}
          value={true}
          clicked={() => {
            setFormValues((oldValues) => ({
              ...oldValues,
              whitelist: true,
            }));
          }}
          defaultChecked={formValues.whitelist}
          label={t('selectedRespondents')}
        />
      </FormGroup>
      {formValues.whitelist && (
        <>
          {notStarted && (
            <div className="d-flex" data-cy="admin-edit-survey-selectNewParticipants">
              <TeamSelect
                setRespondents={setRespondents}
                respondents={formValues.respondents}
                activeRespondents={activeRespondents}
                teams={teams}
              />
            </div>
          )}
          <FormGroup>
            <SurveyRespondentsTable respondents={formValues.respondents} removeRespondent={removeRespondent} />
          </FormGroup>
        </>
      )}
      <BodyLarge margin={`${theme.spacing.f} 0 0 ${theme.spacing.f}`} bold={true}>
        {t('respondentsCount', {
          count: formValues.whitelist ? formValues.respondents.length : activeRespondents.length,
        })}
      </BodyLarge>
      {organization?.featureNames?.find((feature) => feature === Features.SendEmailSms) && (
        <FormGroup as={Col} sm={8} style={{ marginTop: `${theme.spacing.g}` }}>
          <Checkbox
            selected={formValues.sendEmailSms}
            onToggle={() => {
              setFormValues((oldValues) => ({
                ...oldValues,
                sendEmailSms: !oldValues.sendEmailSms,
              }));
            }}
            text={
              <div>
                <div data-icom={'admin-send-email-sms-info'}>{t('sendEmailSms')}</div>
                <BodySmall color={theme.colors.text.secondary}>
                  {t('nummberOfAffectedParticipants', {
                    count: formValues.whitelist
                      ? formValues.respondents.filter(
                          (respondent) =>
                            respondent.contacts?.find((contact) => contact.type === ContactType.Email) &&
                            respondent.contacts?.find((contact) => contact.type === ContactType.Sms),
                        ).length
                      : activeRespondents.filter(
                          (respondent) =>
                            respondent.contacts?.find((contact) => contact.type === ContactType.Email) &&
                            respondent.contacts?.find((contact) => contact.type === ContactType.Sms),
                        ).length,
                  })}
                </BodySmall>
              </div>
            }
          />
        </FormGroup>
      )}
    </Card>
  );
};

export default SurveyParticipantsInput;
