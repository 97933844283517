import styled from '@emotion/styled';
import { theme } from '../../theme';

export const ItemWithCheckboxContainer = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  padding: ${theme.spacing.c} ${theme.spacing.f};
  background: ${theme.colors.backgroundCover.default};
  transition: background 0.25s ease;
  &:hover {
    background: ${({ disabled }) =>
      disabled ? theme.colors.backgroundCover.default : theme.colors.backgroundBasic.default};
  }
  &:hover .inline-checkbox {
    border-color: ${({ disabled }) => (disabled ? theme.colors.borderMain.disabled : theme.colors.borderMain.focused)};
  }
`;
