import React from 'react';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled/macro';

interface IButtonProps {
  margin?: string;
}

export const LinkButton = (props: any) => (
  <Button variant="link" bsPrefix="override" className="link no-btn font-size-xs" onClick={props.clicked}>
    {props.children}
  </Button>
);

export const ActionButton = styled(Button)`
  margin: ${({ margin }: IButtonProps) => margin || '0'};
`;
