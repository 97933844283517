import { ReactComponent as Calendar } from '@arnold/common/src/assets/icons/CalendarIcon.svg';
import { ReactComponent as ChartResult } from '@arnold/common/src/assets/icons/ChartResult.svg';
import { ReactComponent as Company } from '@arnold/common/src/assets/icons/Company.svg';
import { ReactComponent as SettingsIcon } from '@arnold/common/src/assets/icons/SettingsIcon.svg';
import { ReactComponent as Survey } from '@arnold/common/src/assets/icons/Survey.svg';
import { ReactComponent as Team } from '@arnold/common/src/assets/icons/Team.svg';
import classNames from 'classnames';
import PerfectScrollbar from 'perfect-scrollbar';
import { ReactElement, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getFrontendConfigValue, useToast } from '@arnold/common';
import { RouteComponentProps, useLocation, withRouter } from 'react-router-dom';
import { OrganizationLicence, RouteType } from '../../lib/types';
import { ScreenWrapper } from '../Common';
import { InformationFooter } from '../index';
import packageJson from '../../../package.json';
import Navbar from './Navbar';
import SideBar from './SideBar';

interface IProps extends RouteComponentProps<any> {
  children: ReactElement;
  organizationName: string;
  userId?: number;
  organizationLicence?: OrganizationLicence;
  allowedMoreOrganizations: boolean;
  product?: string;
  hasProcessSurveys: boolean;
  leftNavbarOnly?: boolean;
  hideSidebar?: boolean;
  organizationId?: string | null;
}

function AuthorizedPage({
  children,
  organizationName,
  organizationLicence,
  hasProcessSurveys,
  leftNavbarOnly,
  allowedMoreOrganizations,
  product,
  userId,
  hideSidebar,
  organizationId,
}: IProps) {
  const [t] = useTranslation('AuthorizedPage');
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { addToast } = useToast();
  const [needsUpdate, setNeedsUpdate] = useState(false);

  // hook to check the app version on route change
  function useVersionCheck() {
    // check if a version refresh needed on route change
    const location = useLocation();
    useLayoutEffect(() => {
      // if there is an update available
      if (needsUpdate) {
        setNeedsUpdate(false);
        window.location.reload(); // refresh the browser
      }
    }, [location]);
  }

  useVersionCheck();

  useLayoutEffect(() => {
    let ps: PerfectScrollbar | null = null;
    if (navigator.platform.indexOf('Win') > -1 && wrapperRef.current != null) {
      document.documentElement.className += ' perfect-scrollbar-on';
      document.documentElement.classList.remove('perfect-scrollbar-off');
      ps = new PerfectScrollbar(wrapperRef.current);
    }
    return function destroy() {
      if (ps) {
        ps.destroy();
        document.documentElement.className += ' perfect-scrollbar-off';
        document.documentElement.classList.remove('perfect-scrollbar-on');
      }
    };
  });

  useEffect(() => {
    // If the page was reloaded because company was switched in other tab, show a toast
    if (organizationName && localStorage.getItem('pageReloaded') === 'true') {
      localStorage.removeItem('pageReloaded');
      addToast(t('pageReloadedToast', { orgName: organizationName }));
    }

    // Check if user switched org in another tab every time he switches tabs or every 5 minutes
    let interval: any;
    const handleVisibilityChange = () => {
      if (document.hidden) {
        clearInterval(interval);
      } else {
        checkIfOrgHasChanged(userId, organizationId, setNeedsUpdate);
        clearInterval(interval);
        interval = setInterval(() => checkIfOrgHasChanged(userId, organizationId, setNeedsUpdate), 5 * 60 * 1000);
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    interval = setInterval(() => checkIfOrgHasChanged(userId, organizationId, setNeedsUpdate), 5 * 60 * 1000);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearInterval(interval);
    };
  }, [addToast, organizationId, organizationName, t, userId]);

  const routes: RouteType[] = [
    {
      name: t('adminOverview'),
      path: '/overview',
      iconSvg: <Calendar />,
      isVisible: true,
      icomTag: 'nav-overview',
    },
    {
      name: t('surveys'),
      path: '/onboarding',
      iconSvg: <Survey />,
      isVisible: true,
      icomTag: 'nav-surveys',
      children: [
        {
          name: t('onboarding'),
          path: '/onboarding',
          isVisible: true,
          icomTag: 'nav-onboarding',
        },
        {
          name: t('exit'),
          path: '/exit',
          isVisible: true,
          icomTag: 'nav-exit',
        },
        {
          name: t('processSurveys'),
          path: '/processSurveys',
          isVisible: hasProcessSurveys,
          icomTag: 'nav-process-surveys',
        },
        {
          name: t('standaloneSurveys'),
          path: '/surveys',
          isVisible: true,
          icomTag: 'nav-standalone-surveys',
        },
        {
          name: t('templates'),
          path: '/topics',
          isVisible: true,
          icomTag: 'nav-templates',
        },
        {
          name: t('archive'),
          path: '/surveyArchive',
          isVisible: true,
          icomTag: 'nav-archive',
        },
      ],
    },
    {
      name: t('results'),
      path: '/results',
      iconSvg: <ChartResult />,
      isVisible: true,
      icomTag: 'nav-results',
    },
    {
      name: t('organizationalStructure'),
      path: '/teams',
      iconSvg: <Team />,
      isVisible: true,
      icomTag: 'nav-organizational-structure',
      children: [
        {
          name: t('people'),
          path: '/teams',
          isVisible: true,
          icomTag: 'nav-teams',
        },
        {
          name: t('report'),
          path: '/report',
          isVisible: true,
          icomTag: 'nav-reports',
        },
      ],
    },
    {
      name: t('settings'),
      path: '/settings',
      iconSvg: <SettingsIcon />,
      isVisible: true,
      icomTag: 'nav-settings',
    },
    {
      name: t('organizations'),
      path: '/organizations',
      iconSvg: <Company />,
      isVisible: allowedMoreOrganizations,
      icomTag: 'nav-organizations',
    },
  ];

  return (
    <ScreenWrapper className="wrapper" data-cy="admin-page">
      {!hideSidebar && (
        <SideBar
          routes={routes}
          organizationLicence={organizationLicence}
          product={product}
          organizationName={organizationName}
        />
      )}
      <div className={classNames('main-panel', hideSidebar && 'main-panel--without-sidebar')} ref={wrapperRef}>
        {!leftNavbarOnly && <Navbar userId={userId} />}
        <div className={leftNavbarOnly ? 'no-padding-content' : 'content'}>{children}</div>
        {!leftNavbarOnly && <InformationFooter />}
      </div>
    </ScreenWrapper>
  );
}

export default withRouter(AuthorizedPage);

// check if user switched org in another tab and refresh the page
const checkIfOrgHasChanged = async (
  userId: number | undefined,
  orgId: string | null | undefined,
  setNeedsUpdate: (needsUpdate: boolean) => void,
) => {
  if (!userId || !orgId) return;
  const isUserUnderCorrectOrg = await fetch(
    `${getFrontendConfigValue('API_URL')}/check-user-under-correct-org?userId=${userId}&orgId=${orgId}`,
  )
    .then((r) => {
      if (r.headers.get('x-version') !== packageJson.version) {
        setNeedsUpdate(true);
      }
      return r.text();
    })
    .catch((e) => {
      console.error('Error checking if user is still under correct org', e);
    });

  if (isUserUnderCorrectOrg !== 'true') {
    localStorage.setItem('pageReloaded', 'true');
    window.location.reload();
  }
};
