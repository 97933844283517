import { PlainButton, TrashIcon, theme } from '@arnold/common';
import { FC } from 'react';
import { Drag } from 'rete-react-plugin';
import styled from '@emotion/styled';
import { NodeData } from '../dataNode';
import { useDeleteCommentGroupMutation } from '../../../generated/hooks';
import { getTGAccessHeaderContext } from '../utils';

type Props = {
  data: NodeData;
  readOnly: boolean;
};

const StyledPlainButton = styled(PlainButton)`
  position: absolute;
  top: ${theme.spacing.c};
  right: ${theme.spacing.f};
  background-color: transparent;
  padding: ${theme.spacing.b};
  width: 22px;
  height: 22px;

  & path {
    fill: ${theme.colors.text.primary};
  }

  &:hover {
    background-color: transparent !important;
  }
`;

export const DeleteCommentButton: FC<Props> = ({ data, readOnly }) => {
  const [deleteCommentGroup] = useDeleteCommentGroupMutation();
  if (readOnly) return null;
  return (
    <Drag.NoDrag>
      <StyledPlainButton
        onClick={() => {
          const removedNode = data.onDelete?.();
          if (removedNode?.data.commentGroupId) {
            deleteCommentGroup({
              context: data.search ? getTGAccessHeaderContext(data.search) : undefined,
              variables: {
                id: removedNode.data.commentGroupId,
              },
            });
          }
        }}
      >
        <TrashIcon />
      </StyledPlainButton>
    </Drag.NoDrag>
  );
};
