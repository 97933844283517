import { ReactComponent as Check } from '@arnold/common/lib/assets/icons/Check.svg';
import { ReactComponent as Line } from '@arnold/common/lib/assets/icons/Line.svg';
import styled from '@emotion/styled';
import { Checkbox } from '../others';
import { theme } from '../../theme';
import { TableHeadCell } from './TableHeadCell';
import { TableThinHeadCell } from './TableThinHeadCell';
import type { TableColumn, TableDataType, TableProps } from './types';

type Props<T extends TableDataType> = Pick<TableProps<T>, 'thin'> & {
  columns: Array<TableColumn<T>>;
  selected?: boolean;
  partiallySelected?: boolean;
  onChange?: () => void;
};

const StyledCheckboxTh = styled.th<{ thin: boolean }>`
  padding: ${({ thin }) => (thin ? theme.spacing.d : '10px')} ${theme.spacing.f};
`;

export const TableHead = <T extends TableDataType>({
  columns,
  thin,
  selected,
  partiallySelected,
  onChange,
}: Props<T>): JSX.Element => {
  return (
    <tr>
      {onChange && (
        <StyledCheckboxTh thin={thin ?? false}>
          <Checkbox
            onToggle={onChange}
            selected={selected ?? false}
            partlySelected={partiallySelected ?? false}
            text={''}
            checkIcon={<Check />}
            lineIcon={<Line />}
            paddingMultiplier={0}
            labelStyles={'bottom: 12px;'}
          />
        </StyledCheckboxTh>
      )}
      {columns.map((column, index) =>
        thin ? <TableThinHeadCell key={`head-${index}`} column={column} /> : <TableHeadCell column={column} />,
      )}
    </tr>
  );
};
