import { addHours } from 'date-fns';

export function getWorkingDay(date: Date): { workingDay: Date; shiftInDays: number } {
  if ([0, 6].includes(date.getDay())) {
    // is weekend
    const { workingDay, shiftInDays } = getWorkingDay(addHours(date, 24));
    return { workingDay, shiftInDays: shiftInDays + 1 };
  }
  return {
    workingDay: date,
    shiftInDays: 0,
  };
}
