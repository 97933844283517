import countries from 'i18n-iso-countries';
import i18n from 'i18next';
import {
  csFlag,
  gbFlag,
  skFlag,
  plFlag,
  srFlag,
  huFlag,
  ruFlag,
  bgFlag,
  roFlag,
  esFlag,
  fiFlag,
  frFlag,
  itFlag,
  ltFlag,
  ukFlag,
  otherFlag,
} from '../assets/images/languageFlags';
import { default as AddIcon } from '../assets/icons/AddIcon.svg';

export const getFlagByCode = (code: string): string => {
  switch (code) {
    case 'en':
      return gbFlag;
    case 'cs':
      return csFlag;
    case 'sk':
      return skFlag;
    case 'pl':
      return plFlag;
    case 'sr':
      return srFlag;
    case 'hu':
      return huFlag;
    case 'ru':
      return ruFlag;
    case 'bg':
      return bgFlag;
    case 'ro':
      return roFlag;
    case 'es':
      return esFlag;
    case 'fi':
      return fiFlag;
    case 'fr':
      return frFlag;
    case 'it':
      return itFlag;
    case 'lt':
      return ltFlag;
    case 'uk':
      return ukFlag;
    case 'addLanguage':
      return AddIcon;
    default:
      console.warn(`No flag found for country code ${code}!`);
      return otherFlag;
  }
};

export const changeI18nLanguage = (languageCode: string) =>
  i18n.changeLanguage(languageCode, () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    countries.registerLocale(require(`i18n-iso-countries/langs/${i18n.language}.json`));
  });
