import { FC } from 'react';
import { Tooltip, getFlagByCode, theme } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { sortLanguages } from '../../../lib/common';
import { AllowedConversationSubjectQuery } from '../../../generated/hooks';

const DISPLAY_COUNT = 3;

type Organization = NonNullable<AllowedConversationSubjectQuery['user']['organization']>;

type LanguagesIconsProps = {
  languages?: Organization['allowedConversationSubjects'][0]['languages'];
};
export const LanguagesIcons: FC<LanguagesIconsProps> = ({ languages }) => {
  const { t } = useTranslation('languageSelector');

  if (!languages) {
    return null;
  }

  const sortedLanguages = sortLanguages(
    languages,
    new Map(languages.map((l) => [l.code, t(`languageSelector:${l.code}`)])),
  );

  const restCount = languages?.length - DISPLAY_COUNT;

  return (
    <Tooltip
      position="top"
      animation="none"
      title={t('summaryTitle', {
        languages: sortedLanguages.map(({ code }) => t(code)).join(', '),
      })}
    >
      <Languages>
        {sortedLanguages
          ?.slice(0, DISPLAY_COUNT)
          .map(({ code }) => <img key={code} src={getFlagByCode(code)} alt={code + ' icon'} />)}
        {restCount > 0 && <CountIndicator>+{restCount}</CountIndicator>}
      </Languages>
    </Tooltip>
  );
};

const CountIndicator = styled.span`
  color: ${theme.colors.brand.primary};
`;
const Languages = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: end;
  grid-gap: ${theme.spacing.b};
`;
