const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const parseNameFromEmail = (email: string) => {
  const artificialUsername = email.split('@')[0];
  return {
    firstname: capitalizeFirstLetter((artificialUsername.match(/[_.-]/) && artificialUsername.split(/[_.-]/)[0]) || ''),
    surname: capitalizeFirstLetter(
      (artificialUsername.match(/[_.-]/) && artificialUsername.split(/[_.-]/)[1]) || artificialUsername,
    ),
  };
};
