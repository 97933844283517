import { theme, ScrollIcon, MouseLeftClickIcon, MouseAreaIcon, MouseRightClickIcon } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { useTranslation } from 'react-i18next';
import InformationFooter from '../InformationFooter';
import BaseModal, { WIDTH } from './BaseModal';

const Container = styled.div`
  margin-top: ${theme.spacing.h};
  margin-bottom: ${theme.spacing.g};
  padding-right: ${theme.spacing.g};
  height: 65vh;
  overflow-y: scroll;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: ${theme.spacing.d} 0;
`;

const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33%;
  gap: 8px;
`;

const Element = styled.span`
  color: ${theme.colors.textInverted.primary};
  background-color: ${theme.colors.backgroundDark.default};
  border-radius: 6px;
  padding: ${theme.spacing.c} ${theme.spacing.d};
`;

const Description = styled.span`
  margin-left: ${theme.spacing.c};
  width: 66%;
`;

interface IProps {
  close: () => void;
}

const KeyDiv = styled.div`
  display: flex;
  align-items: center;
  padding: ${theme.spacing.c} ${theme.spacing.d};
  border: 1px solid ${theme.colors.borderMain.default};
  border-radius: 6px;
`;

const editationTips = [
  { symbols: <ScrollIcon />, name: 'Scroll' },
  { symbols: <MouseLeftClickIcon />, name: 'Select' },
  {
    symbols: (
      <>
        <KeyDiv>Ctrl</KeyDiv> + <MouseLeftClickIcon />
      </>
    ),
    name: 'SelectMultipleCtrl',
  },
  { symbols: <MouseAreaIcon />, name: 'SelectArea' },
  {
    symbols: (
      <>
        <KeyDiv>Ctrl</KeyDiv> + <KeyDiv>c</KeyDiv>
      </>
    ),
    name: 'Copy',
  },
  { symbols: <MouseRightClickIcon />, name: 'ContextMenu' },
];

const specialElements = [
  {
    element: '|',
    descriptionKey: 'pipe',
  },
  {
    element: '||',
    descriptionKey: 'doublePipe',
  },
  {
    element: '%ORG_NAME%',
    descriptionKey: 'orgName',
  },
  {
    element: '%FIRST_DATE%',
    descriptionKey: 'startDate',
  },
  {
    element: '%TL_NAME%',
    descriptionKey: 'teamLeaderName',
  },
  {
    element: 'linkFormat',
    descriptionKey: 'link',
  },
];

const SectionHeader = styled.h2`
  font-weight: ${theme.typography.heading.medium.default.fontWeight};
  font-size: ${theme.typography.heading.medium.default.fontSize};
  margin-top: ${theme.spacing.j};
  margin-bottom: ${theme.spacing.j};
`;

function ArielHints({ close }: IProps) {
  const [t] = useTranslation('topicEditorHints');
  return (
    <BaseModal
      title={t('editingTitle')}
      width={WIDTH.MEDIUM}
      description={
        <Container>
          {editationTips.map((tip, index) => (
            <>
              <Row>
                <ElementWrapper>{tip.symbols}</ElementWrapper>
                <Description>
                  <b>{t(`editing${tip.name}Text`)}</b> {t(`editing${tip.name}Description`)}
                </Description>
              </Row>
              {index !== specialElements.length - 1 && <hr />}
            </>
          ))}
          <SectionHeader>{t('title')}</SectionHeader>
          {specialElements.map((el, index) => (
            <>
              <Row>
                <ElementWrapper>
                  <Element>{t(el.element)}</Element>
                </ElementWrapper>
                <Description>{t(el.descriptionKey)}</Description>
              </Row>
              {index !== specialElements.length - 1 && <hr />}
            </>
          ))}
          <InformationFooter />
        </Container>
      }
      secondaryActionText={t('common:close')}
      onSecondaryAction={close}
      onClose={close}
      styles={{ width: '850px' }}
    />
  );
}
export default ArielHints;
