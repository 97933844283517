import styled from '@emotion/styled';

export const CardBody = styled.div`
  padding: 15px 15px 10px 15px;
  flex: 1 1 auto;
`;

export const CardTitle = styled.div`
  margin-bottom: 0.75rem;
`;
