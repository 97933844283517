import styled from '@emotion/styled/macro';
import { useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { Tooltip, theme } from '@arnold/common';
import { ArnoldIcon, ArnoldLogo } from '../../assets/images';
import { OrganizationLicence, RouteType } from '../../lib/types';
import LicenceInfo from './LicenceInfo';

interface IProps extends RouteComponentProps<any> {
  routes: RouteType[];
  organizationLicence?: OrganizationLicence;
  product?: string;
  organizationName: string;
}

const SidebarDiv = styled.div`
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;

const MenuListItem = styled.li`
  background-color: ${theme.colors.backgroundCover.default};
`;

const SidebarWrapper = styled.div`
  padding-bottom: 0 !important;
`;

const ArnoldFullLogo = styled.img`
  max-height: 128px;
  object-fit: contain;
  html:not(.nav-open) .sidebar-mini:not(.tippy-touch) .sidebar:not(:hover) & {
    display: none;
  }
`;
const ArnoldSmallLogo = styled.img`
  max-height: 128px;
  object-fit: contain;
  display: none;
  margin: 0 auto;
  html:not(.nav-open) .sidebar-mini:not(.tippy-touch) .sidebar:not(:hover) & {
    display: block;
  }
`;

const Center = styled.div({
  textAlign: 'center',
});

const OrganizationName = styled.strong`
  font-size: 14px;
  font-weight: ${theme.typography.heading.medium.default.fontWeight};
  font-family: '${theme.typography.display.large.default.family}';
  color: ${theme.colors.backgroundDark.default};
  /* letter-spacing: 0.26em; */
  display: inline-block;
  margin: calc(${theme.spacing.g} - 7px) 0 calc(${theme.spacing.g} - ${theme.spacing.f});

  html:not(.nav-open) .sidebar-mini:not(.tippy-touch) .sidebar:not(:hover) & {
    font-size: 10px;
    letter-spacing: 0em;
    margin: calc(${theme.spacing.f} - 2px) 0 0;
  }
`;

const MenuItem = styled.div`
  display: flex;
  svg path {
    fill: ${theme.colors.text.secondary};
  }
  &.active svg path {
    fill: ${theme.colorPrimary};
  }
  gap: ${theme.spacing.f};
  align-items: center;
`;

const SubMenu = styled.ul`
  list-style: none;
  padding-left: 0;
`;

const SubMenuItem = styled.li`
  padding-left: 44px;
`;

function SideBar({ location, organizationLicence, routes, product, organizationName }: IProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const activeRoute = (path: string) => {
    const currentPath = location.pathname;
    if (currentPath.includes('reportAccess')) {
      return path === '/overview' ? 'active' : '';
    } else if (currentPath.includes('updateRespondent')) {
      return path === '/teams' ? 'active' : '';
    } else if (currentPath.includes('onboarding')) {
      return path === '/onboarding' ? 'active' : '';
    } else if (currentPath.includes('archivedSurveyDetail')) {
      return path === '/surveyArchive' ? 'active' : '';
    } else if (currentPath.includes('archivedOnboarding')) {
      return path === '/surveyArchive' ? 'active' : '';
    } else if (currentPath.includes('archivedProcessSurvey')) {
      return path === '/surveyArchive' ? 'active' : '';
    } else if (currentPath.includes('surveyDetail')) {
      return path === '/surveys' ? 'active' : '';
    } else if (currentPath.includes('onboardingConversationSubjects')) {
      return path === '/onboarding' ? 'active' : '';
    } else if (currentPath.includes('processSurveyConversationSubjects')) {
      return path === '/processSurveys' ? 'active' : '';
    } else if (currentPath.includes('processSurvey')) {
      return path === '/processSurveys' ? 'active' : '';
    }
    return currentPath.includes(path) ? 'active' : '';
  };

  let shorterOrgName = '';
  if (organizationName.length > 50) {
    shorterOrgName = `${organizationName.slice(0, 49)}…`;
  }

  return (
    <SidebarDiv className="sidebar" data-color="white" data-active-color="info">
      <div className="logo">
        <Center>
          <ArnoldFullLogo src={ArnoldLogo} />
          <ArnoldSmallLogo src={ArnoldIcon} />
          <OrganizationName data-cy="organization-name">
            {shorterOrgName ? <Tooltip title={organizationName}>{shorterOrgName}</Tooltip> : organizationName}
          </OrganizationName>
        </Center>
      </div>
      <SidebarWrapper className="sidebar-wrapper" ref={wrapperRef}>
        <ul className="nav">
          {routes
            .filter((route) => route.isVisible)
            .map((route) => (
              <MenuListItem
                key={route.name}
                className={route.children ? undefined : activeRoute(route.path)}
                data-icom={route.icomTag}
                data-cy={route.icomTag}
              >
                <NavLink to={route.path}>
                  <MenuItem className={route.children ? undefined : activeRoute(route.path)}>
                    {route.icon && <i className={route.icon} />}
                    {route.iconSvg && route.iconSvg}
                    <p>{route.name}</p>
                  </MenuItem>
                </NavLink>
                {route.children && (
                  <SubMenu>
                    {route.children
                      .filter((route) => route.isVisible)
                      .map((route) => (
                        <SubMenuItem
                          key={route.name}
                          className={activeRoute(route.path)}
                          data-icom={route.icomTag}
                          data-cy={route.icomTag}
                        >
                          <NavLink to={route.path}>
                            <MenuItem>
                              {route.icon && <i className={route.icon} />}
                              {route.iconSvg && route.iconSvg}
                              <p>{route.name}</p>
                            </MenuItem>
                          </NavLink>
                        </SubMenuItem>
                      ))}
                  </SubMenu>
                )}
              </MenuListItem>
            ))}
        </ul>
        {organizationLicence && <LicenceInfo organizationLicence={organizationLicence} product={product} />}
      </SidebarWrapper>
    </SidebarDiv>
  );
}

export default withRouter(SideBar);
