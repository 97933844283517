import { IThemeProps, theme, cleanButtonStyle, CleanButton } from '@arnold/common';
import { css } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import { Button } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { Link } from 'react-router-dom';
import { Aside } from './Common/PublicLayout';
import { Card } from './Common';

export const CardTitle = styled.div<{ textLeft?: boolean }>((props) => ({
  fontSize: 24,
  fontWeight: 450,
  margin: 15,
  textAlign: props.textLeft ? 'left' : 'center',
}));

export const CardText = styled.div<{
  noMargin?: boolean;
  color?: string;
  colorRed?: boolean;
  minHeight?: number;
}>((props) => ({
  fontSize: 18,
  fontWeight: 'normal',
  margin: props.noMargin ? 0 : 8,
  color: props.colorRed ? '#dc3545' : props.color || 'inherit',
  minHeight: props.minHeight ? props.minHeight : 'auto',
}));

export const SurveyScreenHeading = styled.div`
  display: flex;
`;

export const RedText = styled.span`
  color: ${theme.colors.emotionDanger.default};
`;
export const WarningText = styled.span`
  color: ${theme.colors.emotionWarning.active};
`;

export const SurveyDetailContent = styled(Card)`
  border-top: 1px solid ${theme.colors.borderSeparator.default};
`;

interface ILinkProps extends IThemeProps {
  weight?: number;
}
const generaLinkStyle = ({ theme, weight }: ILinkProps) => css`
  transition: color 0.2s ease;
  cursor: pointer;
  text-decoration: none;
  color: ${theme && theme.colors.actionPrimary.default};
  font-weight: ${weight};
  &:hover {
    color: ${theme && theme.colors.actionPrimary.hover};
    text-decoration: underline;
  }
  &:focus {
    color: ${theme && theme.colors.actionPrimary.focused};
    text-decoration: underline;
  }
  &:active {
    color: ${theme && theme.colors.actionPrimary.default};
    text-decoration: underline;
  }
`;

export const ErrorPage = styled.div`
  justify-content: center;
  margin: 60px;
  display: flex;
  font-size: 35px;
  color: #bdbdbd;
`;

export const AppLink = styled(Link)`
  ${generaLinkStyle};
`;

export const ClassicLink = styled.a`
  ${generaLinkStyle};
`;

export const ShowPasswordButton = styled(CleanButton)`
  position: absolute;
  top: 38px;
  right: ${theme.spacing.f};
  path {
    fill: ${theme.colors.actionPrimary.default};
  }
`;

export const ButtonLink = styled.button`
  ${cleanButtonStyle};
  ${generaLinkStyle};
  &:disabled {
    color: ${theme.colors.actionPrimary.disabled};
    cursor: auto;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const DateSelector = styled.div(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingBottom: '0.5rem',
}));

export const DateSelectorInputGroup = styled.div(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-end',
}));

export const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: ${theme.spacing.f};
`;

interface IHeadingSubtextProps {
  dontLimitWidth?: boolean;
  margin?: string;
}
export const HeadingSubtext = styled.div`
  color: ${theme.colors.text.primary};
  font-size: ${theme.typography.body.small.regular.fontSize};
  font-weight: ${theme.typography.body.small.regular.fontWeight};
  max-width: ${({ dontLimitWidth }: IHeadingSubtextProps) => (dontLimitWidth ? 'none' : '600px')};
  margin: ${({ margin }: IHeadingSubtextProps) => margin || '0'};
`;

export const SectionHeaderButtonsContainer = styled.div`
  flex-shrink: 0;
  margin-left: ${theme.spacing.g};

  & button {
    margin-bottom: 0;
  }
`;

export const RegistrationAsideContent = styled(Aside)`
  @media (max-width: ${theme.breakpoint.small}) {
    padding: ${theme.spacing.h} 0 0;
    justify-content: center;
  }
  @media (min-width: ${theme.breakpoint.small}) {
    padding: ${theme.spacing.k};
    justify-content: flex-start;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    padding: 128px;
    justify-content: flex-start;
  }
`;

export const HeadingContainer = styled.div`
  padding: ${theme.spacing.h} 0;

  @media (min-width: ${theme.breakpoint.small}) {
    padding: ${theme.spacing.i} 0 ${theme.spacing.g} 0;
  }
`;

export const LogoContainer = styled.div`
  display: none;
  @media (min-width: ${theme.breakpoint.small}) {
    display: block;
    padding-bottom: ${theme.spacing.h};
    @media (min-width: ${theme.breakpoint.small}) {
      position: absolute;
      top: 664px;
    }
  }
`;

export const ActionsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 1.5rem;
  align-items: flex-start;
  @media (max-width: ${theme.breakpoint.small}) {
    justify-content: center;
  }
`;

export const LinkWrap = styled.div`
  padding: ${theme.spacing.d} ${theme.spacing.h};
  margin-left: ${theme.spacing.f};
  @media (max-width: ${theme.breakpoint.small}) {
    margin-top: ${theme.spacing.f};
  }
`;

export const CheckboxGroup = styled.label`
  display: grid;
  grid-auto-flow: column;
  align-items: start;
  justify-content: start;

  input[type='checkbox'] {
    margin-top: 3px;
  }
`;

export const PrimaryButton = styled(Button)`
  width: 100%;
  margin: 0;
  @media (min-width: ${theme.breakpoint.small}) {
    width: auto;
  }
`;

export const StyledDropdownToggle = styled(DropdownToggle)`
  display: flex;
  gap: ${theme.spacing.d};
  box-shadow: 0px 0px 10px -3px #707070;
  position: absolute;
  top: 6px;
  left: ${theme.spacing.f};
  z-index: 10;
  &:after {
    border: none !important;
  }
`;

const WIDTH = 154;

export const baseMenuStyles = css`
  width: ${WIDTH}px;
  margin-left: -${WIDTH / 2}px;
  margin-top: -20px;
  border-radius: 6px;
  background: ${theme.colors.backgroundCover.default};
  color: ${theme.colors.text.primary};
  font-weight: ${theme.typography.body.medium.regular.fontWeight} !important;
  font-size: ${theme.typography.body.medium.regular.fontSize} !important;
  box-shadow: ${theme.shadows.elevationContentDialog} !important;
  overflow: hidden;
`;

export const baseItemStyles = css`
  &&:hover,
  &&:focus {
    background: var(--background-cover-01-default, #f5f5f5) !important;
    color: var(--action-primary-01-default, #04b7ef) !important;
  }
  transition: all 0.2s ease-in-out;
  padding: 10px !important;
  cursor: pointer;
  overflow: hidden;
  font-weight: ${theme.typography.body.medium.regular.fontWeight} !important;
  font-size: ${theme.typography.body.medium.regular.fontSize} !important;
  border-radius: 0 !important;
`;

export const StyledDropdownMenu = styled(DropdownMenu)`
  transition: none !important;
  ${baseMenuStyles}
`;

export const StyledDropdownItem = styled(DropdownItem)`
  ${baseItemStyles}
`;
