/* eslint-disable @typescript-eslint/no-var-requires */
import * as fnsLocale from 'date-fns/locale';
import { format } from 'date-fns';
import { EN_LANGUAGE_CODE } from '../constants';
const CryptoAES = require('crypto-js/aes');
const CryptoENC = require('crypto-js/enc-utf8');

export const getLocale = (languageCode?: string): Locale => {
  if (!languageCode || languageCode === EN_LANGUAGE_CODE) {
    return fnsLocale[`${EN_LANGUAGE_CODE}GB`];
  }
  return fnsLocale[languageCode] || fnsLocale[`${EN_LANGUAGE_CODE}GB`];
};

export const getLocalizedDateWithoutLeadingZeroes = (date: Date, languageCode?: string, hideYear?: boolean) => {
  const formattedDate = new Intl.DateTimeFormat(getLocale(languageCode).code, {
    month: 'numeric',
    day: 'numeric',
    year: hideYear ? undefined : 'numeric',
  }).format(date);

  return formattedDate;
};

export const getLocalizedDateTimeLongFormWHoursAndMinutes = (date: Date, languageCode?: string) =>
  `${format(date, 'PPPP', { locale: getLocale(languageCode) })} ${format(date, 'p', {
    locale: getLocale(languageCode),
  })}`;

export const modifyText = (text: string, replacements: any) => {
  let replacedText = text;
  Object.entries(replacements).forEach((replace) => {
    replacedText = replacedText.replace(replace[0], replace[1] as string);
  });
  return replacedText;
};

export const getEncodedCryptoAES = (message, key) => {
  let text = CryptoAES.encrypt(message, key).toString();
  while (text.includes('/')) {
    text = CryptoAES.encrypt(message, key).toString();
  }

  return text;
};
export const decodeCryptoAES = (message, key) => {
  const text = CryptoAES.decrypt(message, key).toString(CryptoENC);

  return text;
};

export const removeAccents = (text: string) => {
  return text.normalize('NFD').replace(/\p{Diacritic}/gu, '');
};
