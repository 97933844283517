import { FC, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { FormControl, FormGroup } from 'react-bootstrap';
import { EditIcon, theme } from '@arnold/common';
import { ButtonLink } from '../../StyledComponents';
import { FormErrorFeedback } from '../../Common';
import { EditTopicGroupInfoModal } from './EditTopicGroupInfoModal';

type EditTopicGroupInfoProps = {
  name: string;
  description?: string;
  setName: (name: string) => void;
  setDescription: (description: string) => void;
  preview: boolean;
  isNameInvalid: boolean;
  allowedLanguages: string[];
};

const StyledLinkButton = styled(ButtonLink)`
  display: flex;
  align-items: center;
  -webkit-logical-height: auto;
  padding: 0 ${theme.spacing.d};
  margin: 0 0 0 ${theme.spacing.d};
`;

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 0;
`;

export const EditTopicGroupInfo: FC<EditTopicGroupInfoProps> = ({
  name,
  description,
  setName,
  setDescription,
  preview,
  isNameInvalid,
}) => {
  const { t } = useTranslation('createNewTopic');
  const [isModalOpen, setModalOpen] = useState(false);

  return (
    <>
      <StyledFormGroup>
        <FormControl
          type="text"
          name={'name'}
          value={name}
          data-icom="input-name-ariel"
          data-cy="topic-editor-name"
          disabled={preview}
          onChange={(e) => {
            setName(e.target.value);
          }}
          isInvalid={isNameInvalid}
        />
        {isNameInvalid && <FormErrorFeedback error={t('nameTooShort')} />}
      </StyledFormGroup>
      {!preview && (
        <StyledLinkButton
          onClick={() => setModalOpen(true)}
          data-icom="btn-edit-name-ariel"
          data-cy="btn-edit-name-ariel"
        >
          <EditIcon />
        </StyledLinkButton>
      )}
      {isModalOpen && (
        <EditTopicGroupInfoModal
          isModalOpen
          setModalOpen={setModalOpen}
          name={name}
          description={description}
          onSave={(values) => {
            setDescription(values.description);
            setName(values.name);
          }}
        />
      )}
    </>
  );
};
