import { ConditionalWrapper, ProductHistory, Tooltip, isNullish, theme } from '@arnold/common';
import { ReactComponent as DownChevron } from '@arnold/common/lib/assets/icons/Down-Chevron.svg';
import { ReactComponent as UpChevron } from '@arnold/common/lib/assets/icons/Up-Chevron.svg';
import { ProductCode, getLocale, getLocalizedDateWithoutLeadingZeroes, prepareSurveyDates } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { differenceInDays, format, startOfDay } from 'date-fns';
import { useEffect, useState } from 'react';
import { Col, FormCheck, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SurveyGroupsQuery, SurveyStatus } from '../../../generated/hooks';
import { getTGTranslation } from '../../../lib/helpers/surveyGroup';
import { CheckBoxLabelStyled, FormGroupLabel } from '../../Common';
import DateInput from '../../Common/DateInput';
import { ButtonLink } from '../../StyledComponents';
import { prepareStepOffsets } from '../../../lib/process';
import { SurveyGroupSurveysData } from '../../Modals/AddSGRespondentToOngoingSurveys';
import { SurveyGroupEntries } from './ProcessSurveySelect';

const SurveysTableHeader = styled.th`
  padding: 0 ${theme.spacing.d} 0 ${theme.spacing.e};
  margin-top: 0.25rem;
  color: ${theme.colors.text.secondary};
  font-size: 80%;
  margin-left: 1rem;
  margin-right: 1rem;
  padding-bottom: ${theme.spacing.c};
`;

const SurveysTableHeaderWithRadio = styled(SurveysTableHeader)`
  padding-left: 42px;
`;
const StyledButtonLink = styled(ButtonLink)`
  width: calc(100% - ${theme.spacing.c});
  flex: 1;
  justify-content: space-between;
  margin-left: ${theme.spacing.c};
`;

const StyledFormGroupLabel = styled(FormGroupLabel)`
  margin-bottom: ${theme.spacing.e} !important;
`;

const StyledTable = styled.table`
  table-layout: fixed;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 ${theme.spacing.d};
`;
const LinkText = styled.span`
  max-width: calc(100% - 20px);
  overflow-x: hidden;
  text-overflow: ellipsis;
  height: 123px;
`;
const LinkIcon = styled.span`
  margin-left: ${theme.spacing.e};
`;
const SurveyTable = styled.td`
  padding-left: ${theme.spacing.j};
`;

const Label = styled(CheckBoxLabelStyled)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  vertical-align: middle;
`;

interface ISurveysTableColumnProps {
  textAlign?: string;
}

const SurveysTableColumn = styled.td`
  padding: 0 ${theme.spacing.d};
  text-align: ${(props: ISurveysTableColumnProps) => props.textAlign || 'left'};
  vertical-align: baseline;
  white-space: nowrap;
`;

export type OnboardingItemProps = {
  onboarding: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0];
  onboardingSurveysToAdd: SurveyGroupEntries;
  setOnboardingSurveysToAdd: React.Dispatch<React.SetStateAction<SurveyGroupEntries>>;
  productHistory?: ProductHistory[] | null;
  setOnboardingOngoingSurveys: (surveys: Pick<SurveyGroupSurveysData, 'id' | 'topic'>[]) => void;
};

const OnboardingItem = ({
  onboarding,
  onboardingSurveysToAdd,
  setOnboardingSurveysToAdd,
  productHistory,
  setOnboardingOngoingSurveys,
}: OnboardingItemProps) => {
  const { t, i18n } = useTranslation(['updateRespondent', 'addRespondent']);
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!onboardingSurveysToAdd[onboarding.id]?.checked) {
      setExpanded(false);
    }
  }, [onboardingSurveysToAdd, onboarding.id]);
  return (
    <>
      <tr key={onboarding.id} data-cy={'onboarding-row-to-add'}>
        <SurveysTableColumn>
          <Tooltip title={onboarding.name || onboarding.topicGroup.name}>
            <FormCheck>
              <FormCheck.Input
                type="radio"
                name="onboardingToAdd"
                value={onboarding.id}
                className={'form-check__control'}
                checked={onboardingSurveysToAdd[onboarding.id]?.checked}
              />
              <Label
                className="form-check__label"
                onClick={() =>
                  setOnboardingSurveysToAdd(
                    Object.values(onboardingSurveysToAdd).reduce(
                      (acc, value) => ({
                        ...acc,
                        [value.surveyGroupId]: { ...value, checked: value.surveyGroupId === onboarding.id },
                      }),
                      {},
                    ),
                  )
                }
              >
                <StyledButtonLink
                  type="button"
                  onClick={(e) => {
                    setExpanded(!expanded);
                  }}
                >
                  <LinkText>{onboarding.name || onboarding.topicGroup.name}</LinkText>
                  <LinkIcon
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setExpanded(!expanded);
                    }}
                  >
                    {expanded ? <UpChevron /> : <DownChevron />}
                  </LinkIcon>
                </StyledButtonLink>
              </Label>
            </FormCheck>
          </Tooltip>
        </SurveysTableColumn>
        <SurveysTableColumn textAlign={'center'}>
          {onboarding.statistics?.answered}/{onboarding.statistics?.total}
        </SurveysTableColumn>
        <SurveysTableColumn textAlign={'center'}>
          <DateInput
            onDateChange={(value) => {
              setOnboardingSurveysToAdd({
                ...onboardingSurveysToAdd,
                [onboarding.id]: { ...onboardingSurveysToAdd[onboarding.id], addedOn: value.toISOString() },
              });
              setOnboardingOngoingSurveys(
                onboarding?.topicGroup.steps
                  ?.map((step) => {
                    const offsets = prepareStepOffsets({
                      startOffsetMins: step.startOffsetMins,
                      remindersOffsetMins: step.reminders.map((reminder) => reminder.reminderOffsetMins),
                      endOffsetMins: step.endOffsetMins,
                    });
                    if (isNullish(offsets) || isNullish(offsets.startOffset?.days)) {
                      return null;
                    }
                    const basicDescription = {
                      id: step.id,
                      status: undefined,
                      topic: getTGTranslation(step.stepTopicGroup.translations, i18n.language)!.value || '',
                    };
                    if (!value) {
                      return basicDescription;
                    }
                    const groupAddedOn = startOfDay(value);
                    const surveyDate = prepareSurveyDates(
                      {
                        startOffsetMins: step.startOffsetMins,
                        endOffsetMins: step.endOffsetMins,
                        remindersOffsetMins: step.reminders
                          .map((reminder) => reminder.reminderOffsetMins)
                          .filter(Boolean),
                      },
                      groupAddedOn,
                      true,
                    );
                    if (!surveyDate) {
                      return basicDescription!;
                    }
                    const now = new Date();
                    const status =
                      surveyDate.endsAt < now
                        ? SurveyStatus.Completed
                        : surveyDate.sendAt < now
                          ? SurveyStatus.Ongoing
                          : SurveyStatus.Prepared;

                    return {
                      ...basicDescription,
                      status,
                    };
                  })
                  .filter((item) => item?.status === SurveyStatus.Ongoing) as Pick<
                  SurveyGroupSurveysData,
                  'id' | 'topic'
                >[],
              );
            }}
            dataCy="added-on-input"
            inputName={'addedOn'}
            date={onboardingSurveysToAdd[onboarding.id]?.addedOn}
          />
        </SurveysTableColumn>
        <SurveysTableColumn textAlign={'center'}></SurveysTableColumn>
      </tr>
      {expanded && (
        <tr>
          <SurveyTable colSpan={4}>
            <Row>
              <Col sm={5}>
                <FormGroupLabel className="ml-0">{t('surveyGroup:topic')}</FormGroupLabel>
              </Col>
              <Col sm={3}>
                <FormGroupLabel className="ml-0">{t('surveyGroup:surveyStartDate')}</FormGroupLabel>
              </Col>
              <Col sm={4}>
                <FormGroupLabel className="ml-0">{t('SequenceSteps:startOffset')}</FormGroupLabel>
              </Col>
            </Row>
            {onboarding.topicGroup.steps?.map((step) => {
              const groupAddedOn = startOfDay(
                new Date(
                  onboardingSurveysToAdd[onboarding.id].addedOn
                    ? onboardingSurveysToAdd[onboarding.id].addedOn
                    : new Date(),
                ),
              );
              const surveyDate = prepareSurveyDates(step, groupAddedOn, true);
              if (!surveyDate) {
                return null;
              }
              const surveyStart = surveyDate.sendAt;
              const activeProductAtTheSurveyStart = productHistory?.find(
                (ph) =>
                  new Date(ph.validFrom).getTime() <= surveyStart.getTime() &&
                  new Date(ph.validTo).getTime() >= surveyStart.getTime(),
              );
              const willNotBeSent =
                surveyStart > new Date() &&
                (!activeProductAtTheSurveyStart || activeProductAtTheSurveyStart.product === ProductCode.Free);
              const diffInDays = differenceInDays(startOfDay(surveyDate.sendAt), startOfDay(groupAddedOn));

              return (
                <ConditionalWrapper
                  condition={willNotBeSent}
                  wrapper={(children) => <Tooltip title={t('teamioOnb:willNotBeSent')}>{children}</Tooltip>}
                >
                  <Row
                    className="mt-4 mb-4"
                    style={{ color: willNotBeSent ? theme.colors.emotionDanger.default : undefined }}
                    key={step.id}
                  >
                    <Col sm={5}>{getTGTranslation(step.stepTopicGroup.translations, i18n.language)!.value || ''}</Col>
                    <Col sm={3}>
                      {`${getLocalizedDateWithoutLeadingZeroes(surveyStart, i18n.language)} ${format(surveyStart, 'p', {
                        locale: getLocale(i18n.language),
                      })}`}
                    </Col>
                    <Col sm={4}>
                      {t('processDetail:dayText', {
                        dayNo: diffInDays + (diffInDays >= 0 ? 1 : 0),
                      })}
                    </Col>
                  </Row>
                </ConditionalWrapper>
              );
            })}
          </SurveyTable>
        </tr>
      )}
    </>
  );
};

export type OnboardingSelectProps = {
  onboardings?: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  onboardingSurveysToAdd: SurveyGroupEntries;
  setOnboardingSurveysToAdd: React.Dispatch<React.SetStateAction<SurveyGroupEntries>>;
  productHistory?: ProductHistory[] | null;
  setOnboardingOngoingSurveys: (surveys: Pick<SurveyGroupSurveysData, 'id' | 'topic'>[]) => void;
};

const OnboardingSelect = ({
  productHistory,
  onboardings,
  onboardingSurveysToAdd,
  setOnboardingSurveysToAdd,
  setOnboardingOngoingSurveys,
}: OnboardingSelectProps) => {
  const { t } = useTranslation(['updateRespondent', 'addRespondent']);

  if (!onboardings?.length) {
    return null;
  }

  return (
    <Row>
      <FormGroup as={Col} sm={12} data-cy={'onboarding-list-to-add'}>
        <StyledFormGroupLabel>{t('addRespondent:chooseOnboarding')}</StyledFormGroupLabel>
        <StyledTable>
          <thead>
            <tr>
              <SurveysTableHeaderWithRadio style={{ marginLeft: `${theme.spacing.i}`, width: '30%' }}>
                {t('name')}
              </SurveysTableHeaderWithRadio>
              <SurveysTableHeader style={{ width: '10%', textAlign: 'center' }}>{t('participants')}</SurveysTableHeader>
              <SurveysTableHeader style={{ width: '30%', textAlign: 'left', paddingLeft: 38 }}>
                {t('hireDate')}
              </SurveysTableHeader>
              <SurveysTableHeader style={{ width: '30%', textAlign: 'center' }}></SurveysTableHeader>
            </tr>
          </thead>
          <tbody>
            {onboardings.map((onboarding) => (
              <OnboardingItem
                productHistory={productHistory}
                onboarding={onboarding}
                key={onboarding.id}
                onboardingSurveysToAdd={onboardingSurveysToAdd}
                setOnboardingSurveysToAdd={setOnboardingSurveysToAdd}
                setOnboardingOngoingSurveys={setOnboardingOngoingSurveys}
              />
            ))}
          </tbody>
        </StyledTable>
      </FormGroup>
    </Row>
  );
};

export default OnboardingSelect;
