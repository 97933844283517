import { useMutation } from '@apollo/client';
import {
  BodySmall,
  Checkbox,
  HeadingSmallWDescription,
  LinkButton,
  createEventData,
  pushToDataLayer,
  theme,
  useToast,
} from '@arnold/common';
import styled from '@emotion/styled';
import { addDays } from 'date-fns';
import { Form, Formik } from 'formik';
import { ReactNode, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Loading } from '../../components';
import { Card, TitleCard } from '../../components/Common';
import { SurveyDates } from '../../components/Forms/SurveyForm';
import SurveySchedulingInput from '../../components/Forms/SurveySchedulingInput';
import { SurveyQuery, SurveyStatus, UserQuery, useReopenSurveyMutation } from '../../generated/hooks';
import { updateSurveyMutation } from '../../graphql/mutations';
import { getSurvey } from '../../graphql/queries';
import { getOperationName } from '../../lib/common';
import ee from '../../lib/eventEmitter';

interface IProps {
  surveyId: number;
  notStarted: boolean;
  reopenSurvey?: boolean;
  displayReopenButton?: boolean;
  afterReopenCallback?: () => void;
  heading?: ReactNode;
  user: UserQuery['user'];
  survey: SurveyQuery['survey'] | null;
  organization: UserQuery['user']['organization'];
}

type SurveyDatesTypeWithSendMessage = SurveyDates & {
  sendMessage?: boolean;
};
const SurveyScheduling = ({
  surveyId,
  notStarted,
  survey,
  organization,
  user,
  heading,
  reopenSurvey,
  displayReopenButton,
  afterReopenCallback,
}: IProps) => {
  const [t] = useTranslation('surveyForm');
  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToast();

  const surveyDates = {
    sendAt: survey?.sendAt,
    endsAt: survey?.endsAt,
    reminders: survey?.reminders ? survey?.reminders.map((r) => new Date(r.reminderAt)) : undefined,
  };

  if (reopenSurvey) {
    const newEndsAt = addDays(new Date(), 7);
    newEndsAt.setHours(17);
    newEndsAt.setMinutes(0);
    newEndsAt.setSeconds(0);
    surveyDates.endsAt = newEndsAt.toISOString();
  }

  const [formValues, setFormValues] = useState<SurveyDatesTypeWithSendMessage>(() => ({
    sendAt: new Date(surveyDates.sendAt),
    endsAt: surveyDates.endsAt ? new Date(surveyDates.endsAt) : undefined,
    reminders: surveyDates.reminders,
    sendMessage: true,
  }));
  const [updateSurvey] = useMutation(updateSurveyMutation, { refetchQueries: [getOperationName(getSurvey) || ''] });
  const [reopenSurveyFn] = useReopenSurveyMutation({ refetchQueries: [getOperationName(getSurvey) || ''] });

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      if (reopenSurvey) {
        await reopenSurveyFn({
          variables: {
            input: {
              surveyId: surveyId.toString(),
              endsAt: formValues.endsAt,
              sendMessage: formValues.sendMessage,
            },
          },
        });
        addToast(formValues.sendMessage ? t('reopenScreen:reminderScheduler') : t('reopenScreen:surveyOpened'));
        if (afterReopenCallback) {
          afterReopenCallback();
        }
        pushToDataLayer({
          userId: user.id,
          orgId: organization?.id,
          event: 'ux.reopen-survey-success',
          ...createEventData('survey', 'reopenSuccess', 'survey reopen success'),
        });
      } else {
        await updateSurvey({
          variables: {
            surveyId,
            input: {
              sendAt: survey?.status === SurveyStatus.Ongoing ? undefined : formValues.sendAt,
              endsAt: formValues.endsAt || null,
              reminders: formValues.reminders
                ?.slice()
                .filter((r) => r && r.getTime() > new Date().getTime())
                .sort((a, b) => a.getTime() - b.getTime())
                .map((reminderAt) => ({ reminderAt })),
            },
          },
        });
        addToast(t('toast:changesSaved'));
        pushToDataLayer({
          userId: user.id,
          orgId: organization?.id,
          event: 'ux.edit-survey-success',
          ...createEventData('survey', 'editSuccess', 'survey edit success'),
        });
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      addToast(t('toast:changesFailedToSave'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.edit-survey-error',
        ...createEventData('survey', 'editError', 'survey edit error'),
      });
    }
  };

  const handleEndSurvey = async () => {
    setLoading(true);
    try {
      await updateSurvey({
        variables: {
          surveyId,
          input: {
            endsAt: new Date().toISOString(),
          },
        },
      });
      addToast(t('toast:changesSaved'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.edit-survey-success',
        ...createEventData('survey', 'editSuccess', 'survey edit success'),
      });

      setLoading(false);
    } catch (e) {
      setLoading(false);
      addToast(t('toast:changesFailedToSave'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.edit-survey-error',
        ...createEventData('survey', 'editError', 'survey edit error'),
      });
    }
  };

  const finished = survey?.resultsInfo?.finished || 0;
  const total = survey?.resultsInfo?.total || 0;

  return (
    <div>
      <TitleCard>{heading ?? <BodySmall>{t('dateAndTimesDescription')}</BodySmall>}</TitleCard>
      {loading && <Loading />}
      {!loading && (
        <Formik onSubmit={handleFormSubmit} initialValues={formValues}>
          {({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <SurveySchedulingInput<SurveyDates>
                notStarted={notStarted}
                displayReopenButton={displayReopenButton}
                setFormValues={setFormValues}
                formValues={formValues}
                survey={survey}
                allowEditEnd={reopenSurvey}
                isPublic={!!survey?.public}
                showNote={false}
                isCompleted={survey?.status && [SurveyStatus.Completed, SurveyStatus.Archived].includes(survey?.status)}
              />
              {reopenSurvey && (
                <>
                  <TitleCard>
                    <HeadingSmallWDescription>{t('surveyForm:respondentsLabel')}</HeadingSmallWDescription>
                  </TitleCard>

                  <Card margin={`0 0 ${theme.spacing.j} 0`} data-icom={'form-survey-dates'}>
                    <p>
                      {t('surveyForm:respondentsCount', {
                        count: total,
                      })}
                    </p>
                    <p>
                      {t('reopenScreen:respondentsWithoutResponse', {
                        count: total - finished,
                      })}
                    </p>
                    <div>
                      <Checkbox
                        selected={!!formValues.sendMessage}
                        onToggle={() => {
                          setFormValues((prevState) => ({
                            ...prevState,
                            sendMessage: !prevState.sendMessage,
                          }));
                        }}
                        text={t('reopenScreen:sendReminder')}
                        dataCy="send-reminder-checkbox"
                      />
                    </div>
                  </Card>
                </>
              )}
              {(notStarted || survey?.status === SurveyStatus.Ongoing) && !reopenSurvey && (
                <Button
                  variant="primary"
                  className={'mr-6 mb-5'}
                  type="submit"
                  disabled={
                    formValues.reminders?.some(
                      (r, index) =>
                        r &&
                        (r.getTime() > formValues.endsAt?.getTime() ||
                          r.getTime() < formValues.sendAt.getTime() ||
                          (r.getTime() < new Date().getTime() &&
                            (!survey?.reminders?.[index]?.reminderAt ||
                              new Date(survey.reminders?.[index]?.reminderAt).getTime() !== r.getTime()))),
                    ) ||
                    (formValues.endsAt && formValues.endsAt.getTime() < new Date().getTime())
                  }
                >
                  {t('weekPicker:save')}
                </Button>
              )}
              {survey?.status === SurveyStatus.Ongoing && survey.public && (
                <LinkButton onClick={() => ee.emitModalEndPublicSurvey(handleEndSurvey, survey.topicGroup?.name || '')}>
                  {t('endSurvey')}
                </LinkButton>
              )}
              {reopenSurvey && (
                <>
                  <Button
                    variant="primary"
                    className={'mr-6 mb-5 mt-0'}
                    type="submit"
                    data-cy={'btn-reopen-survey-button'}
                  >
                    {t('reopenScreen:open')}
                  </Button>
                  <Button
                    color="primary"
                    variant={'outline-primary'}
                    className={'mr-6 mb-5 mt-0'}
                    onClick={() => {
                      if (afterReopenCallback) {
                        afterReopenCallback();
                      }
                    }}
                  >
                    {t('weekPicker:cancel')}
                  </Button>
                </>
              )}
              {!survey?.public && <ApproximateMailSendTimeNote />}
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export const ApproximateMailSendTimeNote = () => {
  const [t] = useTranslation('surveyForm');
  return (
    <Note>
      (*) {t('approximateMailSendTimeNote')}
      <NoteLink href={t('approximateMailSendTimeNoteLinkUrl')}>{t('approximateMailSendTimeNoteLink')}</NoteLink>.
    </Note>
  );
};

const Note = styled.div({
  ...theme.typography.body.medium.regular,
  color: theme.colors.text.secondary,
  marginBottom: theme.spacing.h,
});

const NoteLink = styled.a({
  color: theme.colors.brand.primary,
  textDecoration: 'underline',
});

export default SurveyScheduling;
