import { theme, usePath } from '@arnold/common';
import { FC } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Table } from '../../components/Table/Table';
import { OrganizationQuery, OrganizationSurveysQuery } from '../../generated/hooks';
import { getDataSource } from './getDataSource';
import { SurveyData } from './types';
import { getColumns } from './utils';

type SurveyTableProps = {
  organization: NonNullable<OrganizationQuery['organization']>;
  surveys: NonNullable<OrganizationSurveysQuery['organizationSurveys']>;
  pathStatus?: string;
};

export const SurveyTable: FC<SurveyTableProps> = ({ organization, surveys, pathStatus }) => {
  const [surveysOverviewT, i18n] = useTranslation('surveysOverview');

  const [surveyOverviewRowT] = useTranslation('surveyOverviewRow');

  const { surveyDetailPath, reportDetailPath } = usePath();

  if (!organization?.config) {
    return null;
  }

  return (
    <Card style={{ marginTop: theme.spacing.a }}>
      <Table<SurveyData>
        columns={getColumns(surveysOverviewT, pathStatus)}
        dataSources={getDataSource({
          surveys,
          organization,
          pathStatus,
          toSurveyDetail: surveyDetailPath.toFunc,
          toReportDetail: reportDetailPath.toFunc,
          t: surveyOverviewRowT,
          i18nLanguage: i18n.language,
        })}
        selected={undefined}
      />
    </Card>
  );
};
