import { Modal } from '@arnold/common';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormType, NewCustomTopicForm } from '../../ConversationSubjects/components/NewCustomTopicForm';

type EditTopicGroupInfoModalProps = {
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  name: string | undefined;
  description?: string;
  onSave: (values: { name: string; description: string; language?: string }) => void;
  language?: string;
  type?: FormType;
  languages?: string[];
  title?: string;
};

export const EditTopicGroupInfoModal: FC<EditTopicGroupInfoModalProps> = ({
  isModalOpen,
  setModalOpen,
  name,
  description,
  language,
  onSave,
  title,
  type = 'survey',
  languages,
}) => {
  const { t } = useTranslation('createNewTopic');
  const [topicName, setTopicName] = useState<string | undefined>(name);
  const [topicDescription, setTopicDescription] = useState<string>(description || '');
  const [topicLanguage, setTopicLanguage] = useState<string>(language || '');

  const handleSubmitForm = () => {
    onSave({ name: topicName!, description: topicDescription, language: topicLanguage });
  };

  const mapTitle = {
    onboarding: t('modalTitleOnboarding'),
    survey: t('modalTitleSurvey'),
    processSurvey: t('modalTitleProcessSurvey'),
  };

  const isNameInvalid = topicName !== undefined && topicName.length < 3;

  return (
    <Modal
      show={isModalOpen}
      onHide={() => {
        setModalOpen(false);
      }}
      title={title || mapTitle[type]}
      onSubmit={handleSubmitForm}
      data-cy="edit-topic-modal-button"
      buttons={{
        cancel: { title: t('modal:cancel') },
        submit: { title: t('confirm'), disabled: isNameInvalid || topicName === undefined },
      }}
      content={
        <NewCustomTopicForm
          name={topicName}
          setName={setTopicName}
          description={topicDescription}
          setDescription={setTopicDescription}
          isNameInvalid={isNameInvalid}
          language={topicLanguage}
          setLanguage={setTopicLanguage}
          languages={languages || []}
          type={type}
        />
      }
    />
  );
};
