import { IsAccessTokenValidQuery, UserSysRole } from '../../generated/hooks';

export const getFullName = (firstName?: string | null, surname?: string | null, surnameFirst?: boolean) => {
  if (firstName || surname) {
    if (surnameFirst) {
      return `${surname} ${firstName}`;
    }
    return `${firstName} ${surname}`;
  }
  return null;
};

export let loggedUser: IsAccessTokenValidQuery['isAccessTokenValid']['user'] | undefined;

export const setLoggedUser = (user: IsAccessTokenValidQuery['isAccessTokenValid']['user']) => {
  loggedUser = user;
};

export const isLoggedUserSysAdmin = () => {
  return loggedUser?.systemRole === UserSysRole.SysAdmin;
};
