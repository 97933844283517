import styled from '@emotion/styled/macro';
import React, { useState } from 'react';
import { RightArrowIcon } from '../../assets/images';

export type Props = {
  defaultCollapsed: boolean;
  nodeLabel: string;
  labelLink: string;
  peopleCount: number;
  className?: string;
  itemClassName?: string;
  children?: any;
  onClick?: any;
};

const TreeView = ({
  defaultCollapsed,
  nodeLabel,
  labelLink,
  peopleCount,
  className = '',
  itemClassName = '',
  onClick,
  children,
}: Props) => {
  const [collapsed, setCollapsed] = useState(defaultCollapsed);

  const handleArrowClick = (...args: any) => {
    if (children.length !== 0) {
      setCollapsed(!collapsed);
    }
    if (onClick) {
      onClick(...args);
    }
  };

  let arrowClassName = 'tree-view_arrow';
  let containerClassName = 'tree-view_children';
  if (collapsed) {
    arrowClassName += ' tree-view_arrow-collapsed';
    containerClassName += ' tree-view_children-collapsed';
  }
  if (children.length === 0) {
    arrowClassName += ' last';
  }

  const StyledArrow = styled.img({
    maxHeight: '10px',
    objectFit: 'contain',
  });

  const arrow = (
    <div className={className + ' ' + arrowClassName} onClick={handleArrowClick}>
      <StyledArrow src={RightArrowIcon} />
    </div>
  );

  return (
    <div className="tree-view">
      <div className={'tree-view_item ' + itemClassName}>
        {arrow}
        <a href={'#' + labelLink} className={children.length === 0 ? 'last' : ''}>
          {nodeLabel}
        </a>{' '}
        <span className="people-count"> ({peopleCount})</span>
      </div>
      <div className={containerClassName}>{children}</div>
    </div>
  );
};

export default TreeView;
