import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { Check, CloseIcon, ErrorWarningIcon, InfoIcon, TellIcon } from '../../assets/icons';
import { theme } from '../../theme';
import { CleanButton } from '../buttons';

interface IBoxProps {
  bgColor?: string;
  color?: string;
}

const BoxWrapper = styled.div<IBoxProps>`
  background-color: ${(props) => props.bgColor};
  border: 1px solid ${(props) => props.color};
  color: ${(props) => props.color};
  box-shadow: ${theme.shadows.elevationContentBlock};
  border-radius: 8px;
  padding: ${theme.spacing.f};
  display: flex;
  & svg {
    width: 22px;
    & path {
      fill: ${(props) => props.color};
    }
  }
`;

export interface IAlertProps {
  className?: string;
  children?: ReactNode;
  severity: Severity;
  onClose?: () => void;
}

export enum Severity {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  DANGER = 'danger',
}

const BG_COLORS = {
  info: theme.colors.emotionInformative.background,
  success: theme.colors.emotionSuccess.background,
  warning: theme.colors.emotionWarning.background,
  danger: theme.colors.emotionDanger.background,
};

const COLORS = {
  info: theme.colors.emotionInformative.default,
  success: theme.colors.emotionSuccess.default,
  warning: theme.colors.emotionWarning.default,
  danger: theme.colors.emotionDanger.default,
};

const ICONS = {
  info: <InfoIcon width={20} height={20} viewBox="0 0 22 23" />,
  success: <Check width={12} height={12} viewBox="0 0 11 8" style={{ marginTop: 4 }} />,
  warning: <TellIcon />,
  danger: <ErrorWarningIcon width={20} height={20} viewBox="0 0 24 24" />,
};

export const Alert = ({ children, className, severity, onClose }: IAlertProps) => {
  return (
    <BoxWrapper bgColor={BG_COLORS[severity]} color={COLORS[severity]} className={className}>
      {ICONS[severity]}
      <BoxSmallLabel>{children}</BoxSmallLabel>
      {onClose && (
        <CleanButton onClick={onClose} className="ml-6">
          <CloseIcon />
        </CleanButton>
      )}
    </BoxWrapper>
  );
};

const BoxSmallLabel = styled.p`
  padding-left: ${theme.spacing.f};
  flex: 1;
  margin-bottom: 0;
  color: inherit;
`;
