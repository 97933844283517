import { format } from 'date-fns';
import { getLocale } from '@arnold/core';
import i18n from 'i18next';

export const isNullish = (value: unknown): value is null | undefined => value === undefined || value === null;

export const isEmpty = (value: unknown): value is undefined | null | '' =>
  typeof value === 'undefined' || value === null || value === '';

export const formatDate = (date: string) =>
  date
    ? format(new Date(date), 'dd.MM.yyyy', {
        locale: getLocale(i18n.language),
      })
    : '';

export function notEmpty<T>(item: T | undefined | null | '' | false): item is T {
  return !isNullish(item) && !isEmpty(item) && item !== false;
}

export const cleanArray = <T>(array?: null | ReadonlyArray<T | undefined | null | '' | false>): Array<T> =>
  (array || []).filter(notEmpty);

export const isString = (value: unknown): value is string => typeof value === 'string';
export const isNumber = (value: unknown): value is number => typeof value === 'number';
// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function => typeof value === 'function';
export const checkValueOrThrowError = <T>(value: unknown, typeCheck: (value) => value is T) => {
  if (typeCheck(value)) {
    return value;
  }
  throw new Error();
};
