export const pushToDataLayer = (data: any) => {
  if (!(window as any).dataLayer) {
    return;
  }

  (window as any).dataLayer.push(data);
};

export const createEventData = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
  eventValue: number = 0,
  eventNonInteraction: boolean = true,
) => ({
  eventCategory,
  eventAction,
  eventLabel,
  eventValue,
  eventNonInteraction,
});

export const logInIntercomUser = (userId?: number | string, userHash?: string, createdAt?: Date) => {
  if (!(window as any).Intercom) {
    return;
  }

  (window as any).Intercom('boot', {
    app_id: 'z4k0ja8a',
    user_id: userId,
    user_hash: userHash,
    created_at: createdAt || new Date(),
  });
};

export const updateIntercomUser = (newData: { [key: string]: any }) => {
  if (!(window as any).Intercom) {
    return;
  }

  (window as any).Intercom('update', newData);
};

export const logOutIntercomUser = () => {
  if (!(window as any).Intercom) {
    return;
  }

  (window as any).Intercom('shutdown');
};

export const pushToDataLayerRespondentCreateSuccess = (user) =>
  pushToDataLayer({
    userId: user.id,
    orgId: user.organization?.id,
    event: 'ux.respondent-create-success',
    ...createEventData('organization', 'createRespondentSuccess', 'organization create respondent success'),
  });
