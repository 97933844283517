import styled from '@emotion/styled';
import { forwardRef, ReactNode } from 'react';
import { Dropdown } from 'react-bootstrap';
import { DropdownItemProps } from 'react-bootstrap/DropdownItem';
import { theme } from '../../theme';
import { PlainButton } from '../buttons';

export type ActionMenuItem = {
  label: string;
  action: (e: React.MouseEvent<DropdownItemProps>) => any;
  disabled?: boolean;
};

export interface IActionMenuProps {
  toggleIcon: ReactNode;
  items: ActionMenuItem[];
  margin?: string;
  align?: 'left' | 'right';
  dataIcom?: string;
  dataCy?: string;
  menuDataIcom?: string;
  inline?: boolean;
}

interface IActionMenuButtonWithMarginProps {
  margin?: string;
}

const ActionMenuButtonWrap = styled(PlainButton)`
  margin: ${({ margin }: IActionMenuButtonWithMarginProps) => margin || '4px 0 0 0'};
`;

const ActionMenuMenu = styled.div`
  box-shadow: 0 5px 30px -20px rgb(0 0 0 / 50%);
  border-radius: 6px;
  margin-top: ${theme.spacing.d};
  padding: ${theme.spacing.d} 0;
  width: max-content;
  z-index: 100;
  :before,
  :after {
    display: none !important;
  }
`;

const StyledActionMenuItem = styled.a`
  display: block;
  padding: ${theme.spacing.d} ${theme.spacing.f};
  cursor: pointer;
  font-family: ${theme.typography.body.medium.regular.family};
  color: ${theme.colors.text.primary};
  font-size: ${theme.typography.body.medium.regular.fontSize};
  font-weight: ${theme.typography.body.medium.regular.fontWeight};
  line-height: ${theme.typography.body.medium.regular.lineHeight};
  :hover {
    text-decoration: none;
    background-color: ${theme.colors.backgroundBasic.hover};
    color: ${theme.colors.text.primary};
  }
`;

const ActionMenuButton = forwardRef(({ children, onClick, margin, dataIcom }: any, ref: any) => (
  <ActionMenuButtonWrap
    data-icom={dataIcom}
    data-cy="menu-toggle"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    margin={margin}
  >
    {children}
  </ActionMenuButtonWrap>
));

export const ActionMenu = ({
  toggleIcon,
  items,
  margin,
  dataIcom,
  align,
  dataCy,
  menuDataIcom,
  inline,
}: IActionMenuProps) => (
  <Dropdown data-cy={dataCy} className={inline ? 'd-inline-block' : undefined}>
    <Dropdown.Toggle as={ActionMenuButton} margin={margin} dataIcom={dataIcom}>
      {toggleIcon}
    </Dropdown.Toggle>
    <Dropdown.Menu
      as={ActionMenuMenu}
      align={align ?? 'left'}
      flip={false}
      alignRight={false}
      theme={theme}
      data-icom={menuDataIcom}
    >
      {items.map((item) => (
        <Dropdown.Item
          key={item.label}
          as={StyledActionMenuItem}
          theme={theme}
          bsPrefix={'action-menu-item'}
          data-cy="menu-item"
          onClick={item.action}
        >
          {item.label}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);
