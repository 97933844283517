import { Modal } from '@arnold/common';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormType, NewCustomTopicForm } from './NewCustomTopicForm';

type Props = {
  handleSubmit: (name: string, description: string, language?: string) => void;
  type?: FormType | null;
  onClose: () => void;
  languages: string[];
  defaultLanguage: string;
};

export const NewCustomTopicModal: FC<Props> = ({
  type = 'survey',
  handleSubmit,
  onClose,
  defaultLanguage,
  languages,
}) => {
  const { t } = useTranslation('createNewTopic');
  const [language, setLanguage] = useState(defaultLanguage);
  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>('');
  const handleSubmitForm = () => {
    handleSubmit(name ?? '', description, language);
  };

  const mapTitle = {
    onboarding: t('modalTitleOnboarding'),
    survey: t('modalTitleSurvey'),
    processSurvey: t('modalTitleProcessSurvey'),
  };

  const isNameInvalid = name !== undefined && name.length < 3;

  return (
    <Modal
      show={!!type}
      onHide={() => {
        onClose();
        setName('');
      }}
      title={mapTitle[type!]}
      onSubmit={handleSubmitForm}
      buttons={{
        cancel: { title: t('modal:cancel') },
        submit: { title: t('modal:save'), disabled: isNameInvalid || name === undefined },
      }}
      content={
        <NewCustomTopicForm
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          isNameInvalid={isNameInvalid}
          setLanguage={setLanguage}
          language={language}
          languages={languages}
          type={type!}
        />
      }
    />
  );
};
