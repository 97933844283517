import { LinkButton, SmallSurveyCard, theme, usePath } from '@arnold/common';
import { REPORT_LINK } from '@arnold/core';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryIcon, goToTalkResults } from '../../lib/helpers';
import { DashboardQuery } from '../../generated/hooks';
import { DashboardSection } from './DashboardSection';
import { getSubtitle, getValue } from './utils';

type CompletedSurveysProps = {
  surveys?: NonNullable<DashboardQuery['user']['organization']>['currentSurveys'];
  onResultsClick: () => void;
};

export const CompletedSurveys: FC<CompletedSurveysProps> = ({ surveys, onResultsClick }) => {
  const { t } = useTranslation('dashboard');
  const { surveyDetailPath } = usePath();

  if (!surveys?.length) {
    return null;
  }

  return (
    <DashboardSection
      key={'completedSurveys'}
      title={t('completedSurveys')}
      keyName={'completed-surveys'}
      buttons={
        <div>
          {/* TODO: ARN-2157 - results page is not ready yet */}
          {/* <Button onClick={() => createSurveyResultsPath.toFunc()}>{t('surveyResults')}</Button> */}
        </div>
      }
    >
      {surveys.map((survey) => {
        const reportAccess = survey.topLevelReport;
        return (
          <SmallSurveyCard
            dataIcom={'completed-survey-card'}
            key={survey.id}
            header={(survey?.topic?.name, survey?.topicGroup?.name || '')}
            headerIcon={getCategoryIcon(!survey.topicGroup?.categoryCode, survey.topicGroup?.categoryCode)}
            results={
              <div>
                <strong style={{ paddingRight: theme.spacing.d }}>{getValue(survey)}</strong>
                <span style={{ fontSize: theme.typography.body.small.regular.fontSize, lineHeight: '120%' }}>
                  {getSubtitle(survey, t)}
                </span>
              </div>
            }
            footer={
              <>
                {reportAccess && (
                  <LinkButton
                    margin={'0'}
                    onClick={() => {
                      goToTalkResults(REPORT_LINK.REPORT, reportAccess.accessKey);
                      onResultsClick();
                    }}
                    data-icom={'btn-completed-survey-card-results'}
                  >
                    {t('results')}
                  </LinkButton>
                )}
                <LinkButton onClick={() => surveyDetailPath.toFunc(survey.id)} data-cy={'btn-survey-detail'}>
                  {t('detail')}
                </LinkButton>
              </>
            }
          />
        );
      })}
    </DashboardSection>
  );
};
