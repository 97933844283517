import { getFrontendConfigValue, useToast } from '@arnold/common';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Loading } from '../../components';
import { ErrorPage } from '../../components/StyledComponents';
import SurveyGroup from '../../components/SurveyGroup/SurveyGroup';
import {
  ProcessStatus,
  useCreateSurveyGroupWithRespondentsMutation,
  useTopicGroupQuery,
  useUserQuery,
} from '../../generated/hooks';
import { getOrganizationStructure, getSurveyGroups, getUserQuery } from '../../graphql/queries';
import { getOperationName } from '../../lib/common';
import ee from '../../lib/eventEmitter';

type CreateSurveyGroupScreenProps = RouteComponentProps<any> & {
  subjectId: string;
};

export const CreateSurveyGroupScreen = (props: CreateSurveyGroupScreenProps) => {
  const {
    match: { params },
    history,
  } = props;
  const { i18n, t } = useTranslation('surveyGroup');
  const { addToast } = useToast();

  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useUserQuery({
    variables: { languageCode: i18n.language },
  });

  const { loading, data, error } = useTopicGroupQuery({
    variables: {
      topicGroupId: params.subjectId,
    },
  });

  useEffect(() => {
    const surveyGroups =
      (userData &&
        userData.user.organization?.surveyGroups.filter(
          (sg) =>
            sg.topicGroup.id === params.subjectId &&
            [ProcessStatus.Prepared, ProcessStatus.Ongoing].includes(sg.status),
        )) ||
      [];
    if (surveyGroups.length > 0) {
      ee.emitSgAlreadyExistsModal(
        surveyGroups.length === 1
          ? () => {
              window.location.replace(
                `${getFrontendConfigValue('SELF_CARE_URL')}/editSurveyGroup/${surveyGroups[0].id}`,
              );
            }
          : () => window.location.replace(`${getFrontendConfigValue('SELF_CARE_URL')}/overview`),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(userData)]);

  const [createSurveyGroupWithRespondents] = useCreateSurveyGroupWithRespondentsMutation({
    refetchQueries: [
      getOperationName(getUserQuery) || '',
      {
        query: getOrganizationStructure,
        variables: { organizationId: userData?.user.organization?.id },
      },
      getSurveyGroups,
    ],
    awaitRefetchQueries: true,
  });

  const createSubGroup = async (
    respondentIds: string[],
    addedOn: Date,
    runOngoingSteps: string[],
    newRecipientEmails?: string[],
  ) => {
    const result = await createSurveyGroupWithRespondents({
      variables: {
        input: {
          organizationId: userData?.user.organization?.id!,
          respondentIds,
          runOngoingSteps,
          newRecipientEmails,
          topicGroupId: params.subjectId,
          startDate: addedOn,
        },
      },
    });
    if (result.data) {
      addToast(t('surveyGroupCreated'));
      history.push(`/editSurveyGroup/${result.data.createSurveyGroupWithRespondents.id}`);
      return true;
    }
    addToast(t('surveyGroupCreationFailed'));
    return false;
  };

  const noOp = async () => {
    // Do nothing
  };

  if (userError || error) {
    return <ErrorPage>{t('common:loadError')}</ErrorPage>;
  }
  if (userLoading || !userData || loading) {
    return <Loading />;
  }
  const topicGroup = data?.topicGroup;

  if (!topicGroup) {
    return null;
  }
  return (
    <SurveyGroup
      description={topicGroup.description}
      topicGroup={topicGroup}
      headings={
        (t('planSurveyGroup', {
          subjectLabel: topicGroup.name,
        }) as string) || ''
      }
      breadcrumb={[
        { title: t('ReportAccess:backToOverview'), link: '/overview' },
        {
          title: t('planSurveyGroup', {
            subjectLabel: topicGroup.name,
          }),
          active: true,
        },
      ]}
      organization={userData.user.organization}
      createSubGroup={createSubGroup}
      removeRespondent={noOp}
      changeAddedOn={noOp}
      addRespondents={noOp}
      user={userData.user}
    />
  );
};
