import {
  createEventData,
  HeadingLarge,
  pushToDataLayer,
  pushToDataLayerRespondentCreateSuccess,
  theme,
  useToast,
} from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import { GraphQLErrorCard, Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import { getOrganizationStructure, getOrganizationTeams, getSurveyGroup, getSurveyGroups } from '../../graphql/queries';
import RespondentForm from '../../components/Forms/RespondentForm/RespondentForm';
import {
  CreateRespondentMutation,
  IsAccessTokenValidQuery,
  OrganizationQuery,
  ProcessStatus,
  SurveyStatus,
  TopicGroupTypeCode,
  useCreateRespondentMutation,
  useOrganizationSettingQuery,
  useSurveysQuery,
} from '../../generated/hooks';
import { HeadingSubtext } from '../../components/StyledComponents';
import i18n from '../../translations/i18n';
import { useGetOrganizationTeams, useGetSurveyGroups } from '../Surveys/utils';

interface IProps extends RouteComponentProps<any> {
  user: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
  organization: OrganizationQuery['organization'];
}

const AddRespondentScreen = ({ user, organization, history }: IProps) => {
  const { t } = useTranslation(['addRespondent']);
  const { addToast } = useToast();
  const id = organization && organization.id;
  const {
    data: organizationSettings,
    loading: organizationSettingsLoading,
    error: organizationSettingsError,
  } = useOrganizationSettingQuery({
    variables: { organizationId: id || '' },
  });

  const {
    data: surveysData,
    loading: surveysLoading,
    error: surveysError,
  } = useSurveysQuery({
    variables: {
      languageCode: i18n.language,
    },
  });

  const { organizationTeams, loading: teamsLoading, error: teamsError } = useGetOrganizationTeams(id || '');

  const { surveyGroups, loading: sgLoading, error: sgError } = useGetSurveyGroups();

  const handleMutationCompleted = (data: CreateRespondentMutation) => {
    addToast(
      t('createSuccess', {
        respondentName: `${data.createRespondent.firstname} ${data.createRespondent.surname}`,
      }),
    );
    pushToDataLayerRespondentCreateSuccess(user);

    history.push({
      pathname: '/teams',
    });
  };

  const handleMutationError = () => {
    pushToDataLayer({
      userId: user?.id,
      orgId: organization?.id,
      event: 'ux.respondent-create-error',
      ...createEventData('organization', 'createRespondentError', 'organization create respondent error'),
    });
  };

  const [createRespondentMutation, { loading, error }] = useCreateRespondentMutation({
    onCompleted: handleMutationCompleted,
    onError: handleMutationError,
    refetchQueries: [
      {
        query: getOrganizationStructure,
        variables: {
          organizationId: id,
        },
      },
      getSurveyGroup,
      getSurveyGroups,
      {
        query: getOrganizationTeams,
        variables: {
          organizationId: id,
        },
      },
    ],
  });

  if (id === null || organizationSettingsError || surveysError || teamsError || sgError) {
    return <GraphQLErrorCard />;
  }
  if (
    !organization ||
    organizationSettingsLoading ||
    !organizationSettings ||
    surveysLoading ||
    !surveysData ||
    teamsLoading ||
    !organizationTeams ||
    sgLoading ||
    !surveyGroups
  ) {
    return <Loading />;
  }

  return (
    <PageWrapper data-cy="admin-page-content">
      <p className="mt-n6 font-size-xs">
        <Link to={'/teams'}>&lt; {t('backToEmployees')}</Link>
      </p>
      <HeadingLarge>{t('addEmployee')}</HeadingLarge>
      <HeadingSubtext margin={`0 0 ${theme.spacing.f} 0`}>{t('addEmployeeDescription')}</HeadingSubtext>
      {loading && <Loading />}
      {error && <GraphQLErrorCard />}
      {!loading && !error && (
        <RespondentForm
          organization={organization}
          organizationTeams={organizationTeams || []}
          saveRespondent={createRespondentMutation}
          countryCallingCode={organizationSettings.organization?.config?.countryCallingCode}
          onboardings={surveyGroups!.filter(
            (sg) =>
              sg.topicGroup.typeCode === TopicGroupTypeCode.Onboarding &&
              [ProcessStatus.Completed, ProcessStatus.Ongoing, ProcessStatus.Prepared].includes(sg.status),
          )}
          processSurveys={surveyGroups!.filter(
            (sg) =>
              sg.topicGroup.typeCode !== TopicGroupTypeCode.Onboarding &&
              [ProcessStatus.Completed, ProcessStatus.Ongoing, ProcessStatus.Prepared].includes(sg.status),
          )}
          surveys={
            surveysData?.user.organization?.surveys
              .filter(
                (survey) => [SurveyStatus.Ongoing, SurveyStatus.Prepared].includes(survey.status) && !survey.public,
              )
              .sort((a, b) => (a.sendAt > b.sendAt ? 1 : -1)) || []
          }
        />
      )}
    </PageWrapper>
  );
};

export default AddRespondentScreen;
