import Select, { MenuProps, PlaceholderProps, Props, components } from 'react-select';
import { theme } from '../../theme';

export type SelectPlaceholderProps = PlaceholderProps;
export type SelectMenuProps = MenuProps;

export const selectMenuStyles = {
  border: 'none',
  radius: '6px',
  color: theme.colors.actionPrimary.default,
  fontWeight: theme.typography.body.medium.regular.fontWeight,
  fontSize: theme.typography.body.medium.regular.fontSize,
  marginRight: theme.spacing.f,
  boxShadow: theme.shadows.elevationContentDialog,
  width: '100%',
  position: 'absolute' as any,
  backgroundColor: theme.colors.backgroundBasic.active,
};

export const selectControlStyles = (provided: any, state: any) => ({
  ...provided,
  paddingLeft: theme.spacing.f,
  borderColor: state.isFocused ? `${theme.colors.borderMain.active} !important` : theme.colors.borderMain.default,
  boxShadow: 'none',
});

export const selectOptionStyles = (provided: any, state: any) => ({
  ...provided,
  paddingLeft: theme.spacing.f,
  backgroundColor: state.isFocused ? theme.colors.backgroundBasic.hover : theme.colors.backgroundCover.default,
  color: state.isSelected
    ? theme.colors.actionPrimary.default
    : state.isDisabled
      ? theme.colors.text.disabled
      : theme.colors.text.primary,
});

export const selectDropdownIndicatorStyles = (provided: any, state: any) => ({
  color: theme.colors.actionPrimary.default,
  marginRight: theme.spacing.f,
  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '0',
});

export const customStyles = {
  control: selectControlStyles,
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: selectDropdownIndicatorStyles,
  menu: (provided: any, state: any) => selectMenuStyles,
  option: selectOptionStyles,
  valueContainer: (styles: any) => ({
    ...styles,
    paddingLeft: 0,
  }),
};

const CustomInput = (props) => {
  return <components.Input {...props} data-cy={props.selectProps['data-cy']} />;
};

const CustomOption = (props) => {
  return (
    <components.Option
      {...props}
      innerProps={{ ...props.innerProps, 'data-cy': `${props.selectProps['data-cy']}-option-${props.value}` }}
    />
  );
};

type ExtendedProps = {
  dataCy?: string;
} & Props;

export const SelectWrapper = (props: ExtendedProps) => {
  return (
    <Select
      {...{ ...props, styles: { ...customStyles, ...props.styles } }}
      components={{ Input: CustomInput, Option: CustomOption, ...(props.components || {}) }}
      data-cy={props.dataCy}
    />
  );
};
