import { ActionMenu, ActionMenuIcon, BodyMedium, Box, HeadingSmall, cleanArray, theme, useToast } from '@arnold/common';
import styled from '@emotion/styled';
import { useEffect, useMemo, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import { Loading } from '../../../components';
import { Card } from '../../../components/Common';
import { ButtonLink } from '../../../components/StyledComponents';
import {
  Features,
  OrganizationQuery,
  TopicGroupQuery,
  useDeleteProcessStepMutation,
  useUpdateProcessStepMutation,
} from '../../../generated/hooks';
import { getTopicGroup } from '../../../graphql/queries';
import { getTopicGroupTranslationByLanguageId } from '../../../lib/common';
import ee from '../../../lib/eventEmitter';
import { getTimeStringForOffset, prepareStepOffsets } from '../../../lib/process';
import { TopicGroupItemEditableContent } from './TopicGroupItemEditableContent';
import { TopicGroupItemStaticContent } from './TopicGroupItemStaticContent';

type Props = {
  step: NonNullable<TopicGroupQuery['topicGroup']['steps']>[0];
  organization: NonNullable<OrganizationQuery['organization']>;
  topicId?: string;
  topicGroupId: string;
  isEditable: boolean;
};

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const TopicGroupStep = ({ step, organization, topicId, topicGroupId, isEditable }: Props) => {
  const query = useQuery();
  const location = useLocation();
  const isEditingInUrl = query.get('editing');
  const [isEditing, setIsEditing] = useState(isEditingInUrl === step.stepTopicGroup.lastValidTopic?.id);
  const { t } = useTranslation('processDetail');
  const languageId = organization.primaryLanguageId.toString(10);
  const translation = getTopicGroupTranslationByLanguageId(languageId, step.stepTopicGroup.translations);
  const [saveProcessStep, { loading }] = useUpdateProcessStepMutation();
  const [deleteProcessStep] = useDeleteProcessStepMutation();
  const { addToast } = useToast();
  const history = useHistory();
  const offsets = prepareStepOffsets({
    startOffsetMins: step.startOffsetMins,
    endOffsetMins: step.endOffsetMins,
    remindersOffsetMins: step.reminders.map((reminder) => reminder.reminderOffsetMins),
  });

  useEffect(() => {
    if (isEditingInUrl) {
      document.getElementById(`topic-${step.stepTopicGroup.lastValidTopic?.id}`)!.scrollIntoView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditingInUrl]);

  const handleSave = (offsets: { startOffsetMins: number; remindersOffsetMins: number[]; endOffsetMins: number }) => {
    saveProcessStep({
      variables: {
        id: step.id,
        input: {
          startOffsetMins: offsets.startOffsetMins,
          remindersOffsetMins: offsets.remindersOffsetMins,
          endOffsetMins: offsets.endOffsetMins,
        },
      },
      onCompleted: () => {
        addToast(t('topicGroupStepCreated'));
        setIsEditing(false);
      },
      refetchQueries: [
        {
          query: getTopicGroup,
          variables: { topicGroupId },
        },
      ],
      awaitRefetchQueries: true,
    });
  };

  const openDeleteModal = () => {
    const startOffsetDays =
      offsets.startOffset?.days === undefined
        ? undefined
        : offsets.startOffset.days + (offsets.startOffset.days >= 0 ? 1 : 0);
    const startOffsetMins = getTimeStringForOffset(offsets.startOffset);
    ee.emitDeleteProcessStepModal(
      handleDelete,
      <BodyMedium margin={`0 0 ${theme.spacing.h} 0`}>
        {[translation?.value, t('processDetail:simpleDay', { dayNo: startOffsetDays }), startOffsetMins]
          .filter(Boolean)
          .join(', ')}
      </BodyMedium>,
    );
  };

  const handleDelete = () => {
    deleteProcessStep({
      variables: {
        id: step.id,
      },
      onCompleted: () => {
        addToast(t('topicGroupStepDeleted'));
      },
      refetchQueries: [{ query: getTopicGroup, variables: { topicGroupId } }],
    });
  };

  const actionMenuItems = cleanArray([
    {
      label: t('topicDetail'),
      action: () => history.push(`/topic/${topicId}`),
    },
    isEditable && {
      label: t('remove'),
      action: openDeleteModal,
    },
  ]);

  // Insert edit topic menu item into second place if the org has the feature
  organization.featureNames?.includes(Features.CreateAndEditTopics) &&
    isEditable &&
    actionMenuItems.splice(1, 0, {
      label: t('editTopic'),
      action: () => history.push(`/topicEditor/0?topicId=${topicId}`),
    });

  return (
    <Container>
      {loading && <Loading />}
      <Card id={`topic-${step.stepTopicGroup.lastValidTopic?.id || 'unknown'}`}>
        <Header>
          <div style={{ maxWidth: '70%' }}>
            <HeadingSmall data-cy="topicGroupStep-title">{translation?.value}</HeadingSmall>
            <Description data-cy="topicGroupStep-description">{translation?.description || ''}</Description>
          </div>
          {!isEditing && (
            <ActionButtonsContainer>
              {isEditable && (
                <ButtonLink onClick={() => setIsEditing(true)} data-cy="topicGroupStep-editDate">
                  {t('editDataAndDate')}
                </ButtonLink>
              )}
              {!isEditable ? (
                <ButtonLink onClick={() => history.push(`/topic/${topicId}}`)}>{t('topicDetail')}</ButtonLink>
              ) : (
                <DropdownContainer>
                  <ActionMenu
                    dataIcom="btn-reports-action-menu"
                    align="right"
                    toggleIcon={<ActionMenuIcon />}
                    items={actionMenuItems}
                  />
                </DropdownContainer>
              )}
            </ActionButtonsContainer>
          )}
        </Header>
      </Card>
      {isEditing ? (
        <TopicGroupItemEditableContent
          step={step}
          onSave={handleSave}
          onCancel={() => {
            setIsEditing(false);
            query.delete('editing');
            history.push({
              pathname: location.pathname,
              search: query.toString(),
            });
          }}
          loading={loading}
        />
      ) : (
        <TopicGroupItemStaticContent step={step} />
      )}
    </Container>
  );
};

const Container = styled(Box)`
  margin-bottom: ${theme.spacing.f};
  padding: 0;
  background-color: transparent;
`;

const ActionButtonsContainer = styled(Row)`
  align-items: center;
  gap: ${theme.spacing.g};
`;

const Header = styled(Row)`
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-inline: ${theme.spacing.c};
  align-items: center;
`;

const Description = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const DropdownContainer = styled.span`
  display: inline-block;
  margin: 5px 0;
`;
