import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { OrganizationQuery, useTopicGroupDetailQuery } from '../../generated/hooks';
import { Loading } from '../../components';
import { ErrorPage } from '../../components/StyledComponents';
import { TopicEditor } from '../../components/TopicEditor';
import { getTGAccessHeaderContext } from '../../components/TopicEditor/utils';

interface IParams {
  lang?: string;
  topicGroupId: string;
}

interface Props extends RouteComponentProps<IParams> {
  organization: OrganizationQuery['organization'];
}

const PublicTopicEditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  .arielFullscreen {
    /* Public editor (share feature) does not have a sidebar */
    left: 0px;
  }
`;

export const SvenEditorPublicScreen = ({ match, organization, location: { search, pathname } }: Props) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const { topicGroupId, lang } = match.params;
  const { loading, data, error } = useTopicGroupDetailQuery({
    variables: {
      topicGroupId,
      languageCode: lang,
    },
    context: getTGAccessHeaderContext(search),
  });

  const handleChangeLanguage = (lang: string) => {
    const pathNameArr = pathname.split('/');
    pathNameArr[pathNameArr.length - 1] = lang;
    history.push({
      pathname: pathNameArr.join('/'),
      search,
    });
  };

  const parsedSearch = new URLSearchParams(search);
  const accessKey = parsedSearch.get('accessKey');

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.topicGroup.lastValidTopic) {
    return <ErrorPage>{t('loadError')}</ErrorPage>;
  }

  const limitedMode = data.topicGroup.accessKeys?.find((ak) => ak.accessKey === accessKey)?.level as 'READ' | 'WRITE';
  return (
    <PublicTopicEditorWrapper className="publicTopicEditorWrapper">
      <TopicEditor
        limitedMode={limitedMode}
        preview={limitedMode === 'READ'}
        languageCodeOrId={lang}
        topicDetail={data.topicGroup.lastValidTopic}
        topicGroup={data.topicGroup}
        organization={organization}
        onLanguageChange={handleChangeLanguage}
      />
    </PublicTopicEditorWrapper>
  );
};
