import styled from '@emotion/styled';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { theme } from '../../theme';
import { getFlagByCode } from '../../helpers/languages';
import { CleanButton } from '../buttons';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';

type LanguageSelectorProps = {
  onChange?: (lang: string) => void;
  onDelete?: (lang: string) => void;
  lang?: string;
  allowedLanguages?: readonly string[];
  isAlignedToLeft?: boolean;
  displayAlways?: boolean;
  margin?: string;
  maxWidth?: number;
  showBorder?: boolean;
  showAddLanguageButton?: boolean;
  // Selected language normally switches to whatever user clicks
  // but sometimes we want to prevent it and display selected language from props
  propsOverrideSelectedLanguage?: boolean;
  // show delete button next to the language in picker
  showDeleteOnLanguages?: string[];
};

type WrapperProps = {
  isAlignedToLeft?: boolean;
  margin?: string;
  maxWidth?: number;
  showAddLanguageButton?: boolean;
  showBorder?: boolean;
};

const WrapperWithBorder = styled.div<WrapperProps>`
  .reactSelect__control {
    border: 1px solid #e3e3e3;
  }
  .reactSelect__control--is-focused {
    border-color: #0390bd;
    box-shadow: 0 0 0 0.2rem rgba(4, 183, 239, 0.25);
    &:hover {
      border-color: #0390bd;
    }
  }

  .reactSelect__indicator-separator {
    display: none;
  }

  .reactSelect__indicators {
    svg {
      width: 30px;
      height: 30px;
    }
  }
`;

const Wrapper = styled.div<WrapperProps>`
  max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : 56)}px;
  margin: ${({ margin }) => (margin ? margin : 0)};

  .reactSelect__indicators {
    width: 23px !important;
    padding: 0 !important;
  }

  .reactSelect__menu {
    min-width: fit-content;
    padding: ${theme.spacing.e} 0;
    right: ${({ isAlignedToLeft }) => (isAlignedToLeft ? 'inherit' : 0)};
    &:after {
      display: none;
    }
    &:before {
      display: none;
    }
  }

  .reactSelect__menu-list {
    width: 100%;
    & > div:last-child img {
      filter: ${({ showAddLanguageButton }) =>
        showAddLanguageButton ? 'invert(0.5) sepia(1) saturate(5) hue-rotate(175deg)' : ''};
    }
    .deleteButton {
      display: initial;
      margin-left: auto;
    }
  }

  .reactSelect__value-container {
    padding: ${theme.spacing.c};
  }

  .reactSelect__control {
    border: none;
    min-height: 30px;
    border-radius: 6px;
    background-color: transparent;
    &:hover {
      border: none;
      cursor: pointer;
      background-color: ${theme.colors.backgroundBasic.hover};
    }
    &:focused {
      border: none !important;
    }
  }
  .reactSelect__control--is-focused {
    border: none !important;
    box-shadow: none !important;
    &:hover {
      border: none !important;
    }
  }
  .reactSelect__option {
    padding: ${theme.spacing.c} ${theme.spacing.h} ${theme.spacing.c} ${theme.spacing.f};
    margin: ${theme.spacing.c} 0;
    cursor: pointer;
  }
  .reactSelect__option--is-selected {
    background-color: transparent !important;
    color: ${theme.colors.text.disabled} !important;
  }
  .deleteButton {
    display: none;
  }
`;

const Flag = styled.img`
  max-width: 22px;
  max-height: 22px;
  object-fit: contain;
`;

const ValueWrapper = styled.div`
  display: flex;
  alignitems: center;
`;

const LanguageName = styled.div`
  margin-left: 16px;
  margin-right: 16px;
`;

// TODO find better way to import svg - without react scripts it is not possible to import svg as react component
function DropdownIndicator() {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01561 10.1336C7.84492 9.96294 7.56818 9.96294 7.39749 10.1336C7.2268 10.3043 7.2268 10.5811 7.39749 10.7518L10.74 14.0943C10.7833 14.1375 10.8334 14.1699 10.8869 14.1912C11.0439 14.2556 11.231 14.224 11.3585 14.0965L14.702 10.7531C14.8714 10.5836 14.8714 10.3089 14.702 10.1394C14.5325 9.96997 14.2578 9.96997 14.0883 10.1394L11.0549 13.1729L8.01561 10.1336Z"
        fill="#04B7EF"
      />
    </svg>
  );
}

export const LanguageSelector: FC<LanguageSelectorProps> = ({
  lang,
  allowedLanguages,
  onChange,
  onDelete,
  isAlignedToLeft,
  displayAlways,
  margin,
  maxWidth,
  showAddLanguageButton,
  propsOverrideSelectedLanguage,
  showDeleteOnLanguages,
  showBorder,
}) => {
  const [t, i18n] = useTranslation('languageSelector');
  const [language, setLanguage] = useState(lang || i18n.language);

  useEffect(() => {
    if (lang) {
      setLanguage(lang);
    }
  }, [lang]);

  const minimumLimit = displayAlways ? 0 : 1;
  if (!allowedLanguages || allowedLanguages.length <= minimumLimit) {
    return null;
  }

  const languages = [...allowedLanguages];
  if (showAddLanguageButton) {
    languages.push('addLanguage');
  }
  const values = languages.map((code) => {
    return {
      value: code,
      label: (
        <ValueWrapper>
          <Flag src={getFlagByCode(code)} />
          <LanguageName>{t(code)}</LanguageName>
          {showDeleteOnLanguages?.includes(code) && (
            <CleanButton
              className="deleteButton"
              onClick={(e) => {
                e.stopPropagation();
                onDelete?.(code);
              }}
            >
              <CloseIcon />
            </CleanButton>
          )}
        </ValueWrapper>
      ),
      name: t(code),
    };
  });
  const handleLanguageChange = (choice: any) => {
    if (onChange) {
      onChange(choice.value);
    }
    if (!propsOverrideSelectedLanguage && choice.value !== 'addLanguage') setLanguage(choice.value);
  };

  const CorrectWrapper = ({ children, ...wrapperProps }: { children: React.ReactNode } & WrapperProps) => {
    return showBorder ? (
      <WrapperWithBorder {...wrapperProps}>{children}</WrapperWithBorder>
    ) : (
      <Wrapper {...wrapperProps}>{children}</Wrapper>
    );
  };

  return (
    <CorrectWrapper
      isAlignedToLeft={isAlignedToLeft}
      margin={margin}
      showBorder={showBorder}
      data-icom="language-selector"
      maxWidth={maxWidth}
      showAddLanguageButton={showAddLanguageButton}
    >
      <Select
        className="reactSelect primary"
        classNamePrefix="reactSelect"
        value={values.find((value) => value.value === language)}
        onChange={handleLanguageChange}
        options={values}
        components={{ DropdownIndicator }}
        isSearchable={false}
      />
    </CorrectWrapper>
  );
};
