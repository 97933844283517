import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { Button } from 'react-bootstrap';
import { EditTopicGroupInfoModal } from '../TopicEditor/components/EditTopicGroupInfoModal';

type Props = {
  onSave: (values: { name: string; description: string; language?: string }) => void;
  name: string;
  description: string;
  language: string;
  languages: string[];
  type: 'onboarding' | 'processSurvey';
  modalTitle?: string;
};

export const EditTopicGroupInfoButton = ({
  onSave,
  description,
  name,
  type,
  language,
  languages,
  modalTitle,
}: Props) => {
  const [isEditingModalOpen, setIsEditingModalOpen] = useState(false);

  const { t } = useTranslation(['common']);
  return (
    <>
      <StyledButton variant="outline-primary" onClick={() => setIsEditingModalOpen(true)}>
        {t('editInfo')}
      </StyledButton>
      {isEditingModalOpen && (
        <EditTopicGroupInfoModal
          isModalOpen
          setModalOpen={setIsEditingModalOpen}
          title={modalTitle}
          name={name}
          languages={languages}
          language={language}
          description={description}
          onSave={onSave}
          type={type}
        />
      )}
    </>
  );
};

const StyledButton = styled(Button)`
  :hover {
    svg {
      color: white;
    }
  }
`;
