import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@arnold/common';

type OrgAdminAlreadyExistsModalProps = {
  handleSubmit: () => void;
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
};

export const OrgAdminAlreadyExistsModal: FC<OrgAdminAlreadyExistsModalProps> = ({
  isModalOpen,
  handleSubmit,
  setModalOpen,
}) => {
  const { t } = useTranslation('salesForm');

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setModalOpen(false)}
      title={t('orgAdminAlreadyExists.title')}
      text={t('orgAdminAlreadyExists.text')}
      onSubmit={handleSubmit}
      buttons={{
        cancel: { title: t('orgAdminAlreadyExists.cancel') },
        submit: { title: t('orgAdminAlreadyExists.create') },
      }}
    />
  );
};
