import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { HeadingSmall } from '@arnold/common';
import BaseModal, { WIDTH } from '../BaseModal';
import { SurveysQuery } from '../../../generated/hooks';
import { Table } from '../../Table';
import { BaseRespondent, RespondentOneTimeSurveys } from '../../OrganizationImportCard';
import { OneTimeSurveyData, Step } from './types';
import { getOneTimeSurveyColumns, getOneTimeSurveyDataSource } from './utils';
import { Progress } from './Progress';
import { Line, TableContainer } from './styled';
import { AddedEmployees } from './AddedEmployees';

interface IProps {
  cancel: () => void;
  surveys: NonNullable<SurveysQuery['user']['organization']>['surveys'];
  employees: BaseRespondent[];
  onChange: (data: RespondentOneTimeSurveys) => void;
  save: () => void;
  steps: Step[];
}

function ChooseOneTimeSurveyModal({ cancel, surveys, employees, onChange, save, steps }: IProps) {
  const [t] = useTranslation('modal');
  const [importOrganizationT] = useTranslation('organizationImportCard');
  const [surveySettings, setSurveySettings] = useState<RespondentOneTimeSurveys>({});

  const handeDataChange = (settings: RespondentOneTimeSurveys) => {
    onChange(settings);
    setSurveySettings(settings);
  };

  useEffect(() => {
    handeDataChange(surveys.reduce((object, survey) => ({ ...object, [survey.id]: [] }), {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggle = (email: string, id: string, checked: boolean) => {
    const idData = surveySettings[id] || [];
    if (checked) {
      const processSurveyEmployees = [...idData, email];
      handeDataChange({ ...surveySettings, [id]: processSurveyEmployees });
    } else {
      const processSurveyEmployees = idData.filter((employee) => employee !== email);
      handeDataChange({ ...surveySettings, [id]: processSurveyEmployees });
    }
  };

  return (
    <BaseModal
      title={
        <>
          {importOrganizationT('addToOneTimeSurveyTitle')} <Progress steps={steps} step={'one-time-survey'} />
        </>
      }
      description={importOrganizationT('addToOneTimeSurveyDescription')}
      primaryActionText={
        steps.indexOf('one-time-survey') === steps.length - 1 ? t('save') : importOrganizationT('continueButton')
      }
      onPrimaryAction={save}
      secondaryActionText={t('cancel')}
      onSecondaryAction={cancel}
      width={WIDTH.AUTO}
      styles={{
        maxWidth: '100%',
        overflow: 'auto',
      }}
    >
      <AddedEmployees count={employees.length} />
      <Line />
      <HeadingSmall>{importOrganizationT('selectSurvey')}</HeadingSmall>
      <TableContainer>
        <Table<OneTimeSurveyData>
          columns={getOneTimeSurveyColumns(importOrganizationT, surveys, handeDataChange, surveySettings, employees)}
          dataSources={getOneTimeSurveyDataSource(surveys, employees, surveySettings, handleToggle)}
          selected={undefined}
        />
      </TableContainer>
    </BaseModal>
  );
}
export default ChooseOneTimeSurveyModal;
