import { getFrontendConfigValue } from '@arnold/common';
import { ENVIRONMENT } from '@arnold/core';
import { clone } from 'ramda';
import { useEffect } from 'react';
import { TFunction } from 'react-i18next';
import {
  MutationImportTopicArgs,
  QuestionDefinitionInput,
  QuestionDefinitionTranslationInput,
  QuestionType,
  QuestionType as QuestionTypeFromHooks,
  RuleOperator,
  TopicDetailQuery,
  TopicGroupDetailQuery,
} from '../../generated/hooks';
import { parseRange } from '../../screens/Topic/helpers';
import { trimRuleOrigValue } from '../../screens/Topic/topicStructure';
import { QUESTION_TYPE_FROM_HOOKS_MAPPING } from '../../screens/Topic/types';
import { COMMENT_TYPE } from './constants';
import { OptionControlData } from './controls/OptionControl';
import { CustomConnection, DataNode, DataNodeWithDimensions, NodeData, OutputKey } from './dataNode';
import { CustomNodeEditor, processData, updatePositionInNodeData } from './rete';

type topicDetailQuestions = NonNullable<TopicGroupDetailQuery['topicGroup']['lastValidTopic']>['allQuestions'];
type topicDetailComments = NonNullable<TopicGroupDetailQuery['topicGroup']['lastValidTopic']>['commentGroups'];

export const getOutputs = (question: topicDetailQuestions[0]) => {
  const outputs: { [key: string]: number | null } = {};

  if (question.rules.length === 1 && !['RangeRule', 'ArrayRule'].includes(question.rules[0].__typename ?? '')) {
    return { 'opt-0': question.rules[0].nextQuestion.index ?? null };
  }

  if (
    question.rules.length === 0 &&
    !['EndingQuestionDefinition', 'SelectQuestionDefinition'].includes(question.__typename as string)
  ) {
    // if there are no rules (not connected to anything) and it is not the ending quesion
    // it should to have an output
    return { 'opt-0': null };
  }

  const translation = question.translations.find((translation) => translation.isDefault);
  const countOfChoices = translation?.choices?.length || 0;
  for (let index = 0; index < countOfChoices; index++) {
    // create outputs for all choices - it is not enough to do it for each rule (in the next step)
    // coz outputs without connection do not have rules
    const outputKey = `opt-${index}`;
    outputs[outputKey] = null;
  }

  [...question.rules]
    .sort((q1, q2) => q2.order - q1.order)
    .forEach((rule) => {
      const trimmedOrigValue = trimRuleOrigValue(rule.origValue);
      if (trimmedOrigValue === '') {
        const countOfChoices = translation?.choices?.length || 1;
        for (let index = 0; index < countOfChoices; index++) {
          const outputKey = `opt-${index}`; // option number is paired onto translation.choice option number
          outputs[outputKey] = rule.nextQuestion.index ?? null;
        }
      } else {
        const outputKey = `opt-${Number.parseInt(trimmedOrigValue, 10)}`;
        outputs[outputKey] = rule.nextQuestion.index ?? null;
      }
    });

  return outputs;
};

export const getAnswersToHighlight = (question: topicDetailQuestions[0]) => {
  const answersToHighlight: { [key: OutputKey]: number } = {};
  const translation = question.translations.find((translation) => translation.isDefault);
  const countOfChoices = translation?.choices?.length || 0;
  for (let choiceIndex = 0; choiceIndex < countOfChoices; choiceIndex++) {
    const outputKey: OutputKey = `opt-${choiceIndex}`;
    answersToHighlight[outputKey] = (question.answersToHighlight || []).filter(
      (index) => index === choiceIndex.toString(),
    ).length;
  }

  return answersToHighlight;
};

export const topicDetailToNodes = (
  allQuestions: topicDetailQuestions,
  languageCode: string,
  startingQuestionId: string,
  commentGroups: topicDetailComments,
  topicId: string,
  search?: string,
): NodeData[] => {
  const questionNodes = allQuestions.map((question) => {
    const allTranslations = question.translations;
    const primaryTranslation = question.translations.find((translation) => translation.isDefault);
    const translation = question.translations.find((translation) => translation.language.code === languageCode);
    const choices =
      primaryTranslation?.choices && primaryTranslation.choices.length > 0
        ? primaryTranslation.choices.reduce((acc, choice, index) => {
            const translatedChoice = translation?.choices?.[index] || '';
            return { ...acc, [`opt-${index}`]: translatedChoice };
          }, {})
        : {};

    let questionType = QUESTION_TYPE_FROM_HOOKS_MAPPING[question.__typename!];
    const range = parseRange(question.selectedRange);
    if (questionType === QuestionTypeFromHooks.Select && !(range.max === 1 && range.min === 1)) {
      questionType = QuestionTypeFromHooks.Multiselect;
    }
    const outputs = getOutputs(question);
    return {
      questionIndex: question.index ?? generateIndex(),
      questionText: translation?.text || '',
      isRequired: !question.optional,
      isMetric: question.isMetric,
      isQuestionTrigger: question.isQuestionTrigger,
      choices,
      type: questionType,
      range,
      answersToHighlight: getAnswersToHighlight(question),
      allTranslations,
      input: startingQuestionId !== question.id,
      outputs,
      reportOrder: question.reportOrder ?? null,
      position: [question.positionX ?? null, question.positionY ?? null],
      originalQuestionDefinition: question,
      topicId,
    };
  });

  const commentNodes =
    commentGroups?.map((commentGroup) => {
      return {
        type: COMMENT_TYPE as QuestionType | typeof COMMENT_TYPE,
        questionIndex: Number.parseInt(commentGroup.id, 10),
        questionText: '',
        isRequired: false,
        isMetric: false,
        isQuestionTrigger: false,
        choices: { 'opt-0': '' },
        input: false,
        outputs: { 'opt-0': null },
        reportOrder: null,
        position: [commentGroup.positionX ?? null, commentGroup.positionY ?? null],
        comments: commentGroup.comments || undefined,
        topicId,
        search,
        commentGroupId: commentGroup.id,
      };
    }) || [];

  return [...questionNodes, ...commentNodes];
};

/**
 * This will transform the NodeData to be used in the save mutation
 */
export const transformNodesForMutation = (
  editor: CustomNodeEditor,
  topicName: string,
  topicGroupId: string,
  languageCode: string,
  languageId: string,
  defaultTopicLanguageCode: string,
  isTesting: boolean,
  topicGroupDescription?: string,
  isValid?: boolean,
  savingNewLanguage = false,
  topicId?: string,
): MutationImportTopicArgs => {
  const isDefaultLanguage = defaultTopicLanguageCode === languageCode;
  const nodes = editor.getNodes();
  const connections = editor.getConnections();
  updateNodeDataBasedOnConnections(nodes, connections);
  // put the starting question (node without input) as the first question (needed for correct import of startingQuestion in some old topics)
  nodes.sort((a, b) => (a.data.input === false ? -1 : b.data.input === false ? 1 : 0));
  const transformedQuestions = nodes
    .filter((node) => node.data.type !== COMMENT_TYPE)
    .map((node, i): QuestionDefinitionInput => {
      const nodePosition = editor.areaPlugin.nodeViews.get(node.id)?.position;
      const type = node.data.type;
      // Order of choices is controlled by index in option control
      const controlKeys = Object.values(node.controls)
        .filter((c) => c instanceof OptionControlData)
        .sort((a, b) => (a.index ?? 0) - (b.index ?? 0))
        .map((x) => (x as OptionControlData).key) as OutputKey[];

      const outputKeys: OutputKey[] = controlKeys.length
        ? controlKeys
        : node.data.outputs
          ? (Object.keys(node.data.outputs) as OutputKey[])
          : [];
      const choices = controlKeys.map((key) => node.data.choices[key] || '');

      const rules = outputKeys
        .map((outputKey) => {
          const nextQuestionIndex = node.data.outputs?.[outputKey];
          const indexOfOption = controlKeys.findIndex((key) => key === outputKey);
          const optionNo =
            type !== COMMENT_TYPE && [QuestionType.Select, QuestionType.Multiselect].includes(type)
              ? indexOfOption !== -1
                ? indexOfOption.toString()
                : outputKey.replace('opt-', '') // remove the opt- prefix
              : '';
          return {
            nextQuestionIndex: nextQuestionIndex !== null ? nextQuestionIndex?.toString() : null, // needs to be string
            rule: QuestionType.Multiselect === type ? `[${optionNo}]` : optionNo,
            ruleOperator: QuestionType.Multiselect === type ? RuleOperator.Inany : null,
          };
        })
        .filter((rule) => rule.nextQuestionIndex)
        .reverse();

      const answersToHighlight = controlKeys
        .map((key, index) =>
          Array.from(Array(node.data.answersToHighlight?.[key] || 0).keys()).map(() => index.toString()),
        )
        .flat();

      return {
        index: node.data.questionIndex,
        positionX: nodePosition?.x ? Math.floor(nodePosition.x) : null,
        positionY: nodePosition?.y ? Math.floor(nodePosition.y) : null,
        // @ts-ignore
        type,
        selectedRange: node.data.range,
        rules,
        translations: [
          ...(node.data.originalQuestionDefinition?.translations.map((trans) => ({
            isDefault: trans.isDefault,
            languageId: trans.language.id,
            text: trans.language.code === languageCode ? node.data.questionText || '' : trans.text,
            choices: trans.language.code === languageCode ? choices : trans.choices,
          })) || []),
          savingNewLanguage && {
            isDefault: false,
            languageId,
            text: node.data.originalQuestionDefinition?.translations.find((trans) => trans.isDefault)!.text || '',
            choices: node.data.originalQuestionDefinition?.translations.find((trans) => trans.isDefault)!.choices,
          },
          !savingNewLanguage &&
            !node.data.originalQuestionDefinition?.translations.some(
              (trans) => trans.language.code === languageCode,
            ) && {
              isDefault: isDefaultLanguage,
              text: node.data.questionText || '',
              languageId,
              choices,
            },
        ].filter((item) => item) as QuestionDefinitionTranslationInput[],
        answersToHighlight,
        optional: type === QuestionType.Freetext && !node.data.isRequired,
        isFirstInSectionWithNumber: node.data.originalQuestionDefinition?.isFirstInSectionWithNumber,
        reportIndex: node.data.originalQuestionDefinition?.reportIndex,
        reportOrder: node.data.reportOrder,
      };
    });

  return {
    topic: {
      topicName,
      description: topicGroupDescription,
      languageId,
      language: languageCode,
      topicGroupId,
      isApproved: true,
      isTesting,
      isValid,
      previousTopicId: topicId,
    },
    questions: transformedQuestions,
  };
};

export const clearEditor = (editor: CustomNodeEditor, t: TFunction, lng: string, onDelete: (id: string) => void) => {
  const secondNode: NodeData = {
    type: QuestionType.Ending,
    questionIndex: generateIndex(),
    questionText: t('lastNodeText', {
      lng,
    }),
    isRequired: false,
    choices: {},
    input: true,
    isMetric: false,
    isQuestionTrigger: false,
    outputs: {},
    reportOrder: 2,
    position: [] as any,
  };
  const firstNode: NodeData = {
    type: QuestionType.Tell,
    questionIndex: generateIndex(),
    questionText: t('firstNodeText', {
      lng,
    }),
    isRequired: false,
    choices: {},
    input: false,
    isMetric: false,
    isQuestionTrigger: false,
    outputs: { 'opt-0': secondNode.questionIndex },
    reportOrder: 1,
    position: [] as any,
  };
  const commentNodes = editor.getNodes().filter((node) => node.data.type === COMMENT_TYPE);
  commentNodes.forEach((node) => {
    if (node.data.commentGroupId) {
      onDelete(node.data.commentGroupId);
    }
  });
  processData([firstNode, secondNode], editor, t);
};

/**
 * Returns a promise that will be resolved when stopWaiting function returns true
 * or rejected if the wait time reaches the maximum limit
 * @param stopWaiting function to check if it should resolve
 * @param maxWaitTime maximum time to wait in ms
 * @param checkInterval how often should it ask the stopWaiting function if we can stop waiting in ms
 * @param uniqueName should be used when waitFor is called multiple times where only the last call is valid
 */
export function waitFor(
  stopWaiting: () => boolean,
  maxWaitTime = 10000,
  checkInterval = 100,
  uniqueName?: string,
): Promise<void> {
  if (uniqueName) {
    const interval = waitForIntervals[uniqueName];
    interval && clearInterval(interval.toString());
  }
  return new Promise((resolve, reject) => {
    let timePassed = 0;
    const interval = setInterval(() => {
      timePassed += checkInterval;
      if (stopWaiting()) {
        clearInterval(interval);
        resolve();
      } else if (timePassed >= maxWaitTime) {
        clearInterval(interval);
        reject(new Error(`Waiting for '${stopWaiting}' reached maximum waiting time: ${maxWaitTime}ms`));
      }
    }, checkInterval);
    if (uniqueName) waitForIntervals[uniqueName] = interval;
  });
}

const waitForIntervals: { [key: string]: NodeJS.Timer | undefined } = {};

/**
 * @returns number between 0 and 2147483647 which is the max of int in DB
 */
export function generateIndex(): number {
  return Math.floor(Math.random() * 2147483647);
}
/**
 * based on how the nodes are connected
 */
export function updateNodeDataBasedOnConnections(
  nodes: DataNode[],
  connections: CustomConnection<DataNodeWithDimensions>[],
): void {
  nodes.forEach((node) => {
    node.data.outputs &&
      Object.keys(node.data.outputs).forEach((key) => {
        // remove old connection from node data
        if (node.data.outputs) {
          node.data.outputs[key as OutputKey] = null;
        }
      });
  });
  connections.forEach((con) => {
    const sourceNode = nodes.find((node) => node.id === con.source);
    const targetNode = nodes.find((node) => node.id === con.target);
    if (sourceNode == null)
      throw new Error('Node not find but connection still exists. connection.source=' + con.source);
    if (targetNode == null)
      throw new Error('Node not find but connection still exists. connection.source=' + con.target);
    // save new connections to node data
    if (sourceNode.data.outputs) {
      sourceNode.data.outputs[con.sourceOutput as OutputKey] = targetNode.data.questionIndex;
    }
  });
}

export const getTGAccessHeaderContext = (search: string) => {
  const parsedSearch = new URLSearchParams(search);
  const accessKey = parsedSearch.get('accessKey');

  if (!accessKey) {
    return undefined;
  }

  const mutationContext = {
    headers: {
      'tg-access-key': accessKey,
    },
  };
  return mutationContext;
};
/**
 * On window close show alert dialog telling the user that if he exits the changes will not be saved
 */
export function useWarnUserWhenClosingTab(getIsDirty: () => boolean) {
  useEffect(() => {
    const alertUser = (event: BeforeUnloadEvent) => {
      const isDirty = getIsDirty();
      if (!isDirty) return undefined;
      event.returnValue = '';
      return '';
    };
    if (![ENVIRONMENT.DEVEL, 'cypress'].includes(getFrontendConfigValue('ENVIRONMENT'))) {
      window.addEventListener('beforeunload', alertUser);
    }
    return () => {
      if (![ENVIRONMENT.DEVEL, 'cypress'].includes(getFrontendConfigValue('ENVIRONMENT'))) {
        window.removeEventListener('beforeunload', alertUser);
      }
    };
  }, [getIsDirty]);
}

export const getNodesToCompare = (editor: CustomNodeEditor): NodeData[] => {
  const nodes = editor.getNodes().filter((node) => node.data.type !== COMMENT_TYPE);
  updateNodeDataBasedOnConnections(nodes, editor.getConnections());
  updatePositionInNodeData(editor, nodes);
  return nodes.map((node) => clone(node.data));
};

export const nodesEqual = (initialNodes: NodeData[] | undefined, editor: CustomNodeEditor, isTesting = false) => {
  if (initialNodes == null) return false;
  const currentNodes = getNodesToCompare(editor);
  if (isTesting) {
    [...initialNodes, ...currentNodes].forEach((node) => {
      node.position = [null];
      node.reportOrder = null;
    });
  }
  // Ramda does not work here for some unknown reason
  return JSON.stringify(initialNodes) === JSON.stringify(currentNodes);
};

/**
 * Topic detail can be corrupted / damaged and this function tries to fix it and is successful in most cases
 */
export const fixTopicDetail = (
  topicDetail: TopicDetailQuery['topicDetail'] | NonNullable<TopicGroupDetailQuery['topicGroup']['lastValidTopic']>,
) => {
  let somethingChanged = false;
  const clonedTopic = clone(topicDetail); // topicDetail is readonly
  const questions = clonedTopic.allQuestions;
  // In some weird topics there is no default translation, so we need to pick the first one that exists as the default
  questions.forEach((question) => {
    const haveDefaultTranslation = question.translations.some((translation) => translation.isDefault);
    if (!haveDefaultTranslation && question.translations[0]) {
      question.translations[0].isDefault = true;
      somethingChanged = true;
      console.warn(
        `Default translation was missing and needed to be added to the question with index ${question.index}`,
      );
    }
  });

  // In other weird topics there is a starting question id that is wrong and points to a question in the middle
  // In this case guess the real starting question which is successful in most cases where the topic is valid and fully connected
  const startingQuestionId = clonedTopic.startingQuestion.id;
  const startingQuestionIsInTheRulesOfOtherQuestions = questions.some((question) =>
    question.rules.some((rule) => rule.nextQuestion.id === startingQuestionId),
  );
  if (startingQuestionIsInTheRulesOfOtherQuestions) {
    // find question that is not in any rules of other questions - it should be the starting question (this might not be 100% correct if the topic is not valid and connected together)
    const startingQuestion = questions.find(
      (question) =>
        question.rules.length > 0 &&
        !questions.some((otherQuestion) => otherQuestion.rules.some((rule) => rule.nextQuestion.id === question.id)),
    );
    if (startingQuestion) {
      clonedTopic.startingQuestion = startingQuestion;
      somethingChanged = true;
      console.warn('Starting question id was wrong and was changed to: ', startingQuestion.id);
    }
  }

  if (somethingChanged) {
    return clonedTopic;
  }
  return topicDetail;
};
