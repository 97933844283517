import { TFunction, Trans } from 'react-i18next';
import { ReactComponent as Settings } from '@arnold/common/lib/assets/icons/Settings.svg';
import { cleanArray, isNullish, theme, usePath } from '@arnold/common';
import styled from '@emotion/styled';
import { FC } from 'react';
import { getTimeStringForOffsetInCzech, parseOffsetInMinutes } from '../../lib/process';
import { AppLink } from '../StyledComponents';
import { WarningMessage } from '../SurveysOverview/RowParts';
import { SurveyGroupQuery } from '../../generated/hooks';
import { SequenceStepsData } from './types';

export const getColumns = (t: TFunction, topicGroupId: string, isOnboarding?: boolean) =>
  cleanArray([
    {
      key: 'topic',
      title: t('topicName'),
      dataIndex: 'topic' as keyof SequenceStepsData,
    },
    !!isOnboarding && {
      key: 'metrics',
      title: t('onboarding:howDoYouFeel'),
      dataIndex: 'metrics' as keyof SequenceStepsData,
      width: '15%',
    },
    {
      key: 'daysFromStart',
      title: t('SequenceSteps:startOffset'),
      dataIndex: 'daysFromStart' as keyof SequenceStepsData,
      width: '15%',
    },
    {
      key: 'answered',
      title: t('onboarding:responseRate'),
      dataIndex: 'answered' as keyof SequenceStepsData,
    },
    {
      key: 'detail',
      title: (
        <AppLink to={`/stepsEdit/${topicGroupId}`} data-icom={'btn-topic-settings'}>
          <Settings />
          <StyledSpan>{t('onboarding:stepsSettings')}</StyledSpan>
        </AppLink>
      ),
      dataIndex: 'detail' as keyof SequenceStepsData,
    },
  ]);

const StyledSpan = styled.span`
  margin-left: ${theme.spacing.f};
  font-size: ${theme.typography.body.medium.button.fontSize};
  font-weight: ${theme.typography.body.medium.button.fontWeight};
`;

export const printOffset = (minsOffset: number, start: boolean, t: TFunction) => {
  if (isNullish(minsOffset)) {
    return null;
  }
  const offsetParsed = parseOffsetInMinutes(minsOffset);

  if (isNullish(offsetParsed) || isNullish(offsetParsed.days)) {
    return null;
  }
  return start ? (
    <>
      <Trans
        i18nKey={'processDetail:day'}
        values={{ dayNo: offsetParsed.days + (offsetParsed.days >= 0 ? 1 : 0) }}
        components={[<span />]}
      >
        Day 1
      </Trans>
      , {getTimeStringForOffsetInCzech(offsetParsed)}
    </>
  ) : (
    `${offsetParsed.days >= 0 ? '+' : ''}${offsetParsed.days} ${t('processDetail:days', {
      count: Math.abs(offsetParsed.days),
      postProcess: 'interval',
    })}, ${getTimeStringForOffsetInCzech(offsetParsed)}`
  );
};

const StyledLink = styled(AppLink)`
  float: right;
`;

export const TopicDetailLink: FC<{ surveyGroupId: number; step: number; label: string; isOnboarding?: boolean }> = ({
  surveyGroupId,
  step,
  label,
  isOnboarding,
}) => {
  const { onboardingStepDetail } = usePath();
  if (isOnboarding) {
    return (
      <StyledLink to={onboardingStepDetail.createPath(surveyGroupId.toString(), step.toString())}>{label}</StyledLink>
    );
  }
  return null;
};

const WarningMessageContainer = styled.span`
  margin-left: 0.5rem;
  display: inline-block;
`;

export const MetricsInfo: FC<any> = ({
  step,
  t,
}: {
  step: NonNullable<SurveyGroupQuery['surveyGroup']>['steps'][0];
  t: TFunction;
}) => (
  <>
    {step.avgMetric ? Math.round(step.avgMetric * 10) / 10 : '-'}
    {step.hasNotStarted ? (
      <WarningMessageContainer>
        <WarningMessage
          title={
            step.notstartedReason && step.notstartedReason.length > 0
              ? step.notstartedReason &&
                step.notstartedReason
                  .map((reason) =>
                    t('surveyOverviewRow:notStartedSequenceSurveyReason', {
                      reason: t(`surveyOverviewRow:reason-${reason}`),
                    }),
                  )
                  .filter((t) => !!t)
                  .join('<hr />')
              : t('surveyOverviewRow:notStartedWarning')
          }
        />
      </WarningMessageContainer>
    ) : (
      <></>
    )}
  </>
);

const GraySpan = styled.span`
  color: ${theme.colors.text.secondary};
`;
export const AnsweredSpan: FC<{ answered: number; total?: number }> = ({ answered, total }) => (
  <>
    <span>{`${Math.round(total ? (answered / total) * 100 : 0)}% `}</span>
    <GraySpan>
      ({answered} / {total})
    </GraySpan>
  </>
);
