import { theme } from '@arnold/common';
import { REPORT_LINK } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { ButtonLink } from '../../components/StyledComponents';
import DeleteSurvey from '../../components/SurveysOverview/DeleteSurvey';
import { UserQuery } from '../../generated/hooks';
import { goToTalkResults } from '../../lib/helpers';
import { GetDataSourceParams } from './types';
import { isPrepared } from './utils';

type SurveyActionsProps = Pick<GetDataSourceParams, 'toSurveyDetail' | 'pathStatus' | 't'> & {
  survey: NonNullable<UserQuery['user']['organization']>['surveys'][0];
  label?: string;
};
export const SurveyActions: FC<SurveyActionsProps> = ({
  survey: { id, sendAt, endsAt, topLevelReport },
  toSurveyDetail,
  pathStatus,
  t,
  label,
}) => {
  return (
    <Actions>
      <ButtonLink
        weight={500}
        onClick={() => toSurveyDetail(id)}
        data-cy="admin-edit-survey"
        data-icom={'survey-detail-link'}
      >
        {t('surveyDetail')}
      </ButtonLink>
      {isPrepared(pathStatus) ? (
        <DeleteSurvey surveyId={id} label={label} sendAt={sendAt} endsAt={endsAt} data-icom={'survey-delete-link'} />
      ) : (
        <ResultsButton
          onClick={() => {
            if (topLevelReport?.accessKey) {
              goToTalkResults(REPORT_LINK.REPORT, topLevelReport.accessKey);
            }
          }}
          disabled={!topLevelReport}
          data-icom={'survey-report-link'}
        >
          {t('summaryReport')}
        </ResultsButton>
      )}
    </Actions>
  );
};

const ResultsButton = styled(Button)`
  background-color: inherit;
  text-transform: none;
  color: ${theme.colors.actionPrimary.default};
`;

const Actions = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: end;
  gap: ${theme.spacing.f};
`;
