import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '@arnold/common';
import { RenameProcessSurveyForm } from '../RenameProcessSurveyForm';

type RenameProcessSurveyModalProps = {
  handleSubmit: (name: string, surveyGroupId: string) => void;
  isModalOpen: boolean;
  setModalOpen: (value: boolean) => void;
  surveyGroupName?: string;
  surveyGroupId: string;
  isOnboarding?: boolean;
};

export const RenameProcessSurveyModal: FC<RenameProcessSurveyModalProps> = ({
  isModalOpen,
  handleSubmit,
  setModalOpen,
  surveyGroupName,
  surveyGroupId,
  isOnboarding,
}) => {
  const { t } = useTranslation('surveyGroup');
  const [name, setName] = useState<string>(surveyGroupName || '');
  const handleSubmitForm = () => {
    handleSubmit(name, surveyGroupId);
  };

  useEffect(() => {
    setName(surveyGroupName || '');
  }, [surveyGroupName]);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => {
        setModalOpen(false);
        setName(surveyGroupName || '');
      }}
      title={t('rename')}
      onSubmit={handleSubmitForm}
      buttons={{
        cancel: { title: t('modal:cancel') },
        submit: { title: t('modal:save') },
      }}
      content={<RenameProcessSurveyForm name={name} setName={setName} isOnboarding={isOnboarding} />}
    />
  );
};
