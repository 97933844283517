import { EventEmitter } from 'events';
import { GraphQLError } from 'graphql';
import { ReactNode } from 'react';
import { ACCESS_TOKEN_ERROR } from '../components/Modals/AccessTokenValidation';
import { SurveyGroupSurveysData } from '../components/Modals/AddSGRespondentToOngoingSurveys';
import {
  RespondentOnboarding,
  RespondentOneTimeSurveys,
  RespondentSurveyGroups,
} from '../components/OrganizationImportCard';
import { OrganizationQuery, SurveyGroupRespondentsQuery, SurveyGroupsQuery, SurveysQuery } from '../generated/hooks';
import { getGraphQLErrorTranslationKey } from '../graphql/errors';
import { QuestionDetail } from '../screens/Topic/topicStructure';
import { ModalType } from './enums';
import { ImportStatistic } from './types';

export enum EVENT_TYPE {
  MODAL = 'MODAL',
  MODAL_ORG_IMPORT = 'MODAL_ORG_IMPORT',
  MODAL_IMPORT_DISCARD_SETTING = 'MODAL_IMPORT_DISCARD_SETTING',
  MODAL_LOADING = 'MODAL_LOADING',
  MODAL_DISCARD_CHANGES = 'MODAL_DISCARD_CHANGES',
  MODAL_LOADING_DISMISS = 'MODAL_LOADING_DISMISS',
  MODAL_DELETE_QUESTION = 'MODAL_DELETE_QUESTION',
  MODAL_CHOICE_SETTINGS = 'MODAL_CHOICE_SETTINGS',
  SECTION_FORM_CLOSE = 'SECTION_FORM_CLOSE',
  NEW_SECTION_FORM_CLOSE = 'NEW_SECTION_FORM_CLOSE',
  MODAL_QUESTION_SETTINGS = 'MODAL_QUESTION_SETTINGS',
  MODAL_DELETE_RESPONDENT = 'MODAL_DELETE_RESPONDENT',
  MODAL_ACCESS_TOKEN_VALIDATION = 'MODAL_ACCESS_TOKEN_VALIDATION',
  MODAL_OTP_ALREADY_SENT = 'MODAL_OTP_ALREADY_SENT',
  MODAL_SG_ALREADY_EXISTS = 'MODAL_SG_ALREADY_EXISTS',
  MODAL_ARIEL_HINTS = 'MODAL_ARIEL_HINTS',
  MODAL_SHARE_LINKS = 'MODAL_SHARE_LINKS',
  MODAL_NEW_TEAM = 'MODAL_NEW_TEAM',
  MODAL_UPDATE_RESPONDENT = 'MODAL_UPDATE_RESPONDENT',
  MODAL_DELETE_TOPIC_GROUP = 'MODAL_DELETE_TOPIC_GROUP',
  MODAL_DELETE_PROCESS_STEP = 'MODAL_DELETE_PROCESS_STEP',
  MODAL_SHARE_PUBLIC_SURVEY = 'MODAL_SHARE_PUBLIC_SURVEY',
  MODAL_END_PUBLIC_SURVEY = 'MODAL_END_PUBLIC_SURVEY',
  MODAL_SHARE_CHANNEL_LINK = 'MODAL_SHARE_CHANNEL_LINK',
  MODAL_ADD_SG_RESPONDENT_TO_ONGOING_SURVEYS = 'MODAL_ADD_SG_RESPONDENT_TO_ONGOING_SURVEYS',
  MODAL_DELETE_SURVEY_GROUP = 'MODAL_DELETE_SURVEY_GROUP',
}

class CustomEventEmitter extends EventEmitter {
  subscribeModal(
    callbackModal: (
      ModalType: ModalType,
      titleKey: string,
      textKey: string,
      buttonTextKey?: string,
      callback?: () => void,
      style?: any,
      info?: any,
    ) => void,
  ) {
    this.on(EVENT_TYPE.MODAL, ([ModalType, titleKey, textKey, buttonTextKey, callback, style, info]) => {
      callbackModal(ModalType, titleKey, textKey, buttonTextKey, callback, style, info);
    });
  }

  subscribeModalOrgImport(
    callbackModal: (
      name: string,
      statistic: ImportStatistic,
      callback: () => void,
      onboardings: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
      processSurveys: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
      surveys: NonNullable<SurveysQuery['user']['organization']>['surveys'],
      respondentOnboardingSettings: RespondentOnboarding,
      setRespondentOnboardingSettings: (data: RespondentOnboarding) => void,
      respondentOneTimeSurveySettings: RespondentOneTimeSurveys,
      setRespondentOneTimeSurveySettings: (data: RespondentOneTimeSurveys) => void,
      respondentSGSettings: RespondentSurveyGroups,
      setRespondentSGSettings: (data: RespondentSurveyGroups) => void,
      onClose: () => void,
    ) => void,
  ) {
    this.on(
      EVENT_TYPE.MODAL_ORG_IMPORT,
      ([
        name,
        statistic,
        callback,
        onboardings,
        processSurveys,
        surveys,
        respondentOnboardingSettings,
        setRespondentOnboardingSettings,
        respondentOneTimeSurveySettings,
        setRespondentOneTimeSurveySettings,
        respondentSGSettings,
        setRespondentSGSettings,
        onClose,
      ]) => {
        callbackModal(
          name,
          statistic,
          callback,
          onboardings,
          processSurveys,
          surveys,
          respondentOnboardingSettings,
          setRespondentOnboardingSettings,
          respondentOneTimeSurveySettings,
          setRespondentOneTimeSurveySettings,
          respondentSGSettings,
          setRespondentSGSettings,
          onClose,
        );
      },
    );
  }

  subscribeModalImportDiscardSettings(callbackModal: (discard: () => void, cancel?: () => void) => void) {
    this.on(EVENT_TYPE.MODAL_IMPORT_DISCARD_SETTING, ([discard, cancel]) => {
      callbackModal(discard, cancel);
    });
  }

  subscribeModalDiscardChanges(callbackModal: (discard: () => void, cancel?: () => void) => void) {
    this.on(EVENT_TYPE.MODAL_DISCARD_CHANGES, ([discard, cancel]) => {
      callbackModal(discard, cancel);
    });
  }

  subscribeModalDeleteQuestion(
    callbackModal: (
      no: number,
      fromQuestions: number[],
      toQuestions: number[],
      deleteQuestion: () => void,
      cancel?: () => void,
    ) => void,
  ) {
    this.on(EVENT_TYPE.MODAL_DELETE_QUESTION, ([no, fromQuestions, toQuestions, deleteQuestion, cancel]) => {
      callbackModal(no, fromQuestions, toQuestions, deleteQuestion, cancel);
    });
  }
  subscribeModalDeleteRespondent(
    callbackModal: (name: string, email: string, deleteRespondent: () => void, cancel?: () => void) => void,
  ) {
    this.on(EVENT_TYPE.MODAL_DELETE_RESPONDENT, ([name, email, deleteRespondent, cancel]) => {
      callbackModal(name, email, deleteRespondent, cancel);
    });
  }

  subscribeModalChoiceSettings(
    callbackModal: (choiceSeverity: number, save: (choiceSeverity: number) => void) => void,
  ) {
    this.on(EVENT_TYPE.MODAL_CHOICE_SETTINGS, ([choiceSeverity, save]) => {
      callbackModal(choiceSeverity, save);
    });
  }

  subscribeModalQuestionSettings(
    callbackModal: (question: QuestionDetail, save: (question: QuestionDetail) => void) => void,
  ) {
    this.on(EVENT_TYPE.MODAL_QUESTION_SETTINGS, ([question, save]) => {
      callbackModal(question, save);
    });
  }

  subscribeModalLoading(callbackModal: () => void) {
    this.on(EVENT_TYPE.MODAL_LOADING, () => callbackModal());
  }

  subscribeShareLinksModal(
    callbackModal: (
      links: {
        url: string;
        level: string;
      }[],
    ) => void,
  ) {
    this.on(EVENT_TYPE.MODAL_SHARE_LINKS, ([links]) => callbackModal(links));
  }

  subscribeNewTeamModal(
    callbackModal: (onChooseTeam: (teamId: string) => void, organization: OrganizationQuery['organization']) => void,
  ) {
    this.on(EVENT_TYPE.MODAL_NEW_TEAM, ([onChooseTeam, organization]) => callbackModal(onChooseTeam, organization));
  }

  subscribeUpdateRespondentModal(
    callbackModal: (
      respondent: SurveyGroupRespondentsQuery['surveyGroupRespondents']['data'][0]['respondents'][0],
      organizationId: string,
      contact?: string,
    ) => void,
  ) {
    this.on(EVENT_TYPE.MODAL_UPDATE_RESPONDENT, ([respondent, organizationId, contact]) =>
      callbackModal(respondent, organizationId, contact),
    );
  }

  subscribeModalLoadingDismiss(callbackModal: () => void) {
    this.on(EVENT_TYPE.MODAL_LOADING_DISMISS, () => callbackModal());
  }

  subscribeSectionFormClose(sectionId: number, callback: () => void) {
    this.on(`${EVENT_TYPE.SECTION_FORM_CLOSE}_${sectionId}`, callback);
  }

  unsubscribeSectionFormClose(sectionId: number, callback: () => void) {
    this.removeListener(`${EVENT_TYPE.SECTION_FORM_CLOSE}_${sectionId}`, callback);
  }

  subscribeNewSectionFormClose(sectionId: number, callback: () => void) {
    this.on(`${EVENT_TYPE.NEW_SECTION_FORM_CLOSE}_${sectionId}`, callback);
  }

  unsubscribeNewSectionFormClose(sectionId: number, callback: () => void) {
    this.removeListener(`${EVENT_TYPE.NEW_SECTION_FORM_CLOSE}_${sectionId}`, callback);
  }

  subscribeModalAccessTokenValidation(callbackModal: (status: keyof typeof ACCESS_TOKEN_ERROR) => void) {
    this.on(EVENT_TYPE.MODAL_ACCESS_TOKEN_VALIDATION, ([status]) => {
      callbackModal(status);
    });
  }

  subscribeModalOtpAlreadySent(callbackModal: (time: number) => void) {
    this.on(EVENT_TYPE.MODAL_OTP_ALREADY_SENT, ([time]) => {
      callbackModal(time);
    });
  }

  subscribeModalSgAlreadyExists(callbackModal: (callback: () => void) => void) {
    this.on(EVENT_TYPE.MODAL_SG_ALREADY_EXISTS, ([callback]) => {
      callbackModal(callback);
    });
  }

  subscribeArielHintsModal(callbackModal: () => void) {
    this.on(EVENT_TYPE.MODAL_ARIEL_HINTS, () => {
      callbackModal();
    });
  }

  subscribeDeleteTopicGroupModal(callbackModal: (title: string, onDelete: () => void) => void) {
    this.on(EVENT_TYPE.MODAL_DELETE_TOPIC_GROUP, ([title, onDelete]) => {
      callbackModal(title, onDelete);
    });
  }

  subscribeDeleteProcessStepModal(callbackModal: (onDelete: () => void, title: string) => void) {
    this.on(EVENT_TYPE.MODAL_DELETE_PROCESS_STEP, ([onDelete, title]) => {
      callbackModal(onDelete, title);
    });
  }

  subscribeModalSharePublicSurvey(callbackModal: (accessKey: string, title: string) => void) {
    this.on(EVENT_TYPE.MODAL_SHARE_PUBLIC_SURVEY, ([accessKey, title]) => {
      callbackModal(accessKey, title);
    });
  }

  subscribeModalShareChannelLink(callbackModal: (slug: string) => void) {
    this.on(EVENT_TYPE.MODAL_SHARE_CHANNEL_LINK, ([slug]) => {
      callbackModal(slug);
    });
  }

  subscribeModalEndPublicSurvey(callbackModal: (onSave: () => void, title: string) => void) {
    this.on(EVENT_TYPE.MODAL_END_PUBLIC_SURVEY, ([onSave, title]) => {
      callbackModal(onSave, title);
    });
  }

  subscribeModalAddSGRespondentToOngoingSurvey(
    callbackModal: (
      onSave: (runOngoingSteps: string[], addedOn?: Date) => void,
      addedOn: Date,
      ongoingSurveys: SurveyGroupSurveysData[],
      selectedRespondents?: string[],
    ) => void,
  ) {
    this.on(
      EVENT_TYPE.MODAL_ADD_SG_RESPONDENT_TO_ONGOING_SURVEYS,
      ([onSave, addedOn, ongoingSurveys, selectedRespondents]) => {
        callbackModal(onSave, addedOn, ongoingSurveys, selectedRespondents);
      },
    );
  }

  subscribeModalDeleteSurveyGroup(callbackModal: (onDelete: () => void) => void) {
    this.on(EVENT_TYPE.MODAL_DELETE_SURVEY_GROUP, ([onDelete]) => {
      callbackModal(onDelete);
    });
  }

  emitModal(
    ModalType: ModalType,
    titleKey: string,
    textKey: string,
    buttonTextKey?: string,
    callback?: () => void,
    style?: any,
  ) {
    this.emit(EVENT_TYPE.MODAL, [ModalType, titleKey, textKey, buttonTextKey, callback, style]);
  }

  emitModalGraphQlError(graphQLError: GraphQLError, buttonTextKey?: string, callback?: () => void) {
    const [titleKey, textKey] = getGraphQLErrorTranslationKey(graphQLError);
    this.emit(EVENT_TYPE.MODAL, [
      ModalType.ERROR,
      titleKey,
      textKey,
      buttonTextKey,
      callback,
      null,
      (graphQLError as any).info,
    ]);
  }

  emitModalOrgImport(
    name: string,
    statistic: ImportStatistic,
    callback: () => void,
    onboardings: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
    processSurveys: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
    surveys: NonNullable<SurveysQuery['user']['organization']>['surveys'],
    respondentOnboardingSettings: RespondentOnboarding,
    setRespondentOnboardingSettings: (data: RespondentOnboarding) => void,
    respondentOneTimeSurveySettings: RespondentOneTimeSurveys,
    setRespondentOneTimeSurveySettings: (data: RespondentOneTimeSurveys) => void,
    respondentSGSettings: RespondentSurveyGroups,
    setRespondentSGSettings: (data: RespondentSurveyGroups) => void,
    onClose?: () => void,
  ) {
    this.emit(EVENT_TYPE.MODAL_ORG_IMPORT, [
      name,
      statistic,
      callback,
      onboardings,
      processSurveys,
      surveys,
      respondentOnboardingSettings,
      setRespondentOnboardingSettings,
      respondentOneTimeSurveySettings,
      setRespondentOneTimeSurveySettings,
      respondentSGSettings,
      setRespondentSGSettings,
      onClose,
    ]);
  }

  emitModalImportDiscardSettings(discard: () => void, cancel?: () => void) {
    this.emit(EVENT_TYPE.MODAL_IMPORT_DISCARD_SETTING, [discard, cancel]);
  }

  emitModalDiscardChanges(discard: () => void, cancel?: () => void) {
    this.emit(EVENT_TYPE.MODAL_DISCARD_CHANGES, [discard, cancel]);
  }

  emitModalDeleteQuestion(
    no: number,
    fromQuestions: number[],
    toQuestions: number[],
    discard: () => void,
    cancel?: () => void,
  ) {
    this.emit(EVENT_TYPE.MODAL_DELETE_QUESTION, [no, fromQuestions, toQuestions, discard, cancel]);
  }

  emitModalDeleteRespondent(name: string, email: string, deleteRespondent: () => void, cancel?: () => void) {
    this.emit(EVENT_TYPE.MODAL_DELETE_RESPONDENT, [name, email, deleteRespondent, cancel]);
  }

  emitModalChoiceSettings(choiceSeverity: number, save: (choiceSeverity: number) => void) {
    this.emit(EVENT_TYPE.MODAL_CHOICE_SETTINGS, [choiceSeverity, save]);
  }

  emitModalQuestionSettings(question: QuestionDetail, save: (question: QuestionDetail) => void) {
    this.emit(EVENT_TYPE.MODAL_QUESTION_SETTINGS, [question, save]);
  }

  emitModalLoading() {
    this.emit(EVENT_TYPE.MODAL_LOADING);
  }

  emitModalLoadingDismiss() {
    this.emit(EVENT_TYPE.MODAL_LOADING_DISMISS);
  }

  emitSectionFormClose(sectionId: number) {
    this.emit(`${EVENT_TYPE.SECTION_FORM_CLOSE}_${sectionId}`);
  }

  emitNewSectionFormClose(sectionId: number) {
    this.emit(`${EVENT_TYPE.NEW_SECTION_FORM_CLOSE}_${sectionId}`);
  }

  emitAccessTokenValidationModal(status: string) {
    this.emit(EVENT_TYPE.MODAL_ACCESS_TOKEN_VALIDATION, [status]);
  }

  emitOtpAlreadySentModal(time: number) {
    this.emit(EVENT_TYPE.MODAL_OTP_ALREADY_SENT, [time]);
  }

  emitSgAlreadyExistsModal(callback: () => void) {
    this.emit(EVENT_TYPE.MODAL_SG_ALREADY_EXISTS, [callback]);
  }
  emitArielHintsModal() {
    this.emit(EVENT_TYPE.MODAL_ARIEL_HINTS);
  }

  emitDeleteTopicGroupModal(title: string, onDelete: () => void) {
    this.emit(EVENT_TYPE.MODAL_DELETE_TOPIC_GROUP, [title, onDelete]);
  }

  emitNewTeam(onChooseTeam: (teamId: string) => void, organization: OrganizationQuery['organization']) {
    this.emit(EVENT_TYPE.MODAL_NEW_TEAM, [onChooseTeam, organization]);
  }
  emitUpdateRespondent(
    respondent: SurveyGroupRespondentsQuery['surveyGroupRespondents']['data'][0]['respondents'][0],
    organizationId: string,
    contact?: string,
  ) {
    this.emit(EVENT_TYPE.MODAL_UPDATE_RESPONDENT, [respondent, organizationId, contact]);
    this.emit(EVENT_TYPE.MODAL_UPDATE_RESPONDENT, [respondent, organizationId, contact]);
  }

  emitShareLinksModal(
    links: {
      url: string;
      level: string;
    }[],
  ) {
    this.emit(EVENT_TYPE.MODAL_SHARE_LINKS, [links]);
  }

  emitDeleteProcessStepModal(onDelete: () => void, children: ReactNode) {
    this.emit(EVENT_TYPE.MODAL_DELETE_PROCESS_STEP, [onDelete, children]);
  }

  emitModalSharePublicSurvey(accessKey: string, title: string) {
    this.emit(EVENT_TYPE.MODAL_SHARE_PUBLIC_SURVEY, [accessKey, title]);
  }

  emitModalShareChannelLink(slug: string) {
    this.emit(EVENT_TYPE.MODAL_SHARE_CHANNEL_LINK, [slug]);
  }

  emitModalEndPublicSurvey(onSave: () => void, title: string) {
    this.emit(EVENT_TYPE.MODAL_END_PUBLIC_SURVEY, [onSave, title]);
  }

  emitAddSGRespondentToOngoingSurveys(
    onSave: (runOngoingSteps: string[], addedOn: Date) => void,
    addedOn: Date,
    ongoingSurveys: Pick<SurveyGroupSurveysData, 'id' | 'topic'>[],
    selectedRespondents?: string[],
  ) {
    this.emit(EVENT_TYPE.MODAL_ADD_SG_RESPONDENT_TO_ONGOING_SURVEYS, [
      onSave,
      addedOn,
      ongoingSurveys,
      selectedRespondents,
    ]);
  }

  emitModalDeleteSurveyGroup(onDelete: () => void) {
    this.emit(EVENT_TYPE.MODAL_DELETE_SURVEY_GROUP, [onDelete]);
  }
}

const ee = new CustomEventEmitter();

export default ee;
