import { BodyMedium, CardBody, HeadingMedium, ProductHistory, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { Form, Formik, FormikValues } from 'formik';
import { isEmpty } from 'ramda';
import { useState } from 'react';
import { Button, Card, FormControl, FormGroup, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { FormErrorFeedback, FormGroupLabel, FormRadioInput } from '../../components/Common';
import {
  ContactSource,
  ContactType,
  GetPricelistQuery,
  GetRespondentsQuery,
  IsAccessTokenValidQuery,
  OrganizationQuery,
  SendOrderInput,
  SubscriptionDuration,
  useSendOrderMutation,
} from '../../generated/hooks';
import { EDITION } from '../../lib/common';
import { isValidEmail } from '../../lib/helpers';

const DEFAULT_LICENCE_COUNT = 50;
interface IProps {
  productVersion?: ProductHistory | null;
  organizationId?: string;
  productHistory?: NonNullable<OrganizationQuery['organization']>['productVersionHistory'];
  edition?: keyof typeof EDITION;
  pricelist?: GetPricelistQuery['getPricelist'];
  user?: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
  withTeamioArnoldOnboarding?: boolean;
  respondents?: GetRespondentsQuery['user']['respondents'];
}

interface ITableHeaderProps {
  textAlign?: string;
}

interface ITableRowProps {
  selected: boolean;
  disabled?: boolean;
}

const FormContainer = styled.div`
  width: 70%;
  max-width: 500px;
`;

const FormColumns = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing.f};
`;

const TableHeader = styled.th`
  font-size: ${theme.typography.body.small?.regular?.fontSize} !important;
  margin-bottom: ${theme.spacing.c} !important;
  color: ${theme.colors.text.secondary} !important;
  text-transform: none !important;
  font-weight: 400 !important;
  text-align: ${({ textAlign }: ITableHeaderProps) => textAlign || 'center'};
`;

const TableRow = styled.tr`
  background-color: ${({ selected }: ITableRowProps) =>
    selected ? `${theme.colors.backgroundBasic.default}` : `${theme.colors.backgroundCover.default}`};
  cursor: ${({ disabled }: ITableRowProps) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    background-color: ${theme.colors.backgroundBasic.default};
  }
`;

const Price = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.textInverted.disabled};
`;

const TableHeaderResponsive = styled(TableHeader)`
  @media (max-width: 640px) {
    display: none;
  }
`;

const TdResponsive = styled.td`
  @media (max-width: 640px) {
    display: none;
  }
`;

interface IPricing {
  pricelist?: GetPricelistQuery['getPricelist'];
  edition: string;
  hideRespondentForMonth?: boolean;
}

const Pricing = ({ pricelist, edition, hideRespondentForMonth }: IPricing) => {
  const [t] = useTranslation('editionSettings');

  const month = pricelist?.find((pl) => pl && pl.product === edition && pl.duration === SubscriptionDuration.Month);
  const year = pricelist?.find((pl) => pl && pl.product === edition && pl.duration === SubscriptionDuration.Year);

  return (
    <>
      <TdResponsive>
        {month && (
          <Price>
            <div>
              <span>{`${month?.price} `}</span>
              <span>{t(`currency:${month?.currency || 'EUR'}`)}</span>
            </div>
            <div>{t('respondentForMonth')}</div>
          </Price>
        )}
      </TdResponsive>
      <TdResponsive>
        {year && (
          <Price>
            <div>
              <span>{`${year?.price} `}</span>
              <span>{t(`currency:${year?.currency || 'EUR'}`)}</span>
            </div>
            <div>{t(hideRespondentForMonth ? 'respondent' : 'respondentForMonth')}</div>
          </Price>
        )}
      </TdResponsive>
    </>
  );
};

export const OrderForm = ({
  productVersion,
  organizationId,
  productHistory,
  edition,
  pricelist,
  withTeamioArnoldOnboarding,
  respondents,
}: IProps) => {
  const { t, i18n } = useTranslation('editionSettings');
  const history = useHistory();
  const [isFinished, setIsFinished] = useState(false);

  const getEmailForOrganization = (respondents: NonNullable<GetRespondentsQuery['user']['respondents']>) =>
    respondents
      .find(
        (r) =>
          (r.organization.id === organizationId &&
            r.contacts?.some((c) => c.type === ContactType.Email && isValidEmail(c.value))) ||
          r.contacts?.some((c) => c.type === ContactType.Email && isValidEmail(c.value)),
      )
      ?.contacts?.find((c) => (c.source === ContactSource.Primary && isValidEmail(c.value)) || isValidEmail(c.value))
      ?.value;
  const getLatestPaidLicenceCount = (
    paidProducts: NonNullable<NonNullable<OrganizationQuery['organization']>['productVersionHistory']>,
  ) => {
    return paidProducts.sort((p1, p2) => p2.validFrom - p1.validFrom)[0]?.licenceCount;
  };

  const OrderFormValidationSchema = Yup.object().shape({
    licenceCount: Yup.number().required(t('enterLicenceCount')),
    contactEmail: Yup.string()
      .required(t('updateRespondent:enterValidEmail'))
      .email(t('updateRespondent:enterValidEmail')),
  });

  const [sendOrder] = useSendOrderMutation();
  const handleSubmit = async (values: FormikValues) => {
    const input: SendOrderInput = {
      organizationId,
      languageCode: i18n.language,
      edition: values.edition,
      isNew: !productVersion,
      note: values.info,
      licenceCount: parseInt(values.licenceCount, 10),
      contactEmail: values.contactEmail,
    };
    try {
      await sendOrder({
        variables: {
          input,
        },
      });
      try {
        setIsFinished(true);
      } catch {
        // do nothing
      }
    } catch {
      // do nothing
    }
  };

  if (isFinished) {
    return (
      <Card data-cy={'admin-confirm-order'}>
        <CardBody>
          <HeadingMedium margin={`0 0 ${theme.spacing.h} 0`}>{t('thanksForOrder')}</HeadingMedium>
          <BodyMedium margin={`0 0 ${theme.spacing.f} 0`}>{t('thanksForOrderInfo')}</BodyMedium>
          {organizationId && (
            <Button
              variant="primary"
              type="submit"
              onClick={() =>
                history.push({
                  pathname: '/settings',
                })
              }
            >
              {t('cancel')}
            </Button>
          )}
        </CardBody>
      </Card>
    );
  }

  return (
    <Formik
      initialValues={{
        edition:
          (productVersion &&
            Object.keys(EDITION).includes(productVersion.product) &&
            productVersion.product !== 'FREE' &&
            productVersion.product) ||
          edition ||
          EDITION.ESSENTIAL.name,
        licenceCount:
          productVersion?.licenceCount ||
          (productHistory &&
            getLatestPaidLicenceCount(
              productHistory.filter((ph) =>
                [
                  EDITION.ESSENTIAL.name,
                  EDITION.BUSINESS.name,
                  EDITION.ENTERPRISE.name,
                  EDITION.TEAMIO_ARNOLD.name,
                ].includes(ph.product),
              ),
            )) ||
          DEFAULT_LICENCE_COUNT,
        info: undefined,
        period: 12,
        contactEmail: respondents && getEmailForOrganization(respondents),
      }}
      validationSchema={OrderFormValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleSubmit, handleChange, handleBlur, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Card>
            <CardBody>
              <BodyMedium margin={`0 0 ${theme.spacing.f} 0`}>{t('nonBidingOrderInfo')}</BodyMedium>
              <FormContainer>
                <Table style={{ tableLayout: 'fixed' }} borderless>
                  <thead>
                    <tr>
                      <TableHeader textAlign={'left'}>{t('edition')}</TableHeader>
                      <TableHeaderResponsive>{t('monthly')}</TableHeaderResponsive>
                      <TableHeaderResponsive>{t('annually')}</TableHeaderResponsive>
                    </tr>
                  </thead>
                  <tbody>
                    <TableRow
                      selected={values.edition === EDITION.ESSENTIAL.name}
                      disabled={!!productVersion && productVersion.product === EDITION.BUSINESS.name}
                      onClick={() => {
                        if (productVersion && productVersion.product === EDITION.BUSINESS.name) {
                          return;
                        }
                        setFieldValue('edition', EDITION.ESSENTIAL.name);
                      }}
                    >
                      <td>
                        <FormRadioInput
                          name="edition"
                          value={EDITION.ESSENTIAL.name}
                          clicked={() => {
                            if (productVersion && productVersion.product === EDITION.BUSINESS.name) {
                              return;
                            }
                            setFieldValue('edition', EDITION.ESSENTIAL.name);
                          }}
                          changed={handleChange}
                          onBlur={handleBlur}
                          defaultChecked={values.edition === EDITION.ESSENTIAL.name}
                          label={t(`label-${EDITION.ESSENTIAL.name}`)}
                        />
                      </td>
                      <Pricing pricelist={pricelist} edition={EDITION.ESSENTIAL.name} />
                    </TableRow>
                    <TableRow
                      selected={values.edition === EDITION.BUSINESS.name}
                      onClick={() => {
                        setFieldValue('edition', EDITION.BUSINESS.name);
                      }}
                    >
                      <td>
                        <FormRadioInput
                          name="edition"
                          value={EDITION.BUSINESS.name}
                          clicked={() => {
                            setFieldValue('edition', EDITION.BUSINESS.name);
                          }}
                          changed={handleChange}
                          onBlur={handleBlur}
                          defaultChecked={values.edition === EDITION.BUSINESS.name}
                          label={t(`label-${EDITION.BUSINESS.name}`)}
                        />
                      </td>
                      <Pricing pricelist={pricelist} edition={EDITION.BUSINESS.name} />
                    </TableRow>
                    {withTeamioArnoldOnboarding && (
                      <TableRow
                        selected={values.edition === EDITION.TEAMIO_ARNOLD.name}
                        onClick={() => {
                          setFieldValue('edition', EDITION.TEAMIO_ARNOLD.name);
                        }}
                      >
                        <td>
                          <FormRadioInput
                            name="edition"
                            value={EDITION.TEAMIO_ARNOLD.name}
                            clicked={() => {
                              setFieldValue('edition', EDITION.TEAMIO_ARNOLD.name);
                            }}
                            changed={handleChange}
                            onBlur={handleBlur}
                            defaultChecked={values.edition === EDITION.TEAMIO_ARNOLD.name}
                            label={t(`label-${EDITION.TEAMIO_ARNOLD.name}`)}
                          />
                        </td>
                        <Pricing pricelist={pricelist} edition={EDITION.TEAMIO_ARNOLD.name} hideRespondentForMonth />
                      </TableRow>
                    )}
                  </tbody>
                </Table>
                <FormColumns>
                  <FormGroup style={{ width: '200px' }}>
                    <FormGroupLabel>{t('licenceCount')}</FormGroupLabel>
                    <FormControl
                      style={{ width: '200px' }}
                      type="number"
                      name="licenceCount"
                      value={values.licenceCount || ''}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        const regex = /^\d+$/;
                        if (!value || regex.test(value.toString())) {
                          setFieldValue('licenceCount', value);
                        }
                      }}
                      isInvalid={!!errors.licenceCount}
                    />
                    {errors.licenceCount && <FormErrorFeedback error={errors.licenceCount} />}
                  </FormGroup>
                </FormColumns>
                <FormColumns>
                  <FormGroup style={{ width: '100%' }}>
                    <FormGroupLabel>{t('contactEmail')}</FormGroupLabel>
                    <FormControl
                      style={{ width: '100%' }}
                      type="text"
                      name="contactEmail"
                      value={values.contactEmail || undefined}
                      onChange={(e) => {
                        setFieldValue('contactEmail', e.target.value);
                      }}
                      isInvalid={!!errors.contactEmail}
                    />
                    {errors.contactEmail && <FormErrorFeedback error={errors.contactEmail} />}
                  </FormGroup>
                </FormColumns>
                <FormGroup>
                  <FormGroupLabel>{t('info')}</FormGroupLabel>
                  <FormControl
                    as="textarea"
                    rows={3}
                    type="text"
                    name="info"
                    value={values.info || ''}
                    onChange={(e) => {
                      setFieldValue('info', e.target.value);
                    }}
                  />
                </FormGroup>
              </FormContainer>
              <div>
                <Button variant="primary" type="submit" disabled={!isEmpty(errors) || isSubmitting}>
                  {t('orderFormButton')}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default OrderForm;
