import { ClassicPreset } from 'rete';
import { ApolloProvider } from '@apollo/client';
import { FC } from 'react';
import { NodeData } from '../dataNode';
import apolloClient from '../../../createApollo';
import { DeleteCommentButton } from './DeleteCommentButton';

export class DeleteCommentControl extends ClassicPreset.Control {
  constructor(
    public data: NodeData,
    public component: FC<{ data: NodeData; readOnly: boolean }>,
    public readOnly = false,
  ) {
    super();
    this.component = () => (
      <ApolloProvider client={apolloClient}>
        <DeleteCommentButton data={data} readOnly={readOnly} />
      </ApolloProvider>
    );
  }
}
