import { format, isValid, parse } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { TIME_FORMAT } from '../../helpers';
import { theme } from '../../theme';
import { customStyles, selectMenuStyles } from '../selects';

const DEFAULT_TIME = '00:00';
const formatToTime = (value: string): string => {
  const time = parse(value, TIME_FORMAT, new Date());
  if (isValid(time)) {
    return format(time, TIME_FORMAT);
  }
  return format(parse(value, 'hh:mm aa', new Date()), TIME_FORMAT);
};

type TimeSelectProps = {
  initialValue?: string;
  disabled?: boolean;
  isAlert?: boolean;
  onChange: (value: string) => void;
  name?: string;
  dataCy?: string;
};

const getOptions = () => {
  const result: { value: string; label: string }[] = [];

  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 2; minute++) {
      const value = formatToTime(`${hour}:${minute ? '30' : '00'}`);
      result.push({
        value,
        label: value,
      });
    }
  }
  return result;
};

const options = getOptions();

export const TimeSelect: FC<TimeSelectProps> = ({ initialValue, disabled, isAlert, onChange, name, dataCy }) => {
  const [value, setValue] = useState<{ value: string | undefined; label: string | undefined } | null>(null);

  useEffect(() => {
    setValue({
      value: initialValue,
      label: initialValue,
    });
  }, [initialValue]);

  return (
    <div data-cy={dataCy}>
      <CreatableSelect
        className="reactSelect primary"
        classNamePrefix="reactSelect"
        value={value}
        name={name}
        onChange={(val) => {
          setValue(val);
          onChange(val?.value || '');
        }}
        options={options}
        // keep it here or creatable not work
        isSearchable={true}
        onInputChange={(inputValue) => {
          if (isValid(parse(inputValue, TIME_FORMAT, new Date()))) {
            setValue({
              value: formatToTime(inputValue),
              label: formatToTime(inputValue),
            });
          }
        }}
        onBlur={() => {
          onChange(value?.value ?? '');
        }}
        isClearable={true}
        isDisabled={disabled}
        menuPlacement="auto"
        placeholder={DEFAULT_TIME}
        createOptionPosition={'first'}
        formatCreateLabel={(inputValue) => formatToTime(inputValue)}
        isValidNewOption={(inputValue) =>
          isValid(parse(inputValue, TIME_FORMAT, new Date()) && !options.some((option) => option.value === inputValue))
        }
        onCreateOption={(inputValue) => {
          setValue({
            label: formatToTime(inputValue),
            value: formatToTime(inputValue),
          });
          onChange(inputValue);
        }}
        styles={{
          ...customStyles,
          control: (provided: any, state: any) => ({
            ...provided,
            paddingLeft: theme.spacing.f,
            borderColor: isAlert
              ? theme.colors.emotionDanger.default
              : state.isFocused
                ? `${theme.colors.borderMain.active} !important`
                : theme.colors.borderMain.default,
            boxShadow: 'none',
          }),
          menu: (provided: any, state: any) => ({
            ...provided,
            ...selectMenuStyles,
          }),
          container: (provided) => ({
            ...provided,
            width: '126px',
          }),
          clearIndicator: (provided) => ({
            ...provided,
            display: 'none',
          }),
        }}
      />
    </div>
  );
};
