import { Breadcrumb, CardGrid, HeadingLarge, HeadingMedium, TabItem, Tabs, theme } from '@arnold/common';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProductCode } from '@arnold/core';
import { PageWrapper } from '../Common';
import { getUniqByIdConversationSubjects } from '../SurveysOverview/helpers';
import { Loading } from '../index';
import {
  AllowedConversationSubjectQuery,
  Features,
  useAllowedConversationSubjectQuery,
  useOrganizationQuery,
} from '../../generated/hooks';
import { ConversationSubjectCard } from './components/ConversationSubjectCard';
import { NewCustomTopicButton } from './components/NewCustomTopicButton';

type AllowedConversationSubjects = NonNullable<
  AllowedConversationSubjectQuery['user']['organization']
>['allowedConversationSubjects'];

type ConversationSubjectsProps = {
  heading: string;
  breadcrumbItems?: TabItem[];
  tabItems?: TabItem[];
  filterProcesses?: boolean;
  getLink?: (subject: AllowedConversationSubjects[0]) => string;
  isDisabled?: (subject: AllowedConversationSubjects[0]) => boolean;
  processTopicGroupId?: string;
  hideCreateTopicButton?: boolean;
  usedStepTopicGroupIds?: string[];
  dataIcom?: string;
};

export const ConversationSubjects: FC<ConversationSubjectsProps> = ({
  heading,
  breadcrumbItems,
  tabItems,
  filterProcesses,
  getLink,
  isDisabled,
  processTopicGroupId,
  hideCreateTopicButton,
  usedStepTopicGroupIds = [],
  dataIcom,
}) => {
  const { t, i18n } = useTranslation('surveysOverview');
  const [updatingSubjects, setUpdatingSubjects] = useState(false);

  const { loading, data, refetch } = useAllowedConversationSubjectQuery({
    variables: {
      languageCode: i18n.language,
    },
  });

  // organization is most probably loaded already by the authorizedPageLoader, this is just to prevent prop drilling
  const { data: organizationData, loading: organizationLoading } = useOrganizationQuery();

  useEffect(() => {
    refetch({ languageCode: i18n.language });
  }, [i18n.language, refetch]);

  const allowedConversationSubjects = data?.user.organization?.allowedConversationSubjects;
  const organization = organizationData?.organization;

  if (loading || !allowedConversationSubjects || updatingSubjects || organizationLoading || !organization) {
    return <Loading />;
  }

  const compareTopics = (a: AllowedConversationSubjects[0], b: AllowedConversationSubjects[0]) => {
    if (isDisabled) {
      const aDisabled = isDisabled(a) ? 1 : -1;
      const bDisabled = isDisabled(b) ? 1 : -1;
      return aDisabled - bDisabled;
    }
    return 0;
  };

  const customSubjects = getUniqByIdConversationSubjects(
    allowedConversationSubjects.filter(
      (subject: AllowedConversationSubjects[0]) =>
        (!isDisabled || !isDisabled(subject)) && subject.custom && (!filterProcesses || !subject.process),
    ),
  ).reverse();
  const sortedCustomSubjects = (isDisabled ? customSubjects.slice().sort(compareTopics) : customSubjects).filter(
    (s) => !usedStepTopicGroupIds.includes(s.id),
  );
  const generalSubjects = getUniqByIdConversationSubjects(
    allowedConversationSubjects.filter(
      (subject: AllowedConversationSubjects[0]) =>
        (!isDisabled || !isDisabled(subject)) && !subject.custom && (!filterProcesses || !subject.process),
    ),
  );

  const sortedGeneralSubjects = (isDisabled ? generalSubjects.slice().sort(compareTopics) : generalSubjects).filter(
    (s) => !usedStepTopicGroupIds.includes(s.id),
  );
  const activeTab = tabItems && tabItems.find((item) => item.active);
  return (
    <PageWrapper data-cy="admin-page-content">
      {breadcrumbItems && (
        <Breadcrumb
          classList={'mt-n6'}
          items={[
            { title: t('teamsScreen:surveys'), link: '/overview' },
            ...breadcrumbItems,
            { title: activeTab ? activeTab.title : heading, active: true },
          ]}
        />
      )}
      <HeadingLarge data-icom={dataIcom}>{heading}</HeadingLarge>
      {!hideCreateTopicButton && organization.featureNames?.includes(Features.CreateAndEditTopics) && (
        <NewCustomTopicButton
          organizationId={organization.id}
          languages={organization.languages.map((l) => l.code)}
          defaultLanguage={organization.primaryLanguageCode}
        />
      )}
      {tabItems && <Tabs items={tabItems} theme={theme} />}
      {sortedCustomSubjects && sortedCustomSubjects.length > 0 && (
        <>
          <HeadingMedium>{t('chooseCustomSurveyToopic')}</HeadingMedium>
          <CardGrid>
            {sortedCustomSubjects.map((subject: AllowedConversationSubjects[0]) => (
              <ConversationSubjectCard
                key={subject.id}
                subject={subject}
                toDetailLink={getLink && getLink(subject)}
                organizationLanguages={organization.languages}
                languageId={organization.primaryLanguageId}
                processTopicGroupId={processTopicGroupId}
                showOptions
                setLoading={setUpdatingSubjects}
                stepTopicGroupId={subject.id}
                cannotScheduleReason={
                  // Free org can't schedule custom topics
                  organization.productVersion?.product === ProductCode.Free
                    ? t('freeOrganizationsCannotPlanCustomTopics')
                    : undefined
                }
              />
            ))}
          </CardGrid>
        </>
      )}
      {sortedGeneralSubjects && sortedGeneralSubjects.length > 0 && (
        <>
          <HeadingMedium>{t('chooseSystemSurveyTopic')}</HeadingMedium>
          <span style={{ color: '#0D0A0B' }}>{t('chooseTopicDescription')}</span>
          <CardGrid data-icom={'survey-system-topics'}>
            {sortedGeneralSubjects.map((subject: AllowedConversationSubjects[0]) => (
              <ConversationSubjectCard
                key={subject.id}
                subject={subject}
                toDetailLink={getLink && getLink(subject)}
                organizationLanguages={organization.languages}
                languageId={organization.primaryLanguageId}
                processTopicGroupId={processTopicGroupId}
                showOptions
                setLoading={setUpdatingSubjects}
                stepTopicGroupId={subject.id}
              />
            ))}
          </CardGrid>
        </>
      )}
    </PageWrapper>
  );
};
