import { Button, ButtonProps } from 'react-bootstrap';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { theme } from '../../theme';

type Props = ButtonProps & {
  disabled?: boolean;
  children: ReactNode;
  margin?: string;
  padding?: string;
};

const StyledButton = styled(Button)<Props>`
  margin: ${({ margin }) => margin ?? '0 0'};
  padding: ${({ padding }) => padding ?? '0 0'};
  color: ${theme.colors.actionPrimary.default};
  font-weight: ${theme.typography.body.medium.button.fontWeight};
  font-size: ${theme.typography.body.medium.button.fontSize};
  line-height: ${theme.typography.body.medium.button.lineHeight};
  margin: ${(props) => (props.margin ? props.margin : '10px 1px')};
  background: none;
  border: none;
  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:active:hover {
    color: ${theme.colors.actionPrimary.default} !important;
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  &:disabled,
  &:disabled:active {
    background: none !important;
    border: none !important;
    color: ${theme.colors.actionPrimary.disabled} !important;
    path {
      fill: ${theme.colors.actionPrimary.disabled} !important;
    }
  }
`;
export const LinkButton: FC<Props> = ({ disabled, children, margin, padding, ...props }) => (
  <StyledButton disabled={disabled} margin={margin} padding={padding} {...props}>
    {children}
  </StyledButton>
);
