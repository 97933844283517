import { ConditionalWrapper, theme, Tooltip, useToast } from '@arnold/common';
import { FC } from 'react';
import styled from '@emotion/styled/macro';
import { Button } from 'react-bootstrap';
import { UserQuery, useSetSurveyArchivedMutation, useSetSurveyGroupArchivedMutation } from '../../generated/hooks';
import { getOperationName } from '../../lib/common';
import { getSurvey, getSurveyGroup, getUserQuery } from '../../graphql/queries';
import { Loading } from '../../components';
import { ButtonLink } from '../../components/StyledComponents';
import { GetDataSourceParams } from './types';

type SurveyActionsProps = Pick<GetDataSourceParams, 'toSurveyDetail' | 't'> & {
  isSurveyGroup: boolean;
  survey:
    | NonNullable<UserQuery['user']['organization']>['surveys'][0]
    | NonNullable<UserQuery['user']['organization']>['surveyGroups'][0];
  disableDearchive: boolean;
};
export const SurveyArchiveActions: FC<SurveyActionsProps> = ({
  isSurveyGroup,
  survey: { id },
  disableDearchive,
  toSurveyDetail,
  t,
}) => {
  const { addToast } = useToast();
  const [dearchiveSurvey, { loading }] = useSetSurveyArchivedMutation({
    variables: {
      surveyId: id,
      reverse: true,
    },
    onCompleted: () => addToast(t('dearchiveSurveySuccess')),
    onError: () => addToast(t('dearchiveSurveyFailed')),
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(getUserQuery) || '', getOperationName(getSurvey) || ''],
  });
  const [dearchiveSurveyGroup, { loading: loadingGroup }] = useSetSurveyGroupArchivedMutation({
    variables: {
      surveyGroupId: id,
      reverse: true,
    },
    onCompleted: () => addToast(t('dearchiveSurveySuccess')),
    onError: () => addToast(t('dearchiveSurveyFailed')),
    awaitRefetchQueries: true,
    refetchQueries: [getOperationName(getUserQuery) || '', getOperationName(getSurveyGroup) || ''],
  });

  const dearchive = () => {
    if (isSurveyGroup) {
      dearchiveSurveyGroup();
    } else {
      dearchiveSurvey();
    }
  };

  if (loading || loadingGroup) {
    return <Loading />;
  }

  return (
    <Actions>
      <ButtonLink
        weight={500}
        onClick={() => toSurveyDetail(id)}
        data-cy="admin-edit-survey"
        data-icom={'survey-detail-link'}
      >
        {t('surveyDetail')}
      </ButtonLink>
      <ConditionalWrapper
        condition={disableDearchive}
        wrapper={(children) => <Tooltip title={t('dearchiveTooltip')}>{children}</Tooltip>}
      >
        <Button
          variant="outline-primary"
          size="sm"
          onClick={() => dearchive()}
          disabled={disableDearchive}
          data-icom="btn-survey-dearchive-survey"
          data-cy="btn-survey-dearchive-survey"
        >
          {t('dearchive')}
        </Button>
      </ConditionalWrapper>
    </Actions>
  );
};

const Actions = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: end;
  gap: ${theme.spacing.f};
`;
