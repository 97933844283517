import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { theme, ThemeType } from '../../theme';
import ArrowFull from '../../assets/images/ArrowFull.svg';
export interface IProps {
  disable?: boolean;
  headline: string;
  tagline?: string;
  contentBlock?: string;
  headerIcon?: ReactNode;
  headerText?: string;
  footer?: ReactNode;
  children?: ReactNode;
  icomTag?: string;
  icomHeadlineTag?: string;
  icomTaglineTag?: string;
  cyTag?: string;
}

interface ICardProps {
  withChildren?: boolean;
  withHeader?: boolean;
  withContent?: boolean;
  disable?: boolean;
  theme: ThemeType;
}

interface IContentBlockProps {
  theme: ThemeType;
  isDisabled?: boolean;
}

const Card = styled.div`
  width: 288px;
  height: ${({ withChildren, withContent, withHeader }: ICardProps) =>
    withContent && withHeader ? '268px' : withChildren || withContent ? '221px' : '174px'};
  background: ${({ theme }: ICardProps) => theme.colors.backgroundCover.default};
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  padding: ${({ withHeader }: ICardProps) => (withHeader ? '16px' : '32px')} 32px 32px 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: ${({ theme, disable }: ICardProps) => (disable ? theme.colors.text.disabled : theme.colors.text.primary)};
  position: relative;
  transition: 0.25s ease all;
  cursor: ${({ disable }: ICardProps) => (disable ? 'default' : 'pointer')};
  & path {
    transition: 0.25s ease fill;
    fill: ${({ theme }: ICardProps) => theme.colors.iconText.primary};
  }
  & span {
    transition: 0.25s ease color;
  }
  ${({ theme, disable }: ICardProps) =>
    disable
      ? ''
      : `
    &:hover,
    a:focus-within > & {
      background: #04b7ef;
      box-shadow: 0px 15px 30px -20px rgba(0, 0, 0, 0.5);
      color: ${theme.colors.textInverted.primary};
      & path {
        fill: ${theme.colors.backgroundCover.default};
      }
      & span, & footer, & header * {
        transition: 0.25s color all;
        color: ${theme.colors.textInverted.primary};
      }
    }
  `}
`;

const Info = styled.header`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const Headline = styled.div`
  font-size: ${({ theme }: IContentBlockProps) => theme.typography.heading.small.default.fontSize};
  font-weight: ${({ theme }: IContentBlockProps) => theme.typography.heading.small.default.fontWeight};
  max-height: 62px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const Header = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 24px;
  align-items: center;
  & svg {
    height: 15px;
    view-box: 0 0 15px 22px;
  }
`;

const HeaderText = styled.span<IContentBlockProps>`
  margin-left: 8px;
  color: ${({ isDisabled, theme }: IContentBlockProps) =>
    isDisabled ? theme.colors.text.disabled : theme.colors.text.secondary};
  font-size: ${({ theme }: IContentBlockProps) => theme.typography.body.small && theme.typography.body.small.regular};
`;

const Tagline = styled.div`
  margin-top: 4px;
  color: ${({ isDisabled, theme }: IContentBlockProps) =>
    isDisabled ? theme.colors.text.disabled : theme.colors.text.secondary};
  font-size: ${({ theme }: IContentBlockProps) => theme.typography.body.small.regular.fontSize};
  font-weight: ${({ theme }: IContentBlockProps) => theme.typography.body.small.regular.fontWeight};
`;

const ContentBlock = styled.div`
  font-size: ${({ theme }: IContentBlockProps) => theme.typography.body.small.regular.fontSize};
  font-weight: ${({ theme }: IContentBlockProps) => theme.typography.body.small.regular.fontWeight};
  max-height: 76px;
  overflow: hidden;
  margin-top: 24px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const Footer = styled.footer`
  font-size: ${({ theme }: IContentBlockProps) => theme.typography.body.small.regular.fontSize};
  font-weight: ${({ theme }: IContentBlockProps) => theme.typography.body.small.regular.fontWeight};
  color: ${({ isDisabled, theme }: IContentBlockProps) =>
    isDisabled ? theme.colors.text.disabled : theme.colors.text.secondary};
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px;

  position: absolute;
  width: 28px;
  height: 28px;
  left: 274px;
  top: ${(props: { withHeader?: boolean }) => (props.withHeader ? '72px' : '32px')};

  /* Brand/Primary-Default */

  background: ${theme.colors.brand.primary};

  border: 1px solid ${theme.colors.brand.primary};
  box-sizing: border-box;

  box-shadow: 0px 5px 30px -20px rgba(0, 0, 0, 0.5);
  border-radius: 24px;

  & object {
    width: 14px;
    height: 14px;
  }

  & path {
    fill: #ffffff;
  }
`;

export const LinkCard = ({
  disable,
  headerIcon,
  headerText,
  headline,
  tagline,
  contentBlock,
  footer,
  children,
  icomTag,
  icomHeadlineTag,
  icomTaglineTag,
  cyTag,
}: IProps) => {
  const hasHeader = !!headerIcon && !!headerText;
  return (
    <Card
      withHeader={hasHeader}
      withChildren={!!children}
      disable={disable}
      withContent={!!contentBlock}
      data-icom={icomTag}
      data-cy={cyTag}
      theme={theme}
    >
      {hasHeader && (
        <Header>
          {headerIcon}
          <HeaderText isDisabled={disable} theme={theme}>
            {headerText}
          </HeaderText>
        </Header>
      )}
      <Info>
        <Headline theme={theme} data-icom={icomHeadlineTag}>
          {headline}
        </Headline>
        {tagline && (
          <Tagline isDisabled={disable} data-icom={icomTaglineTag} theme={theme}>
            {tagline}{' '}
          </Tagline>
        )}
        {contentBlock && <ContentBlock theme={theme}>{contentBlock}</ContentBlock>}
        {footer && (
          <Footer isDisabled={disable} theme={theme}>
            {footer}
          </Footer>
        )}
      </Info>
      {children}
      {!disable && (
        <ArrowContainer withHeader={hasHeader}>
          <object type="image/svg+xml" data={ArrowFull} aria-label="arrow" />
        </ArrowContainer>
      )}
    </Card>
  );
};
