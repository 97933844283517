import { ActionMenu, ActionMenuItem, BubbleView, theme, formatText } from '@arnold/common';
import { ReactComponent as ActionMenuIcon } from '@arnold/common/lib/assets/icons/ActionMenuIcon.svg';
import { default as RoutingChat } from '@arnold/common/lib/assets/icons/Routing-chat.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChatPreviewArrow } from '../../assets/images';
import ee from '../../lib/eventEmitter';
import { uniqueId } from '../../lib/helpers';
import FakeInput from './FakeInput';
import FormInput from './FormComponents';
import { filterShownSectionLink, getTranslation, handleQuestionTextChange, isQuestionBubble } from './helpers';
import {
  EditRow,
  SectionLinkView,
  SettingsButton,
  SubsectionArrow,
  SubsectionIcon,
  SubsectionWrap,
  View,
} from './LayoutComponents';
import QuestionChoice from './QuestionChoice';
import SectionLink from './SectionLink';
import SelectRouting from './SelectRouting';
import { isNextSection, QuestionDetail, QuestionMappingByOrder, QuestionSubsections } from './topicStructure';
import { QUESTION_DEFINITION_TYPE } from './types';

type QuestionProps = {
  question: QuestionDetail;
  subsections?: QuestionSubsections;
  mapping: QuestionMappingByOrder;
  sectionOrder: number;
  allowStructureChanges?: boolean;
  updateQuestion: (question: QuestionDetail, parentQuestionId?: string) => void;
  deleteQuestion: (question: QuestionDetail, parentQuestionId?: string) => void;
  deleteQuestionsForChoice: (question: QuestionDetail, choiceIndex: number, textBubbleIndex?: number) => void;
  focusedQuestionId?: string | null;
  displayAddQuestionForm: (questionType: QUESTION_DEFINITION_TYPE, index: number) => void;
  displayAddTellToSubsectionForm: (
    question: QuestionDetail,
    parentQuestionId: string,
    choiceIndex: number,
    subsectionIndex: number,
  ) => void;
  setFocusedQuestion: (questionId: string) => void;
  questionIndex: number;
  isLastInSection: boolean;
  chatBubbleOnlySection: boolean;
  nextSectionStartingQuestion?: QuestionDetail;
  editMode: boolean;
  isInNewSection: boolean;
  languageId: string;
  sectionIndexMapping: { [key: number]: number };
};

const Question = ({
  question,
  sectionOrder,
  isInNewSection,
  subsections,
  mapping,
  editMode,
  updateQuestion,
  allowStructureChanges,
  deleteQuestion,
  deleteQuestionsForChoice,
  focusedQuestionId,
  displayAddQuestionForm,
  displayAddTellToSubsectionForm,
  setFocusedQuestion,
  questionIndex,
  isLastInSection,
  chatBubbleOnlySection,
  nextSectionStartingQuestion,
  languageId,
  sectionIndexMapping,
}: QuestionProps) => {
  const [t] = useTranslation('topicOverview');

  const filteredSectionLinks = isLastInSection && filterShownSectionLink(question.rules, false, subsections);

  const defaultRules = (filteredSectionLinks || []).filter((rule) => !rule.origValue);

  const translation = getTranslation(question, languageId);

  const selectOptions = translation!.choices || null;

  const hasDefaultForSubsections = subsections
    ? Object.keys(subsections).some(
        (key) =>
          (subsections[key].flat()[subsections[key].flat().length - 1] &&
            !subsections[key].flat()[subsections[key].flat().length - 1].rules.length) ||
          (subsections[key].flat()[subsections[key].flat().length - 1] &&
            subsections[key].flat()[subsections[key].flat().length - 1].rules[0].isFromDefault),
      )
    : false;

  const hasExplicitForSubsections = subsections
    ? Object.keys(subsections).some(
        (key) =>
          subsections[key].flat()[subsections[key].flat().length - 1] &&
          !!subsections[key].flat()[subsections[key].flat().length - 1].rules.length &&
          subsections[key].flat()[subsections[key].flat().length - 1].rules.some((r) => !r.isFromDefault),
      )
    : false;

  const isExplicitRuleForAll =
    !!selectOptions &&
    selectOptions.length ===
      (filteredSectionLinks || []).filter((rule) => rule.origValue !== '').length +
        Object.keys(subsections || {}).length &&
    !hasDefaultForSubsections;
  const isExplicitRuleForSome =
    !!selectOptions &&
    ((filteredSectionLinks || []).some((rule) => rule.origValue !== '') ||
      (!!Object.keys(subsections || {}).length && !hasExplicitForSubsections));
  useEffect(() => {
    if (
      editMode &&
      (filteredSectionLinks || []).find((rule) => rule.origValue === '') === undefined &&
      !isExplicitRuleForAll &&
      isLastInSection &&
      !!selectOptions
    ) {
      const newRules = question.rules.slice() || [];

      const nextQuestion = {
        index: nextSectionStartingQuestion?.index || null,
        __typename: nextSectionStartingQuestion?.__typename || QUESTION_DEFINITION_TYPE.tell,
        id: nextSectionStartingQuestion?.id || '',
      };

      updateQuestion({
        ...question,
        rules: [
          ...newRules,
          {
            id: uniqueId(),
            nextQuestion,
            operator: null,
            order: newRules.length + 1,
            origValue: '',
            __typename: 'RangeRule',
            isFromDefault: null,
          },
        ],
      });

      Object.values(subsections || {}).forEach((subsection) => {
        const subQuestion = subsection.flat()[subsection.flat().length - 1];
        if (subQuestion && (subQuestion.rules.length === 0 || subQuestion.rules[0].isFromDefault)) {
          updateQuestion({
            ...subQuestion,
            rules: [
              {
                id: uniqueId(),
                nextQuestion,
                operator: null,
                order: 1,
                origValue: '',
                __typename: 'RangeRule',
                isFromDefault: true,
              },
            ],
          });
        }
      });
    } else if (editMode && isExplicitRuleForAll && isLastInSection && !hasDefaultForSubsections) {
      const newRules = question.rules.filter((rule) => rule.origValue !== '');

      updateQuestion({
        ...question,
        rules: [...newRules],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode, isExplicitRuleForAll]);

  const editQuestion = () => {
    ee.emitModalQuestionSettings(question, (newQuestion) => {
      updateQuestion(newQuestion);
    });
  };

  const getAddActionItems = () => {
    const items: ActionMenuItem[] = [];
    if (!questionIndex) {
      items.push({
        label: t('actionMenu:addChatBubbleAbove'),
        action: () => displayAddQuestionForm(QUESTION_DEFINITION_TYPE.tell, questionIndex),
      });
    }
    if (chatBubbleOnlySection) {
      items.push(
        ...[
          {
            label: t('actionMenu:addSelect'),
            action: () => displayAddQuestionForm(QUESTION_DEFINITION_TYPE.select, questionIndex + 1),
          },
          {
            label: t('actionMenu:addFreeText'),
            action: () => displayAddQuestionForm(QUESTION_DEFINITION_TYPE.freetext, questionIndex + 1),
          },
        ],
      );
    }

    if (!isLastInSection || isQuestionBubble(question)) {
      items.push({
        label: t('actionMenu:addChatBubble'),
        action: () => displayAddQuestionForm(QUESTION_DEFINITION_TYPE.tell, questionIndex + 1),
      });
    }

    items.push({
      label: t('delete'),
      action: () => deleteQuestion(question),
    });

    return items;
  };

  const subsectionRule = subsections && subsections.default && subsections.default.flat()[0].rules[0];
  const filteredSectionAndSubsectionLinks =
    subsectionRule &&
    !isNextSection(question.id, subsectionRule.nextQuestion.id, mapping) &&
    Array.isArray(filteredSectionLinks)
      ? [...filteredSectionLinks, subsectionRule]
      : filteredSectionLinks;
  const subsectionKeys = (subsections && Object.keys(subsections)) || [];
  const hasSubsections = !!subsectionKeys.length;
  const newChoice = {
    text: '',
    uid: useMemo(() => uniqueId(), []),
  };

  const isForAll =
    !!selectOptions &&
    (filteredSectionLinks || []).filter((rule) => rule.origValue !== '').length === 0 &&
    Object.values(subsections || {}).filter((subsection) => {
      const subQuestion = subsection.flat()[subsection.flat().length - 1];
      return subQuestion && subQuestion.rules.length && subQuestion.rules[0].isFromDefault;
    }).length === 0;

  return (
    <motion.div
      id={question.id}
      transition={{ type: 'easeInOut', duration: 0.25 }}
      animate={{ height: 'auto', overflow: 'visible' }}
      initial={editMode ? { height: 0, overflow: 'hidden' } : { height: 'auto' }}
      exit={{ height: 0, overflow: 'hidden' }}
    >
      <EditRow>
        <View shouldGrow={true} style={{ overflow: 'hidden' }}>
          {editMode ? (
            <FormInput
              value={translation!.text}
              required
              requiredErrorMessage={t('emptyInputWarning')}
              focused={question.id === focusedQuestionId}
              onChange={handleQuestionTextChange(question, updateQuestion, languageId)}
              onFocus={() => setFocusedQuestion(question.id)}
            />
          ) : (
            <BubbleView backgroundColor={theme.colors.backgroundCover.default} left={true}>
              {formatText(translation!.text)}
            </BubbleView>
          )}
        </View>
        {editMode && allowStructureChanges && (
          <>
            {![QUESTION_DEFINITION_TYPE.tell, QUESTION_DEFINITION_TYPE.ending].includes(question.__typename as any) && (
              <SettingsButton onClick={editQuestion} />
            )}
            <ActionMenu toggleIcon={<ActionMenuIcon />} items={getAddActionItems()} />
          </>
        )}
      </EditRow>
      <FakeInput question={question} editMode={editMode} />
      {((!selectOptions && hasSubsections) ||
        (!selectOptions &&
          filteredSectionAndSubsectionLinks &&
          !editMode &&
          filteredSectionAndSubsectionLinks.length > 0)) && (
        <SubsectionWrap>
          {editMode ? <SubsectionIcon src={RoutingChat} /> : <SubsectionArrow src={ChatPreviewArrow} />}
          <div style={{ width: '100%' }}>
            {hasSubsections &&
              subsectionKeys.map((key) => {
                return (
                  subsections &&
                  subsections[key].flat().map((subsection) => {
                    const translation =
                      subsection.translations.find((t) => t.language.id === languageId) || subsection.translations[0];
                    return (
                      <SectionLinkView key={key}>
                        {editMode ? (
                          <div style={{ flex: 1 }}>
                            <FormInput
                              value={translation.text}
                              required
                              requiredErrorMessage={t('emptyInputWarning')}
                              onChange={handleQuestionTextChange(subsection, updateQuestion, languageId, question.id)}
                              onFocus={() => setFocusedQuestion(question.id)}
                            />
                            {allowStructureChanges &&
                              filteredSectionAndSubsectionLinks &&
                              filteredSectionAndSubsectionLinks.map((rule) => (
                                <SelectRouting
                                  key={`link-${question.id}-${rule.nextQuestion.id}`}
                                  sectionOrder={sectionOrder}
                                  sectionIndexMapping={sectionIndexMapping}
                                  rule={rule}
                                  isInNewSection={isInNewSection}
                                  question={subsection}
                                  updateQuestion={updateQuestion}
                                  parentQuestionId={question.id}
                                  showEndOfChat={true}
                                />
                              ))}
                          </div>
                        ) : (
                          <BubbleView backgroundColor={theme.colors.backgroundCover.default} left={true}>
                            {formatText(translation.text)}
                          </BubbleView>
                        )}
                      </SectionLinkView>
                    );
                  })
                );
              })}
            {filteredSectionAndSubsectionLinks &&
              !editMode &&
              filteredSectionAndSubsectionLinks.map((rule) => (
                <SectionLink
                  key={`link-${question.id}-${rule.nextQuestion.id}`}
                  rule={rule}
                  mapping={mapping}
                  questionId={question.id}
                />
              ))}
          </div>
        </SubsectionWrap>
      )}
      {filteredSectionLinks &&
        editMode &&
        !selectOptions &&
        allowStructureChanges &&
        filteredSectionLinks.map((rule) => {
          return (
            <View key={`view-${question.id}-${rule.nextQuestion.id}`}>
              <SelectRouting
                key={`link-${question.id}-${rule.nextQuestion.id}`}
                sectionOrder={sectionOrder}
                sectionIndexMapping={sectionIndexMapping}
                rule={rule}
                isInNewSection={isInNewSection}
                question={question}
                updateQuestion={updateQuestion}
                parentQuestionId={question.id}
                showEndOfChat={true}
              />
            </View>
          );
        })}
      <AnimatePresence>
        {selectOptions &&
          [QUESTION_DEFINITION_TYPE.select].includes(question.__typename as any) &&
          (editMode && allowStructureChanges ? [...selectOptions, newChoice] : selectOptions).map(
            (choice, choiceIndex) => {
              return (
                <QuestionChoice
                  isInNewSection={isInNewSection}
                  key={choice.uid}
                  sectionOrder={sectionOrder}
                  choice={choice}
                  subsections={subsections}
                  updateQuestion={updateQuestion}
                  allowStructureChanges={allowStructureChanges}
                  deleteQuestionsForChoice={deleteQuestionsForChoice}
                  displayAddTellToSubsectionForm={displayAddTellToSubsectionForm}
                  nextSectionStartingQuestion={nextSectionStartingQuestion}
                  choiceIndex={choiceIndex}
                  mapping={mapping}
                  isNew={choiceIndex >= selectOptions.length}
                  languageId={languageId}
                  editMode={editMode}
                  question={question}
                  sectionIndexMapping={sectionIndexMapping}
                  focusedQuestionId={focusedQuestionId}
                />
              );
            },
          )}
        {!!defaultRules &&
          !editMode &&
          !!selectOptions &&
          defaultRules.map((defaultRule) => (
            <SectionLink
              key={`link-${question.id}-${defaultRule.id}`}
              rule={defaultRule}
              mapping={mapping}
              questionId={question.id}
              isDefault={true}
              isForAll={isForAll}
            />
          ))}
        {!!defaultRules &&
          editMode &&
          !!selectOptions &&
          allowStructureChanges &&
          defaultRules.map((defaultRule) => (
            <View key={`link-${question.id}-${defaultRule.nextQuestion.id}}`}>
              <SelectRouting
                sectionOrder={sectionOrder}
                sectionIndexMapping={sectionIndexMapping}
                rule={defaultRule}
                question={question}
                updateQuestion={updateQuestion}
                subsections={subsections}
                showEndOfChat={true}
                isInNewSection={isInNewSection}
                isForRest={isExplicitRuleForSome}
              />
            </View>
          ))}
        {defaultRules.length === 0 &&
          editMode &&
          allowStructureChanges &&
          isLastInSection &&
          ((!selectOptions && !subsections) || (!!selectOptions && !isExplicitRuleForAll)) && (
            <View key={`link-${question.id}`}>
              <SelectRouting
                key={`link-${question.id}`}
                sectionOrder={sectionOrder}
                sectionIndexMapping={sectionIndexMapping}
                question={question}
                updateQuestion={updateQuestion}
                subsections={subsections}
                disabled={isExplicitRuleForAll}
                isInNewSection={isInNewSection}
                showEndOfChat={true}
              />
            </View>
          )}
      </AnimatePresence>
    </motion.div>
  );
};

export default Question;
