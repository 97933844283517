import styled from '@emotion/styled/macro';
import { theme } from '@arnold/common';
import React from 'react';

const RequiredComponent = styled.span`
  color: ${theme.colors.emotionDanger.default};
  margin-left: ${theme.spacing.c};
`;

export const Required = () => {
  return <RequiredComponent>*</RequiredComponent>;
};

export default Required;
