import styled from '@emotion/styled';
import { useState } from 'react';
import { Toast, ToastBody } from 'react-bootstrap';
import { IThemeProps } from '../../theme';
import { CleanButton } from '../buttons';

const StyledToast = styled(Toast)`
  background: #3f3f3f;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.01);
  border-radius: 6px;
  align-items: center;
  display: flex;
  flex-direction: row;
  min-width: 100px;
  padding: 16px 32px;
  border: 0px;
  max-width: 1080px;
  z-index: 10000000;
`;

const StyledToastBody = styled(ToastBody)`
  color: #ffffff;
  margin: 0px 0px;
  font-size: 15px;
  padding: 0px;
  z-index: 1000000;
`;

const ToastAction = styled(CleanButton)`
  margin-left: 16px;
  text-decoration: none;
  color: ${({ theme }: IThemeProps) => theme && theme.colors.actionPrimary.default};
  font-weight: 500;
  z-index: 1000000;
`;

export interface IToastProps {
  text: string;
  show: boolean;
  delay?: number;
  actionText?: string;
  actionCallback?: () => void;
  id: number;
}

export const ArnoldToast = (props: IToastProps) => {
  const [show, setShow] = useState(true);
  const onActionButtonClick = () => {
    setShow(false);
    if (props.actionCallback) {
      props.actionCallback();
    }
  };
  return (
    <StyledToast
      onClose={() => setShow(false)}
      delay={props.delay || 10000}
      show={show && props.show}
      autohide={true}
      key={props.id}
    >
      <StyledToastBody>
        <span>{props.text}</span>
        {props.actionText && <ToastAction onClick={onActionButtonClick}>{props.actionText}</ToastAction>}
      </StyledToastBody>
    </StyledToast>
  );
};
