import { BodyMedium, DateTimeInput, LinkButton, theme, usePath } from '@arnold/common';
import styled from '@emotion/styled';
import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SurveyQuery } from '../../generated/hooks';
import { ApproximateMailSendTimeNote } from '../../screens/SurveyDetail/SurveyScheduling';
import { Card } from '../Common';
import { ButtonLink } from '../StyledComponents';

interface IProps<T> {
  notStarted: boolean;
  allowEditEnd?: boolean;
  displayReopenButton?: boolean;
  survey?: SurveyQuery['survey'] | null;
  formValues: T;
  setFormValues: React.Dispatch<React.SetStateAction<T>>;
  showNote?: boolean;
  isPublic?: boolean;
  isCompleted?: boolean;
}

interface FormMandatoryField {
  sendAt: Date;
  endsAt?: Date;
  reminders?: Date[];
  firstReminderAt?: Date;
  secondReminderAt?: Date;
}

const SurveySchedulingInput = <T extends FormMandatoryField>({
  notStarted,
  survey,
  formValues,
  setFormValues,
  allowEditEnd,
  displayReopenButton,
  showNote,
  isPublic,
  isCompleted,
}: IProps<T>) => {
  const { reopenSurveyPath } = usePath();
  const { t } = useTranslation('surveyForm');
  const currentTime = new Date().getTime();

  const isSendAtDisabled = !!survey && !notStarted && currentTime >= formValues.sendAt.getTime();
  const isEndsAtDisabled =
    !!survey &&
    !notStarted &&
    !allowEditEnd &&
    formValues.endsAt &&
    currentTime >= formValues.endsAt.getTime() &&
    (!survey.endsAt || new Date(survey.endsAt).getTime() === formValues.endsAt.getTime());

  return (
    <>
      <Card margin={`0 0 ${theme.spacing.j} 0`} data-icom={'form-survey-dates'}>
        <DatesWrapper>
          <FormGroup>
            <DateTimeInput
              label={t('weekPicker:surveyStart') + '*'}
              value={formValues.sendAt}
              allowedFrom={new Date()}
              dataCy="survey-start"
              onDateChange={(date) => {
                setFormValues((prevValues) => {
                  return { ...prevValues, sendAt: date ? new Date(date) : new Date() };
                });
              }}
              disabled={isSendAtDisabled}
              errorText={
                formValues.endsAt && formValues.sendAt.getTime() > formValues.endsAt?.getTime()
                  ? t('weekPicker:surveyStartBeforeEnd')
                  : null
              }
            />
          </FormGroup>
          {!isPublic && (
            <RemindersWrapper>
              <RemindersWrapperArrow>
                <svg
                  width="5.7735009"
                  height="5"
                  viewBox="0 0 5.7735009 5"
                  fill="none"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M 2.886751,5 5.773501,0 H 0 Z" fill="#e7e7e7" />
                </svg>
              </RemindersWrapperArrow>

              {formValues.reminders?.map((reminder, index) => (
                <FormGroup key={index} className="d-flex align-items-end">
                  <DateTimeInput
                    label={t('weekPicker:noReminder', {
                      count: index + 1,
                    })}
                    onDelete={() => {
                      setFormValues((prevValues) => {
                        const reminders = prevValues.reminders ? [...prevValues.reminders] : [];
                        reminders.splice(index, 1);
                        return { ...prevValues, reminders };
                      });
                    }}
                    value={reminder}
                    allowedFrom={formValues.sendAt}
                    allowedTo={formValues.endsAt}
                    dataCy={`survey-reminder-${index}`}
                    onDateChange={(date) => {
                      setFormValues((prevValues) => {
                        const reminders = prevValues.reminders ? [...prevValues.reminders] : [];
                        reminders[index] = date ? new Date(date) : new Date(); // TODO:
                        return { ...prevValues, reminders };
                      });
                    }}
                    dateInputClassName="narrowDateInput"
                    disabled={
                      isEndsAtDisabled ||
                      allowEditEnd ||
                      (!!survey &&
                        !notStarted &&
                        currentTime >= reminder?.getTime() &&
                        survey?.reminders?.[index] &&
                        reminder?.getTime() === new Date(survey.reminders[index]?.reminderAt).getTime())
                    }
                    defaultMonth={formValues.sendAt}
                    errorText={
                      formValues.endsAt &&
                      reminder &&
                      (reminder.getTime() > formValues.endsAt?.getTime() ||
                        reminder.getTime() < formValues.sendAt.getTime())
                        ? t('weekPicker:reminderBetweenStartAndEnd')
                        : reminder &&
                            reminder.getTime() < currentTime &&
                            (!survey?.reminders?.[index] ||
                              (survey?.reminders?.[index] &&
                                reminder?.getTime() !== new Date(survey.reminders[index]?.reminderAt).getTime()))
                          ? t('weekPicker:reminderInFuture')
                          : null
                    }
                  />
                </FormGroup>
              ))}
              {(formValues.reminders?.length ?? 0) < 3 && !isCompleted && (
                <ButtonLink
                  className="mb-6 mt-4 ml-4"
                  type="button"
                  onClick={() => {
                    setFormValues((prevValues) => {
                      return { ...prevValues, reminders: [...(prevValues.reminders || []), null] };
                    });
                  }}
                >
                  <BodyMedium style={{ color: 'inherit' }}>{t('weekPicker:addReminder')}</BodyMedium>
                </ButtonLink>
              )}
            </RemindersWrapper>
          )}
          <FormGroup>
            <DateTimeInput
              label={t('weekPicker:surveyEnd')}
              value={formValues.endsAt ?? null}
              allowedFrom={formValues.sendAt}
              defaultMonth={formValues.sendAt}
              onDateChange={(date) => {
                setFormValues((prevValues) => {
                  return { ...prevValues, endsAt: date ? new Date(date) : new Date() };
                });
              }}
              onDelete={
                isPublic && formValues.endsAt && !isCompleted
                  ? () => {
                      setFormValues((prevValues) => {
                        return { ...prevValues, endsAt: null };
                      });
                    }
                  : undefined
              }
              emptyPlaceholder={t('surveyForm:withoutEndDate')}
              disabled={isEndsAtDisabled}
              dataCy="survey-end"
              errorText={
                formValues.endsAt && formValues.sendAt.getTime() > formValues.endsAt?.getTime()
                  ? t('weekPicker:surveyStartBeforeEnd')
                  : formValues.endsAt &&
                      formValues.endsAt.getTime() < currentTime &&
                      (!survey?.endsAt || new Date(survey.endsAt).getTime() !== formValues.endsAt.getTime())
                    ? t('weekPicker:endInFuture')
                    : null
              }
            />
          </FormGroup>
        </DatesWrapper>
        {displayReopenButton && (
          <LinkButton
            onClick={() => {
              reopenSurveyPath.toFunc(survey?.id ?? '');
            }}
          >
            {t('reopenScreen:reopen')}
          </LinkButton>
        )}
        {showNote && <ApproximateMailSendTimeNote />}
      </Card>
    </>
  );
};

export default SurveySchedulingInput;

const DatesWrapper = styled.div``;

const RemindersWrapper = styled.div`
  border-left: 1px solid ${theme.colors.borderMain.default};
  padding-left: ${theme.spacing.g};
  margin-bottom: ${theme.spacing.h};
  position: relative;

  & .narrowDateInput {
    width: 224px;
  }
`;

const RemindersWrapperArrow = styled.div`
  position: absolute;
  left: -4.5px;
  bottom: 0;
  height: 10px;

  & svg {
    width: 8px;
    height: 8px;
  }
`;
