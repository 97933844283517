import entropy from 'ideal-password';
import commonPasswords from './commonPasswords';

export const validatePassword = (password: string, username?: string) => {
  if (password.length < 8) {
    return false;
  }

  entropy.addCommonPasswords(commonPasswords);
  if (username && username.length > 7) {
    entropy.addCommonPasswords(username);
  }
  const passwordEntropy = entropy(password);

  return (
    passwordEntropy.length > 1 && // if found in common-passwords set, length is set to 1
    passwordEntropy.sets.includes('number') &&
    passwordEntropy.sets.some((set) => set.match(/^latin-*/))
  );
};
