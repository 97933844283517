import {
  BodyMedium,
  BodySmall,
  CallingCodeInput,
  Checkbox,
  FormSelect,
  ToggleButton,
  Tooltip,
  createPhoneNumberWithCallingCode,
  theme,
} from '@arnold/common';
import { ProductCode } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { format } from 'date-fns';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { isEmpty, omit } from 'ramda';
import { useState } from 'react';
import { Button, Card, Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { ExternalPerson, TeamioIcon } from '../../../assets/images';
import {
  ContactInput,
  ContactSource,
  ContactType,
  CreateRespondentMutationFn,
  OrganizationQuery,
  OrganizationTeamsQuery,
  RespondentInput,
  SurveyGroupsQuery,
  SurveysQuery,
  UpdateRespondentInput,
  UpdateRespondentMutationFn,
  useVerifyPhoneNumbersMutation,
} from '../../../generated/hooks';
import { sortLanguages } from '../../../lib/common';
import ee from '../../../lib/eventEmitter';
import { getTeamLeaders } from '../../../lib/helpers/teams';
import { FormErrorFeedback, FormGroupLabel, FormLabelStyled, FormRadioInput } from '../../Common';
import { SurveyGroupSurveysData } from '../../Modals/AddSGRespondentToOngoingSurveys';
import { RedText } from '../../StyledComponents';
import OnboardingSelect from './OnboardingSelect';
import ProcessSurveySelect, { SurveyGroupEntries } from './ProcessSurveySelect';
import StandaloneSurveySelect from './StandaloneSurveySelect';
import { NO_SUPERVISOR, TeamLeaderField } from './TeamLeaderField';
import { createValidationSchema, handlePhoneBlur, handlePhoneChange } from './utils';

const ToggleContainerWithText = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.f};
  &:not(:last-child) {
    border-bottom: ${theme.spacing.a} solid ${theme.colors.borderSeparator.default};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: self-end;
`;

type Organization = NonNullable<OrganizationQuery['organization']>;
type Team = NonNullable<OrganizationTeamsQuery['organizationTeams']>[0];
type Member = Team['members'][0];

export type FormProps = {
  respondent?: Member;
  workEmail?: string;
  team?: Team;
  organizationTeams: Team[];
  personalEmail?: string;
  phone?: string;
  countryCallingCode?: string;
  preferredContactType?: string;
  teamId?: number;
  language?: string;
  onboardings?: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  processSurveys?: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  disabled?: boolean;
  saveRespondent: UpdateRespondentMutationFn | CreateRespondentMutationFn;
  organization: Organization;
  surveys?: NonNullable<SurveysQuery['user']['organization']>['surveys'];
};

const RespondentForm = ({
  workEmail,
  personalEmail,
  phone,
  countryCallingCode,
  saveRespondent,
  respondent,
  team,
  organizationTeams,
  organization,
  onboardings,
  surveys,
  processSurveys,
}: FormProps) => {
  const { t } = useTranslation(['updateRespondent', 'addRespondent']);
  const [unverifiedPhoneNumber, setUnverifiedPhoneNumber] = useState(false);
  const [standaloneSurveysToAdd, setStandaloneSurveysToAdd] = useState<string[]>([]);
  const [processSurveysToAdd, setProcessSurveysToAdd] = useState<SurveyGroupEntries>(
    processSurveys?.reduce(
      (acc, sg) => ({
        ...acc,
        [sg.id]: { surveyGroupId: sg.id, addedOn: '', checked: false },
      }),
      {},
    ) || {},
  );
  const [onboardingSurveysToAdd, setOnboardingSurveysToAdd] = useState<SurveyGroupEntries>(
    onboardings?.reduce(
      (acc, sg, index) => ({
        ...acc,
        [sg.id]: { surveyGroupId: sg.id, addedOn: '', checked: index === 0 },
      }),
      {},
    ) || {},
  );
  const [onboardingOngoingSurveys, setOnboardingOngoingSurveys] = useState<
    Pick<SurveyGroupSurveysData, 'id' | 'topic'>[]
  >([]);
  const [verifyPhoneNumbers] = useVerifyPhoneNumbersMutation();

  const RespondentFormValidationSchema = createValidationSchema(t);

  const getContacts = (values: FormikValues) => {
    const contacts: ContactInput[] = [];
    if (workEmail !== values.primaryEmail) {
      contacts.push({
        source: ContactSource.Primary,
        contactType: ContactType.Email,
        value: values.primaryEmail,
      });
    }
    if ((personalEmail || values.secondaryEmail) && personalEmail !== values.secondaryEmail) {
      contacts.push({
        source: ContactSource.Respondent,
        contactType: ContactType.Email,
        value: values.secondaryEmail || null,
      });
    }
    if ((phone || values.phone) && (phone !== values.phone || countryCallingCode !== values.callingCode)) {
      contacts.push({
        source: ContactSource.Respondent,
        contactType: ContactType.Sms,
        value: values.phone ? createPhoneNumberWithCallingCode(values.phone, values.callingCode) : null,
      });
    }

    return contacts;
  };

  const createAddInput = (values: FormikValues, runOngoingSteps?: string[]) => {
    const newInput: RespondentInput = {
      firstname: values.firstname,
      surname: values.surname,
      disabled: values.disabled,
      preferredContactType: values.preferredContactType,
    };

    newInput.organizationId = organization.id;

    newInput.language = values.language === 'unset' || organization.languages.length <= 1 ? null : values.language;

    newInput.teamId = values.teamId === NO_SUPERVISOR ? null : (newInput.teamId = values.teamId);

    if (values.primaryEmail) {
      newInput.email = values.primaryEmail;
    }

    const contacts = getContacts(values);

    if (contacts.length > 0) {
      newInput.contacts = contacts;
    }
    newInput.surveyGroups = [];
    if (!values.disabled && values.addToSurveys && values.addToOnboarding) {
      newInput.surveyGroups.push(
        ...Object.values(onboardingSurveysToAdd)
          .filter((sg) => sg.checked)
          .map(({ checked, ...sg }) => ({ ...sg, runOngoingSteps })),
      );
    }
    if (!values.disabled && values.addToSurveys) {
      newInput.surveyGroups.push(
        ...Object.values(processSurveysToAdd)
          .filter((sg) => sg.checked)
          .map(({ checked, ...sg }) => sg),
      );
    }
    if (!values.disabled && values.addToSurveys && !!standaloneSurveysToAdd.length) {
      newInput.surveyIds = standaloneSurveysToAdd;
    }

    return newInput;
  };

  const createUpdateInput = (values: FormikValues) => {
    const updateInput: UpdateRespondentInput = {};

    if (respondent) {
      Object.keys(omit(['language'], values)).forEach((key) => {
        if (
          (respondent[key as keyof Member] || respondent[key as keyof Member] === '' || key === 'disabled') &&
          respondent[key as keyof Member] !== values[key]
        ) {
          updateInput[key as keyof UpdateRespondentInput] = values[key];
        }
      });

      if (values.language === 'unset' && respondent.language) {
        updateInput.language = null;
      } else if (
        values.language !== 'unset' &&
        (!respondent.language || values.language !== respondent.language.code)
      ) {
        updateInput.language = values.language;
      }

      if (values.teamId !== NO_SUPERVISOR && (!team || values.teamId !== team.id)) {
        updateInput.teamId = values.teamId;
      } else if (values.teamId === NO_SUPERVISOR && team) {
        updateInput.teamId = null;
      }

      const contacts = getContacts(values);

      if (contacts.length > 0) {
        updateInput.contacts = contacts;
      }

      return updateInput;
    }
  };

  const handleAddRespondentToOnboardingOngoingSurveys = (
    values: FormikValues,
    { setSubmitting }: FormikHelpers<any>,
  ) => {
    return async (runOngoingSteps: string[], addedOn: Date) => {
      try {
        setSubmitting(true);
        if (!respondent || !respondent.id) {
          await (saveRespondent as CreateRespondentMutationFn)({
            variables: {
              input: createAddInput(values, runOngoingSteps),
            },
          });
        }
      } catch (e) {
        setSubmitting(false);
      }
    };
  };

  const handleSaveRespondent = () => async (values: FormikValues, formikHelpers: FormikHelpers<any>) => {
    if (!respondent && values.addToSurveys && values.addToOnboarding && onboardingOngoingSurveys.length) {
      ee.emitAddSGRespondentToOngoingSurveys(
        handleAddRespondentToOnboardingOngoingSurveys(values, formikHelpers),
        new Date(Object.values(onboardingSurveysToAdd).filter((sg) => sg.checked)[0].addedOn),
        onboardingOngoingSurveys,
      );
    } else {
      const { setSubmitting } = formikHelpers;
      try {
        setSubmitting(true);
        if (!respondent || !respondent.id) {
          await (saveRespondent as CreateRespondentMutationFn)({
            variables: {
              input: createAddInput(values),
            },
          });
        }

        const updatedInput = createUpdateInput(values);

        if (respondent && respondent.id && updatedInput) {
          await (saveRespondent as UpdateRespondentMutationFn)({
            variables: {
              id: respondent.id,
              input: updatedInput,
            },
          });
        }
        setSubmitting(false);
      } catch (e) {
        setSubmitting(false);
      }
    }
  };

  const teams = getTeamLeaders(organizationTeams, respondent?.id);

  const createdAt = respondent?.createdAt && format(new Date(respondent.createdAt), 'dd.MM.yyyy HH:mm');
  const imported: Record<string, { icon: string; tooltipText: string; text: string }> = {
    TEAMIO: {
      icon: TeamioIcon,
      tooltipText: t('addedAtTooltip', {
        date: createdAt,
      }),
      text: t('addedByTeamio'),
    },
    AUTOMATIC_IMPORT: {
      icon: ExternalPerson,
      tooltipText: t('addedAtTooltip', {
        date: createdAt,
      }),
      text: t('automaticImported'),
    },
  };

  const numberOfUsedParticipants = organization.activeRespondentsInCurrentPeriodCount ?? 0;
  const numberOfMaxParticipants = organization.productVersion?.licenceCount ?? 0;
  const isTeamioOnboarding = organization.productVersion?.product === ProductCode.Teamio_Arnold;

  return (
    <Formik
      initialValues={{
        firstname: respondent && respondent.firstname,
        surname: respondent && respondent.surname,
        primaryEmail: workEmail || '',
        secondaryEmail: personalEmail || '',
        phone: phone || '',
        callingCode: countryCallingCode || '',
        preferredContactType: (respondent && respondent.preferredContactType) || ContactType.Email,
        teamId: team ? (team.leader ? team.id : NO_SUPERVISOR) : null,
        language: (respondent && respondent.language && respondent.language.code) || 'unset',
        disabled: respondent && respondent.disabled,
        addToSurveys: false,
        addToOnboarding: false,
      }}
      validationSchema={RespondentFormValidationSchema}
      onSubmit={handleSaveRespondent()}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        isSubmitting,
        setValues,
      }) => (
        <Form onSubmit={handleSubmit} data-icom={'form-respondent'}>
          <Card className="p-8">
            <Card.Body className="p-0">
              <Row>
                <FormGroup as={Col} sm={4}>
                  <FormGroupLabel>{t('teamsScreen:name')}</FormGroupLabel>
                  <FormControl
                    type="text"
                    name="firstname"
                    value={values.firstname || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.firstname && !!errors.firstname}
                  />
                  <FormErrorFeedback error={errors.firstname} />
                  {respondent?.originator && imported[respondent.originator] && (
                    <div className="mt-4">
                      <Tooltip title={imported[respondent.originator].tooltipText}>
                        <img src={imported[respondent.originator].icon} alt="Teamio" className="mr-4" />
                      </Tooltip>
                      <FormLabelStyled>{imported[respondent.originator].text}</FormLabelStyled>
                    </div>
                  )}
                </FormGroup>
                <FormGroup as={Col} sm={4}>
                  <FormGroupLabel className="form-group__label">{t('teamsScreen:surname')}</FormGroupLabel>
                  <FormControl
                    type="text"
                    name="surname"
                    value={values.surname || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.surname && !!errors.surname}
                  />
                  <FormErrorFeedback error={errors.surname} />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup as={Col} sm={8}>
                  <FormGroupLabel>{t('teamsScreen:workEmail')} (Arnold ID)</FormGroupLabel>
                  <FormControl
                    type="text"
                    name="primaryEmail"
                    value={values.primaryEmail || ''}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={touched.primaryEmail && !!errors.primaryEmail}
                  />
                  <FormErrorFeedback error={errors.primaryEmail} />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup as={Col} sm={8}>
                  <FormGroupLabel>{t('teamsScreen:personalEmail')}</FormGroupLabel>
                  <FormControl
                    type="email"
                    name="secondaryEmail"
                    value={values.secondaryEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isInvalid={!!errors.secondaryEmail}
                  />
                  <FormErrorFeedback error={errors.secondaryEmail} />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup as={Col} sm={4}>
                  <FormGroupLabel>{t('callingCode')}</FormGroupLabel>
                  <CallingCodeInput
                    callingCode={values.callingCode || ''}
                    onChange={(callingCode: string) => {
                      setValues(() => ({ ...values, callingCode }));
                    }}
                    isInvalid={!!errors.callingCode}
                  />
                  <FormErrorFeedback error={errors.callingCode} />
                </FormGroup>
                <FormGroup as={Col} sm={4}>
                  <FormGroupLabel>{t('teamsScreen:phone')}</FormGroupLabel>
                  <FormControl
                    type="tel"
                    name="phone"
                    value={values.phone}
                    onChange={handlePhoneChange(handleChange)}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handlePhoneBlur(values.callingCode, e.target.value, (internationalPhoneNumber: string) =>
                        verifyPhoneNumbers({
                          variables: { phoneNumbers: [internationalPhoneNumber] },
                          onCompleted: (data) => {
                            setUnverifiedPhoneNumber(
                              !data.verifyPhoneNumbers.find((pn) => pn.phoneNumber === internationalPhoneNumber),
                            );
                          },
                          onError: () => {
                            setUnverifiedPhoneNumber(false);
                          },
                        }),
                      )
                    }
                    isInvalid={!!errors.phone || unverifiedPhoneNumber}
                  />
                  <FormErrorFeedback error={errors.phone || (unverifiedPhoneNumber && t('enterValidPhone'))} />
                </FormGroup>
              </Row>
              <Row>
                <FormGroup as={Col} sm={8}>
                  <FormGroupLabel>{t('sendSurveyTo')}</FormGroupLabel>
                  <FormGroup className="form-check-group">
                    <FormRadioInput
                      name="preferredContactType"
                      value={ContactType.Email}
                      clicked={() => {
                        setFieldValue('preferredContactType', ContactType.Email);
                      }}
                      changed={handleChange}
                      onBlur={handleBlur}
                      defaultChecked={values.preferredContactType === ContactType.Email}
                      label={t('teamsScreen:email')}
                    />
                    <FormRadioInput
                      name="preferredContactType"
                      value={ContactType.Sms}
                      clicked={() => {
                        setFieldValue('preferredContactType', ContactType.Sms);
                      }}
                      changed={handleChange}
                      onBlur={handleBlur}
                      defaultChecked={values.preferredContactType === ContactType.Sms}
                      label={t('teamsScreen:phone')}
                      disabled={!values.phone || errors.phone || unverifiedPhoneNumber}
                    />
                  </FormGroup>
                </FormGroup>
              </Row>
              <Row>
                <TeamLeaderField
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  teams={teams}
                  values={values}
                  setFieldValue={setFieldValue}
                  error={errors.teamId}
                />
              </Row>
              {organization.languages.length > 1 && (
                <Row>
                  <FormGroup as={Col} sm={8}>
                    <FormGroupLabel>{t('settings:language')}</FormGroupLabel>
                    <Field
                      component={FormSelect}
                      name="language"
                      options={[
                        { value: 'unset', label: t('surveyLanguage') },
                        ...sortLanguages(
                          organization.languages,
                          new Map(organization.languages.map((l) => [l.code, t(`languageSelector:${l.code}`)])),
                        ).map((language) => ({
                          value: language!.code,
                          label: t(`languageSelector:${language!.code}`),
                        })),
                      ]}
                      defaultValue={values.language}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </Row>
              )}
              <Row>
                <FormGroup as={Col} sm={8}>
                  <ToggleContainerWithText>
                    <div>
                      <BodyMedium margin={`0 0 ${theme.spacing.d} 0`}>{t(`surveyParticipant`)}</BodyMedium>
                      <BodySmall color={`${theme.colors.text.secondary}`}>
                        {t(`surveyParticipantDescription`)}
                      </BodySmall>
                    </div>
                    <ToggleContainer>
                      <ToggleButton
                        dataCy="disabled-toggle"
                        value={!values.disabled}
                        onChange={(disabled: boolean) => {
                          setValues(() => ({ ...values, disabled: !disabled }));
                        }}
                      />
                    </ToggleContainer>
                  </ToggleContainerWithText>
                  {!values.disabled &&
                    (!!onboardings?.length || !!processSurveys?.length || !!surveys?.length) &&
                    !respondent && (
                      <ToggleContainerWithText>
                        <div>
                          <BodyMedium margin={`0 0 ${theme.spacing.d} 0`}>{t(`addToOngoingPlanned`)}</BodyMedium>
                          <BodySmall color={`${theme.colors.text.secondary}`}>
                            {t(`addToOngoingPlannedDescription`)}
                          </BodySmall>
                        </div>
                        <ToggleContainer>
                          <ToggleButton
                            dataCy="add-to-survey-toggle"
                            value={values.addToSurveys}
                            onChange={(addToSurveys: boolean) => {
                              setValues(() => ({ ...values, addToSurveys }));
                            }}
                          />
                        </ToggleContainer>
                      </ToggleContainerWithText>
                    )}
                </FormGroup>
              </Row>
              {!respondent &&
                !values.disabled &&
                (!!onboardings?.length || !!surveys?.length || !!processSurveys?.length) && (
                  <Row>
                    <FormGroup as={Col} sm={12}>
                      <FormGroup className="form-check-group">
                        {values.addToSurveys && (
                          <>
                            <div className="ml-6">
                              {!!onboardings?.length && (
                                <>
                                  <FormGroup>
                                    <Checkbox
                                      selected={values.addToOnboarding}
                                      onToggle={(e) => {
                                        setValues(() => ({ ...values, addToOnboarding: !values.addToOnboarding }));
                                      }}
                                      text={t('addToOnboarding')}
                                      dataCy="add-to-onboarding-input"
                                    />
                                  </FormGroup>
                                  {values.addToOnboarding && (
                                    <OnboardingSelect
                                      productHistory={organization.productVersionHistory}
                                      onboardings={onboardings}
                                      onboardingSurveysToAdd={onboardingSurveysToAdd}
                                      setOnboardingSurveysToAdd={setOnboardingSurveysToAdd}
                                      setOnboardingOngoingSurveys={setOnboardingOngoingSurveys}
                                    />
                                  )}
                                </>
                              )}
                            </div>
                            {!!surveys?.length && (
                              <StandaloneSurveySelect
                                surveys={surveys}
                                standaloneSurveysToAdd={standaloneSurveysToAdd}
                                setStandaloneSurveysToAdd={setStandaloneSurveysToAdd}
                              />
                            )}
                            {!!processSurveys?.length && (
                              <ProcessSurveySelect
                                processSurveys={processSurveys}
                                processSurveysToAdd={processSurveysToAdd}
                                setProcessSurveysToAdd={setProcessSurveysToAdd}
                              />
                            )}
                          </>
                        )}
                      </FormGroup>
                      {isTeamioOnboarding && numberOfUsedParticipants >= numberOfMaxParticipants && (
                        <BodyMedium margin={`${theme.spacing.f} 0 0 ${theme.spacing.f}`} className="w-100">
                          <RedText>
                            <Trans
                              i18nKey={`teamioOnb:usageInfo`}
                              values={{ total: numberOfMaxParticipants, used: numberOfUsedParticipants }}
                              components={[<strong />]}
                            />{' '}
                            {t('teamioOnb:usageExceeded')}{' '}
                          </RedText>
                          <Trans i18nKey={`teamioOnb:orderMore`} components={[<a href={'/settings/order'} />]} />
                        </BodyMedium>
                      )}
                    </FormGroup>
                  </Row>
                )}
            </Card.Body>
          </Card>
          <div className="d-flex flex-wrap align-items-start mt-7 ml-8">
            <Button
              variant="primary"
              className={'mr-6 mb-5'}
              disabled={
                !isEmpty(errors) ||
                isSubmitting ||
                unverifiedPhoneNumber ||
                (values.addToSurveys && Object.values(processSurveysToAdd).some((sg) => sg.checked && !sg.addedOn)) ||
                (values.addToSurveys &&
                  values.addToOnboarding &&
                  Object.values(onboardingSurveysToAdd).some((sg) => sg.checked && !sg.addedOn))
              }
              type="submit"
              data-icom={'btn-add-employee'}
            >
              {respondent ? t('weekPicker:save') : t('addRespondent:addEmployee')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RespondentForm;
