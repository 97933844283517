import { flatten, mean, uniq } from 'ramda';

import { uniqueId } from '../../lib/helpers';
import { TopicDetailQuery } from '../../generated/hooks';
import { isQuestionBubble } from './helpers';

import { QUESTION_DEFINITION_TYPE } from './types';

export type Choice = {
  text: string | null;
  uid: string;
};

type AllQuestions = NonNullable<TopicDetailQuery['topicDetail']['allQuestions']>;
interface QuestionTranslation extends Omit<AllQuestions[0]['translations'][0], 'choices'> {
  choices?: Choice[] | null;
}

export interface QuestionDetail extends Omit<AllQuestions[0], 'translations'> {
  translations: QuestionTranslation[];
}

type PathToSection = {
  question: QuestionDetail;
  rule: AllQuestions[0]['rules'][0];
};

export type QuestionSubsections = { [key: string]: QuestionDetail[][] };

export type QuestionSubsectionsMap = Map<string, QuestionSubsections>;

export type Section = {
  id: string;
  index: number;
  isOrphan?: boolean;
  questions: QuestionDetail[];
  pathsTo: PathToSection[];
  questionSubsections?: QuestionSubsectionsMap;
};

export type QuestionMappingByOrder = { [key: string]: number };

type QuestionMappingById = { [key: string]: string };

export type TopicStructure = {
  sections: Section[];
  mapping: QuestionMappingByOrder;
  sectionIndexMapping: { [key: number]: number };
};

export const findQuestion = (questions: QuestionDetail[], id: string) => questions.find((q) => q.id === id);

export const getQuestionType = (question: QuestionDetail) => {
  if (question.__typename === 'SelectQuestionDefinition') {
    return question.selectedRange === '1' || question.selectedRange === '0-1'
      ? 'SelectQuestionDefinition'
      : 'MultiSelectQuestionDefinition';
  }
  return question.__typename;
};

export const trimRuleOrigValue = (origValue: string) => {
  return origValue.replace(/^\[+|\]+$/g, '');
};

const getRuleSubsection = (
  rule: AllQuestions[0]['rules'][0],
  allQuestions: QuestionDetail[],
): QuestionDetail[] | null => {
  if (!rule) {
    return null;
  }
  const nextQuestion = findQuestion(allQuestions, rule.nextQuestion.id);
  if (!nextQuestion) {
    throw new Error(`Question with id ${rule.nextQuestion.id} not found`);
  }
  if (
    nextQuestion.isFirstInSectionWithNumber ||
    nextQuestion.rules.some((rule) => rule.nextQuestion.id !== nextQuestion.rules[0].nextQuestion.id)
  ) {
    return null;
  }

  return [nextQuestion, ...(getRuleSubsection(nextQuestion.rules[0], allQuestions) || [])];
};

export const getPathsTo = (questions: QuestionDetail[], questionId: string): PathToSection[] => {
  const paths = questions.reduce((acc: PathToSection[], question) => {
    if (question.rules) {
      const matchingRules = question.rules.filter((rule) => rule.nextQuestion.id === questionId);
      matchingRules.forEach((rule) => {
        acc.push({
          question,
          rule,
        });
      });
    }
    return acc;
  }, []);
  return paths;
};

export const getPathsFrom = (lastQuestion: QuestionDetail, section: Section, mapping: QuestionMappingByOrder) =>
  lastQuestion
    ? uniq(
        flatten(
          lastQuestion.rules.map((rule) => {
            if (section.questionSubsections) {
              const questionSubsections = section.questionSubsections.get(lastQuestion.id);
              if (questionSubsections) {
                const foundInSubsections = Object.keys(questionSubsections).find((subsection) =>
                  questionSubsections[subsection].find((subsectionQuestions) =>
                    subsectionQuestions.find((subsectionQuestion) => rule.nextQuestion.id === subsectionQuestion.id),
                  ),
                );
                if (foundInSubsections !== undefined) {
                  const subsection = questionSubsections[foundInSubsections];
                  return subsection.map((subsectionBranch) => {
                    const lastQuestionInSubsection = subsectionBranch[subsectionBranch.length - 1];
                    return lastQuestionInSubsection.rules.map(
                      (subsectionRule) => mapping[subsectionRule.nextQuestion.id] + 1,
                    );
                  });
                }
              }
            }
            return mapping[rule.nextQuestion.id] + 1;
          }),
        ),
      ).sort((a, b) => a - b)
    : [];

// recursively create linear sequence of TELLs
// finished by multiple entrances
// will be displayed under THE non-tell question
const getQuestionSubsections = (question: QuestionDetail, allQuestions: QuestionDetail[]) => {
  if (
    !isQuestionBubble(question) &&
    (([QUESTION_DEFINITION_TYPE.select, QUESTION_DEFINITION_TYPE.multiselect].includes(question.__typename as any) &&
      question.rules.length === 1) ||
      question.rules.some((rule) => rule.nextQuestion.id !== question.rules[0].nextQuestion.id))
  ) {
    const subsections: {
      [key: string]: QuestionDetail[][];
    } = {};

    question.rules.forEach((rule) => {
      const ruleSubsection = getRuleSubsection(rule, allQuestions);

      if (ruleSubsection && ruleSubsection.length) {
        if (!subsections[trimRuleOrigValue(rule.origValue) || 'default']) {
          subsections[trimRuleOrigValue(rule.origValue) || 'default'] = [];
        }

        subsections[trimRuleOrigValue(rule.origValue) || 'default'].push(ruleSubsection);
      }
    });

    if (Object.keys(subsections).length) {
      return subsections;
    }
  }

  return null;
};

const createSectionMapping = (sections: Section[]): { [key: number]: number } => {
  const sectionMapping: { [key: number]: number } = sections.reduce(
    (acc, section, index) => ({ ...acc, [index]: section.index }),
    {},
  );

  return sectionMapping;
};

const createMappingByOrder = (sections: Section[], mappingById: QuestionMappingById): QuestionMappingByOrder => {
  const sectionMapping: QuestionMappingByOrder = sections.reduce(
    (acc, section, index) => ({ ...acc, [section.id]: index }),
    {},
  );
  return Object.keys(mappingById).reduce(
    (acc, id): QuestionMappingByOrder => ({
      ...acc,
      [id]: sectionMapping[mappingById[id]],
    }),
    {},
  );
};

export const prepareQuestion = (question: AllQuestions[0]): QuestionDetail => {
  return {
    ...question,
    translations: question.translations.map((t) => {
      return {
        ...t,
        choices:
          t.choices &&
          t.choices.map((text) => {
            return {
              text,
              uid: uniqueId(),
            };
          }),
      };
    }),
  };
};

export const isNextSection = (questionId: string, nextQuestionId: string, mapping: QuestionMappingByOrder) =>
  mapping[nextQuestionId] - mapping[questionId] === 1;

export const prepareSections = (allQuestions: QuestionDetail[]): TopicStructure => {
  const mappingById: QuestionMappingById = {};
  let sections: Section[] = allQuestions
    .filter((question) => question.isFirstInSectionWithNumber)
    .map((firstQuestionInSection, index) => {
      const pathsTo = getPathsTo(allQuestions, firstQuestionInSection.id);
      const newSection: Section = {
        id: firstQuestionInSection.id,
        isOrphan: pathsTo.length === 0 && index !== 0,
        index: firstQuestionInSection.index || 0,
        questions: [],
        pathsTo,
      };

      const processQuestion = (question: QuestionDetail) => {
        newSection.questions.push(question);
        mappingById[question.id] = newSection.id;
        const subsections = getQuestionSubsections(question, allQuestions);
        if (subsections) {
          if (!newSection.questionSubsections) {
            newSection.questionSubsections = new Map([[question.id, subsections]]);
          } else {
            newSection.questionSubsections!.set(question.id, subsections);
          }
          Object.keys(subsections).forEach((subsection) =>
            subsections[subsection].forEach((subsectionQuestions) =>
              subsectionQuestions.forEach((subsectionQuestion) => {
                mappingById[subsectionQuestion.id] = newSection.id;
              }),
            ),
          );
        }
        // Go trough other questions in section
        question.rules.forEach((rule) => {
          if (mappingById[rule.nextQuestion.id] !== undefined) {
            return;
          }
          const nextQuestion = findQuestion(allQuestions, rule.nextQuestion.id);
          if (!nextQuestion) {
            throw new Error(`Question with id ${rule.nextQuestion.id} not found`);
          }
          if (nextQuestion.isFirstInSectionWithNumber) {
            return;
          }
          processQuestion(nextQuestion);
        });
      };

      processQuestion(firstQuestionInSection);

      return newSection;
    });

  sections = sortObjects(sections);
  return {
    sections,
    mapping: createMappingByOrder(sections, mappingById),
    sectionIndexMapping: createSectionMapping(sections),
  };
};

const sortObjects = (sections: Section[]) => {
  try {
    const sectionsWithIds = sections.map((section, i) => {
      const subsectionQuestions = ((section.questionSubsections &&
        Array.from(section.questionSubsections.values())
          .map((s) => Object.values(s).map((a) => a.map((b) => b.map((q) => q))))
          .flat(Infinity)) ||
        []) as unknown as QuestionDetail[];
      return {
        index: i,
        section,
        questionIds: [...section.questions.map((q) => q.id), ...subsectionQuestions.map((q) => q.id)],
        subsectionQuestions,
      };
    });

    const sectionsWithIdsAndNextConnections = sectionsWithIds.map((section) => {
      const qIds = [...section.section.questions, ...section.subsectionQuestions]
        .map((q) => q.rules.map((r) => r.nextQuestion.id))
        .flat();
      const nextSectionIndexes = uniq(
        qIds.map((id) => sectionsWithIds.find((s) => s.questionIds.includes(id))!.index),
      ).filter((index) => index !== section.index);
      return {
        ...section,
        nextSectionIndexes,
      };
    });

    const sectionsWithIdsAndAllConnections = sectionsWithIdsAndNextConnections.map((section) => {
      const previousSectionIndexes = sectionsWithIdsAndNextConnections
        .filter((s) => s.nextSectionIndexes.includes(section.index))
        .map((s) => s.index);
      return {
        ...section,
        previousSectionIndexes,
      };
    });

    const sectionsWithDepth = sectionsWithIdsAndAllConnections.map((section) => {
      const depth = calculateAverageDistanceToStart(sectionsWithIdsAndAllConnections, section);
      return {
        ...section,
        depth,
      };
    });

    const sortedSections = sectionsWithDepth.sort((a, b) => a.depth - b.depth).map((s) => s.section);

    if (sections.length !== sortedSections.length) {
      // there is probably some issue with connections - some nodes are not connected
      // return initial unsorted array
      console.error('Conversation cannot be sorted coz there are issues with connections');
      return sections;
    }
    return sortedSections;
  } catch (e) {
    console.error('Conversation cannot be sorted coz there are issues with connections', e);
    return sections;
  }
};

const calculateAverageDistanceToStart = (
  sections: SectionWithConnections[],
  section: SectionWithConnections,
  previousDepth = 0,
): number => {
  if (section.previousSectionIndexes.length === 0) return previousDepth;
  const depths = section.previousSectionIndexes.map((idx) =>
    calculateAverageDistanceToStart(sections, sections[idx], previousDepth + 1),
  );
  return mean(depths);
};

type SectionWithConnections = {
  previousSectionIndexes: number[];
  nextSectionIndexes: number[];
  index: number;
  section: Section;
  questionIds: string[];
  subsectionQuestions: QuestionDetail[];
};
