import { ReactNode, FC } from 'react';
import { BoxWithImage } from '../boxes';
import { BodyMedium } from '../Typography';
import type { Labels } from './types';

type Props = {
  emptyImage: ReactNode;
  labels: Labels;
};

export const EmptyTable: FC<Props> = ({ emptyImage, labels }) => (
  <div data-cy={'admin-no-items'}>
    <BoxWithImage image={emptyImage}>
      <BodyMedium>{labels.emptyTableLabel}</BodyMedium>
    </BoxWithImage>
  </div>
);
