import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { ReactNode } from 'react';
import { theme, ThemeType } from '../../theme';

export type TabItem = {
  link?: string;
  title: ReactNode;
  active?: boolean;
  onClick?: () => void;
  icomTag?: string;
};

export interface ITabProps {
  items: TabItem[];
  theme?: ThemeType;
}

interface ITabLinkProps {
  active: boolean;
  disabled?: boolean;
  theme: ThemeType;
}

export const TabsCard = styled.div`
  height: 70px;
  box-shadow: ${theme.shadows.elevationContentBlock};
  border-radius: 6px;
  background: ${theme.colors.backgroundCover.default};
  padding-left: ${theme.spacing.f};
  padding-right: ${theme.spacing.f};
  display: flex;
  align-items: center;
`;

const TabLink = styled.span`
  padding: ${theme.spacing.g} ${theme.spacing.f};
  margin: 0 ${theme.spacing.f};
  font-size: ${({ theme }: ITabLinkProps) =>
    theme.typography.body.medium &&
    theme.typography.body.medium.regular &&
    theme.typography.body.medium.regular.fontSize};
  line-height: ${({ theme }: ITabLinkProps) =>
    theme.typography.body.medium &&
    theme.typography.body.medium.regular &&
    theme.typography.body.medium.regular.lineHeight};
  color: ${({ active, theme }: ITabLinkProps) => (active ? theme.colors.text.primary : theme.colors.text.secondary)};
  border-bottom: ${({ active, theme }: ITabLinkProps) =>
    active ? `${theme.colors.actionPrimary.default} solid ${theme.spacing.b}` : 'none'};
  :hover {
    color: ${({ disabled, theme }: ITabLinkProps) => (disabled ? theme.colors.text.secondary : 'inherit')};
  }
`;

const StyledLink = styled(Link)`
  font-weight: 500;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.actionPrimary.default};
  }
`;

export const Tabs = ({ items, theme: themeFromProps }: ITabProps) => {
  return (
    <TabsCard>
      {items.map((item) => (
        <StyledLink to={item.link || '?'} key={item.title?.toString()} onClick={item.onClick}>
          <TabLink
            active={!!item.active}
            disabled={!item.link}
            theme={themeFromProps ?? theme}
            data-icom={item.icomTag}
            data-cy={item.icomTag}
          >
            {item.title}
          </TabLink>
        </StyledLink>
      ))}
    </TabsCard>
  );
};
