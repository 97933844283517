import { addDays, addMinutes } from 'date-fns';
import { getWorkingDay } from './dateHelpers';

export const isNullish = (value: unknown): value is null | undefined => value === undefined || value === null;

export const prepareExternalUidForCandidate = (candidateId: number | string): string => {
  return `c_${candidateId}`;
};

export type StepInfo = {
  startOffsetMins?: number | null;
  remindersOffsetMins?: number[] | null;
  endOffsetMins?: number | null;
};

type Reminder = {
  reminderAt: Date;
};

export type Output = {
  sendAt: Date;
  endsAt: Date;
  reminders: Reminder[];
};
export const prepareSurveyDates = (processStep: StepInfo, addedOn: Date, forceResult = false): null | Output => {
  if (isNullish(processStep.startOffsetMins) || isNullish(processStep.endOffsetMins)) {
    return null;
  }

  let startDate = addMinutes(addedOn, processStep.startOffsetMins);

  if (addedOn.getTimezoneOffset() !== startDate.getTimezoneOffset()) {
    startDate = addMinutes(startDate, startDate.getTimezoneOffset() - addedOn.getTimezoneOffset());
  }

  if (startDate < new Date() && !forceResult) {
    return null;
  }
  const startDateInWorkingDay = getWorkingDay(startDate).workingDay;
  const reminders: { reminderAt: Date }[] = [];
  let remindersShift = 0;

  processStep.remindersOffsetMins?.forEach((reminderOffsetMins) => {
    const { workingDay: reminderDate, shiftInDays: reminderShift } = getWorkingDay(
      addMinutes(addDays(startDateInWorkingDay, remindersShift), reminderOffsetMins ?? 0),
    );
    if (reminderOffsetMins) {
      reminders.push({
        reminderAt: reminderDate,
      });
      remindersShift = remindersShift + reminderShift;
    }
  });

  const endDate = getWorkingDay(
    addMinutes(addDays(startDateInWorkingDay, remindersShift), processStep.endOffsetMins),
  ).workingDay;
  const output: Output = {
    sendAt: startDateInWorkingDay,
    endsAt: endDate,
    reminders: [],
  };

  return { ...output, reminders };
};
