import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl } from 'react-bootstrap';
import { getFrontendConfigValue, theme, useToast } from '@arnold/common';
import { ReactComponent as ContentCopyIcon } from '@arnold/common/lib/assets/icons/ContentCopy.svg';
import { ReactComponent as DownloadIcon } from '@arnold/common/lib/assets/icons/Download.svg';

import styled from '@emotion/styled';
import QrCodeWithLogo from 'qrcode-with-logos';
import ArnoldIcon from '../../assets/images/arnoldIcon.svg';
import BaseModal from './BaseModal';

type Props = {
  slug: string;
  onClose: () => void;
};

const StyledButton = styled(Button)`
  svg path {
    fill: ${theme.colors.textInverted.primary};
  }
  margin-bottom: ${theme.spacing.g};
`;

const StyledQrCode = styled.img`
  display: block;
  width: 149px;
  height: 149px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ShareChannelLinkModal: FC<Props> = ({ onClose, slug }) => {
  const [t] = useTranslation('surveyGroup');
  const { addToast } = useToast();

  const channelUrl = `${getFrontendConfigValue('ARNOLD_URL')}/${slug}`;
  let qrcode: QrCodeWithLogo;

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    qrcode = new QrCodeWithLogo({
      content: channelUrl,
      width: 600,
      image: document.getElementById('image') as HTMLImageElement,
      logo: {
        src: ArnoldIcon,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopy = (link: string) => {
    navigator.clipboard.writeText(link);
    addToast?.(t('shareLinksModal:urlCopiedToClipboard'));
  };

  const handleDownloadQrCode = () => {
    if (!qrcode) {
      qrcode = new QrCodeWithLogo({
        content: channelUrl,
        width: 600,
        image: document.getElementById('image') as HTMLImageElement,
        logo: {
          src: ArnoldIcon,
        },
      });
    }
    qrcode.downloadImage(`QR code`);
  };

  return (
    <BaseModal
      title={t('copyLink')}
      description={''}
      secondaryActionText={t('common:close')}
      onSecondaryAction={onClose}
      onClose={onClose}
    >
      <Content>
        <FormControl type="text" autoComplete="none" name={'copy_link'} value={channelUrl} />
        <StyledButton onClick={() => handleCopy(channelUrl)}>
          <ContentCopyIcon /> {t('copyLink')}
        </StyledButton>
        <StyledQrCode id="image" alt="Qr code" />
        <StyledButton onClick={() => handleDownloadQrCode()}>
          <DownloadIcon /> {t('surveyForm:downloadQRCode')}
        </StyledButton>
      </Content>
    </BaseModal>
  );
};
export default ShareChannelLinkModal;
