import { AllowedConversationSubjectQuery } from '../../../generated/hooks';
import { getTopicGroupTranslationByLanguageId } from '../../../lib/common';

export const getCategoryName = (
  categoryName: string | null | undefined,
  custom: boolean,
  customTopicTranslation: string,
): string => {
  if (custom) {
    return customTopicTranslation;
  } else if (categoryName) {
    return categoryName;
  } else {
    console.warn('Empty category name for non custom topic!');
    return '-';
  }
};

type Organization = NonNullable<AllowedConversationSubjectQuery['user']['organization']>;

export const getTopicLanguages = <T>(
  topicLanguages: T extends { code: string } ? T[] : never,
  organizationLanguages: { code: string }[],
): T[] =>
  topicLanguages.filter((topicLanguage) =>
    organizationLanguages.some((organizationLanguage) => organizationLanguage.code === topicLanguage.code),
  );

export const getTopicTitleForLink = (
  topic: Organization['allowedConversationSubjects'][0]['topic'],
  languageId: number,
) => {
  const topicGroupTranslation = getTopicGroupTranslationByLanguageId(`${languageId})`, topic?.topicGroup?.translations);
  return topicGroupTranslation ? topicGroupTranslation.value : topic?.name;
};
