import { gql } from '@apollo/client';

export const getBaseUserQuery = gql`
  query getBaseUser($accessToken: String!) {
    isAccessTokenValid(accessToken: $accessToken) {
      status
      consent
      baseUser {
        firstname
        surname
        organization {
          name
        }
        username
      }
    }
  }
`;

export const isAccessTokenValidQuery = gql`
  query isAccessTokenValid($accessToken: String!) {
    isAccessTokenValid(accessToken: $accessToken) {
      status
      consent
      expireAt
      user {
        id
        username
        firstname
        surname
        createdAt
        systemRole
        consent
        allowedMoreOrganizations
      }
    }
  }
`;

export const getDashboardQuery = gql`
  query dashboard($languageCode: String) {
    user(languageCode: $languageCode) {
      id
      organization {
        id
        currentSurveys {
          id
          accessKey
          status
          whitelistedRespondentsLength
          public
          resultsInfo {
            answered
            total
          }
          topLevelReport {
            accessKey
          }
          notstartedReason
          topic {
            id
            name
          }
          topicGroup {
            id
            name
            categoryCode
          }
          sendAt
          endsAt
        }
        activeProcessSurveys {
          from
          id
          status
          name
          topicGroup {
            id
            name
            categoryCode
          }
          topLevelReport {
            accessKey
          }
          hasOutOfLicenceInterval
          notstartedReason
        }
        activeOnboadings {
          id
          name
          topicGroup {
            id
            name
          }
          steps {
            processStep {
              stepTopicGroup {
                id
                chatSatisfactionScale
                name
              }
            }
            avgMetric
          }
        }
      }
    }
  }
`;

export const getRespondentsQuery = gql`
  query getRespondents($languageCode: String) {
    user(languageCode: $languageCode) {
      respondents {
        id
        organization {
          id
        }
        organizationRole
        contacts {
          source
          type
          value
        }
      }
    }
  }
`;

export const getUserQuery = gql`
  query user($languageCode: String) {
    user(languageCode: $languageCode) {
      id
      username
      systemRole
      organization {
        id
        name
        systemName
        status
        primaryLanguageId
        primaryLanguageCode
        languages {
          id
          code
        }
        respondentsCount
        enabledRespondentsCount
        activeRespondentsInCurrentPeriodCount
        productVersion {
          product
          licenceCount
          validFrom
          validTo
        }
        productVersionHistory {
          product
          licenceCount
          validFrom
          validTo
        }
        beforeFirstSurvey
        config {
          timezone
          testSurveys
        }
        surveys {
          id
          status
          recipientEmails
          public
          archivedAt
          resultsInfo {
            answered
            total
          }
          topLevelReport {
            id
            accessKey
          }
          notstartedReason
          topic {
            id
            name
            description
            topicGroup {
              id
            }
          }
          topicGroup {
            id
            name
            description
            categoryCode
          }
          sendAt
          updatedAt
          endsAt
          reminders {
            reminderAt
          }
          availableInLanguages {
            id
            code
          }
        }
        surveyGroups {
          from
          to
          id
          status
          name
          topicGroup {
            id
            name
            typeCode
            categoryCode
            translations {
              id
              language {
                code
              }
              value
              description
            }
            languages {
              id
              code
            }
            description
            typeCode
          }
          reports {
            id
            respondent {
              id
            }
            accessKey
          }
          statistics {
            total
            answered
          }
          steps {
            hasOutOfLicenceInterval
            hasNotStarted
            status
            notstartedReason
            processStep {
              stepTopicGroup {
                id
                name
                languages {
                  id
                  code
                }
                description
                categoryCode
              }
            }
            surveys {
              sendAt
              id
              archivedAt
            }
            avgMetric
          }
        }
        featureNames
      }
    }
  }
`;

export const getOrganizationSurveysQuery = gql`
  query organizationSurveys($organizationId: ID!, $languageCode: String) {
    organizationSurveys(organizationId: $organizationId, languageCode: $languageCode) {
      id
      status
      recipientEmails
      public
      archivedAt
      resultsInfo {
        answered
        total
      }
      topLevelReport {
        id
        accessKey
      }
      notstartedReason
      topicGroup {
        id
        name
        description
        categoryCode
      }
      sendAt
      updatedAt
      endsAt
      reminders {
        reminderAt
      }
      availableInLanguages {
        id
        code
      }
    }
  }
`;

export const getSurveysAndSurveyGroupsQuery = gql`
  query surveysAndSurveyGroups($languageCode: String) {
    user(languageCode: $languageCode) {
      organization {
        surveys {
          id
          status
          recipientEmails
          resultsInfo {
            answered
            total
          }
          reports {
            id
            accessKey
            readAt
            sent
            accepted
            respondent {
              id
              firstname
              surname
            }
            teams {
              id
              name
              leader {
                id
              }
            }
          }
          notstartedReason
          topic {
            id
            name
            description
            topicGroup {
              id
              name
              translations {
                id
                language {
                  code
                }
                value
                description
              }
            }
          }
          topicGroup {
            id
            name
            description
            categoryCode
            translations {
              id
              language {
                code
              }
              value
              description
            }
          }
          sendAt
          updatedAt
          endsAt
          reminders {
            reminderAt
          }
          availableInLanguages {
            id
            code
          }
        }
        surveyGroups {
          from
          id
          status
          name
          isAnySurveyComplete
          topicGroup {
            id
            name
            typeCode
            translations {
              id
              language {
                code
              }
              value
              description
            }
            languages {
              id
              code
            }
            description
            typeCode
          }
          reports {
            id
            sent
            accepted
            respondent {
              id
              firstname
              surname
            }
            accessKey
            teams {
              id
              name
              leader {
                id
              }
            }
          }
          statistics {
            total
            answered
          }
          steps {
            hasOutOfLicenceInterval
            hasNotStarted
            status
            notstartedReason
            processStep {
              stepTopicGroup {
                id
                name
                languages {
                  id
                  code
                }
                description
                categoryCode
              }
            }
            surveys {
              sendAt
              id
            }
            avgMetric
          }
        }
      }
    }
  }
`;

export const getAllowedConversationSubjects = gql`
  query allowedConversationSubject($languageCode: String) {
    user(languageCode: $languageCode) {
      organization {
        id
        allowedConversationSubjects {
          id
          type
          name
          description
          process
          custom
          typeCode
          lastSurvey {
            id
            sendAt
            endsAt
          }
          lastSurveyGroup {
            id
          }
          languages {
            id
            code
          }
          topic {
            id
            name
            languageIdsOnStartingQuestion
            updatedAt
            updatedBy {
              firstname
              surname
            }
            createdAt
            topicGroup {
              translations {
                language {
                  id
                  code
                }
                value
              }
              id
            }
            languages {
              id
            }
          }
          categoryCode
          categoryName
          isValid
          numberOfComments
        }
      }
    }
  }
`;

export const getOrganizationRespondentsQuery = gql`
  query activeRespondents($languageCode: String) {
    user(languageCode: $languageCode) {
      id
      organization {
        id
        enabledRespondentsCount
        name
        activeRespondents {
          id
          preferredContactType
          firstname
          surname
          language {
            id
            code
          }
          team {
            id
            name
          }
          contacts {
            type
            source
            value
            phoneNumber {
              countryCallingCode
              nationalNumber
            }
          }
        }
        teams {
          id
          name
          rootTeam
          structureId
          leader {
            id
            firstname
            surname
            contacts {
              type
              source
              value
            }
          }
          members {
            id
            firstname
            surname
            preferredContactType
            contacts {
              type
              source
              value
              phoneNumber {
                countryCallingCode
                nationalNumber
              }
            }
            language {
              id
              code
            }
            disabled
          }
        }
      }
    }
  }
`;

export const getTopicGroupDetail = gql`
  query topicGroupDetail($topicGroupId: ID!, $languageCode: String) {
    topicGroup(topicGroupId: $topicGroupId, languageCode: $languageCode) {
      id
      name
      containsSteps
      accessKeys {
        id
        level
        accessKey
      }
      translations {
        language {
          id
          code
        }
        value
        id
        description
      }
      languages {
        id
        code
      }
      description
      lastValidTopic {
        id
        name
        description
        isApproved
        updatedAt
        updatedBy {
          firstname
          surname
        }
        languages {
          id
          code
        }
        startingQuestion {
          id
        }
        allQuestions {
          id
          text
          selectedRange
          index
          reportIndex
          reportOrder
          optional
          answersToHighlight
          isFirstInSectionWithNumber
          translations {
            choices
            isDefault
            text
            language {
              id
              code
            }
          }
          rules {
            id
            nextQuestion {
              id
              index
            }
            origValue
            operator
            order
            isFromDefault
          }
          positionX
          positionY
          isMetric
          isQuestionTrigger
        }
        commentGroups {
          id
          positionX
          positionY
          topicId
          comments {
            id
            text
            originator {
              id
              firstname
              surname
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  }
`;

export const getTopicDetail = gql`
  query topicDetail($topicId: ID, $topicGroupId: ID) {
    topicDetail(topicId: $topicId, topicGroupId: $topicGroupId) {
      id
      name
      description
      isApproved
      isValid
      isEditable
      updatedAt
      updatedBy {
        firstname
        surname
      }
      languages {
        id
        code
      }
      startingQuestion {
        id
      }
      allQuestions {
        id
        text
        selectedRange
        index
        reportIndex
        reportOrder
        optional
        answersToHighlight
        isFirstInSectionWithNumber
        translations {
          choices
          isDefault
          text
          language {
            id
            code
          }
        }
        rules {
          id
          nextQuestion {
            id
            index
          }
          origValue
          operator
          order
          isFromDefault
        }
        positionX
        positionY
        isMetric
        isQuestionTrigger
      }
      commentGroups {
        id
        positionX
        positionY
        topicId
        comments {
          id
          text
          originator {
            id
            firstname
            surname
          }
          createdAt
          updatedAt
        }
      }
      topicGroup {
        id
        name
        organization {
          id
        }
        accessKeys {
          id
          level
          accessKey
        }
        containsSteps
        translations {
          language {
            id
            code
          }
          value
          id
          description
        }
        languages {
          id
          code
        }
        description
      }
    }
  }
`;

export const getSurvey = gql`
  query survey($surveyId: ID!) {
    survey(surveyId: $surveyId) {
      id
      status
      public
      accessKey
      topicGroup {
        id
        name
        translations {
          language {
            code
          }
          id
          value
          description
        }
        languages {
          id
          code
        }
        description
      }
      topic {
        id
      }
      channels {
        respondent {
          id
          firstname
          surname
        }
        lastRemindedAt
        isFinished
        answered
        alert
      }
      whitelistedRespondents {
        id
        preferredContactType
        firstname
        surname
        team {
          id
          name
        }
        contacts {
          type
          source
          value
        }
        language {
          id
          code
        }
      }
      resultsInfo {
        total
        answered
        finished
      }
      reports {
        respondent {
          id
        }
        accessKey
      }
      teamsResultInfo {
        team {
          id
          name
          leader {
            firstname
            surname
          }
        }
        resultInfo {
          total
          answered
          anonymous
        }
        teamMemberIdsFromChannels
      }
      sendAt
      endsAt
      reminders {
        reminderAt
      }
      sendEmailSms
      anonymitySettings {
        allowedLevels
      }
    }
  }
`;

export const getSurveyGroupWithoutRespondents = gql`
  query surveyGroupWithoutRespondents($surveyGroupId: ID!) {
    surveyGroup(surveyGroupId: $surveyGroupId) {
      id
      name
      topicGroup {
        id
        name
        translations {
          language {
            code
          }
          id
          value
          description
        }
        categoryCode
        typeCode
        languages {
          id
          code
        }
        description
      }
      status
      steps {
        processStep {
          startOffsetMins
          reminders {
            reminderOffsetMins
          }
          endOffsetMins
          triggeredOn
          stepTopicGroup {
            id
            name
            translations {
              id
              language {
                code
              }
              value
            }
            lastValidTopic {
              id
            }
          }
        }
        hasMetricQuestion
      }
      topLevelReport {
        id
        accessKey
      }
    }
  }
`;

export const getSurveyGroupStepStatistics = gql`
  query surveyGroupStepStatistics($surveyGroupId: ID!) {
    surveyGroup(surveyGroupId: $surveyGroupId) {
      id
      steps {
        processStep {
          startOffsetMins
          reminders {
            reminderOffsetMins
          }
          endOffsetMins
          stepTopicGroup {
            id
            name
            translations {
              id
              language {
                code
              }
              value
            }
            lastValidTopic {
              id
            }
          }
        }
        daysFromStart
        status
        hasNotStarted
        notstartedReason
        statistics {
          answered
          total
          withProblems
        }
        avgMetric
      }
    }
  }
`;
export const getSurveyGroupWithGroupedRespondents = gql`
  query surveyGroupWithGroupedRespondents($surveyGroupId: ID!) {
    surveyGroup(surveyGroupId: $surveyGroupId) {
      id
      name
      topicGroup {
        id
        name
        translations {
          language {
            code
          }
          id
          value
          description
        }
        steps {
          id
          startOffsetMins
          reminders {
            reminderOffsetMins
          }
          triggeredOn
          endOffsetMins
          stepTopicGroup {
            id
            translations {
              value
              description
              language {
                code
              }
            }
          }
        }
        categoryCode
        typeCode
        languages {
          id
          code
        }
        description
      }
      groupedRespondents {
        respondents {
          id
          preferredContactType
          firstname
          surname
          team {
            id
            name
          }
          contacts {
            type
            source
            value
          }
        }
        addedOn
        surveys {
          id
          status
          sendAt
          changedSeqDate
          topicGroup {
            id
            name
            translations {
              id
              language {
                code
              }
              value
            }
          }
        }
        deletedRespondents
      }
    }
  }
`;

export const getSurveyGroup = gql`
  query surveyGroup($surveyGroupId: ID!) {
    surveyGroup(surveyGroupId: $surveyGroupId) {
      id
      name
      topicGroup {
        id
        name
        translations {
          language {
            code
          }
          id
          value
          description
        }
        categoryCode
        typeCode
        languages {
          id
          code
        }
        description
      }
      groupedRespondents {
        respondents {
          id
          preferredContactType
          firstname
          surname
          team {
            id
            name
          }
          contacts {
            type
            source
            value
          }
        }
        addedOn
        surveys {
          id
          status
          sendAt
          notstartedReason
          topicGroup {
            id
            name
            translations {
              id
              language {
                code
              }
              value
            }
          }
        }
        deletedRespondents
        metricQuestionsForRespondent {
          respondentId
          answers {
            topicGroupId
            value
          }
        }
      }
      status
      isAnySurveyComplete
      steps {
        processStep {
          startOffsetMins
          reminders {
            reminderOffsetMins
          }
          endOffsetMins
          stepTopicGroup {
            id
            name
            translations {
              id
              language {
                code
              }
              value
            }
            lastValidTopic {
              id
            }
          }
        }
        daysFromStart
        hasMetricQuestion
        status
        hasNotStarted
        notstartedReason
        statistics {
          answered
          total
          withProblems
        }
        avgMetric
        channels {
          id
          finishedAt
          allowedAnonymities
          alert
          survey {
            id
          }
          respondent {
            id
            firstname
            surname
            deleted
            team {
              name
            }
          }
        }
        surveys {
          id
          status
          sendAt
          endsAt
        }
      }
      topLevelReport {
        id
        accessKey
      }
    }
  }
`;

export const surveyGroupRespondents = gql`
  query surveyGroupRespondents(
    $surveyGroupId: ID!
    $pagination: Pagination
    $searchQuery: String
    $withoutDeleted: Boolean
  ) {
    surveyGroupRespondents(
      surveyGroupId: $surveyGroupId
      pagination: $pagination
      searchQuery: $searchQuery
      withoutDeleted: $withoutDeleted
    ) {
      data {
        respondents {
          id
          preferredContactType
          firstname
          surname
          deleted
          team {
            id
            name
          }
          contacts {
            type
            source
            value
            phoneNumber {
              countryCallingCode
              nationalNumber
            }
          }
        }
        addedOn
        surveys {
          id
          status
          notstartedReason
          sendAt
          endsAt
          topicGroup {
            id
            name
            translations {
              id
              language {
                code
              }
              value
            }
          }
          channels {
            finishedAt
            lastRemindedAt
            isFinishedByRespondent
            allowedAnonymities
            respondent {
              id
            }
            answered
            slug
          }
        }
        hiringSurveys {
          id
          status
          notstartedReason
          sendAt
          endsAt
          topicGroup {
            id
            name
            translations {
              id
              language {
                code
              }
              value
            }
          }
          whitelistedRespondents {
            id
          }
          channels {
            finishedAt
            isFinishedByRespondent
            allowedAnonymities
            respondent {
              id
            }
            answered
            slug
          }
        }
        deletedRespondents
        undeliveredInvitations {
          respondentId
          undeliveredSurveyInvitations {
            surveyId
            contact
            status
          }
        }
        metricQuestionsForRespondent {
          respondentId
          answers {
            topicGroupId
            value
          }
        }
      }
      totalCount
    }
  }
`;

export const getDatesForNewSurvey = gql`
  query datesForNewSurvey($organizationId: ID!) {
    datesForNewSurvey(organizationId: $organizationId) {
      sendAt
      endsAt
      reminderAt
      secondReminderAt
    }
  }
`;

export const getOrganizationStructure = gql`
  query Organization($organizationId: ID) {
    organization(organizationId: $organizationId) {
      id
      name
      systemName
      companyId
      primaryLanguageId
      primaryLanguageCode
      languages {
        id
        code
      }
      enabledRespondentsCount
      respondentsCount
      activeRespondentsInCurrentPeriodCount
      productVersion {
        product
        validTo
        licenceCount
        validFrom
        activePeriodEnd
      }
      config {
        countryCode
      }
      productVersionHistory {
        product
        licenceCount
        validFrom
        validTo
      }
      featureNames
      hasProcessSurveys
    }
  }
`;

export const getSurveyGroups = gql`
  query surveyGroups($organizationId: ID) {
    organization(organizationId: $organizationId) {
      surveyGroups {
        id
        name
        status
        from
        topicGroup {
          typeCode
          name
          translations {
            value
            language {
              code
            }
          }
          steps {
            id
            startOffsetMins
            reminders {
              reminderOffsetMins
            }
            endOffsetMins
            triggeredOn
            stepTopicGroup {
              id
              translations {
                value
                description
                language {
                  code
                }
              }
            }
          }
        }
        statistics {
          total
          answered
        }
      }
    }
  }
`;

export const getOrganizationTeams = gql`
  query organizationTeams($organizationId: ID!) {
    organizationTeams(organizationId: $organizationId) {
      id
      name
      rootTeam
      structureId
      leader {
        id
        firstname
        surname
        originator
        contacts {
          type
          source
          value
        }
      }
      members {
        id
        firstname
        surname
        preferredContactType
        originator
        createdAt
        contacts {
          type
          source
          value
          phoneNumber {
            countryCallingCode
            nationalNumber
          }
        }
        language {
          id
          code
        }
        disabled
      }
    }
  }
`;

export const getOrganizationAdmins = gql`
  query organizationAdmins($organizationId: ID!) {
    organizationAdmins(organizationId: $organizationId) {
      id
      user {
        id
      }
      contacts {
        source
        type
        value
      }
    }
  }
`;

export const getOrganizationSettings = gql`
  query OrganizationSetting($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      name
      systemName
      primaryLanguageCode
      productVersion {
        product
      }
      productVersionHistory {
        product
        licenceCount
        validFrom
        validTo
      }
      organizationAdmins {
        id
        firstname
        surname
        user {
          id
          username
        }
      }
      languages {
        id
        code
      }
      allRespondents {
        id
        firstname
        surname
        organizationRole
        contacts {
          type
          value
          source
        }
      }
      config {
        timezone
        countryCode
        countryCallingCode
      }
      featureNames
    }
  }
`;

export const getOrganizationSettingsReport = gql`
  query OrganizationSettingReport($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      name
      primaryLanguageId
      primaryLanguageCode
      teams {
        id
        name
        members {
          id
          firstname
          surname
          contacts {
            type
            value
          }
        }
      }
      languages {
        id
        code
      }
      allRespondents {
        id
        firstname
        surname
        organizationRole
        team {
          id
        }
        disabled
        contacts {
          type
          value
        }
      }
    }
  }
`;

export const getLanguages = gql`
  query Languages($filter: LanguageFilterInput) {
    languages(filter: $filter) {
      id
      code
    }
  }
`;

export const getReportRecipients = gql`
  query ReportRecipients($organizationId: ID!) {
    reportRecipients(organizationId: $organizationId) {
      id
      respondentDeleted
      teamDeleted
      respondent {
        id
        firstname
        surname
        contacts {
          type
          value
        }
      }
      team {
        id
        name
      }
      language {
        id
        code
      }
      type
    }
  }
`;

export const getOrganizationImportHeadersQuery = gql`
  query getOrganizationImportFileHeaders {
    getOrganizationImportFileHeaders {
      languageId
      header
      language {
        code
      }
    }
  }
`;

export const removeRespondentFromReportSubscribe = gql`
  mutation RemoveRespondentfromReportSubscribe($respondentId: ID!, $teamId: ID, $type: String!) {
    removeRespondentfromReportSubscribe(respondentId: $respondentId, teamId: $teamId, type: $type)
  }
`;

export const addRespondentToReportSubscribe = gql`
  mutation AddRespondentToReportSubscribe($respondentId: ID!, $teamId: ID, $type: String!, $language: ID!) {
    addRespondentToReportSubscribe(respondentId: $respondentId, teamId: $teamId, type: $type, language: $language)
  }
`;

export const exportOrganizationQuery = gql`
  query exportOrganization($organizationId: ID!) {
    exportOrganization(organizationId: $organizationId) {
      mimetype
      name
      contents
    }
  }
`;

export const getOrganizationRespondentsCount = gql`
  query getOrganizationRespondentsCount($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      respondentsCount
    }
  }
`;

export const getOrganizationsQuery = gql`
  query getOrganizations {
    user {
      id
      allowedOrganizations {
        id
        name
        systemName
      }
      organization {
        id
        name
        systemName
      }
    }
  }
`;

export const getReportAccesses = gql`
  query ReportAccess($surveyId: ID!) {
    survey(surveyId: $surveyId) {
      sendAt
      endsAt
      topic {
        name
        topicGroup {
          name
          translations {
            id
            language {
              code
            }
            value
          }
        }
      }
      status
      reports {
        id
        accessKey
        respondent {
          id
          firstname
          surname
          contacts {
            type
            value
          }
        }
        accepted
        sent
        teams {
          id
          name
          leader {
            id
          }
        }
        readAt
        resultsInfo {
          answered
          total
        }
      }
      resultsInfo {
        answered
        total
      }
      reportRecipients {
        team {
          id
        }
        validFrom
        validTo
        respondent {
          id
        }
      }
    }
  }
`;

export const getSequenceReportAccesses = gql`
  query SequenceReportAccesses($surveyGroupId: ID!) {
    surveyGroup(surveyGroupId: $surveyGroupId) {
      from
      topicGroup {
        id
        name
        translations {
          id
          language {
            code
          }
          value
        }
      }
      status
      isAnySurveyComplete
      steps {
        processStep {
          startOffsetMins
          reminders {
            reminderOffsetMins
          }
          endOffsetMins
          stepTopicGroup {
            name
            translations {
              id
              language {
                code
              }
              value
            }
          }
        }
        daysFromStart
        status
        hasNotStarted
        notstartedReason
        statistics {
          answered
          total
        }
      }
      reports {
        id
        accessKey
        respondent {
          id
          firstname
          surname
          contacts {
            type
            value
          }
        }
        accepted
        sent
        teams {
          id
          name
          leader {
            id
          }
        }
        readAt
      }
      reportRecipients {
        team {
          id
        }
        validFrom
        validTo
        respondent {
          id
        }
      }
    }
  }
`;

export const getTopicGroup = gql`
  query topicGroup($topicGroupId: ID!, $language: ID) {
    topicGroup(topicGroupId: $topicGroupId, language: $language) {
      id
      name
      description
      organization {
        id
      }
      translations {
        value
        description
        language {
          id
        }
      }
      steps {
        id
        startOffsetMins
        reminders {
          reminderOffsetMins
        }
        endOffsetMins
        stepTopicGroup {
          id
          organization {
            id
          }
          lastValidTopic {
            id
            name
          }
          lastValidTopic {
            id
          }
          translations {
            value
            description
            language {
              id
              code
            }
          }
        }
      }
      name
      description
      translations {
        language {
          id
          code
        }
        value
        id
        description
      }
      typeCode
    }
  }
`;

export const getPricelistQuery = gql`
  query getPricelist($language: String!) {
    getPricelist(language: $language) {
      product
      duration
      language {
        id
        code
      }
      price
      currency
    }
  }
`;

export const getTranslationExport = gql`
  query exportTranslations($topicId: ID!, $languageCode: String, $topicGroupId: ID) {
    exportTranslations(topicId: $topicId, languageCode: $languageCode, topicGroupId: $topicGroupId) {
      mimetype
      name
      contents
    }
  }
`;

export const getSurveys = gql`
  query surveys($languageCode: String) {
    user(languageCode: $languageCode) {
      organization {
        surveys {
          id
          public
          status
          whitelistedRespondents {
            id
          }
          resultsInfo {
            answered
            total
          }
          topic {
            name
          }
          topicGroup {
            name
          }
          sendAt
        }
      }
    }
  }
`;

export const getPublicTopicGroups = gql`
  query publicTopicGroups($language: String!, $product: String) {
    publicTopicGroups(language: $language, product: $product) {
      id
      name
    }
  }
`;

export const getLastMessagesForRespondent = gql`
  query lastMessagesForRespondent($surveyIds: [ID!]!, $respondentIds: [ID!]!) {
    lastMessagesForRespondent(surveyIds: $surveyIds, respondentIds: $respondentIds) {
      id
      messageStatus
      processed
      createdAt
      error
      surveyId
    }
  }
`;
