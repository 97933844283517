import { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

function ScrollToTop({ history }: RouteComponentProps<any>) {
  useEffect(() => {
    const unlisten = history.listen((location, action) => {
      if (location.pathname.includes('/process/') && location.search.includes('editing')) {
        return null;
      }

      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
