import { Loading } from '../../components';
import { USER_KEY } from '../../lib/auth';
import { AccessTokenStatus, useGetBaseUserQuery } from '../../generated/hooks';
import RegistrationForm from './RegistrationForm';
import ConfirmRegistrationForm from './ConfirmRegistrationForm';
import { IPropsRegistrationForm } from './common';

interface IProps extends IPropsRegistrationForm {
  accessToken: string;
}

export const RegistartionFromWithAccessToken = ({ accessToken, history, lang, channel, countryCode }: IProps) => {
  localStorage.removeItem(USER_KEY);
  const { loading, data } = useGetBaseUserQuery({
    variables: {
      accessToken,
    },
  });

  if (loading) {
    return <Loading />;
  }

  if (data?.isAccessTokenValid.status === AccessTokenStatus.Revoked && data.isAccessTokenValid.consent) {
    history.push('/login');
  }

  if (
    data?.isAccessTokenValid.status === AccessTokenStatus.Valid &&
    data?.isAccessTokenValid.baseUser &&
    !data.isAccessTokenValid.consent
  ) {
    return (
      <ConfirmRegistrationForm
        firstname={data.isAccessTokenValid.baseUser.firstname}
        surname={data.isAccessTokenValid.baseUser.surname}
        username={data.isAccessTokenValid.baseUser.username}
        organizationName={data.isAccessTokenValid.baseUser.organization!.name}
        history={history}
        accessToken={accessToken}
      />
    );
  }

  return <RegistrationForm history={history} lang={lang} channel={channel} countryCode={countryCode} />;
};

export default RegistartionFromWithAccessToken;
