import { formatDate } from '@arnold/common';
import { DateTooltip } from '../../components/SurveysOverview/RowParts';
import { SurveyTopicTitle } from '../Surveys/SurveyTopicTitle';
import { EDITION } from '../../lib/common';
import { TopicGroupTypeCode } from '../../generated/hooks';
import { GetDataSourceParams, SurveyArchiveData, isSurveyGroup } from './types';
import { SurveyArchiveActions } from './SurveyArchiveActions';

export const getDataSource = ({
  surveys,
  organization,
  t,
  toSurveyDetail,
  toProcessSurveyDetail,
  toOnboarding,
  sysAdmin,
}: GetDataSourceParams): SurveyArchiveData[] =>
  surveys.map((survey) => {
    const isOnboarding = isSurveyGroup(survey) && survey.topicGroup.typeCode === TopicGroupTypeCode.Onboarding;
    const toDetail = isSurveyGroup(survey) ? (isOnboarding ? toOnboarding : toProcessSurveyDetail) : toSurveyDetail;
    const label = isSurveyGroup(survey)
      ? survey.name || survey.topicGroup?.name
      : survey.topic?.name || survey.topicGroup?.name!;
    const startsAt = isSurveyGroup(survey) ? survey.from : survey.sendAt;
    const archivedAt = isSurveyGroup(survey)
      ? survey.steps.map((step) => step.surveys).flat()[0]?.archivedAt
      : survey.archivedAt;
    return {
      id: survey.id,
      topic: <SurveyTopicTitle survey={survey} label={label} />,
      archivedAt: formatDate(archivedAt),
      sendAt: (
        <DateTooltip startsAt={startsAt} endsAt={''} reminders={[]} displayOnlyStartDate={true}>
          {formatDate(startsAt)}
        </DateTooltip>
      ),
      actions: (
        <SurveyArchiveActions
          survey={survey}
          isSurveyGroup={isSurveyGroup(survey)}
          toSurveyDetail={toDetail}
          disableDearchive={organization.productVersion?.product === EDITION.FREE.name && !sysAdmin}
          t={t}
        />
      ),
    };
  });
