import { theme } from '@arnold/common';
import { ReactComponent as CloseIcon } from '@arnold/common/lib/assets/icons/Close.svg';
import styled from '@emotion/styled/macro';
import { FC, ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SurveyQuery } from '../../generated/hooks';
import { getPreferredContact } from '../../lib/helpers';
import { Table } from '../Table/Table';

type TableProps = {
  respondents: NonNullable<SurveyQuery['survey']>['whitelistedRespondents'];
  removeRespondent: (ids: string[]) => void;
  isDisabled?: boolean;
};

type ParticipantTableData = Pick<NonNullable<SurveyQuery['survey']>['whitelistedRespondents']['0'], 'id'> & {
  name: ReactNode;
  email: string;
  team: string;
  remove: ReactNode;
};

const RemoveButton = styled(Button)`
  display: flex !important;
  align-items: center;
  padding: 0;
  background: none;
  border: 0;
  margin: 0;

  &:hover,
  &:focus,
  &:active {
    background: none !important;
  }
  &:disabled {
    background: none !important;
    border: none !important;

    svg path {
      fill: ${theme.colors.actionPrimary.disabled};
    }
  }
`;

const RemoveLabelSpan = styled.span<Pick<TableProps, 'isDisabled'>>`
  margin-left: ${theme.spacing.e};
  font-size: ${theme.typography.body.medium!.button!.fontSize};
  font-weight: ${theme.typography.body.medium!.button!.fontWeight};
  color: ${({ isDisabled }) =>
    `${isDisabled ? theme.colors.actionPrimary.disabled : theme.colors.actionPrimary.default}`};
`;

export const SurveyRespondentsTable: FC<TableProps> = ({ respondents, removeRespondent, isDisabled }) => {
  const { t } = useTranslation('surveyForm');

  return (
    <>
      <Table<ParticipantTableData>
        dataCypressTestFlag={'respondent-whitelist'}
        rowDataCypressTestFlag={'respondent-item'}
        thin={true}
        columns={[
          {
            key: 'name',
            title: t('report:firstname'),
            dataIndex: 'name',
          },
          {
            key: 'email',
            title: t('surveyContact'),
            dataIndex: 'email',
          },
          {
            key: 'team',
            title: t('survey:team'),
            dataIndex: 'team',
          },
          {
            key: 'remove',
            title: undefined,
            dataIndex: 'remove',
          },
        ]}
        dataSources={respondents.map((respondent) => ({
          id: respondent.id,
          name: (
            <div data-cy="survey-participant-respondent-name">{`${respondent.firstname} ${respondent.surname}`}</div>
          ),
          email: getPreferredContact(respondent.preferredContactType, respondent.contacts || []).value,
          team: t('team', { team: respondent.team?.name || t('teams:withoutLeader') }),
          remove: (
            <RemoveButton
              data-cy={'btn-remove-respondent'}
              onClick={() => removeRespondent([respondent.id])}
              disabled={isDisabled}
            >
              <CloseIcon />
              <RemoveLabelSpan isDisabled={isDisabled}>{t('removeRespondent')}</RemoveLabelSpan>
            </RemoveButton>
          ),
        }))}
        selected={undefined}
      />
    </>
  );
};
