import { HeadingLarge } from '@arnold/common';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Card, PageWrapper, PublicNavbar, ScreenWrapper } from '../../components/Common';
import GraphQLErrorCard from '../../components/GraphQLErrorCard';
import Loading from '../../components/Loading';
import { useGetPricelistQuery } from '../../generated/hooks';
import { EDITION } from '../../lib/common';
import storage from '../../lib/storage';
import i18n from '../../translations/i18n';
import OrderForm from '../OrderEdition/OrderForm';

const Modal = styled.div`
  height: 100vh;
  background-color: white;
`;

export const WebOrderModal = () => {
  const { t } = useTranslation(['addRespondent']);
  const { lang } = useParams<{ lang?: string }>();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const edition = query.get('edition');
  const withFullLayout = query.get('withFullLayout') === 'true';

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loading, data, error } = useGetPricelistQuery({
    variables: {
      language: lang || storage.getDetectedLanguage()?.substring(0, 2) || 'en',
    },
  });
  if (error) {
    return <GraphQLErrorCard />;
  }

  if (loading) {
    return <Loading />;
  }
  if (withFullLayout) {
    return (
      <ScreenWrapper className="wrapper" data-cy="admin-page">
        <PublicNavbar />
        <PageWrapper data-cy="admin-page-content">
          <HeadingLarge>{t('editionSettings:order')}</HeadingLarge>
          <Card>
            <OrderForm
              pricelist={data?.getPricelist}
              edition={(edition as keyof typeof EDITION) || EDITION.ESSENTIAL.name}
              withTeamioArnoldOnboarding={query.get('withTeamioArnoldOnboarding') === 'true'}
            />
          </Card>
        </PageWrapper>
      </ScreenWrapper>
    );
  }
  return (
    <Modal>
      <OrderForm
        pricelist={data?.getPricelist}
        edition={(edition as keyof typeof EDITION) || EDITION.ESSENTIAL.name}
        withTeamioArnoldOnboarding={query.get('withTeamioArnoldOnboarding') === 'true'}
      />
    </Modal>
  );
};
