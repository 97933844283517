import { keyframes } from '@emotion/react/macro';
import styled from '@emotion/styled/macro';
import React from 'react';

const SuccessIcon = () => (
  <Wrapper>
    <CirclePartDown />
    <SuccessTip />
    <SuccessLong />
    <Circle />
    <TipLong />
    <CirclePartUp />
  </Wrapper>
);

export default SuccessIcon;

const Wrapper = styled.div(() => ({
  width: 80,
  height: 80,
  borderWidth: 4,
  borderStyle: 'solid',
  borderColor: 'rgb(76, 174, 76)',
  borderRadius: '50%',
  margin: '20px auto',
  position: 'relative',
  boxSizing: 'content-box',
}));

const CirclePartDown = styled.div(() => ({
  borderRadius: '120px 0px 0px 120px',
  position: 'absolute',
  width: 60,
  height: 100,
  background: 'white',
  transform: 'rotate(-45deg)',
  top: -7,
  left: -33,
  transformOrigin: '60px 60px',
}));

const SuccessTip = styled.div(() => ({
  height: 5,
  backgroundColor: 'rgb(92, 184, 92)',
  display: 'block',
  borderRadius: 2,
  position: 'absolute',
  zIndex: 2,
  width: 25,
  left: 14,
  top: 46,
  transform: 'rotate(45deg)',
  animation: `0.75s ease 0s 1 normal none running ${AnimateSuccessTip}`,
}));

const SuccessLong = styled.div(() => ({
  height: 5,
  backgroundColor: 'rgb(92, 184, 92)',
  display: 'block',
  borderRadius: 2,
  position: 'absolute',
  zIndex: 2,
  width: 47,
  right: 8,
  top: 38,
  transform: 'rotate(-45deg)',
  animation: `0.75s ease 0s 1 normal none running ${AnimateSuccessLong}`,
}));

const Circle = styled.div(() => ({
  width: 80,
  height: 80,
  border: '4px solid rgba(92, 184, 92, 0.2)',
  borderRadius: '50%',
  boxSizing: 'content-box',
  position: 'absolute',
  left: -4,
  top: -4,
  zIndex: 2,
}));

const TipLong = styled.div(() => ({
  width: 5,
  height: 90,
  backgroundColor: 'rgb(255, 255, 255)',
  position: 'absolute',
  left: 28,
  top: 8,
  zIndex: 1,
  transform: 'rotate(-45deg)',
}));

const CirclePartUp = styled.div(() => ({
  borderRadius: '0px 120px 120px 0px',
  position: 'absolute',
  width: 60,
  height: 120,
  background: 'white',
  transform: 'rotate(-45deg)',
  top: -11,
  left: 30,
  transformOrigin: '0px 60px',
  animation: `4.25s ease-in 0s 1 normal none running ${RotatePlaceholder}`,
}));

const AnimateSuccessTip = keyframes`
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
`;
const AnimateSuccessLong = keyframes`
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
`;

const RotatePlaceholder = keyframes`
    0% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    5% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
    12% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }
    100% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }
  }
`;
