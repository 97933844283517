import { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import auth from '../lib/auth';
import { AuthorizedPageLoader } from './AuthorizedPageLoader';

type PrivateRouterProps = RouteProps & {
  leftNavbarOnly?: boolean;
  hideSidebar?: boolean;
  disableSavingAccessToken?: boolean;
};

const PrivateRouter: FC<PrivateRouterProps> = ({
  component: Component,
  leftNavbarOnly,
  hideSidebar,
  disableSavingAccessToken,
  ...rest
}) => {
  const isLoggedIn = rest.location
    ? auth.isLoggedIn(rest.location.search, !disableSavingAccessToken)
    : auth.isLoggedIn();
  const user = auth.getUser();
  return (
    <Route
      {...rest}
      render={(props: any) => {
        return isLoggedIn && user && Component != null ? (
          <AuthorizedPageLoader
            location={rest.location}
            user={user}
            leftNavbarOnly={leftNavbarOnly}
            hideSidebar={hideSidebar}
            Component={Component}
            search={rest.location?.search}
            {...rest}
          />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        );
      }}
    />
  );
};

export default PrivateRouter;
