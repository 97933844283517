import { TFunction, Trans } from 'react-i18next';
import { DisabledSpan, GraySpan, SelectedSpan } from '../../../components/Common';

export const getStartOffsetDaysLabel = (index: number, selected?: boolean) => (
  <Trans
    i18nKey={'processDetail:day'}
    values={{ dayNo: index }}
    components={selected ? [<SelectedSpan />] : [<GraySpan />]}
  >
    Day 1
  </Trans>
);

export const getOffsetDaysLabel = (index: number, t: TFunction, selected?: boolean, disabled?: boolean) => {
  const Component = disabled ? DisabledSpan : selected ? SelectedSpan : GraySpan;

  return (
    <div>
      {disabled ? <DisabledSpan>{`+ ${index} `}</DisabledSpan> : `+ ${index} `}
      <Component>
        {t('days', {
          count: index,
          postProcess: 'interval',
        })}
      </Component>
    </div>
  );
};
