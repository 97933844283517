import styled from '@emotion/styled';
import { theme } from '../../theme';
import { Check, Line } from '../../assets/icons';

const boxSize = 18;

const Label = styled.label<Pick<IProps, 'paddingMultiplier' | 'labelStyles' | 'disabled' | 'dataCy'>>`
  display: block;
  position: relative;
  padding-left: ${({ paddingMultiplier }) => (paddingMultiplier ?? 2) * boxSize}px;
  cursor: pointer;
  user-select: none;
  margin-top: 1px;
  margin-bottom: 0px;
  & input:focus + span {
    box-shadow: 0px 0px 6px ${theme.colors.borderMain.focused};
  }
  ${({ labelStyles }) => labelStyles}
  color: ${({ disabled }) => (disabled ? theme.colors.text.disabled : theme.colors.backgroundDark.default)} !important;
  font-size: ${theme.typography.body.medium.regular.fontSize} !important;
  line-height: ${theme.typography.body.medium.regular.lineHeight};
`;

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

interface ICheckMarkProps {
  selected?: boolean;
  disabled?: boolean;
  checkMarkStyles?: string;
}

const CheckMark = styled.span<ICheckMarkProps>`
  position: absolute;
  top: 2px;
  left: 0;
  border: 1px solid
    ${({ selected, disabled }) =>
      disabled
        ? theme.colors.borderMain.disabled
        : selected
          ? theme.colors.borderMain.focused
          : theme.colors.borderMain.default};
  padding-left: ${({ selected }) => (selected ? 1 : 0)}px;
  padding-top: ${({ selected }) => (selected ? 1 : 0)}px;
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${boxSize}px;
  width: ${boxSize}px;
  transition: border-color 0.25s ease;
  background-color: ${theme.colors.backgroundCover.default};
  ${({ checkMarkStyles }) => checkMarkStyles}

  &:hover {
    border: ${theme.spacing.a} solid ${theme.colors.borderMain.hover};
  }
`;

interface IProps {
  onToggle: (e: React.MouseEvent<any, MouseEvent>) => void;
  selected: boolean;
  partlySelected?: boolean;
  text: React.ReactNode;
  checkIcon?: React.ReactNode;
  lineIcon?: React.ReactNode;
  paddingMultiplier?: number;
  labelStyles?: string;
  disabled?: boolean;
  checkMarkStyles?: string;
  dataCy?: string;
}

export const Checkbox = ({
  selected,
  partlySelected = false,
  onToggle,
  text,
  checkIcon = <Check />,
  lineIcon = <Line />,
  paddingMultiplier,
  labelStyles,
  disabled,
  checkMarkStyles,
  dataCy,
}: IProps) => {
  return (
    <Label
      onClick={(e) => {
        e.preventDefault();
        if (!disabled) {
          onToggle(e);
        }
      }}
      paddingMultiplier={paddingMultiplier}
      labelStyles={labelStyles}
      disabled={disabled}
      data-cy={dataCy}
    >
      {text}
      <Input type="checkbox" disabled={disabled} />
      <CheckMark className="inline-checkbox" selected={selected} disabled={disabled} checkMarkStyles={checkMarkStyles}>
        {selected ? checkIcon : partlySelected && lineIcon}
      </CheckMark>
    </Label>
  );
};
