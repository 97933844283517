import { useHistory } from 'react-router-dom';
import { findLast, findLastIndex } from 'ramda';
import ee from '../../lib/eventEmitter';
import {
  CreateQuestionDefinitionMutation,
  UpdateQuestionDefinitionMutation,
  useCreateQuestionDefinitionMutation,
  useDeleteQuestionDefinitionMutation,
  useUpdateQuestionDefinitionMutation,
} from '../../generated/hooks';
import { mapQuestionToCreateQuestionVariables, prepareQuestionForUpdate } from './helpers';
import { QuestionDetail } from './topicStructure';
import { SectionState } from './sectionState';

type UpdaterArgs = {
  topicId: string;
  sectionIndex: number;
  languageId: string;
  reloadTopic: () => Promise<void>;
  state: SectionState;
};

const useSectionUpdater = ({ topicId, sectionIndex, languageId, reloadTopic, state }: UpdaterArgs) => {
  const history = useHistory();
  const [saveQuestionUpdate] = useUpdateQuestionDefinitionMutation();
  const [saveQuestionDeletion] = useDeleteQuestionDefinitionMutation();
  const [saveQuestionCreation] = useCreateQuestionDefinitionMutation();

  const saveChanges = async (
    questionsToUpdate: QuestionDetail[],
    questionsToDelete: QuestionDetail[],
    questionsToCreate: QuestionDetail[],
  ) => {
    ee.emitModalLoading();
    let topic: UpdateQuestionDefinitionMutation['updateQuestionDefinition'] | null = null;
    const getQuestionId = (question: QuestionDetail) => {
      if (topic) {
        const questionWithNewId = topic.allQuestions.find((newQuestion) => newQuestion.index === question.index);
        if (!questionWithNewId) {
          throw new Error('Question not found in new topic');
        }
        return questionWithNewId.id;
      }
      return question.id;
    };

    for (const question of questionsToUpdate) {
      const data = await saveQuestionUpdate({
        variables: {
          id: getQuestionId(question),
          input: prepareQuestionForUpdate(question),
        },
      });
      if (!data.data) {
        ee.emitModalLoadingDismiss();
        throw new Error('No data returned from API');
      }
      topic = data.data.updateQuestionDefinition;
    }
    const questionsToDeleteIndexes = questionsToDelete.map((question) => question.index);
    for (const question of questionsToDelete) {
      const defaultRule = question.rules.find((rule) => rule.origValue === '');
      let ruleToUse: QuestionDetail['rules'][0] | undefined = defaultRule || question.rules[0];
      while (ruleToUse && questionsToDeleteIndexes.includes(ruleToUse.nextQuestion.index)) {
        const nextQuestion =
          // eslint-disable-next-line no-loop-func
          questionsToDelete.find((question) => question.index === ruleToUse?.nextQuestion.index) ||
          // eslint-disable-next-line no-loop-func
          state.questions.find((question) => question.index === ruleToUse?.nextQuestion.index);
        ruleToUse = nextQuestion?.rules.find((rule) => rule.origValue === '') || nextQuestion?.rules[0];
      }
      const data = await saveQuestionDeletion({
        variables: {
          id: getQuestionId(question),
          replaceWithQuestionWithIndex: ruleToUse && ruleToUse.nextQuestion.index?.toString(),
        },
      });
      if (!data.data) {
        ee.emitModalLoadingDismiss();
        throw new Error('No data returned from API');
      }
      topic = data.data.deleteQuestionDefinition;
    }

    const createdQuestionIds = questionsToCreate.map((q1) => q1.id);
    const createdQuestions: CreateQuestionDefinitionMutation['createQuestionDefinition'][] = [];
    for (const question of state.questions) {
      const questionArrayIndex = state.questions.findIndex((q) => q.id === question.id);

      const isDefaultOnly = question.rules.every((r) => !r.origValue);
      const newFollowingQuestion = createdQuestionIds.includes(question.id)
        ? questionArrayIndex === state.questions.length - 1 && state.questions.length < 2
          ? question.rules?.find((rule) => !rule.origValue)?.nextQuestion || state.nextSectionStartingQuestion
          : state.questions.find((q, i) => i > questionArrayIndex && !createdQuestionIds.includes(q.id)) ||
            state.questions[state.questions.length - 1]?.rules[0]?.nextQuestion ||
            state.nextSectionStartingQuestion
        : undefined;

      if (createdQuestionIds.includes(question.id)) {
        if (questionArrayIndex < state.questions.length - 1) {
          // rules of not last question in section are set according to surrounding questions
          question.rules = [];
        }

        const variables = mapQuestionToCreateQuestionVariables(
          topicId,
          question,
          questionArrayIndex
            ? [
                createdQuestionIds.includes(state.questions[questionArrayIndex - 1].id)
                  ? createdQuestions[createdQuestions.length - 1].index!
                  : state.questions[questionArrayIndex - 1].index!,
              ]
            : state.previousSectionRoutingQuestions?.map((q) => q.index!) || [],
          undefined,
          newFollowingQuestion && newFollowingQuestion.index,
        );

        const data = await saveQuestionCreation({
          variables,
        });
        if (!data.data) {
          ee.emitModalLoadingDismiss();
          throw new Error('No data returned from API');
        }
        topic = topic || data.data.createQuestionDefinition.topic;

        createdQuestions.push(data.data.createQuestionDefinition);
      }

      if (state.questionSubsections && state.questionSubsections.has(question.id)) {
        for (const [value, sq] of Object.entries(state.questionSubsections.get(question.id) || {})) {
          const subQuestions = sq.flat();
          const isDefaultRule = value === 'default';
          const subsectionFollowingQuestion =
            (subQuestions.length > 1 && findLast((sq) => !!sq.rules.length, subQuestions)?.rules[0].nextQuestion) ||
            question.rules.find((r) => ((isDefaultOnly || isDefaultRule) && !r.origValue) || r.origValue === value)
              ?.nextQuestion;
          for (const [index, subQuestion] of Array.from(subQuestions.entries())) {
            if (createdQuestionIds.includes(subQuestion.id)) {
              if (index < subQuestions.length - 1) {
                // rules of not last tell in subsection are set according to surrounding tells and parentQuestion
                subQuestion.rules = [];
              }
              const nextQuestion = subQuestion.rules.length
                ? subQuestion.rules.find((rule) => !rule.origValue)?.nextQuestion
                : subsectionFollowingQuestion
                  ? subsectionFollowingQuestion
                  : newFollowingQuestion;
              const variables = mapQuestionToCreateQuestionVariables(
                topicId,
                subQuestion,
                [
                  index
                    ? createdQuestionIds.includes(subQuestions[index - 1].id)
                      ? createdQuestions[createdQuestions.length - 1].index!
                      : subQuestions[index - 1].index!
                    : createdQuestionIds.includes(question.id)
                      ? createdQuestions[
                          findLastIndex((q) => q.__typename === 'SelectQuestionDefinition', createdQuestions) !== -1
                            ? findLastIndex((q) => q.__typename === 'SelectQuestionDefinition', createdQuestions)
                            : createdQuestions.length - 1
                        ].index!
                      : question.index!,
                ],
                index || !value ? undefined : value,
                !subQuestions[index + 1] || createdQuestionIds.includes(subQuestions[index + 1].id)
                  ? nextQuestion && nextQuestion.index
                  : subQuestions[index + 1].index,
              );

              const data = await saveQuestionCreation({
                variables,
              });
              if (!data.data) {
                ee.emitModalLoadingDismiss();
                throw new Error('No data returned from API');
              }
              topic = topic || data.data.createQuestionDefinition.topic;

              createdQuestions.push(data.data.createQuestionDefinition);
            }
          }
        }
      }
    }

    if (topic && topicId !== topic.id) {
      ee.emitModalLoadingDismiss();
      history.push({
        pathname: `/topic/${topic.id}/${languageId}`,
        hash: `section_${sectionIndex}`,
      });
    } else {
      await reloadTopic();
      setTimeout(() => {
        ee.emitModalLoadingDismiss();
      }, 150);
    }
  };
  return saveChanges;
};

export default useSectionUpdater;
