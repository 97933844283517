import styled from '@emotion/styled/macro';
import { Button } from 'react-bootstrap';

export const ChangeAddedOnButton = styled(Button)`
  margin: 0;
  margin-left: 18px;
  position: relative;
  text-transform: none;
  top: -10px;
`;
