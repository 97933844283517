import { FC } from 'react';
import { Button } from 'react-bootstrap';
import styled from '@emotion/styled';
import { ButtonProps } from './types';

export const CancelButton: FC<ButtonProps> = ({
  onClick,
  button: { show = true, title, dataCy } = { show: false },
}) => {
  if (!show) {
    return null;
  }

  return (
    <NoMarginButton variant="outline-primary" data-cy={dataCy || `btn-modal-cancel`} onClick={onClick}>
      {title}
    </NoMarginButton>
  );
};

const NoMarginButton = styled(Button)`
  margin-left: 0 !important;
`;
