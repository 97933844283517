import { PureQueryOptions } from '@apollo/client';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { LinkButton, theme, useToast } from '@arnold/common';
import { ReactComponent as MailOpened } from '@arnold/common/lib/assets/icons/MailOpened.svg';
import { Table } from '../../components/Table/Table';
import { ReportAccessQuery, SequenceReportAccessesQuery, SurveysAndSurveyGroupsQuery } from '../../generated/hooks';
import { getDataSource } from './getDataSource';
import { ReportAccessDetail } from './ReportAccessDetail';
import { ReportData } from './types';
import { combineSurveysAndSequences, getColumns, useAcceptReports } from './utils';

type Organization = NonNullable<SurveysAndSurveyGroupsQuery['user']['organization']>;

interface IProps {
  dependantQueries: Array<string | PureQueryOptions>;
  showOpenIcon?: boolean;
  surveys?: ReportAccessQuery['survey'][] | Organization['surveys'];
  surveyGroups?:
    | SequenceReportAccessesQuery['surveyGroup'][]
    | Organization['surveyGroups']
    | NonNullable<SurveysAndSurveyGroupsQuery['user']['organization']>['surveyGroups'];
  showAdditionalInfoInTable?: boolean;
}

const BulkActionsWrap = styled.div`
  padding-left: ${theme.spacing.k};
`;

const Container = styled.div`
  margin-bottom: ${theme.spacing.f};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledMailOpened = styled(MailOpened)`
  margin-right: ${theme.spacing.c};
`;

export const ReportAccessTable: FC<IProps> = ({
  dependantQueries,
  showOpenIcon,
  surveys,
  surveyGroups,
  showAdditionalInfoInTable,
}) => {
  const [selectedReports, setSelectedReports] = useState<string[]>([]);
  const [t] = useTranslation('ReportAccess');
  const { addToast } = useToast();
  const acceptReports = useAcceptReports(
    t,
    addToast,
    () => setSelectedReports([]),
    selectedReports,
    dependantQueries,
    true,
  );
  const suspendReports = useAcceptReports(
    t,
    addToast,
    () => setSelectedReports([]),
    selectedReports,
    dependantQueries,
    false,
  );

  const toggleReport = (id: string | string[]) => {
    if (Array.isArray(id)) {
      setSelectedReports(id);
      return;
    }

    const selectedIds = selectedReports.slice();
    const reportIndex = selectedReports.indexOf(id);
    if (reportIndex !== -1) {
      selectedIds.splice(reportIndex, 1);
    } else {
      selectedIds.push(id);
    }

    setSelectedReports(selectedIds);
  };

  const tableItems = combineSurveysAndSequences(surveys || [], surveyGroups || []);

  const isOngoing = tableItems.every((item) => item.isOngoing);

  const allSentReports = tableItems
    .map((item) => item.data?.reports)
    .flat()
    .filter((report) => report?.respondent && report.sent);
  const opened = allSentReports.filter((report) => report?.readAt).length;

  return (
    <>
      <Container>
        {dependantQueries.length > 0 && (
          <BulkActionsWrap>
            <LinkButton
              disabled={!selectedReports.length}
              onClick={() => acceptReports()}
              data-icom={'btn-accept-reports'}
            >
              {t(isOngoing ? 'acceptAllOngoing' : 'acceptAll')}
            </LinkButton>
            <LinkButton
              disabled={!selectedReports.length}
              onClick={() => suspendReports()}
              data-icom={'btn-suspend-reports'}
            >
              {t('rejectAll')}
            </LinkButton>
          </BulkActionsWrap>
        )}
        <div>
          <StyledMailOpened />
          {t('openReportRate', {
            opened,
            total: allSentReports.length,
            percentage: allSentReports.length === 0 ? 0 : Math.round((opened / allSentReports.length) * 100),
          })}
        </div>
      </Container>
      <Table<ReportData>
        columns={getColumns(t, showOpenIcon, showAdditionalInfoInTable)}
        dataSources={getDataSource({
          t,
          showOpenIcon,
          tableItems,
          dependantQueries,
        })}
        selected={undefined}
        renderDetailPanel={(rowData) => <ReportAccessDetail rowData={rowData} />}
        rowSelection={{
          selected: selectedReports,
          onChange: toggleReport,
        }}
      />
    </>
  );
};
