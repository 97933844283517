import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { theme } from '../../../theme';
import { CardHeaderContainer, Header } from '../SurveyCardComponents';

type SurveyCardProps = {
  header: string;
  headerIcon: string;
  upperTitle: ReactNode;
  graph: ReactNode;
  lowerTitle: ReactNode;
  lowerTitleMobile: ReactNode;
  footer: ReactNode;
  dataIcom?: string;
};

export const SurveyCard: FC<SurveyCardProps> = ({
  header,
  headerIcon,
  upperTitle,
  graph,
  lowerTitle,
  lowerTitleMobile,
  footer,
  dataIcom,
}) => {
  return (
    <StylesSurveyCard data-icom={dataIcom}>
      <StyledCardHeaderContainer>
        <HeaderIcon src={headerIcon} />
        <Header>{header}</Header>
      </StyledCardHeaderContainer>
      <UpperTitleContainer>{upperTitle}</UpperTitleContainer>
      <GraphContainer>{graph}</GraphContainer>
      <LowerTitleContainer>{lowerTitle}</LowerTitleContainer>
      <LowerTitleMobileContainer>{lowerTitleMobile}</LowerTitleMobileContainer>
      <FooterContainer>{footer}</FooterContainer>
    </StylesSurveyCard>
  );
};

const BREAK_POINT = '992px';

const StylesSurveyCard = styled.div`
  width: 288px;
  height: 205px;
  background-color: ${theme.background.primary};
  border-radius: 6px;
  box-shadow: 0px 10px 14px rgba(0, 0, 0, 0.01);
  padding: ${theme.spacing.h} ${theme.spacing.g} ${theme.spacing.g} ${theme.spacing.g};
  text-align: center;
  display: inline-block;

  @media (min-width: ${BREAK_POINT}) {
    height: 416px;
  }
`;

const HeaderIcon = styled.img`
  width: ${theme.spacing.g};
  height: ${theme.spacing.g};
`;

const StyledCardHeaderContainer = styled(CardHeaderContainer)`
  padding-bottom: ${theme.spacing.f};
  @media (min-width: ${BREAK_POINT}) {
    padding-bottom: 20px;
  }
`;

const UpperTitleContainer = styled.div``;

const GraphContainer = styled.div`
  display: none;
  padding: ${theme.spacing.g};

  @media (min-width: ${BREAK_POINT}) {
    display: flex;
    justify-content: center;
  }
`;

const LowerTitleContainer = styled.div`
  display: none;

  @media (min-width: ${BREAK_POINT}) {
    display: block;
  }
`;

const LowerTitleMobileContainer = styled.div`
  display: block;
  padding-top: 8px;

  @media (min-width: ${BREAK_POINT}) {
    display: none;
    padding-top: 0;
  }
`;

const FooterContainer = styled.div`
  padding-top: ${theme.spacing.f};

  @media (min-width: ${BREAK_POINT}) {
    padding-top: ${theme.spacing.g};
  }
`;
