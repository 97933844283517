import styled from '@emotion/styled';
import { StringKeysOf, ValueOf } from '@arnold/common';
import { FC } from 'react';
import { FormikErrors } from 'formik';
import { useTranslation } from 'react-i18next';
import { SalesValues } from '../types';
import { TextInput, SelectInput, NumberInput } from './Inputs';
import { getLanguageOptions } from './utils';

type SalesFieldsProps = {
  errors: FormikErrors<SalesValues>;
  setFieldValue: (key: StringKeysOf<SalesValues>, value: ValueOf<SalesValues>) => void;
  values: SalesValues;
};

export const SalesFields: FC<SalesFieldsProps> = (props) => {
  const { t } = useTranslation('salesForm');
  const [languageT] = useTranslation('languageSelector');

  return (
    <FieldsContainer>
      <TextInput {...props} title={t('fields.organizationName.title')} name="organizationName" />
      <SelectInput
        {...props}
        title={t('fields.language.title')}
        name="language"
        options={getLanguageOptions(languageT)}
      />
      <TextInput {...props} title={t('fields.vatNumber.title')} name="vatNumber" />

      <NumberInput {...props} title={t('fields.respondentsCount.title')} name="respondentsCount" />
      <TextInput {...props} title={t('fields.adminEmail.title')} name="adminEmail" />
      <TextInput {...props} title={t('fields.adminName.title')} name="adminName" />
      <TextInput {...props} title={t('fields.adminSurname.title')} name="adminSurname" />
    </FieldsContainer>
  );
};

const FieldsContainer = styled.div`
  display: grid;
  justify-content: start;
`;
