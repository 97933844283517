import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { ButtonProps } from './types';

export const SubmitButton: FC<ButtonProps> = ({ onClick, button: { show = true, title, disabled = false } = {} }) => {
  if (!show) {
    return null;
  }

  return (
    <Button variant="primary" data-cy={`btn-modal-submit`} onClick={onClick} disabled={disabled}>
      {title}
    </Button>
  );
};
