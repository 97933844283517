import * as React from 'react';
import { ClassicPreset, NodeId } from 'rete';
import { ClassicScheme, ReactArea2D, RefComponent } from 'rete-react-plugin';
import { GetSockets, Side } from 'rete-react-plugin/_types/presets/classic/types';

type Props<Scheme extends ClassicScheme> = {
  name: string;
  emit: (props: ReactArea2D<Scheme>) => void;
  side: Side;
  nodeId: NodeId;
  socketKey: string;
  payload: ClassicPreset.Socket;
};

export function RefSocket<Scheme extends ClassicScheme>({
  name,
  emit,
  nodeId,
  side,
  socketKey,
  payload,
  ...props
}: Props<Scheme>) {
  return (
    <RefComponent
      {...props}
      className={name}
      init={(ref) =>
        emit({
          type: 'render',
          data: {
            type: 'socket',
            side,
            key: socketKey,
            nodeId,
            element: ref,
            payload: payload as GetSockets<Scheme['Node']>,
          },
        })
      }
      unmount={(ref) => emit({ type: 'unmount', data: { element: ref } })}
    />
  );
}
