import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Breadcrumb, HeadingLarge } from '@arnold/common';
import { PageWrapper } from '../../components/Common';
import { IsAccessTokenValidQuery, OrganizationQuery, UserQuery } from '../../generated/hooks';
import Pricelist from './Pricelist';

interface ILocationState {
  productVersion: NonNullable<NonNullable<UserQuery['user']['organization']>['productVersionHistory']>[0];
}

interface IProps {
  user: NonNullable<IsAccessTokenValidQuery['isAccessTokenValid']['user']>;
  organization: NonNullable<OrganizationQuery['organization']>;
}

export const PricingScreen = ({ user, organization }: IProps) => {
  const location = useLocation<ILocationState>();
  const [t] = useTranslation('editionSettings');
  const history = useHistory();
  const { productVersion } = location.state || {};

  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb
        classList={'mt-n6'}
        items={[
          { title: t('AuthorizedPage:settings'), link: '/settings' },
          {
            title: t('pricelist'),
          },
        ]}
      />
      <HeadingLarge>{t('pricelist')}</HeadingLarge>

      <Pricelist
        onClick={(edition: string) => {
          if (productVersion) {
            history.push({
              pathname: '/settings/order',
              state: { productVersion: { ...productVersion, product: edition } },
            });
          } else {
            history.push({
              pathname: `/settings/order/${edition}`,
            });
          }
        }}
        user={user}
        organization={organization}
        activeProduct={productVersion ? productVersion.product : undefined}
      />
    </PageWrapper>
  );
};

export default PricingScreen;
