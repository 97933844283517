import * as React from 'react';
import { ClassicPreset } from 'rete';
import { Drag } from 'rete-react-plugin';
import { Tooltip } from '@arnold/common';

export class NumberWithTooltipControlData extends ClassicPreset.Control {
  constructor(
    public initialValue: number | null,
    public tooltip: string,
    public className: string,
    public change: (value: number | null) => void,
    public readOnly = false,
  ) {
    super();
  }
  initialValueChangedIndicator?: number;
}

export const NumberWithTooltipControl = (props: { data: NumberWithTooltipControlData }) => {
  const [value, setValue] = React.useState(props.data.initialValue);
  const ref = React.useRef(null);

  Drag.useNoDrag(ref);

  React.useEffect(() => {
    setValue(props.data.initialValue);
  }, [props.data.initialValue, props.data.initialValueChangedIndicator]);

  return (
    <Tooltip title={props.data.tooltip}>
      <input
        data-icom="input-question-order-ariel"
        data-cy="input-question-order-ariel"
        type="text"
        className={props.data.className}
        value={value ?? undefined}
        ref={ref}
        disabled={props.data.readOnly}
        onChange={(e) => {
          const inputValue = e.target.value;
          if (/^([1-9]\d{0,2})?$/.test(inputValue) === false) {
            e.preventDefault();
            e.stopPropagation();
            e.target.value = (value ?? '') + '';
            return;
          }
          const validValue = inputValue ? parseInt(inputValue, 10) : null;
          setValue(validValue);
          props.data.change(validValue);
        }}
      />
    </Tooltip>
  );
};
