import { theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import React from 'react';
import { History } from 'history';
import { useTranslation } from 'react-i18next';

export enum LandingPage {
  registration = 'register',
  login = 'login',
}

const TabContainer = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-bottom: ${theme.spacing.h};

  .selected button {
    border-bottom: ${theme.spacing.b} solid ${theme.colors.actionPrimary.default};
    color: ${theme.colors.text.primary};
  }
`;

const Tab = styled.li`
  flex: 1;
`;

const TabContent = styled.button`
  width: 100%;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  font-size: ${theme.typography.body.large?.regular?.fontSize};
  line-height: ${theme.typography.body.large?.regular?.lineHeight};
  border-bottom: ${theme.spacing.a} solid ${theme.colors.borderSeparator.default};
  color: ${theme.colors.text.secondary};
  padding-bottom: ${theme.spacing.f};
`;

interface IProps {
  history: History;
  selected: LandingPage;
  showOnlySelected?: boolean;
}

export const Tabs = ({ selected, history, showOnlySelected }: IProps) => {
  const { t } = useTranslation('registration');
  const search = history.location.search;
  return (
    <TabContainer>
      {(!showOnlySelected || selected === LandingPage.registration) && (
        <Tab className={`${selected === LandingPage.registration ? 'selected' : ''}`}>
          <TabContent
            data-icom="register-tab"
            onClick={() => selected !== LandingPage.registration && history.push({ pathname: '/register', search })}
          >
            {t('createAccount')}
          </TabContent>
        </Tab>
      )}
      {(!showOnlySelected || selected === LandingPage.login) && (
        <Tab className={`${selected === LandingPage.login ? 'selected' : ''}`}>
          <TabContent
            onClick={() => selected !== LandingPage.login && history.push({ pathname: '/login', search })}
            data-icom="login-tab"
          >
            {t('logIn')}
          </TabContent>
        </Tab>
      )}
    </TabContainer>
  );
};

export default Tabs;
