import { ReactNode } from 'react';
import { TFunction } from 'react-i18next';
import { UserQuery } from '../../generated/hooks';

export type SurveyArchiveData = Pick<NonNullable<UserQuery['user']['organization']>['surveys'][0], 'sendAt' | 'id'> & {
  archivedAt: ReactNode;
  topic: ReactNode;
  sendAt: ReactNode;
  actions: ReactNode;
};

export type Survey = NonNullable<UserQuery['user']['organization']>['surveys'][0];
export type SurveyGroup = NonNullable<UserQuery['user']['organization']>['surveyGroups'][0];

export type GetDataSourceParams = {
  organization: NonNullable<UserQuery['user']['organization']>;
  surveys: (Survey | SurveyGroup)[];
  t: TFunction;
  toSurveyDetail: (id: string) => void;
  toProcessSurveyDetail: (id: string) => void;
  toOnboarding: (id: string) => void;
  i18nLanguage: string;
  sysAdmin?: boolean;
};

export const isSurveyGroup = (surveyOrSurveyGroup: Survey | SurveyGroup): surveyOrSurveyGroup is SurveyGroup =>
  surveyOrSurveyGroup.__typename === 'SurveyGroup';
