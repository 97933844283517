import { MutationFunction } from '@apollo/client';
import { Mutation, Query } from '@apollo/client/react/components';
import { createEventData, pushToDataLayer, ThemeType, HeadingLarge, CardBody } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { map, sort } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import { assignOrganizationMutation } from '../../graphql/mutations';
import { getOrganizationsQuery, getUserQuery } from '../../graphql/queries';
import { getOperationName } from '../../lib/common';
import { getOrganizationName } from '../../lib/helpers';
import {
  AssignOrganizationMutation,
  AssignOrganizationMutationVariables,
  GetOrganizationsQuery,
} from '../../generated/hooks';

const OrganizationsScreen = (props: any) => {
  const [t] = useTranslation('organizationsScreen');

  return (
    <Query<GetOrganizationsQuery> query={getOrganizationsQuery}>
      {({ loading, error, data, client }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <></>;
        }
        if (data) {
          const {
            user: { allowedOrganizations, organization },
          } = data;

          if (allowedOrganizations.length <= 1) {
            return <Redirect to="/" />;
          }

          const list =
            allowedOrganizations &&
            allowedOrganizations.length > 1 &&
            sort(
              (a, b) => a.name.localeCompare(b.name),
              map<
                NonNullable<GetOrganizationsQuery['user']['allowedOrganizations'][0]>,
                NonNullable<GetOrganizationsQuery['user']['allowedOrganizations'][0]>
              >((organization) => {
                return {
                  ...organization,
                  name: getOrganizationName(organization.systemName, organization.name),
                };
              }, allowedOrganizations),
            ).map(({ id, name }) => (
              <tr key={id}>
                <td>
                  <Mutation<AssignOrganizationMutation, AssignOrganizationMutationVariables>
                    mutation={assignOrganizationMutation}
                    variables={{ organizationId: id }}
                    refetchQueries={[getOperationName(getUserQuery) || '']}
                    onCompleted={() => {
                      pushToDataLayer({
                        orgId: id,
                        event: 'ux.organization-switch',
                        ...createEventData(
                          'user',
                          'organizationSwitch',
                          'switch organization',
                          Number.parseInt(id, 10),
                        ),
                      });

                      client.cache.reset();
                      props.history.push('/');
                    }}
                  >
                    {(
                      assignOrganization: MutationFunction<
                        AssignOrganizationMutation,
                        AssignOrganizationMutationVariables
                      >,
                    ) =>
                      organization && organization.id === id ? (
                        <Item>
                          {name} - {t('selectedOrganization')}
                        </Item>
                      ) : (
                        <ItemLink onClick={() => assignOrganization()}>{name}</ItemLink>
                      )
                    }
                  </Mutation>
                </td>
              </tr>
            ));

          return (
            <PageWrapper data-cy="admin-page-content">
              <HeadingLarge>{t('selectOrganization')}</HeadingLarge>
              <Card>
                <CardBody>
                  <table className="table">
                    <tbody>{list}</tbody>
                  </table>
                </CardBody>
              </Card>
            </PageWrapper>
          );
        }
        return null;
      }}
    </Query>
  );
};

const Item = styled.span((props: { theme?: ThemeType }) => ({
  color: props.theme && props.theme.colorPrimary,
}));

const ItemLink = styled.span((props: { theme?: ThemeType }) => ({
  cursor: 'pointer',
  '&:focus, &:hover': {
    color: props.theme && props.theme.colorPrimary,
  },
}));

export default OrganizationsScreen;
