import styled from '@emotion/styled';
import { theme } from '../../theme';
import { BodySmall } from '../Typography';
import type { TableColumn, TableDataType } from './types';

type Props<T extends TableDataType> = {
  column: TableColumn<T>;
};

const StyledTh = styled.th`
  padding: 10px ${theme.spacing.f};
`;

export const TableHeadCell = <T extends TableDataType>({ column }: Props<T>): JSX.Element => {
  return (
    <StyledTh key={column.key} style={{ width: column.width }}>
      <BodySmall color={theme.colors.text.secondary}>{column.title}</BodySmall>
    </StyledTh>
  );
};
