import { usePath } from '@arnold/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loading } from '../../components';
import { PageLayout } from '../../components/Common/PageLayout';
import { TopicGroupOverview } from '../../components/TopicGroupOverview';
import i18n from '../../translations/i18n';
import { OrganizationQuery, TopicGroupTypeCode, useAllowedConversationSubjectQuery } from '../../generated/hooks';

interface IProps {
  organization: OrganizationQuery['organization'];
}

export const ChooseProcessTopicGroupScreen: FC<IProps> = ({ organization }) => {
  const { type } = useParams<{ type: string }>();
  const { t } = useTranslation('onboarding');
  const { t: surveyGroupT } = useTranslation('surveyGroup');
  const { onboardingPath, exitPath, processSurveysPath } = usePath();
  const { loading, data, error } = useAllowedConversationSubjectQuery({
    variables: {
      languageCode: i18n.language,
    },
  });

  const isOnboarding = type.toLocaleLowerCase() === TopicGroupTypeCode.Onboarding.toLowerCase();
  const isExit = type.toLocaleLowerCase() === TopicGroupTypeCode.Exit.toLowerCase();
  const isProcessSurvey = !isOnboarding && !isExit;

  const filteredTopicGroups = (data?.user.organization?.allowedConversationSubjects || []).filter(
    (conversationSubject) =>
      ((isOnboarding && conversationSubject.typeCode === TopicGroupTypeCode.Onboarding) ||
        (isExit && conversationSubject.typeCode === TopicGroupTypeCode.Exit) ||
        isProcessSurvey) &&
      conversationSubject.process,
  );

  const dataIcom = isOnboarding
    ? 'heading-onboarding-topics-title'
    : isExit
      ? 'heading-exit-topics-title'
      : 'heading-process-topics-title';

  return (
    <PageLayout
      title={isOnboarding ? t('newOnboarding') : isExit ? surveyGroupT('newExit') : surveyGroupT('newProcess')}
      dataIcom={dataIcom}
      breadcrumbItems={[
        {
          title: `< ${t('back')}`,
          link: isOnboarding
            ? onboardingPath.createPath()
            : isExit
              ? exitPath.createPath()
              : processSurveysPath.createPath(),
        },
      ]}
    >
      {loading || error || !data || !data?.user.organization ? (
        <Loading />
      ) : (
        <TopicGroupOverview topicGroups={filteredTopicGroups} organization={organization} />
      )}
    </PageLayout>
  );
};
