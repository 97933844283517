import styled from '@emotion/styled';
import { theme, Tooltip } from '@arnold/common';
import { ReactComponent as MailOpened } from '@arnold/common/lib/assets/icons/MailOpened.svg';
import { ReactComponent as MailNotOpened } from '@arnold/common/lib/assets/icons/MailNotOpened.svg';
import { FC } from 'react';
import { getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import { TFunction } from 'react-i18next';
import i18n from '../../translations/i18n';
import { ReportAccessQuery, SequenceReportAccessesQuery } from '../../generated/hooks';

type ReporOpenInfoProps = {
  t: TFunction;
  report:
    | NonNullable<NonNullable<ReportAccessQuery['survey']>['reports']>[0]
    | NonNullable<SequenceReportAccessesQuery['surveyGroup']>['reports'][0];
  showOpenIcon?: boolean;
  status: string;
};

const StyledReportOpen = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.d};
`;

export const ReportOpenInfo: FC<ReporOpenInfoProps> = ({ t, report, showOpenIcon, status }) => {
  if (!report.sent) {
    return <>{status}</>;
  }
  return showOpenIcon && report.sent ? (
    <Tooltip title={report.readAt ? t('openInfo') : t('notOpenInfo')}>
      {report.readAt ? (
        <StyledReportOpen>
          <MailOpened data-icom={'admin-report-open-rate'} />
          <span>{report.readAt && getLocalizedDateWithoutLeadingZeroes(new Date(report.readAt), i18n.language)}</span>
        </StyledReportOpen>
      ) : (
        <MailNotOpened data-icom={'admin-report-open-rate'} />
      )}
    </Tooltip>
  ) : (
    <></>
  );
};
