import { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { PlainButton, RedoIcon, UndoIcon, theme } from '@arnold/common';
import History from 'rete-history-plugin/_types/history';
import { HistoryAction } from 'rete-history-plugin';
import { CustomNodeEditor } from '../rete';

const UndoRedoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${theme.colors.backgroundCover.default};
  border: 1px solid ${theme.colors.actionPrimary.default};
  border-radius: 100px;
  padding-left: 6px;
  padding-right: 6px;
`;

const UndoRedoButton = styled(PlainButton)<{ available: boolean }>`
  background-color: ${theme.colors.backgroundCover.default} !important;
  height: 36px;
  ${(props) =>
    props.available &&
    `
        & path {
            fill: ${theme.colors.actionPrimary.default};
        }
    `}
`;

export const UndoRedoButtons = ({ editor }: { editor: CustomNodeEditor }) => {
  const [undoAvailable, setUndoAvailable] = useState(false);
  const [redoAvailable, setRedoAvailable] = useState(false);

  const updateUndoRedoAvailability = useMemo(
    () => () => {
      const history: History<HistoryAction> = (editor.historyPlugin as any).history;
      setUndoAvailable(!!history.produced.length);
      setRedoAvailable(!!history.reserved.length);
    },
    [editor],
  );

  useEffect(() => {
    editor.addHistoryChangeListener(updateUndoRedoAvailability);

    return () => {
      editor.removeHistoryChangeListener(updateUndoRedoAvailability);
    };
  }, [editor, updateUndoRedoAvailability]);

  return (
    <UndoRedoWrapper>
      <UndoRedoButton
        onClick={() => {
          editor?.historyPlugin.undo();
          updateUndoRedoAvailability();
        }}
        available={undoAvailable}
        data-icom={'btn-undo-ariel'}
      >
        <UndoIcon />
      </UndoRedoButton>
      <UndoRedoButton
        onClick={() => {
          editor?.historyPlugin.redo();
          updateUndoRedoAvailability();
        }}
        available={redoAvailable}
        data-icom={'btn-redo-ariel'}
      >
        <RedoIcon />
      </UndoRedoButton>
    </UndoRedoWrapper>
  );
};
