import { useApolloClient, useMutation } from '@apollo/client';
import { Checkbox, Modal, createEventData, pushToDataLayer, usePath, useToast } from '@arnold/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { ProductCode } from '@arnold/core';
import { Loading } from '../../components';
import { ErrorPage } from '../../components/StyledComponents';
import SurveyGroup from '../../components/SurveyGroup/SurveyGroup';
import {
  OrganizationQuery,
  SurveyGroupWithGroupedRespondentsQuery,
  TopicGroupTypeCode,
  UserQuery,
  useAssignOrganizationMutation,
  useDeleteRespondentMutation,
  useSurveyGroupWithGroupedRespondentsQuery,
} from '../../generated/hooks';
import {
  addRespondentsToSurveyGroupMutation,
  changeAddedOnForSurveyGroupMutation,
  removeRespondentsFromSurveyGroupMutation,
} from '../../graphql/mutations';
import { getOrganizationStructure, getSurveyGroupWithGroupedRespondents } from '../../graphql/queries';
import { watchSurveyGroupChangesSubscription } from '../../graphql/subscriptions';
import { getTopicGroupName } from '../../lib/helpers';

type EditSurveyGroupScreenProps = RouteComponentProps<any> & {
  user: UserQuery['user'];
  organization: NonNullable<OrganizationQuery['organization']>;
};

const EditSurveyGroupScreen = (props: EditSurveyGroupScreenProps) => {
  const {
    match: { params },
    user,
    organization,
    location,
  } = props;
  const { i18n, t } = useTranslation('surveyGroup');
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteRespondentMutation] = useDeleteRespondentMutation();
  const [removeRespondentModalIds, setRemoveRespondentModalIds] = useState<string[] | undefined>();
  const [removeRespondentModalCheckboxChecked, setRemoveRespondentModalCheckboxChecked] = useState(false);
  const { addToast } = useToast();
  const client = useApolloClient();
  const { onboardingPath, processSurveysPath, exitPath } = usePath();

  const search = new URLSearchParams(location.search);
  const organizationId = search.get('organizationId');
  const [assignOrganizationMutation, { loading: assignOrganizationLoading }] = useAssignOrganizationMutation();
  useEffect(() => {
    if (organizationId && organizationId !== organization!.id) {
      assignOrganizationMutation({
        variables: {
          organizationId,
        },
        refetchQueries: [getOrganizationStructure],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    loading: surveyGroupLoading,
    error: surveyGroupError,
    data: surveyGroupData,
    refetch,
  } = useSurveyGroupWithGroupedRespondentsQuery({
    variables: { surveyGroupId: params.id },
  });

  const refetchPolicy = {
    refetchQueries: [
      { query: getSurveyGroupWithGroupedRespondents, variables: { surveyGroupId: params.id } },
      getOrganizationStructure,
    ],
    awaitRefetchQueries: true,
  };
  const [addRespondents] = useMutation(addRespondentsToSurveyGroupMutation, refetchPolicy);
  const [removeRespondents] = useMutation(removeRespondentsFromSurveyGroupMutation, refetchPolicy);
  const [changeAddedOn] = useMutation(changeAddedOnForSurveyGroupMutation, refetchPolicy);

  useEffect(() => {
    const subscription = client
      .subscribe({
        query: watchSurveyGroupChangesSubscription,
        variables: {
          surveyGroupId: params.id,
        },
        fetchPolicy: 'network-only',
      })
      .subscribe({
        next: () => {
          refetch({ surveyGroupId: params.id });
        },
        error: () => {
          refetch({ surveyGroupId: params.id });
        },
      });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  const handleRemoveRespondent = async () => {
    if (!removeRespondentModalIds || removeRespondentModalIds.length === 0) {
      return;
    }
    try {
      setLoading(true);
      await removeRespondents({
        variables: {
          respondentIds: removeRespondentModalIds,
          surveyGroupId: params.id,
        },
        onCompleted: () => setLoading(false),
      });
      if (removeRespondentModalCheckboxChecked) {
        setRemoveRespondentModalCheckboxChecked(false);
        for (const respondentId of removeRespondentModalIds) {
          await deleteRespondentMutation({
            variables: {
              respondentId,
            },
          });
        }
      }
      addToast(t('removeRespondentSuccess'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.update-survey-group-remove-respondent-success',
        ...createEventData('survey group', 'removeRespondentSuccess', 'survey group remove respondent success'),
      });
    } catch (e) {
      addToast(t('removeRespondentError'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.update-survey-group-remove-respondent-error',
        ...createEventData('survey group', 'removeRespondentError', 'survey group remove respondent error'),
      });
    }
  };

  const handleChangeAddedOn = async (oldAddedOn: Date, newAddedOn: Date) => {
    try {
      setLoading(true);
      await changeAddedOn({
        variables: {
          oldAddedOn,
          newAddedOn,
          surveyGroupId: params.id,
        },
        onCompleted: () => setLoading(false),
      });
      addToast(t('changeAddedOnSuccess'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.update-survey-group-change-add-on-success',
        ...createEventData('survey group', 'changeAddOnSuccess', 'survey group change add on success'),
      });
    } catch (e) {
      addToast(t('changeAddedOnError'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.update-survey-group-change-add-on-error',
        ...createEventData('survey group', 'changeAddOnError', 'survey group change add on error'),
      });
    }
  };

  const createSubGroup = async (respondentIds: string[], addedOn: Date, runOngoingSteps: string[]) => {
    try {
      setLoading(true);
      await addRespondents({
        variables: {
          surveyGroupId: params.id,
          respondentIds,
          addedOn,
          runOngoingSteps,
        },
        onCompleted: () => setLoading(false),
      });
      const numberOfUsedParticipants = organization.activeRespondentsInCurrentPeriodCount ?? 0;
      const numberOfMaxParticipants = organization.productVersion?.licenceCount ?? 0;
      if (organization.productVersion?.product === ProductCode.Teamio_Arnold) {
        addToast(
          t('addRespondentsSuccessTeamioOnboarding', {
            count: numberOfMaxParticipants - numberOfUsedParticipants - respondentIds.length,
          }),
        );
      } else {
        addToast(t('addRespondentsSuccess'));
      }
      return true;
    } catch (e) {
      addToast(t('addRespondentsError'));
    }
    return false;
  };
  const addRespondentsFunc = async (respondentIds: string[], addedOn: Date) => {
    try {
      setLoading(true);
      await addRespondents({
        variables: {
          surveyGroupId: params.id,
          respondentIds,
          addedOn,
        },
        onCompleted: () => setLoading(false),
      });
      addToast(t('addRespondentSuccess'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.update-survey-group-add-respondent-success',
        ...createEventData('survey group', 'addRespondentSuccess', 'survey group add respondent success'),
      });
    } catch (e) {
      addToast(t('addRespondentError'));
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.update-survey-group-add-respondent-error',
        ...createEventData('survey group', 'addRespondentError', 'survey group add respondent error'),
      });
    }
  };

  type RespondentType =
    | null
    | NonNullable<SurveyGroupWithGroupedRespondentsQuery['surveyGroup']>['groupedRespondents'][0]['respondents'][0];
  const removedRespondent = surveyGroupData?.surveyGroup?.groupedRespondents.reduce(
    (foundRespondent: RespondentType, groupedRespondents) => {
      if (foundRespondent) return foundRespondent;
      const resp = groupedRespondents.respondents.find((r) => removeRespondentModalIds?.includes(r.id));
      return resp ?? null;
    },
    null as RespondentType,
  );
  const RemoveRespondentModal = (
    <Modal
      show={!!removeRespondentModalIds}
      onHide={() => setRemoveRespondentModalIds(undefined)}
      onSubmit={handleRemoveRespondent}
      title={t('surveyForm:removeRespondentModalTitle')}
      content={
        <>
          <p>
            {t('surveyForm:removeRespondentModalText1')}{' '}
            <b>{`${removedRespondent?.firstname} ${removedRespondent?.surname}`}</b>{' '}
            {t('surveyForm:removeRespondentModalText2')}
          </p>
          <p>
            {t('surveyForm:removeRespondentModalText3')}
            <br />
            {t('surveyForm:removeRespondentModalText4')}
          </p>
          <Checkbox
            selected={removeRespondentModalCheckboxChecked}
            onToggle={() => setRemoveRespondentModalCheckboxChecked(!removeRespondentModalCheckboxChecked)}
            text={<span>{t('surveyForm:removeRespondentModalCheckboxText')}</span>}
          />
        </>
      }
      buttons={{
        submit: { title: t('surveyForm:removeRespondentModalSubmit') },
        cancel: { title: t('modal:cancel') },
      }}
    />
  );
  if (surveyGroupLoading || assignOrganizationLoading) {
    return <Loading />;
  }
  if (surveyGroupError) {
    return <ErrorPage>{t('common:loadError')}</ErrorPage>;
  }
  if (surveyGroupData?.surveyGroup) {
    return (
      <>
        <SurveyGroup
          headings={t('onboarding:addParticipants')}
          description={''}
          breadcrumb={[
            {
              title: t('ReportAccess:backToOverview'),
              link:
                surveyGroupData.surveyGroup.topicGroup.typeCode === TopicGroupTypeCode.Onboarding
                  ? onboardingPath.createPath()
                  : surveyGroupData.surveyGroup.topicGroup.typeCode === TopicGroupTypeCode.Exit
                    ? exitPath.createPath()
                    : processSurveysPath.createPath(),
            },
            {
              title: getTopicGroupName(i18n.language, surveyGroupData.surveyGroup.topicGroup) || '',
              active: true,
            },
          ]}
          respondentGroups={surveyGroupData.surveyGroup.groupedRespondents}
          organization={organization}
          topicGroup={surveyGroupData.surveyGroup.topicGroup}
          removeRespondent={async (respondentIds) => setRemoveRespondentModalIds(respondentIds)}
          changeAddedOn={handleChangeAddedOn}
          createSubGroup={createSubGroup}
          addRespondents={addRespondentsFunc}
          user={user}
          isEdit={true}
          loading={loading}
        />

        {RemoveRespondentModal}
      </>
    );
  }
  return <></>;
};
export default EditSurveyGroupScreen;
