import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { usePath } from '@arnold/common';
import { Card } from 'react-bootstrap';
import { Table } from '../../components/Table/Table';
import { UserQuery } from '../../generated/hooks';
import { ProcessSurveyData } from './types';
import { getColumns } from './utils';
import { getDataSource } from './getDataSource';

type SurveyTableProps = {
  organization: UserQuery['user']['organization'];
  surveyGroups: NonNullable<UserQuery['user']['organization']>['surveyGroups'];
};

export const ProcessSurveysTable: FC<SurveyTableProps> = ({ organization, surveyGroups }) => {
  const [surveyGroupT] = useTranslation('surveyGroup');
  const [onboardingT] = useTranslation('onboarding');

  const { processSurveyDetailPath } = usePath();

  return (
    <Card>
      <Table<ProcessSurveyData>
        columns={getColumns(surveyGroupT)}
        dataSources={getDataSource({
          surveyGroups,
          organization,
          toSurveyGroupDetail: processSurveyDetailPath.toFunc,
          t: onboardingT,
        })}
        selected={undefined}
      />
    </Card>
  );
};
