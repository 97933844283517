import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { theme } from '../../theme';
import PlainButton from './PlainButton';

const StyledButtonGroup = styled.div`
  border-radius: ${theme.spacing.d};
  background: ${theme.colors.backgroundBasic.default};
  display: inline-flex;
  vertical-align: middle;
  overflow: hidden;
  border: ${theme.spacing.a} solid ${theme.colors.borderMain.default};
`;

const StyledButton = styled(PlainButton)<Partial<ButtonProps>>`
  color: ${theme.colors.text.primary};
  font-size: ${theme.typography.body.small.button.fontSize};
  line-height: ${theme.typography.body.small.button.lineHeight};
  font-weight: ${theme.typography.body.small.button.fontWeight};
  padding: ${theme.spacing.d} ${theme.spacing.f};
  height: auto;
  margin: 0;
  border-radius: 0;
  width: inherit;
  background-color: ${theme.colors.backgroundCover.default};
  color: ${(props) => (props.disabled ? theme.colors.text.disabled : theme.colors.text.primary)};
`;

type ButtonProps = {
  title: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

type SubtleButtonProps = {
  buttons: ButtonProps[];
};

export const SubtleButton: FC<SubtleButtonProps> = ({ buttons }) => {
  return (
    <StyledButtonGroup>
      {buttons.map(({ title, onClick, disabled }) => (
        <StyledButton onClick={onClick} disabled={disabled}>
          {title}
        </StyledButton>
      ))}
    </StyledButtonGroup>
  );
};
