import * as React from 'react';

const RoundCheckedIcon = ({ size = 25, color = 'white' }) => {
  return (
    <svg
      width={(size / 25) * 26}
      height={size}
      viewBox={`0 0 ${(size / 25) * 26} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 25C11.2708 25 9.64583 24.6719 8.125 24.0156C6.60417 23.3594 5.28125 22.4688 4.15625 21.3438C3.03125 20.2188 2.14063 18.8958 1.48438 17.375C0.828125 15.8542 0.5 14.2292 0.5 12.5C0.5 10.7708 0.828125 9.14583 1.48438 7.625C2.14063 6.10417 3.03125 4.78125 4.15625 3.65625C5.28125 2.53125 6.60417 1.64063 8.125 0.984375C9.64583 0.328125 11.2708 0 13 0C14.3542 0 15.6354 0.197917 16.8438 0.59375C18.0521 0.989583 19.1667 1.54167 20.1875 2.25L18.375 4.09375C17.5833 3.59375 16.7396 3.20313 15.8438 2.92188C14.9479 2.64062 14 2.5 13 2.5C10.2292 2.5 7.86979 3.47396 5.92188 5.42188C3.97396 7.36979 3 9.72917 3 12.5C3 15.2708 3.97396 17.6302 5.92188 19.5781C7.86979 21.526 10.2292 22.5 13 22.5C15.7708 22.5 18.1302 21.526 20.0781 19.5781C22.026 17.6302 23 15.2708 23 12.5C23 12.125 22.9792 11.75 22.9375 11.375C22.8958 11 22.8333 10.6354 22.75 10.2812L24.7813 8.25C25.0104 8.91667 25.1875 9.60417 25.3125 10.3125C25.4375 11.0208 25.5 11.75 25.5 12.5C25.5 14.2292 25.1719 15.8542 24.5156 17.375C23.8594 18.8958 22.9688 20.2188 21.8438 21.3438C20.7188 22.4688 19.3958 23.3594 17.875 24.0156C16.3542 24.6719 14.7292 25 13 25ZM11.25 18.25L5.9375 12.9375L7.6875 11.1875L11.25 14.75L23.75 2.21875L25.5 3.96875L11.25 18.25Z"
        fill={color}
      />
    </svg>
  );
};

export default RoundCheckedIcon;
