import styled from '@emotion/styled/macro';
import React, { useEffect, ReactElement } from 'react';
import { History } from 'history';

import { theme, LanguageSelector } from '@arnold/common';
import { ReactComponent as LogoPrimary } from '@arnold/common/lib/assets/logo/LogoPrimaryDarkBkg.svg';
import { useTranslation } from 'react-i18next';
import i18n from '../../translations/i18n';
import { allowedLanguages } from '../../lib/common';

const PublicLayoutStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: ${theme.background.primary};
  @media (min-width: ${theme.breakpoint.small}) {
    flex-direction: row;
  }
`;

export const Aside = styled.div`
  background-color: ${theme.colors.brand.primary};
  padding: 0 ${theme.spacing.f};
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: ${theme.breakpoint.small}) {
    min-height: 100%;
    flex: 1;
    width: auto;
  }
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  padding: ${theme.spacing.h} ${theme.spacing.f} 96px ${theme.spacing.f};
  @media (min-width: ${theme.breakpoint.small}) {
    height: 100%;
    min-height: 100vh;
    flex: 1;
    padding: ${theme.spacing.k} ${theme.spacing.f} 96px ${theme.spacing.f};
  }
  @media (min-width: ${theme.breakpoint.large}) {
    padding: ${theme.spacing.l} ${theme.spacing.f};
  }
`;

const LanguageSelectorStyled = styled.div`
  display: block;
  position: absolute;
  top: ${theme.spacing.f};
  right: ${theme.spacing.f};

  svg path {
    fill: ${theme.colors.textInverted.primary};
  }

  @media (min-width: ${theme.breakpoint.small}) {
    svg path {
      fill: ${theme.colors.actionPrimary.default};
    }
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media (min-width: ${theme.breakpoint.small}) {
    width: 304px;
  }
  @media (min-width: ${theme.breakpoint.medium}) {
    width: 352px;
  }

  @media (min-width: ${theme.breakpoint.large}) {
    width: 464px;
  }
`;

const ContentForm = styled.div`
  flex: 1;
`;

const CookieConsentSetting = styled.div`
  padding-top: ${theme.spacing.h};
`;

interface IProps {
  basePath: string;
  children: ReactElement;
  aside?: ReactElement;
  lang?: string;
  history: History;
}

export const PublicLayout = ({ basePath, lang, aside, history, children }: IProps) => {
  const { t } = useTranslation('Navbar');
  const handleLanguageChange = (lang: string) => {
    const search = history.location.search;
    history.push({
      pathname: `/${basePath}/${lang}`,
      search,
    });
  };

  useEffect(() => {
    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return (
    <div data-cy="admin-page">
      <LanguageSelectorStyled>
        <LanguageSelector onChange={handleLanguageChange} lang={lang} allowedLanguages={allowedLanguages} />
      </LanguageSelectorStyled>
      <PublicLayoutStyled>
        <Aside>{aside ? aside : <LogoPrimary />}</Aside>
        <Content>
          <ContentContainer>
            <ContentForm>{children}</ContentForm>
            <CookieConsentSetting>
              <a href="" data-cc="c-settings">
                {t('cookieSettings')}
              </a>
            </CookieConsentSetting>
          </ContentContainer>
        </Content>
      </PublicLayoutStyled>
    </div>
  );
};

export default PublicLayout;
