import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { NewProcessSurvey } from '../../components/NewProcessSurvey';
import { TopicGroupTypeCode } from '../../generated/hooks';

type ParamsProps = {
  subjectId: string;
};

export const CreateExitScreen: FC<RouteComponentProps<ParamsProps>> = ({ match }) => {
  const { params } = match;
  const { t } = useTranslation('surveyGroup');
  return <NewProcessSurvey title={t('newExit')} topicGroupId={params.subjectId} typeCode={TopicGroupTypeCode.Exit} />;
};
