import styled from '@emotion/styled';
import { theme } from '../../theme';

type Props = {
  stylesOverridden?: string;
};
export const TableThinBodyCell = styled.td<Props>`
  ${({ stylesOverridden }) => stylesOverridden}
  padding: ${theme.spacing.d} ${theme.spacing.f};
  margin-left: ${theme.spacing.f};
  font-size: ${theme.typography.body.medium?.regular?.fontSize};
  line-height: ${theme.typography.body.medium?.regular?.lineHeight};
  color: ${theme.colors.text.primary};
`;
