import Switch from 'react-switch';
import { theme } from '../../theme';

export interface IPropsToggleButton {
  value: boolean;
  onChange: (checked: boolean) => void;
  dataCy?: string;
  className?: string;
  dataIcom?: string;
}

export const ToggleButton = ({ value, onChange, dataCy, dataIcom, className }: IPropsToggleButton) => {
  return (
    <label data-cy={dataCy} data-icom={dataIcom} className={className}>
      <Switch
        onChange={onChange}
        checked={value}
        offColor={`${theme.colors.actionSecondary.default}`}
        onColor={`${theme.colors.actionPrimary.default}`}
        uncheckedIcon={false}
        checkedIcon={false}
        height={24}
        width={40}
        handleDiameter={16}
      />
    </label>
  );
};

export default ToggleButton;
