import { Checkbox, SelectWrapper, TableColumn, selectMenuStyles, theme } from '@arnold/common';
import { ReactComponent as Check } from '@arnold/common/lib/assets/icons/Check.svg';
import { ReactComponent as Line } from '@arnold/common/lib/assets/icons/Line.svg';
import { TFunction } from 'react-i18next';
import { ReactNode } from 'react';
import styled from '@emotion/styled';
import {
  BaseRespondent,
  RespondentOnboarding,
  RespondentOneTimeSurveys,
  RespondentSurveyGroups,
} from '../../OrganizationImportCard';
import { SurveyGroupsQuery, SurveysQuery } from '../../../generated/hooks';
import DateInput from '../../Common/DateInput';
import { OnboardingData, OneTimeSurveyData, ProcessSurveyData } from './types';
import { TableHead } from './styled';

const DateCheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: ${theme.spacing.g} 1fr;
  align-items: center;
`;

export const getOnboardingColumns = (t: TFunction): Array<TableColumn<OnboardingData>> => [
  {
    key: 'employee',
    title: t('employee'),
    dataIndex: 'employee',
  },
  {
    key: 'addedOn',
    title: t('addedOn'),
    dataIndex: 'addedOn',
  },
  {
    key: 'onboarding',
    title: t('onboarding'),
    dataIndex: 'onboarding',
    width: '40%',
  },
];

export const getOnboardingDataSource = (
  onboardings: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
  employees: BaseRespondent[],
  onAddedOnChange: (email: string, addedOn: string) => void,
  onSurveyChange: (email: string, surveyGroupId: string) => void,
  t: TFunction,
  employeeOnboardingData: RespondentOnboarding,
) => {
  const options = onboardings.map((onb) => ({
    value: onb.id,
    label: onb.name || onb.topicGroup.name,
  }));

  return employees.map((employee) => {
    return {
      id: employee.email,
      employee: `${employee.firstname} ${employee.surname}`,
      addedOn: (
        <DateInput
          onDateChange={(value) => {
            onAddedOnChange(employee.email, value.toISOString());
          }}
          inputName={`addedOn-${employee.email}`}
          date={(employeeOnboardingData[employee.email] || {}).addedOn}
          key={`addedOn-${employee.email}`}
        />
      ),
      onboarding: (
        <SelectWrapper
          options={options}
          placeholder={t('select')}
          isSearchable={false}
          onChange={(option: { value: string; label: string }) => onSurveyChange(employee.email, option.value)}
          menuPlacement="auto"
          value={onboardings.length === 1 ? options[0] : undefined}
          styles={{
            menu: (provided: any, state: any) => ({
              ...provided,
              ...selectMenuStyles,
              top: '30px',
              bottom: 'auto',
              backgroundColor: `${theme.background.primary}`,
            }),
            container: (provided: any, state: any) => ({
              ...provided,
            }),
            control: (provided: any, state: any) => ({
              ...provided,
              paddingLeft: theme.spacing.f,
              borderColor: state.isFocused
                ? `${theme.colors.borderMain.active} !important`
                : theme.colors.borderMain.default,
              boxShadow: 'none',
              width: '220px',
            }),
          }}
          isDisabled={onboardings.length === 1}
          key={`survey-${employee.email}`}
        />
      ),
    };
  });
};

export const getProcessSurveyColumns = (
  t: TFunction,
  processSurveys: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
  onChange: (data: { [key: string]: { [key: string]: string | undefined } }) => void,
  employeeSurveyData: { [key: string]: { [key: string]: string | undefined } },
  employees: BaseRespondent[],
): Array<TableColumn<ProcessSurveyData>> => [
  {
    key: 'employee',
    title: t('employee'),
    dataIndex: 'employee',
  },
  ...processSurveys.map((processSurvey) => {
    const processSurveyData = employeeSurveyData[processSurvey.id] || {};
    return {
      key: processSurvey.id,
      title: (
        <Checkbox
          onToggle={() => {
            if (Object.keys(processSurveyData).length === employees.length) {
              onChange({ ...employeeSurveyData, [processSurvey.id]: {} });
            } else {
              const keys = Object.keys(processSurveyData);
              let newProcessSurveyData = processSurveyData;
              employees.forEach((employee) => {
                if (!keys.includes(employee.email)) {
                  newProcessSurveyData = { ...newProcessSurveyData, [employee.email]: undefined };
                }
              });
              onChange({ ...employeeSurveyData, [processSurvey.id]: newProcessSurveyData });
            }
          }}
          selected={Object.keys(processSurveyData).length === employees.length}
          partlySelected={
            Object.keys(processSurveyData).length < employees.length && Object.keys(processSurveyData).length !== 0
          }
          text={<div>{processSurvey.name || processSurvey.topicGroup.name}</div>}
          checkIcon={<Check />}
          lineIcon={<Line />}
          paddingMultiplier={0}
          labelStyles={'display: flex;'}
          checkMarkStyles={`position: relative; order: -1; top: 0; margin-right: ${theme.spacing.d};'`}
        />
      ),
      dataIndex: processSurvey.id,
    };
  }),
];

export const getProcessSurveyDataSource = (
  processSurveys: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
  employees: BaseRespondent[],
  employeeSurveyData: RespondentSurveyGroups,
  onAddedOnChange: (email: string, id: string, checked: boolean, addedOn?: string) => void,
) => {
  return employees.map((employee) => {
    const obj: { [key: string]: ReactNode } = {};
    for (const processSurvey of processSurveys) {
      const processSurveyData = employeeSurveyData[processSurvey.id] || {};
      const checked = Object.keys(processSurveyData).includes(employee.email);
      obj[processSurvey.id] = (
        <DateCheckboxContainer>
          <Checkbox
            onToggle={(event) => {
              onAddedOnChange(employee.email, processSurvey.id, !checked);
            }}
            selected={checked}
            partlySelected={false}
            text={''}
            checkIcon={<Check />}
            lineIcon={<Line />}
            paddingMultiplier={0}
            labelStyles={'display: flex; align-items: center;'}
            checkMarkStyles={`position: relative; order: -1; top: 0; margin-right: ${theme.spacing.d};'`}
          />
          <DateInput
            onDateChange={(value) => {
              onAddedOnChange(employee.email, processSurvey.id, true, value.toISOString());
            }}
            inputName={`addedOn-${employee.email}-${processSurvey.id}`}
            date={processSurveyData[employee.email]}
            key={`addedOn-${employee.email}-${processSurvey.id}-${checked}`}
          />
        </DateCheckboxContainer>
      );
    }

    return {
      id: employee.email,
      employee: `${employee.firstname} ${employee.surname}`,
      ...obj,
    };
  });
};

export const getOneTimeSurveyColumns = (
  t: TFunction,
  surveys: NonNullable<SurveysQuery['user']['organization']>['surveys'],
  onChange: (data: RespondentOneTimeSurveys) => void,
  employeeSurveyData: RespondentOneTimeSurveys,
  employees: BaseRespondent[],
): Array<TableColumn<OneTimeSurveyData>> => [
  {
    key: 'employee',
    title: t('employee'),
    dataIndex: 'employee',
  },
  ...surveys.map((survey) => {
    const surveyData = employeeSurveyData[survey.id] || [];
    return {
      key: survey.id,
      title: (
        <TableHead>
          <Checkbox
            onToggle={() => {
              if (
                surveyData.length ===
                employees.filter((employee) => !(employee.email.startsWith('#') && !employee.hasPhoneNumber)).length
              ) {
                onChange({ ...employeeSurveyData, [survey.id]: [] });
              } else {
                let newSurveyData = surveyData;
                employees.forEach((employee) => {
                  if (
                    !surveyData.includes(employee.email) &&
                    (!employee.email.startsWith('#') || employee.hasPhoneNumber)
                  ) {
                    newSurveyData = [...newSurveyData, employee.email];
                  }
                });
                onChange({ ...employeeSurveyData, [survey.id]: newSurveyData });
              }
            }}
            selected={surveyData.length === employees.length}
            partlySelected={surveyData.length < employees.length && surveyData.length !== 0}
            text={<div>{survey.topicGroup?.name}</div>}
            checkIcon={<Check />}
            lineIcon={<Line />}
            paddingMultiplier={0}
            labelStyles={'display: flex;'}
            checkMarkStyles={`position: relative; order: -1; top: 0; margin-right: ${theme.spacing.d};'`}
          />
        </TableHead>
      ),
      dataIndex: survey.id,
    };
  }),
];

export const getOneTimeSurveyDataSource = (
  surveys: NonNullable<SurveysQuery['user']['organization']>['surveys'],
  employees: BaseRespondent[],
  employeeSurveyData: { [key: string]: string[] },
  onAddedOnChange: (email: string, id: string, checked: boolean) => void,
) => {
  return employees.map((employee) => {
    const obj: { [key: string]: ReactNode } = {};
    for (const survey of surveys) {
      const surveyData = employeeSurveyData[survey.id] || [];
      const checked = surveyData.includes(employee.email);
      obj[survey.id] = (
        <DateCheckboxContainer>
          <Checkbox
            onToggle={() => {
              onAddedOnChange(employee.email, survey.id, !checked);
            }}
            disabled={employee.email.startsWith('#') && !employee.hasPhoneNumber}
            selected={checked}
            partlySelected={false}
            text={''}
            checkIcon={<Check />}
            lineIcon={<Line />}
            paddingMultiplier={0}
            labelStyles={'display: flex; align-items: center;'}
            checkMarkStyles={`position: relative; order: -1; top: 0; margin-right: ${theme.spacing.d};'`}
          />
        </DateCheckboxContainer>
      );
    }

    return {
      id: employee.email,
      employee: `${employee.firstname} ${employee.surname}`,
      ...obj,
    };
  });
};
