import SweetModal from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { AccessTokenStatus } from '../../generated/hooks';

export const ACCESS_TOKEN_ERROR = {
  [AccessTokenStatus.Expired]: {
    title: 'AccessTokenAlreadyExpiredTitle',
    description: 'AccessTokenAlreadyExpiredText',
  },
  [AccessTokenStatus.Revoked]: {
    title: 'AccessTokenAlreadyRevokedTitle',
    description: 'AccessTokenAlreadyRevokedText',
  },
  [AccessTokenStatus.ConsentNotFound]: {
    title: 'ConsentNotFoundTitle',
    description: 'ConsentNotFoundText',
  },
};

interface IProps {
  status: keyof typeof ACCESS_TOKEN_ERROR;
  onConfirmClick: () => void;
}

const AccessTokenValidation = ({ status, onConfirmClick }: IProps) => {
  const [t] = useTranslation('modal');

  return (
    <SweetModal
      type={'danger'}
      title={t(ACCESS_TOKEN_ERROR[status].title)}
      confirmBtnText={t('ok')}
      onConfirm={onConfirmClick()}
    >
      {t(ACCESS_TOKEN_ERROR[status].description)}
    </SweetModal>
  );
};

export default AccessTokenValidation;
