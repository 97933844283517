import {
  ActionMenu,
  BarChart,
  BodyMedium,
  BodySmall,
  cleanArray,
  ConditionalWrapper,
  Tabs,
  theme,
  Tooltip,
} from '@arnold/common';
import { ReactComponent as ActionMenuIcon } from '@arnold/common/lib/assets/icons/ActionMenuIcon.svg';
import { XAxisTickProps } from '@arnold/common/lib/components/charts/BarChart/BarChart';
import { ProductCode } from '@arnold/core';
import styled from '@emotion/styled';
import { FC, ReactNode, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { DashboardQuery, OrganizationQuery } from '../../generated/hooks';
import { getShortenedTitle } from '../../lib/helpers/surveyGroup';
import { NoOnboardingsInfo } from '../NoSGInfo/NoOnbardingsInfo';
import { RedText } from '../StyledComponents';
import { DashboardSection } from './DashboardSection';
import { getBarChartData, getSurveyGroup } from './utils';

const BarChartContainer = styled.div`
  background-color: ${theme.background.primary};
  padding: ${theme.spacing.g} ${theme.spacing.h};
  width: 100%;
`;

type OnboardingProps = {
  organization: NonNullable<OrganizationQuery['organization']>;
  surveyGroups: NonNullable<DashboardQuery['user']['organization']>['activeOnboadings'];
};

export const Onboarding: FC<OnboardingProps> = ({ surveyGroups, organization }) => {
  const { t } = useTranslation('dashboard');
  const { surveyGroupId } = useParams<{ surveyGroupId: string }>();
  const surveyGroup = getSurveyGroup(surveyGroups, surveyGroupId);

  const [selectedSurveyGroup, setSelectedSurveyGroup] = useState<
    NonNullable<DashboardQuery['user']['organization']>['activeOnboadings'][0] | undefined
  >(surveyGroups.length ? (surveyGroup ? surveyGroup : surveyGroups[0]) : undefined);
  const organizationEdition = organization.productVersion?.product;
  const selectedSurveyGroupIndex = surveyGroups.indexOf(selectedSurveyGroup!);
  const sortedSurveyGroups = [...surveyGroups];
  if (selectedSurveyGroupIndex > 2) {
    sortedSurveyGroups.splice(2, 0, sortedSurveyGroups.splice(selectedSurveyGroupIndex, 1)[0]);
  }

  const history = useHistory();

  useEffect(() => {
    if (surveyGroupId) {
      const surveyGroup = getSurveyGroup(surveyGroups, surveyGroupId);
      setSelectedSurveyGroup(surveyGroup);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyGroupId]);

  const barCharData = getBarChartData(selectedSurveyGroup);

  const chatSatisfactionScale =
    selectedSurveyGroup?.steps.map((step) => step.processStep.stepTopicGroup.chatSatisfactionScale || 0) || [];
  const maxScale = chatSatisfactionScale.length ? Math.max(...chatSatisfactionScale) : 0;

  const numberOfUsedParticipants = organization.activeRespondentsInCurrentPeriodCount ?? 0;
  const numberOfMaxParticipants = organization.productVersion?.licenceCount ?? 0;

  return (
    <DashboardSection
      title={t('onboarding')}
      buttons={<></>}
      key={'onboarding'}
      keyName={'onboarding'}
      explanation={maxScale === 0 ? undefined : t('scaleExplanation', { max: maxScale })}
    >
      {organization.productVersion?.product === ProductCode.Teamio_Arnold && (
        <BodyMedium margin={`${theme.spacing.d} 0 0`} className="w-100">
          <ConditionalWrapper
            condition={numberOfUsedParticipants >= numberOfMaxParticipants}
            wrapper={(children: ReactNode) => (
              <RedText>
                {children} {t('teamioOnb:usageExceeded')}
              </RedText>
            )}
          >
            <Trans
              i18nKey={`teamioOnb:usageInfo`}
              values={{ total: numberOfMaxParticipants, used: numberOfUsedParticipants }}
              components={[<strong />]}
            />
          </ConditionalWrapper>{' '}
          {numberOfMaxParticipants - numberOfUsedParticipants <= 5 && (
            <Trans i18nKey={`teamioOnb:orderMore`} components={[<a href={'/settings/order'} />]} />
          )}
        </BodyMedium>
      )}
      {surveyGroups?.length > 1 && (
        <Tabs
          items={cleanArray([
            ...sortedSurveyGroups.slice(0, 3).map((surveyGroup) => {
              return {
                title: getShortenedTitle(surveyGroup ? surveyGroup.name || surveyGroup.topicGroup.name || '' : ''),
                link: `/overview/${surveyGroup.id}`,
                active: surveyGroup.id === selectedSurveyGroup?.id,
              };
            }),
            surveyGroups.length > 3 && {
              title: (
                <ActionMenu
                  toggleIcon={<ActionMenuIcon />}
                  items={sortedSurveyGroups.slice(3).map((surveyGroup, i) => {
                    return {
                      label: getShortenedTitle(
                        surveyGroup ? surveyGroup.name || surveyGroup.topicGroup.name || '' : '',
                      ),
                      action: () => {
                        setSelectedSurveyGroup(surveyGroup);
                        history.push(`/overview/${surveyGroup.id}`);
                      },
                    };
                  })}
                />
              ),
            },
          ])}
          theme={theme}
        />
      )}
      {barCharData.length > 0 && (
        <BarChartContainer>
          <BarChart
            height={192}
            xAxisHeight={86}
            onClick={(chartData: { id: string; step: number }) =>
              history.push(`/onboarding/stepDetail/${chartData.id}/${chartData.step}`)
            }
            data={barCharData}
            CustomXAxisTick={(props: XAxisTickProps) => (
              <svg x={props.x - 16} y={props.y}>
                <foreignObject width="80" height="100">
                  <div>
                    <BodyMedium color={theme.colors.text.primary} margin={'10px 0 10px 0'}>
                      {Math.round((barCharData.find((data) => data.x === props.payload.value)?.y ?? 0) * 10) / 10}
                    </BodyMedium>
                    <Tooltip title={props.payload.value}>
                      <BodySmall color={theme.colors.text.secondary} ellipsis={2}>
                        {props.payload.value}
                      </BodySmall>
                    </Tooltip>
                  </div>
                </foreignObject>
              </svg>
            )}
            hideYAxis
          />
        </BarChartContainer>
      )}
      {!surveyGroups.length && <NoOnboardingsInfo edition={organizationEdition} />}
    </DashboardSection>
  );
};
