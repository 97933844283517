import { JSXElementConstructor, ReactElement } from 'react';

interface IProps {
  condition: boolean;
  wrapper: (
    children: ReactElement<any, string | JSXElementConstructor<any>>,
  ) => ReactElement<any, string | JSXElementConstructor<any>>;
  children: ReactElement<any, string | JSXElementConstructor<any>>;
}

export const ConditionalWrapper = ({ condition, wrapper, children }: IProps) =>
  condition ? wrapper(children) : children;
