import { FC } from 'react';
import styled from '@emotion/styled';
import { theme } from '@arnold/common';
import { useTranslation } from 'react-i18next';

type SurveyInfoProps = {
  answered: number;
  total?: number;
  status: string;
  sendAt: string;
  endsAt?: string;
  isPublic: boolean;
};

const SurveyInfoWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${theme.spacing.f} 0;
  width: 100%;
`;

export const SurveyInfo: FC<SurveyInfoProps> = ({ answered, total, status, sendAt, endsAt, isPublic }) => {
  const [t] = useTranslation('survey');

  return (
    <SurveyInfoWrap>
      <div>
        <div>{t('responseRate')}</div>
        <div>
          {isPublic ? answered : `${answered} / ${total} (${Math.round(total ? (answered / total) * 100 : 100)}%)`}
        </div>
      </div>
      <div>
        <div>{t('state')}</div>
        <div>{t(`status-${status}`)}</div>
      </div>
      <div>
        <div>{t('duration')}</div>
        <div>
          {endsAt
            ? t('durationRange', {
                from: sendAt,
                to: endsAt,
              })
            : t('from', {
                from: sendAt,
              })}
        </div>
      </div>
    </SurveyInfoWrap>
  );
};
