import { captureException, withScope } from '@sentry/browser';
import * as React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

interface IState {
  hasError: boolean;
}

interface IProps {
  children: any;
}

export default class ErrorBoundary extends React.Component<IProps, IState> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: any, errorInfo: any) {
    withScope((scope) => {
      scope.setExtras(errorInfo);
      captureException(error);
      this.setState({ hasError: true });
      console.error(error);
    });
  }

  handleRefresh = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <Container>
          <Row>
            <Col>
              <h1>Něco se pokazilo</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button onClick={this.handleRefresh}>Načíst znovu</Button>
            </Col>
          </Row>
        </Container>
      );
    }
    return this.props.children;
  }
}
