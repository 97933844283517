import { Mutation, Query } from '@apollo/client/react/components';
import { createEventData, pushToDataLayer, pushToDataLayerRespondentCreateSuccess, useToast } from '@arnold/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Loading } from '../../components';
import SurveyForm from '../../components/Forms/SurveyForm';
import { FormResults } from '../../components/Forms/formTypes';
import {
  AnonymityLevel,
  ConversationSubjectType,
  CreateSurveyMutation,
  CreateSurveyMutationVariables,
  DatesForNewSurvey,
  DatesForNewSurveyQuery,
  OrganizationQuery,
  UserQuery,
  useAllowedConversationSubjectQuery,
  useCreatePublicSurveyMutation,
} from '../../generated/hooks';
import { createSurveyMutation } from '../../graphql/mutations';
import {
  getDashboardQuery,
  getDatesForNewSurvey,
  getOrganizationStructure,
  getSurveyGroups,
  getUserQuery,
} from '../../graphql/queries';
import { getOperationName } from '../../lib/common';
import ee from '../../lib/eventEmitter';
import { hasTopicMissingTranslations } from '../../lib/helpers';
import i18n from '../../translations/i18n';

type BaseProps = RouteComponentProps<any> & {
  user: UserQuery['user'];
  organization: OrganizationQuery['organization'];
  subjectLabel: string;
  subjectId: string;
  subjectType: string;
};

type FormProps = BaseProps & {
  datesForNewSurvey: DatesForNewSurvey;
};

const CreateSurveyForm = ({
  subjectLabel,
  history,
  subjectId,
  subjectType,
  datesForNewSurvey,
  user,
  organization,
}: FormProps) => {
  const { addToast } = useToast();
  const [newRespondents, setNewRespondents] = useState<string[]>([]);
  const { loading, data } = useAllowedConversationSubjectQuery({
    variables: {
      languageCode: i18n.language,
    },
  });
  const [t] = useTranslation('toast');
  const [createPublicSurvey, { loading: publicSurveyLoading }] = useCreatePublicSurveyMutation();

  const handlePublicSurveySave = (endsAt?: Date) => {
    createPublicSurvey({
      variables: {
        input: {
          organizationId: user.organization!.id,
          topicId: data?.user.organization?.allowedConversationSubjects?.find((subject) => subject.id === subjectId)
            ?.topic?.id!,
          language: user.organization?.primaryLanguageCode!,
          endsAt,
        },
      },
      refetchQueries: () => [
        {
          query: getDashboardQuery,
          variables: {
            languageCode: i18n.language,
          },
        },
      ],
      onCompleted: (data) => {
        history.push('/overview');
        addToast('surveyForm:publicSurveyCreated');
        pushToDataLayer({
          userId: user.id,
          orgId: organization?.id,
          event: 'ux.create-public-survey-success',
          ...createEventData('survey', 'createPublicSuccess', 'public survey create success'),
        });
        ee.emitModalSharePublicSurvey(data.createPublicSurvey.accessKey!, data.createPublicSurvey.topic?.name!);
      },
    });
  };

  const handleTopicOnComplete = () => {
    addToast(t('toast:surveysOverviewNewSurveyPlanned'));
    history.push('/overview');
    pushToDataLayer({
      userId: user.id,
      orgId: organization?.id,
      event: 'ux.create-survey-success',
      ...createEventData('survey', 'createSuccess', 'survey create success'),
    });

    newRespondents.map(() => pushToDataLayerRespondentCreateSuccess(user));
  };

  const handleMutationError = () => {
    addToast(t('toast:changesFailedToSave'));
    pushToDataLayer({
      userId: user.id,
      orgId: organization?.id,
      event: 'ux.create-survey-error',
      ...createEventData('survey', 'createError', 'survey create error'),
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Mutation<CreateSurveyMutation, CreateSurveyMutationVariables>
      mutation={createSurveyMutation}
      refetchQueries={[
        getOperationName(getUserQuery) || '',
        {
          query: getOrganizationStructure,
          variables: { organizationId: organization?.id },
        },
        getSurveyGroups,
      ]}
      onCompleted={handleTopicOnComplete}
      onError={handleMutationError}
    >
      {(createSurvey, { loading }) => {
        const handleSave = (formResults: FormResults) => {
          createSurvey({
            variables: {
              input: {
                organizationId: user.organization!.id,
                conversationSubjectId: subjectId,
                conversationSubjectType: subjectType as ConversationSubjectType,
                sendAt: formResults.sendAt,
                endsAt: formResults.endsAt,
                reminders: formResults.reminders
                  ?.slice()
                  .filter((r) => r && r.getTime() > new Date().getTime())
                  .sort((a, b) => a.getTime() - b.getTime())
                  .map((reminderAt) => ({ reminderAt })),
                newRecipientEmails: formResults.participants?.map((p) => p.trim()),
                recipientIds: formResults.whitelist
                  ? (formResults.respondents.map((respondent) => respondent.id).filter(Boolean) as string[])
                  : undefined,
                sendEmailSms: formResults.sendEmailSms,
                anonymity: {
                  allowedLevels: formResults.anonymityLevels,
                  default: formResults.anonymityLevels.includes(AnonymityLevel.None)
                    ? AnonymityLevel.None
                    : AnonymityLevel.Organization,
                },
              },
            },
          });
        };
        return (
          <SurveyForm
            loading={loading || publicSurveyLoading}
            user={user}
            isEdit={false}
            respondents={[]}
            onSave={handleSave}
            onPublicSurveySave={handlePublicSurveySave}
            subjectLabel={subjectLabel}
            surveyDates={{
              ...datesForNewSurvey,
              reminders: [datesForNewSurvey.reminderAt, datesForNewSurvey.secondReminderAt],
            }}
            hasMissingTranslations={(invitedRespondents) =>
              hasTopicMissingTranslations(
                user.organization,
                data?.user.organization?.allowedConversationSubjects?.find((cs) => cs.id === subjectId)?.topic,
                invitedRespondents,
              )
            }
            setNewRespondents={setNewRespondents}
          />
        );
      }}
    </Mutation>
  );
};

const NewDatesLoader = (props: BaseProps) => {
  return (
    <Query<DatesForNewSurveyQuery>
      variables={{ organizationId: props.user.organization!.id }}
      query={getDatesForNewSurvey}
      fetchPolicy="network-only"
    >
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <></>;
        }
        if (data && data.datesForNewSurvey) {
          return <CreateSurveyForm {...props} datesForNewSurvey={data.datesForNewSurvey} />;
        }
        return null;
      }}
    </Query>
  );
};

const CreateSurveyScreen = (props: RouteComponentProps<any>) => {
  const {
    match: { params },
  } = props;
  const { i18n } = useTranslation('surveyForm');
  const [language, setLanguage] = useState<string>(i18n.language);
  let refetchFn: (a?: any) => any = () => null;

  useEffect(() => {
    setLanguage(i18n.language);
    refetchFn({ languageCode: i18n.language });
  }, [i18n.language]);

  return (
    <Query<UserQuery> variables={{ languageCode: language }} query={getUserQuery}>
      {({ loading, error, data, refetch }) => {
        refetchFn = refetch;
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <></>;
        }
        if (data && data.user && data.user.organization) {
          return <NewDatesLoader {...params} {...props} user={data.user} />;
        }
        return null;
      }}
    </Query>
  );
};
export default CreateSurveyScreen;
