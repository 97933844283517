import React, { ReactNode, useContext, useState } from 'react';
import { IToastProps } from './Toast';
import ToastContainer from './ToastContainer';
import { BeautifulToastConfig } from './BeautifulToast';

export type addToastFunction = (
  text: string,
  delay?: number,
  actionText?: string,
  actionCallback?: () => void,
  hideExisting?: boolean,
) => void;

export type addBeautifulToastFunction = (config: BeautifulToastConfig) => void;

export const ToastContext = React.createContext<{
  addToast: addToastFunction;
  addBeautifulToast: addBeautifulToastFunction;
}>(undefined!);

export type Props = {
  children?: ReactNode;
};

let id = 1;

export const ToastProvider = (props: Props) => {
  const [toasts, setToasts] = useState<IToastProps[] | undefined>(undefined);
  const [beautifulToast, setBeautifulToast] = useState<BeautifulToastConfig | undefined>(undefined);
  const { children } = props;

  const addToast = (
    text: string,
    delay?: number,
    actionText?: string,
    actionCallback?: () => void,
    hideExisting?: boolean,
  ) => {
    const newToast = {
      text,
      delay,
      actionText,
      actionCallback,
      show: true,
      id: id++,
    };
    setToasts((toasts) => {
      if (toasts === undefined) {
        return [newToast];
      }
      return [...(hideExisting ? toasts.map((toast) => ({ ...toast, show: false })) : toasts), newToast];
    });
  };

  const addBeautifulToast = (config: BeautifulToastConfig) => {
    config.lastCalledTime = Date.now();
    setBeautifulToast(config);
  };

  return (
    <ToastContext.Provider value={{ addToast, addBeautifulToast }}>
      <ToastContainer toasts={toasts} beautifulToast={beautifulToast} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
