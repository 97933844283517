import { IThemeProps, PlainButton } from '@arnold/common';
import { ReactComponent as SettingsIcon } from '@arnold/common/lib/assets/icons/Settings.svg';
import styled from '@emotion/styled/macro';
import React from 'react';

type ViewProps = {
  shouldGrow?: boolean;
};

export const View = styled.div`
  margin: 4px 16px 0 0;
  padding-top: 0;
  max-width: 80%;
  padding-bottom: 0;
  display: flex;
  ${({ shouldGrow }: ViewProps) => (shouldGrow ? 'flex: 1;' : '')}
  justify-content: flex-start;
  min-width: 58px;

  @media (min-width: 576px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const RoutingView = styled(View)`
  max-width: calc(80% + 56px);
`;

export const SubsectionWrap = styled.div`
  display: flex;
  margin-left: 80px;
`;

export const SectionWrap = styled.div`
  display: flex;
  margin-left: 40px;
  width: 100%;
`;

export const EditRow = styled.div`
  display: flex;
`;

const StyledPlainButton = styled(PlainButton)`
  margin-top: 4px;
`;

const StyledPlainButtonWithMarginRight = styled(StyledPlainButton)`
  margin-right: ${({ theme }: IThemeProps) => theme && theme.spacing.f};
`;

type PlainButtonProps = {
  onClick: () => void;
};

export const SettingsButton = ({ onClick }: PlainButtonProps) => (
  <StyledPlainButtonWithMarginRight onClick={onClick}>
    <SettingsIcon />
  </StyledPlainButtonWithMarginRight>
);

export const SubsectionArrow = styled.img`
  display: flex;
  align-self: flex-start;
  margin-left: inherit;
  position: relative;
  float: left;
  left: -132px;
`;

type SubsectionIconProps = { displayOnTop?: boolean };
export const SubsectionIcon = styled(SubsectionArrow)`
  height: 16px;
  width: 16px;
  margin-top: ${({ displayOnTop }: SubsectionIconProps) => (displayOnTop ? '12px' : '16px')};
  left: -123px;
  margin-right: 8px;
`;

export const SectionLinkView = styled.div`
  margin: 4px 17px 0 -117px;
  padding-top: 0;
  max-width: calc(100% - 0px);
  padding-bottom: 0;
  display: flex;
  ${({ shouldGrow }: ViewProps) => (shouldGrow ? 'flex: 1;' : '')}
  justify-content: flex-start;
  min-width: 58px;

  @media (min-width: 576px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const DefaultSectionLinkView = styled(SectionLinkView)`
  margin: 16px 0;
`;

export const RoutingSectionLinkView = styled(SectionLinkView)`
  margin: 0 16px 0 -91px;
`;
