import { BodyMedium } from '@arnold/common';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  count: number;
};
export const AddedEmployees: FC<Props> = ({ count }) => {
  const [t] = useTranslation('organizationImportCard');

  return <BodyMedium>{`${t('addedEmployees')}: ${count}`}</BodyMedium>;
};
