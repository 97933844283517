import { BodyMedium, Breadcrumb, createEventData, HeadingLarge, pushToDataLayer, theme, usePath } from '@arnold/common';
import { ReactComponent as WarningIcon } from '@arnold/common/lib/assets/icons/ErrorWarningIcon.svg';
import { ReactComponent as InfoIcon } from '@arnold/common/lib/assets/icons/InfoIcon.svg';
import { getLocale, ProductCode } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Loading } from '../../components';
import { ActionButton, PageWrapper } from '../../components/Common';
import { ErrorPage } from '../../components/StyledComponents';
import {
  Features,
  OrganizationQuery,
  TopicDetailQuery,
  useCloneTopicWithTopicGroupMutation,
  UserQuery,
  UserSysRole,
  useTopicDetailQuery,
  useUserQuery,
} from '../../generated/hooks';
import { getTopicGroupTranslationByLanguageId } from '../../lib/common';
import { scrollToElement } from './helpers';
import TopicPreview from './TopicPreview';
import { prepareQuestion, prepareSections, QuestionDetail, TopicStructure } from './topicStructure';

const TopicMetaDataWrap = styled.div`
  margin-top: ${theme.spacing.f};
  margin-bottom: ${theme.spacing.h};
`;

const TopicMetaData = styled.span`
  margin-right: ${theme.spacing.h};
  color: ${theme.colors.text.secondary};
`;

const WarningText = styled(BodyMedium)`
  display: flex;
  gap: ${theme.spacing.d};
  align-items: center;
  padding-bottom: ${theme.spacing.f};
`;

interface IProps {
  user: UserQuery['user'];
  organization?: OrganizationQuery['organization'] | null;
}

const TopicScreen = (props: RouteComponentProps<any> & IProps) => {
  const {
    match: { params },
  } = props;
  const { t, i18n } = useTranslation('topicOverview');
  const [topicStructure, setTopicStructure] = useState<TopicStructure | null>(null);
  const [allQuestions, setAllQuestions] = useState<QuestionDetail[]>([]);
  const [activeSection, setActiveSection] = useState<number | null>(null);
  const [sectionInEditMode, setSectionInEditMode] = useState<number | null>(null);
  const { topicEditor } = usePath();
  const [cloneTopicWithTopicGroup, { loading: cloningLoading }] = useCloneTopicWithTopicGroupMutation();

  const postProcessData = (responseData: TopicDetailQuery) => {
    setActiveSection(null);
    setSectionInEditMode(null);
    const allQuestions = responseData.topicDetail.allQuestions.map(prepareQuestion);
    setAllQuestions(allQuestions);
    const newTopicStructure = prepareSections(allQuestions);
    setTopicStructure(newTopicStructure);
    if (props.location.hash) {
      setTimeout(() => {
        scrollToElement(props.location.hash.slice(1));
        props.history.replace(props.location.pathname);
      }, 250);
    }
  };

  const { loading: userLoading, data: userData, error: userError } = useUserQuery();
  const { loading, error, data, refetch } = useTopicDetailQuery({
    variables: { topicId: params.id },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first',
    onCompleted: postProcessData,
  });

  const reloadTopic = async () => {
    const result = await refetch({ topicId: params.id });
    if (result && result.data) {
      postProcessData(result.data);
    }
  };

  const registerSvenLinkClick = () =>
    pushToDataLayer({
      userId: props.user.id,
      orgId: props.organization?.id,
      event: 'ux.click-conversation_graph-display-link',
      ...createEventData('topic', 'clickGraphDisplayLink', 'click graph display link'),
    });

  useEffect(() => {
    setTopicStructure(null);
    reloadTopic();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.language]);

  if (error || userError) {
    return <ErrorPage>{t('common:loadError')}</ErrorPage>;
  }
  if ((loading && !topicStructure) || userLoading || !topicStructure || !data || !userData || cloningLoading) {
    return <Loading />;
  }

  const { organization } = userData.user;

  if (!organization) {
    return null;
  }
  const languageId = params.language || organization.primaryLanguageId.toString(10);

  // Free organization with custom topic can't plan survey
  const freeOrgWithCustomTopic =
    organization.productVersion?.product === ProductCode.Free && data.topicDetail.topicGroup.organization?.id != null;

  const isEditable =
    !!data.topicDetail.topicGroup.organization && data.topicDetail.topicGroup.organization.id === organization.id;

  const goToAriel = async (toOriginal?: boolean) => {
    if (isEditable || toOriginal) {
      topicEditor.toFunc(data.topicDetail.topicGroup.id);
    } else {
      const clonedTopic = await cloneTopicWithTopicGroup({
        variables: {
          id: data.topicDetail.id,
        },
      });
      topicEditor.toFunc(clonedTopic.data?.cloneTopicWithTopicGroup?.topicGroup.id!);
    }
  };

  const topicGroupTranslation = getTopicGroupTranslationByLanguageId(
    languageId,
    data.topicDetail.topicGroup.translations,
  ) as NonNullable<TopicDetailQuery['topicDetail']['topicGroup']['translations']>[0];
  const title = topicGroupTranslation ? topicGroupTranslation.value : data.topicDetail.name;
  return (
    <PageWrapper data-cy="admin-page-content">
      <Breadcrumb
        classList={'mt-n6'}
        items={[
          { title: t('teamsScreen:surveys'), link: '/overview' },
          { title: t('ReportAccess:topics'), link: '/topics' },
          {
            title,
            active: true,
          },
        ]}
      />
      <HeadingLarge data-icom={'topic-preview-heading'}>{title}</HeadingLarge>
      {!data.topicDetail.isValid && (
        <WarningText color={theme.colors.text.secondary}>
          <WarningIcon /> {t('topicCannotBeScheduled')}
        </WarningText>
      )}
      {freeOrgWithCustomTopic && (
        <WarningText color={theme.colors.text.secondary}>
          <WarningIcon /> {t('surveysOverview:freeOrganizationsCannotPlanCustomTopics')}
        </WarningText>
      )}
      {!data.topicDetail.isEditable && (
        <WarningText color={theme.colors.text.secondary}>
          <InfoIcon /> {t('topicCannotBeEdited')}
        </WarningText>
      )}
      <ActionButton
        variant="primary"
        margin={`${theme.spacing.d} 0 ${theme.spacing.g} 0`}
        onClick={() => props.history.push(`/createSurvey/TOPIC_GROUP/${data.topicDetail.topicGroup.id}/${title}`)}
        data-icom={'btn-preview-plan-survey'}
        disabled={!data.topicDetail.isValid || freeOrgWithCustomTopic}
      >
        {t('planSurvey')}
      </ActionButton>
      {props.organization?.featureNames?.includes(Features.CreateAndEditTopics) && (
        <ActionButton
          variant="outline-primary"
          margin={`${theme.spacing.d} 0 ${theme.spacing.g} 0`}
          onClick={() => goToAriel()}
          data-icom={'btn-edit-in-ariel'}
          disabled={!data.topicDetail.isEditable}
          data-cy="btn-edit-in-ariel"
        >
          {t('editInAriel')}
        </ActionButton>
      )}
      {props.user.systemRole === UserSysRole.SysAdmin && !isEditable && (
        <ActionButton
          variant="outline-primary"
          margin={`${theme.spacing.d} 0 ${theme.spacing.g} 0`}
          onClick={() => goToAriel(true)}
        >
          {t('editOriginalInAriel')}
        </ActionButton>
      )}
      <p>{topicGroupTranslation ? topicGroupTranslation.description : data.topicDetail.description}</p>
      <p>
        <Trans
          i18nKey={'topicOverview:showTopicTree'}
          components={[
            <a
              href={`/topicEditorPreview/${data.topicDetail.topicGroup.id}`}
              rel="noreferrer"
              onClick={registerSvenLinkClick}
              data-cy="admin-sven-link"
              data-icom={'link-sven'}
            />,
          ]}
        >
          Tady je link na strom konverzace
        </Trans>
      </p>
      <div>
        {data.topicDetail.topicGroup.organization && organization.id === data.topicDetail.topicGroup.organization.id ? (
          <TopicMetaDataWrap>
            <TopicMetaData>
              {data.topicDetail.updatedBy &&
                `${data.topicDetail.updatedBy.firstname} ${data.topicDetail.updatedBy.surname}`}
            </TopicMetaData>
            <TopicMetaData>
              {format(new Date(data.topicDetail.updatedAt), 'P', { locale: getLocale(i18n.language) })}
            </TopicMetaData>
            <TopicMetaData>
              {t(
                `surveysOverview:${
                  data.topicDetail.topicGroup.containsSteps ? 'processTopicGroup' : 'normalTopicGroup'
                }`,
              )}
            </TopicMetaData>
          </TopicMetaDataWrap>
        ) : (
          <TopicMetaDataWrap />
        )}
        <TopicPreview
          isEditable={isEditable}
          activeSection={activeSection}
          setActiveSection={setActiveSection}
          sectionInEditMode={sectionInEditMode}
          setSectionInEditMode={setSectionInEditMode}
          topicDetail={data.topicDetail}
          topicStructure={topicStructure}
          allQuestions={allQuestions}
          languageId={languageId}
          urlBase={`/topic/${params.id}`}
          organization={organization}
          reloadTopic={reloadTopic}
        />
      </div>
    </PageWrapper>
  );
};

export default TopicScreen;
