import { formatDate, isDateInValidPeriod, Tooltip } from '@arnold/common';
import { DateTooltip, getEndDateTitle, PopulationInfo, SurveyInfo } from '../../components/SurveysOverview/RowParts';
import { SurveyActions } from './SurveyActions';
import { SurveyTopicTitle } from './SurveyTopicTitle';
import { GetDataSourceParams, SurveyData } from './types';
import { showResponseRate } from './utils';

export const getDataSource = ({
  surveys,
  organization,
  pathStatus,
  t,
  i18nLanguage,
  toSurveyDetail,
}: GetDataSourceParams): SurveyData[] =>
  surveys.map((survey) => {
    const label = survey?.topicGroup?.name;

    return {
      id: survey.id,
      topic: <SurveyTopicTitle survey={survey} label={label} />,
      info: (
        <SurveyInfo
          isSequential={false}
          notstartedReason={survey.notstartedReason ? [survey.notstartedReason] : []}
          inLicenceInterval={isDateInValidPeriod(new Date(survey.sendAt), organization?.productVersionHistory!)}
          organization={organization}
          status={survey.status}
          conversationLanguages={survey.availableInLanguages}
        />
      ),
      endsAt: survey.endsAt ? (
        <Tooltip title={getEndDateTitle(t, i18nLanguage, true, survey.endsAt)}>{formatDate(survey.endsAt)}</Tooltip>
      ) : (
        '-'
      ),
      sendAt: (
        <DateTooltip
          startsAt={survey.sendAt}
          endsAt={survey.endsAt}
          reminders={survey.reminders}
          displayOnlyStartDate={true}
        >
          {formatDate(survey.sendAt)}
        </DateTooltip>
      ),
      responseRate: showResponseRate(pathStatus) ? (
        <PopulationInfo
          population={survey.resultsInfo?.total}
          answered={survey.resultsInfo?.answered}
          recipientEmails={survey.recipientEmails}
        />
      ) : null,
      actions: (
        <SurveyActions survey={survey} toSurveyDetail={toSurveyDetail} pathStatus={pathStatus} t={t} label={label} />
      ),
    };
  });
