import { FormSelect, Option, cleanArray } from '@arnold/common';
import { Field, FormikProps } from 'formik';
import { Col, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OrganizationTeamsQuery } from '../../../generated/hooks';
import { getRespondentNameLabel } from '../../../lib/common';
import ee from '../../../lib/eventEmitter';
import { FormGroupLabel } from '../../Common';
import { useOrganization } from '../../providers/OrganizationProvider';

export const NO_SUPERVISOR = 'noSupervisor';

type Props = {
  teams: NonNullable<OrganizationTeamsQuery['organizationTeams']>;
  disableCreating?: boolean;
  disableNoSupervisor?: boolean;
  onChange?: (option: Option) => void;
  id?: string;
  error?: string;
  className?: string;
} & Partial<FormikProps<any>>;

export function TeamLeaderField({
  teams,
  onChange,
  disableCreating,
  values,
  setFieldValue,
  disableNoSupervisor,
  id,
  error,
  className,
  ...props
}: Props) {
  const { t } = useTranslation('updateRespondent');
  const { refetch, organization } = useOrganization();

  const handleChange = (option: Option) => {
    if (option.value === 'newLeader') {
      ee.emitNewTeam((teamId) => {
        refetch().then(() => {
          setFieldValue?.('teamId', teamId);
        });
      }, organization);
    } else {
      onChange?.(option);
    }
  };

  const options = cleanArray([
    !disableCreating && {
      label: t('newLeader'),
      value: 'newLeader',
      // means field will not send event to form about changing value
      handling: 'custom',
    },
    !disableNoSupervisor && {
      value: NO_SUPERVISOR,
      label: t('teams:withoutLeader'),
    },
    ...teams.map((team) => ({
      value: team.id,
      label: team.leader ? getRespondentNameLabel(team.leader, team.name!, t) : '',
      additionalInfo: {
        repondentId: team.leader?.id,
      },
    })),
  ]);
  const val = values?.teamId === null ? NO_SUPERVISOR : values?.teamId;
  return (
    <FormGroup as={Col} sm={8} className={className}>
      <FormGroupLabel>{t('teamLeader')}</FormGroupLabel>
      <Field
        component={FormSelect}
        name="teamId"
        options={options}
        isSearchable
        {...props}
        onChange={handleChange}
        placeholder={t('search')}
        isInvalid={!!error}
        value={val}
        id={id || 'team-leader-select'}
      />
      {error && <span className="form-text text-danger mr-6 w-auto">{error}</span>}
    </FormGroup>
  );
}
