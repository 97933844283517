import { TFunction } from 'react-i18next';
import { SequenceReportAccessesQuery, SurveyGroupQuery } from '../../generated/hooks';
import { getTopicGroupName } from '../../lib/helpers';
import { SequenceStepsData } from './types';
import { AnsweredSpan, MetricsInfo, TopicDetailLink } from './utils';

export const getDataSource = (
  surveyGroupId: number,
  steps:
    | NonNullable<SurveyGroupQuery['surveyGroup']>['steps']
    | NonNullable<SequenceReportAccessesQuery['surveyGroup']>['steps'],
  language: string,
  t: TFunction,
  isOnboarding?: boolean,
): SequenceStepsData[] =>
  steps.map((step, index) => ({
    id: step.processStep.stepTopicGroup.name,
    topic: getTopicGroupName(language, step.processStep.stepTopicGroup),
    metrics: <MetricsInfo step={step} t={t} />,
    daysFromStart: t('processDetail:dayText', {
      dayNo: step.daysFromStart,
    }),
    startOffset: step.processStep.startOffsetMins,
    reminderOffset: step.processStep.reminders?.[0]?.reminderOffsetMins,
    secondReminderOffset: step.processStep.reminders?.[1]?.reminderOffsetMins,
    thirdReminderOffset: step.processStep.reminders?.[2]?.reminderOffsetMins,
    endOffset: step.processStep.endOffsetMins,
    answered: <AnsweredSpan answered={step.statistics!.answered ?? 0} total={step.statistics!.total} />,
    detail: (
      <TopicDetailLink
        surveyGroupId={surveyGroupId}
        step={index}
        label={t('onboarding:detail')}
        isOnboarding={isOnboarding}
      />
    ),
  }));
