import {
  BarChart,
  cleanArray,
  getFrontendConfigValue,
  HeadingMedium,
  notEmpty,
  theme,
  usePath,
  FeelingValue,
  CardBody,
} from '@arnold/common';
import { XAxisTickProps } from '@arnold/common/lib/components/charts/BarChart/BarChart';
import styled from '@emotion/styled';
import { subDays } from 'date-fns';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { TFunction, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Loading } from '../../components';
import { Card } from '../../components/Common';
import { InfoBlock } from '../../components/Common/InfoBlock';
import { PageLayout } from '../../components/Common/PageLayout';
import { AnsweredSpan } from '../../components/SequenceStepsTable/utils';
import { WarningMessage } from '../../components/SurveysOverview/RowParts';
import { Table } from '../../components/Table/Table';
import { AnonymityLevel, SurveyStatus, useSurveyGroupQuery } from '../../generated/hooks';
import { getRespondentFeeling, getTopicGroupName } from '../../lib/helpers';
import { ResendButton } from '../GeneralProcessSurveyDetail/ProcessSurveyPatricipants/ParticipantDetail/getDataSource';
import {
  getAverageMetricsFeelings,
  getFeelingsAggregated,
  goToRespondentResults,
  goToResults,
  goToTopicDetail,
} from './utils';

import { StepRespondentData } from './types';
import { UninvitedRespondents } from './UninvitedRespondents';

type Props = {
  isOnboarding?: boolean;
};

const headingButtons = (t: TFunction, topicGroupId: string, accessKey: string, topicId: number) => [
  <Button onClick={() => goToTopicDetail(topicId)} variant={'outline-primary'} data-icom={'btn-try-chat'}>
    {t('arnoldConversation')}
  </Button>,
  <Button
    onClick={() => goToResults(topicGroupId, accessKey)}
    disabled={!accessKey}
    variant={'primary'}
    data-icom={'btn-show-results'}
  >
    {t('results')}
  </Button>,
];

const UpperBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GraphWrapper = styled.div`
  border-right: solid 0.6px ${theme.colors.borderSeparator.default};
  width: 100%;
  padding-right: 40px;
`;

const RespondentResultButton = styled(Button)`
  float: right;
`;

export const GeneralProcessSurveyTopicDetailScreen: FC<Props> = ({ isOnboarding }) => {
  const { t, i18n } = useTranslation('onboarding');
  const [surveyGroupT] = useTranslation('surveyGroup');
  const { onboardingPath, processSurveyDetailPath, processSurveysPath } = usePath();
  const { surveyGroupId, step } = useParams<{ surveyGroupId: string; step: string }>();

  const { data, loading, error } = useSurveyGroupQuery({ variables: { surveyGroupId } });

  if (loading) {
    return <Loading />;
  }

  if (error || !data?.surveyGroup?.steps[parseInt(step, 10)]) {
    return <></>;
  }

  const processStep = data!.surveyGroup!.steps[parseInt(step, 10)];

  const aggregatedMetrics = getFeelingsAggregated(
    data.surveyGroup.groupedRespondents
      .map((group) => group.metricQuestionsForRespondent)
      .flat()
      .map((respondentMetrics) =>
        respondentMetrics.answers.find((answer) => answer.topicGroupId === processStep.processStep.stepTopicGroup.id),
      )
      .filter(notEmpty),
  );

  const getCommonReportAccessKey = data.surveyGroup.topLevelReport?.accessKey;

  const channels = processStep.channels;
  const anonymous = channels.some((ch) => ch?.allowedAnonymities.some((a) => a === AnonymityLevel.Organization));

  const respondentsIds = channels.map((channel) => channel?.respondent?.id);
  const allRespondents = data.surveyGroup.groupedRespondents.map((group) => group.respondents).flat();
  const uninvitedRespondents = allRespondents.filter((respondent) => !respondentsIds.includes(respondent.id));

  return (
    <PageLayout
      dataIcom={`heading-${isOnboarding ? 'onboarding' : 'process-survey'}-topic-detail`}
      breadcrumbItems={[
        {
          title: isOnboarding ? t('AuthorizedPage:onboarding') : t('AuthorizedPage:processSurveys'),
          link: isOnboarding ? onboardingPath.createPath(surveyGroupId) : processSurveysPath.createPath(),
        },
        // TODO use PATHS
        {
          title: isOnboarding ? t('topics') : surveyGroupT('topics'),
          link: isOnboarding
            ? onboardingPath.createPath(surveyGroupId, 'topics')
            : processSurveyDetailPath.createPath(surveyGroupId, 'topics'),
        },
      ]}
      title={getTopicGroupName(i18n.language, processStep!.processStep.stepTopicGroup)}
      buttons={headingButtons(
        t,
        processStep.processStep.stepTopicGroup.id,
        getCommonReportAccessKey!,
        Number(processStep.processStep.stepTopicGroup.lastValidTopic!.id),
      )}
    >
      {isOnboarding && (
        <>
          <HeadingMedium>{t('howDoYouFeel')}</HeadingMedium>
          <Card className={'mb-6'}>
            <UpperBlockWrapper>
              <GraphWrapper>
                <BarChart
                  // TODO: current layout limited to 7 point scale of how do you feel question - make it scalable
                  data={[
                    { x: '1', y: aggregatedMetrics.get(1) ?? 0 },
                    { x: '2', y: aggregatedMetrics.get(2) ?? 0 },
                    { x: '3', y: aggregatedMetrics.get(3) ?? 0 },
                    { x: '4', y: aggregatedMetrics.get(4) ?? 0 },
                    { x: '5', y: aggregatedMetrics.get(5) ?? 0 },
                    { x: '6', y: aggregatedMetrics.get(6) ?? 0 },
                    { x: '7', y: aggregatedMetrics.get(7) ?? 0 },
                  ]}
                  height={363}
                  CustomXAxisTick={(props: XAxisTickProps) => (
                    <svg x={props.x - 14} y={props.y}>
                      <foreignObject width="28" height="28">
                        <FeelingValue value={Number(props.payload.value)}>{props.payload.value}</FeelingValue>
                      </foreignObject>
                    </svg>
                  )}
                />
              </GraphWrapper>
              <div style={{ minWidth: '286px', paddingLeft: '80px' }}>
                <InfoBlock
                  title={t('responseRate')}
                  value={
                    <AnsweredSpan
                      answered={processStep.statistics!.answered ?? 0}
                      total={processStep.statistics!.total}
                    />
                  }
                />
                <InfoBlock
                  title={t('noAlerts')}
                  value={`${
                    100 -
                    Math.round(
                      processStep.statistics?.answered
                        ? (processStep.statistics.withProblems / processStep.statistics.answered) * 100
                        : 100,
                    )
                  }% `}
                />
                <InfoBlock title={t('daysFromStart')} value={processStep.daysFromStart} />
                <InfoBlock title={t('state')} value={t(`status-${data.surveyGroup.status}`)} />
                <InfoBlock
                  title={t('feelingsAverage')}
                  value={getAverageMetricsFeelings(aggregatedMetrics).toLocaleString(i18n.language, {
                    maximumFractionDigits: 1,
                  })}
                />
              </div>
            </UpperBlockWrapper>
          </Card>
        </>
      )}
      <Card>
        <CardBody>
          <Table<StepRespondentData>
            columns={cleanArray([
              {
                key: 'respondent',
                title: t('respondent'),
                dataIndex: 'respondent',
              },
              {
                key: 'alert',
                title: undefined,
                dataIndex: 'alert',
              },
              {
                key: 'team',
                title: t('team'),
                dataIndex: 'team',
              },
              !!isOnboarding && {
                key: 'metricQuestion',
                title: t('howDoYouFeel'),
                dataIndex: 'metricQuestion',
              },
              {
                key: 'results',
                title: undefined,
                dataIndex: 'results',
              },
            ])}
            dataSources={channels.filter(Boolean).map((channel) => {
              const survey = processStep.surveys.find((s) => s.id === channel.survey.id)!;
              const endsAt = survey.endsAt;
              const surveyEndedRecently =
                survey.status === SurveyStatus.Completed &&
                new Date(endsAt) < new Date() &&
                new Date(endsAt) > subDays(new Date(), Number(getFrontendConfigValue('ALLOW_REOPENING_IN_DAYS', '14')));
              // if channel was finished after the survey ended, it was finished by ending the survey and not by the user
              const notAnswered = new Date(channel!.finishedAt) > new Date(endsAt);
              return {
                id: channel!.respondent!.id,
                respondent: `${channel!.respondent!.firstname} ${channel!.respondent!.surname}`,
                alert: channel!.alert ? <WarningMessage title={t('choiceSettingsModal:Warning')} /> : undefined,
                team: channel?.respondent?.team?.name ?? t('teams:withoutLeader'),
                metricQuestion:
                  new Date(survey.sendAt) > new Date()
                    ? t('scheduled')
                    : getRespondentFeeling(
                        processStep.processStep.stepTopicGroup.id,
                        data!.surveyGroup?.groupedRespondents
                          ?.map((group) => group.metricQuestionsForRespondent)
                          .flat()
                          .find((metrics) => metrics.respondentId === channel?.respondent?.id)?.answers,
                      ),
                results:
                  surveyEndedRecently && (notAnswered || anonymous) && channel!.respondent!.deleted === false ? (
                    <div style={{ marginLeft: theme.spacing.g }}>
                      <ResendButton
                        notAnswered={notAnswered}
                        anonymous={anonymous}
                        surveyId={survey.id}
                        surveyEndsAt={endsAt}
                        respondentId={channel!.respondent!.id}
                      />
                    </div>
                  ) : (
                    <RespondentResultButton
                      onClick={() =>
                        goToRespondentResults(
                          getCommonReportAccessKey!,
                          channel!.respondent?.id!,
                          processStep.processStep.stepTopicGroup.id,
                        )
                      }
                      variant={'outline-primary'}
                      size={'sm'}
                    >
                      {t('results')}
                    </RespondentResultButton>
                  ),
              };
            })}
          />
        </CardBody>
      </Card>
      {!!uninvitedRespondents.length && (
        <UninvitedRespondents
          respondents={uninvitedRespondents}
          reportAccessKey={getCommonReportAccessKey}
          stepId={processStep.processStep.stepTopicGroup.id}
        />
      )}
    </PageLayout>
  );
};
