import {
  BodyMedium,
  BoxWithImage,
  HeadingMedium,
  IThemeProps,
  selectMenuStyles,
  SelectWrapper,
  Tabs,
  TabsCard,
  theme,
} from '@arnold/common';
import { ReactComponent as TopicNotTranslated } from '@arnold/common/lib/assets/images/TopicNotTranslated.svg';
import styled from '@emotion/styled';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TopicDetailQuery, useCreateTestSurveyMutation, UserQuery } from '../../generated/hooks';
import { sortLanguages } from '../../lib/common';
import { PickerChoice } from '../../lib/types';
import Section from './Section';
import { QuestionDetail, TopicStructure } from './topicStructure';

const TryContainer = styled.div`
  margin-left: ${({ theme }: IThemeProps) => theme?.spacing.h};
  margin-top: ${({ theme }: IThemeProps) => theme?.spacing.h};
`;

const CardContainer = styled.div`
  margin-top: ${({ theme }: IThemeProps) => theme?.spacing.i};
`;

const CardContent = styled.div`
  margin-left: ${({ theme }: IThemeProps) => theme?.spacing.h};
`;

type TopicPreviewProps = {
  isEditable: boolean;
  topicStructure: TopicStructure;
  allQuestions: QuestionDetail[];
  languageId: string;
  urlBase: string;
  organization: NonNullable<UserQuery['user']['organization']>;
  topicDetail: NonNullable<TopicDetailQuery['topicDetail']>;
  activeSection: number | null;
  setActiveSection: (newActiveSection: number | null) => void;
  sectionInEditMode: number | null;
  setSectionInEditMode: (newActiveSection: number | null) => void;
  reloadTopic: () => Promise<void>;
};

const TopicPreview = ({
  isEditable,
  urlBase,
  reloadTopic,
  topicStructure,
  allQuestions,
  languageId,
  topicDetail,
  organization,
  activeSection,
  setActiveSection,
  sectionInEditMode,
  setSectionInEditMode,
}: TopicPreviewProps) => {
  const { t } = useTranslation('topicOverview');
  const history = useHistory();
  const [createTestSurvey, { loading }] = useCreateTestSurveyMutation();
  const [topPositionOfNewSection, setTopPositionOfNewSection] = useState<number | null>(null);

  const topicHasTranslation = topicDetail.allQuestions.some((q) =>
    q.translations.some((t) => t.language.id === languageId),
  );
  const topicHasCompleteTranslation = topicDetail.allQuestions.every((q) =>
    q.translations.some((t) => t.language.id === languageId),
  );
  const defaultTranslation =
    !!topicDetail.allQuestions.length && topicDetail.allQuestions[0].translations.find((t) => t.isDefault);
  const allowStructureChanges = defaultTranslation && defaultTranslation.language.id === languageId;
  const languageCode =
    (defaultTranslation && defaultTranslation.language && defaultTranslation.language.code) || undefined;
  return (
    <>
      {organization.languages?.length > 4 ? (
        <TabsCard>
          <SelectWrapper
            value={{
              label: t(`languageSelector:${organization.languages.find((l) => l.id === languageId)?.code}`),
              value: languageId,
            }}
            options={sortLanguages(
              organization.languages,
              new Map(organization.languages.map((l) => [l.code, t(`languageSelector:${l.code}`)])),
            ).map((l) => ({ label: t(`languageSelector:${l!.code}`), value: l!.id }))}
            onChange={(option: PickerChoice) => history.push(`${urlBase}/${option.value}`)}
            menuPlacement="auto"
            styles={{
              menu: (provided: any, state: any) => ({
                ...provided,
                ...selectMenuStyles,
              }),
              container: (provided: any, state: any) => ({
                ...provided,
                width: '160px',
              }),
            }}
          />
        </TabsCard>
      ) : (
        <Tabs
          items={sortLanguages(
            organization.languages,
            new Map(organization.languages.map((l) => [l.code, t(`languageSelector:${l.code}`)])),
          ).map((l) => ({
            title: t(`languageSelector:${l!.code}`),
            link: `${urlBase}/${l!.id}`,
            // eslint-disable-next-line eqeqeq
            active: languageId == l!.id,
          }))}
          theme={theme}
        />
      )}
      {topicHasTranslation ? (
        <>
          <TryContainer>
            <Button
              color="primary"
              disabled={loading || !topicHasCompleteTranslation}
              variant={'outline-primary'}
              onClick={async () => {
                const { data } = await createTestSurvey({
                  variables: {
                    input: {
                      language: organization.languages.find((l) => l.id === languageId)?.code || '',
                      topicId: topicDetail.id,
                    },
                  },
                });
                if (data) {
                  window.open(data?.createTestSurvey, '_blank')?.focus();
                }
              }}
              data-icom={'btn-test-survey'}
            >
              {t('tryTopic')}
            </Button>
          </TryContainer>
          {topicStructure.sections.map((section, sectionIndex) => {
            return (
              <Section
                key={`section_${section.id}`}
                section={section}
                topicId={topicDetail.id}
                isEditable={isEditable}
                activeSection={activeSection}
                setActiveSection={setActiveSection}
                sectionInEditMode={sectionInEditMode}
                topPositionOfNewSection={topPositionOfNewSection}
                setTopPositionOfNewSection={setTopPositionOfNewSection}
                setSectionInEditMode={setSectionInEditMode}
                allowStructureChanges={allowStructureChanges}
                allowDelete={
                  !!allowStructureChanges && (!!sectionIndex || sectionIndex < topicStructure.sections.length - 1)
                }
                reloadTopic={reloadTopic}
                allQuestions={allQuestions}
                previousSectionRoutingQuestions={
                  sectionIndex
                    ? [
                        ...topicStructure.sections[sectionIndex - 1].questions.filter(
                          (q) => q.rules && q.rules.some((r) => r.nextQuestion.id === section.questions[0].id),
                        ),
                        ...(topicStructure.sections[sectionIndex - 1].questionSubsections
                          ? Array.from(topicStructure.sections[sectionIndex - 1].questionSubsections!.values())
                              .map((sub) => Object.values(sub))
                              .flat(3)
                              .filter(
                                (q) => q.rules && q.rules.some((r) => r.nextQuestion.id === section.questions[0].id),
                              )
                          : []),
                      ]
                    : undefined
                }
                nextSectionStartingQuestion={
                  sectionIndex + 1 >= topicStructure.sections.length
                    ? undefined
                    : topicStructure.sections[sectionIndex + 1].questions[0]
                }
                sectionIndex={sectionIndex}
                mapping={topicStructure.mapping}
                languageId={languageId}
                languageCode={languageCode}
                sectionIndexMapping={topicStructure.sectionIndexMapping}
              />
            );
          })}
        </>
      ) : (
        <CardContainer>
          <BoxWithImage image={<TopicNotTranslated />}>
            <CardContent>
              <HeadingMedium margin="12.5px 0 8px">{t('notTranslatedTitle')}</HeadingMedium>
              <BodyMedium>{t('notTranslatedDescription')}</BodyMedium>
            </CardContent>
          </BoxWithImage>
        </CardContainer>
      )}
    </>
  );
};

export default TopicPreview;
