import React from 'react';
import { RouteComponentProps } from 'react-router';
import { HeadingMedium, theme } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import PublicLayout from '../../components/Common/PublicLayout';
import LoginAside from '../Login/LoginAside';
import storage from '../../lib/storage';
import ChangePasswordForm from './ChangePasswordForm';

interface ILocationState {
  accessToken: string;
}
interface ILocation extends Location {
  state: ILocationState;
}
interface IProps extends RouteComponentProps<any> {
  location: ILocation;
}

const ChangePasswordScreen = ({ match, history }: IProps) => {
  const [t] = useTranslation('changePasswordForm');
  const { accessToken, lang } = match.params;
  const detectedLang = lang || storage.getDetectedLanguage()?.substring(0, 2);

  return (
    <PublicLayout
      basePath={`changePassword/${accessToken}`}
      history={history}
      lang={detectedLang}
      aside={<LoginAside />}
    >
      <>
        <HeadingMedium margin={`0 0 ${theme.spacing.h}`}>{t('changePassword')}</HeadingMedium>
        <ChangePasswordForm accessToken={accessToken} lang={detectedLang} />
      </>
    </PublicLayout>
  );
};

export default ChangePasswordScreen;
