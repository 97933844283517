import { BodyMedium, BodySmall, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';

type ReportValueProps = {
  title: string;
  value: ReactNode;
  dataIcom?: string;
};

const InfoBlockValue = styled.div`
  margin-bottom: 20px;
`;

export const InfoBlock: FC<ReportValueProps> = ({ title, value, dataIcom }) => {
  return (
    <InfoBlockValue>
      <BodySmall margin={`0 0 ${theme.spacing.e} 0`} color={theme.colors.text.secondary} data-icom={dataIcom}>
        {title}
      </BodySmall>
      <BodyMedium>{value}</BodyMedium>
    </InfoBlockValue>
  );
};
