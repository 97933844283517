import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  SequenceReportAccessesQuery,
  SurveyGroupQuery,
  useSurveyGroupStepStatisticsLazyQuery,
} from '../../generated/hooks';
import { DAY, HOUR } from '../../lib/process';
import { Card } from '../Common';
import GraphQLErrorCard from '../GraphQLErrorCard';
import Loading from '../Loading';
import { Table } from '../Table/Table';
import { getDataSource } from './getDataSource';
import { SequenceStepDetailData, SequenceStepsData } from './types';
import { getColumns, printOffset } from './utils';

type Steps =
  | NonNullable<SurveyGroupQuery['surveyGroup']>['steps']
  | NonNullable<SequenceReportAccessesQuery['surveyGroup']>['steps'];

interface IProps {
  surveyGroupId: number;
  topicGroupId: number;
  steps?: Steps;
  isOnboarding?: boolean;
}

export const SequenceStepsTable: FC<IProps> = ({ surveyGroupId, steps, topicGroupId, isOnboarding }: IProps) => {
  const { t, i18n } = useTranslation(['SequenceSteps', 'surveysOverview']);

  const [getStepStatistics, { loading, data, error }] = useSurveyGroupStepStatisticsLazyQuery();
  useEffect(() => {
    if (surveyGroupId.toString() !== data?.surveyGroup?.id && !steps) {
      getStepStatistics({
        variables: {
          surveyGroupId: surveyGroupId.toString(),
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyGroupId, steps, data]);

  if (loading && !data) {
    return <Loading />;
  }
  const stepsToRender = steps || data?.surveyGroup?.steps || [];
  if (error || !stepsToRender.length) {
    return <GraphQLErrorCard />;
  }
  return (
    <Card>
      <Table<SequenceStepsData>
        columns={getColumns(t, topicGroupId.toString(), isOnboarding)}
        dataSources={getDataSource(
          surveyGroupId,
          [...stepsToRender].sort((s1, s2) => s1.daysFromStart - s2.daysFromStart),
          i18n.language,
          t,
          isOnboarding,
        )}
        renderDetailPanel={(rowData) => {
          const minutes = (rowData.startOffset ?? 0) % (HOUR * DAY);
          const startOffset = rowData.startOffset
            ? minutes >= 0
              ? minutes
              : HOUR * DAY + minutes
            : rowData.startOffset;
          return (
            <Table<SequenceStepDetailData>
              columns={[
                {
                  key: 'startOffset',
                  title: t('SequenceSteps:startOffset'),
                  dataIndex: 'startOffset',
                  width: '10%',
                },
                {
                  key: 'reminderOffset',
                  title: t('noReminder', { count: 1 }),
                  dataIndex: 'reminderOffset',
                  width: '10%',
                },
                {
                  key: 'secondReminderOffset',
                  title: t('noReminder', { count: 2 }),
                  dataIndex: 'secondReminderOffset',
                  width: '10%',
                },
                {
                  key: 'thirdReminderOffset',
                  title: t('noReminder', { count: 3 }),
                  dataIndex: 'thirdReminderOffset',
                  width: '10%',
                },
                {
                  key: 'endOffset',
                  title: t('endOffset'),
                  dataIndex: 'endOffset',
                  width: '20%',
                },
              ]}
              dataSources={[
                {
                  id: rowData.id,
                  startOffset: rowData.startOffset ? printOffset(rowData.startOffset, true, t) : null,
                  reminderOffset:
                    rowData.reminderOffset === 0 || !startOffset || !rowData.reminderOffset
                      ? '-'
                      : printOffset(rowData.reminderOffset + startOffset, false, t),
                  secondReminderOffset:
                    rowData.secondReminderOffset === 0 || !startOffset || !rowData.secondReminderOffset
                      ? '-'
                      : printOffset(rowData.secondReminderOffset + startOffset, false, t),
                  thirdReminderOffset:
                    rowData.thirdReminderOffset === 0 || !startOffset || !rowData.thirdReminderOffset
                      ? '-'
                      : printOffset(rowData.thirdReminderOffset + startOffset, false, t),
                  endOffset:
                    rowData.endOffset && startOffset ? printOffset(rowData.endOffset + startOffset, false, t) : null,
                },
              ]}
            />
          );
        }}
      />
    </Card>
  );
};
