import { append, reduce } from 'ramda';
import { ConversationPickerChoice } from '../../lib/types';
import { UserQuery, AllowedConversationSubjectQuery, ConversationSubjectType } from '../../generated/hooks';

type AllowedConversationSubjects = NonNullable<
  AllowedConversationSubjectQuery['user']['organization']
>['allowedConversationSubjects'];

export const getInitialConversationSubject = (
  conversationSubjectChoices: ConversationPickerChoice[],
  topic: NonNullable<UserQuery['user']['organization']>['surveys'][0]['topic'] | null,
  topicGroup: NonNullable<UserQuery['user']['organization']>['surveys'][0]['topicGroup'] | null,
): ConversationPickerChoice | undefined => {
  let topicChoice;

  topicChoice = conversationSubjectChoices.find((subject) => {
    if (topic) {
      return subject.value.id === topic.id;
    } else if (topicGroup) {
      return subject.value.id === topicGroup.id;
    }
    return false;
  });

  if (!topicChoice && topic) {
    topicChoice = conversationSubjectChoices.find(
      (subject) =>
        subject.value.id === topic.topicGroup.id && subject.value.type === ConversationSubjectType.TopicGroup,
    );
  }

  if (!topicChoice) {
    const subject = topic || topicGroup;
    if (subject) {
      topicChoice = {
        value: {
          id: subject.id,
          type: topic ? ConversationSubjectType.Topic : ConversationSubjectType.TopicGroup,
        },
        label: subject.name,
        description: subject.description || '',
        isDisabled: true,
      };
    }
  }
  return topicChoice;
};

export const getUniqByIdConversationSubjects = (conversationSubjects: AllowedConversationSubjects) =>
  reduce(
    (acc: AllowedConversationSubjects, subject) => {
      return acc.some((sub) => sub.type === subject.type && sub.id === subject.id) ? acc : append(subject, acc);
    },
    [],
    conversationSubjects,
  );

const getUniqConversationSubjects = (conversationSubjects: AllowedConversationSubjects) =>
  reduce(
    (acc: AllowedConversationSubjects, subject) => {
      return acc.some((sub) => sub.name === subject.name) ? acc : append(subject, acc);
    },
    [],
    conversationSubjects,
  );

export const prepareConversationChoices = (
  allowedConversationSubjects: AllowedConversationSubjects,
): ConversationPickerChoice[] => {
  const uniqueAllowedConversationSubjects = getUniqConversationSubjects(allowedConversationSubjects);

  return uniqueAllowedConversationSubjects.map((subject) => ({
    value: {
      id: subject.id,
      type: subject.type,
    },
    label: subject.name,
    description: subject.description,
    isProcess: subject.process,
    isDisabled: false,
  }));
};

export const getSequenceTopicGroup = (
  organization: NonNullable<UserQuery['user']['organization']>,
  surveyGroupId: string,
) => {
  const sequence = organization.surveyGroups.find((sg) => sg.id === surveyGroupId);

  return sequence && sequence.topicGroup;
};
