import { Box, createEventData, pushToDataLayer } from '@arnold/common';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultRecipientOrgAdminEmail, isValidEmail } from '../../lib/helpers';
import { FormGroupLabel } from '../Common';
import NewRespondentsBox from '../Forms/NewRespondentsBox';
import { DateSelector, DateSelectorInputGroup } from '../StyledComponents';
import DateInput from '../Common/DateInput';
import { OrganizationQuery, UserQuery, useOrganizationAdminsQuery } from '../../generated/hooks';
import { ChangeAddedOnButton } from './CommonComponents';

type CreateWithRespondentsProps = {
  organization: UserQuery['user']['organization'] | OrganizationQuery['organization'];
  addRespondents: (
    respondentId: string[],
    addedOn: Date,
    runOngoingSteps: string[],
    newRecipientEmails?: string[],
  ) => Promise<boolean>;
  user: UserQuery['user'];
};

export type FormResults = { participants?: string[]; addedOn: Date };

const CreateWithRespondents = ({ organization, addRespondents, user }: CreateWithRespondentsProps) => {
  const { t } = useTranslation('surveyGroup');
  const [formValues, setFormValues] = useState<FormResults>({
    addedOn: new Date(),
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);

  useOrganizationAdminsQuery({
    variables: { organizationId: organization?.id! },
    fetchPolicy: 'no-cache',
    onCompleted: (responseData) => {
      const defaultOrgAdminRecipient = getDefaultRecipientOrgAdminEmail(responseData?.organizationAdmins || [], user);
      if (!defaultOrgAdminRecipient) {
        return;
      }

      setFormValues({
        ...formValues,
        participants: [defaultOrgAdminRecipient],
      });
    },
  });

  const submit = async () => {
    const wasSuccessful = await addRespondents([], formValues.addedOn, [], formValues.participants);
    if (wasSuccessful) {
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.create-survey-group-success',
        ...createEventData('survey', 'createSurveyGroupSuccess', 'survey create survey group success'),
      });
    } else {
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.create-survey-group-error',
        ...createEventData('survey', 'createSurveyGroupError', 'survey create survey group error'),
      });
    }
  };
  const submitAddedOn = async () => {
    setIsSubmitDisabled(true);
    await submit();
    setIsSubmitDisabled(false);
  };

  const allEmailsValid = () =>
    !formValues.participants?.length ||
    (formValues.participants.every((email) => isValidEmail(email.trim())) &&
      new Set(formValues.participants.map((p) => p.trim())).size === formValues.participants.length);
  return (
    <div data-cy="admin-survey-group-new-respondents">
      <Box>
        <NewRespondentsBox
          labelForMultipleSurveys={true}
          participants={formValues.participants}
          setParticipants={(participants: string[]) => {
            setFormValues((oldValues) => ({ ...oldValues, participants }));
          }}
        />
        <>
          <FormGroupLabel>{t('start')}</FormGroupLabel>
          <DateSelectorInputGroup>
            <DateSelector
              style={{
                width: '66.66667%',
              }}
            >
              <DateInput
                onDateChange={(value) =>
                  setFormValues((oldValues) => ({
                    ...oldValues,
                    addedOn: new Date(value.toISOString().substring(0, 10) + 'T00:00:00Z'),
                  }))
                }
                inputName={'addedOn'}
                date={formValues.addedOn?.toISOString()}
              />
            </DateSelector>
            <ChangeAddedOnButton
              variant="primary"
              className={'btn-empty'}
              type="submit"
              disabled={isSubmitDisabled || !allEmailsValid()}
              onClick={submitAddedOn}
            >
              {t('surveyForm:scheduleTitle')}
            </ChangeAddedOnButton>
          </DateSelectorInputGroup>
        </>
      </Box>
    </div>
  );
};

export default CreateWithRespondents;
