import { TFunction } from 'react-i18next';
import { cleanArray, TableColumn } from '@arnold/common';
import { ProcessSurveyData } from './types';

export const getColumns = (t: TFunction): Array<TableColumn<ProcessSurveyData>> =>
  cleanArray([
    {
      key: 'topic',
      title: t('chat'),
      dataIndex: 'topic',
    },
    {
      key: 'info',
      title: undefined,
      dataIndex: 'info',
    },
    {
      key: 'sendAt',
      title: t('startDate'),
      dataIndex: 'sendAt',
    },
    {
      key: 'responseRate',
      title: t('responseRate'),
      dataIndex: 'responseRate',
    },
    {
      key: 'actions',
      title: undefined,
      dataIndex: 'actions',
    },
  ]);
