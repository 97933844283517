import styled from '@emotion/styled/macro';
import { ReactComponent as ArnoldLoading } from '../assets/images/ArnoldLoading.svg';

const Loading = () => (
  <Container>
    <CenterAnimation>
      <ArnoldLoading />
    </CenterAnimation>
  </Container>
);

const Container = styled.div(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1050,
  width: '100%',
  height: '100%',
  outline: '0',
  transition: 'opacity 0.15s linear',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
}));

const CenterAnimation = styled.div(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: 128,
}));

export default Loading;
