import styled from '@emotion/styled/macro';
import { theme, ThemeType } from '@arnold/common';

type TopicSectionProps = {
  withBackground: boolean;
  theme?: ThemeType;
};

export const TopicSection = styled.div`
  margin: 32px 0 16px;
  transition: all 0.25s ease-out;
  display: flex;
  background: ${(props: TopicSectionProps) =>
    props.withBackground && theme ? theme.colors.backgroundCover.default : undefined};
  padding: ${(props: TopicSectionProps) => (props.withBackground ? '32px' : '0 32px')};
  border-radius: 6px;
`;

export const SectionBubbleView = styled.div(() => ({
  position: 'relative',
  backgroundColor: theme.colors.backgroundDark.default,
  float: 'left',
  color: theme.colors.textInverted.primary,
  height: '37px',
  minWidth: '37px',
  textAlign: 'center',
  padding: '8px',
  borderRadius: '23px',
  marginLeft: '12px',
  fontWeight:
    theme.typography.body.medium && theme.typography.body.medium.bold
      ? theme.typography.body.medium.bold.fontWeight
      : undefined,
  fontSize:
    theme.typography.body.medium && theme.typography.body.medium.bold
      ? theme.typography.body.medium.bold.fontSize
      : undefined,
}));
