import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Box } from './Box';

const Layout = styled.div`
  display: flex;
`;
const CenteredColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export interface IBoxWithImageProps {
  className?: string;
  image: ReactNode;
  children?: ReactNode;
}

export const BoxWithImage = ({ children, image, className }: IBoxWithImageProps) => {
  return (
    <Box className={className}>
      <Layout>
        <div>{image}</div>
        <CenteredColumn>{children}</CenteredColumn>
      </Layout>
    </Box>
  );
};
