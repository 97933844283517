export function getPageCookies() {
  // cookie is a string containing a semicolon-separated list, this split puts it into an array
  const cookieArr = document.cookie.split(';');

  // This object will hold all of the key value pairs
  const cookieObj: { [key: string]: string } = {};

  // Iterate the array of flat cookies to get their key value pair
  // eslint-disable-next-line
  for (let i = 0; i < cookieArr.length; i++) {
    // Remove the standardized whitespace
    const cookieSeg = cookieArr[i].trim();

    // Index of the split between key and value
    const firstEq = cookieSeg.indexOf('=');

    // Assignments
    const name = cookieSeg.substr(0, firstEq);
    const value = cookieSeg.substr(firstEq + 1);
    cookieObj[name] = value;
  }
  return cookieObj;
}

export function eraseCookie(name: string, withDomain?: boolean) {
  document.cookie = `${name}=; Max-Age=-99999999; ${withDomain ? 'path=/; domain=.arnold-robot.com' : ''}`;
}

export function eraseAllCookies(withDomain?: boolean) {
  const allCookies = getPageCookies();
  Object.keys(allCookies)
    .filter((cookieName) => cookieName !== 'lmc_ccm')
    .forEach((cookieName) => eraseCookie(cookieName, withDomain));
}
