import { CardGrid, cleanArray, Tabs, theme } from '@arnold/common';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getLinkToTopicOverview } from '../../lib/topic';
import { ConversationSubjectCard } from '../ConversationSubjects/components';
import { AllowedConversationSubjectQuery, OrganizationQuery } from '../../generated/hooks';

type TopicGroupsOverviewProps = {
  topicGroups: NonNullable<AllowedConversationSubjectQuery['user']['organization']>['allowedConversationSubjects'];
  organization: OrganizationQuery['organization'];
};

export const TopicGroupOverview: FC<TopicGroupsOverviewProps> = ({ topicGroups, organization }) => {
  const { t } = useTranslation('surveysOverview');
  const [custom, setCustom] = useState(!!topicGroups.find((topicGroup) => topicGroup.custom));
  const customTopicGroups = topicGroups.filter((topicGroup) => topicGroup.custom);
  const systemTopicGroups = topicGroups.filter((topicGroup) => !topicGroup.custom);
  const selectedTopicGroups = custom ? customTopicGroups : systemTopicGroups;

  return (
    <div>
      <Tabs
        items={cleanArray([
          customTopicGroups.length > 0 && {
            title: t('chooseCustomSurveyToopic'),
            active: custom,
            onClick: () => setCustom(true),
          },
          systemTopicGroups.length > 0 && {
            title: t('chooseSystemSurveyTopic'),
            active: !custom,
            onClick: () => setCustom(false),
          },
        ])}
        theme={theme}
      />
      <CardGrid>
        {selectedTopicGroups.map((subject) => (
          <ConversationSubjectCard
            key={subject.id}
            subject={subject}
            toDetailLink={getLinkToTopicOverview(subject)}
            organizationLanguages={organization?.languages || []}
            languageId={organization?.primaryLanguageId || 2}
          />
        ))}
      </CardGrid>
    </div>
  );
};
