import { ApolloError } from '@apollo/client';
import { CallingCodeInput, REGEXP_PHONE_NUMBER, pushToDataLayer, theme } from '@arnold/common';
import { validatePassword } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { Form, Formik, FormikValues } from 'formik';
import { parsePhoneNumber } from 'libphonenumber-js';
import { FocusEvent, useState } from 'react';
import { Col, FormControl, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FormErrorFeedback, FormGroupLabel } from '../../components/Common';
import { Required } from '../../components/Common/Required';
import { CreateOrganizationInput, useRegistrationMutation, useVerifyPhoneNumbersMutation } from '../../generated/hooks';
import { EDITION } from '../../lib/common';
import RegistrationFormSetPassword from './RegistrationFormSetPassword';
import { IPropsRegistrationForm } from './common';

const VALID_PERIOD = 10 * 365;

const FormColumns = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoint.small}) {
    width: auto;
    flex-direction: row;
    gap: ${theme.spacing.f};
  }
`;

export const RegistrationForm = ({ channel, countryCode, history, setEmail }: IPropsRegistrationForm) => {
  const { t, i18n } = useTranslation('registration');

  const [unverifiedPhoneNumber, setUnverifiedPhoneNumber] = useState(false);
  const [registration] = useRegistrationMutation();
  const [verifyPhoneNumbers] = useVerifyPhoneNumbersMutation();

  const handleSubmit = async (values: FormikValues, { setErrors, setSubmitting }: any) => {
    let organizationInput: CreateOrganizationInput = {
      systemName: values.company,
      validPeriod: VALID_PERIOD,
      language: i18n.language,
      conversationSubjects: [],
      product: EDITION.FREE.name,
      licenceCount: 15,
      channel,
    };

    if (countryCode) {
      organizationInput = {
        ...organizationInput,
        countryCode,
      };
    }
    try {
      await registration({
        variables: {
          userInput: {
            // revert value, because question is negation
            marketingConsent: !values.marketingConsent,
            email: values.email,
            firstname: values.firstname,
            surname: values.surname,
            callingCode: values.callingCode,
            phone: values.phone,
          },
          organizationInput,
          password: values.password,
        },
      });

      try {
        setSubmitting(false);
        pushToDataLayer({
          event: 'ux.form',
          eventCategory: 'form',
          eventAction: 'submit',
          eventLabel: `${i18n.language} - Try arnold on me`,
          eventValue: 0,
          eventNonInteraction: false,
        });
        setEmail && setEmail(values.email);
      } catch (e) {
        history.push('/login');
      }
    } catch (e) {
      if ((e as ApolloError).graphQLErrors) {
        (e as ApolloError).graphQLErrors.forEach((error: any) => {
          if (error.name === 'UserAlreadyExists') {
            setErrors({
              email: t('registration:alreadyRegisteredEmail'),
            });
          }
          if (error.name === 'OrganizationAlreadyExists') {
            setErrors({
              company: t('registration:alreadyRegisteredCompany'),
            });
          }
        });
      }
    }
  };

  const handlePhoneChange =
    (handler: any, setFieldTouched: (field: string, isTouched?: boolean | undefined) => void) => (e: any) => {
      const { target } = e;
      const { value } = target;

      handler({ target });

      // without setTimeout validation runs on old values: https://github.com/jaredpalmer/formik/issues/2083
      setTimeout(() => setFieldTouched('phone', true));

      if (value) {
        try {
          const parsedPhoneNumber = parsePhoneNumber(value);
          if (parsedPhoneNumber) {
            handler({
              target: { name: 'callingCode', value: parsedPhoneNumber.countryCallingCode },
            });
            handler({
              target: { name: target.name, value: parsedPhoneNumber.nationalNumber },
            });
          } else {
            handler({
              target: { name: target.name, value },
            });
          }
        } catch (e) {
          // do nothing}
        }
      }
    };

  const handlePhoneBlur = async (cc: string, phoneNumber: string) => {
    const internationalPhoneNumber = `+${cc}${phoneNumber}`;
    if (
      /^((\+[1-9]{1,4}[ -]*)|(\([0-9]{2,3}\)[ -]*)|([0-9]{2,4})[ -]*)*?[0-9]{3,4}?[ -]*[0-9]{3,4}?$/.test(
        internationalPhoneNumber,
      )
    ) {
      await verifyPhoneNumbers({
        variables: { phoneNumbers: [internationalPhoneNumber] },
        onCompleted: (data) => {
          setUnverifiedPhoneNumber(!data.verifyPhoneNumbers.find((pn) => pn.phoneNumber === internationalPhoneNumber));
        },
        onError: (err) => {
          setUnverifiedPhoneNumber(false);
        },
      });
    } else {
      setUnverifiedPhoneNumber(true);
    }
  };

  const RegisterFormValidationSchema = Yup.object().shape({
    firstname: Yup.string().required(t('registration:enterFirstname')),
    surname: Yup.string().required(t('registration:enterSurname')),
    company: Yup.string().required(t('registration:enterCompany')),
    email: Yup.lazy((value: string = '') => {
      return value.startsWith('#')
        ? Yup.string().required(t('registration:enterValidEmail'))
        : Yup.string().required(t('registration:enterEmail')).email(t('registration:enterValidEmail'));
    }),
    password: Yup.string().when('email', (email: any, schema: any) =>
      schema.test(
        'is-password-valid',
        t('registration:enterValidPassword'),
        (value: string) => value && validatePassword(value, email),
      ),
    ),
    phone: Yup.string().required(t('enterValidPhoneNumber')).matches(REGEXP_PHONE_NUMBER, t('enterValidPhoneNumber')),
    callingCode: Yup.string()
      .required(t('updateRespondent:enterValidCallingCode'))
      .matches(/^(\+?\d{1,3})$/, t('updateRespondent:enterValidCallingCode'))
      .when('phone', (phone, schema) => {
        return phone ? schema.required(t('updateRespondent:enterCallingCode')) : schema;
      }),
  });

  return (
    <Formik
      validationSchema={RegisterFormValidationSchema}
      onSubmit={handleSubmit}
      initialValues={{
        firstname: undefined,
        surname: undefined,
        company: undefined,
        email: undefined,
        callingCode: '',
        phone: undefined,
        password: undefined,
        consent: false,
      }}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleBlur,
        isSubmitting,
        setValues,
        setFieldTouched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel className="form-group__label">
              {t('registration:company')}
              <Required />
            </FormGroupLabel>
            <FormControl
              type="text"
              name="company"
              value={values.company || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.company && !!errors.company}
            />
            <FormErrorFeedback error={errors.company} />
          </FormGroup>
          <FormColumns>
            <FormGroup className="col p-0">
              <FormGroupLabel>
                {t('registration:firstname')}
                <Required />
              </FormGroupLabel>
              <FormControl
                type="text"
                name="firstname"
                value={values.firstname || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.firstname && !!errors.firstname}
              />
              <FormErrorFeedback error={errors.firstname} />
            </FormGroup>
            <FormGroup className="col p-0">
              <FormGroupLabel className="form-group__label">
                {t('registration:surname')}
                <Required />
              </FormGroupLabel>
              <FormControl
                type="text"
                name="surname"
                value={values.surname || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={touched.surname && !!errors.surname}
              />
              <FormErrorFeedback error={errors.surname} />
            </FormGroup>
          </FormColumns>
          <FormGroup>
            <FormGroupLabel>
              {t('registration:email')}
              <Required />
            </FormGroupLabel>
            <FormControl
              type="text"
              name="email"
              value={values.email || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={touched.email && !!errors.email}
            />
            <FormErrorFeedback error={errors.email} />
          </FormGroup>
          <Row>
            <FormGroup as={Col} sm={6}>
              <FormGroupLabel>
                {t('updateRespondent:callingCode')}
                <Required />
              </FormGroupLabel>
              <CallingCodeInput
                callingCode={values.callingCode || ''}
                onChange={(callingCode: string) => {
                  setValues(() => ({ ...values, callingCode }));
                  setTimeout(() => setFieldTouched('callingCode', true));
                  setUnverifiedPhoneNumber(false);
                }}
                isInvalid={(touched.callingCode || touched.phone) && !!errors.callingCode}
              />
              <FormErrorFeedback error={errors.callingCode} />
            </FormGroup>
            <FormGroup as={Col} sm={6}>
              <FormGroupLabel>
                {t('teamsScreen:phone')}
                <Required />
              </FormGroupLabel>
              <FormControl
                type="tel"
                name="phone"
                value={values.phone}
                onChange={handlePhoneChange(handleChange, setFieldTouched)}
                onBlur={(e: FocusEvent<HTMLInputElement>) => handlePhoneBlur(values.callingCode, e.target.value)}
                isInvalid={(touched.phone && !!errors.phone) || unverifiedPhoneNumber}
              />
              <FormErrorFeedback error={errors.phone || (unverifiedPhoneNumber && t('enterValidPhoneNumber'))} />
            </FormGroup>
          </Row>
          <RegistrationFormSetPassword
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            unverifiedPhoneNumber={unverifiedPhoneNumber}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RegistrationForm;
