import { useEffect, useState } from 'react';
import { equals } from 'ramda';

let scrollingPreventionDisabled = false;

/**
 * This useHook prevents the scroll from changing when an element inside a scrollable layout gets bigger.
 * @param elementInsideScrollableLayoutThatGetsBigger element that gets bigger and causes the scroll to change - this element should be inside a scrollable layout
 * @param changableValue any value that changes - is used to trigger the scroll stabilization
 */
export const usePreventScrollChangeOnElementResize = (
  elementInsideScrollableLayoutThatGetsBigger: HTMLElement | null,
  changableValue: unknown,
) => {
  const [lastState, setLastState] = useState(changableValue);
  const [scrollableElement, setScrollableElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const scrollableElement = getScrollableParent(elementInsideScrollableLayoutThatGetsBigger);
    setScrollableElement(scrollableElement);
  }, [elementInsideScrollableLayoutThatGetsBigger]);

  if (equals(lastState, changableValue) || !scrollableElement || scrollingPreventionDisabled) return;
  setLastState(changableValue);

  const scrollHeightBeforeRerender = scrollableElement.scrollHeight;
  const scrollTopBeforeRerender = scrollableElement.scrollTop;

  setTimeout(() => {
    const scrollHeightAfterRerender = scrollableElement.scrollHeight;
    const rect = elementInsideScrollableLayoutThatGetsBigger?.getBoundingClientRect();
    // If the scroll height didn't change - the element did not get bigger we return
    // If the viewport in on the element or above it we return coz it would jump even weirder
    if (scrollHeightAfterRerender === scrollHeightBeforeRerender && (!rect || rect?.y + rect?.height < 0)) return;
    const heightDifference = scrollHeightAfterRerender - scrollHeightBeforeRerender;
    // We compute the difference between the scroll height before and after the rerender and add it to the scroll top
    scrollableElement.scrollTop = scrollTopBeforeRerender + heightDifference;
  }, 0);
};

/**
 * returns the first scrollable parent of an element
 */
const getScrollableParent = (element: HTMLElement | null): HTMLElement | null => {
  if (element === null) {
    return null;
  }
  if (element.scrollHeight > element.clientHeight) {
    return element;
  }
  return getScrollableParent(element.parentElement as HTMLElement);
};

/**
 * Disables the scrolling prevention for a given duration - useful when you want to programmatically scroll to some element
 * @param duration duration in milliseconds
 */
export const disableScrollingPrevention = (duration: number) => {
  scrollingPreventionDisabled = true;
  setTimeout(() => {
    scrollingPreventionDisabled = false;
  }, duration);
};
