import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import BaseModal from './BaseModal';

type Props = {
  onSave: () => void;
  onClose: () => void;
  title: string;
};

export const EndPublicSurveyModal: FC<Props> = ({ onClose, onSave, title }) => {
  const [t] = useTranslation('surveyForm');

  return (
    <BaseModal
      title={t('endPublicSurveyTitle')}
      description={
        <Trans
          i18nKey={'surveyForm:endPublicSurveyDescription'}
          values={{
            title,
          }}
        >
          Skutečně si přeješ ukončit veřejný průzkum <strong>Názov</strong>?<br />
          Po ukončení již nebude přesdílený link ani QR kód funkční.
        </Trans>
      }
      primaryActionText={t('endPublicSurveyButton')}
      onPrimaryAction={() => {
        onSave();
        onClose();
      }}
      secondaryActionText={t('modal:cancel')}
      onSecondaryAction={onClose}
      onClose={onClose}
    />
  );
};
export default EndPublicSurveyModal;
