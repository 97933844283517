import { Mutation } from '@apollo/client/react/components';
import { Modal, useToast } from '@arnold/common';
import { getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOrganizationSurveyMutation, DeleteOrganizationSurveyMutationVariables } from '../../generated/hooks';
import { deleteOrganizationSurveyMutation } from '../../graphql/mutations';
import { getUserQuery } from '../../graphql/queries';
import { getOperationName } from '../../lib/common';
import { ButtonLink } from '../StyledComponents';

type DeleteSurveyProps = {
  surveyId: string;
  label?: string;
  sendAt: string;
  endsAt: string;
};

const DeleteSurvey: FC<DeleteSurveyProps> = ({ surveyId, label, sendAt, endsAt }) => {
  const [t] = useTranslation('surveyOverviewRow');
  const [confirmAlert, showConfirmAlert] = useState(false);
  const { addToast } = useToast();

  const hideAlert = () => {
    showConfirmAlert(false);
  };

  const handleMutationError = () => {
    addToast(t('toast:changesFailedToSave'));
    hideAlert();
  };

  const handleMutationCompleted = () => {
    addToast(t('toast:surveysOverviewSurveyDeleted'));
    hideAlert();
  };

  return (
    <Mutation<DeleteOrganizationSurveyMutation, DeleteOrganizationSurveyMutationVariables>
      mutation={deleteOrganizationSurveyMutation}
      variables={{ surveyId }}
      onCompleted={handleMutationCompleted}
      onError={handleMutationError}
      refetchQueries={[getOperationName(getUserQuery) || '']}
    >
      {(deleteSurvey: () => void) => (
        <>
          <ButtonLink weight={500} onClick={() => showConfirmAlert(true)} data-cy="admin-delete-survey">
            {t('delete')}
          </ButtonLink>
          <Modal
            show={confirmAlert}
            onHide={hideAlert}
            onSubmit={deleteSurvey}
            title={t('deleteDialogTitle')}
            text={`${label}: ${getLocalizedDateWithoutLeadingZeroes(
              new Date(sendAt),
            )}-${getLocalizedDateWithoutLeadingZeroes(new Date(endsAt))}`}
            buttons={{
              submit: { title: t('confirmBtnText') },
              cancel: { title: t('cancel') },
            }}
          />
        </>
      )}
    </Mutation>
  );
};

export default DeleteSurvey;
