import parse from 'html-react-parser';
import { modifyText } from '@arnold/core';

export const formatText = (text: string): string | JSX.Element | JSX.Element[] => {
  return parse(text.replace(/\[([^\]]+)\]\(([^)]+)\)/g, '<a href="$2" target="_blank">$1</a>'));
};
export const getRawText = (text: string): string | JSX.Element | JSX.Element[] => {
  return parse(text.replace(/\[([^\]]+)\]\(([^)]+)\)/, '$1'));
};
export const replaceQuestionPlaceholders = (
  text: string,
  organizationName: string,
  firstDate: string,
  teamLeaderName: string,
) =>
  modifyText(text, {
    '%ORG_NAME%': organizationName,
    '%FIRST_DATE%': firstDate,
    '%TL_NAME%': teamLeaderName,
  });
