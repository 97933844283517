import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { BodyMedium, HeadingMedium, theme } from '@arnold/common';
import PublicLayout from '../../components/Common/PublicLayout';
import storage from '../../lib/storage';
import { confirmEmailMutation } from '../../graphql/mutations';
import { Loading } from '../../components';
import { PrimaryButton } from '../../components/StyledComponents';
import auth, { USER_KEY } from '../../lib/auth';
import RegistrationAside from './RegistrationAside';

export const ConfirmEmailScreen = ({ match, history, location }: RouteComponentProps<any>) => {
  const { accessToken, lang } = match.params;
  const detectedLang = lang || storage.getDetectedLanguage()?.substring(0, 2);
  const [confirmEmail] = useMutation(confirmEmailMutation);
  const [confirmedEmail, setConfirmedEmail] = useState(undefined);
  const { t } = useTranslation('registration');

  useEffect(() => {
    localStorage.removeItem(USER_KEY);
  }, []);

  useEffect(() => {
    if (accessToken) {
      const confirm = async () => {
        const response = await confirmEmail({
          context: { headers: { Authorization: `Bearer ${accessToken}` } },
          variables: {
            deviceToken: auth.getDeviceToken(),
          },
        });
        setConfirmedEmail(response.data.confirmEmail);
      };
      try {
        confirm();
      } catch (e) {
        console.log(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PublicLayout basePath={'register/confirm'} history={history} lang={detectedLang} aside={<RegistrationAside />}>
      {confirmedEmail ? (
        <>
          <HeadingMedium margin={`0 0 ${theme.spacing.h} 0`}>{t('emailConfirmed')}</HeadingMedium>
          <BodyMedium margin={`${theme.spacing.g} 0`}>
            <Trans i18nKey="registration:emailConfirmedInfo" values={{ email: confirmedEmail }}>
              Arnold účet {{ email: confirmedEmail }} je nyní připravený k použití.
            </Trans>
          </BodyMedium>
          <PrimaryButton variant="primary" type="submit" onClick={() => history.push(`/login/${lang}`)}>
            {t('logIn')}
          </PrimaryButton>
        </>
      ) : (
        <Loading />
      )}
    </PublicLayout>
  );
};

export default ConfirmEmailScreen;
