import { PureQueryOptions } from '@apollo/client';
import { REPORT_LINK, getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import { TFunction } from 'react-i18next';
import { getFullName, getTopicGroupName, showResult } from '../../lib/helpers';
import i18n from '../../translations/i18n';
import { SequenceReportAccessesQuery } from '../../generated/hooks';
import { ActionButtons } from './ActionButtons';
import { ReportOpenInfo } from './ReportOpenInfo';
import { ReportData, SurveyTableItemType, TableItem } from './types';
import { getStateTitle, getTopicGroup, isSurveyReport } from './utils';

type GetDataSourceParams = {
  showOpenIcon?: boolean;
  t: TFunction;
  tableItems: TableItem[];
  dependantQueries: Array<string | PureQueryOptions>;
};

export const getDataSource = ({ t, showOpenIcon, tableItems, dependantQueries }: GetDataSourceParams): ReportData[] => {
  return tableItems
    .sort((s1, s2) => s2.timestamp - s1.timestamp)
    .map((tableItem) => {
      const topicGroup = getTopicGroup(
        tableItem.reportBasePath,
        tableItem.type === SurveyTableItemType.Survey ? tableItem.data : undefined,
        tableItem.type === SurveyTableItemType.Sequence
          ? (tableItem.data as NonNullable<SequenceReportAccessesQuery['surveyGroup']>)
          : undefined,
      );

      const sendAt = tableItem.timestamp;

      return !tableItem?.data?.reports
        ? []
        : tableItem?.data?.reports
            ?.filter((report) => !!report.respondent)
            .sort((a, b) =>
              `${a.respondent?.surname} ${a.respondent?.firstname}`
                .toLowerCase()
                .localeCompare(`${b.respondent?.surname} ${b.respondent?.firstname}`.toLowerCase()),
            )
            .map((report) => {
              const status = getStateTitle(t, report, tableItem.isOngoing);
              return {
                id: report.id,
                reportLabel: (
                  <span data-icom={'reports-report-label'}>
                    {getFullName(report.respondent?.firstname, report.respondent?.surname, true)}
                  </span>
                ),
                status,
                reportOpened: <ReportOpenInfo t={t} report={report} showOpenIcon={showOpenIcon} status={status} />,
                actions: (
                  <ActionButtons
                    report={report}
                    type={tableItem.type}
                    reportBasePath={tableItem.reportBasePath}
                    t={t}
                    isOngoing={tableItem.isOngoing}
                    dependantQueries={dependantQueries}
                  />
                ),
                topicName: topicGroup ? getTopicGroupName(i18n.language, topicGroup) : '',
                sendAt: sendAt && getLocalizedDateWithoutLeadingZeroes(new Date(sendAt), i18n.language),
                teams:
                  report.teams
                    ?.map((team) =>
                      t('team', {
                        team: team.name || t('teams:withoutLeader'),
                      }),
                    )
                    .join(', ') || '',
                recipients: `${report.respondent?.firstname} ${report.respondent?.surname}`,
                responseRate:
                  (isSurveyReport(report) &&
                    tableItem.reportBasePath === REPORT_LINK.REPORT &&
                    showResult(report?.resultsInfo?.answered, report?.resultsInfo?.total)) ||
                  '',
              };
            });
    })
    .flat();
};
