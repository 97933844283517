import { OrganizationTeamsQuery } from '../../generated/hooks';
import { getTeamMemberIdsRecursive } from '.';

export function getTeamLeaders(teams: NonNullable<OrganizationTeamsQuery['organizationTeams']>, respondentId?: string) {
  const teamMemberIds = respondentId
    ? getTeamMemberIdsRecursive(
        teams,
        teams.find((t) => t.leader?.id === respondentId),
      )
    : [];

  const filteredTeams = teams.filter(
    (t: NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]) =>
      t.leader &&
      (!respondentId || (t.leader.id === respondentId && t.rootTeam) || !teamMemberIds.includes(t.leader.id)),
  );

  return filteredTeams
    .filter((team) => team.leader)
    .sort(({ leader: leaderA }, { leader: leaderB }) => {
      const surnameA = leaderA?.surname?.toUpperCase()!;
      const surnameB = leaderB?.surname?.toUpperCase()!;
      if (surnameA < surnameB) {
        return -1;
      }
      if (surnameA > surnameB) {
        return 1;
      }

      const firstnameA = leaderA?.firstname?.toUpperCase()!;
      const firstnameB = leaderB?.firstname?.toUpperCase()!;
      if (firstnameA < firstnameB) {
        return -1;
      }
      if (firstnameA > firstnameB) {
        return 1;
      }

      return 0;
    });
}

export function getPossibleTeamLeaders(teams: NonNullable<OrganizationTeamsQuery['organizationTeams']>) {
  const teamLeaderIds: string[] = [];
  teams.forEach((team) => {
    if (team.leader) {
      teamLeaderIds.push(team.leader.id);
    }
  });

  const nonLeaders = teams.flatMap((team) => team.members).filter((member) => !teamLeaderIds.includes(member.id));

  return nonLeaders.sort((employeeA, employeeB) => {
    const surnameA = employeeA?.surname?.toUpperCase()!;
    const surnameB = employeeB?.surname?.toUpperCase()!;
    if (surnameA < surnameB) {
      return -1;
    }
    if (surnameA > surnameB) {
      return 1;
    }

    const firstnameA = employeeA?.firstname?.toUpperCase()!;
    const firstnameB = employeeB?.firstname?.toUpperCase()!;
    if (firstnameA < firstnameB) {
      return -1;
    }
    if (firstnameA > firstnameB) {
      return 1;
    }

    return 0;
  });
}
