import { useMutation } from '@apollo/client';
import { ReactComponent as VisibilityOffIcon } from '@arnold/common/lib/assets/icons/VisibilityOff.svg';
import { ReactComponent as VisibilityOnIcon } from '@arnold/common/lib/assets/icons/VisibilityOn.svg';
import { Field, Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { FormGroup } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { validatePassword } from '@arnold/core';
import { Loading } from '../../components';
import { FormControlPassword, FormErrorFeedback, FormGroupLabel, FormInputInfo } from '../../components/Common';
import { CheckboxGroup, PrimaryButton, ShowPasswordButton } from '../../components/StyledComponents';
import { AccessTokenStatus, Origin, useGetBaseUserQuery } from '../../generated/hooks';
import { changePasswordMutation, resetPasswordMutation } from '../../graphql/mutations';
import auth, { USER_KEY } from '../../lib/auth';
import RecoverPasswordSuccess from '../RecoverPassword/RecoverPasswordSuccess';

interface IProps {
  accessToken: string;
  lang?: string;
}

const ChangePasswordForm = ({ accessToken, lang }: IProps) => {
  const [t] = useTranslation(['changePasswordForm', 'loginForm']);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [changePassword] = useMutation(changePasswordMutation);
  const [emailSent, setEmailSent] = useState('');
  const [resetPasswordSubmitting, setResetPasswordSubmitting] = useState(false);
  const [resetPassword] = useMutation(resetPasswordMutation);

  const { loading, data } = useGetBaseUserQuery({
    variables: {
      accessToken,
    },
  });

  useEffect(() => {
    localStorage.removeItem(USER_KEY);
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (
    !data?.isAccessTokenValid.status ||
    [AccessTokenStatus.Revoked, AccessTokenStatus.Expired].includes(data.isAccessTokenValid.status)
  ) {
    if (emailSent) {
      return <RecoverPasswordSuccess email={emailSent} />;
    }
    const sendTokenAgain = async () => {
      try {
        const username = data?.isAccessTokenValid.baseUser?.username || '';
        setResetPasswordSubmitting(true);
        await resetPassword({
          variables: { username, origin: Origin.SelfCare },
        });
        setEmailSent(username);
        setResetPasswordSubmitting(false);
      } catch (e) {
        setResetPasswordSubmitting(false);
      }
    };
    return (
      <div>
        {t('tokenExpired')}
        <div className="d-flex flex-wrap align-items-start mt-7">
          <PrimaryButton variant="primary" type="submit" disable={resetPasswordSubmitting} onClick={sendTokenAgain}>
            {t('sendAgain')}
          </PrimaryButton>
        </div>
      </div>
    );
  }

  const missingConsent = !data.isAccessTokenValid.consent;

  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string().test(
      'is-password-valid',
      t('registration:enterValidPassword'),
      (value) => value && validatePassword(value, data.isAccessTokenValid.baseUser?.username),
    ),
  });

  const handleOnSubmit = async (values: FormikValues, { setSubmitting }: FormikHelpers<any>) => {
    const newPassword = values.password;
    localStorage.removeItem(USER_KEY);
    const variables = {
      newPassword,
      consent: missingConsent ? values.consent : undefined,
      deviceToken: auth.getDeviceToken(),
    };

    const data = await changePassword({
      variables,
      context: { headers: { Authorization: `Bearer ${accessToken}` } },
    });

    try {
      setSubmitting(false);
      await auth.login(data.data.changePassword.username, values.password);
      history.push('/overview');
    } catch (e) {
      let response;
      try {
        response = await (e as Response).json();
      } catch (err) {
        // Do nothing
      }
      if ((e as Response).status === 403) {
        history.push({
          pathname: `/login${lang ? `/${lang}` : ''}`,
          state: {
            otpEmail: response.email,
            otpValidTo: response.otpValidTo,
            password: values.password,
            username: data.data.changePassword.username,
          },
        });
      } else {
        history.push(`/login${lang ? `/${lang}` : ''}`);
      }
    }
  };

  return (
    <Formik
      initialValues={{ password: '', consent: false }}
      onSubmit={handleOnSubmit}
      validationSchema={ChangePasswordSchema}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <FormGroupLabel>{t('password')}</FormGroupLabel>
            <FormControlPassword
              style={{
                paddingRight: 40,
              }}
              type={showPassword ? 'text' : 'password'}
              name="password"
              value={values.password || ''}
              onChange={handleChange}
              isInvalid={touched.password && !!errors.password}
            />
            <ShowPasswordButton
              onClick={(e) => {
                if (e.screenX || e.screenY) {
                  e.preventDefault();
                  setShowPassword(!showPassword);
                }
              }}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityOnIcon />}
            </ShowPasswordButton>
            {errors.password && <FormErrorFeedback error={errors.password} />}
            {!errors.password && <FormInputInfo>{t('registration:enterValidPassword')}</FormInputInfo>}
          </FormGroup>
          {missingConsent && (
            <FormGroup>
              <CheckboxGroup>
                <Field type="checkbox" name="consent" />
                <span style={{ marginLeft: '18px' }}>
                  <Trans
                    i18nKey={'registration:agreeWithT&C'}
                    components={[<a href={t('registration:T&CLink')} target="_blank" rel="noreferrer" />]}
                  >
                    I agree with the LMC s.r.o. Terms and Conditions.
                  </Trans>
                </span>
              </CheckboxGroup>
            </FormGroup>
          )}
          <div className="d-flex flex-wrap align-items-start mt-7">
            <PrimaryButton
              variant="primary"
              type="submit"
              disabled={isSubmitting || (!values.consent && missingConsent) || !values.password}
            >
              {t('change')}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
