import { useState, FC } from 'react';
import { HeadingLarge, Tabs, notEmpty, theme } from '@arnold/common';
import { useTranslation } from 'react-i18next';
import { Card, PageWrapper } from '../../components/Common';
import {
  SurveyStatus,
  ProcessStatus,
  useSurveysAndSurveyGroupsQuery,
  IsAccessTokenValidQuery,
  OrganizationQuery,
  useGetRespondentsQuery,
} from '../../generated/hooks';
import { Loading } from '../../components';
import { ReportAccessTable } from '../ReportAccess';
import { NoSurveysInfoBase } from '../../components/NoSurveysInfo/NoSurveysInfoBase';
import { getSurveysAndSurveyGroupsQuery } from '../../graphql/queries';

type Props = {
  user: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
  organization: OrganizationQuery['organization'];
};

export const ResultsScreen: FC<Props> = ({ user, organization }) => {
  const [t, i18n] = useTranslation('results');
  const [isAll, setIsAll] = useState(false);

  const { data, loading, error } = useSurveysAndSurveyGroupsQuery({ variables: { languageCode: i18n.language } });
  const {
    loading: userLoading,
    data: userData,
    error: userError,
  } = useGetRespondentsQuery({
    variables: { languageCode: i18n.language },
  });

  if (loading || userLoading) {
    return <Loading />;
  }
  if (error || userError || !data?.user || !data.user.organization) {
    return <></>;
  }

  const myRespondent = userData?.user?.respondents?.find(
    (respondent) => respondent.organization.id === organization?.id,
  );

  const surveys = data.user.organization.surveys.filter(
    (survey) => ![SurveyStatus.Archived, SurveyStatus.Notstarted].includes(survey.status),
  );

  const filteredSurveys = myRespondent
    ? surveys
        .map((survey) => {
          const filteredReports = survey.reports?.filter((report) => report.respondent?.id === myRespondent.id);
          if (filteredReports?.length) {
            return {
              ...survey,
              reports: filteredReports,
            };
          }
          return null;
        })
        .filter(notEmpty)
    : [];

  const surveyGroups = data.user.organization.surveyGroups.filter(
    (surveyGroup) => ![ProcessStatus.Archived, ProcessStatus.Notstarted].includes(surveyGroup.status),
  );

  const filteredSurveyGroups = myRespondent
    ? surveyGroups
        .map((surveyGroup) => {
          const filteredReports = surveyGroup.reports?.filter((report) => report.respondent?.id === myRespondent.id);
          if (filteredReports?.length) {
            return {
              ...surveyGroup,
              reports: filteredReports,
            };
          }
          return null;
        })
        .filter(notEmpty)
    : [];

  const isWithoutSurveys =
    data!.user.organization.surveys.length === 0 && data!.user.organization.surveyGroups.length === 0;

  return (
    <PageWrapper data-cy="admin-page-content">
      <HeadingLarge data-icom={'heading-results'}>{t('resultsTitle')}</HeadingLarge>
      <Tabs
        items={[
          {
            title: t('reportsForMe'),
            active: !isAll,
            onClick: () => setIsAll(false),
          },
          {
            title: t('allReports'),
            active: isAll,
            onClick: () => setIsAll(true),
          },
        ]}
        theme={theme}
      />
      <Card>
        {isWithoutSurveys ? (
          <NoSurveysInfoBase
            translationNamespace="results"
            navigateTo={'/conversationSubjects'}
            dataCy="admin-no-results"
          />
        ) : (
          <ReportAccessTable
            dependantQueries={[
              {
                query: getSurveysAndSurveyGroupsQuery,
                variables: { languageCode: i18n.language },
              },
            ]}
            surveys={isAll ? surveys : filteredSurveys}
            surveyGroups={isAll ? surveyGroups : filteredSurveyGroups}
            showOpenIcon
            showAdditionalInfoInTable
          />
        )}
      </Card>
    </PageWrapper>
  );
};

export default ResultsScreen;
