import { ENVIRONMENT } from '@arnold/core';

let alreadyInitialized = false;

export function getFrontendConfigValue(key: string, defaultValue?: any) {
  if (!alreadyInitialized && process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.LOCAL) {
    setupLocalFrontendConfig();
  }
  return (window as any).FE_CONFIG?.[key] ?? defaultValue;
}

export function setupLocalFrontendConfig() {
  alreadyInitialized = true;
  (window as any).FE_CONFIG = Object.entries(process.env).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.replace(/^REACT_APP_/, '')]: value,
    }),
    {},
  );
}
