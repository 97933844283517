import { BodySmall, formatDate, LinkButton, notEmpty, SmallSurveyCard, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getCategoryIcon } from '../../lib/helpers';
import { SurveyInfo } from '../SurveysOverview/RowParts';
import { DashboardQuery, ProcessStatus, OrganizationQuery } from '../../generated/hooks';
import { goToSGResults } from '../GeneralProcessSurveyTopicDetail/utils';
import { DashboardSection } from './DashboardSection';

type ProcessSurveysProps = {
  organization: NonNullable<OrganizationQuery['organization']>;
  activeProcessSurveys?: NonNullable<DashboardQuery['user']['organization']>['activeProcessSurveys'];
  onResultsClick: () => void;
};

const SurveyInfoSpan = styled.span`
  margin-left: ${theme.spacing.d} !important;
`;

export const ProcessSurveys: FC<ProcessSurveysProps> = ({ organization, activeProcessSurveys, onResultsClick }) => {
  const { t } = useTranslation('dashboard');

  if (!activeProcessSurveys?.length) {
    return null;
  }

  return (
    <DashboardSection title={t('processSurveys')} buttons={<></>} key={'processSurveys'} keyName={'process-surveys'}>
      {activeProcessSurveys.map((surveyGroup) => {
        const commonAccessKey = surveyGroup.topLevelReport?.accessKey;
        return (
          <SmallSurveyCard
            dataIcom={'process-survey-card'}
            key={surveyGroup.id}
            headerIcon={getCategoryIcon(!surveyGroup.topicGroup.categoryCode, surveyGroup.topicGroup.categoryCode)}
            header={surveyGroup.topicGroup.name}
            results={
              <BodySmall>
                {formatDate(surveyGroup.from)}
                <SurveyInfoSpan>
                  <SurveyInfo
                    isSequential={true}
                    notstartedReason={surveyGroup.notstartedReason?.filter(notEmpty)}
                    inLicenceInterval={
                      !surveyGroup.hasOutOfLicenceInterval || surveyGroup.status === ProcessStatus.Archived
                    }
                    organization={organization}
                    status={surveyGroup.status}
                  />
                </SurveyInfoSpan>
              </BodySmall>
            }
            footer={
              <>
                {commonAccessKey && (
                  <LinkButton
                    margin={'0'}
                    onClick={() => {
                      goToSGResults(commonAccessKey);
                      onResultsClick();
                    }}
                  >
                    {t('results')}
                  </LinkButton>
                )}
                <LinkButton href={`/processSurvey/${surveyGroup.id}`}>{t('detail')}</LinkButton>
              </>
            }
          />
        );
      })}
    </DashboardSection>
  );
};
