import { Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { BodyMedium, HeadingMedium, theme, useToast } from '@arnold/common';
import { Envelope } from '../../assets/images';
import { sendEmailConfirmationMutation } from '../../graphql/mutations';

interface IProps {
  email?: string;
  lang: string;
}

const LinkButtonMedium = (props: any) => (
  <Button variant="link" bsPrefix="override" className="link no-btn font-size-s" onClick={props.clicked}>
    {props.children}
  </Button>
);
export const ConfirmEmail = ({ email, lang }: IProps) => {
  const { t } = useTranslation('registration');
  const [sendEmailConfirmation] = useMutation(sendEmailConfirmationMutation);
  const { addToast } = useToast();

  const handleClick = async () => {
    await sendEmailConfirmation({
      variables: {
        email,
        language: lang,
      },
    });
    addToast(t('sendEmailSuccess'));
  };

  const ResendButton = () => <LinkButtonMedium clicked={handleClick}>{t('resendButton')}</LinkButtonMedium>;

  return (
    <>
      <HeadingMedium margin={`0 0 ${theme.spacing.h} 0`} daty-cy={'admin-confirm-email'}>
        {t('confirmEmail')}
      </HeadingMedium>
      <div className={'d-flex justify-content-center'}>
        <img src={Envelope} height={60.15} alt="envelope" />
      </div>
      <BodyMedium margin={`${theme.spacing.g} 0`}>
        <Trans i18nKey="registration:confirmInfo" values={{ email }} />
      </BodyMedium>
      <div>
        <Trans i18nKey={'registration:resendEmail'} components={[<ResendButton />]}>
          Nedostali jste e-mail?
        </Trans>
      </div>
      <div>
        <Trans
          i18nKey={'registration:helpQuestion'}
          components={[
            <a key="0" href={`mailto:${t('informationFooter:helpEmail')}`} target="_blank" rel="noreferrer">
              {t('informationFooter:helpEmail')}
            </a>,
          ]}
        >
          Potřebujete pomoc? Ozvěte se prosím na{' '}
          <a key="0" href={`mailto:${t('informationFooter:helpEmail')}`} target="_blank" rel="noreferrer">
            {t('informationFooter:helpEmail')}
          </a>
          .
        </Trans>
      </div>
    </>
  );
};

export default ConfirmEmail;
