import { Cell, Legend, Pie, PieChart } from 'recharts';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { theme } from '../../../theme';
import { isNullish } from '../../../helpers';

const LegendWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const SmallHeading = styled.div`
  color: ${theme.colors.text.secondary};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: ${theme.typography.body.small.regular.fontWeight};
  font-size: ${theme.typography.body.small.regular.fontSize};
  line-height: ${theme.typography.body.small.regular.lineHeight};
`;

const BiggerSubTitle = styled.div`
  color: ${theme.colors.text.primary};
  margin-top: 4px;
  font-family: 'Roboto';
  font-weight: ${theme.typography.body.large.bold.fontWeight};
  font-size: ${theme.typography.body.large.bold.fontSize};
  line-height: ${theme.typography.body.large.bold.lineHeight};
`;

const ValueHeading = styled.div`
  color: ${theme.colors.text.primary};
  font-size: 30px;
  font-weight: 700;
  line-height: 43.65px;
`;

const IconWrapper = styled.div`
  margin-bottom: 10px;
`;

type CircleGraphProps = {
  value?: string | number;
  color?: string;
  backgroundColor?: string;
  title?: string;
  valueSubTitle?: string;
  centerContent?: ReactNode;
  icon?: string;
  hideValueInGraph?: boolean;
  dataCy?: string;
};

const prepareData = (
  value = '0',
  color = theme.colors.actionPrimary.default,
  backgroundColor = theme.colors.actionInvertedPrimary.default,
  hideValueInGraph = false,
) =>
  Number.parseInt(value, 10) && !hideValueInGraph
    ? [
        { value: 100 - Number.parseInt(value, 10), color: backgroundColor },
        { value: Number.parseInt(value, 10), color },
      ]
    : [{ value: 100, color: backgroundColor }];

const LegendWithValue: FC<Pick<CircleGraphProps, 'value' | 'title' | 'valueSubTitle' | 'dataCy'>> = ({
  value,
  title,
  valueSubTitle,
  dataCy,
}) => (
  <LegendWrapper>
    <SmallHeading>{title}</SmallHeading>
    <ValueHeading data-cy={dataCy}>{value}</ValueHeading>
    <SmallHeading>{valueSubTitle}</SmallHeading>
  </LegendWrapper>
);

const LegendWithoutValue: FC<Pick<CircleGraphProps, 'title' | 'valueSubTitle' | 'icon' | 'dataCy'>> = ({
  title,
  valueSubTitle,
  icon,
  dataCy,
}) => (
  <LegendWrapper>
    <IconWrapper>
      <img src={icon} alt="" />
    </IconWrapper>
    <SmallHeading>{title}</SmallHeading>
    <BiggerSubTitle data-cy={dataCy}>{valueSubTitle}</BiggerSubTitle>
  </LegendWrapper>
);

export const CircleGraph: FC<CircleGraphProps> = ({
  value,
  color,
  backgroundColor,
  title,
  valueSubTitle,
  icon,
  hideValueInGraph,
  dataCy,
}) => {
  const data = prepareData(
    value == null ? undefined : typeof value === 'string' ? value : value.toString(),
    color,
    backgroundColor,
    hideValueInGraph,
  );

  return (
    <PieChart width={160} height={160}>
      <Pie
        data={data}
        dataKey="value"
        startAngle={90}
        endAngle={450}
        cx="50%"
        cy="50%"
        innerRadius={65}
        outerRadius={80}
        blendStroke={true}
      >
        {data?.map((d, index) => <Cell key={index} fill={d.color} />)}
      </Pie>
      <Legend
        content={
          isNullish(value) ? (
            <LegendWithoutValue title={title} valueSubTitle={valueSubTitle} icon={icon} dataCy={dataCy} />
          ) : (
            <LegendWithValue value={value} title={title} valueSubTitle={valueSubTitle} dataCy={dataCy} />
          )
        }
        align={'center'}
        verticalAlign={'middle'}
      />
    </PieChart>
  );
};
