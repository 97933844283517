import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PublicLayout from '../../components/Common/PublicLayout';
import storage from '../../lib/storage';
import Tabs, { LandingPage } from '../../components/LandingPageTabs';
import i18n from '../../translations/i18n';
import RegistrationForm from './RegistrationForm';
import RegistrationAside from './RegistrationAside';
import RegistrationFormWithAccessToken from './RegistrationFormWithAccessToken';
import ConfirmEmail from './ConfirmEmail';

export const RegistrationScreen = () => {
  const [email, setEmail] = useState('');

  const { lang } = useParams<{ lang: string }>();
  const history = useHistory();
  const languages = i18n.languages;
  const browserLang = languages[0];

  const detectedLang = lang || storage.getDetectedLanguage()?.substring(0, 2) || browserLang!.substring(0, 2);
  const query = new URLSearchParams(history.location.search);
  const channel = query.get('channel');
  const countryCode = query.get('countryCode');
  const accessToken = query.get('accessToken');
  const showOnlySelected = query.get('showOnlySelected') === 'true';

  return (
    <PublicLayout basePath={'register'} history={history} lang={detectedLang} aside={<RegistrationAside />}>
      {email ? (
        <ConfirmEmail email={email} lang={detectedLang} />
      ) : (
        <>
          <Tabs history={history} selected={LandingPage.registration} showOnlySelected={showOnlySelected} />
          {!accessToken && (
            <RegistrationForm
              history={history}
              channel={channel || 'PPC'}
              countryCode={countryCode}
              setEmail={setEmail}
            />
          )}

          {accessToken && (
            <RegistrationFormWithAccessToken
              accessToken={accessToken}
              history={history}
              lang={detectedLang}
              channel={channel || 'PPC'}
              countryCode={countryCode}
            />
          )}
        </>
      )}
    </PublicLayout>
  );
};

export default RegistrationScreen;
