import styled from '@emotion/styled';
import { theme } from '../../theme';

type Props = {
  columnsCount?: number;
};

const DEFAULT_COLUMNS_COUNT = 2;

export const CardGrid = styled.div<Props>`
  display: grid;
  grid-column-gap: ${theme.spacing.h};
  grid-row-gap: ${theme.spacing.h};
  grid-template-columns: min-content;
  justify-content: center;

  @media (min-width: 686px) {
    justify-content: left;
    grid-template-columns: ${({ columnsCount }) => `repeat(${columnsCount || DEFAULT_COLUMNS_COUNT}, min-content)`};
  }

  @media (min-width: 992px) {
    justify-content: left;
    grid-template-columns: ${({ columnsCount }) => `repeat(${columnsCount || DEFAULT_COLUMNS_COUNT}, min-content)`};
  }

  margin: ${theme.spacing.h} 0px ${theme.spacing.l} 0px;
`;
