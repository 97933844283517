import {
  ActionMenu,
  ActionMenuIcon,
  ActionMenuItem,
  BodyMedium,
  ChatIcon,
  CloseIcon,
  LanguageSelector,
  Modal,
  SaveIcon,
  ShareIcon,
  theme,
  useToast,
} from '@arnold/common';
import styled from '@emotion/styled';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useCreateTestSurveyMutation } from '../../generated/hooks';
import ee from '../../lib/eventEmitter';
import { ButtonLink } from '../StyledComponents';
import { EditTopicGroupInfo } from './components/EditTopicGroupInfo';
import { getTGAccessHeaderContext } from './utils';

const StyledControlPanel = styled.div`
  background-color: ${theme.background.primary};
  padding: ${theme.spacing.f};
  display: flex;
  justify-content: space-between;
`;

const StyledLinkButton = styled(ButtonLink)`
  display: flex;
  align-items: center;
  -webkit-logical-height: auto;
  padding: 0 ${theme.spacing.d};
  margin-left: ${theme.spacing.f};
`;

const ActionMenuWrap = styled.div`
  margin-left: ${theme.spacing.f};
`;

type ControlPanelProps = {
  languages: string[];
  selectedLanguage?: string;
  defaultTopicLanguageCode: string;
  topicGroupTranslation: string;
  handleLanguageChange: (language: string) => void;
  handleLanguageDelete: (language: string) => void;
  onClose: () => void;
  onSave: (isTesting: boolean) => Promise<string | undefined>;
  onClear: () => void;
  onRearrange: () => void;
  onDownloadTranslations: () => void;
  onUploadTranslations: () => void;
  onRegenerateReportOrder: () => void;
  shareLinks: {
    url: string;
    level: string;
  }[];
  topicId: string;
  topicGroupId: string;
  preview: boolean;
  setTopicGroupName: (name: string) => void;
  setTopicGroupDescription: (description: string) => void;
  topicGroupDescription?: string;
  limitedMode?: 'READ' | 'WRITE';
};

export const ControlPanel: FC<ControlPanelProps> = ({
  languages,
  selectedLanguage,
  defaultTopicLanguageCode,
  topicGroupTranslation,
  shareLinks,
  handleLanguageChange,
  handleLanguageDelete,
  topicId,
  topicGroupId,
  onClose,
  onSave,
  onClear,
  onRearrange,
  onRegenerateReportOrder,
  onDownloadTranslations,
  onUploadTranslations,
  preview,
  setTopicGroupName,
  setTopicGroupDescription,
  topicGroupDescription,
  limitedMode,
}) => {
  const { t } = useTranslation(['topicOverview', 'topicEditor']);
  const [clearModalShow, setClearModalShow] = useState(false);
  const { search } = useLocation();
  const { addToast } = useToast();

  const [createTestSurvey, { loading: loadingTestSurvey }] = useCreateTestSurveyMutation();

  const createSurvey = async () => {
    const testTopicId = await onSave(true);
    if (testTopicId == null) return;
    try {
      const { data } = await createTestSurvey({
        variables: {
          input: {
            language: selectedLanguage || '',
            topicId: testTopicId,
          },
        },
        context: getTGAccessHeaderContext(search),
      });
      if (data) {
        window.open(data?.createTestSurvey, '_blank')?.focus();
      }
    } catch (error) {
      addToast(t('topicEditor:createTestSurveyFailed'), undefined, undefined, undefined, true);
      throw error;
    }
  };

  const copyUrlToClipboard = () => {
    ee.emitShareLinksModal(shareLinks);
  };

  const isNameInvalid = topicGroupTranslation !== undefined && topicGroupTranslation.length < 3;

  return (
    <>
      <StyledControlPanel>
        <div className="d-flex align-items-center">
          <EditTopicGroupInfo
            name={topicGroupTranslation}
            description={topicGroupDescription}
            setName={setTopicGroupName}
            setDescription={setTopicGroupDescription}
            preview={preview}
            isNameInvalid={isNameInvalid}
            allowedLanguages={languages}
          />
          <LanguageSelector
            lang={selectedLanguage}
            onChange={handleLanguageChange}
            onDelete={handleLanguageDelete}
            allowedLanguages={languages}
            showAddLanguageButton
            isAlignedToLeft
            displayAlways
            propsOverrideSelectedLanguage
            showDeleteOnLanguages={languages.filter((l) => l !== defaultTopicLanguageCode)}
            margin={`0 0 0 ${theme.spacing.d}`}
          />
        </div>
        <div className="d-flex align-items-center">
          <StyledLinkButton
            disabled={loadingTestSurvey}
            onClick={createSurvey}
            data-icom="btn-test-survey-ariel"
            data-cy="topic-editor-test-survey"
          >
            <ChatIcon />
            <BodyMedium color={theme.colors.iconAction.primary} margin={`0 0 0 ${theme.spacing.d}`}>
              {t('tryTopic')}
            </BodyMedium>
          </StyledLinkButton>
          {((!preview && !limitedMode) || limitedMode === 'WRITE') && (
            <StyledLinkButton
              onClick={() => onSave(false)}
              data-cy="topic-editor-save"
              data-icom="btn-save-topic-ariel"
              disabled={isNameInvalid}
            >
              <SaveIcon />
              <BodyMedium color={theme.colors.iconAction.primary} margin={`0 0 0 ${theme.spacing.d}`}>
                {t('save')}
              </BodyMedium>
            </StyledLinkButton>
          )}
          {!preview && !limitedMode && (
            <StyledLinkButton onClick={copyUrlToClipboard} data-cy="topic-editor-share" data-icom="btn-share-ariel">
              <ShareIcon />
              <BodyMedium color={theme.colors.iconAction.primary} margin={`0 0 0 ${theme.spacing.d}`}>
                {t('share')}
              </BodyMedium>
            </StyledLinkButton>
          )}
          <ActionMenuWrap>
            <ActionMenu
              dataCy="topic-editor-menu"
              dataIcom="btn-other-options-ariel"
              menuDataIcom="menu-other-options-ariel"
              toggleIcon={<ActionMenuIcon />}
              align="right"
              items={
                [
                  !preview && {
                    label: t('topicEditor:clearEditor'),
                    action: () => setClearModalShow(true),
                  },
                  {
                    label: t('topicEditor:rearrange'),
                    action: onRearrange,
                  },
                  !preview && {
                    label: t('topicEditor:regenerateReportOrder'),
                    action: onRegenerateReportOrder,
                  },
                  !preview && {
                    label: t('topicEditor:downloadTranslations'),
                    action: onDownloadTranslations,
                  },
                  !preview && {
                    label: t('topicEditor:uploadTranslations'),
                    action: onUploadTranslations,
                  },
                ].filter((item) => item) as ActionMenuItem[]
              }
            />
          </ActionMenuWrap>
          {!limitedMode && (
            <StyledLinkButton onClick={onClose} data-icom="btn-close-ariel" data-cy="btn-close-ariel">
              <CloseIcon />
              <span className="visually-hidden">{t('close')}</span>
            </StyledLinkButton>
          )}
        </div>
      </StyledControlPanel>
      <Modal
        show={clearModalShow}
        onHide={() => setClearModalShow(false)}
        onSubmit={onClear}
        title={t('topicEditor:clearEditor')}
        text={t('topicEditor:clearEditorModal')}
        buttons={{
          submit: { title: t('topicEditor:clear') },
          cancel: { title: t('topicEditor:cancel') },
        }}
      />
    </>
  );
};
