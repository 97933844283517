import { Query } from '@apollo/client/react/components';
import { Loading } from '..';
import { getSequenceReportAccesses } from '../../graphql/queries';
import { ReportAccessTable } from '../../screens/ReportAccess';
import { SequenceReportAccessesQuery, SequenceReportAccessesQueryVariables } from '../../generated/hooks';

type IProps = {
  surveyGroupId: string;
};

export const ProcessSurveyResultsSettings = ({ surveyGroupId }: IProps) => {
  const dependantQueries = [
    {
      query: getSequenceReportAccesses,
      variables: { surveyGroupId },
    },
  ];

  return (
    <Query<SequenceReportAccessesQuery, SequenceReportAccessesQueryVariables> {...dependantQueries[0]}>
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <></>;
        }
        if (data && data.surveyGroup) {
          return (
            <ReportAccessTable
              dependantQueries={dependantQueries}
              surveyGroups={data.surveyGroup ? [data.surveyGroup] : []}
              showOpenIcon={true}
            />
          );
        }
        return null;
      }}
    </Query>
  );
};
