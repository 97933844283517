import { FC, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Tooltip, usePath } from '@arnold/common';
import {
  TopicGroupTypeCode,
  useCreateTopicGroupFromDefaultMutation,
  useCreateTopicGroupMutation,
} from '../../../generated/hooks';
import Loading from '../../Loading';
import { getAllowedConversationSubjects } from '../../../graphql/queries';
import { NewCustomTopicModal } from './NewCustomTopicModal';

type Props = {
  organizationId: string;
  languages: string[];
  defaultLanguage: string;
};

type ModalType = 'onboarding' | 'survey' | 'processSurvey';

export const NewCustomTopicButton: FC<Props> = ({ organizationId, languages, defaultLanguage }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t, i18n } = useTranslation('createNewTopic');
  const { topicEditor, processDetailPath } = usePath();
  const [modal, setModal] = useState<ModalType | null>(null);

  const [createTopicGroup, { loading }] = useCreateTopicGroupFromDefaultMutation();
  const [createTopicGroupWithSteps] = useCreateTopicGroupMutation();

  const handleSubmit = async (name: string, description: string, language?: string) => {
    if (modal === 'survey') {
      const tg = await createTopicGroup({
        variables: {
          organizationId,
          name,
          description,
        },
        refetchQueries: [
          {
            query: getAllowedConversationSubjects,
            variables: { languageCode: i18n.language },
          },
        ],
      });
      const topicGroupId = tg.data?.createTopicGroupFromDefault?.id;
      if (topicGroupId) {
        topicEditor.toFunc(topicGroupId);
      }
    } else {
      createTopicGroupWithSteps({
        variables: {
          input: {
            containsSteps: true,
            name,
            description,
            language: language!,
            typeCode: modal === 'onboarding' ? TopicGroupTypeCode.Onboarding : undefined,
            organizationId,
          },
        },
        onCompleted: (data) => {
          processDetailPath.toFunc(data.createTopicGroup.id);
        },
      });
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type: ModalType | null) => {
    setAnchorEl(null);
    setModal(type);
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Button
        variant="primary"
        data-cy={`btn-create-new-topic-group`}
        data-icom={'btn-create-new-topic-group'}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {t('createButton')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => handleClose('survey')}
          data-icom="btn-create-new-survey"
          data-cy="btn-create-new-survey"
        >
          <Tooltip title={t('surveyTooltip')}>{t('survey')}</Tooltip>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose('onboarding')}
          data-icom="btn-create-new-onboarding"
          data-cy="btn-create-new-onboarding"
        >
          <Tooltip title={t('onboardingTooltip')}>{t('onboarding')}</Tooltip>
        </MenuItem>
        <MenuItem
          onClick={() => handleClose('processSurvey')}
          data-icom="btn-create-new-process-survey"
          data-cy="btn-create-new-process-survey"
        >
          <Tooltip title={t('processSurveyTooltip')}>{t('processSurvey')} </Tooltip>
        </MenuItem>
      </Menu>
      {loading && <Loading />}
      {modal && (
        <NewCustomTopicModal
          type={modal}
          onClose={() => setModal(null)}
          handleSubmit={handleSubmit}
          languages={languages}
          defaultLanguage={defaultLanguage}
        />
      )}
    </>
  );
};
