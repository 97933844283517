import styled from '@emotion/styled/macro';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { BodyMedium, HeadingXLarge, theme } from '@arnold/common';
import { ReactComponent as LogoInBubble } from '@arnold/common/lib/assets/logo/LogoInBubble.svg';
import { ReactComponent as LogoPrimary } from '@arnold/common/lib/assets/logo/LogoPrimaryDarkBkg.svg';
import { HeadingContainer, LogoContainer, RegistrationAsideContent } from '../../components/StyledComponents';

const ShowMoreContainer = styled.div`
  padding: ${theme.spacing.h} 0 0 0;

  @media (min-width: ${theme.breakpoint.small}) {
    padding: ${theme.spacing.g} 0;
  }

  @media (max-width: ${theme.breakpoint.small}) {
    margin: 0 0 ${theme.spacing.h};
  }
`;

const InvertedLink = styled.a`
  color: ${theme.colors.actionInvertedSecondary.default};

  &:hover {
    color: ${theme.colors.actionInvertedSecondary.hover};
  }

  &:active {
    color: ${theme.colors.actionInvertedSecondary.active};
  }

  &:focus {
    color: ${theme.colors.actionInvertedSecondary.focused};
  }
`;

export const RegistrationAside = () => {
  const { t } = useTranslation('registration');
  return (
    <RegistrationAsideContent>
      <div>
        <LogoInBubble />
      </div>
      <HeadingContainer>
        <HeadingXLarge color={theme.colors.textInverted.primary} margin={'0'}>
          {t('tryArnold')}
        </HeadingXLarge>
      </HeadingContainer>
      <BodyMedium color={theme.colors.textInverted.primary}>
        <Trans i18nKey={'registration:arnoldDescription'}>
          Vedu se zaměstnanci pravidelný dialog formou
          <strong>krátkých a interaktivních konverzací.</strong> <br />
          <br />
          Jednoduše vyplňte formulář a nastavte si pro sebe a pro vaše týmy témata konverzací. Můžete si vybrat hned z
          několika témat a po ukončení interakce se zaměstnancem obdržíte report s vyhodnocením.
        </Trans>
      </BodyMedium>
      <ShowMoreContainer>
        <BodyMedium color={theme.colors.textInverted.primary}>
          <Trans
            i18nKey={'registration:showMore'}
            components={[<InvertedLink href={t('marektingUrl')} target="_blank" rel="noreferrer" />]}
          >
            Potřebujete vědět víc?
          </Trans>
        </BodyMedium>
      </ShowMoreContainer>
      <LogoContainer>
        <LogoPrimary width="228" height="57" />
      </LogoContainer>
    </RegistrationAsideContent>
  );
};

export default RegistrationAside;
