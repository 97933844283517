import {
  CleanButton,
  CopyLink,
  HourGlass,
  NoMetricsQuestionIcon,
  NoSurveyIcon,
  NotDeliveredIcon,
  PlainButton,
  SendIcon,
  Tooltip,
  isNullish,
  theme,
  useToast,
} from '@arnold/common';
import { NoAnswerComponent } from '@arnold/common/lib/assets/icons/numbers';
import { getLocalizedDateWithoutLeadingZeroes, getWorkingDay } from '@arnold/core';
import styled from '@emotion/styled';
import { addDays, addMinutes } from 'date-fns';
import { random } from 'lodash';
import { useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import {
  AnonymityLevel,
  ConversationSubjectType,
  SurveyGroupQuery,
  SurveyGroupRespondentsQuery,
  SurveyGroupWithoutRespondentsQuery,
  SurveyStatus,
  useCreateSurveyMutation,
  useReopenSurveyMutation,
  useSendInvitationAgainMutation,
  useUpdateSurveyMutation,
} from '../../../../generated/hooks';
import ee from '../../../../lib/eventEmitter';
import { getRespondentFeeling, getTopicGroupName } from '../../../../lib/helpers';
import i18n from '../../../../translations/i18n';
import { ParticipantDetailData } from './types';

const ButtonContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.d};
`;
const HourGlassWithMargin = styled(HourGlass)`
  margin-left: 0.38rem;
`;

const StyledPlainButton = styled(PlainButton)`
  padding: 0;
  height: 22px;
  width: 22px;
`;

type GetDataSourceParams = {
  metricQuestionsForRespondent: Partial<
    NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['metricQuestionsForRespondent']
  >;
  steps:
    | NonNullable<SurveyGroupQuery['surveyGroup']>['steps']
    | NonNullable<SurveyGroupWithoutRespondentsQuery['surveyGroup']>['steps'];
  surveys?: NonNullable<NonNullable<SurveyGroupRespondentsQuery['surveyGroupRespondents']['data']>[0]>['surveys'];
  hiringSurveys?: NonNullable<
    NonNullable<SurveyGroupRespondentsQuery['surveyGroupRespondents']['data']>[0]
  >['hiringSurveys'];
  respondent: SurveyGroupRespondentsQuery['surveyGroupRespondents']['data'][0]['respondents'][0];
  t: TFunction;
  deletedRespondents?: string[];
  undeliveredInvitations: SurveyGroupRespondentsQuery['surveyGroupRespondents']['data'][0]['undeliveredInvitations'];
  addedOn: Date;
  surveyGroupId: string;
  organizationId: string;
  resendingInvitations: string[];
  deliveredInvitations: string[];
  waitForInvitation: (x: string) => void;
};

export const getDataSource = ({
  metricQuestionsForRespondent,
  steps,
  t,
  surveys,
  hiringSurveys,
  respondent,
  deletedRespondents,
  undeliveredInvitations,
  deliveredInvitations,
  addedOn,
  surveyGroupId,
  organizationId,
  resendingInvitations,
  waitForInvitation,
}: GetDataSourceParams): ParticipantDetailData[] => {
  const respondentData = metricQuestionsForRespondent.find(
    (metricQuestion) => metricQuestion?.respondentId === respondent.id,
  );

  return steps.map((step) => {
    let stepSurvey = surveys?.find((survey) => survey.topicGroup?.id === step.processStep.stepTopicGroup.id);
    if (!stepSurvey) {
      stepSurvey = hiringSurveys?.find(
        (survey) =>
          survey.topicGroup?.id === step.processStep.stepTopicGroup.id &&
          survey.whitelistedRespondents.some((r) => r.id === respondent.id),
      );
    }
    const channel = stepSurvey?.channels?.find((ch) => ch.respondent?.id === respondent.id);
    const anonymous =
      stepSurvey?.channels?.some((ch) => ch.allowedAnonymities.some((a) => a === AnonymityLevel.Organization)) ?? false;
    const surveyHasEnded = stepSurvey?.status === SurveyStatus.Completed;
    const notAnswered = (channel && stepSurvey && new Date(channel.finishedAt) > new Date(stepSurvey.endsAt)) ?? false;

    const undelivered = undeliveredInvitations
      ?.find((messages) => messages?.respondentId === respondent.id)
      ?.undeliveredSurveyInvitations?.find(
        (inv) => inv.surveyId === stepSurvey!.id && !deliveredInvitations.includes(inv.surveyId),
      );

    const { sendAt: surveySentAt, id: surveyId } = stepSurvey || {};

    let actionButton: JSX.Element | null = <></>;

    const isResending = resendingInvitations.find((x) => x === stepSurvey?.id);
    if (
      surveyHasEnded &&
      (notAnswered || anonymous) &&
      !respondent.deleted &&
      !deletedRespondents?.includes(respondent.id) &&
      !isResending
    )
      actionButton = (
        <ResendButton
          notAnswered={notAnswered}
          anonymous={anonymous}
          surveyId={surveyId!}
          surveyEndsAt={stepSurvey?.endsAt}
          respondentId={respondent.id}
          afterSend={() => {
            if (stepSurvey) {
              waitForInvitation(stepSurvey.id);
            }
          }}
        />
      );
    if (stepSurvey?.status === SurveyStatus.Ongoing && (!channel?.isFinishedByRespondent || anonymous)) {
      actionButton = (
        <ButtonContainer>
          <SendAgainButton
            notAnswered={!channel?.finishedAt}
            todayWasSent={
              channel?.lastRemindedAt && !undelivered
                ? addDays(new Date(channel?.lastRemindedAt), 1).getTime() >= new Date().getTime()
                : !!isResending
            }
            missingChannel={!channel}
            anonymous={anonymous}
            surveyEnd={new Date(stepSurvey.endsAt)}
            respondentId={respondent.id}
            surveyId={stepSurvey.id}
            afterSend={() => {
              if (stepSurvey) {
                waitForInvitation(stepSurvey.id);
              }
            }}
          />
          {!anonymous && stepSurvey.status === SurveyStatus.Ongoing && channel?.slug && (
            <Tooltip title={t('copyLink')}>
              <StyledPlainButton onClick={() => ee.emitModalShareChannelLink(channel.slug!)}>
                <CopyLink />
              </StyledPlainButton>
            </Tooltip>
          )}
        </ButtonContainer>
      );
    }
    if (!stepSurvey && !respondent.deleted && !deletedRespondents?.includes(respondent.id)) {
      actionButton = (
        <CreateSurveyButton
          respondentId={respondent.id}
          organizationId={organizationId}
          surveyGroupId={surveyGroupId}
          step={step}
          addedOn={new Date(addedOn)}
        />
      );
    }
    if (stepSurvey?.status === SurveyStatus.Notstarted) {
      actionButton = (
        <UpdateNotStartedSurveyButton
          respondentId={respondent.id}
          surveyId={stepSurvey.id}
          step={step}
          addedOn={new Date(addedOn)}
        />
      );
    }

    return {
      id: step.processStep.stepTopicGroup.id,
      sentAt:
        surveySentAt &&
        (new Date(surveySentAt).getTime() > new Date().getTime() ||
          channel ||
          stepSurvey?.status === SurveyStatus.Prepared)
          ? getLocalizedDateWithoutLeadingZeroes(new Date(surveySentAt), i18n.language)
          : t('notSent'),
      topicGroupName: getTopicGroupName(i18n.language, step.processStep.stepTopicGroup),
      feeling: isResending ? (
        <Tooltip title={t('deliveryInProgressTooltip')}>
          <HourGlassWithMargin />
        </Tooltip>
      ) : (stepSurvey &&
          (([SurveyStatus.Prepared, SurveyStatus.Notstarted].includes(stepSurvey.status) &&
            (respondent.deleted || deletedRespondents?.includes(respondent.id))) ||
            ([SurveyStatus.Completed, SurveyStatus.Archived, SurveyStatus.Ongoing].includes(stepSurvey.status) &&
              !channel))) ||
        !stepSurvey ? (
        <Tooltip title={t('notSentTooltip')}>
          <NoSurveyIcon />
        </Tooltip>
      ) : undelivered ? (
        <CleanButton
          onClick={() => {
            ee.emitUpdateRespondent(respondent, organizationId, undelivered?.contact);
          }}
        >
          <Tooltip title={t('notDelivered')}>
            <NotDeliveredIcon />
          </Tooltip>
        </CleanButton>
      ) : !channel?.answered ? (
        <Tooltip title={t('noAnswer')}>
          <NoAnswerComponent />
        </Tooltip>
      ) : !step.hasMetricQuestion ? (
        <Tooltip title={t('noMetricQuestionTooltip')}>
          <NoMetricsQuestionIcon />
        </Tooltip>
      ) : new Date(surveySentAt) > new Date() ? (
        t('scheduled')
      ) : (
        getRespondentFeeling(step.processStep.stepTopicGroup.id, respondentData?.answers)
      ),
      resend: actionButton,
    };
  });
};

export const CreateSurveyButton = ({
  respondentId,
  surveyGroupId,
  organizationId,
  addedOn,
  step,
}: {
  addedOn: Date;
  surveyGroupId: string;
  organizationId: string;
  respondentId: string;
  step:
    | NonNullable<SurveyGroupQuery['surveyGroup']>['steps'][0]
    | NonNullable<SurveyGroupWithoutRespondentsQuery['surveyGroup']>['steps'][0];
}) => {
  const [t] = useTranslation('surveyGroup');
  const [clicked, setClicked] = useState(false);
  const { addToast } = useToast();
  const [createSurvey] = useCreateSurveyMutation();
  return (
    <Tooltip title={t('send')}>
      <StyledPlainButton
        key="send"
        aria-label="Send invitation"
        onClick={() => {
          setClicked(true);
          const processStep = step.processStep;
          if (isNullish(processStep.startOffsetMins) || isNullish(processStep.endOffsetMins)) {
            return null;
          }
          let sendAt = addMinutes(addedOn, processStep.startOffsetMins);
          if (addedOn.getTimezoneOffset() !== sendAt.getTimezoneOffset()) {
            sendAt = addMinutes(sendAt, sendAt.getTimezoneOffset() - addedOn.getTimezoneOffset());
          }
          // Set date of sendAt to today if it is in the past
          const isInPast = sendAt < new Date();
          if (isInPast) {
            sendAt.setFullYear(new Date().getFullYear());
            sendAt.setMonth(new Date().getMonth());
            sendAt.setDate(new Date().getDate());
          }

          const reminders: { reminderAt: Date }[] = [];
          let remindersShift = 0;

          processStep.reminders.forEach((reminder) => {
            const { workingDay: reminderDate, shiftInDays: reminderShift } = getWorkingDay(
              addMinutes(addDays(sendAt, remindersShift), reminder.reminderOffsetMins ?? 0),
            );
            if (reminder.reminderOffsetMins) {
              reminders.push({
                reminderAt: reminderDate,
              });
              remindersShift = remindersShift + reminderShift;
            }
          });

          const endsAt = getWorkingDay(
            addMinutes(addDays(sendAt, remindersShift), processStep.endOffsetMins),
          ).workingDay;

          if (isInPast) {
            sendAt = new Date();
          }
          const toastMessage = t('resendToast') + new Date(endsAt).toLocaleDateString() + '.';

          createSurvey({
            refetchQueries: ['surveyGroupRespondents'],
            awaitRefetchQueries: true,
            variables: {
              input: {
                sendAt,
                endsAt,
                reminders,
                surveyGroupId,
                conversationSubjectId: processStep.stepTopicGroup.id,
                conversationSubjectType: ConversationSubjectType.TopicGroup,
                forceActivation: true,
                organizationId,
                seqDate: addedOn,
                respondentIds: [respondentId],
              },
            },
            onCompleted: () => addToast(toastMessage, undefined, undefined, undefined, true),
          });
        }}
        disabled={clicked}
      >
        <SendIcon color={theme.colors.actionPrimary.default} size={18} />
      </StyledPlainButton>
    </Tooltip>
  );
};

export const UpdateNotStartedSurveyButton = ({
  surveyId,
  respondentId,
  addedOn,
  step,
}: {
  addedOn: Date;
  surveyId: string;
  respondentId: string;
  step:
    | NonNullable<SurveyGroupQuery['surveyGroup']>['steps'][0]
    | NonNullable<SurveyGroupWithoutRespondentsQuery['surveyGroup']>['steps'][0];
}) => {
  const [t] = useTranslation('surveyGroup');
  const [clicked, setClicked] = useState(false);
  const { addToast } = useToast();
  const [updateSurvey] = useUpdateSurveyMutation();
  return (
    <Tooltip title={t('send')}>
      <StyledPlainButton
        key="send"
        aria-label="Send invitation"
        onClick={() => {
          setClicked(true);
          const processStep = step.processStep;
          if (isNullish(processStep.startOffsetMins) || isNullish(processStep.endOffsetMins)) {
            return null;
          }
          let sendAt = addMinutes(addedOn, processStep.startOffsetMins);
          if (addedOn.getTimezoneOffset() !== sendAt.getTimezoneOffset()) {
            sendAt = addMinutes(sendAt, sendAt.getTimezoneOffset() - addedOn.getTimezoneOffset());
          }
          // Set date of sendAt to today if it is in the past
          const isInPast = sendAt < new Date();
          if (isInPast) {
            sendAt.setFullYear(new Date().getFullYear());
            sendAt.setMonth(new Date().getMonth());
            sendAt.setDate(new Date().getDate());
          }

          const reminders: { reminderAt: Date }[] = [];
          let remindersShift = 0;

          processStep.reminders.forEach((reminder) => {
            const { workingDay: reminderDate, shiftInDays: reminderShift } = getWorkingDay(
              addMinutes(addDays(sendAt, remindersShift), reminder.reminderOffsetMins ?? 0),
            );
            if (reminder.reminderOffsetMins) {
              reminders.push({
                reminderAt: reminderDate,
              });
              remindersShift = remindersShift + reminderShift;
            }
          });
          const endsAt = getWorkingDay(
            addMinutes(addDays(sendAt, remindersShift), processStep.endOffsetMins),
          ).workingDay;

          if (isInPast) {
            sendAt = new Date();
          }
          const toastMessage = t('resendToast') + new Date(endsAt).toLocaleDateString() + '.';

          updateSurvey({
            refetchQueries: ['surveyGroupRespondents'],
            awaitRefetchQueries: true,
            variables: {
              surveyId,
              input: {
                sendAt,
                endsAt,
                reminders,
                forceActivation: true,
                respondentIds: [respondentId],
              },
            },
            onCompleted: () => addToast(toastMessage, undefined, undefined, undefined, true),
          });
        }}
        disabled={clicked}
      >
        <SendIcon color={theme.colors.actionPrimary.default} size={18} />
      </StyledPlainButton>
    </Tooltip>
  );
};

export const ResendButton = ({
  notAnswered,
  anonymous,
  surveyId,
  surveyEndsAt,
  respondentId,
  afterSend,
}: {
  notAnswered: boolean;
  anonymous: boolean;
  surveyId: string;
  surveyEndsAt: string;
  respondentId: string;
  afterSend?: () => void;
}) => {
  const [t] = useTranslation('surveyGroup');
  const [clicked, setClicked] = useState(false);
  const { addToast } = useToast();
  const [callReopenMutation] = useReopenSurveyMutation();
  return (
    <Tooltip title={t('resend')}>
      <StyledPlainButton
        key="send"
        aria-label="Send invitation again"
        onClick={() => {
          setClicked(true);
          const toastMessage =
            t(anonymous ? 'resendToastAnonymity' : 'resendToast') +
            new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toLocaleDateString() +
            '.';

          if (notAnswered) {
            // Today + 3 days and set the end hour to the same as previous end had
            const endsAt = new Date(new Date().getTime() + 3 * 24 * 60 * 60 * 1000);
            endsAt.setHours(new Date(surveyEndsAt).getHours());
            endsAt.setMinutes(0);
            endsAt.setSeconds(0);
            endsAt.setMilliseconds(0);
            callReopenMutation({
              refetchQueries: ['surveyGroupRespondents'],
              variables: {
                input: {
                  surveyId,
                  endsAt,
                  sendMessage: true,
                  respondentIds: [respondentId],
                },
              },
              onCompleted: () => {
                addToast(toastMessage, undefined, undefined, undefined, true);
                if (afterSend) {
                  afterSend();
                }
              },
            });
          } else {
            // if user answered anonymously we need to simulate delayed response from server before showing the toast
            setTimeout(() => addToast(toastMessage, undefined, undefined, undefined, true), random(500, 2000));
          }
        }}
        disabled={clicked}
      >
        <SendIcon color={theme.colors.actionPrimary.default} size={18} />
      </StyledPlainButton>
    </Tooltip>
  );
};

export const SendAgainButton = ({
  notAnswered,
  anonymous,
  surveyId,
  surveyEnd,
  respondentId,
  missingChannel,
  todayWasSent,
  afterSend,
}: {
  notAnswered: boolean;
  anonymous: boolean;
  todayWasSent: boolean;
  missingChannel: boolean;
  surveyEnd: Date;
  surveyId: string;
  respondentId: string;
  afterSend?: () => void;
}) => {
  const [t] = useTranslation('surveyGroup');
  const [clicked, setClicked] = useState(false);
  const { addToast } = useToast();
  const [sendAgain] = useSendInvitationAgainMutation();
  return (
    <Tooltip
      title={(todayWasSent && !anonymous) || clicked ? t('todayWasAlreadySent') : t(missingChannel ? 'send' : 'resend')}
    >
      <StyledPlainButton
        key="send"
        aria-label="Send invitation again"
        onClick={() => {
          setClicked(true);
          const toastMessage =
            t(anonymous ? 'resendToastAnonymity' : 'resendToast') + new Date(surveyEnd).toLocaleDateString() + '.';

          if (notAnswered || todayWasSent) {
            sendAgain({
              refetchQueries: ['surveyGroupRespondents'],
              variables: {
                surveyId,
                respondentId,
              },
              onCompleted: () => {
                addToast(toastMessage, undefined, undefined, undefined, true);

                if (afterSend) {
                  afterSend();
                }
              },
            });
          } else {
            // if user answered anonymously we need to simulate delayed response from server before showing the toast
            setTimeout(() => addToast(toastMessage, undefined, undefined, undefined, true), random(500, 2000));
          }
        }}
        disabled={clicked || (todayWasSent && !anonymous)}
      >
        <SendIcon color={theme.colors.actionPrimary.default} size={18} />
      </StyledPlainButton>
    </Tooltip>
  );
};
