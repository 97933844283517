import { theme, Tooltip } from '@arnold/common';

import { FC } from 'react';
import styled from '@emotion/styled/macro';
import { ReactComponent as ErrorWarningIcon } from '../../../assets/images/ErrorWarning.svg';

type WarningMessageProps = {
  title?: string;
  color?: string;
};
export const WarningMessage: FC<WarningMessageProps> = ({ title = '', color }) => (
  <StyledTooltip title={title}>
    <StyledWarningMessage color={color} />
  </StyledTooltip>
);

const StyledTooltip = styled(Tooltip)`
  margin-right: 13px;
`;

const StyledWarningMessage = styled(ErrorWarningIcon)<WarningMessageProps>`
  & path {
    fill: ${({ color }) => color ?? theme.colors.chart.alert[2]};
  }
`;
