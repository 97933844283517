export type ThemeType = typeof theme;

export const theme = {
  // Historical styles
  colorPrimary: '#51bcda',
  colorSecondary: '#b2e1ef',
  colorTertiary: '#f3fafc',
  colorReminder: 'yellow',
  login: {
    backgroundColor: '#f4f3ef',
  },
  background: {
    primary: '#FFFFFF',
  },

  // DSL styles
  shadows: {
    elevationContentBlock: '0px 10px 14px rgba(0, 0, 0, 0.01)',
    elevationContentDialog: '0px 5px 30px -20px rgba(0, 0, 0, 0.5)',
  },
  colors: {
    brand: {
      primary: '#04B7EF',
    },
    ai: {
      default: '#9B37FF',
    },
    text: {
      primary: '#0D0A0B',
      secondary: '#767676',
      disabled: '#A1A1A1',
    },
    form: {
      error: '#af4c62',
    },
    textInverted: {
      primary: '#FFFFFF',
      secondary: '#BDBDBD',
      disabled: '#959595',
    },
    actionPrimary: {
      default: '#04b7ef',
      hover: '#04A5D8',
      active: '#0390BD',
      focused: '#03C3FF',
      disabled: '#A1A1A1',
    },
    actionSecondary: {
      default: '#CCCCCC',
      hover: '#04A5D8',
      active: '#0390BD',
      focused: '#03C3FF',
      disabled: '#A1A1A1',
    },
    actionInvertedPrimary: {
      default: '#F0F0F0',
      hover: '#E8E5E5',
      active: '#CCCCCC',
      focused: '#F7F7F7',
      disabled: '#F8F8F8',
    },
    actionInvertedSecondary: {
      default: '#026482',
      hover: '#01465A',
      active: '#013342',
      focused: '#038BB5',
      disabled: '#B1DAE7',
    },
    backgroundBasic: {
      default: '#F9F9F9',
      hover: '#F5F5F5',
      active: '#F0F0F0',
      disabled: '#E7E7E7',
    },
    backgroundCover: {
      default: '#FFFFFF',
      hover: '#F9F9F9',
      active: '#F9F9F9',
      disabled: '#FFFFFF',
    },
    backgroundDark: {
      default: '#3F3F3F',
    },
    borderMain: {
      default: '#D2D5D6',
      hover: '#04A5D8',
      active: '#0390BD',
      focused: '#03C3FF',
      disabled: '#E7E7E7',
    },
    borderSeparator: {
      default: '#EDEDED',
    },
    emotionInformative: {
      default: '#1C9CB8',
      hover: '#016A81',
      active: '#148CA6',
      disabled: '#F8F8F8',
      background: '#C0E9FF',
    },
    emotionSuccess: {
      default: '#67B529',
      hover: '#4C851E',
      active: '#5A9E24',
      disabled: '#F8F8F8',
      background: '#EBF4E4',
      backgroundHover: '#DFEDD4',
      backgroundActive: '#D7E9C9',
    },
    emotionWarning: {
      default: '#F4B049',
      hover: '#C07400',
      active: '#EC9E29',
      disabled: '#F8F8F8',
      background: '#FFF1CD',
      backgroundHover: '#FFEDBE',
      backgroundActive: '#FFE8AF',
    },
    emotionDanger: {
      default: '#CC454D',
      hover: '#840404',
      active: '#C22C2C',
      disabled: '#F8F8F8',
      background: '#F9EDF0',
      backgroundHover: '#F5E2E7',
      backgroundActive: '#F3DBE1',
    },
    iconText: {
      primary: '#535353',
      secondary: '#9C9C9C',
      disabled: '#C1C1C1',
    },
    iconAction: {
      primary: '#04B7EF',
    },
    chart: {
      neutral: ['#30D0F2', '#20B3D3', '#1C9CB8', '#148CA6', '#077E98'],
      warning: ['#F9C475', '#F4B049', '#EC9E29', '#E28801'],
      alert: ['#E15E5E', '#CC454D', '#C22C2C', '#B00606'],
    },
  },
  spacing: {
    a: '1px',
    b: '2px',
    c: '4px',
    d: '8px',
    e: '12px',
    f: '16px',
    g: '24px',
    h: '32px',
    i: '40px',
    j: '48px',
    k: '56px',
    l: '64px',
    m: '80px',
  },
  typography: {
    display: {
      large: {
        default: {
          family: 'Quicksand',
          fontWeight: 700,
          fontSize: '64px',
          lineHeight: '120%',
        },
      },
    },
    heading: {
      xLarge: {
        default: {
          family: 'Quicksand',
          fontWeight: 700,
          fontSize: '48px',
          lineHeight: '120%',
        },
      },
      large: {
        default: {
          family: 'Roboto',
          fontWeight: 300,
          fontSize: '34px',
          lineHeight: '120%',
        },
      },
      medium: {
        default: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '22px',
          lineHeight: '120%',
        },
      },
      small: {
        default: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '17px',
          lineHeight: '120%',
        },
      },
    },
    body: {
      xLarge: {
        regular: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '145%',
        },
        bold: {
          family: 'Roboto',
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '145%',
        },
        italic: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '20px',
          lineHeight: '145%',
        },
        link: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '145%',
          letterSpacing: '.05rem',
        },
        button: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '20px',
          lineHeight: '145%',
          letterSpacing: '.05rem',
        },
      },
      large: {
        regular: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '17px',
          lineHeight: '145%',
        },
        bold: {
          family: 'Roboto',
          fontWeight: 700,
          fontSize: '17px',
          lineHeight: '145%',
        },
        italic: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '17px',
          lineHeight: '145%',
        },
        link: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '17px',
          lineHeight: '145%',
          letterSpacing: '.05rem',
        },
        button: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '17px',
          lineHeight: '145%',
          letterSpacing: '.05rem',
        },
      },
      medium: {
        regular: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '145%',
        },
        bold: {
          family: 'Roboto',
          fontWeight: 700,
          fontSize: '15px',
          lineHeight: '145%',
        },
        italic: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '145%',
        },
        link: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '145%',
          letterSpacing: '.05rem',
        },
        button: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '15px',
          lineHeight: '145%',
          letterSpacing: '.05rem',
        },
      },
      small: {
        regular: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '145%',
        },
        bold: {
          family: 'Roboto',
          fontWeight: 700,
          fontSize: '12px',
          lineHeight: '145%',
        },
        italic: {
          family: 'Roboto',
          fontWeight: 400,
          fontSize: '12px',
          lineHeight: '145%',
        },
        link: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '145%',
          letterSpacing: '.05rem',
        },
        button: {
          family: 'Roboto',
          fontWeight: 500,
          fontSize: '12px',
          lineHeight: '145%',
          letterSpacing: '.05rem',
        },
      },
    },
  },
  breakpoint: {
    small: '768px',
    medium: '1024px',
    large: '1440px',
  },
};
