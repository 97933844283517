import { Query } from '@apollo/client/react/components';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { CardBody, CardTitle } from '@arnold/common';
import { Loading, OrganizationImportCard } from '../../components';
import { getLanguages, getOrganizationImportHeadersQuery, getOrganizationStructure } from '../../graphql/queries';
import {
  GetOrganizationImportFileHeadersQuery,
  LanguagesQuery,
  OrganizationQuery,
  OrganizationQueryVariables,
  UserQuery,
} from '../../generated/hooks';
import { useGetOrganizationTeams, useGetSurveyGroups } from '../Surveys/utils';

interface IProps {
  user: UserQuery['user'];
  organization: OrganizationQuery['organization'];
}

const UpdateTeamScreen = (props: IProps) => {
  const [t] = useTranslation('updateTeamsScreen');

  const {
    organizationTeams,
    loading: teamsLoading,
    error: teamsError,
  } = useGetOrganizationTeams(props.organization?.id || '');

  const { surveyGroups, loadingOrError } = useGetSurveyGroups();

  if (props.user && props.organization) {
    return (
      <Query<OrganizationQuery, OrganizationQueryVariables>
        query={getOrganizationStructure}
        variables={{ organizationId: props.organization.id }}
      >
        {({ loading, error, data, refetch }) => {
          if (error || teamsError) {
            return null;
          }
          if (loadingOrError) {
            return loadingOrError;
          }
          if (loading || !data || !data.organization || teamsLoading || !organizationTeams) {
            return <Loading />;
          }
          if (data && data.organization) {
            const { organization } = data;
            return (
              <>
                {props.user && props.organization && (
                  <Query<GetOrganizationImportFileHeadersQuery> query={getOrganizationImportHeadersQuery}>
                    {({ loading, error, data }) => {
                      if (error) {
                        return <></>;
                      }
                      if (loading || !data) {
                        return <Loading />;
                      }
                      if (data) {
                        return (
                          <Query<LanguagesQuery> query={getLanguages}>
                            {({ loading, error, data: languagesData }) => {
                              if (error) {
                                return <></>;
                              }
                              if (loading || !data) {
                                return <Loading />;
                              }
                              if (data) {
                                return (
                                  <OrganizationImportCard
                                    headerKey={'teamImportTeamsPage'}
                                    organization={organization}
                                    organizationTeams={organizationTeams}
                                    surveyGroups={surveyGroups!}
                                    refetch={refetch}
                                    userId={props.user.id}
                                    fileHeaders={data.getOrganizationImportFileHeaders}
                                    languages={languagesData}
                                  />
                                );
                              }
                              return null;
                            }}
                          </Query>
                        );
                      }
                      return null;
                    }}
                  </Query>
                )}
              </>
            );
          }
          return null;
        }}
      </Query>
    );
  }
  return (
    <Card>
      <CardBody>
        <CardTitle>{t('noOrganization')}</CardTitle>
      </CardBody>
    </Card>
  );
};

export default UpdateTeamScreen;
