import { useHistory } from 'react-router-dom';

enum Paths {
  SurveyDetail = 'surveyDetail',
  ArchivedSurveyDetail = 'archivedSurveyDetail',
  Surveys = 'surveys',
  CreateSurvey = 'createSurvey/TOPIC_GROUP',
  SurveyResults = 'results',
  SurveyArchive = 'surveyArchive',
  ReportDetail = 'report',
  CreateSurveyGroup = 'createSurveyGroup',
  CreateOnboarding = 'createOnboarding',
  CreateProcessSurvey = 'createProcessSurvey',
  CreateExit = 'createExit',
  ConversationSubjects = 'conversationSubjects',
  Pricing = 'settings/pricing',
  Overview = 'overview',
  Onboarding = 'onboarding',
  ArchivedOnboarding = 'archivedOnboarding',
  ProcessSurveys = 'processSurveys',
  ProcessSurveyDetail = 'processSurvey',
  ArchivedProcessSurveyDetail = 'archivedProcessSurvey',
  EditSurveyGroup = 'editSurveyGroup',
  OnboardingStepDetail = 'onboarding/stepDetail',
  ProcessSurveyStepDetail = 'processSurvey/stepDetail',
  TopicEditor = 'topicEditor',
  TopicEditorPreview = 'topicEditorPreview',
  Topic = 'topic',
  Process = 'process',
  ReopenSurvey = 'reopenSurvey',
  Exit = 'exit',
  OrderArnold = 'settings/order',
}

const pathCreator = <T extends Array<string | undefined>>(createPath: (...param: T) => string, push) => ({
  toFunc: (...params: T) => push(createPath(...params)),
  createPath,
});

const addOptionalParam = (param?: string): string => (param ? `/${param}` : '');

/**
 * Surveys
 */
const createReopenSurveyPath = (id: string) => `/${Paths.ReopenSurvey}/${id}`;
const createSurveysPath = (status?: string) => `/${Paths.Surveys}${addOptionalParam(status)}`;
const createSurveyDetailPath = (id: string, status?: string) =>
  `/${Paths.SurveyDetail}/${id}${addOptionalParam(status)}`;
const createArchivedSurveyDetailPath = (id: string, status?: string) =>
  `/${Paths.ArchivedSurveyDetail}/${id}${addOptionalParam(status)}`;
const createReportsSettingPath = (id: string) => `/${Paths.SurveyDetail}/${id}/results`;
const crateCreateSurveyPath = (id: string, title: string) => `/${Paths.CreateSurvey}/${id}/${title}`;
const createOnboardingPath = (surveyGroupId?: string, type?: string) =>
  `/${Paths.Onboarding}${addOptionalParam(surveyGroupId)}${addOptionalParam(type)}`;
const createExitPath = (surveyGroupId?: string, type?: string) =>
  `/${Paths.Exit}${addOptionalParam(surveyGroupId)}${addOptionalParam(type)}`;
const createArchivedOnboardingPath = (surveyGroupId?: string, type?: string) =>
  `/${Paths.ArchivedOnboarding}${addOptionalParam(surveyGroupId)}${addOptionalParam(type)}`;
const createSurveyResults = () => `${Paths.SurveyResults}`;
const createProcessSurveysPath = () => `/${Paths.ProcessSurveys}`;
const createProcessSurveyDetailPath = (surveyGroupId: string, type?: string) =>
  `/${Paths.ProcessSurveyDetail}/${surveyGroupId}${addOptionalParam(type)}`;
const createArchivedProcessSurveyDetailPath = (surveyGroupId: string, type?: string) =>
  `/${Paths.ArchivedProcessSurveyDetail}/${surveyGroupId}${addOptionalParam(type)}`;

/**
 * Survey groups
 */
const createCreateSurveyGroupPath = (processTopicGroupId: string) =>
  `/${Paths.CreateSurveyGroup}/${processTopicGroupId}`;
const createCreateOnboardingPath = (processTopicGroupId: string) => `/${Paths.CreateOnboarding}/${processTopicGroupId}`;
const createCreateExitPath = (processTopicGroupId: string) => `/${Paths.CreateExit}/${processTopicGroupId}`;
const createProcessSurveyPath = (processTopicGroupId: string) => `/${Paths.CreateProcessSurvey}/${processTopicGroupId}`;
const createEditSurveyGroupPath = (processTopicGroupId: string) => `/${Paths.EditSurveyGroup}/${processTopicGroupId}`;
const createOnboardingStepDetailPath = (surveyGroupId: string, step: string) =>
  `/${Paths.OnboardingStepDetail}/${surveyGroupId}/${step}`;
const createProcessSurveyStepDetailPath = (surveyGroupId: string, step: string) =>
  `/${Paths.ProcessSurveyStepDetail}/${surveyGroupId}/${step}`;

/**
 * Reports
 */
const createReportDetailPath = (id: string) => `/${Paths.ReportDetail}/${id}`;

/**
 * SurveyArchive
 */
const createSurveyArchivePath = () => `/${Paths.SurveyArchive}`;

/**
 * Process
 */
const createProcessStepPath = (processTopicGroupId: string, stepId: string, languageId?: string) =>
  `/${Paths.Process}/${processTopicGroupId}/step/${stepId}/${languageId}`;
const createProcessStepEditPath = (processTopicGroupId) => `/stepsEdit/${processTopicGroupId}`;
const createAddStepToProcessPath = (processTopicGroupId: string, topicId: string) =>
  `/${Paths.Process}/${processTopicGroupId}/step/add/${topicId}`;
const createProcessDetail = (processTopicGroupId: string) => `/${Paths.Process}/${processTopicGroupId}`;

/**
 * Topics
 */
const createConversationSubjectsPath = (type?: string) => `/${Paths.ConversationSubjects}/${type ? type : ''}`;

/**
 * Settings
 */
const createPricingPath = () => `/${Paths.Pricing}`;
const createOrderArnoldPath = () => `/${Paths.OrderArnold}`;

/**
 * Overview
 */
const createOverviewPath = () => `/${Paths.Overview}`;

/**
 * Topic editor
 */
const createTopicEditorPath = (topicGroupId: string, languageId?: string, topicId?: string) =>
  `/${Paths.TopicEditor}/${topicGroupId}${addOptionalParam(languageId)}` + (topicId ? `?topicId=${topicId}` : '');
const createTopicEditorPreviewPath = (topicGroupId: string, languageId?: string, topicId?: string) =>
  `/${Paths.TopicEditorPreview}/${topicGroupId}${addOptionalParam(languageId)}` +
  (topicId ? `?topicId=${topicId}` : '');

const createTopicPath = (topicId: string, languageId?: string) =>
  `/${Paths.Topic}/${topicId}${addOptionalParam(languageId)}`;

export const usePath = () => {
  const history = useHistory();

  return {
    surveyDetailPath: pathCreator(createSurveyDetailPath, history.push),
    reportsSettingPath: pathCreator(createReportsSettingPath, history.push),
    surveysPath: pathCreator(createSurveysPath, history.push),
    createSurveyPath: pathCreator(crateCreateSurveyPath, history.push),
    reopenSurveyPath: pathCreator(createReopenSurveyPath, history.push),
    createSurveyResultsPath: pathCreator(createSurveyResults, history.push),

    createSurveyGroupPath: pathCreator(createCreateSurveyGroupPath, history.push),
    createOnboardingPath: pathCreator(createCreateOnboardingPath, history.push),
    createProcessSurveyPath: pathCreator(createProcessSurveyPath, history.push),
    createExitPath: pathCreator(createCreateExitPath, history.push),
    editSurveyGroupPath: pathCreator(createEditSurveyGroupPath, history.push),

    reportDetailPath: pathCreator(createReportDetailPath, history.push),

    surveyArchivePath: pathCreator(createSurveyArchivePath, history.push),

    processStepEditPath: pathCreator(createProcessStepEditPath, history.push),
    processStepPath: pathCreator(createProcessStepPath, history.push),
    addStepToProcessPath: pathCreator(createAddStepToProcessPath, history.push),

    conversationSubjectsPath: pathCreator(createConversationSubjectsPath, history.push),

    pricingPath: pathCreator(createPricingPath, history.push),
    overviewPath: pathCreator(createOverviewPath, history.push),
    onboardingPath: pathCreator(createOnboardingPath, history.push),
    exitPath: pathCreator(createExitPath, history.push),
    processSurveysPath: pathCreator(createProcessSurveysPath, history.push),
    processSurveyDetailPath: pathCreator(createProcessSurveyDetailPath, history.push),
    onboardingStepDetail: pathCreator(createOnboardingStepDetailPath, history.push),
    processSurveyStepDetail: pathCreator(createProcessSurveyStepDetailPath, history.push),
    topicEditor: pathCreator(createTopicEditorPath, history.push),
    topicEditorPreview: pathCreator(createTopicEditorPreviewPath, history.push),
    archivedSurveyDetailPath: pathCreator(createArchivedSurveyDetailPath, history.push),
    archivedOnboardingPath: pathCreator(createArchivedOnboardingPath, history.push),
    archivedProcessSurveyDetailPath: pathCreator(createArchivedProcessSurveyDetailPath, history.push),
    topic: pathCreator(createTopicPath, history.push),
    processDetailPath: pathCreator(createProcessDetail, history.push),
    orderArnoldPath: pathCreator(createOrderArnoldPath, history.push),
  };
};
