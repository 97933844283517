import styled from '@emotion/styled/macro';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '@arnold/common';
import { getLocale, getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import { DateInfoProps } from './types';
import { DateTooltip } from './DateTooltip';

const NonBreakableSpan = styled.span`
  white-space: nowrap;
  margin-left: ${theme.spacing.c};
`;

const DateInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DateInfo: FC<DateInfoProps> = ({
  startsAt,
  endsAt,
  isDisabled,
  isInvalid,
  reminders,
  displayOnlyStartDate,
}) => {
  const { i18n } = useTranslation('surveyOverviewRow');
  return (
    <DateTooltip startsAt={startsAt} endsAt={endsAt} reminders={reminders} displayOnlyStartDate={displayOnlyStartDate}>
      <DateInfoContainer>
        <span
          className={'startDate'}
          style={{
            ...(isInvalid
              ? { cursor: isDisabled ? 'auto' : 'pointer', color: '#AF4C62' }
              : isDisabled
                ? { color: 'inherit' }
                : { cursor: 'pointer', color: '#04B7EF' }),
            textAlign: 'right',
          }}
          data-cy="admin-survey-open-calendar"
        >
          {startsAt && getLocalizedDateWithoutLeadingZeroes(new Date(startsAt), i18n.language)}
        </span>
        <span>
          {startsAt && format(new Date(startsAt), 'cccccc', { locale: getLocale(i18n.language) })}
          <>
            <NonBreakableSpan>
              {startsAt && format(new Date(startsAt), 'p', { locale: getLocale(i18n.language) })}
            </NonBreakableSpan>
          </>
        </span>
      </DateInfoContainer>
    </DateTooltip>
  );
};
