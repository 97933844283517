import { Checkbox, Tooltip, theme } from '@arnold/common';
import { Col, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SurveyGroupsQuery } from '../../../generated/hooks';
import DateInput from '../../Common/DateInput';
import {
  StyledFormGroupLabel,
  StyledTable,
  SurveysTableColumn,
  SurveysTableHeader,
  SurveysTableHeaderWithRadio,
} from './SharedComponents';

export type SurveyGroupEntry = { surveyGroupId: string; addedOn: string; checked: boolean };

export type SurveyGroupEntries = { [key: string]: SurveyGroupEntry };

interface Props {
  processSurveys: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  processSurveysToAdd: SurveyGroupEntries;
  setProcessSurveysToAdd: React.Dispatch<React.SetStateAction<SurveyGroupEntries>>;
}

const ProcessSurveySelect: React.FC<Props> = ({ processSurveys, processSurveysToAdd, setProcessSurveysToAdd }) => {
  const { t } = useTranslation(['updateRespondent', 'addRespondent']);

  return (
    <FormGroup as={Col} sm={8} data-cy={'standalone-survey-list-to-add'}>
      <StyledFormGroupLabel>{t('addToProcessSurveys')}</StyledFormGroupLabel>
      <StyledTable>
        <thead>
          <tr>
            <SurveysTableHeaderWithRadio style={{ marginLeft: `${theme.spacing.i}`, width: '40%' }}>
              {t('name')}
            </SurveysTableHeaderWithRadio>
            <SurveysTableHeader style={{ width: '15%' }}>{t('participants')}</SurveysTableHeader>
            <SurveysTableHeader style={{ width: '30%' }}>{t('surveyGroup:startDate')}</SurveysTableHeader>
          </tr>
        </thead>
        <tbody>
          {processSurveys.map((surveyGroup) => {
            const entry = processSurveysToAdd[surveyGroup.id];
            return (
              <tr key={surveyGroup.id} data-cy={'onboarding-row-to-add'}>
                <SurveysTableColumn>
                  <Tooltip title={surveyGroup.topicGroup?.name}>
                    <Checkbox
                      selected={entry.checked}
                      onToggle={() =>
                        setProcessSurveysToAdd({
                          ...processSurveysToAdd,
                          [surveyGroup.id]: { ...entry, checked: !entry.checked },
                        })
                      }
                      text={surveyGroup.topicGroup?.name}
                      labelStyles="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 100%;vertical-align: middle;margin-bottom: 0;"
                    />
                  </Tooltip>
                </SurveysTableColumn>{' '}
                <SurveysTableColumn textAlign={'center'}>
                  {surveyGroup.statistics?.answered}/{surveyGroup.statistics?.total}
                </SurveysTableColumn>
                <SurveysTableColumn data-cy={'added-on-date'}>
                  <DateInput
                    date={entry?.addedOn}
                    onDateChange={(value) => {
                      setProcessSurveysToAdd({
                        ...processSurveysToAdd,
                        [surveyGroup.id]: { ...entry, addedOn: value.toISOString() },
                      });
                    }}
                    inputName={'addedOn'}
                  />
                </SurveysTableColumn>
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </FormGroup>
  );
};

export default ProcessSurveySelect;
