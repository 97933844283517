import { IThemeProps, selectControlStyles, selectMenuStyles, SelectWrapper, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { ReactNode } from 'react';
import { FormGroup } from 'react-bootstrap';

import { useTranslation } from 'react-i18next';

type OptionDaysType = {
  label: ReactNode;
  value: number;
};

type OptionMinutesType = {
  label: string;
  value: number;
};

const FormError = styled.div`
  margin-left: ${theme.spacing.f};
  color: ${theme.colors.emotionDanger.default};
  margin-top: ${theme.spacing.c};
  font-size: 80%;
`;

type DateTimeSelectProps = {
  onDaysChange: (value: number) => void;
  onMinutesChange: (value: number) => void;
  offsetDays: number | null;
  offsetMinutes: number | null;
  optionDays: OptionDaysType[];
  optionMinutes: OptionMinutesType[];
  disabled?: boolean;
  dataCy?: string;
  error: string | null;
};

const dateTimeSelectStyles = {
  menu: (provided: any, state: any) => ({
    ...provided,
    ...selectMenuStyles,
  }),
  container: (styles: any) => ({
    ...styles,
    flex: 1,
    marginRight: theme.spacing.d,
  }),
};

const SelectContainer = styled.div`
  display: flex;
  margin-right: ${({ theme }: IThemeProps) => theme?.spacing.f};
  width: 100%;
`;

const StyledFormGroup = styled(FormGroup)`
  width: 100%;
`;

const filterOption = (option: OptionMinutesType, inputValue: string): boolean =>
  (option.label.toString().match(inputValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')) || []).length > 0;

export const DateTimeSelect = ({
  onDaysChange,
  onMinutesChange,
  offsetDays,
  offsetMinutes,
  optionDays,
  optionMinutes,
  disabled = false,
  error,
  dataCy,
}: DateTimeSelectProps) => {
  const { t } = useTranslation('processDetail');
  const handleDaysChange = (item: OptionDaysType) => {
    onDaysChange(item.value);
  };

  const handleMinutesChange = (item: OptionMinutesType) => {
    onMinutesChange(item.value);
  };

  return (
    <StyledFormGroup>
      <SelectContainer>
        <SelectWrapper
          isSearchable={true}
          options={optionDays}
          dataCy={`date-select-${dataCy}`}
          noOptionsMessage={() => t('noOptions')}
          value={optionDays.find((option) => option.value === offsetDays)}
          styles={{
            ...dateTimeSelectStyles,
            control: (provided: any, state: any) => ({
              ...provided,
              ...selectControlStyles,
              paddingLeft: theme.spacing.f,
            }),
          }}
          onChange={handleDaysChange}
          isDisabled={disabled}
          menuPlacement="auto"
          placeholder=""
          fullWidth
        />
        <SelectWrapper
          isSearchable={true}
          options={optionMinutes}
          noOptionsMessage={() => t('noOptions')}
          dataCy={`time-select-${dataCy}`}
          value={optionMinutes.find((option) => option.value === offsetMinutes)}
          styles={{
            ...dateTimeSelectStyles,
            control: (provided: any, state: any) => ({
              ...provided,
              ...selectControlStyles,
              paddingLeft: theme.spacing.f,
            }),
            container: (provided: any) => ({
              ...provided,
              width: '196px',
            }),
          }}
          onChange={handleMinutesChange}
          isDisabled={disabled}
          filterOption={filterOption}
          placeholder=""
        />
      </SelectContainer>
      {error && <FormError>{error}</FormError>}
    </StyledFormGroup>
  );
};

export default DateTimeSelect;
