import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeadingXLarge, theme } from '@arnold/common';
import { ReactComponent as LogoInBubble } from '@arnold/common/lib/assets/logo/LogoInBubble.svg';
import { ReactComponent as LogoPrimary } from '@arnold/common/lib/assets/logo/LogoPrimaryDarkBkg.svg';

import { HeadingContainer, LogoContainer, RegistrationAsideContent } from '../../components/StyledComponents';

export const LoginAside = () => {
  const { t } = useTranslation('loginForm');
  return (
    <RegistrationAsideContent>
      <LogoInBubble />
      <HeadingContainer>
        <HeadingXLarge color={theme.colors.textInverted.primary} margin={'0'}>
          {t('loginToArnold')}
        </HeadingXLarge>
      </HeadingContainer>
      <LogoContainer>
        <LogoPrimary width="228" height="57" />
      </LogoContainer>
    </RegistrationAsideContent>
  );
};

export default LoginAside;
