import {
  CircleGraph,
  formatDate,
  isDateInValidPeriod,
  LinkButton,
  SurveyCard,
  TextBubble,
  theme,
  Tooltip,
  usePath,
} from '@arnold/common';
import { ReactComponent as Team } from '@arnold/common/src/assets/icons/Team.svg';
import { REPORT_LINK } from '@arnold/core';
import styled from '@emotion/styled';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getCategoryIcon, goToTalkResults } from '../../lib/helpers';
import { PATHS } from '../../screens/Surveys/types';
import { NoSurveysInfo } from '../NoSurveysInfo';
import { getActiveMessages } from '../SurveysOverview/RowParts/SurveyInfo';
import { DashboardQuery, OrganizationQuery, SurveyStatus } from '../../generated/hooks';
import ee from '../../lib/eventEmitter';
import { DashboardSection } from './DashboardSection';
import { getIcon, getLabel, getSubtitle, getTitle, getValue } from './utils';

type ActiveSurveysProps = {
  surveys?: NonNullable<DashboardQuery['user']['organization']>['currentSurveys'];
  enabledRespondentsCount: number;
  organization: NonNullable<OrganizationQuery['organization']>;
  isWithoutSurveys: boolean;
  onResultsClick: () => void;
};

export const ActiveSurveys: FC<ActiveSurveysProps> = ({
  surveys,
  enabledRespondentsCount,
  organization,
  isWithoutSurveys,
  onResultsClick,
}) => {
  const { t } = useTranslation('dashboard');
  const [surveyT] = useTranslation('surveyOverviewRow');
  const { surveyDetailPath, conversationSubjectsPath } = usePath();

  return (
    <DashboardSection
      key={'activeSurveys'}
      keyName={'active-surveys'}
      title={t('activeSurveys')}
      dataCy="dashboard-active-surveys"
      buttons={
        <div>
          <Button
            onClick={() => conversationSubjectsPath.toFunc()}
            data-icom="btn-create-new-survey"
            data-cy="btn-create-new-survey"
          >
            {t('newSurvey')}
          </Button>
        </div>
      }
    >
      {surveys?.length === 0 && (
        <NoSurveysInfo
          status={PATHS.PREPARED}
          isWithoutSurveys={isWithoutSurveys}
          dataCy={isWithoutSurveys ? 'admin-before-survey' : undefined}
        />
      )}
      {surveys?.map((survey) => {
        const graph = (
          <CircleGraph
            value={getValue(survey!)}
            title={getTitle(survey!, t)}
            dataCy="survey-start-date"
            valueSubTitle={getSubtitle(survey, t)}
            backgroundColor={
              survey.status === SurveyStatus.Notstarted ? theme.colors.emotionWarning.background : undefined
            }
            icon={getIcon(survey)}
            hideValueInGraph={!!survey.public}
          />
        );
        const reportAccess = survey.topLevelReport;
        const inLicenceInterval = isDateInValidPeriod(new Date(survey.sendAt), organization.productVersionHistory!);
        const notStartedReasonMessage = getActiveMessages(
          {
            isSequential: false,
            notstartedReason: [survey.notstartedReason!],
            inLicenceInterval,
            organization,
          },
          surveyT,
        );
        return (
          <SurveyCard
            dataIcom={'active-survey-card'}
            key={survey.id}
            header={survey.topic?.name || survey.topicGroup?.name || ''}
            headerIcon={getCategoryIcon(
              !survey.topicGroup?.categoryCode,
              survey.topicGroup?.categoryCode,
              survey.public ?? undefined,
            )}
            upperTitle={
              <TextBubble
                color={
                  [SurveyStatus.Ongoing, SurveyStatus.Notstarted].includes(survey.status)
                    ? undefined
                    : theme.colors.emotionInformative.hover
                }
                backgroundColor={
                  [SurveyStatus.Ongoing, SurveyStatus.Notstarted].includes(survey.status)
                    ? undefined
                    : theme.colors.emotionInformative.background
                }
                label={getLabel(survey, t)}
              />
            }
            graph={
              survey.status === SurveyStatus.Notstarted ? (
                <Tooltip title={notStartedReasonMessage.join('<hr />')}>{graph}</Tooltip>
              ) : (
                graph
              )
            }
            lowerTitle={
              <TextBubble
                padding={`6px ${theme.spacing.d}`}
                label={
                  survey.public ? (
                    t('startAtDate', { date: formatDate(survey.sendAt) })
                  ) : survey.status === SurveyStatus.Ongoing ? (
                    `${formatDate(survey.sendAt)} - ${survey.endsAt ? formatDate(survey.endsAt) : '-'}`
                  ) : (
                    <Participants>
                      <Team />
                      {survey.whitelistedRespondentsLength ? '' : `${t('wholeOrganization')} | `}
                      {t('participants', {
                        count: survey.whitelistedRespondentsLength
                          ? survey.whitelistedRespondentsLength
                          : enabledRespondentsCount,
                        postProcess: 'interval',
                      })}
                    </Participants>
                  )
                }
              />
            }
            lowerTitleMobile={
              survey.status === SurveyStatus.Ongoing && !survey.public ? (
                <div>
                  <span
                    style={{
                      fontSize: theme.typography.body.small.regular.fontSize,
                      lineHeight: '120%',
                      color: theme.colors.text.secondary,
                    }}
                  >
                    {t('responseRate')}
                  </span>
                  <strong
                    style={{
                      padding: `0 ${theme.spacing.d}`,
                      fontSize: theme.typography.heading.small.default.fontSize,
                      lineHeight: '140%',
                    }}
                  >{`${getValue(survey)}`}</strong>
                  <span style={{ fontSize: theme.typography.body.small.regular.fontSize, lineHeight: '200%' }}>
                    {getSubtitle(survey, t)}
                  </span>
                </div>
              ) : (
                <span>
                  {t('participants', {
                    count: survey.public
                      ? survey.resultsInfo?.answered
                      : survey.whitelistedRespondentsLength
                        ? survey.whitelistedRespondentsLength
                        : enabledRespondentsCount,
                    postProcess: 'interval',
                  })}
                </span>
              )
            }
            footer={
              <Footer>
                {survey.public && survey.accessKey && (
                  <LinkButton
                    onClick={() => {
                      ee.emitModalSharePublicSurvey(survey.accessKey!, survey.topic?.name || '');
                    }}
                    data-icom={'btn-public-survey-share'}
                  >
                    {t('share')}
                  </LinkButton>
                )}
                {survey.status === SurveyStatus.Ongoing && reportAccess && (
                  <LinkButton
                    onClick={() => {
                      goToTalkResults(REPORT_LINK.REPORT, reportAccess.accessKey);
                      onResultsClick();
                    }}
                    data-icom={'btn-active-survey-card-results'}
                  >
                    {t('results')}
                  </LinkButton>
                )}
                <LinkButton onClick={() => surveyDetailPath.toFunc(survey.id)} data-cy="btn-survey-detail">
                  {t('detail')}
                </LinkButton>
              </Footer>
            }
          />
        );
      })}
    </DashboardSection>
  );
};

const Footer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
`;

const Participants = styled.span`
  svg {
    padding-right: ${theme.spacing.c};
    > path {
      fill: ${theme.colors.actionPrimary.default};
    }
  }
`;
