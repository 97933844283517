import { cleanArray, changeI18nLanguage, updateIntercomUser, pushToDataLayer, createEventData } from '@arnold/common';
import { getLocale } from '@arnold/core';
import { format } from 'date-fns';
import { DocumentNode, getOperationAST } from 'graphql';
import { TFunction } from 'react-i18next';
import {
  ContactSource,
  ContactType,
  OrganizationTeamsQuery,
  SurveyQuery,
  UpdateRespondentMutation,
} from '../generated/hooks';
import { USER_KEY } from './auth';

export default function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export const allowedLanguages = Object.freeze(['en', 'cs', 'sk']);

export const handleLanguageChange = (code: string) => {
  changeI18nLanguage(code);
  updateIntercomUser({
    language: code,
    language_override: code,
    user_hash: getUserHash(),
  });
};

export const getRawContact = (
  member:
    | NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'][0]
    | NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['leader']
    | UpdateRespondentMutation['updateRespondent'],
  contactType: ContactType,
  contactSource?: ContactSource,
): NonNullable<NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'][0]['contacts']>[0] | null => {
  if (member?.contacts != null) {
    const contact = member.contacts.find(
      (contact) => contact.type === contactType && (!contactSource || contact.source === contactSource),
    );
    if (contact) {
      return contact as NonNullable<
        NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'][0]['contacts']
      >[0];
    }
  }
  return null;
};

export const getContact = (
  member:
    | NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'][0]
    | NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['leader']
    | UpdateRespondentMutation['updateRespondent'],
  contactType: ContactType,
  contactSource?: ContactSource,
) => {
  const contact = getRawContact(member, contactType, contactSource);
  return contact ? contact.value : '';
};

type MemberOrLeader =
  | NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'][0]
  | NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['leader'];
type ParamsWithoutTeamName = [respondent: MemberOrLeader];
type ParamsWithTeamName = [respondent: MemberOrLeader, teamName: string, t: TFunction<'updateRespondent', undefined>];

export const getTopicGroupTranslationByLanguageId = <T extends { language: { id: string } }>(
  languageId: string,
  translations?: T[] | null,
) => translations?.find((t) => t.language.id === languageId);
export const getRespondentNameLabel = (...params: ParamsWithTeamName | ParamsWithoutTeamName) => {
  const [respondent, teamName, t] = params;
  let name = `${respondent?.surname} ${respondent?.firstname} (${getContact(
    respondent!,
    ContactType.Email,
    ContactSource.Primary,
  )})`;
  if (teamName) {
    name += `, ${t?.('team')}: ${teamName}`;
  }
  return name;
};

export const getOperationName = (document: DocumentNode) => {
  const result = getOperationAST(document);
  if (result && result.name) {
    return result.name.value;
  }
};

export const getUserHash = () => {
  const user = localStorage.getItem(USER_KEY);
  const userHash = user != null ? JSON.parse(user).intercomHash : undefined;
  return userHash;
};

type Language = { code: string; id: string };
export const sortLanguages = (languages: Language[], languageTranslations: Map<string, string>): Language[] => {
  const priorLanguages = cleanArray([
    languages.find((l) => l.code === 'cs'),
    languages.find((l) => l.code === 'en'),
    languages.find((l) => l.code === 'sk'),
  ] as Language[]);

  const languagesSorted = languages.filter((l) => !['cs', 'en', 'sk'].includes(l.code));
  languagesSorted.sort((l1, l2) =>
    (languageTranslations.get(l1.code) || '').localeCompare(languageTranslations.get(l2.code) || ''),
  );

  return [...priorLanguages, ...languagesSorted];
};

export const EDITION = {
  FREE: {
    name: 'FREE',
    licenceCount: 15,
  },
  ESSENTIAL: {
    name: 'ESSENTIAL',
    licenceCount: 30,
  },
  BUSINESS: {
    name: 'BUSINESS',
    licenceCount: 50,
  },
  ENTERPRISE: {
    name: 'ENTERPRISE',
    licenceCount: null,
  },
  TEAMIO_ARNOLD: {
    name: 'TEAMIO_ARNOLD',
    licenceCount: null,
  },
};

export const sortRespondents = (respondents: NonNullable<SurveyQuery['survey']>['whitelistedRespondents']) => {
  return respondents.sort((a, b) => {
    return (a.surname || '').localeCompare(b.surname || '');
  });
};

export const formatFullDate = (date: string, language: string, short: boolean = true): string =>
  format(new Date(date), short ? 'PPp' : 'PPPPp', { locale: getLocale(language) });

export const handleSummaryResultsClick = (userId: string, orgId: string) => {
  pushToDataLayer({
    userId,
    orgId,
    event: 'ux.open-summary-results-from-admin',
    ...createEventData('survey', 'openSummaryResultsFromAdmin', 'survey open summary results from admin'),
  });
};
