import { ReactComponent as RoutingSelect } from '@arnold/common/lib/assets/icons/Routing-select.svg';
import { useTranslation } from 'react-i18next';
import { selectMenuStyles, SelectWrapper } from '@arnold/common';
import { uniqueId } from '../../lib/helpers';
import { TopicDetailQuery } from '../../generated/hooks';
import { FormIcon } from './FormComponents';
import { QuestionDetail, QuestionSubsections } from './topicStructure';
import { QUESTION_DEFINITION_TYPE } from './types';

type SelectRoutingProps = {
  sectionOrder: number;
  sectionIndexMapping: { [key: number]: number };
  rule?: TopicDetailQuery['topicDetail']['allQuestions'][0]['rules'][0];
  question: QuestionDetail;
  updateQuestion: (question: QuestionDetail, parentQuestionId?: string) => void;
  parentQuestionId?: string;
  subsections?: QuestionSubsections;
  disabled?: boolean;
  isInNewSection: boolean;
  showEndOfChat?: boolean;
  isForRest?: boolean;
};

const SelectRouting = ({
  sectionOrder,
  rule,
  question,
  sectionIndexMapping,
  updateQuestion,
  parentQuestionId,
  subsections,
  disabled,
  isInNewSection,
  showEndOfChat,
  isForRest,
}: SelectRoutingProps) => {
  const [t] = useTranslation('topicOverview');
  const endOfChatOption = {
    label: t('endOfChat'),
    value: '',
  };
  const getSelectDefaultValue = () => {
    if (rule) {
      return rule.nextQuestion.index;
    }

    if ((parentQuestionId || disabled) && sectionIndexMapping[sectionOrder + 1]) {
      return sectionIndexMapping[sectionOrder + 1];
    }

    return null;
  };

  const nextQuestionIndex = getSelectDefaultValue();

  const handleRoutingChange = (option: { value: any; label: any }) => {
    const newValue = option.value;

    const newRules = rule
      ? newValue === ''
        ? question.rules.filter((r) => r.id !== rule.id)
        : question.rules.map((r) => {
            if (r.id === rule.id) {
              return {
                ...rule,
                nextQuestion: {
                  index: Number.parseInt(newValue, 10) || null,
                  __typename: QUESTION_DEFINITION_TYPE.tell,
                  id: uniqueId(),
                },
              };
            }

            return r;
          })
      : question.rules.slice();

    if (!rule && newValue) {
      newRules.push({
        id: uniqueId(),
        nextQuestion: {
          index: Number.parseInt(newValue, 10) || null,
          __typename: QUESTION_DEFINITION_TYPE.tell,
          id: uniqueId(),
        },
        operator: null,
        order: newRules.length,
        origValue: '',
        __typename: 'RangeRule',
        isFromDefault: null,
      });
    }

    updateQuestion(
      {
        ...question,
        rules: newRules,
      },
      parentQuestionId,
    );

    Object.values(subsections || {}).forEach((subsection) => {
      const subQuestion = subsection.flat()[subsection.flat().length - 1];
      if (subQuestion && subQuestion.rules[0].isFromDefault) {
        updateQuestion({
          ...subQuestion,
          rules: [
            {
              id: uniqueId(),
              nextQuestion: {
                index: Number.parseInt(newValue, 10) || null,
                __typename: QUESTION_DEFINITION_TYPE.tell,
                id: uniqueId(),
              },
              operator: null,
              order: 1,
              origValue: '',
              __typename: 'RangeRule',
              isFromDefault: true,
            },
          ],
        });
      }
    });
  };

  const selectMapping = Object.keys(sectionIndexMapping).reduce(
    (acc, key) => {
      return Number.parseInt(key, 10) === sectionOrder && !isInNewSection
        ? acc
        : {
            ...acc,
            [key]: sectionIndexMapping[Number.parseInt(key, 10)],
          };
    },
    {} as { [key: number]: number | '' },
  );
  const options = Object.keys(selectMapping)
    .filter(
      (key) =>
        sectionOrder + 1 >= Object.keys(sectionIndexMapping).length ||
        selectMapping[Number.parseInt(key, 10)] !== sectionIndexMapping[sectionOrder + 1],
    )
    .map((key) => {
      return {
        value: selectMapping[Number.parseInt(key, 10)],
        label: isForRest
          ? `${t('selectRouteToNumber')} ${Number.parseInt(key, 10) + 1}`
          : `${t('routeWithoutAnonymity')} ${Number.parseInt(key, 10) + 1}`,
      };
    });

  if (sectionOrder + 1 < Object.keys(sectionIndexMapping).length) {
    options.unshift({
      value: sectionIndexMapping[sectionOrder + 1],
      label: isForRest ? t('selectRouteToNext') : t('routeToNext'),
    });
  }

  if (showEndOfChat) {
    options.push({
      label: t('endOfChat'),
      value: '',
    });
  }

  return (
    <div
      style={{
        display: 'flex',
        minWidth: '100%',
        marginBottom: '16px',
        marginRight: '16px',
        marginTop: '6px',
      }}
    >
      <FormIcon>
        <RoutingSelect />
      </FormIcon>
      <div style={{ width: '100%' }}>
        <SelectWrapper
          options={options}
          onChange={handleRoutingChange}
          value={options.find((o) => o.value === nextQuestionIndex)}
          disabled={disabled}
          menuPlacement="auto"
          styles={{
            menu: (provided: any, state: any) => ({
              ...provided,
              ...selectMenuStyles,
            }),
          }}
          defaultValue={endOfChatOption}
        />
      </div>
    </div>
  );
};

export default SelectRouting;
