import { BodyMedium, HeadingLarge, HeadingMedium, formatDate, theme } from '@arnold/common';
import { ProductCode } from '@arnold/core';
import styled from '@emotion/styled';
import { FunctionComponent, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { GraphQLErrorCard, Loading } from '../../components';
import { PageWrapper, PublicNavbar } from '../../components/Common';
import TeamioRespondentForm from '../../components/Forms/RespondentForm/TeamioRespondentForm';
import {
  IsAccessTokenValidQuery,
  OrganizationQuery,
  ProcessStatus,
  TopicGroupTypeCode,
  useCreateRespondentMutation,
} from '../../generated/hooks';
import { EDITION } from '../../lib/common';
import i18n from '../../translations/i18n';
import { useGetOrganizationTeams, useGetSurveyGroups } from '../Surveys/utils';

const CustomHeadingLarge = styled(HeadingLarge)`
  display: flex;
  max-width: calc(100vw - 2rem);
  margin: 1rem 0;
  justify-content: space-between;
  align-items: center;

  & img {
    width: 250px;
  }
`;

const CustomCard = styled(Card)`
  width: 925px;
  max-width: calc(100vw - 2rem);
  margin: 1rem;
  padding-top: 0;
`;

const GraphicWrapper = styled(CustomCard)`
  margin-top: ${theme.spacing.g};
  text-align: center;

  & img {
    width: 250px;
    margin: auto;
    margin-top: 88px;
  }
`;

// Generate component with props
interface IOrderButtons {
  orderUrl: string;
  redirectTo?: string;
}

const OrderButtons: FunctionComponent<IOrderButtons> = ({ orderUrl, redirectTo }) => {
  const { t } = useTranslation(['teamioOnb']);
  const history = useHistory();
  return (
    <div className="mt-7 ml-10">
      <Button
        variant="primary"
        onClick={() => {
          history.push(orderUrl);
        }}
        type="button"
      >
        {t('teamioOnb:upgradePlan')}
      </Button>
      <Button
        variant="outline-primary"
        className={'mb-5'}
        onClick={() => {
          if (redirectTo) {
            window.location.href = redirectTo;
          }
        }}
        type="button"
      >
        {t('addRespondent:goBackToTeamio')}
      </Button>
    </div>
  );
};

interface IProps extends RouteComponentProps<any> {
  user: IsAccessTokenValidQuery['isAccessTokenValid']['user'];
  organization: OrganizationQuery['organization'];
}

const AddRespondentFromTeamioScreen = ({ user, organization }: IProps) => {
  const { t } = useTranslation(['addRespondent']);
  const id = organization && organization.id;
  const secondsRef = useRef(5);
  const [, setForceRender] = useState(secondsRef.current);

  const urlParams = new URLSearchParams(window.location.search);
  const hireDate = urlParams.get('hireDate');
  const valuesFromUrl = {
    email: urlParams.get('email') ?? '',
    firstName: urlParams.get('firstName') ?? '',
    surname: urlParams.get('surname') ?? '',
    phoneNumber: urlParams.get('phoneNumber') ?? '',
    candidateId: urlParams.get('candidateId') ?? '',
    redirectTo: urlParams.get('redirectTo') ?? undefined,
    hireDate: hireDate ?? undefined,
  };

  const {
    organizationTeams,
    loading: teamsLoading,
    error: teamsError,
  } = useGetOrganizationTeams(organization?.id || '');

  const { surveyGroups, loading: sgLoading, error: sgError } = useGetSurveyGroups();

  const [createRespondentMutation, { data, loading, error }] = useCreateRespondentMutation({
    onCompleted: () => {
      setInterval(() => {
        if (secondsRef.current) {
          secondsRef.current = secondsRef.current - 1;
          setForceRender(secondsRef.current);
        } else if (valuesFromUrl.redirectTo) {
          window.location.href = valuesFromUrl.redirectTo;
        }
      }, 1000);
    },
  });

  if (id === null || teamsError || sgError) {
    return <GraphQLErrorCard />;
  }
  if (!organization || teamsLoading || sgLoading) {
    return <Loading />;
  }
  const validPeriod = organization?.productVersion;
  const numberOfUsedParticipants = organization.activeRespondentsInCurrentPeriodCount ?? 0;
  const numberOfMaxParticipants = validPeriod?.licenceCount ?? 0;
  const orderUrl = `/order/${
    i18n.language
  }?withTeamioArnoldOnboarding=true&withFullLayout=true&edition=${encodeURIComponent(EDITION.TEAMIO_ARNOLD.name)}`;

  if (validPeriod?.product === ProductCode.Free) {
    const previousPaidLicence = organization.productVersionHistory?.find((pv) => pv.product !== ProductCode.Free);
    return (
      <>
        <PublicNavbar />
        <PageWrapper data-cy="admin-page-content">
          <CustomHeadingLarge>{t('addEmployeeTeamio')}</CustomHeadingLarge>
          <CustomCard>
            <HeadingMedium className="mt-8">{t('teamioOnb:licenceExpiredHeading')}</HeadingMedium>
            <BodyMedium margin={`${theme.spacing.e} 0`}>
              <Trans
                i18nKey={`teamioOnb:licenceExpiredDescription`}
                values={{ date: previousPaidLicence ? formatDate(previousPaidLicence.validTo) : '' }}
                components={[<strong />]}
              />
            </BodyMedium>
            <BodyMedium margin={`${theme.spacing.e} 0`}>{t('teamioOnb:exceededContactInfo')}</BodyMedium>
          </CustomCard>
          <OrderButtons orderUrl={orderUrl} redirectTo={valuesFromUrl.redirectTo} />
        </PageWrapper>
      </>
    );
  }
  if (numberOfUsedParticipants >= numberOfMaxParticipants && validPeriod?.product === ProductCode.Teamio_Arnold) {
    return (
      <>
        <PublicNavbar />
        <PageWrapper data-cy="admin-page-content">
          <CustomHeadingLarge>{t('addEmployeeTeamio')}</CustomHeadingLarge>
          <CustomCard>
            <HeadingMedium className="mt-8">{t('teamioOnb:exceededHeading')}</HeadingMedium>
            <BodyMedium margin={`${theme.spacing.e} 0`}>{t('teamioOnb:exceededDescription')}</BodyMedium>
            <BodyMedium>
              {t('teamioOnb:numberOfMax')} <strong>{numberOfMaxParticipants}</strong>
            </BodyMedium>
            <BodyMedium>
              {t('teamioOnb:numberOfUsed')} <strong>{numberOfUsedParticipants}</strong>
            </BodyMedium>
            <BodyMedium margin={`${theme.spacing.e} 0`}>{t('teamioOnb:exceededContactInfo')}</BodyMedium>
          </CustomCard>
          <OrderButtons orderUrl={orderUrl} redirectTo={valuesFromUrl.redirectTo} />
        </PageWrapper>
      </>
    );
  }
  if (data) {
    return (
      <>
        <PublicNavbar />
        <PageWrapper data-cy="admin-page-content">
          <GraphicWrapper>
            <img src="/images/Arnold_celebrating.png" alt="Arnold celebrating" />

            <HeadingMedium className="mt-8">{t('addRespondent:sucessHeading')}</HeadingMedium>
            <BodyMedium>
              {t('addRespondent:redirectCountdown', {
                count: secondsRef.current,
                postProcess: 'interval',
              })}
            </BodyMedium>

            <div className="mt-7">
              <Button
                variant="outline-primary"
                className={'mb-5'}
                onClick={() => {
                  if (valuesFromUrl.redirectTo) {
                    window.location.href = valuesFromUrl.redirectTo;
                  }
                }}
                type="button"
              >
                {t('addRespondent:goBackToTeamio')}
              </Button>
            </div>
            <hr className="mt-7 mb-7" />
            <BodyMedium>{t('addRespondent:sucessInfo1')}</BodyMedium>
            <BodyMedium className="mt-4 mb-9">{t('addRespondent:sucessInfo2')}</BodyMedium>
          </GraphicWrapper>
        </PageWrapper>
      </>
    );
  }
  return (
    <>
      <PublicNavbar />
      <PageWrapper data-cy="admin-page-content">
        <CustomHeadingLarge>{t('addEmployeeTeamio')}</CustomHeadingLarge>

        <BodyMedium margin={`0 0 ${theme.spacing.g} 0`}>
          <Trans
            i18nKey={`teamioOnb:usageInfo`}
            values={{ total: numberOfMaxParticipants, used: numberOfUsedParticipants }}
            components={[<strong />]}
          />{' '}
          {numberOfMaxParticipants - numberOfUsedParticipants <= 5 && (
            <Trans i18nKey={`teamioOnb:orderMore`} components={[<a href={orderUrl} />]} />
          )}
        </BodyMedium>
        {loading && <Loading />}
        {error && <GraphQLErrorCard />}
        {!loading && !error && (
          <TeamioRespondentForm
            workEmail={valuesFromUrl.email}
            phone={valuesFromUrl.phoneNumber}
            candidateId={valuesFromUrl.candidateId}
            firstName={valuesFromUrl.firstName}
            hireDate={valuesFromUrl.hireDate}
            redirectTo={valuesFromUrl.redirectTo}
            surname={valuesFromUrl.surname}
            organization={organization}
            organizationTeams={organizationTeams || []}
            saveRespondent={createRespondentMutation}
            countryCallingCode={'420'}
            onboardings={surveyGroups!.filter(
              (sg) =>
                sg.topicGroup.typeCode === TopicGroupTypeCode.Onboarding &&
                [ProcessStatus.Completed, ProcessStatus.Ongoing, ProcessStatus.Prepared].includes(sg.status),
            )}
          />
        )}
      </PageWrapper>
    </>
  );
};

export default AddRespondentFromTeamioScreen;
