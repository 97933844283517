import { FormControl, FormGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { LanguageSelector } from '@arnold/common';
import { FormErrorFeedback, FormGroupLabel } from '../../Common';

export type CommonProps = {
  name?: string;
  isNameInvalid: boolean;
  setName: (n: string) => void;
  description: string;
  setDescription: (d: string) => void;
  type: FormType;
};

export type FormType = 'survey' | 'onboarding' | 'processSurvey';

type ConditionalProps =
  | {
      languages?: never;
      language?: never;
      setLanguage?: never;
    }
  | {
      languages: string[];
      language: string;
      setLanguage: (lang: string) => void;
    };

type Props = CommonProps & ConditionalProps;

export type FormValues = {
  name: string;
  description: string;
};

export const NewCustomTopicForm = ({
  name,
  setName,
  description,
  setDescription,
  isNameInvalid,
  language,
  languages,
  setLanguage,
  type,
}: Props) => {
  const [t] = useTranslation('createNewTopic');
  return (
    <>
      <FormGroup>
        <FormGroupLabel>{t('nameInput')}</FormGroupLabel>
        <FormControl
          type="text"
          name={'name'}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          isInvalid={isNameInvalid}
        />
        {isNameInvalid && <FormErrorFeedback error={t('nameTooShort')} />}
      </FormGroup>
      <FormGroup>
        <FormGroupLabel>{t('descriptionInput')}</FormGroupLabel>
        <FormControl
          type="text"
          as="textarea"
          name={'description'}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
      </FormGroup>
      {type !== 'survey' && (
        <FormGroup>
          <FormGroupLabel>{t('languageInput')}</FormGroupLabel>
          <LanguageSelector
            lang={language}
            allowedLanguages={languages}
            onChange={setLanguage}
            displayAlways
            maxWidth={250}
            showBorder
          />
        </FormGroup>
      )}
    </>
  );
};
