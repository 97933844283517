import { Checkbox } from '@arnold/common';
import * as React from 'react';
import { ClassicPreset } from 'rete';
import { Drag } from 'rete-react-plugin';

export class CheckboxControlData extends ClassicPreset.Control {
  constructor(
    public initialValue: boolean,
    public label: string,
    public change: (value: boolean) => void,
    public readOnly = false,
  ) {
    super();
  }
  initialValueChangedIndicator?: number;
}

export const CheckboxControl = (props: { data: CheckboxControlData }) => {
  const [value, setValue] = React.useState(props.data.initialValue);
  const ref = React.useRef(null);

  Drag.useNoDrag(ref);

  React.useEffect(() => {
    setValue(props.data.initialValue);
  }, [props.data.initialValue, props.data.initialValueChangedIndicator]);

  return (
    <label
      className="checkboxAndLabel"
      ref={ref}
      data-icom={'checkbox-is-required-ariel'}
      data-cy="checkbox-is-required-ariel"
    >
      <Checkbox
        selected={value}
        disabled={props.data.readOnly}
        onToggle={() => {
          setValue(!value);
          props.data.change(!value);
        }}
        text={<span className="checkboxLabel">{props.data.label} </span>}
      />
    </label>
  );
};
