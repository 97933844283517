import { FC } from 'react';
import { getCategoryIcon } from '../../../../lib/helpers';

type CategoryIconProps = {
  categoryCode?: string | null;
  custom: boolean;
};

export const CategoryIcon: FC<CategoryIconProps> = ({ categoryCode, custom }) => (
  <img src={getCategoryIcon(custom, categoryCode)} alt={categoryCode + ' category icon'} />
);
