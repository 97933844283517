import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { theme } from '../../theme';
import UpChevron from '../../assets/icons/Up-Chevron.svg';
import DownChevron from '../../assets/icons/Down-Chevron.svg';
import { TableBodyCell } from './TableBodyCell';

type Props = {
  children: ReactNode;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
};

const ExpandedStyledTd = styled(TableBodyCell)`
  cursor: pointer;
  color: ${theme.colors.iconAction.primary};

  &:hover {
    color: ${theme.colors.actionPrimary.hover};

    img {
      filter: brightness(0) saturate(100%) invert(64%) sepia(82%) saturate(3165%) hue-rotate(155deg) brightness(87%)
        contrast(97%);
    }
  }
`;

const StyledImg = styled.img`
  padding-left: ${theme.spacing.d};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableBodyCellExpandable: FC<Props> = ({ children, isExpanded, setIsExpanded }) => {
  return (
    <ExpandedStyledTd onClick={() => setIsExpanded(!isExpanded)}>
      <Content>
        {children}
        <StyledImg src={isExpanded ? UpChevron : DownChevron} />
      </Content>
    </ExpandedStyledTd>
  );
};
