export const ENVIRONMENT = {
  LOCAL: 'local',
  DEV: 'dev27',
  DEVEL: 'devel6',
  DEPLOY: 'deploy',
  PROD: 'prod',
};

export const REPORT_LINK = {
  REPORT: 'report',
  REPORT_SEQUENCE: 'report-sequence',
  RESULTS: 'results',
  PROCESS_RESULTS: 'process-results',
};

export enum RespondentOriginator {
  IMPORT = 'IMPORT',
  AUTOGENERATED = 'AUTOGENERATED',
  ADMINISTRATOR = 'ADMINISTRATOR',
  TEAMIO = 'TEAMIO',
  AUTOMATIC_IMPORT = 'AUTOMATIC_IMPORT',
}

export enum ProductCode {
  Free = 'FREE',
  Business = 'BUSINESS',
  Essential = 'ESSENTIAL',
  Enterprise = 'ENTERPRISE',
  Preview = 'PREVIEW',
  Teamio_Arnold = 'TEAMIO_ARNOLD',
}
