import { ReactElement, ReactNode, useEffect, useState } from 'react';
import SweetModal from 'react-bootstrap-sweetalert';
import { Trans, useTranslation } from 'react-i18next';
import { SurveyGroupRespondentsQuery, SurveyGroupsQuery, SurveysQuery } from '../../generated/hooks';
import { ModalType } from '../../lib/enums';
import ee from '../../lib/eventEmitter';
import { ImportStatistic } from '../../lib/types';
import { QuestionDetail } from '../../screens/Topic/topicStructure';
import Loading from '../Loading';
import { RespondentOnboarding, RespondentOneTimeSurveys, RespondentSurveyGroups } from '../OrganizationImportCard';
import AccessTokenValidation, { ACCESS_TOKEN_ERROR } from './AccessTokenValidation';
import { AddSGRespondentToOngoingSurveys, SurveyGroupSurveysData } from './AddSGRespondentToOngoingSurveys';
import ArielHints from './ArielHints';
import BaseModal from './BaseModal';
import ChoiceSettings from './ChoiceSettings';
import EndPublicSurveyModal from './EndPublicSurveyModal';
import { NewTeam } from './NewTeam/NewTeam';
import OrganizationImport from './OrganizationImport/OrganizationImport';
import QuestionSettings from './QuestionSettings';
import ShareChannelLinkModal from './ShareChannelLinkModal';
import ShareLinks from './ShareLinks';
import SharePublicSurveyModal from './SharePublicSurveyModal';
import { UpdateRespondent } from './UpdateRespondent';

interface IProps {
  children: ReactElement;
}

function Modal(props: IProps) {
  const [modal, setModal] = useState(<></>);
  const [secondModal, setSecondModal] = useState<ReactNode | null>(null);
  const [subscribed, setSubscribed] = useState(false);
  const [t] = useTranslation(['modal', 'discardChangesModal']);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!subscribed) {
      ee.subscribeModal(Modal);
      ee.subscribeModalOrgImport(ModalOrgImport);
      ee.subscribeModalImportDiscardSettings(ModalImportDiscardSetting);
      ee.subscribeModalLoading(onModalLoading);
      ee.subscribeModalDiscardChanges(ModalDiscardChanges);
      ee.subscribeModalLoadingDismiss(onModalLoadingDismiss);
      ee.subscribeModalDeleteQuestion(ModalDeleteQuestion);
      ee.subscribeModalDeleteRespondent(ModalDeleteRespondent);
      ee.subscribeModalChoiceSettings(ModalChoiceSettings);
      ee.subscribeModalQuestionSettings(ModalQuestionSettings);
      ee.subscribeModalAccessTokenValidation(ModalAccessTokenValidation);
      ee.subscribeModalOtpAlreadySent(ModalOtpAlreadySent);
      ee.subscribeModalSgAlreadyExists(ModalSgAlreadyExists);
      ee.subscribeArielHintsModal(ModalArielHints);
      ee.subscribeShareLinksModal(ModalShareLinks);
      ee.subscribeNewTeamModal(ModalNewTeam);
      ee.subscribeUpdateRespondentModal(ModalUpdateRespondent);
      ee.subscribeDeleteTopicGroupModal(ModalDeleteTopicGroup);
      ee.subscribeDeleteProcessStepModal(ModalDeleteProcessStep);
      ee.subscribeModalSharePublicSurvey(ModalSharePublicSurvey);
      ee.subscribeModalShareChannelLink(ModalShareChannelLink);
      ee.subscribeModalEndPublicSurvey(ModalEndPublicSurvey);
      ee.subscribeModalAddSGRespondentToOngoingSurvey(ModalAddSGRespondentsToOngoingSurveys);
      ee.subscribeModalDeleteSurveyGroup(ModalDeleteSurveyGroup);
      setSubscribed(true);
    }
  });
  const onModalLoading = () => {
    setModal(<Loading />);
  };

  const onModalLoadingDismiss = () => {
    setModal(<></>);
  };

  const onModalConfirm = (callback?: () => void) => () => {
    setModal(<></>);
    if (callback) {
      callback();
    }
  };

  const onSecondModalConfirm = (callback?: () => void) => () => {
    setSecondModal(<></>);
    if (callback) {
      callback();
    }
  };

  const ModalDiscardChanges = (discard: () => void, cancel?: () => void) => {
    setModal(
      <BaseModal
        title={t('discardChangesModal:Title')}
        description={t('discardChangesModal:Description')}
        primaryActionText={t('discardChangesModal:Discard')}
        onPrimaryAction={onModalConfirm(discard)}
        secondaryActionText={t('discardChangesModal:Cancel')}
        onSecondaryAction={onModalConfirm(cancel)}
      />,
    );
  };

  const ModalSharePublicSurvey = (accessKey: string, title: string) => {
    // eslint-disable-next-line jsx-a11y/no-access-key
    setModal(<SharePublicSurveyModal onClose={onModalConfirm()} accessKey={accessKey} title={title} />);
  };

  const ModalShareChannelLink = (slug: string) => {
    setModal(<ShareChannelLinkModal onClose={onModalConfirm()} slug={slug} />);
  };

  const ModalEndPublicSurvey = (save: () => void, title: string) => {
    setModal(<EndPublicSurveyModal onClose={onModalConfirm()} onSave={onModalConfirm(save)} title={title} />);
  };

  const ModalShareLinks = (links: { url: string; level: string }[]) => {
    setModal(<ShareLinks links={links} cancel={onModalConfirm()} />);
  };

  const ModalNewTeam = (onChooseTeam: (teamId: string) => void, organization: any) => {
    setModal(<NewTeam cancel={onModalConfirm()} onChooseTeam={onChooseTeam} organization={organization} />);
  };
  const ModalUpdateRespondent = (
    respondent: SurveyGroupRespondentsQuery['surveyGroupRespondents']['data'][0]['respondents'][0],
    organizationId: string,
    contact?: string,
  ) => {
    setModal(
      <UpdateRespondent
        cancel={onModalConfirm()}
        respondent={respondent}
        contact={contact}
        organizationId={organizationId}
      />,
    );
  };

  const ModalChoiceSettings = (choiceSeverity: number, save: (choiceSeverity: number) => void) => {
    setModal(
      <ChoiceSettings
        choiceSeverity={choiceSeverity}
        save={(newChoiceSeverity: number) => {
          onModalConfirm(() => save(newChoiceSeverity))();
        }}
        cancel={onModalConfirm()}
      />,
    );
  };

  const ModalQuestionSettings = (question: QuestionDetail, save: (question: QuestionDetail) => void) => {
    setModal(
      <QuestionSettings
        question={question}
        save={(newQuestion: QuestionDetail) => {
          onModalConfirm(() => save(newQuestion))();
        }}
        cancel={onModalConfirm()}
      />,
    );
  };

  const ModalDeleteQuestion = (
    no: number,
    fromQuestions: number[],
    toQuestions: number[],
    deleteQuestion: () => void,
    cancel?: () => void,
  ) => {
    const joinArray = (arr: number[]) =>
      arr.map((text, i, array) => {
        if (i === 0) {
          return <strong>{text}</strong>;
        }
        if (i < array.length - 1) {
          return (
            <>
              , <strong>{text}</strong>
            </>
          );
        }
        return (
          <>
            {t('deleteQuestionModal:conjunction')} <strong>{text}</strong>
          </>
        );
      });
    setModal(
      <BaseModal
        title={t('deleteQuestionModal:Title', { no })}
        description={
          fromQuestions.length ? (
            <>
              {t('deleteQuestionModal:DescriptionFrom')}
              {joinArray(fromQuestions)}.
              {toQuestions.length ? (
                <>
                  {' '}
                  {t('deleteQuestionModal:DescriptionTo')}
                  {joinArray(toQuestions)}. {t('deleteQuestionModal:DeleteInfo')}
                </>
              ) : null}
            </>
          ) : null
        }
        primaryActionText={t('deleteQuestionModal:Delete')}
        onPrimaryAction={onModalConfirm(deleteQuestion)}
        secondaryActionText={t('deleteQuestionModal:Cancel')}
        onSecondaryAction={onModalConfirm(cancel)}
      />,
    );
  };

  const ModalDeleteRespondent = (name: string, email: string, deleteRespondent: () => void, cancel?: () => void) => {
    setModal(
      <BaseModal
        title={t('deleteRespondentModal:Title')}
        description={
          <Trans i18nKey="deleteRespondentModal:Description" values={{ name, email }}>
            <strong>{name}</strong> ({email}) will be deleted.
          </Trans>
        }
        primaryActionText={t('deleteRespondentModal:Delete')}
        onPrimaryAction={onModalConfirm(deleteRespondent)}
        secondaryActionText={t('deleteRespondentModal:Cancel')}
        onSecondaryAction={onModalConfirm(cancel)}
      />,
    );
  };

  const ModalOrgImport = (
    name: string,
    statistic: ImportStatistic,
    callback: () => void,
    onboardings: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
    processSurveys: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'],
    surveys: NonNullable<SurveysQuery['user']['organization']>['surveys'],
    respondentOnboardingSettings: RespondentOnboarding,
    setRespondentOnboardingSettings: (data: RespondentOnboarding) => void,
    respondentOneTimeSurveySettings: RespondentOneTimeSurveys,
    setRespondentOneTimeSurveySettings: (data: RespondentOneTimeSurveys) => void,
    respondentSGSettings: RespondentSurveyGroups,
    setRespondentSGSettings: (data: RespondentSurveyGroups) => void,
    onClose: () => void,
  ) => {
    setModal(
      <OrganizationImport
        name={name}
        statistic={statistic}
        save={onModalConfirm(callback)}
        cancel={onModalConfirm()}
        onboardings={onboardings}
        processSurveys={processSurveys}
        surveys={surveys}
        respondentOnboardingSettings={respondentOnboardingSettings}
        setRespondentOnboardingSettings={setRespondentOnboardingSettings}
        respondentOneTimeSurveySettings={respondentOneTimeSurveySettings}
        setRespondentOneTimeSurveySettings={setRespondentOneTimeSurveySettings}
        respondentSGSettings={respondentSGSettings}
        setRespondentSGSettings={setRespondentSGSettings}
        onClose={onClose}
      />,
    );
  };

  const Modal = (
    type: ModalType,
    titleKey: string,
    textKey: string,
    buttonText?: string,
    callback?: () => void,
    style: any = {},
    info?: any,
  ) => {
    let components;
    switch (textKey) {
      case 'surveyCreatedText':
      case 'showEmailText':
        components = [<br />];
        break;
    }
    const modal = (
      <SweetModal
        success={type === ModalType.SUCCESS}
        error={type === ModalType.ERROR}
        title={t(titleKey)}
        confirmBtnText={buttonText ? t(buttonText) : t('ok')}
        onConfirm={onModalConfirm(callback)}
        style={style}
        customClass={`modal-${type.toLocaleLowerCase()}`}
      >
        <div style={{ textAlign: textKey === 'showEmailText' ? 'left' : 'center' }}>
          {components !== undefined ? (
            <Trans i18nKey={`modal:${textKey}`} components={components} values={info} />
          ) : (
            t(textKey, info)
          )}
        </div>
      </SweetModal>
    );
    setModal(modal);
  };

  const ModalImportDiscardSetting = (discard: () => void) => {
    const handlePrimaryAction = () => () => {
      onSecondModalConfirm(discard)();
      onModalConfirm()();
    };

    setSecondModal(
      <BaseModal
        title={t('organizationImportCard:closeSettingsTitle')}
        description={t('organizationImportCard:closeSettingsDescription')}
        primaryActionText={t('organizationImportCard:close')}
        onPrimaryAction={handlePrimaryAction()}
        secondaryActionText={t('organizationImportCard:continue')}
        onSecondaryAction={onSecondModalConfirm()}
      />,
    );
  };

  const ModalAccessTokenValidation = (status: keyof typeof ACCESS_TOKEN_ERROR) => {
    const modal = <AccessTokenValidation status={status} onConfirmClick={onModalConfirm} />;
    setModal(modal);
  };

  const ModalOtpAlreadySent = (time: number) => {
    setModal(
      <BaseModal
        title={t('loginForm:modalTitle')}
        description={<Trans i18nKey="loginForm:modalDescription" values={{ time }}></Trans>}
        primaryActionText={t('ok')}
        onPrimaryAction={onModalConfirm()}
      />,
    );
  };

  const ModalSgAlreadyExists = (callback: () => void) => {
    setModal(
      <BaseModal
        title={t('surveyGroup:modalTitle')}
        description={<Trans i18nKey="surveyGroup:modalDescription"></Trans>}
        secondaryActionText={t('surveyGroup:createNewButton')}
        onSecondaryAction={onModalConfirm()}
        primaryActionText={t('surveyGroup:useActiveSgButton')}
        onPrimaryAction={onModalConfirm(callback)}
      />,
    );
  };

  const ModalArielHints = () => {
    setModal(<ArielHints close={onModalConfirm()} />);
  };

  const ModalAddSGRespondentsToOngoingSurveys = (
    onSave: (runOngoingSteps: string[], addedOn?: Date) => void,
    addedOn: Date,
    ongoingSurveys: SurveyGroupSurveysData[],
    selectedRespondents?: string[],
  ) => {
    setModal(
      <AddSGRespondentToOngoingSurveys
        close={onModalConfirm()}
        onSubmit={onSave}
        addedOn={addedOn}
        ongoingSurveys={ongoingSurveys}
        selectedRespondents={selectedRespondents}
      />,
    );
  };

  const ModalDeleteTopicGroup = (title: string, onDelete: () => void) => {
    setModal(
      <BaseModal
        title={t('topicOverview:deleteTopicGroupModalTitle')}
        description={
          <Trans i18nKey={'topicOverview:deleteTopicGroupModalDescription'} values={{ title }}>
            Přejete si vlastní téma <strong>{title}</strong> trvale odstranit?
          </Trans>
        }
        secondaryActionText={t('newTeamModal:back')}
        onSecondaryAction={onModalConfirm()}
        primaryActionText={t('topicOverview:remove')}
        onPrimaryAction={onModalConfirm(onDelete)}
      />,
    );
  };

  const ModalDeleteProcessStep = (onDelete: () => void, children: ReactNode) => {
    setModal(
      <BaseModal
        title={t('processDetail:removeModalButton')}
        description={t('processDetail:removeModalTitle')}
        secondaryActionText={t('processDetail:cancel')}
        onSecondaryAction={onModalConfirm()}
        primaryActionText={t('processDetail:remove')}
        onPrimaryAction={onModalConfirm(onDelete)}
        primaryBtnDataCy="confirm-delete-btn"
      >
        {children}
      </BaseModal>,
    );
  };

  const ModalDeleteSurveyGroup = (onDelete: () => void) => {
    setModal(
      <BaseModal
        title={t('surveyGroup:deleteSurveyGroupModalTitle')}
        description={t('surveyGroup:deleteSurveyGroupModalDescription')}
        secondaryActionText={t('newTeamModal:back')}
        onSecondaryAction={onModalConfirm()}
        primaryActionText={t('surveyGroup:delete')}
        onPrimaryAction={onModalConfirm(onDelete)}
      />,
    );
  };

  return (
    <>
      {modal}
      {props.children}
      {secondModal}
    </>
  );
}

export default Modal;
