import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, FormGroup } from 'react-bootstrap';
import { calculateValidPeriod, CardBody, HeadingMedium, theme } from '@arnold/common';
import { ProductCode, getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import { FormGroupLabel } from '../../components/Common';
import { EDITION } from '../../lib/common';
import { UserQuery } from '../../generated/hooks';

interface IProps {
  productHistory: NonNullable<NonNullable<UserQuery['user']['organization']>['productVersionHistory']>;
}

interface IPropsEdition {
  edition?: string;
  duration?: string;
  licenceCount?: number;
}

const FormColumns = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${theme.breakpoint.small}) {
    width: auto;
    flex-direction: row;
    gap: ${theme.spacing.f};
  }
`;

const TextValue = styled.div`
  margin-left: ${theme.spacing.f};
  margin-top: ${theme.spacing.d};
`;

const Heading = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const EditionContainer = styled.div`
  display: flex;
`;

const EditionList = styled.div`
  flex: 1;
`;

const LinkContainer = styled.div`
  align-self: flex-start;
  margin: ${theme.spacing.f} ${theme.spacing.h};
`;

const Edition = ({ edition, duration, licenceCount }: IPropsEdition) => {
  const [t] = useTranslation('editionSettings');
  return (
    <FormColumns>
      <FormGroup className="col p-0">
        <FormGroupLabel>{t('edition')}</FormGroupLabel>
        <TextValue>{t(`label-${edition}`)}</TextValue>
      </FormGroup>
      <FormGroup className="col p-0">
        <FormGroupLabel>{t('duration')}</FormGroupLabel>
        <TextValue>{duration}</TextValue>
      </FormGroup>
      <FormGroup className="col p-0">
        <FormGroupLabel>
          {t(edition !== ProductCode.Teamio_Arnold ? 'licenceCount' : 'licenceCountForTeamioOnboarding')}
        </FormGroupLabel>
        <TextValue>{licenceCount}</TextValue>
      </FormGroup>
    </FormColumns>
  );
};

export const EditionOverviewCard = ({ productHistory }: IProps) => {
  const { t, i18n } = useTranslation('editionSettings');
  const history = useHistory();

  const getNextEdition = (
    activeVersionValidTo: string | number,
    productHistory: NonNullable<NonNullable<UserQuery['user']['organization']>['productVersionHistory']>,
  ) => {
    const nextEdition = productHistory.find((product) => product.validFrom === activeVersionValidTo);

    const editionInFuture = productHistory
      .filter((product) => product.validFrom > activeVersionValidTo)
      .sort((a, b) => new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime());

    if (!nextEdition) {
      if (editionInFuture.length >= 1) {
        return {
          edition: EDITION.FREE.name,
          licenceCount: EDITION.FREE.licenceCount,
          duration: `${getLocalizedDateWithoutLeadingZeroes(
            new Date(activeVersionValidTo),
            i18n.language,
          )} - ${getLocalizedDateWithoutLeadingZeroes(new Date(editionInFuture[0].validFrom), i18n.language)}`,
        };
      }

      return {
        edition: EDITION.FREE.name,
        licenceCount: EDITION.FREE.licenceCount,
        duration: t('from', {
          validFrom: getLocalizedDateWithoutLeadingZeroes(new Date(activeVersionValidTo), i18n.language),
        }),
      };
    }

    return {
      edition: nextEdition.product,
      licenceCount: nextEdition.licenceCount,
      duration: `${getLocalizedDateWithoutLeadingZeroes(
        new Date(activeVersionValidTo),
        i18n.language,
      )} - ${getLocalizedDateWithoutLeadingZeroes(new Date(nextEdition.validTo), i18n.language)}`,
    };
  };

  const activeEdition = calculateValidPeriod(productHistory);
  const nextEdition = getNextEdition(activeEdition.validTo, productHistory);

  if (Object.keys(EDITION).includes(activeEdition.product)) {
    return (
      <Card data-cy="admin-order-arnold-card">
        <CardBody>
          <Heading>
            <HeadingMedium margin={`0 0 ${theme.spacing.h} 0`}>{t('activeEdition')}</HeadingMedium>
            <div>
              {activeEdition.product !== EDITION.FREE.name && (
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    history.push({
                      pathname: '/settings/order',
                    })
                  }
                  data-cy="admin-upgrade-plan-button"
                >
                  {t('upgradeButton')}
                </Button>
              )}
              <Button
                variant="primary"
                type="submit"
                onClick={() =>
                  history.push({
                    pathname: '/settings/pricing',
                  })
                }
              >
                {t('orderButton')}
              </Button>
            </div>
          </Heading>
          <EditionContainer>
            <EditionList>
              <Edition
                edition={activeEdition.product}
                licenceCount={activeEdition.licenceCount || EDITION.FREE.licenceCount}
                duration={
                  activeEdition.product === EDITION.FREE.name && nextEdition.edition === EDITION.FREE.name
                    ? t('unlimited')
                    : t('to', {
                        validTo: getLocalizedDateWithoutLeadingZeroes(new Date(activeEdition.validTo), i18n.language),
                      })
                }
              />

              {(activeEdition.product !== EDITION.FREE.name || nextEdition.edition !== EDITION.FREE.name) && (
                <>
                  <HeadingMedium margin={`0 0 ${theme.spacing.h} 0`}>{t('plannedEdition')}</HeadingMedium>
                  <Edition
                    edition={nextEdition.edition}
                    licenceCount={nextEdition.licenceCount || EDITION.FREE.licenceCount}
                    duration={nextEdition.duration}
                  />
                </>
              )}
            </EditionList>
            {activeEdition.product !== EDITION.FREE.name && (
              <LinkContainer>
                <Link
                  to={{
                    pathname: '/settings/order',
                  }}
                  data-cy="admin-plan-add-participants"
                >
                  {t('increaseCapacity')}
                </Link>
              </LinkContainer>
            )}
          </EditionContainer>
        </CardBody>
      </Card>
    );
  }

  return null;
};

export default EditionOverviewCard;
