import { PureQueryOptions } from '@apollo/client';
import { TableColumn, addToastFunction, cleanArray, createEventData, pushToDataLayer } from '@arnold/common';
import { REPORT_LINK } from '@arnold/core';
import { prop, sortBy } from 'ramda';
import { TFunction } from 'react-i18next';
import {
  ReportAccessQuery,
  SequenceReportAccessesQuery,
  SurveyStatus,
  SurveysAndSurveyGroupsQuery,
  useAcceptReportsMutation,
} from '../../generated/hooks';
import auth from '../../lib/auth';
import { ISequenceTableItem, ISurveyTableItem, ReportData, SurveyTableItemType, TableItem } from './types';

export const getColumns = (
  t: TFunction,
  showOpenIcon?: boolean,
  showAdditionalInfoInTable?: boolean,
): Array<TableColumn<ReportData>> =>
  cleanArray([
    {
      key: 'reportLabel',
      title: <span data-icom={'reports-report-label-title'}>{t('reportLabel')}</span>,
      dataIndex: 'reportLabel',
    },
    !!showAdditionalInfoInTable && {
      key: 'topicName',
      title: <span data-icom={'reports-report-survey-in-report-title'}>{t('surveyInReport')}</span>,
      dataIndex: 'topicName',
    },
    !!showOpenIcon && {
      key: 'reportOpened',
      title: (
        <span data-icom={'reports-report-opened-title'} style={{ whiteSpace: 'nowrap' }}>
          {t('reportOpened')}
        </span>
      ),
      dataIndex: 'reportOpened',
    },
    !showAdditionalInfoInTable && {
      key: 'status',
      title: <span data-icom={'reports-report-status-title'}>{t('status')}</span>,
      dataIndex: 'status',
    },
    {
      key: 'actions',
      title: undefined,
      dataIndex: 'actions',
      width: '20%',
    },
  ]);

type Survey = NonNullable<ReportAccessQuery['survey']>;
type SurveyGroup = NonNullable<SequenceReportAccessesQuery['surveyGroup']>;

export const getStateTitle = (
  t: TFunction,
  { sent, accepted }: NonNullable<Survey['reports']>[0] | SurveyGroup['reports'][0],
  isOngoing: boolean,
) => {
  if (sent) {
    return t('sent');
  } else if (accepted === null) {
    if (isOngoing) {
      return t('open');
    }
    return t('accepted');
  } else if (accepted) {
    if (isOngoing) {
      return t('willBeSent');
    }
    return t('accepted'); // Only available when it waits in job queue and message queue
  } else {
    if (isOngoing) {
      return t('wontBeSent');
    }
    return t('notSent');
  }
};

export const getPrimaryActionTitle = (isOngoing: boolean, accepted: boolean | null, sent: boolean, t: TFunction) => {
  if (sent) {
    return t('resend');
  }
  if (accepted === true) {
    return t('dontAccept');
  }
  return t('accept');
};

// open rate info is shown only for earlier reports, because this info has not been collected for older ones
export const getShowReportOpenIcon = (date?: string) => {
  if (date) {
    return new Date(date).getTime() > new Date('2023-01-25T00:00:00').getTime();
  }
  return false;
};

export const useAcceptReport = (
  t: TFunction,
  addToast: addToastFunction,
  report: NonNullable<Survey['reports']>[0] | SurveyGroup['reports'][0],
  dependantQueries: Array<string | PureQueryOptions>,
) => {
  const user = auth.getUser();
  const handleMutationCompleted = () => {
    addToast(t('acceptSuccess'));
    pushToDataLayer({
      userId: user!.id,
      orgId: user!.orgId,
      event: 'ux.report-accept',
      ...createEventData('report', 'accept', 'accept report'),
    });
  };

  const [acceptReport] = useAcceptReportsMutation({
    variables: {
      reportAccepts: [
        {
          reportAccessId: report.id,
          accepted: true,
        },
      ],
    },
    onCompleted: handleMutationCompleted,
    refetchQueries: dependantQueries,
  });

  return acceptReport;
};

export const useSuspendReport = (
  t: TFunction,
  addToast: addToastFunction,
  report: NonNullable<Survey['reports']>[0] | SurveyGroup['reports'][0],
  dependantQueries: Array<string | PureQueryOptions>,
) => {
  const user = auth.getUser();
  const handleMutationCompleted = () => {
    addToast(t('suspendSuccess'));
    pushToDataLayer({
      userId: user!.id,
      orgId: user!.orgId,
      event: 'ux.report-suspend',
      ...createEventData('report', 'suspend', 'suspend report'),
    });
  };

  const [suspendReport] = useAcceptReportsMutation({
    variables: {
      reportAccepts: [
        {
          reportAccessId: report.id,
          accepted: false,
        },
      ],
    },
    onCompleted: handleMutationCompleted,
    refetchQueries: dependantQueries,
  });

  return suspendReport;
};

const createBulkActionsVariables = (reportIds: string[], accepted: boolean) =>
  reportIds.map((reportId) => ({
    reportAccessId: reportId,
    accepted,
  }));

export const useAcceptReports = (
  t: TFunction,
  addToast: addToastFunction,
  clearSelection: () => void,
  reportIds: string[],
  dependantQueries: Array<string | PureQueryOptions>,
  accepted: boolean,
) => {
  const user = auth.getUser();
  const handleMutationCompleted = () => {
    addToast(
      t(accepted ? 'acceptBulkSuccess' : 'suspendBulkSuccess', { count: reportIds.length, postProcess: 'interval' }),
    );
    clearSelection();
    pushToDataLayer({
      userId: user!.id,
      orgId: user!.orgId,
      event: accepted ? 'ux.report-accept' : 'ux.report-suspend',
      ...createEventData('report', accepted ? 'accept' : 'suspend', `${accepted ? 'accept' : 'suspend'} report`),
    });
  };

  const [acceptReports] = useAcceptReportsMutation({
    variables: {
      reportAccepts: createBulkActionsVariables(reportIds, accepted),
    },
    onCompleted: handleMutationCompleted,
    refetchQueries: dependantQueries,
  });

  return acceptReports;
};

export const getTopicGroup = (reportBasePath: string, survey?: Survey, surveyGroup?: SurveyGroup) => {
  if (reportBasePath === 'report' && survey) {
    return survey?.topic?.topicGroup;
  }

  if (reportBasePath === 'report-sequence' && surveyGroup) {
    return surveyGroup.topicGroup;
  }

  return null;
};

export function isSurveyReport(object: any): object is NonNullable<Survey['reports']>[0] {
  return 'resultsInfo' in object;
}

export const combineSurveysAndSequences = (
  surveys:
    | ReportAccessQuery['survey'][]
    | NonNullable<NonNullable<SurveysAndSurveyGroupsQuery['user']['organization']>['surveys']>,
  sequences:
    | SequenceReportAccessesQuery['surveyGroup'][]
    | NonNullable<SurveysAndSurveyGroupsQuery['user']['organization']>['surveyGroups']
    | NonNullable<NonNullable<SurveysAndSurveyGroupsQuery['user']['organization']>['surveyGroups']>,
): TableItem[] => {
  const preparedDataStructure = [
    ...surveys.map(
      (survey) =>
        ({
          data: survey,
          type: SurveyTableItemType.Survey,
          timestamp: new Date(survey?.sendAt),
          isOngoing: survey?.status === SurveyStatus.Ongoing,
          reportBasePath: REPORT_LINK.REPORT,
        }) as unknown as ISurveyTableItem,
    ),
    ...sequences.map(
      (sequence) =>
        ({
          data: sequence,
          type: SurveyTableItemType.Sequence,
          timestamp: new Date(sequence?.from),
          isOngoing: !sequence?.isAnySurveyComplete,
          reportBasePath: REPORT_LINK.REPORT_SEQUENCE,
        }) as unknown as ISequenceTableItem,
    ),
  ];
  const sortByTimestamp = sortBy(prop('timestamp'));
  return sortByTimestamp(preparedDataStructure);
};
