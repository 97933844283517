import { getLocalizedDateWithoutLeadingZeroes } from '@arnold/core';
import styled from '@emotion/styled';
import { isMatch, isValid, parse } from 'date-fns';
import * as locale from 'date-fns/locale';
import { useEffect, useState } from 'react';
import { DayPicker, useInput } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../assets/icons';
import { theme } from '../../theme';
import { PlainButton } from '../buttons';
import { TimeSelect } from './TimeSelect';

export const Label = styled.div`
  color: ${theme.colors.text.secondary};
  font-size: 0.75rem !important;
  padding-left: ${theme.spacing.f};
  padding-bottom: ${theme.spacing.c};
`;

export const DateContainer = styled.div`
  margin-right: 1rem;
  position: relative;
`;

type IDateInputProps = {
  isAlert: boolean;
};

const DateError = styled.div`
  position: absolute;
  margin-left: ${theme.spacing.f};
  margin-top: ${theme.spacing.c};
  margin-bottom: ${theme.spacing.e};
  color: ${theme.colors.emotionDanger.default};
  font-size: ${theme.typography.body.small &&
  theme.typography.body.small.regular &&
  theme.typography.body.small.regular.fontSize};
`;

const DateInput = styled.input`
  border: ${({ isAlert }: IDateInputProps) =>
    `${theme?.spacing.a} solid ${isAlert ? theme?.colors.emotionDanger.default : theme?.colors.borderMain.default}`};
  color: ${theme.colors.text.primary};
  height: 39px;
  cursor: pointer;
  width: 100%;
  border-radius: 6px;
  padding: ${theme.spacing.d} ${theme.spacing.l} ${theme.spacing.d} ${theme.spacing.f};
  font-size: initial;
  outline: none;
`;

type IProps = {
  allowedFrom?: Date;
  allowedTo?: Date;
  onDateChange: (value: Date | null) => void;
  onDelete?: () => void;
  value: Date | null;
  defaultMonth?: Date;
  errorText?: string | null;
  label: string;
  dataCy?: string;
  disabled?: boolean;
  emptyPlaceholder?: string;
  dateInputClassName?: string;
};

export const DateTimeInput = ({
  allowedFrom,
  allowedTo,
  onDateChange,
  value,
  label,
  onDelete,
  errorText,
  dataCy,
  disabled,
  emptyPlaceholder,
  defaultMonth,
  dateInputClassName,
}: IProps) => {
  const { t, i18n } = useTranslation('dateInput');
  const [isDayPickerOpen, setIsDayPickerOpen] = useState<boolean>(false);
  const [month, setMonth] = useState<Date>(value ?? defaultMonth ?? new Date());
  const [dateInputValue, setDateInputValue] = useState<string | null>(null);
  const [dateInputError, setDateInputError] = useState<string | null>(null);
  // has to be type any ReturnType<typeof setTimeout> does not match type number of clearTimeout
  const [closeDayPickerTimer, setCloseDayPickerTimer] = useState<any>(undefined);

  const blockDayPickerClose = () => {
    clearTimeout(closeDayPickerTimer);
  };

  useEffect(() => {
    setDateInputValue(value ? getLocalizedDateWithoutLeadingZeroes(new Date(value), i18n.language) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  useEffect(() => {
    setMonth(value ? new Date(value) : defaultMonth ?? new Date());
    setDateInputValue(value ? getLocalizedDateWithoutLeadingZeroes(new Date(value), i18n.language) : null);
    setIsDayPickerOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { inputProps, dayPickerProps } = useInput({
    defaultSelected: new Date(),
    format: 'P',
    required: true,
    locale: locale[i18n.language as keyof typeof locale],
  });

  const handleDateChange = (selectedDay: Date) => {
    selectedDay.setHours(value?.getHours() ?? 0, value?.getMinutes() ?? 0, 0, 0);
    onDateChange(selectedDay);
    setDateInputError(null);
    setDateInputValue(selectedDay ? getLocalizedDateWithoutLeadingZeroes(new Date(selectedDay), i18n.language) : null);
  };

  const parseInputValue = (inputValue: string) => {
    const trimValue = inputValue.replace(/\s/g, '');

    const inputDate =
      (isMatch(trimValue, 'd.M.yyyy') && parse(trimValue, 'd.M.yyyy', value ?? new Date())) ||
      (isMatch(trimValue, 'd/M/yyyy') && parse(trimValue, 'd/M/yyyy', value ?? new Date())) ||
      (isMatch(trimValue, 'dd.MM.yyyy') && parse(trimValue, 'dd.MM.yyyy', value ?? new Date())) ||
      (isMatch(trimValue, 'dd/MM/yyyy') && parse(trimValue, 'dd/MM/yyyy', value ?? new Date())) ||
      (isMatch(trimValue, 'yyyy.MM.dd') && parse(trimValue, 'yyyy.MM.dd', value ?? new Date())) ||
      (isMatch(trimValue, 'yyyy.MM.dd.') && parse(trimValue, 'yyyy.MM.dd.', value ?? new Date())) ||
      (isMatch(trimValue, 'yyyy/MM/dd') && parse(trimValue, 'yyyy/MM/dd', value ?? new Date())) ||
      (isMatch(trimValue, 'yyyy.M.d') && parse(trimValue, 'yyyy.M.d', value ?? new Date())) ||
      (isMatch(trimValue, 'yyyy.M.d.') && parse(trimValue, 'yyyy.M.d.', value ?? new Date())) ||
      (isMatch(trimValue, 'yyyy/M/d') && parse(trimValue, 'yyyy/M/d', value ?? new Date()));

    if (!inputDate || !isValid(inputDate)) {
      setDateInputError(t('dateInput:incorrectDate'));
      return;
    }

    setDateInputError(null);
    return inputDate;
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleInputChange();
    }
    if (e.key === 'Escape') {
      setIsDayPickerOpen(false);
      e.target.blur();
    }
  };

  const handleInputChange = (e?: any) => {
    setCloseDayPickerTimer(setTimeout(() => setIsDayPickerOpen(false), 200));
    if (dateInputValue === '') {
      setDateInputError(null);
      return;
    }

    if (dateInputValue) {
      const date = parseInputValue(dateInputValue!);

      if (date) {
        date.setHours(value?.getHours() ?? 0, value?.getMinutes() ?? 0, 0, 0);
        if (date.toISOString() !== value?.toISOString()) {
          onDateChange(date);
        }
      }
    }
  };

  return (
    <div className={dateInputError ?? errorText ? 'mb-8' : ''}>
      <div className="d-flex align-items-end">
        <DateContainer data-cy={dataCy}>
          <Label>{label}</Label>
          <DateInput
            {...inputProps}
            onChange={(e) => setDateInputValue(e.target.value)}
            onBlur={(e) => handleInputChange(e)}
            onFocus={() => setIsDayPickerOpen(true)}
            onKeyDown={handleKeyDown}
            value={dateInputValue || ''}
            placeholder={emptyPlaceholder || t('dateInput:dateInputPlaceholder')}
            disabled={disabled}
            isAlert={!!dateInputError || !!errorText}
            data-cy={dataCy ? `${dataCy}-input` : undefined}
            className={dateInputClassName}
          />
          {isDayPickerOpen && (
            <div
              onClick={blockDayPickerClose}
              style={{
                position: 'relative',
                zIndex: 1,
              }}
            >
              <DayPicker
                {...dayPickerProps}
                mode={'single'}
                month={month}
                onMonthChange={setMonth}
                selected={value ? new Date(value) : undefined}
                fromDate={allowedFrom}
                toDate={allowedTo}
                onDayClick={handleDateChange}
                locale={locale[i18n.language as keyof typeof locale]}
                styles={{
                  root: {
                    position: 'absolute',
                    backgroundColor: theme.colors.backgroundCover.default,
                    margin: 0,
                    minWidth: '290px',
                    boxShadow: theme.shadows.elevationContentDialog,
                    borderRadius: '6px',
                  },
                  months: { margin: theme.spacing.f },
                  caption_label: { ...theme.typography.heading.medium!.default },
                  head_cell: {
                    ...theme.typography.body.medium!.regular,
                    color: theme.colors.text.secondary,
                    textTransform: 'none',
                  },
                  day: { width: theme.spacing.h, height: theme.spacing.h },
                }}
              />
            </div>
          )}
        </DateContainer>

        {value && (
          <TimeSelect
            initialValue={`${String(value.getHours()).padStart(2, '0')}:${String(value.getMinutes()).padStart(2, '0')}`}
            disabled={disabled}
            dataCy={dataCy ? `${dataCy}-time` : undefined}
            isAlert={!!errorText}
            onChange={(newTime) => {
              onDateChange(parse(newTime, 'HH:mm', value ?? new Date()));
            }}
          />
        )}

        {onDelete && (
          <PlainButton onClick={onDelete} disabled={disabled} className="ml-4">
            <CloseIcon />
            <span className="visually-hidden">{t('common:close')}</span>
          </PlainButton>
        )}
      </div>

      {(dateInputError ?? errorText) && <DateError>{dateInputError ?? errorText}</DateError>}
    </div>
  );
};

export default DateTimeInput;
