import { theme } from '@arnold/common';
import styled from '@emotion/styled';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ClassicPreset } from 'rete';
import { Drag } from 'rete-react-plugin';
import { SelectedRange } from '../../../generated/hooks';

export class MinMaxControlData extends ClassicPreset.Control {
  constructor(
    public initialValue: SelectedRange,
    public change: (value: SelectedRange) => void,
    public readOnly = false,
  ) {
    super();
  }
}

const Container = styled.div`
  margin: ${theme.spacing.f};

  & > div {
    margin-bottom: ${theme.spacing.d};
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;

  & > label {
    margin-right: ${theme.spacing.d};
    color: ${theme.colors.text.secondary};
  }

  & > label,
  & > input {
    width: calc(50% - ${theme.spacing.c});
  }

  & > input {
    background-color: ${theme.colors.backgroundBasic.default};
    border-color: ${theme.colors.backgroundBasic.default};
    font-size: inherit;
    font-family: inherit;
  }
`;

export const MinMaxControl = (props: { data: MinMaxControlData }) => {
  const { t } = useTranslation('topicEditor');
  const [value, setValue] = useState(props.data.initialValue);
  const ref = React.useRef(null);
  Drag.useNoDrag(ref);

  useEffect(() => {
    setValue(props.data.initialValue);
  }, [props.data.initialValue]);

  const onChange = (value: SelectedRange) => {
    setValue(value);
    props.data.change(value);
  };

  return (
    <Container ref={ref} data-error-part={`min/max`} className="minMax">
      <Row data-error-part={`min`}>
        <label>{t('min')}</label>
        <FormControl
          type="number"
          name="min"
          data-icom={'input-min-ariel'}
          data-cy="input-min-ariel"
          disabled={props.data.readOnly}
          value={value.min}
          min={0}
          max={value.max}
          onChange={(e) => onChange({ ...value, min: +e.target.value })}
        />
      </Row>
      <Row data-error-part={`max`}>
        <label>{t('max')}</label>
        <FormControl
          type="number"
          name="max"
          data-icom={'input-max-ariel'}
          data-cy="input-max-ariel"
          disabled={props.data.readOnly}
          min={value.min}
          value={value.max}
          onChange={(e) => onChange({ ...value, max: +e.target.value })}
          isInvalid={false}
        />
      </Row>
    </Container>
  );
};
