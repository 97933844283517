import { Mutation } from '@apollo/client/react/components';
import { ActionMenu, CardBody, CardTitle, HeadingLarge, HeadingMedium, Tooltip, theme, useToast } from '@arnold/common';
import { ReactComponent as ActionMenuIcon } from '@arnold/common/lib/assets/icons/ActionMenuIcon.svg';
import styled from '@emotion/styled/macro';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Button, Card } from 'react-bootstrap';
import { Col, HeaderRow, NormalRowHovered, PageWrapper } from '../../components/Common';
import { HeadingSubtext, SectionHeader, SectionHeaderButtonsContainer } from '../../components/StyledComponents';
import { TeamTree } from '../../components/TeamTree/TeamTree';
import { getOrganizationTeams } from '../../graphql/queries';
import { ExternalPerson, TeamioIcon } from '../../assets/images';
import { getContact } from '../../lib/common';
import ee from '../../lib/eventEmitter';
import {
  ContactSource,
  ContactType,
  OrganizationQuery,
  OrganizationTeamsQuery,
  UserQuery,
  useDeleteRespondentMutation,
} from '../../generated/hooks';
import { setTeamNameMutation } from '../../graphql/mutations';
import { useGetOrganizationTeams } from '../Surveys/utils';
import SurveyParticipants from './SurveyParticipants';
import NoRespondents from './NoRespondents';

const LinkAndActionMenuWrap = styled.div`
  position: relative;
`;

const ActionMenuWrap = styled.div`
  position: absolute;
  left: calc(100% - 32px);
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  & circle {
    fill: ${theme.colors.actionSecondary.default} !important;
  }
  &:hover circle {
    fill: ${theme.colors.actionPrimary.hover} !important;
  }
`;

interface IProps extends RouteComponentProps<any> {
  user: UserQuery['user'];
  organization: OrganizationQuery['organization'];
}

interface ButtonProps {
  children: ReactNode;
}
const AddRespondentButton = ({ children }: ButtonProps) => {
  const history = useHistory();

  const goToAddRespondent = () => {
    history.push({
      pathname: '/addRespondent',
    });
  };

  return (
    <Button
      variant="outline-primary"
      onClick={goToAddRespondent}
      data-icom={'btn-add-employee'}
      data-cy={'add-employee-button'}
    >
      {children}
    </Button>
  );
};

const UpdateTeamButton = ({ children }: ButtonProps) => {
  const history = useHistory();

  const goToUpdateTeam = () => {
    history.push('/updateTeam');
  };

  return (
    <Button color="primary" onClick={goToUpdateTeam} data-cy="admin-update-teams-button" data-icom="btn-update-teams">
      {children}
    </Button>
  );
};

const TeamsScreen = ({ user, organization, history }: IProps) => {
  const [t] = useTranslation(['teamsScreen', 'languageSelector', 'AuthorizedPage', 'confirmation']);
  const [renameDialog, setRenameDialog] = useState<string | null>(null);
  const { organizationTeams, loadingOrError } = useGetOrganizationTeams(user && organization ? organization.id : '0');

  const { addToast } = useToast();
  const [deleteRespondent] = useDeleteRespondentMutation({
    refetchQueries: [getOrganizationTeams],
    awaitRefetchQueries: true,
  });

  const goToUpdateRespondent = (
    respondent: NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'][0],
    organization: OrganizationQuery['organization'],
  ) => {
    history.push({
      pathname: '/updateRespondent',
      state: {
        respondent,
        organization,
      },
    });
  };

  const imported: Record<string, { icon: string; tooltipText: string }> = {
    TEAMIO: {
      icon: TeamioIcon,
      tooltipText: t('teamioIconTooltip'),
    },
    AUTOMATIC_IMPORT: {
      icon: ExternalPerson,
      tooltipText: t('externalIconTooltip'),
    },
  };

  if (loadingOrError) {
    return loadingOrError;
  }
  if (user && organization && organizationTeams) {
    const teams = organizationTeams;
    const hasTeams = teams.length > 0;
    return (
      <PageWrapper data-cy="admin-page-content">
        <HeadingLarge margin={`${theme.spacing.h} 0 0`} data-icom="heading-teams">
          {t('AuthorizedPage:teams')}
        </HeadingLarge>
        {organization.productVersion && (
          <SurveyParticipants
            maxParticipants={organization.productVersion.licenceCount || undefined}
            usedParticipants={organization.activeRespondentsInCurrentPeriodCount || 0}
            periodEnd={organization.productVersion.activePeriodEnd}
            activeEdition={organization.productVersion}
          />
        )}
        {hasTeams && (
          <>
            <SectionHeader>
              <div>
                <HeadingMedium margin={`${theme.spacing.i} 0 ${theme.spacing.c}`}>{t('orgStructure')}</HeadingMedium>
                <HeadingSubtext>{t('orgStructureDescription')}</HeadingSubtext>
              </div>
              <SectionHeaderButtonsContainer>
                <AddRespondentButton>{t('addEmployee')}</AddRespondentButton>
                <UpdateTeamButton>{t('updateTeam')}</UpdateTeamButton>
              </SectionHeaderButtonsContainer>
            </SectionHeader>
            <TeamTree teams={teams} />
          </>
        )}
        <SectionHeader>
          <div>
            <HeadingMedium margin={`${theme.spacing.i} 0 ${theme.spacing.c}`}>{t('teams')}</HeadingMedium>
            <HeadingSubtext dontLimitWidth>{t('teamsDescription')}</HeadingSubtext>
          </div>
        </SectionHeader>
        {(organization.enabledRespondentsCount === 0 || teams.length === 0) && (
          <NoRespondents user={user} organization={organization}>
            <UpdateTeamButton>{t('addMultipleEmployees')}</UpdateTeamButton>
            <AddRespondentButton>{t('addOneEmployee')}</AddRespondentButton>
          </NoRespondents>
        )}
        {hasTeams && (
          <Card>
            <CardBody>
              <CardTitle>
                {teams.map((team) => (
                  <div key={team.id} id={team.id} data-cy="team-table">
                    <HeaderRow style={{ marginTop: theme.spacing.c }}>
                      <Col sm="12" xl="12">
                        <FontAwesomeIcon
                          icon={faPen as IconProp}
                          style={{
                            fontSize: '24px',
                            float: 'right',
                            marginTop: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => setRenameDialog(team.id)}
                        />
                        <h2>{`${t('team')} ${
                          team.name ||
                          t('withoutLeader', {
                            ns: 'teams',
                          })
                        }`}</h2>
                      </Col>
                    </HeaderRow>
                    <HeaderRow>
                      <Col sm="2" xl="2" className="ml-7">
                        {t('name')}
                      </Col>
                      <Col sm="2" xl="2">
                        {t('surname')}
                      </Col>
                      <Col sm="3" xl="3">
                        {t('workEmail')}
                      </Col>
                      <Col sm="4" xl="4">
                        {t('surveys')}
                      </Col>
                    </HeaderRow>

                    {team.members.map((member) => (
                      <LinkAndActionMenuWrap key={member.id} data-cy="team-member-row">
                        <NormalRowHovered
                          style={{ cursor: 'pointer' }}
                          onClick={() => goToUpdateRespondent(member, organization)}
                        >
                          <Col sm="2" xl="2" className="position-relative ml-7">
                            {member.originator && imported[member.originator] && (
                              <Tooltip title={imported[member.originator].tooltipText}>
                                <img
                                  src={imported[member.originator].icon}
                                  className="position-absolute mr-4"
                                  alt="Teamio"
                                  style={{ left: -16 }}
                                />
                              </Tooltip>
                            )}
                            {member.firstname}
                          </Col>
                          <Col sm="2" xl="2">
                            {member.surname}
                          </Col>
                          <Col sm="3" xl="3">
                            {getContact(member, ContactType.Email, ContactSource.Primary)}
                          </Col>
                          <Col sm="4" xl="4">
                            {`${member.disabled ? t('confirmation:no') : t('confirmation:yes')} (${t(
                              `languageSelector:${
                                (member.language && member.language.code) || organization.primaryLanguageCode
                              }`,
                            )},
                                          ${
                                            member.contacts &&
                                            (member.preferredContactType === ContactType.Sms
                                              ? t('phone')
                                              : (member.contacts.find(
                                                  (c) =>
                                                    c.type === ContactType.Email &&
                                                    c.source === ContactSource.Respondent,
                                                ) &&
                                                  t('personalEmail')) ||
                                                t('workEmail'))
                                          })`}
                          </Col>
                        </NormalRowHovered>
                        <ActionMenuWrap>
                          <ActionMenu
                            dataCy="teams-respondent-options"
                            toggleIcon={<ActionMenuIcon />}
                            items={[
                              {
                                label: t('edit'),
                                action: () => goToUpdateRespondent(member, organization),
                              },
                              {
                                label: t('delete'),
                                action: () =>
                                  ee.emitModalDeleteRespondent(
                                    `${member.firstname} ${member.surname}`,
                                    getContact(member, ContactType.Email, ContactSource.Primary),
                                    async () => {
                                      await deleteRespondent({
                                        variables: {
                                          respondentId: member.id,
                                        },
                                      });
                                      addToast(
                                        t('respondentDeleted', {
                                          name: `${member.firstname} ${member.surname}`,
                                        }),
                                      );
                                    },
                                  ),
                              },
                            ]}
                          />
                        </ActionMenuWrap>
                      </LinkAndActionMenuWrap>
                    ))}
                    {renameDialog === team.id && (
                      <Mutation mutation={setTeamNameMutation} refetchQueries={[getOrganizationTeams]}>
                        {(postMutation: (variables: any) => void) => (
                          <SweetAlert
                            question
                            input="text"
                            required
                            placeholder={t('newName')}
                            showCancel
                            confirmBtnText={t('change')}
                            confirmBtnBsStyle="primary"
                            cancelBtnBsStyle="default"
                            cancelBtnText={t('cancel')}
                            title={t('changeTeamName', {
                              teamName: team.name,
                            })}
                            onConfirm={(val: string) => {
                              postMutation({
                                variables: {
                                  teamName: val,
                                  teamId: team.id,
                                },
                              });
                              setRenameDialog(null);
                            }}
                            preConfirm={() => setRenameDialog(null)}
                            onCancel={() => setRenameDialog(null)}
                            validationMsg={t('emptyTeamName')}
                          >
                            {t('changeTeamSupervisor', {
                              teamSupervisor:
                                team && team.leader ? team.leader.firstname + ' ' + team.leader.surname : '',
                            })}
                          </SweetAlert>
                        )}
                      </Mutation>
                    )}
                  </div>
                ))}
              </CardTitle>
            </CardBody>
          </Card>
        )}
        {hasTeams && (
          <div className="mt-n4 ml-8 mr-8">
            <UpdateTeamButton>{t('updateTeam')}</UpdateTeamButton>
            <AddRespondentButton>{t('addEmployee')}</AddRespondentButton>
          </div>
        )}
      </PageWrapper>
    );
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>{t('noTeams')}</CardTitle>
      </CardBody>
    </Card>
  );
};

export default TeamsScreen;
