export { ReactComponent as AIIcon } from './AI.svg';
export { ReactComponent as ActionMenuIcon } from './ActionMenuIcon.svg';
export { ReactComponent as AddIcon } from './AddIcon.svg';
export { ReactComponent as AddPlusIcon } from './AddPlusIcon.svg';
export { ReactComponent as AlertIcon } from './AlertIcon.svg';
export { ReactComponent as CalendarIcon } from './CalendarIcon.svg';
export { ReactComponent as CancelIcon } from './Cancel.svg';
export { ReactComponent as CancelCloseIcon } from './CancelClose.svg';
export { ReactComponent as ChatIcon } from './Chat.svg';
export { ReactComponent as ChatInfoIcon } from './ChatInfo.svg';
export { ReactComponent as CheckBox } from './Check-box.svg';
export { ReactComponent as Check } from './Check.svg';
export { ReactComponent as CloseIcon } from './Close.svg';
export { ReactComponent as ContentCopyIcon } from './ContentCopy.svg';
export { ReactComponent as CopyLink } from './CopyLink.svg';
export { ReactComponent as DownChevron } from './Down-Chevron.svg';
export { ReactComponent as DownloadIcon } from './Download.svg';
export { ReactComponent as Email } from './E-mail.svg';
export { ReactComponent as EditIcon } from './EditIcon.svg';
export { ReactComponent as ErrorWarningIcon } from './ErrorWarningIcon.svg';
export { ReactComponent as Excel } from './Excel.svg';
export { ReactComponent as ExitFullscreenIcon } from './ExitFullscreenIcon.svg';
export { ReactComponent as FullscreenIcon } from './FullscreenIcon.svg';
export { ReactComponent as HourGlass } from './HourGlass.svg';
export { ReactComponent as InfoIcon } from './InfoIcon.svg';
export { ReactComponent as LeftChevron } from './Left-Chevron.svg';
export { ReactComponent as Line } from './Line.svg';
export { ReactComponent as Link } from './Link.svg';
export { ReactComponent as MailNotOpened } from './MailNotOpened.svg';
export { ReactComponent as MailOpened } from './MailOpened.svg';
export { ReactComponent as MinusIcon } from './MinusIcon.svg';
export { ReactComponent as MouseAreaIcon } from './MouseAreaIcon.svg';
export { ReactComponent as MouseLeftClickIcon } from './MouseLeftClickIcon.svg';
export { ReactComponent as MouseRightClickIcon } from './MouseRightClickIcon.svg';
export { ReactComponent as No } from './No.svg';
export { ReactComponent as NoMetricsQuestionIcon } from './NoMetricsQuestion.svg';
export { ReactComponent as NoSurveyIcon } from './NoSurvey.svg';
export { ReactComponent as NotDeliveredIcon } from './NotDelivered.svg';
export { ReactComponent as OpenEndedIconBig } from './OpenEndedIconBig.svg';
export { ReactComponent as OptionsIconBig } from './OptionsIconBig.svg';
export { ReactComponent as PersonMultiselect } from './Person-multiselect.svg';
export { ReactComponent as Phone } from './Phone.svg';
export { ReactComponent as QuestionMarkIcon } from './QuestionMarkIcon.svg';
export { ReactComponent as RadioButton } from './Radio-button.svg';
export { ReactComponent as RedoIcon } from './Redo.svg';
export { ReactComponent as Refresh } from './Refresh.svg';
export { ReactComponent as Report } from './Report.svg';
export { default as RoundCheckedIcon } from './RoundCheckedIcon'; // Normal component
export { ReactComponent as RoutingChat } from './Routing-chat.svg';
export { ReactComponent as RoutingSelect } from './Routing-select.svg';
export { ReactComponent as SaveIcon } from './Save.svg';
export { ReactComponent as ScrollIcon } from './ScrollIcon.svg';
export { default as SendIcon } from './SendIcon'; // Normal component
export { ReactComponent as Settings } from './Settings.svg';
export { ReactComponent as SettingsIcon } from './SettingsIcon.svg';
export { ReactComponent as ShareIcon } from './Share.svg';
export { ReactComponent as TellIcon } from './Tell.svg';
export { ReactComponent as TellIconBig } from './TellIconBig.svg';
export { ReactComponent as TextFieldMandatory } from './Text-field-mandatory.svg';
export { ReactComponent as TextField } from './Text-field.svg';
export { ReactComponent as TrashIcon } from './TrashIcon.svg';
export { ReactComponent as UndoIcon } from './Undo.svg';
export { ReactComponent as UpChevron } from './Up-Chevron.svg';
export { ReactComponent as Vector } from './Vector.svg';
export { ReactComponent as VisibilityOff } from './VisibilityOff.svg';
export { ReactComponent as VisibilityOn } from './VisibilityOn.svg';
export { ReactComponent as WarningIcon } from './WarningIcon.svg';
export { ReactComponent as WidthIcon } from './WidthIcon.svg';
export { ReactComponent as UniIcon } from './uniIcon.svg';
