import { TableBodyRow } from './TableBodyRow';
import type { TableDataType, TableProps } from './types';

type Props<T extends TableDataType> = Pick<
  TableProps<T>,
  | 'columns'
  | 'dataSources'
  | 'selected'
  | 'onClick'
  | 'renderDetailPanel'
  | 'thin'
  | 'rowDataCypressTestFlag'
  | 'rowSelection'
  | 'wrapRow'
>;

export const TableBody = <T extends TableDataType>({
  dataSources,
  selected,
  thin,
  rowDataCypressTestFlag,
  rowSelection,
  wrapRow,
  ...props
}: Props<T>): JSX.Element => {
  return (
    <>
      {dataSources.map((data, index) => {
        const Component = (
          <TableBodyRow
            rowIndex={index}
            dataCypressTestFlag={rowDataCypressTestFlag}
            key={`row-${index}`}
            isSelected={selected === data.id}
            data={data}
            thin={thin}
            rowSelection={rowSelection}
            {...props}
          />
        );
        if (wrapRow) {
          return wrapRow(data, Component);
        }
        return Component;
      })}
    </>
  );
};
