import {
  clearLocalStorageFromNonArnoldItems,
  CookieConsentStyle,
  eraseAllCookies,
  getFrontendConfigValue,
} from '@arnold/common';
import { ENVIRONMENT } from '@arnold/core';
import LmcCookieConsentManager, {
  CookieConsentCategory,
  DisplayMode,
  VanillaCookieConsent,
} from '@lmc-eu/cookie-consent-manager';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import smartlookClient from 'smartlook-client';

const environment = getFrontendConfigValue('ENVIRONMENT');

const disableAnalytics = () => {
  if (smartlookClient.initialized()) {
    smartlookClient.disable();
  }
  eraseAllCookies(environment !== ENVIRONMENT.LOCAL);
  clearLocalStorageFromNonArnoldItems();
};

export let vanillaCookieConsent: VanillaCookieConsent.CookieConsent<string>;

export default function CookieConsent() {
  const { i18n } = useTranslation();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);
  const cookiesSettings = query.get('cookies');

  useEffect(() => {
    if (
      ![ENVIRONMENT.DEV, ENVIRONMENT.DEPLOY, ENVIRONMENT.PROD].includes(environment) ||
      cookiesSettings === 'necessary'
    ) {
      return;
    }

    const cookieBannerWrapper = document.getElementById('cc_div');
    if (cookieBannerWrapper) {
      cookieBannerWrapper.remove();
    }

    vanillaCookieConsent = LmcCookieConsentManager('admin.arnold-robot.com', {
      defaultLang: i18n.language,
      // @ts-ignore
      displayMode: DisplayMode.FORCE,
      autodetectLang: false,
      config: {
        cookie_domain: 'arnold-robot.com',
      },
      onFirstAccept: (cookieConsent) => {
        // @ts-ignore
        if (!cookieConsent.allowedCategory(CookieConsentCategory.ANALYTICS)) {
          disableAnalytics();
        }
      },
      onChange: (cookieConsent, categories) => {
        if (
          // @ts-ignore
          categories.changed.includes(CookieConsentCategory.ANALYTICS) && // @ts-ignore
          !cookieConsent.allowedCategory(CookieConsentCategory.ANALYTICS)
        ) {
          disableAnalytics();
        }
      },
      onAccept: (cookieConsent) => {
        const isSmartlookEnabled = ['prod'].includes(environment) && false;
        if (
          // @ts-ignore
          cookieConsent.allowedCategory(CookieConsentCategory.ANALYTICS) &&
          isSmartlookEnabled &&
          !smartlookClient.initialized()
        ) {
          smartlookClient.init(getFrontendConfigValue('SMARTLOOK_ID'));
        }
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language]);

  return <CookieConsentStyle />;
}
