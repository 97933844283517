import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BodyMedium,
  BodySmall,
  CardBody,
  CardTitle,
  HeadingSmall,
  theme,
  ToggleButton,
  useToast,
} from '@arnold/common';
import styled from '@emotion/styled';
import { Card } from 'react-bootstrap';
import { Features, useDisableFeatureMutation, useEnableFeatureMutation } from '../../generated/hooks';

const StyledFeature = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${theme.spacing.f};
  &:not(:last-child) {
    border-bottom: ${theme.spacing.a} solid ${theme.colors.borderSeparator.default};
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: self-end;
`;

interface IProps {
  features: Array<Features | null>;
  organizationId: string;
}

interface IPropsFeature {
  feature: Features;
  enabled: boolean;
  organizationId: string;
}

const FEATURES = [Features.ContinuousReportAlerts, Features.ReportRemoveRespondentFromProcessSurvey];

const FeatureToggle = ({ feature, enabled, organizationId }: IPropsFeature) => {
  const [t] = useTranslation('featureSettings');
  const [checked, setChecked] = useState(enabled);
  const [enableFeature] = useEnableFeatureMutation();
  const [disableFeature] = useDisableFeatureMutation();
  const { addToast } = useToast();

  const handleChange = async (value: boolean) => {
    setChecked(value);
    if (value) {
      try {
        await enableFeature({
          variables: {
            feature,
            organizationId,
          },
        });
        addToast(t('switchedOn', { feature: t(`feature-title-${feature}`) }));
      } catch {
        setChecked(false);
      }
    } else {
      try {
        await disableFeature({
          variables: {
            feature,
            organizationId,
          },
        });
        addToast(t('switchedOff', { feature: t(`feature-title-${feature}`) }));
      } catch {
        setChecked(true);
      }
    }
  };

  return (
    <StyledFeature>
      <div>
        <BodyMedium margin={`0 0 ${theme.spacing.d} 0`} data-icom={`feature-title-${feature}`}>
          {t(`feature-title-${feature}`)}
        </BodyMedium>
        <BodySmall color={`${theme.colors.text.secondary}`}>{t(`feature-description-${feature}`)}</BodySmall>
      </div>
      <ToggleContainer data-cy={`admin-feature-${feature}`}>
        <ToggleButton value={checked} onChange={handleChange} />
      </ToggleContainer>
    </StyledFeature>
  );
};

export const ManageFeaturesCard = ({ features, organizationId }: IProps) => {
  const [t] = useTranslation('featureSettings');

  return (
    <Card data-cy="admin-manage-features-card">
      <CardBody>
        <CardTitle>
          <HeadingSmall>{t('title')}</HeadingSmall>
          <BodySmall>{t('description')}</BodySmall>
        </CardTitle>
        {FEATURES.map((feature) => (
          <FeatureToggle feature={feature} enabled={features.includes(feature)} organizationId={organizationId} />
        ))}
      </CardBody>
    </Card>
  );
};

export default ManageFeaturesCard;
