import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { ConversationSubjects } from '../../components/ConversationSubjects';
import { ErrorPage } from '../../components/StyledComponents';
import { Loading } from '../../components';
import { getTopicGroupTranslationByLanguageId } from '../../lib/common';
import { AllowedConversationSubjectQuery, useTopicGroupQuery, useUserQuery } from '../../generated/hooks';

const AddTopicScreen = (props: RouteComponentProps<any>) => {
  const {
    match: { params },
  } = props;
  const { t } = useTranslation('processDetail');

  const { loading: userLoading, data: userData, error: userError } = useUserQuery();
  const { loading, error, data } = useTopicGroupQuery({
    variables: { topicGroupId: params.topicGroupId },
    fetchPolicy: 'cache-first',
  });

  if (error || userError) {
    return <ErrorPage>{t('common:loadError')}</ErrorPage>;
  }
  if (loading || userLoading || !data || !userData) {
    return <Loading />;
  }

  const { organization } = userData.user;

  if (!organization) {
    return null;
  }
  const languageId = organization.primaryLanguageId.toString(10);

  const topicGroupTranslation = getTopicGroupTranslationByLanguageId(languageId, data.topicGroup.translations);

  const notProcessAndHasEmptyTopic = (
    subject: NonNullable<AllowedConversationSubjectQuery['user']['organization']>['allowedConversationSubjects'][0],
  ) => !subject.process && !subject.topic;

  const usedStepTopicGroupIds = data.topicGroup?.steps?.map((s) => s.stepTopicGroup.id);

  return (
    <ConversationSubjects
      filterProcesses={true}
      hideCreateTopicButton
      isDisabled={notProcessAndHasEmptyTopic}
      breadcrumbItems={[
        { title: t('ReportAccess:topics'), link: '/topics' },
        {
          title: topicGroupTranslation ? topicGroupTranslation.value : data.topicGroup.name,
          link: `/process/${params.topicGroupId}`,
        },
      ]}
      heading={t('processDetail:chooseTopic')}
      processTopicGroupId={data.topicGroup.id}
      usedStepTopicGroupIds={usedStepTopicGroupIds}
    />
  );
};

export default AddTopicScreen;
