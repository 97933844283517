import { Col, FormGroup } from 'react-bootstrap';
import { Field, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormSelect, Option } from '@arnold/common';
import { FormGroupLabel } from '../../Common';
import { getRespondentNameLabel } from '../../../lib/common';
import { OrganizationTeamsQuery } from '../../../generated/hooks';

type Props = {
  members: NonNullable<OrganizationTeamsQuery['organizationTeams']>[0]['members'];
  onChange?: (option: Option) => void;
  id?: string;
  error?: string;
  className?: string;
} & Partial<FormikProps<any>>;

export function EmployeeField({ members, onChange, values, error, setFieldValue, id, className, ...props }: Props) {
  const { t } = useTranslation('updateRespondent');

  const options = members.map((member) => ({
    value: member.id,
    label: getRespondentNameLabel(member),
  }));

  return (
    <FormGroup as={Col} sm={8} className={className}>
      <FormGroupLabel>{t('teamLeader')}</FormGroupLabel>
      <Field
        component={FormSelect}
        name="memberId"
        options={options}
        placeholder={t('search')}
        isSearchable
        {...props}
        defaultValue={values?.teamId}
        isInvalid={!!error}
        id={id || 'team-leader-select'}
      />
      {error && <span className="form-text text-danger mr-6 w-auto">{error}</span>}
    </FormGroup>
  );
}
