import { FetchResult } from '@apollo/client';
import { FeelingValue, getFrontendConfigValue } from '@arnold/common';
import { NoAnswerComponent } from '@arnold/common/lib/assets/icons/numbers';
import { REPORT_LINK } from '@arnold/core';
import { trim } from 'ramda';
import { ReactNode } from 'react';
import {
  ActiveRespondentsQuery,
  AllowedConversationSubjectQuery,
  ContactSource,
  ContactType,
  FeEventType,
  LogFeEventMutation,
  OrganizationAdminsQuery,
  OrganizationTeamsQuery,
  SurveyGroupQuery,
  SurveyQuery,
  UserQuery,
} from '../../generated/hooks';
import { isLoggedUserSysAdmin } from './user';

export * from './survey';
export * from './user';

export const goToTalkResults = (reportBasePath: string, accessKey: string) =>
  window.open(
    `${getFrontendConfigValue('ARNOLD_URL')}/${reportBasePath}/${accessKey}${
      isLoggedUserSysAdmin() ? '?sysAdmin' : ''
    }`,
    '_blank',
  );

export const goToResultsWithTeamFiter = (teamId: string, reportAccessKey: string) =>
  window.open(
    `${getFrontendConfigValue('ARNOLD_URL')}/${REPORT_LINK.REPORT}/${reportAccessKey}?unit-teams=unit_${teamId}${
      isLoggedUserSysAdmin() ? '&sysAdmin' : ''
    }`,
    '_blank',
  );

export const goToResultsWithRespondentView = (reportAccessKey: string, respondentId: string) =>
  window.open(
    `${getFrontendConfigValue('ARNOLD_URL')}/${REPORT_LINK.REPORT}/${reportAccessKey}/alert/${respondentId}${
      isLoggedUserSysAdmin() ? '?sysAdmin' : ''
    }`,
    '_blank',
  );

export const getTopicGroupName = (
  lang: string,
  topicGroup?:
    | Pick<
        NonNullable<NonNullable<UserQuery['user']['organization']>['surveyGroups'][0]>['topicGroup'],
        'translations' | 'name'
      >
    | Pick<NonNullable<SurveyGroupQuery['surveyGroup']>['topicGroup'], 'translations' | 'name'>
    | Pick<
        NonNullable<NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['surveys'][0]['topicGroup']>,
        'translations' | 'name'
      >
    | null,
) => {
  const foundValue =
    topicGroup && (topicGroup.translations || []).find((translation) => translation.language.code === lang);
  if (foundValue) {
    return foundValue.value;
  }

  return topicGroup?.name || '';
};

export const hasTopicMissingTranslations = (
  organization: UserQuery['user']['organization'] | null,
  topic?:
    | NonNullable<AllowedConversationSubjectQuery['user']['organization']>['allowedConversationSubjects'][0]['topic']
    | null,
  invitedRespondents?: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'],
) => {
  if (!topic || !organization) {
    return true;
  }
  const respondentsLanguages = Array.from(
    new Set(
      invitedRespondents?.map((r) => (r.language ? parseInt(r.language.id, 10) : organization.primaryLanguageId)),
    ),
  );
  const necessaryLanguages = organization.languages.filter((l) => respondentsLanguages.includes(parseInt(l.id, 10)));
  return !(necessaryLanguages.length ? necessaryLanguages : organization.languages).every(
    (l) => topic.languages.map((tl) => tl.id).includes(l.id) && topic.languageIdsOnStartingQuestion.includes(l.id),
  );
};

export const getPreferredContact = (
  type: ContactType,
  contacts: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'][0]['contacts'],
) => {
  const sortedContacts = [...(contacts || [])].sort((contactA, contactB) => {
    if (contactA.type === type) {
      if (contactB.type !== type) {
        return -1;
      }
    }
    if (contactA.type === contactB.type && contactA.source === ContactSource.Respondent) {
      return -1;
    }
    return 1;
  });
  return sortedContacts[0];
};

export const uniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36);
  return dateString + randomness;
};

export const isValidEmail = (email: string) =>
  /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(
    email.toLowerCase(),
  );

export const getDefaultRecipientOrgAdminEmail = (
  organizationAdmins: OrganizationAdminsQuery['organizationAdmins'] | null,
  user: UserQuery['user'],
) =>
  !!organizationAdmins?.length &&
  (organizationAdmins
    .find((admin) => admin && admin.user?.id === user.id)
    ?.contacts?.find((c) => c.type === ContactType.Email)?.value ||
    organizationAdmins[0]?.contacts?.find((c) => c.type === ContactType.Email)?.value);

export const getTeamMemberIdsRecursive = (
  teams: NonNullable<OrganizationTeamsQuery['organizationTeams']>,
  team?: NonNullable<OrganizationTeamsQuery['organizationTeams']>[0],
): string[] => {
  return team
    ? [
        ...team.members.map((m) => m.id),
        ...team.members
          .filter((m) => m.id !== team.leader?.id)
          .map((m) =>
            getTeamMemberIdsRecursive(
              teams,
              teams.find((t) => t.leader?.id === m.id),
            ),
          )
          .flat(),
      ]
    : [];
};

export const getOrganizationName = (systemName: string, name: string) => {
  const orgSystemName = trim(systemName);
  const orgName = trim(name);
  return orgSystemName === orgName ? orgSystemName : `${orgSystemName} (${orgName})`;
};

export const getTopLevelReportAccess = (reports?: NonNullable<SurveyQuery['survey']>['reports'] | null) =>
  reports?.find((report) => !report.respondent);

export const registerTopLevelReportClick = (
  reportAccess: string,
  respondentId: string,
  logFeEvent: (options: any) => Promise<FetchResult<LogFeEventMutation>>,
) => {
  try {
    logFeEvent({
      variables: {
        eventType: FeEventType.TopLevelReportOpen,
        payload: {
          reportAccess,
          respondentId,
        },
      },
    });
  } catch (err) {
    // Do nothing
  }
};

export const showResult = (answered: number = 0, total?: number): string =>
  `${answered} / ${total} (${Math.round(total ? (answered / total) * 100 : 100)}%)`;

export const getRespondentFeeling = (
  topicGroupId: string,
  answers?: Partial<
    NonNullable<
      NonNullable<SurveyGroupQuery['surveyGroup']>['groupedRespondents'][0]['metricQuestionsForRespondent']
    >[0]
  >['answers'],
): ReactNode => {
  const value = answers?.find((answer) => answer.topicGroupId === topicGroupId)?.value;

  if (!value) {
    return <NoAnswerComponent />;
  }
  return <FeelingValue value={value}>{value}</FeelingValue>;
};

const openUrl = (url: string, target?: string) => {
  window.open(url, target);
};

export const goToTalkProcessResults = (
  accessKey: string | boolean,
  respondentId: string,
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename',
) =>
  openUrl(
    `${getFrontendConfigValue('ARNOLD_URL')}/${REPORT_LINK.REPORT_SEQUENCE}/${accessKey}/respondent/${respondentId}${
      isLoggedUserSysAdmin() ? '?sysAdmin' : ''
    }`,
    target,
  );
