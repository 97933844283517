import { Col } from 'react-bootstrap';
import { BodyMedium, isNullish, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import { Card, GraySpan, HeaderRow, NormalRow } from '../../../components/Common';
import { TopicGroupQuery } from '../../../generated/hooks';
import { getIsReminderSet, getTimeStringForOffset, prepareStepOffsets } from '../../../lib/process';

type Props = {
  step: NonNullable<TopicGroupQuery['topicGroup']['steps']>[0];
};

export const TopicGroupItemStaticContent = ({ step }: Props) => {
  const { t } = useTranslation('processDetail');
  const offsets = prepareStepOffsets({
    startOffsetMins: step.startOffsetMins,
    endOffsetMins: step.endOffsetMins,
    remindersOffsetMins: step.reminders.map((reminder) => reminder.reminderOffsetMins),
  });

  const firstReminder = offsets.remindersOffset?.[0] || null;
  const secondReminder = offsets.remindersOffset?.[1] || null;
  const thirdReminder = offsets.remindersOffset?.[2] || null;

  return (
    <Card style={{ marginTop: 6, paddingInline: theme.spacing.h }}>
      <HeaderRow>
        <Col sm="2" xl="2">
          {t('start')}
        </Col>
        {firstReminder && (
          <Col sm="2" xl="2">
            {t('noReminder', { count: 1 })}
          </Col>
        )}
        {secondReminder && (
          <Col sm="2" xl="2">
            {t('noReminder', { count: 2 })}
          </Col>
        )}
        {thirdReminder && (
          <Col sm="2" xl="2">
            {t('noReminder', { count: 3 })}
          </Col>
        )}
        <Col sm="2" xl="2">
          {t('end')}
        </Col>
        <Col sm="4" xl="4" />
      </HeaderRow>
      <StyledNormalRow>
        <Col sm="2" xl="2">
          <BodyMedium>
            {offsets.startOffset?.days !== undefined ? (
              <>
                <Trans
                  i18nKey={'processDetail:day'}
                  values={{ dayNo: offsets.startOffset.days + (offsets.startOffset.days >= 0 ? 1 : 0) }}
                  components={[<GraySpan />]}
                >
                  Day 1
                </Trans>
                <GraySpan>,</GraySpan> {getTimeStringForOffset(offsets.startOffset)}
              </>
            ) : (
              '-'
            )}
          </BodyMedium>
        </Col>
        {firstReminder && (
          <Col sm="2" xl="2">
            <BodyMedium>
              {!isNullish(firstReminder?.days) && getIsReminderSet(offsets.startOffset, firstReminder) ? (
                <>
                  {`+${firstReminder?.days} `}
                  <GraySpan>
                    {t('days', {
                      count: firstReminder?.days,
                      postProcess: 'interval',
                    })}
                    ,
                  </GraySpan>{' '}
                  {getTimeStringForOffset(firstReminder)}
                </>
              ) : (
                '-'
              )}
            </BodyMedium>
          </Col>
        )}
        {secondReminder && (
          <Col sm="2" xl="2">
            <BodyMedium>
              {!isNullish(secondReminder?.days) && getIsReminderSet(offsets.startOffset, secondReminder) ? (
                <>
                  {`+${secondReminder?.days} `}
                  <GraySpan>
                    {t('days', {
                      count: secondReminder?.days,
                      postProcess: 'interval',
                    })}
                    ,
                  </GraySpan>{' '}
                  {getTimeStringForOffset(secondReminder)}
                </>
              ) : (
                '-'
              )}
            </BodyMedium>
          </Col>
        )}
        {thirdReminder && (
          <Col sm="2" xl="2">
            <BodyMedium>
              {!isNullish(thirdReminder?.days) && getIsReminderSet(offsets.startOffset, thirdReminder) ? (
                <>
                  {`+${thirdReminder?.days} `}
                  <GraySpan>
                    {t('days', {
                      count: thirdReminder?.days,
                      postProcess: 'interval',
                    })}
                    ,
                  </GraySpan>{' '}
                  {getTimeStringForOffset(thirdReminder)}
                </>
              ) : (
                '-'
              )}
            </BodyMedium>
          </Col>
        )}
        <Col sm="2" xl="2">
          <BodyMedium>
            {!isNullish(offsets.endOffset?.days) ? (
              <>
                {`+${offsets.endOffset?.days} `}
                <GraySpan>
                  {t('days', {
                    count: offsets.endOffset?.days,
                    postProcess: 'interval',
                  })}
                  ,
                </GraySpan>{' '}
                {getTimeStringForOffset(offsets.endOffset)}
              </>
            ) : (
              '-'
            )}
          </BodyMedium>
        </Col>
        <Col sm="4" xl="4" />
      </StyledNormalRow>
    </Card>
  );
};

const StyledNormalRow = styled(NormalRow)`
  padding: ${theme.spacing.d} 0;
  display: flex;
  align-items: center;
  border-top: none;
`;
