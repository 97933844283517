import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Step } from './types';

type Props = {
  steps: Step[];
  step: Step;
};
export const Progress: FC<Props> = ({ steps, step }) => {
  const [t] = useTranslation('organizationImportCard');

  if (steps.length <= 1) {
    return null;
  }
  return <span>{`(${t('step')} ${steps.indexOf(step) + 1}/${steps.length})`}</span>;
};
