import { pick } from 'ramda';
import { scroller } from 'react-scroll';
import { notEmpty } from '@arnold/common';
import { uniqueId } from '../../lib/helpers';
import {
  CreateQuestionDefinitionMutationVariables,
  QuestionDefinitionInput,
  QuestionType,
  TopicDetailQuery,
} from '../../generated/hooks';
import { QuestionDetail, QuestionSubsections, Section, trimRuleOrigValue } from './topicStructure';
import { QUESTION_DEFINITION_TYPE, QUESTION_TYPE_MAPPING } from './types';

type Rules = TopicDetailQuery['topicDetail']['allQuestions'][0]['rules'];

// Question helpers
export const isMultiselect = (question: QuestionDetail): boolean => {
  return !(question.selectedRange === '1' || question.selectedRange === '0-1' || question.selectedRange === '0');
};

export const getElseRules = (question: QuestionDetail) => {
  return question.rules.filter((rule) => rule.origValue === '');
};

export const isRuleForValue = (rule: Rules[0], value: string) => {
  if (!rule.origValue) {
    return false;
  }
  const parsedValue = JSON.parse(rule.origValue);
  return Array.isArray(parsedValue)
    ? parsedValue.map((v) => v.toString()).includes(value)
    : parsedValue.toString() === value.toString();
};

export const getRulesForValue = (question: QuestionDetail, value: string) =>
  question.rules.filter((r) => isRuleForValue(r, value));

export const getTranslation = (question: QuestionDetail, languageId: string) => {
  return question.translations.find((t) => t.language.id === languageId);
};

export const extractValueFromRules = (origRules: Rules, value: string) => {
  const remainingRules: Rules = [];
  origRules.forEach((rule) => {
    if (!isRuleForValue(rule, value)) {
      remainingRules.push(rule as any);
    } else if (rule.origValue !== value) {
      const parsedRuleValue = JSON.parse(rule.origValue);
      if (Array.isArray(parsedRuleValue) && parsedRuleValue.length > 1) {
        parsedRuleValue.splice(parsedRuleValue.map((v) => v.toString()).indexOf(value), 1);
        remainingRules.push({
          ...rule,
          origValue: JSON.stringify(parsedRuleValue),
        } as any);
      }
    }
  });

  return remainingRules;
};

export const getQuestionType = (question: QuestionDetail): QuestionType => {
  const type = QUESTION_TYPE_MAPPING[question.__typename!];
  if (type === QuestionType.Select && isMultiselect(question)) {
    return QuestionType.Multiselect;
  }
  return type;
};

export function isQuestionBubble(question: QuestionDetail) {
  return [QUESTION_DEFINITION_TYPE.tell, QUESTION_DEFINITION_TYPE.ending].includes(question.__typename as any);
}

export function areAllQuestionsBubbles(questions: QuestionDetail[]) {
  return questions.every((q) => isQuestionBubble(q));
}

export type QuestionRange = {
  min: number;
  max: number;
};

export const parseRange = (rawSelectedRange?: string): QuestionRange => {
  const selectedRange = rawSelectedRange && rawSelectedRange.split('-');
  const range = selectedRange
    ? {
        min: parseInt(selectedRange[0], 10),
        max: parseInt(selectedRange[selectedRange.length > 1 ? 1 : 0], 10),
      }
    : {
        min: 1,
        max: 1,
      };
  return range;
};

export const concatRange = (range: QuestionRange) => {
  if (range.min === range.max) {
    return range.min.toString(10);
  }
  return `${range.min}-${range.max}`;
};

export const filterShownSectionLink = (rules: Rules, isSubsection: boolean, subsections?: QuestionSubsections) =>
  rules
    .filter(notEmpty)
    .filter(
      (rule) =>
        isSubsection || !subsections || !subsections[trimRuleOrigValue(rule.origValue) || 'default']?.flat().length,
    );

// Helpers for updating the state
export const prepareQuestionForUpdate = (question: QuestionDetail): QuestionDefinitionInput => {
  const selectedRange = question.selectedRange && question.selectedRange.split('-');
  return {
    ...pick(
      ['answersToHighlight', 'index', 'reportIndex', 'reportOrder', 'isFirstInSectionWithNumber', 'optional'],
      question,
    ),
    type: getQuestionType(question),
    translations: question.translations.map((t) => ({
      ...pick(['text', 'isDefault'], t),
      choices: t.choices && t.choices.map((choice) => choice.text),
      languageId: t.language.id,
    })),
    selectedRange: selectedRange
      ? {
          min: parseInt(selectedRange[0], 10),
          max: parseInt(selectedRange[selectedRange.length > 1 ? 1 : 0], 10),
        }
      : null,
    rules: question.rules
      .sort((a, b) => a.order - b.order)
      .filter((t) => t.nextQuestion.index)
      .map((t) => ({
        ...pick(['anonymity', 'isFromDefault'], t),
        rule: t.origValue,
        ruleOperator: t.operator,
        nextQuestionIndex: t.nextQuestion.index ? t.nextQuestion.index.toString() : null,
      })),
  };
};

export const handleQuestionTextChange =
  (
    question: QuestionDetail,
    updateQuestion: (question: QuestionDetail, parentQuestionId?: string) => void,
    languageId: string,
    parentQuestionId?: string,
  ) =>
  (text: string) => {
    const translations = question.translations.map((t) => (t.language.id === languageId ? { ...t, text } : t));
    updateQuestion(
      {
        ...question,
        translations,
      },
      parentQuestionId,
    );
  };
// UI helpers

export const scrollToElement = (name: string) =>
  scroller.scrollTo(name, {
    duration: 500,
    smooth: true,
    offset: -25,
  });

export const getPreviousSectionRoutingQuestions = (section: Section, nextSectionStartingQuestionId?: string) =>
  nextSectionStartingQuestionId
    ? [
        ...section.questions,
        ...(section.questionSubsections
          ? Array.from(section.questionSubsections.values())
              .map((sub) => Object.values(sub))
              .flat(3)
          : []),
      ].filter((q) => {
        return q.index && (!q.rules.length || q.rules.some((r) => r.nextQuestion.id === nextSectionStartingQuestionId));
      })
    : [
        ...section.questions,
        ...(section.questionSubsections
          ? Array.from(section.questionSubsections.values())
              .map((sub) => Object.values(sub))
              .flat(3)
          : []),
      ].filter((q) => q.index && !q.rules.length);

export const createEmptyQuestion = (questionType: QUESTION_DEFINITION_TYPE, languageId: string): QuestionDetail => ({
  __typename: questionType,
  isFirstInSectionWithNumber: null,
  id: uniqueId(),
  text: '',
  translations: [
    {
      __typename: 'QuestionDefinitionTranslation',
      language: {
        __typename: 'Language',
        id: languageId,
        code: '', // not needed
      },
      choices: questionType === QUESTION_DEFINITION_TYPE.select ? [] : null,
      text: '',
      isDefault: true,
    },
  ],
  index: null,
  positionX: null,
  positionY: null,
  reportIndex: null,
  reportOrder: null,
  selectedRange: questionType === QUESTION_DEFINITION_TYPE.select ? '1' : null,
  optional: null,
  answersToHighlight: null,
  rules: [],
  isMetric: false,
  isQuestionTrigger: false,
});

export const mapQuestionToCreateQuestionInput = (
  question: QuestionDetail,
  followingQuestionIndex?: number | null,
  ruleOrigValue?: string,
): QuestionDefinitionInput => {
  const selectedRange = question.selectedRange && question.selectedRange.split('-');
  return {
    ...pick(['answersToHighlight', 'index', 'reportIndex', 'isFirstInSectionWithNumber', 'optional'], question),
    type: getQuestionType(question),
    rules: question.rules.length
      ? question.rules
          .filter((rule) => rule.nextQuestion.index)
          .map((rule) => ({
            ...pick(['anonymity', 'isFromDefault'], rule),
            rule: rule.origValue,
            nextQuestionIndex: rule.nextQuestion.index?.toString(10),
          }))
      : followingQuestionIndex
        ? [
            {
              rule: ruleOrigValue,
              nextQuestionIndex: followingQuestionIndex.toString(10),
            },
          ]
        : [],
    selectedRange: selectedRange
      ? {
          min: parseInt(selectedRange[0], 10),
          max: parseInt(selectedRange[selectedRange.length > 1 ? 1 : 0], 10),
        }
      : null,
    translations: question.translations.map((t) => ({
      languageId: t.language.id,
      text: t.text,
      choices: t.choices ? t.choices.map((ch) => ch.text) : null,
      isDefault: t.isDefault,
    })),
  };
};

export const mapQuestionToCreateQuestionVariables = (
  topicId: string,
  question: QuestionDetail,
  precedingQuestionIndexes: Array<number | null>,
  precedingRuleValue?: string,
  followingQuestionIndex?: number | null,
  ruleOrigValue?: string,
): CreateQuestionDefinitionMutationVariables => ({
  topicId,
  input: mapQuestionToCreateQuestionInput(question, followingQuestionIndex, ruleOrigValue),
  precedingQuestionIndexes,
  precedingRuleValue,
  followingQuestionIndex,
});
