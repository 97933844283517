import { HeadingMedium, Tooltip, theme } from '@arnold/common';
import styled from '@emotion/styled';
import { FC, ReactNode } from 'react';
import { ReactComponent as InfoIcon } from '../../assets/images/InfoIcon.svg';

type DashboardSectionProps = {
  children: ReactNode;
  title: string;
  buttons: ReactNode;
  keyName: string;
  dataCy?: string;
  explanation?: string;
};

export const DashboardSection: FC<DashboardSectionProps> = ({
  children,
  title,
  buttons,
  keyName,
  dataCy,
  explanation,
}) => {
  return (
    <StyledDashboardSection>
      <Header>
        <HeadingMedium data-icom={`dashboard-section-${keyName}`}>
          {title}
          {explanation && (
            <Tooltip title={explanation}>
              <InfoIcon className="ml-4" />
            </Tooltip>
          )}
        </HeadingMedium>
        <Buttons>{buttons}</Buttons>
      </Header>
      <Content data-cy={dataCy}>{children}</Content>
    </StyledDashboardSection>
  );
};

const StyledDashboardSection = styled.div`
  padding-top: ${theme.spacing.d};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Buttons = styled.div``;

const Content = styled.div`
  display: flex;
  flex-flow: wrap;
  gap: ${theme.spacing.h};
`;
