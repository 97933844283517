import styled from '@emotion/styled/macro';

export const H1 = styled.h1`
  font-size: 2.125rem;
  font-family:
    Roboto,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  font-weight: 300;
`;
