import { useTranslation } from 'react-i18next';
import { Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import { Dashboard } from '../../components/Dashboard';
import { OrganizationQuery, useDashboardQuery } from '../../generated/hooks';

interface IProps {
  organization: NonNullable<OrganizationQuery['organization']>;
}

const OverviewScreen = ({ organization }: IProps) => {
  const { i18n } = useTranslation();

  const { loading, error, data } = useDashboardQuery({
    variables: { languageCode: i18n.language },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <>ERROR: {error}</>;
  }
  if (data) {
    const { user } = data;
    return (
      <PageWrapper data-cy="admin-page-content">
        {user.organization && <Dashboard organization={organization} dashboardData={user.organization} user={user} />}
      </PageWrapper>
    );
  }
  return <></>;
};

export default OverviewScreen;
