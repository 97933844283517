import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { HeadingSmall, BodySmall } from '../Typography';
import { theme } from '../../theme';
import { Box } from './Box';

const InnerBox = styled.div`
  margin: -${theme.spacing.f} 0;
`;

export interface IBoxWithLabelProps {
  className?: string;
  headline: string;
  description?: ReactNode;
}

export const BoxWithLabel = ({ description, headline, className }: IBoxWithLabelProps) => {
  return (
    <Box className={className}>
      <InnerBox>
        <HeadingSmall margin={'0 0 4px 0'}>{headline}</HeadingSmall>
        {description && <BodySmall>{description}</BodySmall>}
      </InnerBox>
    </Box>
  );
};
