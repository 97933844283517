export function calculateNPS(answers: number[]) {
  if (!answers.length) {
    return 0;
  }
  const relevantAnswers = answers.reduce(
    (relevant, answer) => {
      // detractors - range 0-6
      if (answer < 7) {
        relevant.detractors++;
        // promoters - range 9-10
      } else if (answer > 8) {
        relevant.promoters++;
      }

      return relevant;
    },
    { detractors: 0, promoters: 0 },
  );

  return Math.round(((relevantAnswers.promoters - relevantAnswers.detractors) / answers.length) * 100);
}
