import { ConditionalWrapper, Tooltip } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SurveyQuery, UserQuery, UserSysRole } from '../../generated/hooks';
import { ButtonLink } from '../StyledComponents';
import { Loading } from '../index';
import useArchive from './useArchive';

const ArchiveLink = styled(ButtonLink)`
  margin-left: 12px;
`;

const ArchiveButton = styled(Button)`
  margin-left: 12px;
  white-space: nowrap;
`;

type ArchiveSurveyProps = {
  survey: SurveyQuery['survey'] | NonNullable<UserQuery['user']['organization']>['surveys'][0];
  organization?: UserQuery['user']['organization'] | null;
  user: UserQuery['user'];
  showButton?: boolean;
};

const ArchiveSurvey: FC<ArchiveSurveyProps> = ({ survey, organization, user, showButton }) => {
  const [t] = useTranslation('surveyOverviewRow');

  const { handleArchive, loading, isArchived } = useArchive({ survey, organization });

  const isDisabled = organization?.productVersion?.product === 'FREE' && user.systemRole !== UserSysRole.SysAdmin;

  const ShownArchiveButton = showButton ? ArchiveButton : ArchiveLink;

  if (loading) {
    return <Loading />;
  }

  return (
    <ConditionalWrapper
      condition={isDisabled}
      wrapper={(children) => (
        <Tooltip title={isArchived ? t('dearchiveTooltip') : t('archiveTooltip')}>{children}</Tooltip>
      )}
    >
      <ShownArchiveButton
        data-icom={'btn-archive'}
        weight={500}
        data-cy={`admin-${isArchived ? 'dearchive' : 'archive'}-survey`}
        onClick={handleArchive}
        disabled={isDisabled}
        variant={'outline-primary'}
      >
        {isArchived ? t('dearchive') : t('archive')}
      </ShownArchiveButton>
    </ConditionalWrapper>
  );
};

export default ArchiveSurvey;
