import { formatDate, notEmpty } from '@arnold/common';
import { SurveyInfo } from '../../components/SurveysOverview/RowParts';
import { AppLink } from '../../components/StyledComponents';
import { ProcessStatus } from '../../generated/hooks';
import { SurveyGroupActions } from './SurveyGroupActions';
import { GetDataSourceParams, ProcessSurveyData } from './types';

export const getDataSource = ({
  surveyGroups,
  organization,
  t,
  toSurveyGroupDetail,
}: GetDataSourceParams): ProcessSurveyData[] =>
  surveyGroups.map((surveyGroup) => {
    const label = surveyGroup.topicGroup.name;
    const notstartedReason = surveyGroup.steps
      .map((step) => step.notstartedReason)
      .filter(notEmpty)
      .flat();

    return {
      id: surveyGroup.id,
      topic: <AppLink to={`/processSurvey/${surveyGroup.id}`}>{surveyGroup.name || label}</AppLink>,
      info: (
        <SurveyInfo
          isSequential={true}
          notstartedReason={notstartedReason}
          inLicenceInterval={
            !surveyGroup.steps.some((step) => step.hasOutOfLicenceInterval) ||
            surveyGroup.status === ProcessStatus.Archived
          }
          organization={organization}
          status={surveyGroup.status}
        />
      ),
      sendAt: formatDate(surveyGroup.from),
      responseRate: surveyGroup.statistics?.answered || 0,
      actions: <SurveyGroupActions surveyGroup={surveyGroup} toSurveyGroupDetail={toSurveyGroupDetail} t={t} />,
    };
  });
