import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { HeadingSmall } from '@arnold/common';
import { addMonths, startOfMonth } from 'date-fns';
import BaseModal, { WIDTH } from '../BaseModal';
import { SurveyGroupsQuery } from '../../../generated/hooks';
import { Table } from '../../Table';
import { BaseRespondent, RespondentOnboarding } from '../../OrganizationImportCard';
import { OnboardingData, Step } from './types';
import { getOnboardingColumns, getOnboardingDataSource } from './utils';
import { Progress } from './Progress';
import { Line, TableContainer } from './styled';
import { AddedEmployees } from './AddedEmployees';

interface IProps {
  cancel: () => void;
  onboardings: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'];
  employees: BaseRespondent[];
  onChange: (data: RespondentOnboarding) => void;
  save: () => void;
  steps: Step[];
}

function ChooseOnboardingModal({ cancel, onboardings, employees, onChange, save, steps }: IProps) {
  const [t] = useTranslation('modal');
  const [importOrganizationT] = useTranslation('organizationImportCard');
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [respondentOnboardingSettings, setRespondentOnboardingSettings] = useState<RespondentOnboarding>({});
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const surveyGroupId = onboardings.length === 1 ? onboardings[0].id : undefined;

  const handleAddedOnChange = (email: string, addedOn: string) => {
    const respondentSetting = respondentOnboardingSettings[email] || {};
    const newRespondentSetting = { ...respondentSetting, addedOn };
    handleChange([...new Set([...selectedEmployees, email])], {
      ...respondentOnboardingSettings,
      [email]: newRespondentSetting,
    });
  };

  const handleChange = (selected: string[], data?: RespondentOnboarding) => {
    setSelectedEmployees(selected);
    if (data) {
      // filter selected employees
      const filtered = Object.keys(data)
        .filter((key) => selected.includes(key))
        .reduce((obj, key) => {
          (obj as RespondentOnboarding)[key] = data[key];
          return obj;
        }, {});
      onChange(filtered);
      setRespondentOnboardingSettings(data);
    } else {
      onChange({});
    }
  };

  useEffect(() => {
    const today = new Date();
    const addedOn = (today.getDate() === 1 ? startOfMonth(today) : startOfMonth(addMonths(today, 1))).toISOString();
    const settings: RespondentOnboarding = employees.reduce(
      (acc, employee) => ({ ...acc, [employee.email]: { addedOn, surveyGroupId } }),
      {},
    );
    handleChange(selectedEmployees, settings);

    setIsDisabled(
      selectedEmployees.length === 0
        ? false
        : Object.values(settings).filter((setting) => !setting.surveyGroupId).length !==
            Object.values(settings).filter((setting) => !setting.addedOn).length,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDisabled(
      selectedEmployees.length === 0
        ? false
        : Object.values(respondentOnboardingSettings).filter((setting) => !setting.surveyGroupId).length !==
            Object.values(respondentOnboardingSettings).filter((setting) => !setting.addedOn).length ||
            selectedEmployees.length !==
              Object.values(respondentOnboardingSettings).filter((setting) => setting.addedOn).length,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(respondentOnboardingSettings), selectedEmployees]);

  const handleSurveyChange = (email: string, surveyGroupId?: string, addedOn?: string) => {
    const respondentSetting = respondentOnboardingSettings[email] || {};

    const newRespondentSetting = {
      ...respondentSetting,
      surveyGroupId: surveyGroupId || respondentOnboardingSettings[email].surveyGroupId,
      addedOn: addedOn || respondentOnboardingSettings[email].addedOn,
    };

    handleChange([...new Set([...selectedEmployees, email])], {
      ...respondentOnboardingSettings,
      [email]: newRespondentSetting,
    });
  };

  const toggleEmployee = (email: string | string[]) => {
    if (Array.isArray(email)) {
      handleChange(email, respondentOnboardingSettings);
      if (!email.length) {
        handleChange([], respondentOnboardingSettings);
      }
      return;
    }

    const selectedEmails = selectedEmployees.slice();
    const employeeIndex = selectedEmployees.indexOf(email);
    if (employeeIndex !== -1) {
      selectedEmails.splice(employeeIndex, 1);
    } else {
      selectedEmails.push(email);
    }
    handleChange(selectedEmails, respondentOnboardingSettings);
  };

  return (
    <BaseModal
      title={
        <>
          {importOrganizationT('addToOnboardingTitle')} <Progress steps={steps} step="onboarding" />
        </>
      }
      description={importOrganizationT('addToOnboardingDescription')}
      primaryActionText={
        steps.indexOf('onboarding') === steps.length - 1 ? t('save') : importOrganizationT('continueButton')
      }
      onPrimaryAction={save}
      secondaryActionText={t('cancel')}
      onSecondaryAction={cancel}
      width={WIDTH.AUTO}
      primaryActionDisabled={isDisabled}
      styles={{
        maxWidth: '100%',
        overflow: 'auto',
      }}
    >
      <AddedEmployees count={employees.length} />
      <Line />
      <HeadingSmall>{importOrganizationT('selectOnboarding')}</HeadingSmall>
      <TableContainer>
        <Table<OnboardingData>
          columns={getOnboardingColumns(importOrganizationT)}
          dataSources={getOnboardingDataSource(
            onboardings,
            employees,
            handleAddedOnChange,
            handleSurveyChange,
            importOrganizationT,
            respondentOnboardingSettings,
          )}
          selected={undefined}
          rowSelection={{
            selected: selectedEmployees,
            onChange: toggleEmployee,
          }}
        />
      </TableContainer>
    </BaseModal>
  );
}
export default ChooseOnboardingModal;
