import { FieldProps } from 'formik';
import { ActionMeta } from 'react-select';
import { theme } from '../../theme';
import { selectControlStyles, selectDropdownIndicatorStyles, selectMenuStyles, SelectWrapper } from './SelectWrapper';

export interface Option {
  label: string;
  value: string;
  handling?: 'formik' | 'custom';
  additionalInfo?: Record<string, any>;
}

export interface FormSelectProps extends FieldProps {
  options: Option[];
  isMulti?: boolean;
  onChange?: (opt: Option | Option[]) => void;
  id?: string;
  placeholder?: string;
  isSearchable?: boolean;
  isInvalid?: boolean;
}

export const FormSelect = ({
  field,
  form,
  options,
  isMulti = false,
  onChange: customOnChange,
  placeholder = '',
  isSearchable = false,
  id,
  isInvalid,
}: FormSelectProps) => {
  const onChange = (option: Option | Option[]) => {
    if (Array.isArray(option) ? option[0].handling !== 'custom' : option.handling !== 'custom') {
      form.setFieldValue(
        field.name,
        isMulti ? (option as Option[]).map((item: Option) => item.value) : (option as Option).value,
      );
    }
    customOnChange?.(option);
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : ('' as any);
    }
  };
  const value = getValue();
  return (
    <SelectWrapper
      id={id}
      name={field.name}
      value={value}
      placeholder={placeholder}
      onChange={onChange as (newValue: unknown, actionMeta: ActionMeta<unknown>) => void}
      options={options}
      isMulti={isMulti}
      menuPlacement={'auto'}
      styles={{
        menu: (provided: any, state: any) => ({
          ...provided,
          ...selectMenuStyles,
        }),
        control: (provided, state) => {
          const ourCustom = selectControlStyles(provided, state);
          return {
            ...provided,
            ...ourCustom,
            borderColor: isInvalid ? `${theme.colors.form.error} !important` : ourCustom.borderColor,
          };
        },
        dropdownIndicator: (provided, state) => {
          const ourCustom = selectDropdownIndicatorStyles(provided, state);
          return {
            ...ourCustom,
            color: isInvalid ? `${theme.colors.form.error} !important` : ourCustom.color,
          };
        },
      }}
      isSearchable={isSearchable}
    />
  );
};
