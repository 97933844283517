import styled from '@emotion/styled/macro';
import { FormCheck, FormControl } from 'react-bootstrap';

interface ISectionControlsProps {
  paddingLeft?: string;
  visible?: boolean;
}

export const FormLabelStyled = styled.label`
  font-size: 0.75rem !important;
  margin-bottom: 0.25rem !important;
  color: #767676 !important;
`;

export const CheckBoxLabelStyled = styled.label`
  font-size: 1em !important;
  color: #3f3f3f !important;
`;

const FormInputInfoStyled = styled.div`
  margin-top: 0.25rem;
  font-size: 80%;
  margin-left: 1rem;
  margin-right: 1rem;
`;

export const FormGroupLabel = (props: any) => (
  <FormLabelStyled className={`form-group__label ${props.className ? props.className : ''}`} {...props}>
    {props.children}
  </FormLabelStyled>
);

export const FormControlPassword = styled(FormControl)`
  &.is-invalid {
    background-image: none;
  }
`;

export const FormErrorFeedback = ({ error }: any) => (
  <FormControl.Feedback type="invalid" className="form-text text-danger mr-6 w-auto">
    {error}
  </FormControl.Feedback>
);

export const FormInputInfo = ({ children }: any) => <FormInputInfoStyled>{children}</FormInputInfoStyled>;

export const FormRadioInput = ({
  name,
  dataCy,
  dataIcom,
  className,
  value,
  clicked,
  changed,
  defaultChecked,
  label,
  disabled,
}: any) => (
  <FormCheck>
    <FormCheck.Input
      name={name}
      type="radio"
      value={value}
      onChange={() => changed()}
      className={`form-check__control ${className || ''}`}
      checked={defaultChecked}
      disabled={disabled}
    />
    <CheckBoxLabelStyled
      data-cy={dataCy}
      data-icom={dataIcom}
      className="form-check__label"
      onClick={() => !disabled && clicked()}
    >
      {label}
    </CheckBoxLabelStyled>
  </FormCheck>
);

export const SectionControls = styled.div`
  padding-left: ${({ paddingLeft }: ISectionControlsProps) => paddingLeft || '122px'};
  margin-bottom: 16px;
  opacity: ${({ visible }: ISectionControlsProps) => (visible ? 1 : 0)};
  transition: opacity 0.15s ease-out;
`;

export const HoverWrapper = styled.div`
  &:hover ${SectionControls} {
    opacity: 1;
  }
`;
