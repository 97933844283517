import { Query } from '@apollo/client/react/components';
import { Breadcrumb, HeadingLarge, getFrontendConfigValue } from '@arnold/common';
import { Trans, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router';
import { Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import { getReportAccesses } from '../../graphql/queries';
import {
  getTopicGroupName,
  getTopLevelReportAccess,
  isLoggedUserSysAdmin,
  registerTopLevelReportClick,
} from '../../lib/helpers';
import { ReportAccessQuery, ReportAccessQueryVariables, useLogFeEventMutation } from '../../generated/hooks';
import { ReportAccessTable } from './ReportAccessTable';
import { getShowReportOpenIcon } from './utils';

type IProps = RouteComponentProps<any>;

const ReportAccessScreen = ({
  match: {
    params: { surveyId },
  },
}: IProps) => {
  const { t, i18n } = useTranslation('ReportAccess');
  const [logFeEvent] = useLogFeEventMutation();

  const dependantQueries = [
    {
      query: getReportAccesses,
      variables: { surveyId },
    },
  ];
  return (
    <Query<ReportAccessQuery, ReportAccessQueryVariables> {...dependantQueries[0]}>
      {({ loading, error, data }) => {
        if (loading) {
          return <Loading />;
        }
        if (error) {
          return <></>;
        }
        if (data && data.survey) {
          const { survey } = data;
          const topLevelReportAccess = getTopLevelReportAccess(survey?.reports);
          return (
            <PageWrapper data-cy="admin-page-content">
              <Breadcrumb
                classList={'mt-n6'}
                items={[
                  { title: t('backToOverview'), link: '/overview' },
                  {
                    title: survey.topic ? getTopicGroupName(i18n.language, survey.topic.topicGroup) || '' : '',
                    active: true,
                  },
                ]}
              />
              <HeadingLarge>
                {survey.topic ? getTopicGroupName(i18n.language, survey.topic.topicGroup) : ''}
              </HeadingLarge>
              {topLevelReportAccess && (
                <p>
                  <Trans
                    i18nKey={'ReportAccess:reportAccessInfo'}
                    components={[
                      <a
                        href={`${getFrontendConfigValue('ARNOLD_URL')}/report/${topLevelReportAccess.accessKey}${
                          isLoggedUserSysAdmin() ? '?sysAdmin' : ''
                        }`}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() =>
                          registerTopLevelReportClick(
                            topLevelReportAccess.accessKey,
                            topLevelReportAccess.respondent?.id!,
                            logFeEvent,
                          )
                        }
                        data-icom="top-level-report"
                      />,
                    ]}
                  >
                    Níže naleznete speciálně vygenerované reporty pro vedoucí, které obsahují pouze jejich týmy. Pro
                    jejich distribuci klikněte na Odeslat. Rovněž je možné prohlížet souhrné výsledky za všechny
                    oslovené.
                  </Trans>
                </p>
              )}
              <ReportAccessTable
                dependantQueries={dependantQueries}
                showOpenIcon={getShowReportOpenIcon(survey?.sendAt)}
                surveys={[survey]}
              />
            </PageWrapper>
          );
        }
        return null;
      }}
    </Query>
  );
};

export default ReportAccessScreen;
