import { Checkbox, IThemeProps, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ImportStatistic } from '../../../lib/types';
import BaseModal from '../BaseModal';
import { Step } from './types';

interface IProps {
  name: string;
  statistic: ImportStatistic;
  save: () => void;
  cancel: () => void;
  steps?: Step[];
  setShowSteps: (showSteps: boolean) => void;
  showSteps: boolean;
}

const Description = styled.p`
  list-style-type: none;
  & li {
    margin-left: ${({ theme }: IThemeProps) => theme && theme.spacing.g};
  }
`;

function ImportModal({ name, statistic, save, cancel, steps, setShowSteps, showSteps }: IProps) {
  const [t] = useTranslation('modal');
  const [importOrganizationT] = useTranslation('organizationImportCard');

  return (
    <BaseModal
      title={<Trans i18nKey="modal:importOrgTitle" values={{ name }} components={[<strong>Org name</strong>]} />}
      description={
        <Description>
          <Trans
            i18nKey={'modal:importSuccessText'}
            values={{ ...statistic }}
            components={[
              <Link to="/teams" onClick={save}>
                Týmy
              </Link>,
            ]}
          >
            Zaměstnanci byly úspěšně naimportováni,{{}}, {{}}, {{}}, {{}}
            prohlédnout si je můžete na stránce
            <Link to="/teams" onClick={save}>
              Týmy
            </Link>
          </Trans>
          {steps && steps.length > 0 && statistic.toCreateRespondents.length > 0 && (
            <Checkbox
              selected={showSteps}
              onToggle={() => setShowSteps(!showSteps)}
              text={importOrganizationT('addEmployeesToSurveys')}
              labelStyles={`margin: ${theme.spacing.f} 0 0 0;`}
            />
          )}
        </Description>
      }
      primaryActionText={showSteps ? importOrganizationT('continueButton') : t('modal:save')}
      onPrimaryAction={save}
      secondaryActionText={t('deleteRespondentModal:Cancel')}
      onSecondaryAction={cancel}
    />
  );
}
export default ImportModal;
