import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { Fragment } from 'react';
import { theme } from '../../theme';

export type BreadcrumbItem = {
  link?: string;
  title: React.ReactNode;
  active?: boolean;
  locationState?: object;
};

export interface IBreadcrumbProps {
  classList?: string;
  items: BreadcrumbItem[];
}

interface IBreadcrumbItemProps {
  bold?: boolean;
  emphasized?: boolean;
}

const BreadcrumbItemSpan = styled.span`
  margin-right: ${theme.spacing.e};
  letter-spacing: 0.05em;
  font-weight: ${(props: IBreadcrumbItemProps) => (props.bold || props.emphasized ? 500 : 400)};
  font-size: ${theme.typography.body.small.regular.fontSize};
  line-height: 140%;
  color: ${(props: IBreadcrumbItemProps) =>
    props.emphasized
      ? theme.colors.brand.primary
      : props.bold
        ? theme.colors.backgroundDark
        : theme.colors.textInverted};
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

export const Breadcrumb = ({ items, classList }: IBreadcrumbProps) => {
  return (
    <div className={classList} data-cy="page-breadcrumbs">
      {items.map(({ link, title, active, locationState }, index) => (
        <Fragment key={`${link}-${title}`}>
          {link ? (
            <StyledLink to={locationState ? { pathname: link, state: locationState } : link}>
              <BreadcrumbItemSpan emphasized={true}>{title}</BreadcrumbItemSpan>
            </StyledLink>
          ) : (
            <BreadcrumbItemSpan bold={!!active}>{title}</BreadcrumbItemSpan>
          )}
          {index !== items.length - 1 && <BreadcrumbItemSpan bold={true}>|</BreadcrumbItemSpan>}
        </Fragment>
      ))}
    </div>
  );
};
