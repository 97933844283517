import styled from '@emotion/styled/macro';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { CardBody } from '@arnold/common';
import { ErrorIcon, SuccessIcon } from '.';

interface IProps {
  isError: boolean;
  header: string;
  content: string;
  onCLick?: (event: React.SyntheticEvent<any, MouseEvent>) => void;
  buttonText?: string;
}

const NotificationCard = (props: IProps) => (
  <Card>
    <div className="card-header">
      {props.isError ? <ErrorIcon /> : <SuccessIcon />}
      <Title>{props.header} </Title>
    </div>
    <CardBody>{props.content}</CardBody>
    {props.onCLick && props.buttonText && (
      <div className="card-footer">
        <Button className="btn-round mb-3 btn-block" variant="warning" onClick={props.onCLick}>
          {props.buttonText}
        </Button>
      </div>
    )}
  </Card>
);

const Title = styled.h2(() => ({
  textAlign: 'center',
}));

export default NotificationCard;
