import { IThemeProps } from '@arnold/common';
import styled from '@emotion/styled/macro';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormRadioInput } from '../Common';
import BaseModal from './BaseModal';

interface IProps {
  choiceSeverity: number;
  save: (choiceSeverity: number) => void;
  cancel: () => void;
}

const NeutralLabel = styled.span`
  color: ${({ theme }: IThemeProps) => theme && theme.colors.emotionInformative.default};
`;
const WarningLabel = styled.span`
  color: ${({ theme }: IThemeProps) => theme && theme.colors.emotionWarning.default};
`;

const AlertLabel = styled.span`
  color: ${({ theme }: IThemeProps) => theme && theme.colors.emotionDanger.default};
`;

const FormGroup = styled.div`
  margin-top: ${({ theme }: IThemeProps) => theme && theme.spacing.h};
  margin-bottom: 22px;
  margin-left: ${({ theme }: IThemeProps) => theme && theme.spacing.f};
  & > div:not(:last-child) {
    margin-bottom: ${({ theme }: IThemeProps) => theme && theme.spacing.f} !important;
  }
`;

function ChoiceSettings({ choiceSeverity, save, cancel }: IProps) {
  const [value, setValue] = useState<number>(choiceSeverity);
  const [t] = useTranslation('choiceSettingsModal');
  return (
    <BaseModal
      title={t('Title')}
      description={
        <>
          {t('Description')}
          <FormGroup className="form-check-group">
            <FormRadioInput
              name="choiceSeverity"
              value={0}
              clicked={() => {
                setValue(0);
              }}
              changed={() => {
                setValue(0);
              }}
              dataCy="severity-netural"
              defaultChecked={value === 0}
              label={<NeutralLabel>{t('Neutral')}</NeutralLabel>}
            />
            <FormRadioInput
              name="choiceSeverity"
              value={1}
              clicked={() => {
                setValue(1);
              }}
              changed={() => {
                setValue(1);
              }}
              dataCy="severity-attention"
              defaultChecked={value === 1}
              label={<WarningLabel>{t('Warning')}</WarningLabel>}
            />
            <FormRadioInput
              name="choiceSeverity"
              value={2}
              clicked={() => {
                setValue(2);
              }}
              changed={() => {
                setValue(2);
              }}
              dataCy="severity-special-attention"
              defaultChecked={value > 1}
              label={<AlertLabel>{t('Alert')}</AlertLabel>}
            />
          </FormGroup>
        </>
      }
      primaryActionText={t('Save')}
      primaryBtnDataCy="severity-save"
      onPrimaryAction={() => {
        save(value);
      }}
      secondaryActionText={t('Cancel')}
      onSecondaryAction={cancel}
    />
  );
}
export default ChoiceSettings;
