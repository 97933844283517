import { FC } from 'react';
import { usePath } from '@arnold/common';
import { AppLink } from '../../components/StyledComponents';
import { Survey, SurveyGroup, isSurveyGroup } from '../SurveyArchive/types';
import { TopicGroupTypeCode } from '../../generated/hooks';

type SurveyTopicTitleProps = {
  survey: Survey | SurveyGroup;
  label?: string;
  'data-cy'?: string;
};

export const SurveyTopicTitle: FC<SurveyTopicTitleProps> = ({ survey, label }) => {
  const { archivedOnboardingPath, archivedSurveyDetailPath, archivedProcessSurveyDetailPath } = usePath();
  const isOnboarding = isSurveyGroup(survey) && survey.topicGroup.typeCode === TopicGroupTypeCode.Onboarding;
  const path = isSurveyGroup(survey)
    ? isOnboarding
      ? archivedOnboardingPath
      : archivedProcessSurveyDetailPath
    : archivedSurveyDetailPath;
  return <AppLink to={path.createPath(survey.id)}>{label}</AppLink>;
};
