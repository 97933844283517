import React, { useEffect, useRef, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import styled from '@emotion/styled/macro';
import { IThemeProps } from '@arnold/common';
import { isValidEmail } from '../../lib/helpers';

export enum SemanticCheckType {
  EMAIL = 'email',
  PHONE = 'phone',
  UNIQUE = 'unique',
}

type SemanticCheckOptions = {
  message: string;
  duplicities?: string[];
};

export const FormIcon = styled.div`
  height: 16px;
  width: 16px;
  margin-top: 9px;
  vertical-align: text-top;
  margin-right: 16px;

  & svg path {
    fill: ${({ pathColor }: { pathColor?: string }) => pathColor};
  }
`;

const InputWrapper = styled.div`
  flex: 1;
`;

interface IFormErrorProps extends IThemeProps {
  isWarning?: boolean;
}

const FormError = styled.div`
  margin-left: 16px;
  margin-top: -12px;
  margin-bottom: 12px;
  color: ${({ theme, isWarning }: IFormErrorProps) =>
    theme && (isWarning ? theme.colors.emotionWarning.default : theme.colors.emotionDanger.default)};
  font-size: ${({ theme }: IThemeProps) =>
    theme &&
    theme.typography.body.small &&
    theme.typography.body.small.regular &&
    theme.typography.body.small.regular.fontSize};
`;

interface ITextareaProps extends IThemeProps {
  isInvalid?: boolean;
  hasWarnings?: boolean;
  dashed?: boolean;
}

const Textarea = styled(TextareaAutosize)`
  border-radius: 0.375rem;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 12px;
  resize: none;
  color: ${({ theme }: ITextareaProps) => theme && theme.colors.text.primary};
  background: ${({ theme }: ITextareaProps) => theme && theme.colors.backgroundCover.default};
  background-image: ${({ dashed, theme, hasWarnings }: ITextareaProps) =>
    dashed && theme
      ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${encodeURIComponent(
          hasWarnings ? theme.colors.emotionWarning.default : theme.colors.borderMain.default,
        )}' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`
      : 'none'};
  border: 1px ${({ dashed }: ITextareaProps) => (dashed ? 'none' : 'solid')}
    ${({ theme, isInvalid }: ITextareaProps) =>
      theme && (isInvalid ? theme.colors.emotionDanger.default : theme.colors.borderMain.default)};

  transition: border-color 0.15s ease-out;

  &:focus {
    border-color: ${({ theme, isInvalid }: ITextareaProps) => theme && !isInvalid && theme.colors.borderMain.focused};
    outline: 0;
  }
  &:hover {
    background-image: ${({ dashed, theme, hasWarnings }: ITextareaProps) =>
      dashed && theme
        ? `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='${encodeURIComponent(
            hasWarnings ? theme.colors.emotionWarning.default : theme.colors.borderMain.hover,
          )}' stroke-width='2' stroke-dasharray='10%2c 10' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`
        : 'none'};
  }
`;

export interface IFormInputProps {
  value: string;
  placeholder?: string;
  disabled?: boolean;
  dashed?: boolean;
  onChange?: (newValue: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  focused?: boolean;
  required?: boolean;
  semanticCheck?: { [key in SemanticCheckType]?: SemanticCheckOptions };
  warnings?: string[];
  requiredErrorMessage?: string;
}

const FormInput: React.FC<IFormInputProps> = ({
  value,
  onChange,
  dashed,
  focused,
  onFocus,
  onBlur,
  placeholder,
  disabled,
  required,
  semanticCheck,
  warnings,
  requiredErrorMessage,
}) => {
  const [errors, setErrors] = useState<string[]>([]);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const validate = (newValue: string, requiredOnly = false) => {
    const errorsForNewValue: string[] = [];
    if (required && !newValue.trim()) {
      errorsForNewValue.push(requiredErrorMessage || '');
    }

    if (!requiredOnly && semanticCheck) {
      if (semanticCheck[SemanticCheckType.EMAIL] && !isValidEmail(newValue.trim())) {
        errorsForNewValue.push(semanticCheck[SemanticCheckType.EMAIL]?.message || '');
      }

      if (
        semanticCheck[SemanticCheckType.UNIQUE] &&
        semanticCheck[SemanticCheckType.UNIQUE]?.duplicities?.length &&
        semanticCheck[SemanticCheckType.UNIQUE]?.duplicities?.includes(newValue.trim())
      ) {
        errorsForNewValue.push(semanticCheck[SemanticCheckType.UNIQUE]?.message || '');
      }
    }
    setErrors(errorsForNewValue);
  };

  const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
    validate(event.target.value);
    onBlur ? onBlur() : onFocus && onFocus();
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    if (onChange) {
      onChange(newValue);
    }
    if (errors.length) {
      validate(newValue);
    }
  };

  useEffect(() => {
    if (focused) {
      inputRef.current?.focus();
    }
  }, [focused]);

  useEffect(() => {
    if (value || errors.length) {
      validate(value, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (dashed) {
      setErrors([]);
    }
  }, [dashed]);
  return (
    <InputWrapper>
      <Textarea
        ref={inputRef}
        style={{ boxSizing: 'border-box', width: '100%' }}
        value={value}
        disabled={disabled}
        placeholder={placeholder || ''}
        isInvalid={!!errors.length}
        hasWarnings={!!(warnings && warnings.length)}
        dashed={dashed}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={handleBlur}
      />
      {!!errors.length && <FormError>{errors.find((e) => e === requiredErrorMessage) || errors[0]}</FormError>}
      {(warnings || []).map((error) => (
        <FormError isWarning={true} key={error}>
          {error}
        </FormError>
      ))}
    </InputWrapper>
  );
};

export default FormInput;
