const USER_KEY = '@Arnold/SelfCareStorage';

type Store = {
  detectedlanguage: string | null;
};

class Storage {
  private store: Store = {
    detectedlanguage: null,
  };
  constructor() {
    const storage = localStorage.getItem(USER_KEY);
    if (storage) {
      this.store = JSON.parse(storage);
    }
  }

  setDetectedLanguage(lang: string) {
    this.store.detectedlanguage = lang;
    this.saveStore();
  }

  getDetectedLanguage() {
    return this.store.detectedlanguage;
  }

  private saveStore() {
    localStorage.setItem(USER_KEY, JSON.stringify(this.store));
  }
}

const storage = new Storage();
export default storage;
