import styled from '@emotion/styled';
import { theme } from '../../theme';

export type FeelingValueProps = {
  value: number;
  smaller?: boolean;
};
export const FeelingValue = styled.span<FeelingValueProps>`
  display: flex;
  height: ${({ smaller }) => (smaller ? '16px' : '28px')};
  width: ${({ smaller }) => (smaller ? '16px' : '28px')};
  justify-content: center;
  align-items: center;
  font-family: ${theme.typography.body.medium.regular.family};
  font-size: ${({ smaller }) =>
    smaller ? theme.typography.body.small.regular.fontSize : theme.typography.body.medium.regular.fontSize};
  font-weight: ${theme.typography.body.medium.regular.fontWeight};
  border-radius: 50%;
  background: ${({ value }) =>
    value < 3
      ? theme.colors.emotionDanger.backgroundHover
      : value < 6
        ? theme.colors.emotionWarning.backgroundHover
        : theme.colors.emotionSuccess.background};
  color: ${({ value }) =>
    value < 3
      ? theme.colors.emotionDanger.default
      : value < 6
        ? theme.colors.emotionWarning.hover
        : theme.colors.emotionSuccess.hover};
`;
