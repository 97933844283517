import { ErrorWarningIcon, TableColumn, Tooltip, theme } from '@arnold/common';
import { removeAccents } from '@arnold/core';
import styled from '@emotion/styled';
import { addDays } from 'date-fns';
import { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { TFunction } from 'react-i18next';
import { SendAgainButton } from '../../components/GeneralProcessSurveyDetail/ProcessSurveyPatricipants/ParticipantDetail/getDataSource';
import { Table } from '../../components/Table';
import { SurveyQuery } from '../../generated/hooks';
import { getFullName, goToResultsWithRespondentView, goToResultsWithTeamFiter, showResult } from '../../lib/helpers';
import { SurveyDetailData } from './types';

export const getDataSource = (
  surveyTeamResults: NonNullable<NonNullable<SurveyQuery['survey']>['teamsResultInfo']>,
  channels: NonNullable<SurveyQuery['survey']>['channels'],
  surveyId: string,
  surveyEndsAt: string,
  searchQuery: string,
  isSurveyOngoing: boolean,
  isAnonymityEnabled: boolean,
  t: TFunction,
  emptyReportAccess?: string,
): SurveyDetailData[] => {
  return surveyTeamResults
    .sort((a, b) => (a?.team?.name || '').toLowerCase().localeCompare((b?.team?.name || '').toLowerCase()))
    .map((item) => {
      const teamMemberData = item?.teamMemberIdsFromChannels
        ?.map((memberId) => {
          const channel = channels?.find((c) => c.respondent?.id === memberId);
          if (!channel) return null!; // not all team members are in the survey
          return {
            fullName: getFullName(channel.respondent?.firstname, channel.respondent?.surname),
            respondentId: memberId,
            lastRemindedAt: channel.lastRemindedAt,
            metricQuestionWarning: !isAnonymityEnabled && channel.alert,
            showOpenResultsAction: !isAnonymityEnabled && channel.isFinished && channel.answered,
            showResendAction: !isAnonymityEnabled && !channel.isFinished && isSurveyOngoing,
          };
        })
        .filter((item) => item);
      return {
        team: item?.team!,
        resultInfo: item?.resultInfo!,
        teamMemberData,
      };
    })
    .map((item) => {
      const searchWordsMatchTeamOrLeader = searchQuery.split(' ').every((word) => {
        const sanitizedWord = removeAccents(word).toLowerCase();
        return (
          removeAccents(item?.team.name || t('teams:withoutLeader'))
            .toLowerCase()
            .includes(sanitizedWord) ||
          removeAccents(getFullName(item?.team.leader?.firstname, item?.team.leader?.surname) || '')
            .toLowerCase()
            .includes(sanitizedWord)
        );
      });
      const searchWordsMatchTeamMembers = item.teamMemberData?.some((member) =>
        searchQuery.split(' ').every((word) => {
          const sanitizedWord = removeAccents(word).toLowerCase();
          return removeAccents(member.fullName || '')
            .toLowerCase()
            .includes(sanitizedWord);
        }),
      );
      return {
        ...item,
        searchWordsMatchTeamOrLeader,
        searchWordsMatchTeamMembers,
      };
    })
    .filter((item) => item.searchWordsMatchTeamOrLeader || item.searchWordsMatchTeamMembers)
    .map((item) => ({
      id: item?.team.id!,
      team: (
        <Highlighter
          highlightStyle={{
            fontWeight: '700',
            backgroundColor: 'transparent',
            color: theme.colorPrimary,
            padding: 0,
          }}
          textToHighlight={
            item?.team.name ||
            t('withoutLeader', {
              ns: 'teams',
            })
          }
          searchWords={searchQuery.split(' ')}
          sanitize={removeAccents}
        />
      ),
      teamLeader: (
        <Highlighter
          highlightStyle={{
            fontWeight: '700',
            backgroundColor: 'transparent',
            padding: 0,
          }}
          textToHighlight={getFullName(item?.team.leader?.firstname, item?.team.leader?.surname) || '-'}
          searchWords={searchQuery.split(' ')}
          sanitize={removeAccents}
        />
      ),
      answered: showResult(item?.resultInfo.answered, item?.resultInfo.total),
      anonymous: showResult(item?.resultInfo.anonymous, item?.resultInfo.answered),
      results: (
        <Button
          onClick={() => {
            if (emptyReportAccess) {
              goToResultsWithTeamFiter(item?.team.id!, emptyReportAccess);
            }
          }}
          variant={'outline-primary'}
          size="sm"
          disabled={!emptyReportAccess}
        >
          {t('surveyOverviewRow:summaryReport')}
        </Button>
      ),
      teamMembersDetail: (
        <StyledTable<teamMemberDetailData>
          columns={getMemberDetailColumns(t)}
          dataSources={getMemberDetailDataSource(
            item?.teamMemberData || [],
            surveyId,
            surveyEndsAt,
            searchQuery,
            t,
            emptyReportAccess,
          )}
          selected={undefined}
          thin
        />
      ),
      expandTeamMembers: (searchQuery.trim().length && item?.searchWordsMatchTeamMembers) || false,
    }));
};

const StyledTable = styled(Table)`
  @media (min-width: ${theme.breakpoint.medium}) {
    width: 70%;
  }
  tr {
    background-color: transparent;
    height: ${theme.spacing.j};
  }
` as typeof Table;

type teamMemberDetailData = {
  name: ReactNode;
  metricQuestionWarning: ReactNode;
  actions: ReactNode;
};

const getMemberDetailColumns = (t: TFunction): TableColumn<teamMemberDetailData>[] => {
  return [
    {
      key: 'name',
      title: t('surveyGroup:surveyParticipant'),
      dataIndex: 'name',
    },
    {
      key: 'metricQuestionWarning',
      title: '',
      dataIndex: 'metricQuestionWarning',
    },
    {
      key: 'actions',
      title: '',
      dataIndex: 'actions',
    },
  ];
};

const getMemberDetailDataSource = (
  memberData: {
    fullName: string | null;
    respondentId: string;
    lastRemindedAt: string | null;
    metricQuestionWarning: boolean;
    showOpenResultsAction: boolean;
    showResendAction: boolean;
  }[],
  surveyId: string,
  surveyEndsAt: string,
  searchQuery: string,
  t: TFunction,
  reportAccess?: string,
): teamMemberDetailData[] =>
  memberData
    .sort((a, b) => (a.fullName || '').localeCompare(b.fullName || ''))
    .map((item) => ({
      name: (
        <Highlighter
          highlightStyle={{
            fontWeight: '700',
            backgroundColor: 'transparent',
            padding: 0,
          }}
          textToHighlight={item?.fullName || ''}
          searchWords={searchQuery.split(' ')}
          sanitize={removeAccents}
        />
      ),
      metricQuestionWarning: item?.metricQuestionWarning ? (
        <Tooltip title={t('choiceSettingsModal:Warning')}>
          <StyledWarningIcon />
        </Tooltip>
      ) : (
        <></>
      ),
      actions: (
        <div>
          {item.showOpenResultsAction && (
            <Button
              variant={'outline-primary'}
              size="sm"
              className="mt-0 mb-0"
              disabled={!reportAccess}
              onClick={() => {
                if (reportAccess) {
                  goToResultsWithRespondentView(reportAccess, item.respondentId);
                }
              }}
            >
              {t('surveyGroup:answers')}
            </Button>
          )}
          {item.showResendAction && (
            <div className="ml-6">
              <SendAgainButton
                notAnswered={true}
                todayWasSent={
                  item.lastRemindedAt
                    ? addDays(new Date(item.lastRemindedAt), 1).getTime() >= new Date().getTime()
                    : false
                }
                missingChannel={false}
                anonymous={false}
                surveyEnd={new Date(surveyEndsAt)}
                respondentId={item.respondentId}
                surveyId={surveyId}
              />
            </div>
          )}
        </div>
      ),
    }));

const StyledWarningIcon = styled(ErrorWarningIcon)`
  path {
    fill: ${theme.colors.chart.alert[0]};
  }
`;
