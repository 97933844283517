import { BodyMedium, Box, createEventData, pushToDataLayer, theme } from '@arnold/common';
import { ProductCode } from '@arnold/core';
import styled from '@emotion/styled/macro';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trans, useTranslation } from 'react-i18next';
import {
  ActiveRespondentsQuery,
  OrganizationQuery,
  SurveyGroupWithGroupedRespondentsQuery,
  SurveyGroupsQuery,
  SurveyStatus,
  UserQuery,
} from '../../generated/hooks';
import { RedText } from '../StyledComponents';
import ee from '../../lib/eventEmitter';
import RespondentGroup from './RespondentGroup';
import { getDataSource } from './SurveyGroupSurveysPreviewTable';

type NewSubGroupProps = {
  activeRespondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'];
  alreadySelectedRespondents: string[];
  addRespondents: (respondentId: string[], addedOn: Date, runOngoingSteps: string[]) => Promise<boolean>;
  topicGroup: NonNullable<SurveyGroupsQuery['organization']>['surveyGroups'][0]['topicGroup'];
  selectedRespondents: string[];
  setSelectedRespondents: (changeFn: (respondentId: string[]) => string[]) => void;
  user: UserQuery['user'];
  hasAnotherRespondentGroup: boolean;
  teams?: NonNullable<ActiveRespondentsQuery['user']['organization']>['teams'];
  respondentGroups?: NonNullable<SurveyGroupWithGroupedRespondentsQuery['surveyGroup']>['groupedRespondents'];
  organization: UserQuery['user']['organization'] | OrganizationQuery['organization'] | null;
};

const AddGroup = styled(Button)`
  background-color: inherit;
  text-transform: none;
  color: #04b7ef;
`;

enum NewGroupState {
  INIT,
  SELECT_PEOPLE,
  SELECT_DATE,
}

const NewSubGroup = ({
  alreadySelectedRespondents,
  activeRespondents,
  addRespondents,
  user,
  selectedRespondents,
  setSelectedRespondents,
  hasAnotherRespondentGroup,
  teams,
  topicGroup,
  respondentGroups,
  organization,
}: NewSubGroupProps) => {
  const { t, i18n } = useTranslation('surveyGroup');
  const [newGroupState, setNewGroupState] = useState(
    alreadySelectedRespondents.length ? NewGroupState.INIT : NewGroupState.SELECT_PEOPLE,
  );

  const displaySelectPeople = () => setNewGroupState(NewGroupState.SELECT_PEOPLE);

  const addRespondentsFunc = async (
    newRespondents: NonNullable<ActiveRespondentsQuery['user']['organization']>['activeRespondents'],
  ) => {
    setSelectedRespondents(() => newRespondents.map((respondent) => respondent.id));
  };
  const removeRespondent = (respondentIds: string[]) => {
    setSelectedRespondents((respondents) => respondents.filter((rId) => !respondentIds.includes(rId)));
  };
  const onAddedOnSubmit = async (runOngoingSteps: string[], addedOn: Date) => {
    const wasSuccessful = await addRespondents(selectedRespondents, addedOn, runOngoingSteps);
    if (wasSuccessful) {
      setSelectedRespondents(() => []);
      setNewGroupState(NewGroupState.INIT);
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.create-survey-group-success',
        ...createEventData('survey', 'createSurveyGroupSuccess', 'survey create survey group success'),
      });
    } else {
      pushToDataLayer({
        userId: user.id,
        orgId: organization?.id,
        event: 'ux.create-survey-group-error',
        ...createEventData('survey', 'createSurveyGroupError', 'survey create survey group error'),
      });
    }
  };
  const deletedRespondents = respondentGroups
    ? respondentGroups.reduce((acc: string[], group) => {
        return [
          ...acc,
          ...group.respondents
            .filter((respondent) => group.deletedRespondents?.includes(respondent.id))
            .map((r) => r.id),
        ];
      }, [])
    : [];

  const numberOfUsedParticipants = organization?.activeRespondentsInCurrentPeriodCount ?? 0;
  const numberOfMaxParticipants = organization?.productVersion?.licenceCount ?? 0;
  const isDisabled =
    organization?.productVersion?.product === ProductCode.Teamio_Arnold &&
    numberOfUsedParticipants >= numberOfMaxParticipants;
  if (newGroupState === NewGroupState.INIT && hasAnotherRespondentGroup) {
    return (
      <Box className="mb-3">
        {newGroupState === NewGroupState.INIT && (
          <AddGroup
            variant="primary"
            className={'mr-6 mb-5 btn-empty'}
            type="submit"
            onClick={displaySelectPeople}
            disabled={isDisabled}
          >
            {t('addRespondents')}
          </AddGroup>
        )}
        {isDisabled && (
          <BodyMedium margin={`${theme.spacing.f} 0 0`} className="w-100">
            <RedText>
              <Trans
                i18nKey={`teamioOnb:usageInfo`}
                values={{ total: numberOfMaxParticipants, used: numberOfUsedParticipants }}
                components={[<strong />]}
              />{' '}
              {t('teamioOnb:usageExceeded')}{' '}
            </RedText>
            <Trans i18nKey={`teamioOnb:orderMore`} components={[<a href={'/settings/order'} />]} />
          </BodyMedium>
        )}
      </Box>
    );
  }

  return (
    <>
      <RespondentGroup
        group={{
          __typename: 'GroupedRespondents',
          respondents: activeRespondents.filter((r) => selectedRespondents.includes(r.id)),
          addedOn: new Date(),
          surveys: [],
        }}
        forceDisplaySubmit={true}
        submitButtonText={t('surveyGroup:schedule')}
        addedOn={null}
        topicGroup={topicGroup}
        alreadySelectedRespondents={alreadySelectedRespondents}
        removeRespondent={removeRespondent}
        onSubmit={(addedOn) =>
          ee.emitAddSGRespondentToOngoingSurveys(
            onAddedOnSubmit,
            addedOn,
            getDataSource(topicGroup, addedOn!, i18n.language, t, organization?.productVersionHistory).filter(
              (row) => row.rawStatus === SurveyStatus.Ongoing,
            ),
            selectedRespondents,
          )
        }
        activeRespondents={activeRespondents}
        addRespondents={addRespondentsFunc}
        teams={teams}
        allowAddNew
        deletedRespondents={deletedRespondents}
        respondentGroups={respondentGroups}
        productHistory={organization?.productVersionHistory ?? []}
        remainingCount={
          organization?.productVersion?.product === ProductCode.Teamio_Arnold
            ? numberOfMaxParticipants - numberOfUsedParticipants - selectedRespondents.length
            : undefined
        }
      />
    </>
  );
};

export default NewSubGroup;
