import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { BodySmall, SearchInput, theme } from '@arnold/common';
import { Table } from '../../components/Table/Table';
import { SurveyQuery } from '../../generated/hooks';
import { SurveyDetailData } from './types';
import { getDataSource } from './getDataSource';

type SurveyDetailTableProps = {
  surveyTeamResults?: NonNullable<SurveyQuery['survey']>['teamsResultInfo'];
  emptyReportAccess?: string;
  channels: NonNullable<SurveyQuery['survey']>['channels'];
  isSurveyOngoing: boolean;
  surveyId: string;
  surveyEndsAt: string;
  isAnonymityEnabled: boolean;
};

export const SurveyDetailTable: FC<SurveyDetailTableProps> = ({
  surveyTeamResults,
  emptyReportAccess,
  channels,
  isSurveyOngoing,
  surveyId,
  surveyEndsAt,
  isAnonymityEnabled,
}) => {
  const [t] = useTranslation('survey');
  const [searchQuery, setSearchQuery] = useState('');
  const handleChangeSearchQuery = (value: string) => setSearchQuery(value);

  return (
    <>
      <BodySmall color={theme.colors.text.secondary} margin={`0 0 ${theme.spacing.e} 0`}>
        {t('summaryResultsInfo')}
      </BodySmall>
      <div className="mt-6 mb-6" style={{ maxWidth: 250 }}>
        <SearchInput onChange={handleChangeSearchQuery} value={searchQuery} delay={500} name="respondents-search" />
      </div>
      <Table<SurveyDetailData>
        columns={[
          {
            key: 'team',
            title: t('team'),
            dataIndex: 'team',
          },
          {
            key: 'teamLeader',
            title: t('teamLeader'),
            dataIndex: 'teamLeader',
          },
          {
            key: 'answered',
            title: t('responseRate'),
            dataIndex: 'answered',
          },
          {
            key: 'anonymous',
            title: t('anonymous'),
            dataIndex: 'anonymous',
          },
          {
            key: 'results',
            title: undefined,
            dataIndex: 'results',
          },
        ]}
        dataSources={
          surveyTeamResults
            ? getDataSource(
                surveyTeamResults,
                channels,
                surveyId,
                surveyEndsAt,
                searchQuery,
                isSurveyOngoing,
                isAnonymityEnabled,
                t,
                emptyReportAccess,
              )
            : []
        }
        selected={undefined}
        renderDetailPanel={channels?.length ? (rowData) => rowData.teamMembersDetail : undefined}
        expandDetailPanel={(rowData) => rowData.expandTeamMembers}
      />
    </>
  );
};
