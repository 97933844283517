import { FC } from 'react';
import { RouteComponentProps, useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { TopicEditor } from '../../components/TopicEditor';
import Loading from '../../components/Loading';
import { ErrorPage } from '../../components/StyledComponents';
import { IsAccessTokenValidQuery, OrganizationQuery, useTopicDetailQuery } from '../../generated/hooks';

interface IParams {
  languageId?: string;
  topicGroupId: string;
}

interface IProps extends RouteComponentProps<IParams> {
  user: NonNullable<IsAccessTokenValidQuery['isAccessTokenValid']['user']>;
  organization: OrganizationQuery['organization'];
}

export const SvenEditorScreen: FC<IProps> = ({ match, user, organization }) => {
  const { t } = useTranslation('common');
  const history = useHistory();
  const topicGroupId = match.params.topicGroupId;
  const topicIdFromParams = new URLSearchParams(history.location.search).get('topicId') || undefined;
  const languageId = match.params.languageId;

  const { loading, error, data } = useTopicDetailQuery({
    variables: {
      topicGroupId,
      topicId: topicIdFromParams,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    return <ErrorPage>{t('loadError')}</ErrorPage>;
  }

  return (
    <TopicEditor
      topicDetail={data.topicDetail}
      topicGroup={data.topicDetail.topicGroup}
      organization={organization}
      user={user}
      languageCodeOrId={languageId}
    />
  );
};
