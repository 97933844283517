import { theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '../../components/StyledComponents';

const TextWrap = styled.div`
  margin-bottom: ${theme.spacing.h};
`;

interface IProps {
  lang?: string;
  email: string;
}

const RecoverPasswordSuccess = ({ lang, email }: IProps) => {
  const [t] = useTranslation('recoverPassword');
  const history = useHistory();

  const handleClickLoginButton = () => {
    history.push(`/login${lang ? `/${lang}` : ''}`);
  };

  return (
    <>
      <TextWrap>
        <Trans i18nKey="recoverPassword:resetPasswordNextSteps" values={{ email }}>
          Na e-mail <strong>{email}</strong> jsem ti poslal odkaz k vytvoření nového hesla.
        </Trans>
      </TextWrap>
      <PrimaryButton variant="primary" type="button" onClick={handleClickLoginButton}>
        {t('loginForm:signIn')}
      </PrimaryButton>
    </>
  );
};

export default RecoverPasswordSuccess;
