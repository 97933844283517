import { notEmpty } from '@arnold/common';
import { clone } from 'ramda';
import { TFunction } from 'react-i18next';
import { Position } from 'rete-react-plugin';
import { QuestionType } from '../../generated/hooks';
import { COMMENT_TYPE } from './constants';
import { getBiggestOrderFromNodes } from './contextMenu';
import { NodeData } from './dataNode';
import { CustomNodeEditor, processData, updatePositionInNodeData } from './rete';
import { generateIndex, updateNodeDataBasedOnConnections } from './utils';

const COPIED_NODES_KEY = 'topicEditorCopiedNodes';
const COPIED_NODES_TIME_KEY = 'topicEditorCopiedNodesTime';
const MAX_COPIED_NODES_AGE = 1 * 60 * 60 * 1000; // 1 hour

export const addKeydownEventListenerForCopyAndPasteAndDelete = (
  editor: CustomNodeEditor,
  t: TFunction,
  onCommentDelete: (id: string) => void,
) => {
  const keydownListener = (e: KeyboardEvent) => {
    if (e.key === 'Delete') {
      deleteSelectedNodes(editor, onCommentDelete);
    } else if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
      copySelectedNodes(editor);
    } else if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
      if (
        e.target instanceof HTMLInputElement ||
        e.target instanceof HTMLTextAreaElement ||
        (e.target && (e.target as HTMLElement).hasAttribute('contenteditable'))
      )
        // if the target is an input, textarea or contenteditable div, do not paste
        return;
      pasteSelectedNodes(editor, t, editor.areaPlugin.area.pointer);
    }
  };
  document.addEventListener('keydown', keydownListener);
  editor.removeEventListernersFunctions.push(() => {
    document.removeEventListener('keydown', keydownListener);
  });
};

export const hasCoppiedNodes = () => {
  const nodesDate = localStorage.getItem(COPIED_NODES_TIME_KEY);
  const nodesAge = nodesDate ? Date.now() - parseInt(nodesDate, 10) : 0;
  return localStorage.getItem(COPIED_NODES_KEY) != null && nodesAge <= MAX_COPIED_NODES_AGE;
};

export const copySelectedNodes = (editor: CustomNodeEditor, saveToLocalStorage = true) => {
  const selectedNodes = [...editor.customNodeSelector.entities.values()];
  if (selectedNodes.length === 0) return;
  updateNodeDataBasedOnConnections(editor.getNodes(), editor.getConnections());
  updatePositionInNodeData(editor, editor.getNodes());
  const nodesData = selectedNodes
    .map((selectedNode) => {
      const node = editor.getNode(selectedNode.id);
      return node?.data;
    })
    .filter(notEmpty)
    .filter((node) => node.type !== COMMENT_TYPE);
  if (saveToLocalStorage) {
    localStorage.setItem(COPIED_NODES_KEY, JSON.stringify(nodesData));
    localStorage.setItem(COPIED_NODES_TIME_KEY, Date.now().toString());
  }
  return clone(nodesData);
};

export const pasteSelectedNodes = async (
  editor: CustomNodeEditor,
  t: TFunction,
  position?: Position,
  nodes?: NodeData[],
) => {
  let nodesData: NodeData[];
  if (nodes) {
    nodesData = nodes;
  } else {
    const localStorageNodesData = localStorage.getItem(COPIED_NODES_KEY);
    const nodesDate = localStorage.getItem(COPIED_NODES_TIME_KEY);
    const nodesAge = nodesDate ? Date.now() - parseInt(nodesDate, 10) : 0;
    if (!localStorageNodesData || nodesAge > MAX_COPIED_NODES_AGE) return;
    nodesData = JSON.parse(localStorageNodesData) as NodeData[];
    if (nodesData.length === 0) return localStorage.removeItem(COPIED_NODES_KEY);
  }
  let biggestReportOrder = getBiggestOrderFromNodes(editor.getNodes());
  nodesData.forEach((node) => {
    node.input = true;
    if (node.type === QuestionType.Ending) node.type = QuestionType.Tell;
    if (Object.values(node.outputs).length === 0) {
      // if there are no outputs, we need to add at least one (copy if end node)
      node.outputs = { 'opt-0': null };
    }
    node.reportOrder = ++biggestReportOrder;
    const oldIdx = node.questionIndex;
    node.questionIndex = generateIndex();
    // Change references to the new index in other nodes
    nodesData.forEach((n) => {
      n.outputs &&
        Object.entries(n.outputs).forEach(([outputName, outputConnectedNodeQuestionIndex]) => {
          if (outputConnectedNodeQuestionIndex === oldIdx) {
            n.outputs[outputName as `opt-${number}`] = node.questionIndex;
          }
        });
    });
  });

  const nodeQIdxes = nodesData.map((node) => node.questionIndex);
  nodesData.forEach((node) => {
    // if node has some output that is not in the copied nodes, we need to remove it
    Object.entries(node.outputs).forEach(([outputName, outputConnectedNodeQuestionIndex]) => {
      if (outputConnectedNodeQuestionIndex && !nodeQIdxes.includes(outputConnectedNodeQuestionIndex)) {
        node.outputs[outputName as `opt-${number}`] = null;
      }
    });
  });

  const middleNode = nodesData[Math.floor(nodesData.length / 2)];
  const middleNodeInitialPosition = { x: middleNode.position[0]!, y: middleNode.position[1]! };
  const theMostTopY = nodesData.reduce((acc, node) => {
    if (node.position[1]! < acc) {
      return node.position[1]!;
    }
    return acc;
  }, nodesData[0].position[1]!);
  const theMostBottomY = nodesData.reduce((acc, node) => {
    if (node.position[1]! > acc) {
      return node.position[1]!;
    }
    return acc;
  }, nodesData[0].position[1]!);
  const heightDifferenceBetweenCopiedNodes = theMostBottomY - theMostTopY;

  const desiredPosition = position ?? {
    x: middleNodeInitialPosition.x,
    y: middleNodeInitialPosition.y + 300 + heightDifferenceBetweenCopiedNodes,
  };
  // Move all nodes to the desired position but keep the relative position between them
  nodesData.forEach((node) => {
    const positionDiffFromMiddleNode = {
      x: node.position[0]! - middleNodeInitialPosition.x,
      y: node.position[1]! - middleNodeInitialPosition.y,
    };
    node.position = [
      desiredPosition.x + positionDiffFromMiddleNode.x,
      desiredPosition.y + positionDiffFromMiddleNode.y,
    ];
  });

  await processData(nodesData, editor, t, undefined, true);

  // Select the pasted nodes
  editor.customNodeSelector.unselectAll();
  const reteNodes = editor.getNodes().filter((node) => nodeQIdxes.includes(node.data.questionIndex));
  reteNodes.forEach((node) => {
    editor.nodeSelectorInterface.select(node.id, true);
  });
};

export const deleteSelectedNodes = async (editor: CustomNodeEditor, onCommentDelete: (id: string) => void) => {
  const selectedNodes = editor.customNodeSelector.entities.values();
  const nodes = Array.from(selectedNodes)
    .map((selectedNode) => editor.getNode(selectedNode.id))
    .filter(notEmpty);
  // Filter out starting node and ending node
  const filteredNodes = nodes.filter(
    (node) => (node.data.type !== QuestionType.Ending && node.data.input !== false) || node.data.type === COMMENT_TYPE,
  );
  const nodeIds = filteredNodes.map((node) => node.id);
  const connections = editor.getConnections().filter((connection) => {
    return nodeIds.includes(connection.source) || nodeIds.includes(connection.target);
  });
  // prevent updating data during deletion
  editor.loaded = false;
  for (const connection of connections) {
    await editor.removeConnection(connection.id);
  }
  for (const node of filteredNodes) {
    await editor.removeNode(node.id);
    if (node.data.type === COMMENT_TYPE && node.data.commentGroupId) {
      onCommentDelete(node.data.commentGroupId);
    }
  }
  editor.loaded = true;
  editor.customNodeSelector.unselectAll();
  updateNodeDataBasedOnConnections(editor.getNodes(), editor.getConnections());
};
