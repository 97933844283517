import { HeadingLarge, Tabs, theme } from '@arnold/common';
import styled from '@emotion/styled/macro';
import { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Loading } from '../../components';
import { PageWrapper } from '../../components/Common';
import { NoSurveysInfo } from '../../components/NoSurveysInfo';
import { OrganizationQuery, useOrganizationSurveysQuery } from '../../generated/hooks';
import { SurveyTable } from './SurveyTable';
import { PATHS } from './types';

const ButtonContainer = styled(Button)`
  margin-top: 0;
  margin-bottom: ${theme.spacing.f};
`;

interface IProps {
  organization: NonNullable<OrganizationQuery['organization']>;
}

export const SurveysScreen: FC<IProps> = ({ organization }) => {
  const [t, i18n] = useTranslation('surveysOverview');
  const { status } = useParams<{ status?: string }>();
  const history = useHistory();

  const { data, loading, error } = useOrganizationSurveysQuery({
    variables: { languageCode: i18n.language, organizationId: organization.id },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <></>;
  }

  const surveys = data?.organizationSurveys.filter((s) => s.status.toLowerCase() === (status || PATHS.ONGOING));

  const isWithoutSurveys = data?.organizationSurveys.length === 0;

  return (
    <PageWrapper data-cy="admin-page-content">
      <HeadingLarge data-icom={'heading-standalone-surveys'}>{t('standaloneSurveys')}</HeadingLarge>
      <ButtonContainer
        onClick={() => history.push('/conversationSubjects')}
        data-icom={'btn-create-new-survey'}
        data-cy="btn-create-new-survey"
      >
        {t('newSurveyFromTemplate')}
      </ButtonContainer>

      <Tabs
        items={Object.values(PATHS).map((s) => ({
          title: t(s),
          link: `/surveys/${s}`,
          active: (!status && s === PATHS.ONGOING) || s === status,
          icomTag: `tab-surveys-${s}`,
        }))}
        theme={theme}
      />
      {surveys && surveys.length > 0 ? (
        <SurveyTable organization={organization} surveys={surveys} pathStatus={status} />
      ) : (
        <NoSurveysInfo
          status={status ? (status as PATHS) : PATHS.ONGOING}
          isWithoutSurveys={isWithoutSurveys}
          dataCy={isWithoutSurveys ? 'admin-before-survey' : undefined}
        />
      )}
    </PageWrapper>
  );
};
