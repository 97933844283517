import React from 'react';
import { format } from 'date-fns';
import { BodyMedium, ConditionalWrapper, theme, Tooltip } from '@arnold/common';
import { Trans, useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getLocale, ProductCode } from '@arnold/core';
import { ReactComponent as InfoIcon } from '../../assets/images/InfoIcon.svg';
import { EDITION } from '../../lib/common';
import { OrganizationQuery } from '../../generated/hooks';
import { RedText } from '../../components/StyledComponents';

interface IProps {
  maxParticipants?: number;
  usedParticipants: number;
  periodEnd: string;
  activeEdition: NonNullable<OrganizationQuery['organization']>['productVersion'];
}

const BoldSpan = styled.span`
  font-weight: ${theme.typography.body.medium?.bold};
`;

const CapacityInfo = styled.div`
  display: flex;
`;

const StyledTooltip = styled(Tooltip)`
  padding-top: ${theme.spacing.c};
  margin-left: ${theme.spacing.d};
  display: flex !important;
  align-items: center;
`;

const SurveyParticipants = ({ maxParticipants, usedParticipants, periodEnd, activeEdition }: IProps) => {
  const { t, i18n } = useTranslation('teamsScreen');
  const history = useHistory();

  if (activeEdition?.product === ProductCode.Teamio_Arnold) {
    return (
      <div data-cy={'admin-participants-info'}>
        <BodyMedium margin={`${theme.spacing.d} 0 0`} className="w-100">
          <ConditionalWrapper
            condition={usedParticipants >= (maxParticipants ?? 0)}
            wrapper={(children) => (
              <RedText>
                {children} {t('teamioOnb:usageExceeded')}
              </RedText>
            )}
          >
            <Trans
              i18nKey={`teamioOnb:usageInfo`}
              values={{ total: maxParticipants, used: usedParticipants }}
              components={[<strong />]}
            />
          </ConditionalWrapper>{' '}
          {(maxParticipants ?? 0) - usedParticipants <= 5 && (
            <Trans i18nKey={`teamioOnb:orderMore`} components={[<a href={'/settings/order'} />]} />
          )}
        </BodyMedium>
      </div>
    );
  }
  return (
    <div data-cy={'admin-participants-info'}>
      <CapacityInfo>
        <BodyMedium margin={`${theme.spacing.f} 0 ${theme.spacing.e}`} data-icom={'teams-participants-capacity'}>
          {t('participantsCapacity', {
            periodEnd: format(new Date(periodEnd), 'P', { locale: getLocale(i18n.language) }),
          })}{' '}
          <BoldSpan data-cy={'admin-participants-capacity'}>{maxParticipants || '-'}</BoldSpan>
        </BodyMedium>

        <StyledTooltip title={t('maxCapacityTooltip')}>
          <InfoIcon />
        </StyledTooltip>
        {activeEdition?.product === EDITION.FREE.name ? (
          <Button
            data-cy={'admin-update-button'}
            variant={'outline-primary'}
            style={{
              margin: '0 0 0 auto',
              height: 'fit-content',
              alignSelf: 'center',
            }}
            onClick={() => history.push({ pathname: '/settings/pricing' })}
          >
            {t('orderButton')}
          </Button>
        ) : (
          <Button
            data-cy={'admin-participants-button'}
            variant={'outline-primary'}
            style={{
              margin: '0 0 0 auto',
              height: 'fit-content',
              alignSelf: 'center',
            }}
            onClick={() => history.push({ pathname: '/settings/order' })}
          >
            {t('buyParticipants')}
          </Button>
        )}
      </CapacityInfo>
      <BodyMedium>
        <div data-icom={'teams-used-capacity'}>
          {t('usedCapacity')} <BoldSpan data-cy={'admin-participants-used'}>{usedParticipants}</BoldSpan>
        </div>
        <div data-icom={'teams-free-capacity'}>
          {t('freeCapacity')}
          <BoldSpan data-cy={'admin-participants-available'}>
            {maxParticipants ? Math.max(0, maxParticipants - usedParticipants) : '-'}
          </BoldSpan>
        </div>
      </BodyMedium>
    </div>
  );
};

export default SurveyParticipants;
