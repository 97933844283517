import { getReportAccesses } from '../../graphql/queries';
import { Loading } from '../../components';
import { ReportAccessTable } from '../ReportAccess/ReportAccessTable';
import { getShowReportOpenIcon } from '../ReportAccess/utils';
import { useReportAccessQuery } from '../../generated/hooks';

interface IProps {
  surveyId: number;
}

export const SurveyReportAccess = ({ surveyId }: IProps) => {
  const { loading, data } = useReportAccessQuery({
    variables: {
      surveyId: surveyId.toString(),
    },
  });

  const dependantQueries = [
    {
      query: getReportAccesses,
      variables: { surveyId },
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <ReportAccessTable
      dependantQueries={dependantQueries}
      showOpenIcon={getShowReportOpenIcon(data?.survey?.sendAt)}
      surveys={data?.survey ? [data.survey] : []}
    />
  );
};

export default SurveyReportAccess;
